import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { CatalogExtraCategoryUnitPriceType } from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";

interface Props extends WithStyles<typeof styles> {
  catalogExtraCategoryUnitPrice: CatalogExtraCategoryUnitPriceType;
  setCatalogExtraCategoryUnitPrice: React.Dispatch<
    React.SetStateAction<CatalogExtraCategoryUnitPriceType>
  >;
}

function DialogContentCatalogExtraCategoryUnitPrice({
  classes,
  catalogExtraCategoryUnitPrice,
  setCatalogExtraCategoryUnitPrice,
}: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "catalogs_extra.add_catalogextracategoryunitprice",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs_extra.change_catalogextracategoryunitprice",
  ]);
  const disabled = catalogExtraCategoryUnitPrice.id
    ? !hasPermissionEdit
    : !hasPermissionAdd;

  return (
    <DialogContent>
      <FormControl fullWidth>
        <TextField
          label={t("name")}
          onChange={(event) => {
            setCatalogExtraCategoryUnitPrice({
              ...catalogExtraCategoryUnitPrice,
              name: event.target.value,
            });
          }}
          value={catalogExtraCategoryUnitPrice.name}
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          autoFocus={true}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblCatalogExtraCategoryUnitPricePrintSeparately">
          {t("catalog_extra_print_separately")}
        </InputLabel>
        <Select
          autoWidth
          labelId="lblCatalogExtraCategoryUnitPricePrintSeparately"
          value={catalogExtraCategoryUnitPrice.printSeparately ? 1 : 0}
          onChange={(event) => {
            setCatalogExtraCategoryUnitPrice({
              ...catalogExtraCategoryUnitPrice,
              printSeparately: Boolean(event.target.value),
            });
          }}
          disabled={disabled}
        >
          <MenuItem value={1}>{t("yes")}</MenuItem>
          <MenuItem value={0}>{t("no")}</MenuItem>
        </Select>
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentCatalogExtraCategoryUnitPrice);
