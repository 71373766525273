import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import {
  Mutation,
  MutationDeleteReservationImageBasicArgs,
  MutationUpdateReservationImageBasicArgs,
  ReservationImageBasicType,
} from "../../entity/types";
import { Image } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import {
  DELETE_RESERVATION_IMAGE_BASIC_MUTATION,
  UPDATE_RESERVATION_IMAGE_BASIC_MUTATION,
} from "../../apollo/mutations/reservations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { dialogConfirm } from "../../utils/dialogs";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  reservationImageBasic: ReservationImageBasicType;
}

function ReservationImageBasic({ classes, reservationImageBasic }: Props) {
  const { t } = useTranslation();

  const [reservationImageBasicEdited, setReservationImageBasicEdited] =
    useState(reservationImageBasic);

  const [deleteReservationImageBasic, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteReservationImageBasicArgs
  >(DELETE_RESERVATION_IMAGE_BASIC_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("reservationImageBasics"),
      });
    },
  });

  const [updateReservationImageBasic, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateReservationImageBasicArgs
  >(UPDATE_RESERVATION_IMAGE_BASIC_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "reservations.delete_reservationimagebasic",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "reservations.change_reservationimagebasic",
  ]);

  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteReservationImageBasic({
        variables: {
          reservationImageBasicId: reservationImageBasicEdited.id,
        },
      });
    });
  };

  const handleUpdate = (values: { [key: string]: string } = {}) => {
    const base: MutationUpdateReservationImageBasicArgs = {
      reservationImageBasicId: reservationImageBasicEdited.id,
      information: reservationImageBasicEdited.information,
      shootingDate: reservationImageBasicEdited.shootingDate,
    };

    updateReservationImageBasic({
      variables: { ...base, ...values },
    });
  };

  return (
    <div key={reservationImageBasicEdited.id} className={classes.conImage}>
      <a
        href={reservationImageBasicEdited.image}
        target="_blank"
        rel="noreferrer"
      >
        <Image
          className="mb-2"
          src={reservationImageBasicEdited.image}
          thumbnail
          fluid
        />
      </a>
      <div
        className={`flex-grow-1 ${classes.imgFields} ${
          loadingUpdate ? "loading" : ""
        }`}
      >
        <div className={classes.imgFieldsInner}>
          <FormControl>
            <TextField
              className="me-2"
              type="date"
              label={t("shooting_date")}
              value={reservationImageBasicEdited.shootingDate}
              onChange={(event) => {
                const shootingDateNew = event.target.value;
                setReservationImageBasicEdited({
                  ...reservationImageBasicEdited,
                  shootingDate: shootingDateNew,
                });
                handleUpdate({ shootingDate: shootingDateNew });
              }}
              required={true}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!hasPermissionEdit}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label={t("information")}
              value={reservationImageBasicEdited.information}
              inputProps={{ maxLength: 100 }}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setReservationImageBasicEdited({
                  ...reservationImageBasicEdited,
                  information: event.target.value,
                });
              }}
              onBlur={() => handleUpdate()}
            />
          </FormControl>
        </div>
      </div>
      <ButtonLoad
        loading={loadingDelete}
        className={classes.btnDelete}
        variant="light"
        onClick={onClickDelete}
        disabled={!hasPermissionDelete}
      >
        <FontAwesomeIcon icon={faTrash} />
      </ButtonLoad>
    </div>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    conImage: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    imgFields: {
      display: "flex",
      marginBottom: spacing(2),
    },
    imgFieldsInner: {
      display: "inline-block",
      alignSelf: "flex-end",
      width: "100%",
    },
    btnDelete: {
      position: "absolute",
      top: 0,
      right: 0,
    },
  });

export default withStyles(styles)(ReservationImageBasic);
