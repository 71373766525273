import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  InspectionReturnQuestionAnswerType,
  InspectionReturnQuestionType,
  Mutation,
  MutationCreateInspectionReturnQuestionOptionArgs,
} from "../../entity/types";
import InspectionReturnQuestionOption from "./InspectionReturnQuestionOption";
import { Button } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { CREATE_INSPECTION_RETURN_QUESTION_OPTION_MUTATION } from "../../apollo/mutations/inspections";
import { handleError } from "../../entity/ErrorHandler";
import { GET_INSPECTION_RETURN_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WithStyles } from "@material-ui/core/styles";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  inspectionReturnQuestion: InspectionReturnQuestionType;
}

function InspectionReturnQuestionOptions({
  classes,
  inspectionReturnQuestion,
}: Props) {
  const { t } = useTranslation();

  const [showOptions, setShowOptions] = useState(false);

  const [
    createInspectionReturnQuestionOption,
    { loading: loadingCreateOption },
  ] = useMutation<Mutation, MutationCreateInspectionReturnQuestionOptionArgs>(
    CREATE_INSPECTION_RETURN_QUESTION_OPTION_MUTATION,
    {
      refetchQueries: [{ query: GET_INSPECTION_RETURN_CATEGORIES_QUERY }],
      onError: (error) => {
        handleError(error);
      },
    }
  );

  if (
    inspectionReturnQuestion.answerType !==
    InspectionReturnQuestionAnswerType.Options
  ) {
    return <></>;
  }

  const onClickOptionAdd = () => {
    createInspectionReturnQuestionOption({
      variables: {
        inspectionReturnQuestionId: inspectionReturnQuestion.id,
        title: "",
      },
    });
  };

  return (
    <>
      <tr>
        <td colSpan={5}>
          <Button
            variant="light"
            className="ms-3"
            size="sm"
            onClick={() => setShowOptions(!showOptions)}
          >
            <FontAwesomeIcon icon={showOptions ? faChevronUp : faChevronDown} />
          </Button>
          <span className="text-muted ms-3">
            {t("options")}{" "}
            <span className="ms-1">
              (
              {
                inspectionReturnQuestion.inspectionreturnquestionoptionSet
                  .length
              }
              )
            </span>
          </span>
        </td>
      </tr>
      {inspectionReturnQuestion.inspectionreturnquestionoptionSet.map(
        (inspectionReturnQuestionOption) => (
          <InspectionReturnQuestionOption
            className={showOptions ? "" : "d-none"}
            key={inspectionReturnQuestionOption.id}
            inspectionReturnQuestionOption={inspectionReturnQuestionOption}
          />
        )
      )}
      <tr className={showOptions ? "" : "d-none"}>
        <td colSpan={5}>
          <ButtonLoad
            loading={loadingCreateOption}
            onClick={onClickOptionAdd}
            className="btnRoundSmall ms-5"
            variant="primary"
          >
            <AddIcon />
          </ButtonLoad>
        </td>
      </tr>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InspectionReturnQuestionOptions);
