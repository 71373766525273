import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  MaintenanceMaintenanceType,
  MaintenanceType,
  Mutation,
  MutationUpdateMaintenanceArgs,
  UserType,
} from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { useMutation, useQuery } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import Error from "../Shared/Error";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { UPDATE_MAINTENANCE_MUTATION } from "../../apollo/mutations/maintenances";
import ButtonLoad from "../Shared/ButtonLoad";
import { Col, Row } from "react-bootstrap";
import { GET_USERS_QUERY, QueryResultUsers } from "../../apollo/queries/users";
import { findFromSetById } from "../../utils/collections";
import { getQueryKey } from "../../utils/cache";
import LoadingDialog from "../Shared/LoadingDialog";
import { isInvalidDate } from "../../utils/dates";

export type ImageFieldType = "" | "giveImagesAt" | "returnImagesAt";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  maintenance: MaintenanceType;
}

function DialogMaintenanceSchedule({
  classes,
  open,
  setOpen,
  maintenance,
}: Props) {
  const { t } = useTranslation();

  const [editMaintenance, setEditMaintenance] = useState(maintenance);

  const { loading, error, data } = useQuery<QueryResultUsers>(GET_USERS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("users"),
  });

  const [updateMaintenance, { loading: loadingUpdate, error: errorUpdate }] =
    useMutation<Mutation, MutationUpdateMaintenanceArgs>(
      UPDATE_MAINTENANCE_MUTATION,
      {
        onError: (error) => {
          setOpen(true);
          handleError(error);
        },
        update: (cache, result) => {
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("maintenances"),
          });
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("maintenancesUpcoming"),
          });
        },
        onCompleted: (data) => {
          setOpen(false);
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "maintenance.add_maintenance",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "maintenance.change_maintenance",
  ]);

  if (loading) return <LoadingDialog />;
  if (error) return <Error error={error} />;

  if (loadingUpdate) return <LoadingDialog />;
  if (errorUpdate) return <Error error={errorUpdate} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const handleUpdate = (values: { [key: string]: string } = {}) => {
    if (
      isInvalidDate(editMaintenance.serviceStartBy) ||
      isInvalidDate(editMaintenance.serviceEndBy)
    ) {
      return;
    }

    const base: MutationUpdateMaintenanceArgs = {
      maintenanceId: editMaintenance.id,
      serviceTechnicianId: editMaintenance.serviceTechnician?.id
        ? editMaintenance.serviceTechnician.id
        : ID_EMPTY,
      serviceStartBy: editMaintenance.serviceStartBy,
      serviceEndBy: editMaintenance.serviceEndBy,
    };

    updateMaintenance({
      variables: { ...base, ...values },
    });
  };

  return !hasPermissionAdd ? (
    <></>
  ) : (
    <Dialog open={open}>
      <DialogTitleWithClose
        id="dialogTitleCatalogRowUpload"
        onClose={() => setOpen(false)}
      >
        {maintenance.maintenanceType === MaintenanceMaintenanceType.Scheduled
          ? t("maintenance_assignment_info")
          : t("move_to_service_queue")}
      </DialogTitleWithClose>
      <DialogContent className={loading ? "loading" : ""}>
        <FormControl fullWidth>
          <InputLabel id="lblServiceOwner">
            {t("service_technician")}
          </InputLabel>
          <Select
            autoWidth
            labelId="lblServiceOwner"
            value={
              editMaintenance.serviceTechnician?.id
                ? editMaintenance.serviceTechnician.id
                : ID_EMPTY
            }
            onChange={(event) => {
              const serviceTechnicianNew = findFromSetById(
                String(event.target.value),
                data.users ? data.users : [],
                undefined
              );
              setEditMaintenance({
                ...editMaintenance,
                serviceTechnician: serviceTechnicianNew,
              });
            }}
          >
            <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
            {data.users &&
              data.users.map((user: UserType) => (
                <MenuItem
                  key={user.id}
                  value={user.id}
                >{`${user.lastName} ${user.firstName}`}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <Row>
          <Col>
            <FormControl>
              <TextField
                className="me-2"
                type="date"
                label={t("service_start_by_date")}
                value={editMaintenance.serviceStartBy}
                onChange={(event) => {
                  const serviceByNew = event.target.value;
                  setEditMaintenance({
                    ...editMaintenance,
                    serviceStartBy: serviceByNew,
                  });
                }}
                required={true}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={
                  (maintenance.maintenanceType ===
                    MaintenanceMaintenanceType.Scheduled &&
                    !hasPermissionEdit) ||
                  (maintenance.maintenanceType ===
                    MaintenanceMaintenanceType.Upcoming &&
                    !hasPermissionAdd)
                }
              />
            </FormControl>
          </Col>
          <Col>
            {" "}
            <FormControl>
              <TextField
                className="me-2"
                type="date"
                label={t("service_end_by_date")}
                value={editMaintenance.serviceEndBy}
                onChange={(event) => {
                  const serviceEndByNew = event.target.value;
                  setEditMaintenance({
                    ...editMaintenance,
                    serviceEndBy: serviceEndByNew,
                  });
                }}
                required={true}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={
                  (maintenance.maintenanceType ===
                    MaintenanceMaintenanceType.Scheduled &&
                    !hasPermissionEdit) ||
                  (maintenance.maintenanceType ===
                    MaintenanceMaintenanceType.Upcoming &&
                    !hasPermissionAdd)
                }
              />
            </FormControl>
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <ButtonLoad
          loading={loading}
          variant="primary"
          onClick={() => handleUpdate()}
          disabled={
            (maintenance.maintenanceType ===
              MaintenanceMaintenanceType.Scheduled &&
              !hasPermissionEdit) ||
            (maintenance.maintenanceType ===
              MaintenanceMaintenanceType.Upcoming &&
              !hasPermissionAdd)
          }
        >
          {maintenance.maintenanceType === MaintenanceMaintenanceType.Scheduled
            ? t("save")
            : t("move_to_service_queue")}
        </ButtonLoad>
      </DialogActions>
    </Dialog>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    conImages: {
      maxWidth: "300px",
      marginBottom: spacing(2),
    },
    conImage: {
      position: "relative",
    },
    btnDelete: {
      position: "absolute",
      top: 0,
      right: 0,
    },
    radioContainer: {
      display: "block",
      marginTop: "2em",
    },
    textContainer: {
      position: "relative",
    },
    textContent: {
      position: "absolute",
      top: "0.5em",
    },
  });

export default withStyles(styles)(DialogMaintenanceSchedule);
