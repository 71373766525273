import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  GET_PRODUCT_CODES_QUERY,
  QueryResultProductCodes,
} from "../../apollo/queries/product_codes";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Table } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/pro-light-svg-icons";
import ProductCodeRow from "./ProductCodeRow";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ProductCodeEmpty } from "../../entity/empties";
import { Mutation, MutationCreateProductCodeArgs } from "../../entity/types";
import { handleError } from "../../entity/ErrorHandler";
import { CREATE_PRODUCT_CODE_MUTATION } from "../../apollo/mutations/product_codes";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import ButtonLoad from "../Shared/ButtonLoad";
import HeaderPage from "../Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {}

const ProductCodeList = ({ classes }: Props) => {
  const { t } = useTranslation();

  const [productCodeNew, setProductCodeNew] = useState({
    ...ProductCodeEmpty,
    code: ProductCodeEmpty.code,
    description: ProductCodeEmpty.description,
  });

  const [showProductCodeNew, setShowProductCodeNew] = useState(false);

  const { loading, error, data } = useQuery<QueryResultProductCodes>(
    GET_PRODUCT_CODES_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("productCodes"),
    }
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionProductRowAdd = checkPermission(myPermissions, [
    "product_codes.add_productcode",
  ]);

  const [createProductCode, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateProductCodeArgs
  >(CREATE_PRODUCT_CODE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("productCodes"),
      });
    },
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const onClickShowProductCodeNew = () => {
    setShowProductCodeNew(true);
  };
  const onClickHideProductCodeNew = () => {
    setShowProductCodeNew(false);
  };
  const onClickAddProductCode = () => {
    createProductCode({
      variables: {
        code: productCodeNew.code,
        description: productCodeNew.description,
      },
    });
    setShowProductCodeNew(false);
    setProductCodeNew(ProductCodeEmpty);
  };

  return (
    <>
      <HeaderPage title={t("product_numbers")} />
      <div className="containerInner">
        <Table borderless className="w-auto mb-5">
          <thead>
            <tr>
              <th>{t("product_number")}</th>
              <th>{t("product_number_description")}</th>
            </tr>
          </thead>
          <tbody>
            {data.productCodes?.map((productCode, index) => (
              <ProductCodeRow key={productCode.id} productCode={productCode} />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>
                {hasPermissionProductRowAdd && !showProductCodeNew && (
                  <Button
                    onClick={onClickShowProductCodeNew}
                    className="btnRoundSmall"
                  >
                    <AddIcon />
                  </Button>
                )}
              </td>
            </tr>
            <tr>
              <td>
                {showProductCodeNew && (
                  <FormControl>
                    <TextField
                      value={productCodeNew.code}
                      onChange={(event) => {
                        setProductCodeNew({
                          ...productCodeNew,
                          code: event.target.value,
                        });
                      }}
                      inputProps={{ maxLength: 32 }}
                      onBlur={() => {
                        setProductCodeNew({
                          ...productCodeNew,
                          code: productCodeNew.code,
                        });
                      }}
                    />
                  </FormControl>
                )}
              </td>
              <td>
                {showProductCodeNew && (
                  <FormControl>
                    <TextField
                      value={productCodeNew.description}
                      onChange={(event) => {
                        setProductCodeNew({
                          ...productCodeNew,
                          description: event.target.value,
                        });
                      }}
                      inputProps={{ maxLength: 200 }}
                      onBlur={() => {
                        setProductCodeNew({
                          ...productCodeNew,
                          description: productCodeNew.description,
                        });
                      }}
                    />
                  </FormControl>
                )}
              </td>
              <td>
                {hasPermissionProductRowAdd && showProductCodeNew && (
                  <ButtonLoad
                    loading={loadingCreate}
                    onClick={onClickAddProductCode}
                    variant="primary"
                    size="sm"
                    className={classes.btn}
                  >
                    <FontAwesomeIcon icon={faSave} />
                  </ButtonLoad>
                )}
              </td>
              <td>
                {hasPermissionProductRowAdd && showProductCodeNew && (
                  <Button
                    variant="light"
                    size="sm"
                    className={classes.btn}
                    onClick={onClickHideProductCodeNew}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                )}
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    btn: {
      minWidth: "2rem",
    },
  });

export default withStyles(styles)(ProductCodeList);
