import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  MachineMakeType,
  Mutation,
  MutationDeleteMachineMakeArgs,
  MutationUpdateMachineMakeArgs,
} from "../../entity/types";
import { Button } from "react-bootstrap";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faEdit,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import {
  DELETE_MACHINE_MAKE_MUTATION,
  UPDATE_MACHINE_MAKE_MUTATION,
} from "../../apollo/mutations/machines";
import { GET_MAKES_AND_MODELS_QUERY } from "../../apollo/queries/machines";
import { handleError } from "../../entity/ErrorHandler";
import { dialogConfirm } from "../../utils/dialogs";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  machineMake: MachineMakeType;
}

function MachineMakeFlex({ classes, machineMake }: Props) {
  const { t } = useTranslation();

  const [editMode, setEditMode] = useState(false);
  const [machineMakeEdited, setMachineMakeEdited] = useState(machineMake);

  const [deleteMachineMake, { loading: loadingDeleteMake }] = useMutation<
    Mutation,
    MutationDeleteMachineMakeArgs
  >(DELETE_MACHINE_MAKE_MUTATION, {
    refetchQueries: [{ query: GET_MAKES_AND_MODELS_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const [updateMachineMake, { loading: loadingUpdateMake }] = useMutation<
    Mutation,
    MutationUpdateMachineMakeArgs
  >(UPDATE_MACHINE_MAKE_MUTATION, {
    refetchQueries: [{ query: GET_MAKES_AND_MODELS_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  /* If the source make changes, we need to change the edited make too, even if changes are lost */
  useEffect(() => {
    setMachineMakeEdited(machineMake);
  }, [machineMake]);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditMake = checkPermission(myPermissions, [
    "machines.change_machinemake",
  ]);
  const hasPermissionDeleteMake = checkPermission(myPermissions, [
    "machines.delete_machinemake",
  ]);

  const onClickDeleteMake = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteMachineMake({ variables: { machineMakeId: machineMake.id } });
    });
  };

  const handleUpdate = (values: { [key: string]: string | number }) => {
    const base: MutationUpdateMachineMakeArgs = {
      machineMakeId: machineMake.id,
      title: machineMake.title,
    };

    let changed = false;
    for (let key in values) {
      if (values[key] !== base[key as keyof MutationUpdateMachineMakeArgs]) {
        changed = true;
      }
    }

    if (changed) {
      updateMachineMake({
        variables: { ...base, ...values },
      });
    }
  };

  return (
    <div className={`d-flex mb-3 ${loadingUpdateMake ? "loading" : ""}`}>
      {editMode && (
        <FormControl className="mb-0 me-2" fullWidth>
          <TextField
            value={machineMakeEdited.title}
            inputProps={{
              maxLength: 100,
            }}
            onChange={(event) => {
              setMachineMakeEdited({
                ...machineMakeEdited,
                title: event.target.value,
              });
            }}
            onBlur={() =>
              handleUpdate({
                title: machineMakeEdited.title,
              })
            }
            disabled={!hasPermissionEditMake}
          />
        </FormControl>
      )}
      {hasPermissionEditMake && (
        <Button
          className={`me-2 ${classes.btnEditMake}`}
          onClick={() => setEditMode(!editMode)}
          variant="light"
          size="sm"
        >
          <FontAwesomeIcon
            className="me-2"
            icon={editMode ? faChevronLeft : faChevronRight}
          />
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      )}
      {hasPermissionDeleteMake && (
        <ButtonLoad
          loading={loadingDeleteMake}
          className="me-2"
          onClick={() => onClickDeleteMake()}
          variant="light"
          size="sm"
        >
          <FontAwesomeIcon icon={faTrash} />
        </ButtonLoad>
      )}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    btnEditMake: {
      minWidth: "50px",
    },
  });

export default withStyles(styles)(MachineMakeFlex);
