import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import SearchOrder from "./SearchOrder";

interface Props extends WithStyles<typeof styles> {
  title: string;
}

const HeaderPage = ({ classes, title }: Props) => {
  const [showTitle, setShowTitle] = useState(true);

  return (
    <h2 className="headerPage position-relative">
      <div
        className={`${classes.title} ${
          showTitle ? classes.titleVisible : classes.titleHidden
        }`}
      >
        {title}
      </div>
      <SearchOrder setShowTitle={setShowTitle} />
    </h2>
  );
};

const styles = ({ breakpoints }: Theme) =>
  createStyles({
    title: {
      transition: "width 1s",
      overflow: "hidden",
    },
    titleVisible: {
      width: "calc(100% - 60px)",
    },
    titleHidden: {
      [breakpoints.down("sm")]: {
        width: "0",
      },
    },
  });

export default withStyles(styles)(HeaderPage);
