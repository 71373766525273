import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

/* Nothing requiring permissions should be added to me query
   so we don't log user out after login if no permissions have been set. */
export interface QueryResultMe {
  me: Query["me"];
  myPermissions: Query["myPermissions"];
  settings: Query["settings"];
}
export const ME_QUERY_K = gql`
  query getMe {
    me {
      id
      firstName
      lastName
      email
      dateJoined
      language
      lastLogin
      groups {
        id
        name
      }
    }
    myPermissions
    settings {
      vat
      vatFactor
      productNumberSingle
      productNumberOpHours
      productNumberInsurance
      productNumberBillingSurcharge
      productNumberOpeningFee
      insurancePercent
      billingSurchargeAmount
      billingSurchargeLimit
      openingFeeAmount
      openingFeeLimit
      commissionOwner
      commissionOrderer
      commissionRentThrough
      textConfirmation1
      textConfirmation2
      textOffer0
      textOffer1
      textOffer2
      textContract1
      textContract2
    }
  }
`;



export const ME_QUERY = gql`
  query getMe {
    me {
      id
      firstName
      lastName
      email
      language
      dateJoined
      lastLogin
    }
      
  }
`;

export interface QueryResultProfile {
  user: Query["user"];
}
/* VL outc
 UserInfo {
        phone
        address
        postcode
        district
        language
        location {
          id
        }
      }   groups {
        id
        name
      } */
export const PROFILE_QUERY = gql`
  query getProfile($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      dateJoined
      lastLogin
     
     
    }
  }
`;

export interface QueryResultProfileForeigns {
  groups: Query["groups"];
  locations: Query["locations"];
}
export const PROFILE_FOREIGNS_QUERY = gql`
  query getProfileForeigns {
    roles {
      id
      name
    }
    locations {
      id
      name
    }
  }
`;
