import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  CatalogTransferMethodType,
  CatalogTransferPriceType,
  Mutation,
  MutationDeleteTransportInvoicingArgs,
  MutationUpdateTransportInvoicingArgs,
  OrderType,
  ReferenceType,
  TransportInvoicingMethod,
  TransportInvoicingRowType,
  TransportInvoicingType,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { Button } from "react-bootstrap";
import { handleError } from "../../entity/ErrorHandler";
import {
  DELETE_TRANSPORT_INVOICING_MUTATION,
  UPDATE_TRANSPORT_INVOICING_MUTATION,
} from "../../apollo/mutations/transports";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { dialogConfirm } from "../../utils/dialogs";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import {
  CatalogTransferMethodEmpty,
  CatalogTransferPriceEmpty,
  ReferenceEmpty,
} from "../../entity/empties";
import { formatNumber, parseNumber } from "../../utils/formatting";
import SelectRequired from "../Shared/SelectRequired";
import { findFromSetById } from "../../utils/collections";
import { getDiscountPercent, getPercentFactorDiscount } from "../../utils/calc";
import { getQueryKey } from "../../utils/cache";
import {
  getDescriptionTextTransport,
  getTitleTextTransport,
} from "../../utils/transports/transports";

const getDefaultTotalAfterDiscount = (
  transportInvoicing: TransportInvoicingType
) =>
  formatNumber(
    transportInvoicing.quantity *
      transportInvoicing.unitPrice *
      getPercentFactorDiscount(transportInvoicing.discountPercent),
    2
  );

interface Props extends WithStyles<typeof styles> {
  order: OrderType;
  transportInvoicing: TransportInvoicingType;
  catalogTransferMethods: CatalogTransferMethodType[];
  references: ReferenceType[];
  hasDiscountCol: boolean;
}

function TransportCardInvoicing({
  classes,
  order,
  transportInvoicing,
  catalogTransferMethods,
  references,
  hasDiscountCol,
}: Props) {
  const { t } = useTranslation();

  const [transportInvoicingEdited, setTransportInvoicingEdited] = useState({
    ...transportInvoicing,
    quantity: formatNumber(transportInvoicing.quantity, 2),
    unitPrice: formatNumber(transportInvoicing.unitPrice, 2),
    discountPercent: formatNumber(transportInvoicing.discountPercent, 4),
  });
  const [totalAfterDiscount, setTotalAfterDiscount] = useState<string>(
    getDefaultTotalAfterDiscount(transportInvoicing)
  );

  const [updateTransportInvoicing, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateTransportInvoicingArgs
  >(UPDATE_TRANSPORT_INVOICING_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("transportInvoicingsByOrder"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("orderTotal"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("invoiceableRows"),
      });
    },
  });

  const [deleteTransportInvoicing, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteTransportInvoicingArgs
  >(DELETE_TRANSPORT_INVOICING_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("transportInvoicingsByOrder"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("orderTotal"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("invoiceableRows"),
      });
    },
  });

  useEffect(() => {
    setTotalAfterDiscount(getDefaultTotalAfterDiscount(transportInvoicing));
  }, [transportInvoicing]);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "transports.change_transportinvoicing",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "transports.delete_transportinvoicing",
  ]);

  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteTransportInvoicing({
        variables: {
          transportInvoicingId: transportInvoicingEdited.id,
        },
      });
    });
  };

  const handleUpdate = (
    values: { [key: string]: string | number | [] } = {}
  ) => {
    const base: MutationUpdateTransportInvoicingArgs = {
      transportInvoicingId: transportInvoicingEdited.id,
      orderId: transportInvoicingEdited.order.id,
      referenceId: transportInvoicingEdited.reference
        ? transportInvoicingEdited.reference.id
        : ID_EMPTY,
      catalogTransferMethodId: transportInvoicingEdited.catalogTransferMethod
        ? transportInvoicingEdited.catalogTransferMethod.id
        : ID_EMPTY,
      method: transportInvoicingEdited.method,
      rowType: transportInvoicingEdited.rowType,
      title: transportInvoicingEdited.title,
      description: transportInvoicingEdited.description,
      unitPrice: parseNumber(transportInvoicingEdited.unitPrice),
      quantity: parseNumber(transportInvoicingEdited.quantity),
      discountPercent: parseNumber(transportInvoicingEdited.discountPercent),
    };

    updateTransportInvoicing({
      variables: { ...base, ...values },
    });
  };

  const getCatalogTransferPriceByLocation = (
    catalogTransferPrices: CatalogTransferPriceType[]
  ) => {
    const catalogTransferPricesForLocation = catalogTransferPrices.filter(
      (catalogTransferPriceLooped) =>
        catalogTransferPriceLooped.location.id === order.location.id
    );
    return catalogTransferPricesForLocation[0] !== undefined
      ? catalogTransferPricesForLocation[0]
      : CatalogTransferPriceEmpty;
  };

  const calculateTransferPrice = (
    method: TransportInvoicingMethod,
    rowType: TransportInvoicingRowType,
    catalogTransferMethodId: string
  ) => {
    const catalogTransferMethodNew =
      transportInvoicingEdited.catalogTransferMethod
        ? transportInvoicingEdited.catalogTransferMethod
        : CatalogTransferMethodEmpty;

    const catalogTransferMethod: CatalogTransferMethodType = findFromSetById(
      catalogTransferMethodId,
      catalogTransferMethods,
      CatalogTransferMethodEmpty
    );

    const catalogTransferPrice = getCatalogTransferPriceByLocation(
      catalogTransferMethod.catalogtransferpriceSet
    );

    const rowTypeChanged = rowType !== transportInvoicingEdited.rowType;

    let title = getTitleTextTransport(t, rowType, order.location.name);
    let description = getDescriptionTextTransport(
      t,
      method,
      catalogTransferMethod.name
    );
    let quantity = transportInvoicingEdited.quantity;

    let unitPrice = "0";
    if (rowType === TransportInvoicingRowType.Basic) {
      unitPrice = catalogTransferPrice.priceBasic;
    } else if (rowType === TransportInvoicingRowType.Distance) {
      unitPrice = catalogTransferPrice.priceExtra;
    } else if (rowType === TransportInvoicingRowType.Stop) {
      unitPrice = catalogTransferPrice.priceStop;
    }

    if (rowTypeChanged) {
      title = getTitleTextTransport(t, rowType, order.location.name);
      description = getDescriptionTextTransport(
        t,
        method,
        catalogTransferMethod.name
      );
      quantity = "1";
    }

    setTransportInvoicingEdited({
      ...transportInvoicingEdited,
      catalogTransferMethod: {
        ...catalogTransferMethodNew,
        id: catalogTransferMethodId,
      },
      method: method,
      unitPrice: formatNumber(unitPrice, 2),
      rowType: rowType,
      title: title,
      description: description,
      quantity: quantity,
    });

    handleUpdate({
      catalogTransferMethodId: catalogTransferMethodId,
      unitPrice: unitPrice,
      rowType: rowType,
      title: title,
      description: description,
      quantity: parseNumber(quantity),
    });
  };

  const hasBeenInvoiced = Boolean(transportInvoicingEdited.invoiceRow);
  const disabled = !hasPermissionEdit || hasBeenInvoiced;
  const total =
    parseNumber(transportInvoicingEdited.quantity) *
    parseNumber(transportInvoicingEdited.unitPrice);

  return (
    <Paper
      className={`${loadingDelete || loadingUpdate ? "loading" : ""} ${
        classes.conTransport
      }`}
    >
      <FormControl fullWidth>
        <InputLabel id="lblTransportInvoicingRowType">
          {t("transport_invoicing_row_type")}
        </InputLabel>
        <SelectRequired
          labelId="lblTransportInvoicingRowType"
          value={transportInvoicingEdited.rowType}
          disabled={disabled}
          onChange={(event) => {
            const rowTypeNew = event.target.value as TransportInvoicingRowType;
            calculateTransferPrice(
              transportInvoicingEdited.method,
              rowTypeNew,
              transportInvoicingEdited.catalogTransferMethod
                ? transportInvoicingEdited.catalogTransferMethod.id
                : ID_EMPTY
            );
          }}
        >
          <MenuItem value={TransportInvoicingRowType.Basic}>
            {t("transport_row_type_" + TransportInvoicingRowType.Basic)}
          </MenuItem>
          <MenuItem value={TransportInvoicingRowType.Distance}>
            {t("transport_row_type_" + TransportInvoicingRowType.Distance)}
          </MenuItem>
          <MenuItem value={TransportInvoicingRowType.Stop}>
            {t("transport_row_type_" + TransportInvoicingRowType.Stop)}
          </MenuItem>
        </SelectRequired>
      </FormControl>
      {[
        TransportInvoicingRowType.Basic,
        TransportInvoicingRowType.Distance,
      ].includes(transportInvoicingEdited.rowType) && (
        <FormControl fullWidth>
          <InputLabel id="lblTransportMethod">
            {t("transport_method")}
          </InputLabel>
          <SelectRequired
            labelId="lblTransportMethod"
            value={transportInvoicingEdited.method}
            disabled={disabled}
            onChange={(event) => {
              const methodNew = event.target.value as TransportInvoicingMethod;
              calculateTransferPrice(
                methodNew,
                transportInvoicingEdited.rowType,
                transportInvoicingEdited.catalogTransferMethod
                  ? transportInvoicingEdited.catalogTransferMethod.id
                  : ID_EMPTY
              );
            }}
          >
            <MenuItem value={TransportInvoicingMethod.Send}>
              {t("transport_method_" + TransportInvoicingMethod.Send)}
            </MenuItem>
            <MenuItem value={TransportInvoicingMethod.Retrieve}>
              {t("transport_method_" + TransportInvoicingMethod.Retrieve)}
            </MenuItem>
          </SelectRequired>
        </FormControl>
      )}
      {[
        TransportInvoicingRowType.Basic,
        TransportInvoicingRowType.Distance,
      ].includes(transportInvoicingEdited.rowType) && (
        <FormControl fullWidth>
          <InputLabel id="lblTransportCatalogTransferMethod">
            {t("catalog_transfer_method")}
          </InputLabel>
          <SelectRequired
            labelId="lblTransportCatalogTransferMethod"
            value={
              transportInvoicingEdited.catalogTransferMethod
                ? transportInvoicingEdited.catalogTransferMethod.id
                : ID_EMPTY
            }
            disabled={disabled}
            onChange={(event) => {
              calculateTransferPrice(
                transportInvoicingEdited.method,
                transportInvoicingEdited.rowType,
                String(event.target.value)
              );
            }}
          >
            <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
            {catalogTransferMethods &&
              catalogTransferMethods.map((catalogTransferMethod) => (
                <MenuItem
                  key={catalogTransferMethod.id}
                  value={catalogTransferMethod.id}
                >
                  {catalogTransferMethod.name}
                </MenuItem>
              ))}
          </SelectRequired>
        </FormControl>
      )}
      <FormControl fullWidth>
        <TextField
          label={t("title")}
          required={true}
          value={transportInvoicingEdited.title}
          disabled={disabled}
          onChange={(event) => {
            setTransportInvoicingEdited({
              ...transportInvoicingEdited,
              title: event.target.value,
            });
          }}
          onBlur={() => {
            handleUpdate();
          }}
          inputProps={{ maxLength: 200 }}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("description")}
          value={transportInvoicingEdited.description}
          disabled={disabled}
          onChange={(event) => {
            setTransportInvoicingEdited({
              ...transportInvoicingEdited,
              description: event.target.value,
            });
          }}
          onBlur={() => {
            handleUpdate();
          }}
          inputProps={{ maxLength: 512 }}
        />
      </FormControl>
      <div className="d-flex">
        <div className="w-50 pe-2">
          <FormControl fullWidth>
            <TextField
              label={t("quantity")}
              required={true}
              value={transportInvoicingEdited.quantity}
              disabled={disabled}
              onChange={(event) => {
                setTransportInvoicingEdited({
                  ...transportInvoicingEdited,
                  quantity: event.target.value,
                });
              }}
              onBlur={(event) => {
                const quantityNew = formatNumber(event.target.value, 2);
                setTransportInvoicingEdited({
                  ...transportInvoicingEdited,
                  quantity: quantityNew,
                });
                handleUpdate({ quantity: parseNumber(quantityNew) });
              }}
            />
          </FormControl>
        </div>
        <div className="w-50">
          <FormControl fullWidth>
            <TextField
              label={t("unit_price")}
              required={true}
              value={transportInvoicingEdited.unitPrice}
              disabled={disabled}
              onChange={(event) => {
                setTransportInvoicingEdited({
                  ...transportInvoicingEdited,
                  unitPrice: event.target.value,
                });
              }}
              onBlur={(event) => {
                const unitPriceNew = formatNumber(event.target.value, 2);
                setTransportInvoicingEdited({
                  ...transportInvoicingEdited,
                  unitPrice: unitPriceNew,
                });
                handleUpdate({ unitPrice: parseNumber(unitPriceNew) });
              }}
            />
          </FormControl>
        </div>
      </div>
      <FormControl fullWidth>
        <TextField
          label={t("discount_percent")}
          required={true}
          value={transportInvoicingEdited.discountPercent}
          disabled={disabled}
          onChange={(event) => {
            setTransportInvoicingEdited({
              ...transportInvoicingEdited,
              discountPercent: event.target.value,
            });
          }}
          onBlur={(event) => {
            const discountPercentNew = formatNumber(event.target.value, 2);
            setTransportInvoicingEdited({
              ...transportInvoicingEdited,
              discountPercent: discountPercentNew,
            });
            handleUpdate({
              discountPercent: parseNumber(discountPercentNew),
            });
          }}
        />
      </FormControl>
      {hasDiscountCol && (
        <FormControl fullWidth>
          <TextField
            label={t("total_without_discount")}
            value={formatNumber(total, 2)}
            disabled={true}
          />
        </FormControl>
      )}
      <FormControl fullWidth>
        <TextField
          label={t(hasDiscountCol ? "total_after_discount" : "total")}
          value={totalAfterDiscount}
          disabled={disabled}
          onChange={(event) => setTotalAfterDiscount(event.target.value)}
          onBlur={() => {
            const totalAfterDiscountNew = parseNumber(totalAfterDiscount);
            const discountPercentNew = getDiscountPercent(
              total,
              totalAfterDiscountNew
            );

            setTotalAfterDiscount(formatNumber(totalAfterDiscountNew, 2));

            setTransportInvoicingEdited({
              ...transportInvoicingEdited,
              discountPercent: formatNumber(discountPercentNew, 4),
            });
            handleUpdate({
              discountPercent: discountPercentNew,
            });
          }}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblTransportReference">{t("reference")}</InputLabel>
        <SelectRequired
          labelId="lblTransportReference"
          value={
            transportInvoicingEdited.reference
              ? transportInvoicingEdited.reference.id
              : ID_EMPTY
          }
          disabled={disabled}
          onChange={(event) => {
            const idNew = String(event.target.value);
            const referenceNew = transportInvoicingEdited.reference
              ? transportInvoicingEdited.reference
              : ReferenceEmpty;

            setTransportInvoicingEdited({
              ...transportInvoicingEdited,
              reference: {
                ...referenceNew,
                id: idNew,
              },
            });
            handleUpdate({ referenceId: idNew });
          }}
        >
          <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
          {references.map((reference) => (
            <MenuItem key={reference.id} value={reference.id}>
              {reference.name}
            </MenuItem>
          ))}
        </SelectRequired>
      </FormControl>
      {hasPermissionDelete && (
        <Button onClick={onClickDelete} variant="light">
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      )}
    </Paper>
  );
}

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    conTransport: {
      width: "30%",
      padding: spacing(2),
      marginRight: spacing(2),
      marginBottom: spacing(2),
      display: "inline-block",
      verticalAlign: "top",

      [breakpoints.down("lg")]: {
        width: "45%",
      },
      [breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  });

export default withStyles(styles)(TransportCardInvoicing);
