import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import {
  CommissionLevelType,
  Mutation,
  MutationDeleteCommissionLevelArgs,
  MutationUpdateCommissionLevelArgs,
} from "../../entity/types";
import ButtonLoad from "../Shared/ButtonLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import {
  DELETE_COMMISSION_LEVEL_MUTATION,
  UPDATE_COMMISSION_LEVEL_MUTATION,
} from "../../apollo/mutations/commissions";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { useTranslation } from "react-i18next";
import { dialogConfirm } from "../../utils/dialogs";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { WithStyles } from "@material-ui/core/styles";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  commissionLevel: CommissionLevelType;
  commissionLevelPrev: CommissionLevelType | null;
}

function CommissionLevelRow({
  classes,
  commissionLevel,
  commissionLevelPrev,
}: Props) {
  const { t } = useTranslation();

  const [commissionLevelEdit, setCommissionLevelEdit] = useState({
    ...commissionLevel,
    maxDiscount: formatNumber(commissionLevel.maxDiscount, 2),
    maxCommission: formatNumber(commissionLevel.maxCommission, 2),
    minCommission: formatNumber(commissionLevel.minCommission, 2),
  });

  const [updateCommissionLevel, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCommissionLevelArgs
  >(UPDATE_COMMISSION_LEVEL_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const [deleteCommissionLevel, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteCommissionLevelArgs
  >(DELETE_COMMISSION_LEVEL_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("commissionTemplates"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionCommissionLevelChange = checkPermission(myPermissions, [
    "commissions.change_commissionlevel",
  ]);
  const hasPermissionCommissionLevelDelete = checkPermission(myPermissions, [
    "commissions.delete_commissionlevel",
  ]);

  const handleUpdate = (values: { [key: string]: string | number } = {}) => {
    const base: MutationUpdateCommissionLevelArgs = {
      commissionLevelId: commissionLevelEdit.id,
      maxDiscount: parseNumber(commissionLevelEdit.maxDiscount),
      maxCommission: parseNumber(commissionLevelEdit.maxCommission),
      minCommission: parseNumber(commissionLevelEdit.minCommission),
    };

    updateCommissionLevel({
      variables: { ...base, ...values },
    });
  };

  const onClickDeleteCommissionLevel = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCommissionLevel({
        variables: {
          commissionLevelId: commissionLevel.id,
        },
      });
    });
  };

  const checkPercent = (value: string) => {
    const numValue = parseNumber(value);
    if (numValue > 100) {
      return formatNumber(100, 2);
    }
    if (numValue < 0) {
      return formatNumber(0, 2);
    }
    return value;
  };

  return (
    <tr className={loadingUpdate ? "loading" : ""}>
      <td>
        <FormControl>
          <TextField
            value={formatNumber(
              commissionLevelPrev ? commissionLevelPrev.maxDiscount : 0,
              2
            )}
            disabled={true}
          />
        </FormControl>
      </td>
      <td>
        <FormControl>
          <TextField
            value={commissionLevelEdit.maxDiscount}
            disabled={!hasPermissionCommissionLevelChange}
            onChange={(event) => {
              setCommissionLevelEdit({
                ...commissionLevelEdit,
                maxDiscount: event.target.value,
              });
            }}
            onBlur={(event) => {
              let maxDiscount = formatNumber(event.target.value, 2);

              maxDiscount = checkPercent(maxDiscount);

              setCommissionLevelEdit({
                ...commissionLevelEdit,
                maxDiscount: maxDiscount,
              });

              handleUpdate({ maxDiscount: parseNumber(maxDiscount) });
            }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl>
          <TextField
            value={commissionLevelEdit.maxCommission}
            disabled={!hasPermissionCommissionLevelChange}
            onChange={(event) => {
              setCommissionLevelEdit({
                ...commissionLevelEdit,
                maxCommission: event.target.value,
              });
            }}
            inputProps={{ maxLength: 6 }}
            onBlur={(event) => {
              let maxCommission = formatNumber(event.target.value, 2);

              if (
                parseNumber(maxCommission) <
                parseNumber(commissionLevelEdit.minCommission)
              ) {
                maxCommission = formatNumber(
                  commissionLevelEdit.minCommission,
                  2
                );
              }

              maxCommission = checkPercent(maxCommission);

              setCommissionLevelEdit({
                ...commissionLevelEdit,
                maxCommission: maxCommission,
              });

              handleUpdate({ maxCommission: parseNumber(maxCommission) });
            }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl>
          <TextField
            value={commissionLevelEdit.minCommission}
            disabled={!hasPermissionCommissionLevelChange}
            onChange={(event) => {
              setCommissionLevelEdit({
                ...commissionLevelEdit,
                minCommission: event.target.value,
              });
            }}
            inputProps={{ maxLength: 6 }}
            onBlur={(event) => {
              let minCommission = formatNumber(event.target.value, 2);

              if (
                parseNumber(minCommission) >
                parseNumber(commissionLevelEdit.maxCommission)
              ) {
                minCommission = formatNumber(
                  commissionLevelEdit.maxCommission,
                  2
                );
              }

              minCommission = checkPercent(minCommission);

              setCommissionLevelEdit({
                ...commissionLevelEdit,
                minCommission: minCommission,
              });

              handleUpdate({ minCommission: parseNumber(minCommission) });
            }}
          />
        </FormControl>
      </td>
      <td>
        {hasPermissionCommissionLevelDelete && (
          <ButtonLoad
            loading={loadingDelete}
            variant="light"
            size="sm"
            className={classes.btn}
            onClick={onClickDeleteCommissionLevel}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonLoad>
        )}
      </td>
    </tr>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    btn: {
      minWidth: "2rem",
    },
  });

export default withStyles(styles)(CommissionLevelRow);
