import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultTransportsByOrder {
  transportsByOrder: Query["transportsByOrder"];
}
export const GET_TRANSPORTS_BY_ORDER_QUERY = gql`
  query getTransportsByOrderQuery($orderId: ID!) {
    transportsByOrder(orderId: $orderId) {
      id
      method
      address
      postcode
      district
      information
      dateDeadline
      timeDeadline
      dateEndUsage
      timeEndUsage
      catalogTransferMethod {
        id
      }
      reservations {
        id
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export interface QueryResultTransportInvoicingsByOrder {
  transportInvoicingsByOrder: Query["transportInvoicingsByOrder"];
}
export const GET_TRANSPORT_INVOICINGS_BY_ORDER_QUERY = gql`
  query getTransportInvoicingsByOrderQuery($orderId: ID!) {
    transportInvoicingsByOrder(orderId: $orderId) {
      id
      order {
        id
      }
      reference {
        id
      }
      catalogTransferMethod {
        id
      }
      invoiceRow {
        id
      }
      method
      rowType
      title
      description
      unitPrice
      quantity
      discountPercent
      price
    }
  }
`;

export interface QueryResultTransportInvoicingSummariesByOrder {
  transportInvoicingSummariesByOrder: Query["transportInvoicingSummariesByOrder"];
}
export const GET_TRANSPORT_INVOICING_SUMMARIES_BY_ORDER_QUERY = gql`
  query getTransportInvoicingSummariesByOrderQuery($orderId: ID!) {
    transportInvoicingSummariesByOrder(orderId: $orderId) {
      id
      order {
        id
      }
      reference {
        id
      }
      invoiceRow {
        id
      }
      percent
      price
    }
  }
`;

export interface QueryResultTransportsUnhandled {
  transportsUnhandled: Query["transportsUnhandled"];
}
export const GET_TRANSPORTS_UNHANDLED_QUERY = gql`
  query getTransportsUnhandledQuery($locationIds: [ID]!) {
    transportsUnhandled(locationIds: $locationIds) {
      id
      method
      address
      postcode
      district
      dateDeadline
      timeDeadline
      dateEndUsage
      timeEndUsage
      information
      catalogTransferMethod {
        id
        name
      }
      reservations {
        id
        machine {
          id
          identifier
          location {
            id
            name
          }
          machineModel {
            id
            title
            machineMake {
              id
              title
            }
          }
        }
        catalogExtraRowRental {
          id
          identifier
          location {
            id
            name
          }
          name
        }
        drivecontentSet {
          id
          transport {
            id
          }
        }
      }
    }
  }
`;

export interface QueryResultSearchTransports {
  transportsSearch: Query["transportsSearch"];
}
export const GET_TRANSPORTS_SEARCH_QUERY = gql`
  query getTransportsSearchQuery($customerId: ID!, $searchTerms: String) {
    transportsSearch(customerId: $customerId, searchTerms: $searchTerms) {
      id
      address
      postcode
      district
      order {
        id
        location {
          id
          name
          transferDistanceIncluded
        }
        transportinvoicingSet {
          id
          rowType
          quantity
        }
      }
    }
  }
`;
