import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { useMutation } from "@apollo/client";
import { createStyles, Theme } from "@material-ui/core";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/pro-light-svg-icons";
import { DELETE_INSPECTION_GIVE_CATEGORY_MUTATION } from "../../apollo/mutations/inspections";
import { GET_INSPECTION_GIVE_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { PermissionsContext } from "../../Root";
import Loading from "../Shared/Loading";
import {
  InspectionGiveCategoryType,
  InspectionGiveTagType,
  Mutation,
  MutationDeleteInspectionGiveCategoryArgs,
} from "../../entity/types";
import { handleError } from "../../entity/ErrorHandler";
import { checkPermission } from "../../utils/permissions";
import InspectionGiveCategoryQuestions from "./InspectionGiveCategoryQuestions";
import { dialogConfirm } from "../../utils/dialogs";
import DialogInspectionGiveCategory from "./DialogInspectionGiveCategory";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionGiveCategory: InspectionGiveCategoryType;
  inspectionGiveTags: InspectionGiveTagType[];
}

function InspectionGiveCategory({
  classes,
  inspectionGiveCategory,
  inspectionGiveTags,
}: Props) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const [deleteInspectionGiveCategory, { loading: loadingDelete }] =
    useMutation<Mutation, MutationDeleteInspectionGiveCategoryArgs>(
      DELETE_INSPECTION_GIVE_CATEGORY_MUTATION,
      {
        refetchQueries: [{ query: GET_INSPECTION_GIVE_CATEGORIES_QUERY }],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "inspections.change_inspectiongivecategory",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "inspections.delete_inspectiongivecategory",
  ]);

  if (loadingDelete) return <Loading />;

  const handleDeleteInspectionGiveCategory = (
    inspectionGiveCategoryId: string
  ) => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteInspectionGiveCategory({
        variables: { inspectionGiveCategoryId: inspectionGiveCategoryId },
      });
    });
  };

  return (
    <Table className={classes.table} borderless hover responsive={true}>
      <thead>
        <tr>
          <th className={classes.headerCategory}>
            {inspectionGiveCategory.name}
          </th>
          <th>{t("answer_type")}</th>
          <th>{t("tags")}</th>
          <th>
            {hasPermissionEdit && (
              <Button
                className="me-1"
                variant="light"
                size="sm"
                onClick={() => setOpenDialog(true)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
            {hasPermissionDelete && (
              <Button
                variant="light"
                size="sm"
                onClick={() =>
                  handleDeleteInspectionGiveCategory(inspectionGiveCategory.id)
                }
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
            <DialogInspectionGiveCategory
              open={openDialog}
              setOpen={setOpenDialog}
              inspectionGiveCategory={inspectionGiveCategory}
            />
          </th>
        </tr>
      </thead>
      <InspectionGiveCategoryQuestions
        inspectionGiveCategory={inspectionGiveCategory}
        inspectionGiveQuestions={
          inspectionGiveCategory.inspectiongivequestionSet
        }
        inspectionGiveTags={inspectionGiveTags}
      />
    </Table>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    table: {
      width: "auto",
    },
    headerCategory: {
      color: palette.primary.main,
      fontSize: "1.2rem",
    },
  });

export default withStyles(styles)(InspectionGiveCategory);
