import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import {
  InspectionReturnQuestionOptionType,
  Mutation,
  MutationDeleteInspectionReturnQuestionOptionArgs,
  MutationUpdateInspectionReturnQuestionOptionArgs,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import ButtonLoad from "../Shared/ButtonLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import {
  DELETE_INSPECTION_RETURN_QUESTION_OPTION_MUTATION,
  UPDATE_INSPECTION_RETURN_QUESTION_OPTION_MUTATION,
} from "../../apollo/mutations/inspections";
import { GET_INSPECTION_RETURN_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { handleError } from "../../entity/ErrorHandler";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionReturnQuestionOption: InspectionReturnQuestionOptionType;
  className: string;
}

function InspectionReturnQuestionOption({
  classes,
  inspectionReturnQuestionOption,
  className,
}: Props) {
  const [
    inspectionReturnQuestionOptionEdited,
    setInspectionReturnQuestionOptionEdited,
  ] = useState(inspectionReturnQuestionOption);

  const [updateInspectionReturnQuestionOption, { loading: loadingUpdate }] =
    useMutation<Mutation, MutationUpdateInspectionReturnQuestionOptionArgs>(
      UPDATE_INSPECTION_RETURN_QUESTION_OPTION_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const [deleteInspectionReturnQuestionOption, { loading: loadingDelete }] =
    useMutation<Mutation, MutationDeleteInspectionReturnQuestionOptionArgs>(
      DELETE_INSPECTION_RETURN_QUESTION_OPTION_MUTATION,
      {
        refetchQueries: [{ query: GET_INSPECTION_RETURN_CATEGORIES_QUERY }],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "inspections.change_inspectionreturnquestionoption",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "inspections.delete_inspectionreturnquestionoption",
  ]);

  const handleUpdate = () => {
    updateInspectionReturnQuestionOption({
      variables: {
        inspectionReturnQuestionOptionId: inspectionReturnQuestionOption.id,
        title: inspectionReturnQuestionOptionEdited.title,
      },
    });
  };

  const onClickDelete = () => {
    deleteInspectionReturnQuestionOption({
      variables: {
        inspectionReturnQuestionOptionId: inspectionReturnQuestionOption.id,
      },
    });
  };

  return (
    <tr className={`${classes.rowOption} ${className}`}>
      <td colSpan={2} className={classes.cellOption}>
        <FormControl fullWidth>
          <TextField
            className={loadingUpdate ? "loading" : ""}
            value={inspectionReturnQuestionOptionEdited.title}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setInspectionReturnQuestionOptionEdited({
                ...inspectionReturnQuestionOptionEdited,
                title: event.target.value,
              });
            }}
            onBlur={() => {
              handleUpdate();
            }}
          />
        </FormControl>
      </td>
      <td></td>
      <td></td>
      <td>
        {hasPermissionDelete && (
          <ButtonLoad loading={loadingDelete} variant="light" size="sm">
            <FontAwesomeIcon icon={faTrash} onClick={onClickDelete} />
          </ButtonLoad>
        )}
      </td>
    </tr>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    rowOption: {
      "& $cellOption": {
        paddingLeft: spacing(8),
      },
    },
    cellOption: {
      // Need to have cellOption here even if empty
      // so that "& $cellOption" works
    },
  });

export default withStyles(styles)(InspectionReturnQuestionOption);
