import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Alert, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DrivingCreate from "../components/Drive/DrivingCreate";
import NavigationDriving from "../components/Drive/NavigationDriving";
import DrivingList from "../components/Drive/DrivingList";

interface Props extends WithStyles<typeof styles> {}

function WorkQueuesDriving({ classes }: Props) {
  const { t } = useTranslation();
  let tab: string;
  ({ tab } = useParams());

  const renderSwitch = (tab: string) => {
    switch (tab) {
      case "create":
        return <DrivingCreate />;
      case "list":
        return <DrivingList />;
      default:
        return <Alert variant="danger">{t("error_page_not_found")}</Alert>;
    }
  };

  return (
    <Container className="containerMain">
      <NavigationDriving tab={tab} />
      <div className="containerInner">{renderSwitch(tab)}</div>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(WorkQueuesDriving);
