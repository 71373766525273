import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { useQuery } from "@apollo/client";
import { createStyles, Theme } from "@material-ui/core";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  GET_CATALOG_TRANSFER_QUERY,
  QueryResultCatalogTransfer,
} from "../apollo/queries/catalogs_transfer";
import CatalogTransferLocation from "../components/CatalogTransfer/CatalogTransferLocation";
import CatalogTransferMethods from "../components/CatalogTransfer/CatalogTransferMethods";
import {
  GET_LOCATIONS_QUERY,
  QueryResultLocations,
} from "../apollo/queries/locations";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import HeaderPage from "../components/Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {}

function SettingsCatalogTransfer({ classes }: Props) {
  const { t } = useTranslation();

  const {
    loading: loadingTransfers,
    error: errorTransfers,
    data: dataTransfers,
  } = useQuery<QueryResultCatalogTransfer>(GET_CATALOG_TRANSFER_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalogTransferMethods"),
  });
  const {
    loading: loadingLocations,
    error: errorLocations,
    data: dataLocations,
  } = useQuery<QueryResultLocations>(GET_LOCATIONS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("locations"),
  });

  if (loadingTransfers || loadingLocations) return <Loading />;
  if (errorTransfers) return <Error error={errorTransfers} />;
  if (errorLocations) return <Error error={errorLocations} />;
  if (!dataTransfers || !dataLocations) {
    return <Error error={t("error_query_failed")} />;
  }

  return (
    <Container className="containerMain">
      <HeaderPage title={t("catalog_transfer")} />
      <div className="containerInner">
        <CatalogTransferMethods
          catalogTransferMethods={
            dataTransfers.catalogTransferMethods
              ? dataTransfers.catalogTransferMethods
              : []
          }
        />
        <h6 className="fw-bold mt-5 mb-4">{t("catalog_transfers")}</h6>
        <div>
          {dataLocations.locations?.map((location) => (
            <CatalogTransferLocation
              key={location.id}
              location={location}
              catalogTransferMethods={
                dataTransfers.catalogTransferMethods
                  ? dataTransfers.catalogTransferMethods
                  : []
              }
            />
          ))}
          <div className="clearfix"></div>
        </div>
      </div>
    </Container>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    table: {
      width: "auto",
    },
  });

export default withStyles(styles)(SettingsCatalogTransfer);
