import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props extends WithStyles<typeof styles> {
  timeline: Date[];
}

function CalendarTrMonth({ classes, timeline }: Props) {
  const { t } = useTranslation();

  let monthLast: boolean | string = false;

  return (
    <tr>
      <th className="calCellFirst"></th>
      {timeline.map((date, i) => {
        const monthLooped = String(date.getMonth() + 1);
        const printMonth = monthLast === false || monthLast !== monthLooped;
        monthLast = monthLooped;
        if (printMonth) {
          return (
            <th key={i} className={classes.thMonth}>
              <span className="position-absolute top-0 text-muted">
                {t("month_name_short_" + monthLooped)}
              </span>
            </th>
          );
        } else {
          return <th key={i} className={classes.thMonth}></th>;
        }
      })}
    </tr>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    thMonth: {
      height: "1.7rem",
      position: "relative",
      fontSize: "0.9rem",
    },
  });

export default withStyles(styles)(CalendarTrMonth);
