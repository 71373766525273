import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import {
  ME_QUERY,
  PROFILE_FOREIGNS_QUERY,
  PROFILE_QUERY,
  QueryResultMe,
  QueryResultProfile,
  QueryResultProfileForeigns,
} from "../apollo/queries/login";
import { useQuery } from "@apollo/client";
import { createStyles, Theme } from "@material-ui/core";
import { UserContext } from "../Root";
import ProfileView from "../components/Profile/ProfileView";
import { UserEmpty } from "../entity/empties";
import { useTranslation } from "react-i18next";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  match: any;
}

function Profile({ classes, match }: Props) {
  const { t } = useTranslation();

  const id = match.params.id;
  const currentUser = useContext(UserContext);

  const isMe = currentUser.id === id;
  const {
    loading: loadingMe,
    error: errorMe,
    data: dataMe,
  } = useQuery<QueryResultMe>(ME_QUERY, {
    fetchPolicy: getQueryFetchPolicy("me"),
    variables: { id: id },
    skip: !isMe,
  });
  const {
    loading: loadingProfile,
    error: errorProfile,
    data: dataProfile,
  } = useQuery<QueryResultProfile>(PROFILE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("user"),
    variables: { id: id },
    skip: isMe,
  });


  if (loadingMe || loadingProfile) return <Loading />;
  if (errorMe) return <Error error={errorMe} />;
  if (errorProfile) return <Error error={errorProfile} />;

  let user = UserEmpty;
  if (isMe && dataMe && dataMe.me) {
    user = dataMe.me;
  } else if (!isMe && dataProfile && dataProfile.user) {
    user = dataProfile.user;
  }
  var disabled = false;

  return (
    <ProfileView
      userSelected={user}
      groups={disabled ? []: []}
      locations={disabled ? []: []}
    />
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(Profile);
