import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import {
  CatalogExtraCategoryUpperRentalType,
  CatalogExtraRowRentalType,
  Mutation,
  MutationCreateCatalogExtraRowRentalArgs,
  MutationUpdateCatalogExtraRowRentalArgs,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { CatalogExtraRowRentalEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import {
  CREATE_CATALOG_EXTRA_ROW_RENTAL_MUTATION,
  UPDATE_CATALOG_EXTRA_ROW_RENTAL_MUTATION,
} from "../../apollo/mutations/catalogs_extra";
import DialogContentCatalogExtraRowRental from "./DialogContentCatalogExtraRowRental";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { parseNumber } from "../../utils/formatting";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalogExtraRowRental: CatalogExtraRowRentalType;
  catalogExtraCategoriesUpperRental: CatalogExtraCategoryUpperRentalType[];
}

function DialogCatalogExtraRowRental({
  classes,
  open,
  setOpen,
  catalogExtraRowRental,
  catalogExtraCategoriesUpperRental,
}: Props) {
  const { t } = useTranslation();

  const [catalogExtraRowRentalEdited, setCatalogExtraRowRentalEdited] =
    useState({
      ...catalogExtraRowRental,
    });

  /* If object is updated above, we want to mirror changes here */
  useEffect(() => {
    setCatalogExtraRowRentalEdited({
      ...catalogExtraRowRental,
    });
  }, [catalogExtraRowRental]);

  const [createCatalogExtraRowRental, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateCatalogExtraRowRentalArgs
  >(CREATE_CATALOG_EXTRA_ROW_RENTAL_MUTATION, {
    onCompleted: (result) => {
      setCatalogExtraRowRentalEdited(CatalogExtraRowRentalEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogExtraCategoriesUpperRental"),
      });
    },
  });
  const [updateCatalogExtraRowRental, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCatalogExtraRowRentalArgs
  >(UPDATE_CATALOG_EXTRA_ROW_RENTAL_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogExtraCategoriesUpperRental"),
      });
    },
  });

  const handleSubmitCatalogExtra = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (catalogExtraRowRentalEdited.id !== ID_EMPTY) {
      updateCatalogExtraRowRental({
        variables: {
          catalogExtraRowRentalId: catalogExtraRowRentalEdited.id,
          catalogExtraCategoryRentalId:
            catalogExtraRowRentalEdited.catalogExtraCategoryRental.id,
          locationId: catalogExtraRowRentalEdited.location.id,
          name: catalogExtraRowRentalEdited.name,
          productNumber: catalogExtraRowRentalEdited.productNumber,
          identifier: catalogExtraRowRentalEdited.identifier,
          information: catalogExtraRowRentalEdited.information,
          billingDaysWeekCompany:
            catalogExtraRowRentalEdited.billingDaysWeekCompany,
          billingDaysWeekPrivate:
            catalogExtraRowRentalEdited.billingDaysWeekPrivate,
          priceDayCompany: parseNumber(
            catalogExtraRowRentalEdited.priceDayCompany
          ),
          priceDayPrivate: parseNumber(
            catalogExtraRowRentalEdited.priceDayPrivate
          ),
          priceMonthCompany: parseNumber(
            catalogExtraRowRentalEdited.priceMonthCompany
          ),
          priceMonthPrivate: parseNumber(
            catalogExtraRowRentalEdited.priceMonthPrivate
          ),
          showPrint: catalogExtraRowRentalEdited.showPrint,
        },
      });
    } else {
      createCatalogExtraRowRental({
        variables: {
          catalogExtraCategoryRentalId:
            catalogExtraRowRentalEdited.catalogExtraCategoryRental.id,
          locationId: catalogExtraRowRentalEdited.location.id,
          name: catalogExtraRowRentalEdited.name,
          productNumber: catalogExtraRowRentalEdited.productNumber,
          identifier: catalogExtraRowRentalEdited.identifier,
          information: catalogExtraRowRentalEdited.information,
          billingDaysWeekCompany:
            catalogExtraRowRentalEdited.billingDaysWeekCompany,
          billingDaysWeekPrivate:
            catalogExtraRowRentalEdited.billingDaysWeekPrivate,
          priceDayCompany: parseNumber(
            catalogExtraRowRentalEdited.priceDayCompany
          ),
          priceDayPrivate: parseNumber(
            catalogExtraRowRentalEdited.priceDayPrivate
          ),
          priceMonthCompany: parseNumber(
            catalogExtraRowRentalEdited.priceMonthCompany
          ),
          priceMonthPrivate: parseNumber(
            catalogExtraRowRentalEdited.priceMonthPrivate
          ),
          showPrint: catalogExtraRowRentalEdited.showPrint,
        },
      });
    }
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <form
        className={loadingCreate || loadingUpdate ? "loading" : ""}
        onSubmit={(event) => handleSubmitCatalogExtra(event)}
      >
        <DialogTitle>
          {t(
            catalogExtraRowRentalEdited.id !== ID_EMPTY
              ? "edit_catalog_extra_row"
              : "new_catalog_extra_row"
          )}
        </DialogTitle>
        <DialogContentCatalogExtraRowRental
          catalogExtraRowRental={catalogExtraRowRentalEdited}
          setCatalogExtraRowRental={setCatalogExtraRowRentalEdited}
          catalogExtraCategoriesUpperRental={catalogExtraCategoriesUpperRental}
        />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <Button
            disabled={
              !catalogExtraRowRentalEdited.name.trim() ||
              !catalogExtraRowRentalEdited.catalogExtraCategoryRental.id
            }
            type="submit"
            variant="primary"
          >
            {t("save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCatalogExtraRowRental);
