import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Theme,
} from "@material-ui/core";
import {
  CatalogExtraRowUnitPriceType,
  Mutation,
  MutationCopyCatalogExtraRowUnitPriceMachinesArgs,
  QueryCatalogExtraRowsUnitPriceArgs,
} from "../../entity/types";
import { useMutation, useQuery } from "@apollo/client";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { handleError } from "../../entity/ErrorHandler";
import {
  GET_CATALOG_EXTRA_ROWS_UNIT_PRICE_QUERY,
  QueryResultCatalogExtraRowsUnitPrice,
} from "../../apollo/queries/catalogs_extra";
import { COPY_CATALOG_EXTRA_ROW_UNIT_PRICE_MACHINES_MUTATION } from "../../apollo/mutations/catalogs_extra";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  catalogExtraRowUnitPrice: CatalogExtraRowUnitPriceType;
}

function CatalogExtraRowUnitPriceMachineCopy({
  classes,
  catalogExtraRowUnitPrice,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultCatalogExtraRowsUnitPrice,
    QueryCatalogExtraRowsUnitPriceArgs
  >(GET_CATALOG_EXTRA_ROWS_UNIT_PRICE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalogExtraRowsUnitPrice"),
  });

  const [copyRows, { loading: loadingCopy }] = useMutation<
    Mutation,
    MutationCopyCatalogExtraRowUnitPriceMachinesArgs
  >(COPY_CATALOG_EXTRA_ROW_UNIT_PRICE_MACHINES_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("machines"),
      });
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const catalogExtraRowsUnitPrice: CatalogExtraRowUnitPriceType[] = (
    data.catalogExtraRowsUnitPrice ? data.catalogExtraRowsUnitPrice : []
  ).filter(
    (catalogExtraRowUnitPriceLooped) =>
      catalogExtraRowUnitPriceLooped.id !== catalogExtraRowUnitPrice.id
  );

  const onChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const catalogExtraRowUnitPriceIdCopyFrom = String(event.target.value);
    if (catalogExtraRowUnitPriceIdCopyFrom !== "") {
      copyRows({
        variables: {
          catalogExtraRowUnitPriceIdCopyFrom:
            catalogExtraRowUnitPriceIdCopyFrom,
          catalogExtraRowUnitPriceIdCopyTo: catalogExtraRowUnitPrice.id,
        },
      });
    }
  };

  return (
    <Paper className={`p-3 mb-2 ${loadingCopy ? "loading" : ""}`}>
      <FormControl fullWidth>
        <InputLabel id="lblMachineCatalogExtraUnitPriceCopy">
          {t("catalog_extra_copy_machines")}
        </InputLabel>
        <Select
          value=""
          labelId="lblMachineCatalogExtraUnitPriceCopy"
          onChange={onChange}
        >
          {catalogExtraRowsUnitPrice.map((catalogExtraRowUnitPriceLooped) => (
            <MenuItem
              key={catalogExtraRowUnitPriceLooped.id}
              value={catalogExtraRowUnitPriceLooped.id}
            >
              {catalogExtraRowUnitPriceLooped.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CatalogExtraRowUnitPriceMachineCopy);
