import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Theme,
} from "@material-ui/core";
import {
  CatalogExtraRowRentalType,
  Mutation,
  MutationCopyCatalogExtraRowRentalMachinesArgs,
} from "../../entity/types";
import { useMutation, useQuery } from "@apollo/client";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { handleError } from "../../entity/ErrorHandler";
import {
  GET_CATALOG_EXTRA_ROWS_RENTAL_QUERY,
  QueryResultCatalogExtraRowsRental,
} from "../../apollo/queries/catalogs_extra";
import { COPY_CATALOG_EXTRA_ROW_RENTAL_MACHINES_MUTATION } from "../../apollo/mutations/catalogs_extra";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  catalogExtraRowRental: CatalogExtraRowRentalType;
}

function CatalogExtraRowRentalMachineCopy({
  classes,
  catalogExtraRowRental,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<QueryResultCatalogExtraRowsRental>(
    GET_CATALOG_EXTRA_ROWS_RENTAL_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("catalogExtraRowsRental"),
    }
  );

  const [copyRows, { loading: loadingCopy }] = useMutation<
    Mutation,
    MutationCopyCatalogExtraRowRentalMachinesArgs
  >(COPY_CATALOG_EXTRA_ROW_RENTAL_MACHINES_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("machines"),
      });
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const catalogExtraRowsRental: CatalogExtraRowRentalType[] = (
    data.catalogExtraRowsRental ? data.catalogExtraRowsRental : []
  ).filter(
    (catalogExtraRowRentalLooped) =>
      catalogExtraRowRentalLooped.id !== catalogExtraRowRental.id
  );

  const onChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const catalogExtraRowRentalIdCopyFrom = String(event.target.value);
    if (catalogExtraRowRentalIdCopyFrom !== "") {
      copyRows({
        variables: {
          catalogExtraRowRentalIdCopyFrom: catalogExtraRowRentalIdCopyFrom,
          catalogExtraRowRentalIdCopyTo: catalogExtraRowRental.id,
        },
      });
    }
  };

  return (
    <Paper className={`p-3 mb-2 ${loadingCopy ? "loading" : ""}`}>
      <FormControl fullWidth>
        <InputLabel id="lblMachineCatalogExtraRentalCopy">
          {t("catalog_extra_copy_machines")}
        </InputLabel>
        <Select
          value=""
          labelId="lblMachineCatalogExtraRentalCopy"
          onChange={onChange}
        >
          {catalogExtraRowsRental.map((catalogExtraRowRentalLooped) => (
            <MenuItem
              key={catalogExtraRowRentalLooped.id}
              value={catalogExtraRowRentalLooped.id}
            >
              {catalogExtraRowRentalLooped.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CatalogExtraRowRentalMachineCopy);
