import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { MachineBulkAmountType, MachineType } from "../../entity/types";
import { useQuery } from "@apollo/client";
import {
  GET_LOCATIONS_QUERY,
  QueryResultLocations,
} from "../../apollo/queries/locations";
import Error from "../Shared/Error";
import LoadingSimple from "../Shared/LoadingSimple";
import { Table } from "react-bootstrap";
import { MachineBulkAmountEmpty } from "../../entity/empties";
import MachineBulkAmountTr from "./MachineBulkAmountTr";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
  setMachine: React.Dispatch<React.SetStateAction<MachineType>>;
}

function MachineBulkAmount({ classes, machine, setMachine }: Props) {
  const { t } = useTranslation();

  const {
    loading: loadingLocations,
    error: errorLocations,
    data: dataLocations,
  } = useQuery<QueryResultLocations>(GET_LOCATIONS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("locations"),
  });

  if (loadingLocations) return <LoadingSimple />;
  if (errorLocations) return <Error error={errorLocations} />;
  if (!dataLocations) return <Error error={t("error_query_failed")} />;

  return (
    <Table borderless size="sm">
      <tbody>
        {dataLocations.locations?.map((location) => {
          const machineBulkAmount: MachineBulkAmountType =
            machine.machinebulkamountSet.find(
              (machineBulkAmount) =>
                machineBulkAmount.location.id === location.id
            ) || MachineBulkAmountEmpty;
          return (
            <MachineBulkAmountTr
              key={location.id}
              machine={machine}
              setMachine={setMachine}
              location={location}
              machineBulkAmount={machineBulkAmount}
            />
          );
        })}
      </tbody>
    </Table>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MachineBulkAmount);
