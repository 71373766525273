import { gql } from "@apollo/client";
import { FRAG_RESERVATION_WORK_QUEUE } from "./reservations";
import { Query } from "../../entity/types";

export interface QueryResultDrives {
  drives: Query["drives"];
}
export const GET_DRIVES_QUERY = gql`
  query getDrivesQuery($locationIds: [ID]!, $dateFrom: Date!, $dateTo: Date!) {
    drives(locationIds: $locationIds, dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      createdBy {
        id
        firstName
        lastName
      }
      createdAt
      drivecontentSet {
        id
        transport {
          id
          method
          address
          postcode
          district
          dateDeadline
          timeDeadline
        }
        reservation {
          id
          catalogExtraRowRental {
            id
            identifier
            name
          }
          machine {
            id
            identifier
            machineModel {
              id
              title
              machineMake {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;

export interface QueryResultDrivesForWorkQueues {
  drivesForWorkQueues: Query["drivesForWorkQueues"];
}
export const GET_DRIVES_FOR_WORK_QUEUES_QUERY = gql`
  ${FRAG_RESERVATION_WORK_QUEUE}
  query getDrivesForWorkQueuesQuery($locationIds: [ID]!, $userId: ID) {
    drivesForWorkQueues(locationIds: $locationIds, userId: $userId) {
      id
      createdAt
      drivecontentSet {
        id
        transport {
          id
          method
          address
          postcode
          district
          dateDeadline
          timeDeadline
          information
          dateEndUsage
          timeEndUsage
        }
        reservation {
          ...ReservationWorkQueueFields
        }
      }
    }
  }
`;
