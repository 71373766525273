import { gql } from "@apollo/client";

export const CREATE_EVENT_MUTATION = gql`
  mutation createEvent(
    $eventType: String!
    $date: Date!
    $information: String!
    $customerId: ID!
  ) {
    createEvent(
      eventType: $eventType
      date: $date
      information: $information
      customerId: $customerId
    ) {
      event {
        id
      }
    }
  }
`;

export const UPDATE_EVENT_MUTATION = gql`
  mutation updateEvent(
    $eventId: ID!
    $eventType: String!
    $date: Date!
    $information: String!
    $customerId: ID!
  ) {
    updateEvent(
      eventId: $eventId
      eventType: $eventType
      date: $date
      information: $information
      customerId: $customerId
    ) {
      event {
        id
        eventType
        date
        information
        customer {
          id
        }
      }
    }
  }
`;

export const DELETE_EVENT_MUTATION = gql`
  mutation deleteEvent($eventId: ID!) {
    deleteEvent(eventId: $eventId) {
      eventId
    }
  }
`;
