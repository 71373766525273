import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { Button, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import {
  URL_CUSTOMERS,
  URL_WORK_QUEUES_DRIVING_CREATE,
  URL_WORK_QUEUES_DRIVING_LIST,
} from "../../utils/urls";

interface Props extends WithStyles<typeof styles> {
  tab: String;
}

const NavigationCustomer = ({ classes, tab }: Props) => {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionViewList = checkPermission(myPermissions, [
    "drives.view_drive",
    "drives.view_drivecontent",
  ]);

  return (
    <Navbar bg="light" expand="md" className="mb-3">
      <LinkContainer to={URL_CUSTOMERS}>
        <Button variant="outline-light">
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
      </LinkContainer>
      <Navbar.Brand>{t("work_queues_driving")}</Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarDriving" />
      <Navbar.Collapse className={classes.navbarDriving} id="navbarDriving">
        <Nav className="me-auto">
          <LinkContainer to={URL_WORK_QUEUES_DRIVING_CREATE}>
            <Nav.Link>{t("work_queues_driving_create")}</Nav.Link>
          </LinkContainer>
          {hasPermissionViewList && (
            <LinkContainer to={URL_WORK_QUEUES_DRIVING_LIST}>
              <Nav.Link>{t("work_queues_driving_list")}</Nav.Link>
            </LinkContainer>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const styles = ({ breakpoints, spacing }: Theme) =>
  createStyles({
    navbarDriving: {
      marginLeft: spacing(4),
    },
  });

export default withStyles(styles)(NavigationCustomer);
