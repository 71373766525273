import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import {
  CommissionRangeType,
  Mutation,
  MutationDeleteCommissionRangeArgs,
  MutationUpdateCommissionRangeArgs,
} from "../../entity/types";
import ButtonLoad from "../Shared/ButtonLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import {
  DELETE_COMMISSION_RANGE_MUTATION,
  UPDATE_COMMISSION_RANGE_MUTATION,
} from "../../apollo/mutations/commissions";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { useTranslation } from "react-i18next";
import { dialogConfirm } from "../../utils/dialogs";
import { parseNumber } from "../../utils/formatting";
import { WithStyles } from "@material-ui/core/styles";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { INT_UNSIGNED_VALUE_MAX, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import TextFieldFocus from "../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {
  commissionRange: CommissionRangeType;
  commissionRangePrev: CommissionRangeType | null;
  isLast: boolean;
}

function CommissionRangeRow({
  classes,
  commissionRange,
  commissionRangePrev,
  isLast,
}: Props) {
  const { t } = useTranslation();

  const [commissionRangeEdit, setCommissionRangeEdit] =
    useState(commissionRange);

  const [updateCommissionRange, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCommissionRangeArgs
  >(UPDATE_COMMISSION_RANGE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("commissionTemplates"),
      });
    },
  });

  const [deleteCommissionRange, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteCommissionRangeArgs
  >(DELETE_COMMISSION_RANGE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("commissionTemplates"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionCommissionRangeChange = checkPermission(myPermissions, [
    "commissions.change_commissionrange",
  ]);
  const hasPermissionCommissionRangeDelete = checkPermission(myPermissions, [
    "commissions.delete_commissionrange",
  ]);

  const handleUpdate = (values: { [key: string]: string | number } = {}) => {
    const base: MutationUpdateCommissionRangeArgs = {
      commissionRangeId: commissionRangeEdit.id,
      daysMax: commissionRangeEdit.daysMax,
    };

    updateCommissionRange({
      variables: { ...base, ...values },
    });
  };

  const onClickDeleteCommissionRange = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCommissionRange({
        variables: {
          commissionRangeId: commissionRangeEdit.id,
        },
      });
    });
  };

  return (
    <tr className={loadingUpdate ? "loading" : ""}>
      <td>
        <FormControl>
          <TextField
            value={commissionRangePrev ? commissionRangePrev.daysMax : ""}
            disabled={true}
          />
        </FormControl>
      </td>
      <td>
        {isLast && (
          <span className="text-muted">
            {t("last_commission_range_row", {
              value: commissionRangeEdit.daysMax,
            })}
          </span>
        )}
        {!isLast && (
          <FormControl>
            <TextFieldFocus
              type="number"
              value={commissionRangeEdit.daysMax}
              disabled={!hasPermissionCommissionRangeChange}
              onChange={(event) => {
                setCommissionRangeEdit({
                  ...commissionRangeEdit,
                  daysMax: parseNumber(event.target.value),
                });
              }}
              onBlur={() => handleUpdate()}
              inputProps={{
                min: 1,
                max: INT_UNSIGNED_VALUE_MAX,
              }}
            />
          </FormControl>
        )}
      </td>
      <td>
        {hasPermissionCommissionRangeDelete && (
          <ButtonLoad
            loading={loadingDelete}
            variant="light"
            size="sm"
            onClick={onClickDeleteCommissionRange}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonLoad>
        )}
      </td>
    </tr>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CommissionRangeRow);
