import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import {
  GET_MACHINE_INFO_CATEGORIES_QUERY,
  QueryResultMachineInfoCategories,
} from "../apollo/queries/machines";
import { useQuery } from "@apollo/client";
import { createStyles, Theme } from "@material-ui/core";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CreateMachineInfoCategory from "../components/Machine/CreateMachineInfoCategory";
import MachineInformationCategory from "../components/Machine/MachineInformationCategory";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import HeaderPage from "../components/Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {}

function SettingsMachineInformation({ classes }: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<QueryResultMachineInfoCategories>(
    GET_MACHINE_INFO_CATEGORIES_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("machineInfoCategories"),
    }
  );

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <Container className="containerMain">
      <HeaderPage title={t("machine_information")} />
      <div className="containerInner">
        {data.machineInfoCategories?.map((machineInfoCategory) => (
          <MachineInformationCategory
            key={machineInfoCategory.id}
            machineInfoCategory={machineInfoCategory}
            machineInfoTags={data.machineInfoTags ? data.machineInfoTags : []}
          />
        ))}
      </div>
      <CreateMachineInfoCategory />
    </Container>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    headerCategory: {
      color: palette.primary.main,
      fontSize: "1.2rem",
    },
  });

export default withStyles(styles)(SettingsMachineInformation);
