import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  CatalogCategoryUpperType,
  CatalogRowType,
  CatalogType,
  Mutation,
  MutationDeleteCatalogRowArgs,
  MutationUpdateCatalogRowArgs,
  MutationUpdateCatalogRowLockedArgs,
  MutationUpdateCatalogRowSourceArgs,
} from "../../../entity/types";
import { PermissionsContext, SettingsContext } from "../../../Root";
import { formatNumber, parseNumber } from "../../../utils/formatting";
import { Button } from "react-bootstrap";
import { faEdit, faPaperclip, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";
import { handleError } from "../../../entity/ErrorHandler";
import {
  DELETE_CATALOG_ROW_MUTATION,
  UPDATE_CATALOG_ROW_LOCKED_MUTATION,
  UPDATE_CATALOG_ROW_MUTATION,
  UPDATE_CATALOG_ROW_SOURCE_MUTATION,
} from "../../../apollo/mutations/catalogs";
import { GET_CATALOG_WITH_SOURCE_QUERY } from "../../../apollo/queries/catalogs";
import DialogCatalogRow from "../DialogCatalogRow";
import DialogCatalogRowUpload from "../DialogCatalogRowUpload";
import { checkPermission } from "../../../utils/permissions";
import { dialogConfirm } from "../../../utils/dialogs";
import { COLOR_MUTED, ID_EMPTY } from "../../../utils/constants";
import { isLocked } from "../../../utils/catalogs/locked";
import { renderSelectGroupCategoryRow } from "../../../utils/catalogs/select_group";
import {
  updateCacheCatalogEdit,
  updateCacheCatalogRowEdit,
} from "../../../utils/cache";
import TextFieldFocus from "../../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
  catalogRow: CatalogRowType;
  catalogCategoriesUpper: CatalogCategoryUpperType[];
  DragHandle: any;
}

function CatalogRow({
  classes,
  catalog,
  catalogRow,
  catalogCategoriesUpper,
  DragHandle,
}: Props) {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);

  const [updateCatalogRow, { loading: loadingUpdateRow }] = useMutation<
    Mutation,
    MutationUpdateCatalogRowArgs
  >(UPDATE_CATALOG_ROW_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheCatalogRowEdit(cache);
    },
  });
  const [updateCatalogRowLocked, { loading: loadingUpdateRowLocked }] =
    useMutation<Mutation, MutationUpdateCatalogRowLockedArgs>(
      UPDATE_CATALOG_ROW_LOCKED_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
      }
    );
  const [updateCatalogRowSource, { loading: loadingUpdateRowSource }] =
    useMutation<Mutation, MutationUpdateCatalogRowSourceArgs>(
      UPDATE_CATALOG_ROW_SOURCE_MUTATION,
      {
        refetchQueries: [
          {
            query: GET_CATALOG_WITH_SOURCE_QUERY,
            variables: {
              catalogId: catalog.id,
            },
          },
        ],
        onError: (error) => {
          handleError(error);
        },
      }
    );
  const [deleteCatalogRow, { loading: loadingDeleteRow }] = useMutation<
    Mutation,
    MutationDeleteCatalogRowArgs
  >(DELETE_CATALOG_ROW_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheCatalogEdit(cache);
    },
  });

  const [openDialogCatalogRow, setOpenDialogCatalogRow] = useState(false);
  const [openDialogCatalogRowUpload, setOpenDialogCatalogRowUpload] =
    useState(false);

  const [name, setName] = useState<string>(catalogRow.name);
  const [priceDayPrivate, setPriceDayPrivate] = useState<string>(
    formatNumber(catalogRow.priceDayPrivate, 2)
  );
  const [priceDayPrivateVat, setPriceDayPrivateVat] = useState<string>(
    formatNumber(catalogRow.priceDayPrivate * settings.vatFactor, 2)
  );
  const [priceMonthPrivate, setPriceMonthPrivate] = useState<string>(
    formatNumber(catalogRow.priceMonthPrivate, 2)
  );
  const [priceMonthPrivateVat, setPriceMonthPrivateVat] = useState<string>(
    formatNumber(Number(catalogRow.priceMonthPrivate) * settings.vatFactor, 2)
  );
  const [priceDayCompany, setPriceDayCompany] = useState<string>(
    formatNumber(catalogRow.priceDayCompany, 2)
  );
  const [priceDayCompanyVat, setPriceDayCompanyVat] = useState<string>(
    formatNumber(catalogRow.priceDayCompany * settings.vatFactor, 2)
  );
  const [priceMonthCompany, setPriceMonthCompany] = useState<string>(
    formatNumber(catalogRow.priceMonthCompany, 2)
  );
  const [priceMonthCompanyVat, setPriceMonthCompanyVat] = useState<string>(
    formatNumber(Number(catalogRow.priceMonthCompany) * settings.vatFactor, 2)
  );
  const [billingDaysWeekPrivate, setBillingDaysWeekPrivate] = useState<number>(
    catalogRow.billingDaysWeekPrivate
  );
  const [billingDaysWeekCompany, setBillingDaysWeekCompany] = useState<number>(
    catalogRow.billingDaysWeekCompany
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "catalogs.delete_catalogrow",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs.change_catalogrow",
  ]);

  const lockedCatalog = isLocked(catalog);

  const handleUpdateCatalogRow = (values: {
    [key: string]: string | number;
  }) => {
    if (lockedCatalog) {
      handleUpdateCatalogRowLocked(values);
    } else {
      handleUpdateCatalogRowNotLocked(values);
    }
  };

  const handleUpdateCatalogRowLocked = (values: {
    [key: string]: string | number;
  }) => {
    const base: MutationUpdateCatalogRowLockedArgs = {
      catalogRowId: catalogRow.id,
      name: catalogRow.name,
      information: catalogRow.information,
    };

    let changed = false;
    for (let key in values) {
      if (
        values[key] !== base[key as keyof MutationUpdateCatalogRowLockedArgs]
      ) {
        changed = true;
      }
    }

    if (changed) {
      const options = {
        variables: { ...base, ...values },
      };

      updateCatalogRowLocked(options);
    }
  };

  const handleUpdateCatalogRowNotLocked = (values: {
    [key: string]: string | number;
  }) => {
    const base: MutationUpdateCatalogRowArgs = {
      catalogRowId: catalogRow.id,
      name: catalogRow.name,
      catalogCategoryId: catalogRow.catalogCategory
        ? catalogRow.catalogCategory.id
        : ID_EMPTY,
      information: catalogRow.information,
      billingDaysWeekCompany: catalogRow.billingDaysWeekCompany,
      priceDayCompany: catalogRow.priceDayCompany,
      priceMonthCompany: catalogRow.priceMonthCompany,
      billingDaysWeekPrivate: catalogRow.billingDaysWeekPrivate,
      priceDayPrivate: catalogRow.priceDayPrivate,
      priceMonthPrivate: catalogRow.priceMonthPrivate,
      hourLimitDayCompany: catalogRow.hourLimitDayCompany,
      hourLimitDayPrivate: catalogRow.hourLimitDayPrivate,
      hourLimitMonthCompany: catalogRow.hourLimitMonthCompany,
      hourLimitMonthPrivate: catalogRow.hourLimitMonthPrivate,
      extraHoursCompany: catalogRow.extraHoursCompany,
      extraHoursPrivate: catalogRow.extraHoursPrivate,
    };

    let changed = false;
    for (let key in values) {
      if (values[key] !== base[key as keyof MutationUpdateCatalogRowArgs]) {
        changed = true;
      }
    }

    if (changed) {
      const options = {
        variables: { ...base, ...values },
      };

      updateCatalogRow(options);
    }
  };

  const handleClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCatalogRow({ variables: { catalogRowId: catalogRow.id } });
    });
  };
  const handleClickInfo = () => {
    setOpenDialogCatalogRow(true);
  };
  const handleClickUpload = () => {
    setOpenDialogCatalogRowUpload(true);
  };

  const getTitleRowEdit = () => {
    return (
      catalogRow.name +
      "\n\n" +
      t("hour_limit_day_company") +
      "\t" +
      catalogRow.hourLimitDayCompany +
      "\n" +
      t("hour_limit_day_private") +
      "\t" +
      catalogRow.hourLimitDayPrivate +
      "\n" +
      t("hour_limit_month_company") +
      "\t" +
      catalogRow.hourLimitMonthCompany +
      "\n" +
      t("hour_limit_month_private") +
      "\t" +
      catalogRow.hourLimitMonthPrivate +
      "\n" +
      t("extra_hours_company") +
      "\t" +
      catalogRow.extraHoursCompany +
      "\n" +
      t("extra_hours_private") +
      "\t" +
      catalogRow.extraHoursPrivate +
      (catalogRow.information
        ? "\n\n" + t("information") + "\n" + catalogRow.information
        : "")
    );
  };

  const onChangeRowSource = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    updateCatalogRowSource({
      variables: {
        catalogRowId: catalogRow.id,
        catalogRowIdSource:
          event.target.value === "" ? undefined : String(event.target.value),
      },
    });
  };

  return (
    <tr
      className={`hideOnCategorySort ${
        loadingUpdateRow || loadingUpdateRowLocked || loadingDeleteRow
          ? "loading"
          : ""
      }`}
    >
      {hasPermissionEdit ? <DragHandle /> : <td></td>}
      <td>
        <FormControl fullWidth>
          <TextField
            value={name}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setName(event.target.value);
            }}
            onBlur={() => {
              handleUpdateCatalogRow({ name: name });
            }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={priceDayCompany}
            disabled={!hasPermissionEdit || lockedCatalog}
            onChange={(event) => {
              setPriceDayCompany(event.target.value);
              setPriceDayCompanyVat(
                formatNumber(
                  parseNumber(event.target.value) * settings.vatFactor,
                  2
                )
              );
            }}
            onBlur={() => {
              setPriceDayCompany(formatNumber(priceDayCompany, 2));
              handleUpdateCatalogRow({
                priceDayCompany: parseNumber(priceDayCompany).toFixed(2),
              });
            }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={priceDayCompanyVat}
            disabled={!hasPermissionEdit || lockedCatalog}
            onChange={(event) => {
              setPriceDayCompanyVat(event.target.value);
              setPriceDayCompany(
                formatNumber(
                  parseNumber(event.target.value) / settings.vatFactor,
                  2
                )
              );
            }}
            onBlur={() => {
              setPriceDayCompanyVat(formatNumber(priceDayCompanyVat, 2));
              handleUpdateCatalogRow({
                priceDayCompany: (
                  parseNumber(priceDayCompanyVat) / settings.vatFactor
                ).toFixed(2),
              });
            }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={priceMonthCompany}
            disabled={!hasPermissionEdit || lockedCatalog}
            onChange={(event) => {
              setPriceMonthCompany(event.target.value);
              setPriceMonthCompanyVat(
                formatNumber(
                  parseNumber(event.target.value) * settings.vatFactor,
                  2
                )
              );
            }}
            onBlur={() => {
              setPriceMonthCompany(formatNumber(priceMonthCompany, 2));
              handleUpdateCatalogRow({
                priceMonthCompany: parseNumber(priceMonthCompany).toFixed(2),
              });
            }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={priceMonthCompanyVat}
            disabled={!hasPermissionEdit || lockedCatalog}
            onChange={(event) => {
              setPriceMonthCompanyVat(event.target.value);
              setPriceMonthCompany(
                formatNumber(
                  parseNumber(event.target.value) / settings.vatFactor,
                  2
                )
              );
            }}
            onBlur={() => {
              setPriceMonthCompanyVat(formatNumber(priceMonthCompanyVat, 2));
              handleUpdateCatalogRow({
                priceMonthCompany: (
                  parseNumber(priceMonthCompanyVat) / settings.vatFactor
                ).toFixed(2),
              });
            }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={billingDaysWeekCompany}
            disabled={!hasPermissionEdit || lockedCatalog}
            onChange={(event) => {
              setBillingDaysWeekCompany(parseInt(event.target.value));
            }}
            onBlur={() => {
              handleUpdateCatalogRow({
                billingDaysWeekCompany: billingDaysWeekCompany,
              });
            }}
          />
        </FormControl>
      </td>
      <td></td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={priceDayPrivate}
            disabled={!hasPermissionEdit || lockedCatalog}
            onChange={(event) => {
              setPriceDayPrivate(event.target.value);
              setPriceDayPrivateVat(
                formatNumber(
                  parseNumber(event.target.value) * settings.vatFactor,
                  2
                )
              );
            }}
            onBlur={() => {
              setPriceDayPrivate(formatNumber(priceDayPrivate, 2));
              handleUpdateCatalogRow({
                priceDayPrivate: parseNumber(priceDayPrivate).toFixed(2),
              });
            }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={priceDayPrivateVat}
            disabled={!hasPermissionEdit || lockedCatalog}
            onChange={(event) => {
              setPriceDayPrivateVat(event.target.value);
              setPriceDayPrivate(
                formatNumber(
                  parseNumber(event.target.value) / settings.vatFactor,
                  2
                )
              );
            }}
            onBlur={() => {
              setPriceDayPrivateVat(formatNumber(priceDayPrivateVat, 2));
              handleUpdateCatalogRow({
                priceDayPrivate: (
                  parseNumber(priceDayPrivateVat) / settings.vatFactor
                ).toFixed(2),
              });
            }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={priceMonthPrivate}
            disabled={!hasPermissionEdit || lockedCatalog}
            onChange={(event) => {
              setPriceMonthPrivate(event.target.value);
              setPriceMonthPrivateVat(
                formatNumber(
                  parseNumber(event.target.value) * settings.vatFactor,
                  2
                )
              );
            }}
            onBlur={() => {
              setPriceMonthPrivate(formatNumber(priceMonthPrivate, 2));
              handleUpdateCatalogRow({
                priceMonthPrivate: parseNumber(priceMonthPrivate).toFixed(2),
              });
            }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={priceMonthPrivateVat}
            disabled={!hasPermissionEdit || lockedCatalog}
            onChange={(event) => {
              setPriceMonthPrivateVat(event.target.value);
              setPriceMonthPrivate(
                formatNumber(
                  parseNumber(event.target.value) / settings.vatFactor,
                  2
                )
              );
            }}
            onBlur={() => {
              setPriceMonthPrivateVat(formatNumber(priceMonthPrivateVat, 2));
              handleUpdateCatalogRow({
                priceMonthPrivate: (
                  parseNumber(priceMonthPrivateVat) / settings.vatFactor
                ).toFixed(2),
              });
            }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={billingDaysWeekPrivate}
            disabled={!hasPermissionEdit || lockedCatalog}
            onChange={(event) => {
              setBillingDaysWeekPrivate(parseInt(event.target.value));
            }}
            onBlur={() => {
              handleUpdateCatalogRow({
                billingDaysWeekPrivate: billingDaysWeekPrivate,
              });
            }}
          />
        </FormControl>
      </td>
      {!lockedCatalog && (
        <td>
          <FormControl fullWidth>
            <Select
              className={loadingUpdateRowSource ? "loading" : ""}
              value={
                catalogRow.catalogRowSource &&
                catalogRow.catalogRowSource.id !== ID_EMPTY
                  ? catalogRow.catalogRowSource.id
                  : ""
              }
              disabled={!hasPermissionEdit || lockedCatalog}
              onChange={onChangeRowSource}
            >
              <MenuItem value="">{t("no_source_use_catalog_price")}</MenuItem>
              {catalog.catalogSource?.catalogcategoryupperSet.map(
                (catalogCategoryUpper) =>
                  renderSelectGroupCategoryRow(catalogCategoryUpper)
              )}
            </Select>
          </FormControl>
        </td>
      )}
      <td>
        {hasPermissionEdit && (
          <Button
            variant="light"
            size="sm"
            className={`${classes.btn}`}
            onClick={handleClickInfo}
            title={getTitleRowEdit()}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        )}
        {hasPermissionEdit && (
          <Button
            variant="light"
            size="sm"
            className={`${classes.btn} ${
              catalogRow.image ? classes.btnHasData : classes.btnNoData
            }`}
            onClick={handleClickUpload}
          >
            <FontAwesomeIcon icon={faPaperclip} />
          </Button>
        )}
        {hasPermissionDelete && (
          <Button
            variant="light"
            size="sm"
            onClick={handleClickDelete}
            className={classes.btn}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
        <DialogCatalogRow
          open={openDialogCatalogRow}
          setOpen={setOpenDialogCatalogRow}
          catalogCategoriesUpper={catalogCategoriesUpper}
          catalogRowOrig={catalogRow}
          setName={setName}
          catalog={catalog}
        />
        <DialogCatalogRowUpload
          open={openDialogCatalogRowUpload}
          setOpen={setOpenDialogCatalogRowUpload}
          catalogRow={catalogRow}
        />
      </td>
    </tr>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    btn: {
      marginRight: spacing(0.5),
    },
    btnHasData: {},
    btnNoData: {
      color: COLOR_MUTED,
    },
  });

export default withStyles(styles)(CatalogRow);
