import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { CustomerType } from "../../entity/types";
import { Table } from "react-bootstrap";
import CustomerShowBasicFamilyRow from "./CustomerShowBasicFamilyRow";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
}

const CustomerShowBasicFamily = ({ classes, customer }: Props) => {
  let customerPrimary = customer.customerParent
    ? customer.customerParent
    : customer;

  return (
    <div className={classes.conCustomerFamily}>
      <Table hover>
        <tbody>
          {customerPrimary && (
            <CustomerShowBasicFamilyRow
              customer={customerPrimary}
              customerSelected={customer}
              isFamilyHead={true}
            />
          )}
          {customerPrimary.customersChildren.map(
            (customerChild: CustomerType) => (
              <CustomerShowBasicFamilyRow
                key={customerChild.id}
                customer={customerChild}
                customerSelected={customer}
                isFamilyHead={false}
              />
            )
          )}
        </tbody>
      </Table>
    </div>
  );
};

const styles = ({ spacing }: Theme) =>
  createStyles({
    conCustomerFamily: {
      marginTop: spacing(3),
    },
  });

export default withStyles(styles)(CustomerShowBasicFamily);
