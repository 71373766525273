import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export const FRAG_INVOICE_SHARED = gql`
  fragment InvoiceFields on InvoiceType {
    id
    information
    sentAt
    isAdvance
    reference {
      id
      name
      customer {
        id
        name
        invoicingEmail
        invoicingEAddress
        invoicingAddress
      }
    }
    invoicerowSet {
      id
      productNumber
      title
      description
      quantity
      unitPrice
      price
      vat
      rowType
    }
  }
`;

export interface QueryResultInvoiceableRows {
  invoiceableRows: Query["invoiceableRows"];
}
export const GET_INVOICEABLE_ROWS_QUERY = gql`
  query getInvoiceableRowsQuery(
    $orderId: ID
    $customerId: ID
    $isAdvance: Boolean
    $locationIds: [ID]!
    $dateTo: Date!
    $onlyEndedReservations: Boolean
  ) {
    invoiceableRows(
      orderId: $orderId
      customerId: $customerId
      isAdvance: $isAdvance
      locationIds: $locationIds
      dateTo: $dateTo
      onlyEndedReservations: $onlyEndedReservations
    ) {
      identifier
      subrowReservationId
      referenceId
      productNumber
      quantity
      unitPrice
      price
      title
      description
      rowSource
    }
  }
`;

export interface QueryResultInvoicesOpen {
  invoicesOpen: Query["invoicesOpen"];
}
export const GET_INVOICES_OPEN_QUERY = gql`
  ${FRAG_INVOICE_SHARED}
  query getInvoicesOpenQuery(
    $orderId: ID
    $isAdvance: Boolean
    $locationIds: [ID]
    $dateTo: Date
  ) {
    invoicesOpen(
      orderId: $orderId
      isAdvance: $isAdvance
      locationIds: $locationIds
      dateTo: $dateTo
    ) {
      ...InvoiceFields
    }
  }
`;

export interface QueryResultInvoicesSent {
  invoicesSent: Query["invoicesSent"];
}
export const GET_INVOICES_SENT_QUERY = gql`
  query getInvoicesSentQuery($dateFrom: Date!, $dateTo: Date!, $orderId: ID) {
    invoicesSent(dateFrom: $dateFrom, dateTo: $dateTo, orderId: $orderId) {
      id
      sentAt
      reference {
        id
        name
        customer {
          id
          name
        }
      }
      invoicerowSet {
        id
        productNumber
        title
        description
        quantity
        unitPrice
        price
        vat
      }
    }
  }
`;
