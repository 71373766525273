import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import { InspectionReturnCategoryType } from "../../entity/types";
import { Col, Row } from "react-bootstrap";
import InspectionReturnSortCategory from "./InspectionReturnSortCategory";
import InspectionReturnSortQuestions from "./InspectionReturnSortQuestions";

interface Props extends WithStyles<typeof styles> {
  inspectionReturnCategories: InspectionReturnCategoryType[];
}

function InspectionReturnSort({ classes, inspectionReturnCategories }: Props) {
  return (
    <Row>
      <Col className={classes.col}>
        <InspectionReturnSortCategory
          inspectionReturnCategories={inspectionReturnCategories}
        />
      </Col>
      <Col className={classes.col}>
        <InspectionReturnSortQuestions
          inspectionReturnCategories={inspectionReturnCategories}
        />
      </Col>
    </Row>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    col: {
      marginBottom: spacing(3),
      userSelect: "none",
    },
  });

export default withStyles(styles)(InspectionReturnSort);
