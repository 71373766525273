import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { DiscountListType } from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";

interface Props extends WithStyles<typeof styles> {
  discountList: DiscountListType;
  setDiscountList: React.Dispatch<React.SetStateAction<DiscountListType>>;
}

function DialogContentDiscountList({
  classes,
  discountList,
  setDiscountList,
}: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "discounts.add_discountlist",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "discounts.change_discountlist",
  ]);
  const disabled = discountList.id ? !hasPermissionEdit : !hasPermissionAdd;

  return (
    <DialogContent>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue="top"
        >
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label={t("company")}
            checked={Boolean(discountList.isBusiness) === true}
            onChange={() => {
              setDiscountList({ ...discountList, isBusiness: true });
            }}
            disabled={disabled}
          />
          <FormControlLabel
            value="0"
            control={<Radio color="primary" />}
            label={t("private")}
            checked={Boolean(discountList.isBusiness) === false}
            onChange={() => {
              setDiscountList({ ...discountList, isBusiness: false });
            }}
            disabled={disabled}
          />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("name")}
          onChange={(event) => {
            setDiscountList({
              ...discountList,
              name: event.target.value,
            });
          }}
          value={discountList.name}
          inputProps={{ maxLength: 100 }}
          disabled={disabled}
          autoFocus
        />
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentDiscountList);
