import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  MachineInfoQuestionAnswerType,
  MachineInfoQuestionType,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import SelectRequired from "../Shared/SelectRequired";

interface Props extends WithStyles<typeof styles> {
  machineInfoQuestion: MachineInfoQuestionType;
  setMachineInfoQuestion: React.Dispatch<
    React.SetStateAction<MachineInfoQuestionType>
  >;
}

function DialogContentMachineInfoQuestion({
  classes,
  machineInfoQuestion,
  setMachineInfoQuestion,
}: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "machines.change_machineinfoquestion",
  ]);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "machines.add_machineinfoquestion",
  ]);
  const disabled = machineInfoQuestion.id
    ? !hasPermissionEdit
    : !hasPermissionAdd;

  return (
    <DialogContent>
      <FormControl fullWidth>
        <TextField
          label={t("question")}
          onChange={(event) => {
            setMachineInfoQuestion({
              ...machineInfoQuestion,
              question: event.target.value,
            });
          }}
          value={machineInfoQuestion.question}
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          autoFocus
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblMachineInfoQuestionAnswerType">
          {t("answer_type")}
        </InputLabel>
        <Select
          labelId="lblMachineInfoQuestionAnswerType"
          value={machineInfoQuestion.answerType}
          disabled={disabled}
          onChange={(event) => {
            setMachineInfoQuestion({
              ...machineInfoQuestion,
              answerType: event.target.value as MachineInfoQuestionAnswerType,
            });
          }}
        >
          <MenuItem value={MachineInfoQuestionAnswerType.Text}>
            {t("answer_type_" + MachineInfoQuestionAnswerType.Text)}
          </MenuItem>
          <MenuItem value={MachineInfoQuestionAnswerType.Options}>
            {t("answer_type_" + MachineInfoQuestionAnswerType.Options)}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblMachineInfoQuestionShowProductCard">
          {t("show_product_card")}
        </InputLabel>
        <SelectRequired
          autoWidth
          labelId="lblMachineInfoQuestionShowProductCard"
          value={machineInfoQuestion.showProductCard ? 1 : 0}
          disabled={disabled}
          onChange={(event) => {
            setMachineInfoQuestion({
              ...machineInfoQuestion,
              showProductCard: Boolean(event.target.value),
            });
          }}
        >
          <MenuItem value={1}>{t("yes")}</MenuItem>
          <MenuItem value={0}>{t("no")}</MenuItem>
        </SelectRequired>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblMachineInfoQuestionShowDrive">
          {t("show_drive")}
        </InputLabel>
        <SelectRequired
          autoWidth
          labelId="lblMachineInfoQuestionShowDrive"
          value={machineInfoQuestion.showDrive ? 1 : 0}
          disabled={disabled}
          onChange={(event) => {
            setMachineInfoQuestion({
              ...machineInfoQuestion,
              showDrive: Boolean(event.target.value),
            });
          }}
        >
          <MenuItem value={1}>{t("yes")}</MenuItem>
          <MenuItem value={0}>{t("no")}</MenuItem>
        </SelectRequired>
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentMachineInfoQuestion);
