import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { Button } from "react-bootstrap";
import { GET_MACHINE_INFO_CATEGORIES_QUERY } from "../../apollo/queries/machines";
import { handleError } from "../../entity/ErrorHandler";
import {
  MachineInfoCategoryType,
  MachineInfoQuestionType,
  Mutation,
  MutationCreateMachineInfoQuestionArgs,
} from "../../entity/types";
import { MachineInfoQuestionEmpty } from "../../entity/empties";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import DialogContentMachineInfoQuestion from "./DialogContentMachineInfoQuestion";
import { CREATE_MACHINE_INFO_QUESTION_MUTATION } from "../../apollo/mutations/machines";

interface Props extends WithStyles<typeof styles> {
  machineInfoCategory: MachineInfoCategoryType;
}

function CreateMachineInfoQuestion({ classes, machineInfoCategory }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [machineInfoQuestion, setMachineInfoQuestion] =
    useState<MachineInfoQuestionType>(MachineInfoQuestionEmpty);

  const [createMachineInfoQuestion] = useMutation<
    Mutation,
    MutationCreateMachineInfoQuestionArgs
  >(CREATE_MACHINE_INFO_QUESTION_MUTATION, {
    refetchQueries: [{ query: GET_MACHINE_INFO_CATEGORIES_QUERY }],
    onCompleted: (result) => {
      setMachineInfoQuestion(MachineInfoQuestionEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "machines.add_machineinfoquestion",
  ]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    createMachineInfoQuestion({
      variables: {
        machineInfoCategoryId: machineInfoCategory.id,
        question: machineInfoQuestion.question,
        answerType: machineInfoQuestion.answerType,
        showProductCard: machineInfoQuestion.showProductCard,
        showDrive: machineInfoQuestion.showDrive,
      },
    });
    setOpen(false);
  };

  if (!hasPermissionAdd) {
    return <></>;
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className="btnRoundSmall"
        variant="primary"
      >
        {open ? <ClearIcon /> : <AddIcon />}
      </Button>
      <Dialog open={open}>
        <form onSubmit={(event) => handleSubmit(event)}>
          <DialogTitle>{t("new_machine_info_question")}</DialogTitle>
          <DialogContentMachineInfoQuestion
            machineInfoQuestion={machineInfoQuestion}
            setMachineInfoQuestion={setMachineInfoQuestion}
          />
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="secondary">
              {t("cancel")}
            </Button>
            <Button
              disabled={!machineInfoQuestion.question.trim()}
              type="submit"
              variant="primary"
            >
              {t("save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CreateMachineInfoQuestion);
