import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { ListGroup } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faStar } from "@fortawesome/pro-light-svg-icons";
import format from "date-fns/format";
import { CatalogType } from "../../entity/types";
import { getCatalogIdActive } from "../../utils/catalogs/selected_catalog";
import { getUrlSettingsCatalogEdit } from "../../utils/urls";
import { newDate } from "../../utils/dates";

interface Props extends WithStyles<typeof styles> {
  catalogs: CatalogType[];
  title: string;
}

function CatalogList({ classes, catalogs, title }: Props) {
  const { t } = useTranslation();

  const catalogIdActive = getCatalogIdActive();

  return (
    <div>
      <h5>{title}</h5>
      <ListGroup>
        {catalogs.map((catalog) => {
          let date = catalog.createdAt;
          let title_date = t("created_at");
          if (catalog.setActiveAt) {
            date = catalog.setActiveAt;
            title_date = t("catalog_activate_at");
          }
          return (
            <LinkContainer
              key={catalog.id}
              to={getUrlSettingsCatalogEdit(catalog.id)}
            >
              <ListGroup.Item action>
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">
                    {catalog.id === catalogIdActive && (
                      <FontAwesomeIcon
                        title={t("catalog_active")}
                        className="me-2"
                        icon={faStar}
                        color="#FFD700"
                      />
                    )}
                    {catalog.setActiveAt && (
                      <FontAwesomeIcon className="me-2" icon={faLock} />
                    )}
                    {catalog.name}
                  </h5>
                  <small title={title_date}>
                    {format(newDate(date), t("format_datetime"))}
                  </small>
                </div>
                {catalog.createdBy && (
                  <p>
                    {catalog.createdBy.lastName} {catalog.createdBy.firstName}
                  </p>
                )}
              </ListGroup.Item>
            </LinkContainer>
          );
        })}
      </ListGroup>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CatalogList);
