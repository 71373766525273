import React from "react";

import withRoot from "../../withRoot";
import Login from "./Login";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";

export default withRoot(() => {
  return (
    <I18nextProvider i18n={i18n}>
      <Login />
    </I18nextProvider>
  );
});
