import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  Theme,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import {
  GET_INVOICEABLE_ROWS_QUERY,
  QueryResultInvoiceableRows,
} from "../../apollo/queries/invoices";
import {
  InvoiceableRowType,
  OrderType,
  QueryInvoiceableRowsArgs,
  ReferenceType,
} from "../../entity/types";
import { DATE_FORMAT_ISO, ID_EMPTY } from "../../utils/constants";
import format from "date-fns/format";
import InvoiceListFilter from "./InvoiceListFilter";
import { resetDateTime } from "../../utils/dates";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { UserContext } from "../../Root";
import { handleError } from "../../entity/ErrorHandler";
import { getLocationSelected } from "../../utils/locations/location";
import InvoiceableRows from "./InvoiceableRows";

export type InvoiceableRowsWithReferenceType = {
  [referenceId: string]: {
    reference: ReferenceType;
    invoiceableRows: InvoiceableRowType[];
  };
};

interface Props extends WithStyles<typeof styles> {
  order?: OrderType;
  isAdvance?: Boolean;
}

function InvoicesCreateListReferences({ classes, order, isAdvance }: Props) {
  const { t } = useTranslation();

  const user = useContext(UserContext);

  const locationIdDefault = getLocationSelected(user);
  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([
    locationIdDefault,
  ]);
  const [dateTo, setDateTo] = useState(resetDateTime(new Date()));
  const [hideSurchargeInvoices, setHideSurchargeInvoices] = useState(false);
  const [onlyEndedReservations, setOnlyEndedReservations] = useState(false);

  const [getInvoices, { data: dataReservations, loading: loadingInvoices }] =
    useLazyQuery<QueryResultInvoiceableRows, QueryInvoiceableRowsArgs>(
      GET_INVOICEABLE_ROWS_QUERY,
      {
        fetchPolicy: "cache-and-network", // We want to always fetch up-to-date data when searching
        variables: {
          orderId: order ? order.id : ID_EMPTY,
          isAdvance: Boolean(isAdvance),
          locationIds: [],
          dateTo: format(new Date(), DATE_FORMAT_ISO),
          onlyEndedReservations: onlyEndedReservations ? true : undefined,
        },
        onError: (error) => {
          handleError(error);
        },
      }
    );

  useEffect(() => {
    if (order) {
      getInvoices({
        variables: {
          orderId: order ? order.id : ID_EMPTY,
          locationIds: locationIdsSelected,
          dateTo: format(dateTo, DATE_FORMAT_ISO),
        },
      });
    }
    // eslint-disable-next-line
  }, [order, locationIdsSelected, dateTo]);

  const onClickCallback = () => {
    getInvoices({
      variables: {
        orderId: order ? order.id : ID_EMPTY,
        locationIds: locationIdsSelected,
        dateTo: format(dateTo, DATE_FORMAT_ISO),
      },
    });
  };

  return (
    <div>
      {!isAdvance && (
        <>
          <InvoiceListFilter
            dateTo={dateTo}
            setDateTo={setDateTo}
            locationIdsSelected={locationIdsSelected}
            setLocationIdsSelected={setLocationIdsSelected}
            onlyEndedReservations={onlyEndedReservations}
            setOnlyEndedReservations={setOnlyEndedReservations}
            onClickCallback={onClickCallback}
            onClickLoading={loadingInvoices}
          />
          <Row className="mb-4">
            <Col>
              <FormControl fullWidth>
                <FormControlLabel
                  label={t("invoiceable_filter_surcharge")}
                  control={
                    <Checkbox
                      checked={hideSurchargeInvoices}
                      onChange={(event) => {
                        setHideSurchargeInvoices(event.target.checked);
                      }}
                    />
                  }
                />
              </FormControl>
            </Col>
          </Row>
        </>
      )}
      <InvoiceableRows
        invoiceableRows={
          dataReservations?.invoiceableRows
            ? dataReservations.invoiceableRows
            : []
        }
        hideSurchargeInvoices={hideSurchargeInvoices}
        isAdvance={isAdvance}
        dateTo={dateTo}
      />
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InvoicesCreateListReferences);
