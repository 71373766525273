import { CatalogType } from "../../entity/types";

export function updateVisibility(catalog: CatalogType, search: string) {
  let catalogCategoryUpperVisible: { [key: string]: boolean } = {};
  let catalogCategoryVisible: { [key: string]: boolean } = {};
  let catalogRowVisible: { [key: string]: boolean } = {};

  catalog.catalogcategoryupperSet.forEach((catalogCategoryUpper) => {
    let showCategoryUpper = true;
    let searchMatchCategoryUpper = false;
    if (search !== "") {
      searchMatchCategoryUpper =
        catalogCategoryUpper.name.search(new RegExp(search, "i")) >= 0;
      if (!searchMatchCategoryUpper) {
        showCategoryUpper = false;
        catalogCategoryUpper.catalogcategorySet.forEach((catalogCategory) => {
          catalogCategory.catalogrowSet.forEach((catalogRow) => {
            if (catalogRow.name.search(new RegExp(search, "i")) >= 0) {
              showCategoryUpper = true;
              return;
            }
          });
          if (showCategoryUpper) {
            return;
          }
        });
      }
    }

    catalogCategoryUpperVisible[catalogCategoryUpper.id] =
      showCategoryUpper || searchMatchCategoryUpper;

    catalogCategoryUpper.catalogcategorySet.forEach((catalogCategory) => {
      let showCategory = true;
      let searchMatchCategory = false;
      if (search !== "") {
        searchMatchCategory =
          catalogCategory.name.search(new RegExp(search, "i")) >= 0;
        if (!searchMatchCategory) {
          showCategory = false;
          catalogCategory.catalogrowSet.forEach((catalogRow) => {
            if (catalogRow.name.search(new RegExp(search, "i")) >= 0) {
              showCategory = true;
              return;
            }
          });
        }
      }

      catalogCategoryVisible[catalogCategory.id] =
        showCategory || searchMatchCategory || searchMatchCategoryUpper;

      catalogCategory.catalogrowSet.forEach((catalogRow) => {
        catalogRowVisible[catalogRow.id] =
          search === "" ||
          searchMatchCategory ||
          searchMatchCategoryUpper ||
          catalogRow.name.indexOf(search) >= 0;
      });
    });
  });

  return {
    catalogCategoryUpperVisible,
    catalogCategoryVisible,
    catalogRowVisible,
  };
}
