import { CustomerType } from "../../entity/types";
import {
  CatalogEmpty,
  CustomerEmpty,
  DiscountListEmpty,
} from "../../entity/empties";
import {
  CUSTOMER_ID_CATALOG_COMPANY,
  CUSTOMER_ID_CATALOG_PRIVATE,
  ID_EMPTY,
} from "../constants";
import { TFunction } from "react-i18next";

export function getCustomerPriceLists(customer: CustomerType) {
  let catalogSelected = CatalogEmpty;
  if (customer.catalogSelected) {
    catalogSelected = customer.catalogSelected;
  }
  let discountListSelected = DiscountListEmpty;
  if (catalogSelected.id === ID_EMPTY && customer.discountListSelected) {
    discountListSelected = customer.discountListSelected;
  }
  return { catalogSelected, discountListSelected };
}

export function getCustomerCatalogDefault(t: TFunction<"translation">) {
  return getCustomerCatalogPrivate(t);
}

export function getCustomerCatalogPrivate(t: TFunction<"translation">) {
  return {
    ...CustomerEmpty,
    id: CUSTOMER_ID_CATALOG_PRIVATE,
    name: t("catalog_private"),
    isBusiness: false,
    hasInsurance: CustomerEmpty.hasInsurance,
  };
}

export function getCustomerCatalogCompany(t: TFunction<"translation">) {
  return {
    ...CustomerEmpty,
    id: CUSTOMER_ID_CATALOG_COMPANY,
    name: t("catalog_company"),
    isBusiness: true,
    hasInsurance: CustomerEmpty.hasInsurance,
  };
}
