import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  InspectionGiveQuestionAnswerType,
  InspectionGiveQuestionType,
  InspectionGiveTagType,
  Mutation,
  MutationDeleteInspectionGiveQuestionArgs,
  MutationUpdateInspectionGiveQuestionArgs,
} from "../../entity/types";
import ButtonLoad from "../Shared/ButtonLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import {
  DELETE_INSPECTION_GIVE_QUESTION_MUTATION,
  UPDATE_INSPECTION_GIVE_QUESTION_MUTATION,
} from "../../apollo/mutations/inspections";
import { GET_INSPECTION_GIVE_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { handleError } from "../../entity/ErrorHandler";
import InspectionGiveQuestionTags from "./InspectionGiveQuestionTags";
import { dialogConfirm } from "../../utils/dialogs";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionGiveQuestion: InspectionGiveQuestionType;
  inspectionGiveTags: InspectionGiveTagType[];
}

function InspectionGiveQuestion({
  classes,
  inspectionGiveQuestion,
  inspectionGiveTags,
}: Props) {
  const { t } = useTranslation();

  const [inspectionGiveQuestionEdited, setInspectionGiveQuestionEdited] =
    useState(inspectionGiveQuestion);

  const [updateInspectionGiveQuestion, { loading: loadingUpdate }] =
    useMutation<Mutation, MutationUpdateInspectionGiveQuestionArgs>(
      UPDATE_INSPECTION_GIVE_QUESTION_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const [deleteInspectionGiveQuestion, { loading: loadingDelete }] =
    useMutation<Mutation, MutationDeleteInspectionGiveQuestionArgs>(
      DELETE_INSPECTION_GIVE_QUESTION_MUTATION,
      {
        refetchQueries: [{ query: GET_INSPECTION_GIVE_CATEGORIES_QUERY }],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditQuestion = checkPermission(myPermissions, [
    "inspections.change_inspectiongivequestion",
  ]);
  const hasPermissionDeleteQuestion = checkPermission(myPermissions, [
    "inspections.delete_inspectiongivequestion",
  ]);

  const handleDeleteInspectionGiveQuestion = (
    inspectionGiveQuestionId: string
  ) => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteInspectionGiveQuestion({
        variables: { inspectionGiveQuestionId: inspectionGiveQuestionId },
      });
    });
  };

  const handleUpdateInspectionGiveQuestion = (
    values: {
      [key: string]: string | number | boolean;
    } = {}
  ) => {
    let base: MutationUpdateInspectionGiveQuestionArgs = {
      inspectionGiveQuestionId: inspectionGiveQuestionEdited.id,
      inspectionGiveCategoryId:
        inspectionGiveQuestionEdited.inspectionGiveCategory.id,
      question: inspectionGiveQuestionEdited.question,
      answerType: inspectionGiveQuestionEdited.answerType,
    };

    updateInspectionGiveQuestion({
      variables: { ...base, ...values },
    });
  };

  return (
    <tr
      key={inspectionGiveQuestion.id}
      className={`${classes.rowQuestion} ${loadingUpdate ? "loading" : ""}`}
    >
      <td className={classes.cellQuestion}>
        <FormControl fullWidth>
          <TextField
            onChange={(event) => {
              setInspectionGiveQuestionEdited({
                ...inspectionGiveQuestionEdited,
                question: event.target.value,
              });
            }}
            value={inspectionGiveQuestionEdited.question}
            disabled={!hasPermissionEditQuestion}
            inputProps={{ maxLength: 100 }}
            onBlur={() => handleUpdateInspectionGiveQuestion()}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <Select
            value={inspectionGiveQuestionEdited.answerType}
            disabled={!hasPermissionEditQuestion}
            onChange={(event) => {
              const answerType = event.target
                .value as InspectionGiveQuestionAnswerType;

              setInspectionGiveQuestionEdited({
                ...inspectionGiveQuestionEdited,
                answerType: answerType,
              });

              handleUpdateInspectionGiveQuestion({
                answerType: answerType,
              });
            }}
          >
            <MenuItem value={InspectionGiveQuestionAnswerType.Text}>
              {t("answer_type_" + InspectionGiveQuestionAnswerType.Text)}
            </MenuItem>
            <MenuItem value={InspectionGiveQuestionAnswerType.Options}>
              {t("answer_type_" + InspectionGiveQuestionAnswerType.Options)}
            </MenuItem>
            <MenuItem value={InspectionGiveQuestionAnswerType.Check}>
              {t("answer_type_" + InspectionGiveQuestionAnswerType.Check)}
            </MenuItem>
          </Select>
        </FormControl>
      </td>
      <InspectionGiveQuestionTags
        inspectionGiveQuestion={inspectionGiveQuestion}
        inspectionGiveTags={inspectionGiveTags}
      />
      <td>
        {hasPermissionDeleteQuestion && (
          <ButtonLoad
            loading={loadingDelete}
            variant="light"
            size="sm"
            onClick={() =>
              handleDeleteInspectionGiveQuestion(inspectionGiveQuestion.id)
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonLoad>
        )}
      </td>
    </tr>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    rowQuestion: {
      "& $cellQuestion": {
        paddingLeft: spacing(4),
      },
    },
    cellQuestion: {
      // Need to have cellQuestion here even if empty
      // so that "& $cellQuestion" works
      minWidth: "15rem",
    },
  });

export default withStyles(styles)(InspectionGiveQuestion);
