import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  Mutation,
  MutationDeleteReservationImageArgs,
  ReservationImageType,
} from "../../entity/types";
import { Col, Image, Row } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { DELETE_RESERVATION_IMAGE_MUTATION } from "../../apollo/mutations/reservations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { dialogConfirm } from "../../utils/dialogs";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  reservationImages: ReservationImageType[];
}

function ReservationImages({ classes, reservationImages }: Props) {
  const { t } = useTranslation();

  const [deleteReservationImage, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteReservationImageArgs
  >(DELETE_RESERVATION_IMAGE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("reservationImageGives"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("reservationImageReturns"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "reservations.delete_reservationimage",
  ]);

  const onClickDelete = (reservationImage: ReservationImageType) => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteReservationImage({
        variables: {
          reservationImageId: reservationImage.id,
        },
      });
    });
  };

  return (
    <Row className={classes.conImages}>
      {reservationImages.map((reservationImage) => (
        <Col xs={12} sm={6}>
          <div key={reservationImage.id} className={classes.conImage}>
            <a href={reservationImage.image} target="_blank" rel="noreferrer">
              <Image
                className="mb-2"
                key={reservationImage.id}
                src={reservationImage.image}
                thumbnail
                fluid
              />
            </a>
            <ButtonLoad
              loading={loadingDelete}
              className={classes.btnDelete}
              variant="light"
              onClick={() => {
                onClickDelete(reservationImage);
              }}
              disabled={!hasPermissionDelete}
            >
              <FontAwesomeIcon icon={faTrash} />
            </ButtonLoad>
          </div>
        </Col>
      ))}
    </Row>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    conImages: {
      maxWidth: "100%",
      marginBottom: spacing(2),
    },
    conImage: {
      position: "relative",
    },
    btnDelete: {
      position: "absolute",
      top: 0,
      right: 0,
    },
  });

export default withStyles(styles)(ReservationImages);
