import gql from "graphql-tag";
import {OperationVariables } from '@apollo/client';

import { setActiveOrganization, getActiveOrganization } from "../../entity/ActiveOrganizationHandler";

import { Query } from "../../entity/types";
import React from "react";

export interface QueryResultIdentificationRequestsByState {
    identificationRequestsByState: Query["newWaitingOrCompletedIdentificationRequests"];
}

/*
export interface QueryResultWaitingIdentificationRequests {
    identificationRequestsByState: Query["waitingIdentificationRequests"];
}

export interface QueryResultCompletedIdentificationRequests {
    identificationRequestsByState: Query["completedIdentificationRequests"];
}*/

// this query fetch data from backend
export const GET_IDENTIFICATION_REQUESTS_BY_STATE = gql`
query identificationRequestsByState($state: String)  {
    identificationRequestsByState(state: $state) {
            id
            createdTs
            identificationCompletedTs
            operatorHandlingCompletedTs
            identificationLink
            trustedBirthPlace
            trustedFullName
            trustedNationalPersonalId
        }
        # Here is the debug field that will output the SQL queries
        _debug {
            sql {
                rawSql
            }
        }
    }
`;


// this query fetch data from backend
export const GET_WAITING_IDENTIFICATION_REQUESTS = gql`
    {
        waitingIdentificationRequests {
            id
            createdTs
            identificationLink
        }
        # Here is the debug field that will output the SQL queries
        _debug {
            sql {
                rawSql
            }
        }
    }
`;

// this query fetch data from backend
export const GET_COMPLETED_IDENTIFICATION_REQUESTS = gql`
    {
        completedIdentificationRequests {
            id
            createdTs
            identificationLink
        }
        # Here is the debug field that will output the SQL queries
        _debug {
            sql {
                rawSql
            }
        }
    }
`;

