import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Theme,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from "react-i18next";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  advanceValidUntil: string;
  setAdvanceValidUntil: React.Dispatch<React.SetStateAction<string>>;
  callback: Function;
}

function DialogOrderAdvanceValidUntil({
  classes,
  open,
  setOpen,
  advanceValidUntil,
  setAdvanceValidUntil,
  callback,
}: Props) {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <form>
        <DialogTitle>{t("advance_valid_until")}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              type="date"
              onChange={(event) => {
                setAdvanceValidUntil(event.target.value);
              }}
              value={advanceValidUntil}
              autoFocus={true}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              callback();
              setOpen(false);
            }}
            variant="primary"
          >
            {t("save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogOrderAdvanceValidUntil);
