import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { CommissionRangeType } from "../../entity/types";
import { WithStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

interface Props extends WithStyles<typeof styles> {
  commissionRange: CommissionRangeType | null;
  commissionRangePrev: CommissionRangeType | null;
  isLast: boolean;
}

function CommissionRangeHeader({
  classes,
  commissionRange,
  commissionRangePrev,
  isLast,
}: Props) {
  const { t } = useTranslation();

  const range =
    (commissionRangePrev ? commissionRangePrev.daysMax : "0") +
    " - " +
    (commissionRange && !isLast ? commissionRange.daysMax : "");

  return <h5>{t("commission_range", { range: range })}</h5>;
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CommissionRangeHeader);
