import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import { createStyles, Theme } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { URL_SETTINGS_CATALOG_NEW } from "../../utils/urls";
import { LinkContainer } from "react-router-bootstrap";

interface Props extends WithStyles<typeof styles> {}

function CreateCatalog({ classes }: Props) {
  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddCatalog = checkPermission(myPermissions, [
    "catalogs.add_catalog",
  ]);

  if (!hasPermissionAddCatalog) {
    return <></>;
  }

  return (
    <LinkContainer to={URL_SETTINGS_CATALOG_NEW}>
      <Button className="addButton" variant="primary" size="lg">
        <AddIcon />
      </Button>
    </LinkContainer>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CreateCatalog);
