import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { ApolloConsumer, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCog,
  faChartLine,
  faList,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { createStyles, Theme, Box } from "@material-ui/core";
import AvatarUser from "./AvatarUser";
import Logo from "./Logo";
import { SET_IS_LOGGED_IN_MUTATION } from "../../apollo/client/is_logged_in";
import { handleError } from "../../entity/ErrorHandler";
import { UserType } from "../../entity/types";
import {
  getUrlProfile,
  URL_CALENDAR,
  URL_REPORTS_COMMISSIONS,
  URL_CUSTOMERS,
  URL_INVOICES_CREATE,
  URL_INVOICES_OPEN,
  URL_INVOICES_SENT,
  URL_SETTINGS_CATALOG,
  URL_SETTINGS_CATALOG_EXTRA_RENTAL,
  URL_SETTINGS_CATALOG_EXTRA_UNIT_PRICE,
  URL_SETTINGS_CATALOG_TRANSFER,
  URL_SETTINGS_COMMISSIONS,
  URL_SETTINGS_DISCOUNTS,
  URL_SETTINGS_HOLIDAYS,
  URL_SETTINGS_INSPECTION_RETURN,
  URL_SETTINGS_INSPECTION_GIVE,
  URL_SETTINGS_LOCATIONS,
  URL_SETTINGS_LOGS,
  URL_SETTINGS_MACHINE_INFORMATION,
  URL_SETTINGS_MACHINES,
  URL_SETTINGS_OTHER,
  URL_SETTINGS_PROFILES,
  URL_SETTINGS_USERS,
  URL_WORK_QUEUES_DRIVING_CREATE,
  URL_WORK_QUEUES_MAINTENANCE,
  URL_WORK_QUEUES_RENTING,
  URL_REPORTS_DAY,
  URL_OPERATOR,
} from "../../utils/urls";
import { AuthType } from "../../Root";
import { onLogout } from "../../utils/login";

interface Props extends WithStyles<typeof styles> {
  currentUser: UserType;
  Auth: AuthType;
}

const Header = ({ classes, currentUser, Auth }: Props) => {
  const { t } = useTranslation();
  const [navSelected, setNavSelected] = useState(window.location.pathname);
  const [setIsLoggedIn] = useMutation(SET_IS_LOGGED_IN_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSignout = () => {
    onLogout();
    setIsLoggedIn({ variables: { isLoggedIn: false } });
    window.location.reload();
  };

  const handleNavSelect = (key: any) => {
    setNavSelected(key);
  };

  const workQueuesSelected = () => {
    return navSelected.indexOf("/work_queues/") >= 0;
  };

  const managementSelected = () => {
    return navSelected.indexOf("/management/") >= 0;
  };

  const settingsSelected = () => {
    return navSelected.indexOf("/settings/") >= 0;
  };

  const classNameLink = "d-inline d-md-none d-lg-none d-xl-inline";

  const LinkCalendar = Auth.Calendar(() => {
    return (
      <LinkContainer to={URL_CALENDAR}>
        <Nav.Link title={t("calendar")}>
          <FontAwesomeIcon
            className={classes.iconLink}
            size="lg"
            icon={faCalendarAlt}
          />
          <Box className={classNameLink}>{t("calendar")}</Box>
        </Nav.Link>
      </LinkContainer>
    );
  });

  const LinkCustomers = Auth.Customers(() => {
    return (
      <LinkContainer to={URL_CUSTOMERS}>
        <Nav.Link title={t("customers")}>
          <FontAwesomeIcon
            className={classes.iconLink}
            size="lg"
            icon={faUsers}
          />
          <Box className={classNameLink}>{t("customers")}</Box>
        </Nav.Link>
      </LinkContainer>
    );
  });

  const LinkSettingsProfiles = Auth.Profiles(() => {
    return (
      <LinkContainer to={URL_SETTINGS_PROFILES}>
        <NavDropdown.Item>{t("profiles")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsUsers = Auth.Users(() => {
    return (
      <LinkContainer to={URL_SETTINGS_USERS}>
        <NavDropdown.Item>{t("users")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsCommissions = Auth.SettingsCommissions(() => {
    return (
      <LinkContainer to={URL_SETTINGS_COMMISSIONS}>
        <NavDropdown.Item>{t("commissions")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsLogs = Auth.Logs(() => {
    return (
      <LinkContainer to={URL_SETTINGS_LOGS}>
        <NavDropdown.Item>{t("logs")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsHolidays = Auth.Holidays(() => {
    return (
      <LinkContainer to={URL_SETTINGS_HOLIDAYS}>
        <NavDropdown.Item>{t("holidays")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsLocations = Auth.Locations(() => {
    return (
      <LinkContainer to={URL_SETTINGS_LOCATIONS}>
        <NavDropdown.Item>{t("locations")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsCatalogs = Auth.Catalogs(() => {
    return (
      <LinkContainer to={URL_SETTINGS_CATALOG}>
        <NavDropdown.Item>{t("catalog")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsCatalogsExtraRental = Auth.CatalogsExtraRental(() => {
    return (
      <LinkContainer to={URL_SETTINGS_CATALOG_EXTRA_RENTAL}>
        <NavDropdown.Item>{t("catalog_extra_rental")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsCatalogsExtraUnitPrice = Auth.CatalogsExtraUnitPrice(() => {
    return (
      <LinkContainer to={URL_SETTINGS_CATALOG_EXTRA_UNIT_PRICE}>
        <NavDropdown.Item>{t("catalog_extra_unit_price")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsCatalogsTransfer = Auth.CatalogsTransfer(() => {
    return (
      <LinkContainer to={URL_SETTINGS_CATALOG_TRANSFER}>
        <NavDropdown.Item>{t("catalog_transfer")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsMachines = Auth.Machines(() => {
    return (
      <LinkContainer to={URL_SETTINGS_MACHINES}>
        <NavDropdown.Item>{t("machines")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsMachinesInformation = Auth.MachineInformation(() => {
    return (
      <LinkContainer to={URL_SETTINGS_MACHINE_INFORMATION}>
        <NavDropdown.Item>{t("machine_information")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsInspectionReturn = Auth.InspectionReturn(() => {
    return (
      <LinkContainer to={URL_SETTINGS_INSPECTION_RETURN}>
        <NavDropdown.Item>{t("inspection_return")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsInspectionGive = Auth.InspectionGive(() => {
    return (
      <LinkContainer to={URL_SETTINGS_INSPECTION_GIVE}>
        <NavDropdown.Item>{t("inspection_give")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsOther = Auth.SettingsOther(() => {
    return (
      <LinkContainer to={URL_SETTINGS_OTHER}>
        <NavDropdown.Item>{t("settings_other")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkSettingsDiscounts = Auth.Discounts(() => {
    return (
      <LinkContainer to={URL_SETTINGS_DISCOUNTS}>
        <NavDropdown.Item>{t("discounts")}</NavDropdown.Item>
      </LinkContainer>
    );
  });

  const LinkSettings = Auth.Settings(() => {
    return (
      <NavDropdown
        id="ddlNaviSettings"
        title={
          <>
            <FontAwesomeIcon
              className={classes.iconLink}
              size="lg"
              icon={faCog}
            />
            <Box component="span" className={classNameLink}>
              {t("settings")}
            </Box>
          </>
        }
        active={settingsSelected()}
      >
        <LinkSettingsProfiles />
        <LinkSettingsUsers />
        <LinkSettingsCommissions />
        <LinkSettingsLogs />
        <LinkSettingsHolidays />
        <LinkSettingsLocations />
        <LinkSettingsCatalogs />
        <LinkSettingsCatalogsExtraRental />
        <LinkSettingsCatalogsExtraUnitPrice />
        <LinkSettingsCatalogsTransfer />
        <LinkSettingsDiscounts />
        <LinkSettingsMachines />
        <LinkSettingsMachinesInformation />
        <LinkSettingsInspectionReturn />
        <LinkSettingsInspectionGive />
        <LinkSettingsOther />
      </NavDropdown>
    );
  });

  const LinkWorkQueuesDrivingCreate = Auth.WorkQueuesDrivingCreate(() => {
    return (
      <LinkContainer to={URL_WORK_QUEUES_DRIVING_CREATE}>
        <NavDropdown.Item>{t("work_queues_driving")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkWorkQueuesRenting = Auth.WorkQueuesRenting(() => {
    return (
      <LinkContainer to={URL_WORK_QUEUES_RENTING}>
        <NavDropdown.Item>{t("work_queues_renting")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkWorkQueuesMaintenance = Auth.WorkQueuesMaintenance(() => {
    return (
      <LinkContainer to={URL_WORK_QUEUES_MAINTENANCE}>
        <NavDropdown.Item>{t("work_queues_maintenance")}</NavDropdown.Item>
      </LinkContainer>
    );
  });

  const LinkWorkQueues = Auth.WorkQueues(() => {
    return (
      <NavDropdown
        id="ddlNaviWorkQueues"
        title={
          <>
            <FontAwesomeIcon
              className={classes.iconLink}
              size="lg"
              icon={faList}
            />
            <Box component="span" className={classNameLink}>
              {t("work_queues")}
            </Box>
          </>
        }
        active={workQueuesSelected()}
      >
        <LinkWorkQueuesDrivingCreate />
        <LinkWorkQueuesRenting />
        <LinkWorkQueuesMaintenance />
      </NavDropdown>
    );
  });

  const LinkManagementInvoicesCreate = Auth.ManagementInvoicesCreate(() => {
    return (
      <LinkContainer to={URL_INVOICES_CREATE}>
        <NavDropdown.Item>{t("management_invoices_create")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkManagementInvoicesOpen = Auth.ManagementInvoicesOpen(() => {
    return (
      <LinkContainer to={URL_INVOICES_OPEN}>
        <NavDropdown.Item>{t("management_invoices_open")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkManagementInvoicesSent = Auth.ManagementInvoicesSent(() => {
    return (
      <LinkContainer to={URL_INVOICES_SENT}>
        <NavDropdown.Item>{t("management_invoices_sent")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkManagementCommissions = Auth.ManagementCommissions(() => {
    return (
      <LinkContainer to={URL_REPORTS_COMMISSIONS}>
        <NavDropdown.Item>{t("management_commission_report")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkManagementReportsDay = Auth.ManagementCommissions(() => {
    return (
      <LinkContainer to={URL_REPORTS_DAY}>
        <NavDropdown.Item>{t("management_reports_day")}</NavDropdown.Item>
      </LinkContainer>
    );
  });
  const LinkOperatorMainPage = Auth.OperatorMainPage(() => {
    return (
      <LinkContainer to={URL_OPERATOR}>
        <NavDropdown.Item>{t("operator_main_page")}</NavDropdown.Item>
      </LinkContainer>
    );
  });

  const LinkManagement = Auth.Management(() => {
    return (
      <NavDropdown
        id="ddlNaviManagement"
        title={
          <>
            <FontAwesomeIcon
              className={classes.iconLink}
              size="lg"
              icon={faChartLine}
            />
            <Box component="span" className={classNameLink}>
              {t("management")}
            </Box>
          </>
        }
        active={managementSelected()}
      >
        <LinkManagementInvoicesCreate />
        <LinkManagementInvoicesOpen />
        <LinkManagementInvoicesSent />
        <LinkManagementCommissions />
        <LinkManagementReportsDay />
      </NavDropdown>
    );
  });

  return (
    <Navbar className={classes.navbar} expand="md" variant="light">
      <div className={classes.navContainerSide}>
        <Logo navClass="d-none d-md-block" handleNavSelect={handleNavSelect} />
      </div>
      <Container>
        <Logo navClass="d-block d-md-none" handleNavSelect={handleNavSelect} />
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className={classes.nav}
            activeKey={navSelected}
            onSelect={handleNavSelect}
          >
            <LinkCalendar />
            <LinkCustomers />
            <LinkWorkQueues />
            <LinkManagement />
            <LinkSettings />
          </Nav>
        </Navbar.Collapse>
      </Container>
      <div className={classes.navContainerSide}>
        {currentUser && (
          <Navbar.Collapse className="justify-content-end text-end">
            <Navbar.Text>
              <NavDropdown
                id="ddlNaviUser"
                title={
                  <Box display="inline-block">
                    <AvatarUser user={currentUser} />
                  </Box>
                }
                align="end"
              >
                <LinkContainer to={getUrlProfile(currentUser.id)}>
                  <NavDropdown.Item>{t("profile")}</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item>
                  <ApolloConsumer>
                    {(client) => (
                      <Box component="span" onClick={() => handleSignout()}>
                        {t("logout")}
                      </Box>
                    )}
                  </ApolloConsumer>
                </NavDropdown.Item>
              </NavDropdown>
            </Navbar.Text>
          </Navbar.Collapse>
        )}
      </div>
    </Navbar>
  );
};

const styles = ({ spacing, palette, breakpoints }: Theme) =>
  createStyles({
    navbar: {
      backgroundColor: palette.primary.main,

      "&& .nav-link": {
        color: "#FFFFFF",
        fontSize: "1rem",
        paddingTop: spacing(1.4),
        paddingRight: spacing(2),
        paddingBottom: spacing(1.4),
        paddingLeft: spacing(2),
        marginRight: spacing(2),

        [breakpoints.only("md")]: {
          fontSize: "1rem",
          marginRight: spacing(1),
        },
        [breakpoints.only("lg")]: {
          fontSize: "1.3rem",
        },
        [breakpoints.only("xl")]: {
          fontSize: "1rem",
        },
        // eslint-disable-next-line
        ["@media (min-width:1350px)"]: {
          fontSize: "1.3rem",
        },
      },
      "&& .nav-link:hover": {
        color: "#EEEEEE",
      },
      "&& .nav-link.active": {
        backgroundColor: "#EEEEEE",
        borderRadius: "10px",
        color: "#444444",
      },

      "&& #ddlNaviUser.dropdown-toggle": {
        color: "#333333",
        padding: 0,
      },
    },
    nav: {
      margin: "auto",
    },
    navContainerSide: {
      flex: 1,
    },
    iconLink: {
      [breakpoints.up("xs")]: {
        marginRight: spacing(2),
      },
      [breakpoints.only("md")]: {
        marginRight: spacing(0),
      },
      [breakpoints.up("lg")]: {
        marginRight: spacing(1),
      },
      // eslint-disable-next-line
      ["@media (min-width:1350px)"]: {
        marginRight: spacing(1.5),
      },
    },
  });

export default withStyles(styles)(Header);
