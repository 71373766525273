import { gql } from "@apollo/client";

export const CREATE_LOCATION_MUTATION = gql`
  mutation createLocation($name: String!, $dimIdentifier: String!) {
    createLocation(name: $name, dimIdentifier: $dimIdentifier) {
      location {
        id
      }
    }
  }
`;

export const UPDATE_LOCATION_MUTATION = gql`
  mutation updateLocation(
    $locationId: ID!
    $name: String!
    $dimIdentifier: String!
  ) {
    updateLocation(
      locationId: $locationId
      name: $name
      dimIdentifier: $dimIdentifier
    ) {
      location {
        id
        name
        dimIdentifier
      }
    }
  }
`;

export const UPDATE_LOCATION_TRANSFER_MUTATION = gql`
  mutation updateLocationTransfer(
    $locationId: ID!
    $transferDistanceIncluded: Int!
    $transferDistanceLimit: Int!
  ) {
    updateLocationTransfer(
      locationId: $locationId
      transferDistanceIncluded: $transferDistanceIncluded
      transferDistanceLimit: $transferDistanceLimit
    ) {
      location {
        id
        transferDistanceIncluded
        transferDistanceLimit
      }
    }
  }
`;

export const DELETE_LOCATION_MUTATION = gql`
  mutation deleteLocation($locationId: ID!) {
    deleteLocation(locationId: $locationId) {
      locationId
    }
  }
`;
