import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { QueryReservationImageBasicsArgs } from "../../entity/types";
import { Col, Row } from "react-bootstrap";
import ReservationImageBasic from "./ReservationImageBasic";
import { useQuery } from "@apollo/client";
import {
  GET_RESERVATION_IMAGE_BASICS_QUERY,
  QueryResultReservationImageBasics,
} from "../../apollo/queries/reservations";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import LoadingDialog from "../Shared/LoadingDialog";
import Error from "../Shared/Error";
import { useTranslation } from "react-i18next";

interface Props extends WithStyles<typeof styles> {
  reservationId: string;
}

function ReservationImagesBasic({ classes, reservationId }: Props) {
  const { t } = useTranslation();

  const {
    loading: loadingImages,
    error: errorImages,
    data: dataImages,
  } = useQuery<
    QueryResultReservationImageBasics,
    QueryReservationImageBasicsArgs
  >(GET_RESERVATION_IMAGE_BASICS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("reservationImageBasics"),
    variables: {
      reservationId: reservationId,
    },
  });

  if (loadingImages) return <LoadingDialog />;
  if (errorImages) return <Error error={errorImages} />;
  if (!dataImages) return <Error error={t("error_query_failed")} />;

  const reservationImagesBasic = dataImages?.reservationImageBasics
    ? dataImages.reservationImageBasics
    : [];

  return (
    <Row className={classes.conImages}>
      {reservationImagesBasic.map((reservationImageBasic) => (
        <Col key={reservationImageBasic.id} xs={12} sm={6}>
          <ReservationImageBasic
            reservationImageBasic={reservationImageBasic}
          />
        </Col>
      ))}
    </Row>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    conImages: {
      maxWidth: "100%",
      marginBottom: spacing(2),
    },
  });

export default withStyles(styles)(ReservationImagesBasic);
