import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { CatalogCategoryUpperType } from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";

interface Props extends WithStyles<typeof styles> {
  catalogCategoryUpper: CatalogCategoryUpperType;
  setCatalogCategoryUpper: React.Dispatch<
    React.SetStateAction<CatalogCategoryUpperType>
  >;
}

function DialogContentCatalogCategoryUpper({
  classes,
  catalogCategoryUpper,
  setCatalogCategoryUpper,
}: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "catalogs.add_catalogcategoryupper",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs.change_catalogcategoryupper",
  ]);
  const disabled = catalogCategoryUpper.id
    ? !hasPermissionEdit
    : !hasPermissionAdd;

  return (
    <DialogContent>
      <FormControl fullWidth>
        <TextField
          id="txtCatalogName"
          label={t("name")}
          className={classes.textField}
          onChange={(event) => {
            setCatalogCategoryUpper({
              ...catalogCategoryUpper,
              name: event.target.value,
            });
          }}
          value={catalogCategoryUpper.name}
          disabled={disabled}
          autoFocus={true}
        />
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  });

export default withStyles(styles)(DialogContentCatalogCategoryUpper);
