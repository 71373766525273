import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import {
  MaintenanceType,
  Mutation,
  MutationUploadMaintenanceImageArgs,
} from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ROOT_QUERY } from "../../utils/constants";
import { UPLOAD_MAINTENANCE_IMAGE_MUTATION } from "../../apollo/mutations/maintenances";
import { getQueryKey } from "../../utils/cache";
import { Button } from "react-bootstrap";
import MaintenanceImages from "./MaintenanceImages";

export type ImageFieldType = "" | "giveImagesAt" | "returnImagesAt";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  maintenance: MaintenanceType;
}

function DialogMaintenanceImages({
  classes,
  open,
  setOpen,
  maintenance,
}: Props) {
  const { t } = useTranslation();

  const [uploadMaintenanceImage, { loading: loadingUpload }] = useMutation<
    Mutation,
    MutationUploadMaintenanceImageArgs
  >(UPLOAD_MAINTENANCE_IMAGE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("maintenanceImages"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "maintenance.add_maintenanceimage",
  ]);

  function onChange({
    target: { validity, files },
  }: React.ChangeEvent<HTMLInputElement>) {
    if (files && validity.valid) {
      uploadMaintenanceImage({
        variables: {
          maintenanceAlbumId: maintenance.album.id,
          file: files[0],
        },
      });
    }
  }

  return (
    <Dialog open={open} maxWidth="xl">
      <DialogTitleWithClose
        id="dialogTitleCatalogRowUpload"
        onClose={() => setOpen(false)}
      >
        {t("maintenance_images")}
      </DialogTitleWithClose>
      <DialogContent className={loadingUpload ? "loading" : ""}>
        {hasPermissionAdd && (
          <div className={classes.conUpload}>
            <input type="file" required onChange={onChange} accept="image/*" />
          </div>
        )}
        <MaintenanceImages maintenanceAlbumId={maintenance.album.id} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} variant="secondary">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    conUpload: {
      marginBottom: spacing(2),
    },
  });

export default withStyles(styles)(DialogMaintenanceImages);
