import { ApolloLink } from "@apollo/client";

export const setAuthorizationLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("authToken") || "";
  operation.setContext({
    headers: {
      authorization: `JWT ${token}`,
    },
  });
  return forward(operation);
});
