import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  InspectionGiveCategoryType,
  Mutation,
  MutationCreateInspectionGiveCategoryArgs,
  MutationUpdateInspectionGiveCategoryArgs,
} from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { InspectionGiveCategoryEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import DialogContentInspectionGiveCategory from "./DialogContentInspectionGiveCategory";
import {
  CREATE_INSPECTION_GIVE_CATEGORY_MUTATION,
  UPDATE_INSPECTION_GIVE_CATEGORY_MUTATION,
} from "../../apollo/mutations/inspections";
import { GET_INSPECTION_GIVE_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { ID_EMPTY } from "../../utils/constants";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  inspectionGiveCategory: InspectionGiveCategoryType;
}

function DialogInspectionGiveCategory({
  classes,
  open,
  setOpen,
  inspectionGiveCategory,
}: Props) {
  const { t } = useTranslation();
  const [inspectionGiveCategoryEdited, setInspectionGiveCategoryEdited] =
    useState<InspectionGiveCategoryType>(inspectionGiveCategory);

  const [createInspectionGiveCategory] = useMutation<
    Mutation,
    MutationCreateInspectionGiveCategoryArgs
  >(CREATE_INSPECTION_GIVE_CATEGORY_MUTATION, {
    refetchQueries: [{ query: GET_INSPECTION_GIVE_CATEGORIES_QUERY }],
    onCompleted: (result) => {
      setInspectionGiveCategoryEdited(InspectionGiveCategoryEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [updateInspectionGiveCategory] = useMutation<
    Mutation,
    MutationUpdateInspectionGiveCategoryArgs
  >(UPDATE_INSPECTION_GIVE_CATEGORY_MUTATION, {
    refetchQueries: [{ query: GET_INSPECTION_GIVE_CATEGORIES_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (inspectionGiveCategoryEdited.id !== ID_EMPTY) {
      updateInspectionGiveCategory({
        variables: {
          inspectionGiveCategoryId: inspectionGiveCategoryEdited.id,
          name: inspectionGiveCategoryEdited.name,
        },
      });
    } else {
      createInspectionGiveCategory({
        variables: {
          name: inspectionGiveCategoryEdited.name,
        },
      });
    }

    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <form className={classes.form} onSubmit={(event) => handleSubmit(event)}>
        <DialogTitle>
          {t(
            inspectionGiveCategoryEdited.id !== ID_EMPTY
              ? "update_inspection_category"
              : "new_inspection_category"
          )}
        </DialogTitle>
        <DialogContentInspectionGiveCategory
          inspectionGiveCategory={inspectionGiveCategoryEdited}
          setInspectionGiveCategory={setInspectionGiveCategoryEdited}
        />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <Button
            disabled={!inspectionGiveCategoryEdited.name.trim()}
            type="submit"
            variant="primary"
          >
            {t("save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: spacing(2),
    },
  });

export default withStyles(styles)(DialogInspectionGiveCategory);
