import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import { ReservationType } from "../../entity/types";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import DialogContentCatalogExtraReservation from "./DialogContentCatalogExtraReservation";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reservation: ReservationType;
}

function DialogCatalogExtraReservation({
  classes,
  open,
  setOpen,
  reservation,
}: Props) {
  const { t } = useTranslation();

  const onClickClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitleWithClose
        id="dialogTitleCatalogExtraReservation"
        onClose={onClickClose}
      >
        {t("catalog_extra_unit_price")}
      </DialogTitleWithClose>
      <DialogContentCatalogExtraReservation
        reservation={reservation}
        showReservationButtonReturnCheckedCatalog={false}
      />
      <DialogActions>
        <Button onClick={onClickClose} variant="secondary">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCatalogExtraReservation);
