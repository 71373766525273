import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogCategoryUpperType,
  CatalogType,
  Mutation,
  MutationUpdateCatalogCategoryUpperOrderArgs,
} from "../../../entity/types";
import { Table } from "react-bootstrap";
import CatalogCategoryUpper from "./CatalogCategoryUpper";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsV } from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import { UPDATE_CATALOG_CATEGORY_UPPER_MUTATION_ORDER } from "../../../apollo/mutations/catalogs";
import { handleError } from "../../../entity/ErrorHandler";
import Loading from "../../Shared/Loading";
import { isLocked } from "../../../utils/catalogs/locked";
import { ROOT_QUERY } from "../../../utils/constants";
import { getQueryKey } from "../../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
}

function Catalog({ classes, catalog }: Props) {
  const { t } = useTranslation();

  const [updateCatalogCategoryUpperOrder, { loading }] = useMutation<
    Mutation,
    MutationUpdateCatalogCategoryUpperOrderArgs
  >(UPDATE_CATALOG_CATEGORY_UPPER_MUTATION_ORDER, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogs"),
      });
    },
  });

  if (loading) return <Loading />;

  const catalogCategoriesUpper = catalog.catalogcategoryupperSet;

  /* Add the sortingCategoryUpper first to the dragged table so
   * trs are hidden BEFORE rendering the helper drag element.
   * = The drag element height is rendered without the hidden trs. */
  const updateBeforeSortStart = ({ node }: any) => {
    node.classList.add("sortingCategoryUpper");
  };

  /* Then add the sortingCategoryUpper class to the rest of the tables.
   * If this is done in updateBeforeSortStart, the page layout acts funny. */
  const onSortStart = () => {
    let elements = document.getElementsByClassName("tblCatalog");
    Array.from(elements).forEach((element) => {
      element.classList.add("sortingCategoryUpper");
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    let elements = document.getElementsByClassName("tblCatalog");
    Array.from(elements).forEach((element) => {
      element.classList.remove("sortingCategoryUpper");
    });

    let idToIndex: Array<any> = [];
    arrayMove(catalogCategoriesUpper, oldIndex, newIndex).map(
      (catalogCategoryUpper: CatalogCategoryUpperType, index) => {
        idToIndex.push({
          id: catalogCategoryUpper.id,
          order: index,
        });
        return null;
      }
    );
    updateCatalogCategoryUpperOrder({ variables: { idOrderInput: idToIndex } });
  };

  const DragHandleCategoryUpper = SortableHandle(() => (
    <th className={classes.thSort}>
      <FontAwesomeIcon icon={faArrowsV} />
    </th>
  ));

  const CatalogCategoryUpperContainer = SortableElement(
    ({ catalogCategoryUpper }: any) => (
      <CatalogCategoryUpper
        key={catalogCategoryUpper.id}
        catalogCategoriesUpper={catalogCategoriesUpper}
        catalogCategoryUpper={catalogCategoryUpper}
        DragHandleCategoryUpper={DragHandleCategoryUpper}
        catalog={catalog}
      />
    )
  );

  const CategoriesUpperContainer = SortableContainer(({ items }: any) => {
    return (
      <div>
        {items.map(
          (catalogCategoryUpper: CatalogCategoryUpperType, index: number) => (
            <CatalogCategoryUpperContainer
              key={catalogCategoryUpper.id}
              index={index}
              catalogCategoryUpper={catalogCategoryUpper}
            />
          )
        )}
      </div>
    );
  });

  return (
    <div>
      <Table className="tblCatalog" borderless>
        <thead>
          <tr className="text-muted">
            <th></th>
            <th>
              {catalog.catalogSource && (
                <small>
                  {t("catalog_source_is", { name: catalog.catalogSource.name })}
                </small>
              )}
            </th>
            <th>{t("company")}</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>{t("private")}</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            {!isLocked(catalog) && <th></th>}
            <th></th>
          </tr>
        </thead>
      </Table>
      <CategoriesUpperContainer
        items={catalogCategoriesUpper}
        updateBeforeSortStart={updateBeforeSortStart}
        onSortStart={onSortStart}
        onSortEnd={onSortEnd}
        helperClass={classes.sorted}
        useDragHandle
      />
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    sorted: {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    thSort: {
      cursor: "move",
    },
  });

export default withStyles(styles)(Catalog);
