import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogExtraCategoryUpperRentalType,
  Mutation,
  MutationDeleteCatalogExtraCategoryUpperRentalArgs,
} from "../../../entity/types";
import { Button, Table } from "react-bootstrap";
import { PermissionsContext } from "../../../Root";
import { useMutation } from "@apollo/client";
import { handleError } from "../../../entity/ErrorHandler";
import { checkPermission } from "../../../utils/permissions";
import { DELETE_CATALOG_EXTRA_CATEGORY_UPPER_RENTAL_MUTATION } from "../../../apollo/mutations/catalogs_extra";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/pro-light-svg-icons";
import { dialogConfirm } from "../../../utils/dialogs";
import DialogCatalogExtraCategoryUpperRental from "../DialogCatalogExtraCategoryUpperRental";
import { ROOT_QUERY } from "../../../utils/constants";
import { getQueryKey } from "../../../utils/cache";
import ButtonLoad from "../../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  catalogExtraCategoryUpperRental: CatalogExtraCategoryUpperRentalType;
}

function CatalogExtraCategoryUpperRentalTable({
  classes,
  catalogExtraCategoryUpperRental,
}: Props) {
  const { t } = useTranslation();

  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  const [deleteCatalogExtraCategoryUpper, { loading: loadingDelete }] =
    useMutation<Mutation, MutationDeleteCatalogExtraCategoryUpperRentalArgs>(
      DELETE_CATALOG_EXTRA_CATEGORY_UPPER_RENTAL_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("catalogExtraCategoriesUpperRental"),
          });
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs_extra.change_catalogextracategoryupperrental",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "catalogs_extra.delete_catalogextracategoryupperrental",
  ]);

  const onClickEdit = () => {
    setOpenDialogEdit(true);
  };
  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCatalogExtraCategoryUpper({
        variables: {
          catalogExtraCategoryUpperRentalId: catalogExtraCategoryUpperRental.id,
        },
      });
    });
  };

  return (
    <Table className="tblCatalog extra rental" borderless={true}>
      <thead>
        <tr>
          <th></th>
          <th className={classes.thTitle}>
            <div className="position-absolute">
              {catalogExtraCategoryUpperRental.name}
            </div>
            &nbsp;
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>
            {hasPermissionEdit && (
              <Button
                variant="light"
                size="sm"
                className={classes.btn}
                onClick={onClickEdit}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
            {hasPermissionDelete && (
              <ButtonLoad
                loading={loadingDelete}
                variant="light"
                size="sm"
                className={classes.btn}
                onClick={onClickDelete}
              >
                <FontAwesomeIcon icon={faTrash} />
              </ButtonLoad>
            )}
            {hasPermissionEdit && (
              <DialogCatalogExtraCategoryUpperRental
                open={openDialogEdit}
                setOpen={setOpenDialogEdit}
                catalogExtraCategoryUpperRental={
                  catalogExtraCategoryUpperRental
                }
              />
            )}
          </th>
        </tr>
      </thead>
    </Table>
  );
}

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    thTitle: {
      color: palette.primary.main,
      fontSize: "1.2rem",
    },
    btn: {
      marginRight: spacing(0.5),
    },
  });

export default withStyles(styles)(CatalogExtraCategoryUpperRentalTable);
