import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, DialogContent, Paper, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  CustomerType,
  Mutation,
  MutationUpdateOrderStatusArgs,
  OrderStatus,
  OrderType,
  ReservationDeliveryMethod,
  ReservationType,
} from "../../entity/types";
import format from "date-fns/format";
import { ReservationEmpty } from "../../entity/empties";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import ReservationFields from "./ReservationFields";
import { UPDATE_ORDER_STATUS_MUTATION } from "../../apollo/mutations/orders";
import {
  CONTAINER_MAIN_WIDTH_L,
  ID_EMPTY,
  ROOT_QUERY,
} from "../../utils/constants";
import {
  getReservationProductName,
  hasReservationCatalogExtra,
} from "../../utils/reservations/reservation";
import { dialogConfirm } from "../../utils/dialogs";
import OrderFields from "./OrderFields";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import DialogOrderAdvanceValidUntil from "./DialogOrderAdvanceValidUntil";
import Transport from "./Transport";
import { getQueryKey } from "../../utils/cache";
import OrderPrice from "./OrderPrice";
import { newDate } from "../../utils/dates";
import ButtonOrderDelete from "./ButtonOrderDelete";
import ButtonLoad from "../Shared/ButtonLoad";
import { findFromSetById } from "../../utils/collections";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  customer: CustomerType;
  order: OrderType;
  setOrderId?: React.Dispatch<React.SetStateAction<string>>;
  forgetOrder?: Function;
  reservationSelected?: ReservationType;
}

function DialogOrder({
  classes,
  open,
  setOpen,
  customer,
  order,
  setOrderId,
  forgetOrder,
  reservationSelected,
}: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionChangeOrder = checkPermission(myPermissions, [
    "orders.change_order",
  ]);

  const [reservation, setReservation] = useState(
    reservationSelected ? reservationSelected : ReservationEmpty
  );
  const [openDialogAdvanceValidUntil, setOpenDialogAdvanceValidUntil] =
    useState(false);
  const [advanceValidUntil, setAdvanceValidUntil] = useState(
    order.advanceValidUntil === null ? "" : order.advanceValidUntil
  );
  const [showTransport, setShowTransport] = useState(false);

  const hasTransportableReservations =
    order.reservationSet.filter(
      (reservation) =>
        reservation.deliveryMethod === ReservationDeliveryMethod.Transport
    ).length > 0;

  /* When dialog is closed, forget selected reservation. (Handy also if reservation is later deleted.) */
  useEffect(() => {
    if (!open) {
      setReservation(ReservationEmpty);
    }
  }, [open]);

  // Update the stored reservation if order reservations have updates we need to cascade
  useEffect(() => {
    if (reservation.id !== ID_EMPTY) {
      setReservation(
        findFromSetById(reservation.id, order.reservationSet, ReservationEmpty)
      );
    }
    // eslint-disable-next-line
  }, [order.reservationSet]);

  const [updateOrderStatus, { loading: loadingUpdateOrderStatus }] =
    useMutation<Mutation, MutationUpdateOrderStatusArgs>(
      UPDATE_ORDER_STATUS_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
        onCompleted: () => {
          setOpen(false);
          if (forgetOrder) {
            forgetOrder();
          }
        },
        update: (cache) => {
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("reservationsWorkQueues"),
          });
        },
      }
    );

  if (order.id === ID_EMPTY) {
    return null;
  }

  const onClickOrderAdvance = () => {
    if (order.status === OrderStatus.Advance) {
      updateOrderStatus({
        variables: {
          orderId: order.id,
          status: OrderStatus.Offer,
          advanceValidUntil: null,
        },
      });
      setAdvanceValidUntil("");
    } else {
      setOpenDialogAdvanceValidUntil(true);
    }
  };

  const callbackSaveAdvanceUntil = () => {
    if (!advanceValidUntil) {
      handleError(t("error_set_advance_valid_until"));
      return false;
    }

    updateOrderStatus({
      variables: {
        orderId: order.id,
        status: OrderStatus.Advance,
        advanceValidUntil: advanceValidUntil,
      },
    });
  };

  const setOrderStatusToReserved = () => {
    updateOrderStatus({
      variables: {
        orderId: order.id,
        status: OrderStatus.Reserved,
        advanceValidUntil: order.advanceValidUntil,
      },
    });
  };

  const onClickOrderConfirm = () => {
    const reservationsWithDiffLocation: ReservationType[] =
      order.reservationSet.filter(
        (reservationLooped) =>
          reservationLooped.machine &&
          !reservationLooped.machine.bulkProduct &&
          !hasReservationCatalogExtra(reservationLooped) &&
          reservationLooped.machine.location &&
          reservationLooped.machine.location.id !== order.location.id
      );

    if (reservationsWithDiffLocation.length > 0) {
      const textsConfirm = reservationsWithDiffLocation.map(
        (reservationLooped) =>
          t("confirm_order_has_different_location", {
            machine: getReservationProductName(t, reservationLooped, true),
            location: order.location.name,
          })
      );
      dialogConfirm(t, textsConfirm.join("\n"), () => {
        setOrderStatusToReserved();
      });
    } else {
      setOrderStatusToReserved();
    }
  };

  const callbackOnDelete = () => {
    setOpen(false);
    if (forgetOrder) {
      forgetOrder();
    }
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <form>
        <DialogTitleWithClose
          id="dialogOrderClose"
          onClose={() => setOpen(false)}
        >
          {customer.name}{" "}
          <small className="text-muted">
            <span title={t("order_id_header")}>#{order.id}</span>{" "}
            {t("order_status_" + order.status)}
          </small>
        </DialogTitleWithClose>
        <DialogContent className={classes.dialogContent}>
          <div id="conDialogOrderContent" className="d-sm-block d-md-flex">
            <div
              id="colDialogOrderTabNav"
              className={`d-flex flex-column ${classes.colDialogOrderTabNav}`}
            >
              <Paper variant="outlined">
                <Table
                  id="tblDialogOrderReservations"
                  borderless
                  className="m-0 table-hover"
                >
                  <tbody>
                    <tr
                      className={
                        "cursor-pointer" +
                        (!showTransport && reservation.id === ID_EMPTY
                          ? " table-active"
                          : "")
                      }
                      onClick={() => {
                        setReservation(ReservationEmpty);
                        setShowTransport(false);
                      }}
                    >
                      <td colSpan={2}>{t("order_details")}</td>
                    </tr>
                    {order.reservationSet.map((reservationLooped) => (
                      <tr
                        className={
                          "cursor-pointer" +
                          (!showTransport &&
                          reservation.id === reservationLooped.id
                            ? " table-active"
                            : "")
                        }
                        key={reservationLooped.id}
                        onClick={() => {
                          setReservation(reservationLooped);
                          setShowTransport(false);
                        }}
                      >
                        <td>
                          {getReservationProductName(t, reservationLooped)}
                        </td>
                        <td>
                          {t("date_range", {
                            start: format(
                              newDate(reservationLooped.dateRented),
                              t("format_date")
                            ),
                            end: format(
                              newDate(reservationLooped.dateReturned),
                              t("format_date")
                            ),
                          })}
                        </td>
                      </tr>
                    ))}
                    {hasTransportableReservations && (
                      <tr
                        className={
                          "cursor-pointer" +
                          (showTransport ? " table-active" : "")
                        }
                        onClick={() => {
                          setShowTransport(!showTransport);
                        }}
                      >
                        <td colSpan={2}>{t("transport")}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Paper>
              <OrderPrice order={order} />
              <div className="flex-grow-1"></div>
              <div className="mt-3">
                {hasPermissionChangeOrder &&
                  [OrderStatus.Offer, OrderStatus.Advance].includes(
                    order.status
                  ) && (
                    <div className="mb-2">
                      {advanceValidUntil && (
                        <small className="me-2 text-muted">
                          {format(newDate(advanceValidUntil), t("format_date"))}
                        </small>
                      )}
                      <ButtonLoad
                        loading={loadingUpdateOrderStatus}
                        className="me-2 mt-1"
                        onClick={onClickOrderAdvance}
                        variant={
                          order.status === OrderStatus.Advance
                            ? "warning"
                            : "primary"
                        }
                      >
                        {t(
                          order.status === OrderStatus.Advance
                            ? "advance_order_cancel"
                            : "advance_order"
                        )}
                      </ButtonLoad>
                      <DialogOrderAdvanceValidUntil
                        open={openDialogAdvanceValidUntil}
                        setOpen={setOpenDialogAdvanceValidUntil}
                        advanceValidUntil={advanceValidUntil}
                        setAdvanceValidUntil={setAdvanceValidUntil}
                        callback={callbackSaveAdvanceUntil}
                      />
                    </div>
                  )}
                {hasPermissionChangeOrder && (
                  <ButtonLoad
                    loading={loadingUpdateOrderStatus}
                    className="me-2"
                    onClick={onClickOrderConfirm}
                    variant="primary"
                    disabled={order.status === OrderStatus.Reserved}
                  >
                    {t("confirm_order")}
                  </ButtonLoad>
                )}
                <ButtonOrderDelete
                  order={order}
                  reservations={order.reservationSet}
                  callbackOnDelete={callbackOnDelete}
                />
              </div>
            </div>
            <div
              id="colDialogOrderTabCon"
              className="flex-grow-1 ps-sm-0 ps-md-4 mt-sm-4 mt-md-0"
            >
              {!showTransport && reservation.id === ID_EMPTY && (
                <OrderFields customer={customer} order={order} />
              )}
              {!showTransport && reservation.id !== ID_EMPTY && (
                <ReservationFields
                  order={order}
                  setOrderId={setOrderId}
                  reservation={reservation}
                  setReservation={setReservation}
                  customer={customer}
                />
              )}
              {showTransport && <Transport order={order} />}
            </div>
          </div>
        </DialogContent>
      </form>
    </Dialog>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    dialogContent: {
      paddingBottom: spacing(2),
    },
    colDialogOrderTabNav: {
      paddingRight: spacing(2),
      borderRight: "1px solid #EEEEEE",
      minWidth: "20rem",
    },
    autocomplete: {
      flex: "1 1 0",
    },
    paper: {
      width: CONTAINER_MAIN_WIDTH_L,
      maxWidth: "100%",
    },
  });

export default withStyles(styles)(DialogOrder);
