import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  CatalogExtraRowUnitPriceType,
  Mutation,
  MutationDeleteCatalogExtraRowUnitPriceArgs,
  MutationUpdateCatalogExtraRowUnitPriceArgs,
} from "../../../entity/types";
import { formatNumber, parseNumber } from "../../../utils/formatting";
import { PermissionsContext, SettingsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { useMutation, useQuery } from "@apollo/client";
import { handleError } from "../../../entity/ErrorHandler";
import {
  DELETE_CATALOG_EXTRA_ROW_UNIT_PRICE_MUTATION,
  UPDATE_CATALOG_EXTRA_ROW_UNIT_PRICE_MUTATION,
} from "../../../apollo/mutations/catalogs_extra";
import { GET_CATALOG_EXTRA_UNIT_PRICE_QUERY } from "../../../apollo/queries/catalogs_extra";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTasks } from "@fortawesome/pro-light-svg-icons";
import DialogCatalogExtraRowUnitPriceMachine from "../DialogCatalogExtraRowUnitPriceMachine";
import { dialogConfirm } from "../../../utils/dialogs";
import { ID_EMPTY } from "../../../utils/constants";
import SelectRequired from "../../Shared/SelectRequired";
import {
  GET_PRODUCT_CODES_QUERY,
  QueryResultProductCodes,
} from "../../../apollo/queries/product_codes";
import LoadingSimple from "../../Shared/LoadingSimple";
import Error from "../../Shared/Error";
import { getQueryFetchPolicy } from "../../../utils/getQueryFetchPolicy";
import ButtonLoad from "../../Shared/ButtonLoad";
import TextFieldFocus from "../../Shared/TextFieldFocus";

const getCatalogExtraRowUnitPriceDefaults = (
  catalogExtraRowUnitPrice: CatalogExtraRowUnitPriceType
) => ({
  ...catalogExtraRowUnitPrice,
  unitPrice: formatNumber(catalogExtraRowUnitPrice.unitPrice, 2),
});

interface Props extends WithStyles<typeof styles> {
  catalogExtraRowUnitPrice: CatalogExtraRowUnitPriceType;
}

function CatalogExtraUnitPriceRow({
  classes,
  catalogExtraRowUnitPrice,
}: Props) {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);

  const [catalogExtraRowUnitPriceEdited, setCatalogExtraRowUnitPriceEdited] =
    useState(getCatalogExtraRowUnitPriceDefaults(catalogExtraRowUnitPrice));
  const [unitPriceVat, setUnitPriceVat] = useState<string>(
    formatNumber(
      Number(catalogExtraRowUnitPrice.unitPrice) * settings.vatFactor,
      2
    )
  );
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [updateCatalogRow, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCatalogExtraRowUnitPriceArgs
  >(UPDATE_CATALOG_EXTRA_ROW_UNIT_PRICE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });
  const [deleteCatalogRow, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteCatalogExtraRowUnitPriceArgs
  >(DELETE_CATALOG_EXTRA_ROW_UNIT_PRICE_MUTATION, {
    refetchQueries: [{ query: GET_CATALOG_EXTRA_UNIT_PRICE_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  /* If object is updated above (data is reloaded), we want to mirror changes here */
  useEffect(() => {
    setCatalogExtraRowUnitPriceEdited(
      getCatalogExtraRowUnitPriceDefaults(catalogExtraRowUnitPrice)
    );
  }, [catalogExtraRowUnitPrice]);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs_extra.change_catalogextrarowunitprice",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "catalogs_extra.delete_catalogextrarowunitprice",
  ]);

  const {
    loading: loadingProductCodes,
    error: errorProductRows,
    data: dataProductRows,
  } = useQuery<QueryResultProductCodes>(GET_PRODUCT_CODES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("productCodes"),
  });
  if (loadingProductCodes) return <LoadingSimple />;
  if (errorProductRows) return <Error error={errorProductRows} />;

  const handleClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCatalogRow({
        variables: {
          catalogExtraRowUnitPriceId: catalogExtraRowUnitPriceEdited.id,
        },
      });
    });
  };

  const handleClickAssignMachine = () => {
    setOpenDialog(true);
  };

  const handleUpdate = (values: {
    [key: string]: string | number | boolean;
  }) => {
    const base: MutationUpdateCatalogExtraRowUnitPriceArgs = {
      catalogExtraRowUnitPriceId: catalogExtraRowUnitPriceEdited.id,
      name: catalogExtraRowUnitPriceEdited.name,
      productCodeId: catalogExtraRowUnitPriceEdited.productCode.id,
      unit: catalogExtraRowUnitPriceEdited.unit,
      catalogExtraCategoryUnitPriceId:
        catalogExtraRowUnitPriceEdited.catalogExtraCategoryUnitPrice
          ? catalogExtraRowUnitPriceEdited.catalogExtraCategoryUnitPrice.id
          : ID_EMPTY,
      unitPrice: parseNumber(catalogExtraRowUnitPriceEdited.unitPrice),
      allowDiscount: catalogExtraRowUnitPriceEdited.allowDiscount,
      requireAnswer: catalogExtraRowUnitPriceEdited.requireAnswer,
      minCharge: catalogExtraRowUnitPriceEdited.minCharge,
      showPrint: catalogExtraRowUnitPriceEdited.showPrint,
    };

    updateCatalogRow({
      variables: { ...base, ...values },
    });
  };

  return (
    <tr className={loadingUpdate ? "loading" : ""}>
      <td className={classes.tdProductNumber}>
        <FormControl className={classes.ddlProductNumber} fullWidth>
          <SelectRequired
            autoWidth
            disabled={!hasPermissionEdit}
            value={
              catalogExtraRowUnitPriceEdited.productCode
                ? catalogExtraRowUnitPriceEdited.productCode.id
                : ""
            }
            onChange={(event) => {
              setCatalogExtraRowUnitPriceEdited({
                ...catalogExtraRowUnitPriceEdited,
                productCode: {
                  ...catalogExtraRowUnitPriceEdited.productCode,
                  id: String(event.target.value),
                },
              });
              handleUpdate({
                productCodeId: String(event.target.value),
              });
            }}
          >
            {dataProductRows?.productCodes?.map((productCode, index) => {
              return (
                <MenuItem key={productCode.id} value={productCode.id}>
                  {productCode.description} - {productCode.code}
                </MenuItem>
              );
            })}
          </SelectRequired>
        </FormControl>
      </td>
      <td className={classes.tdName}>
        <FormControl fullWidth>
          <TextField
            value={catalogExtraRowUnitPriceEdited.name}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setCatalogExtraRowUnitPriceEdited({
                ...catalogExtraRowUnitPriceEdited,
                name: event.target.value,
              });
            }}
            onBlur={() => {
              handleUpdate({ name: catalogExtraRowUnitPriceEdited.name });
            }}
            inputProps={{ maxLength: 100 }}
          />
        </FormControl>
      </td>
      <td className={classes.tdUnit}>
        <FormControl fullWidth>
          <TextField
            value={catalogExtraRowUnitPriceEdited.unit}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setCatalogExtraRowUnitPriceEdited({
                ...catalogExtraRowUnitPriceEdited,
                unit: event.target.value,
              });
            }}
            onBlur={() => {
              handleUpdate({ unit: catalogExtraRowUnitPriceEdited.unit });
            }}
            inputProps={{ maxLength: 5 }}
          />
        </FormControl>
      </td>
      <td className={classes.tdUnitPrice}>
        <FormControl fullWidth>
          <TextFieldFocus
            value={catalogExtraRowUnitPriceEdited.unitPrice}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setCatalogExtraRowUnitPriceEdited({
                ...catalogExtraRowUnitPriceEdited,
                unitPrice: event.target.value,
              });
              setUnitPriceVat(
                formatNumber(
                  parseNumber(event.target.value) * settings.vatFactor,
                  2
                )
              );
            }}
            onBlur={() => {
              const unitPriceNew = parseNumber(
                catalogExtraRowUnitPriceEdited.unitPrice
              );
              setCatalogExtraRowUnitPriceEdited({
                ...catalogExtraRowUnitPriceEdited,
                unitPrice: formatNumber(unitPriceNew, 2),
              });
              handleUpdate({
                unitPrice: unitPriceNew,
              });
            }}
            inputProps={{ maxLength: 10 }}
          />
        </FormControl>
      </td>
      <td className={classes.tdUnitPriceVat}>
        <FormControl fullWidth>
          <TextFieldFocus
            value={unitPriceVat}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setUnitPriceVat(event.target.value);
              setCatalogExtraRowUnitPriceEdited({
                ...catalogExtraRowUnitPriceEdited,
                unitPrice: formatNumber(
                  parseNumber(event.target.value) / settings.vatFactor,
                  2
                ),
              });
            }}
            onBlur={() => {
              setUnitPriceVat(formatNumber(unitPriceVat, 2));
              handleUpdate({
                unitPrice: (
                  parseNumber(unitPriceVat) / settings.vatFactor
                ).toFixed(2),
              });
            }}
            inputProps={{ maxLength: 10 }}
          />
        </FormControl>
      </td>
      <td className={classes.tdMinCharge}>
        <FormControl fullWidth>
          <TextFieldFocus
            value={catalogExtraRowUnitPriceEdited.minCharge}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setCatalogExtraRowUnitPriceEdited({
                ...catalogExtraRowUnitPriceEdited,
                minCharge: parseNumber(event.target.value),
              });
            }}
            onBlur={() => {
              handleUpdate({
                minCharge: catalogExtraRowUnitPriceEdited.minCharge,
              });
            }}
            inputProps={{ maxLength: 6 }}
          />
        </FormControl>
      </td>
      <td className={classes.tdAllowDiscount}>
        <Select
          fullWidth
          value={catalogExtraRowUnitPriceEdited.allowDiscount ? 1 : 0}
          disabled={!hasPermissionEdit}
          onChange={(
            event: React.ChangeEvent<{ name?: string; value: any }>
          ) => {
            const allowDiscountNew = parseInt(event.target.value) === 1;
            setCatalogExtraRowUnitPriceEdited({
              ...catalogExtraRowUnitPriceEdited,
              allowDiscount: allowDiscountNew,
            });
            handleUpdate({
              allowDiscount: allowDiscountNew,
            });
          }}
        >
          <MenuItem value="1">{t("yes")}</MenuItem>
          <MenuItem value="0">{t("no")}</MenuItem>
        </Select>
      </td>
      <td className={classes.tdRequireAnswer}>
        <Select
          fullWidth
          value={catalogExtraRowUnitPriceEdited.requireAnswer ? 1 : 0}
          disabled={!hasPermissionEdit}
          onChange={(
            event: React.ChangeEvent<{ name?: string; value: any }>
          ) => {
            const requireAnswerNew = parseInt(event.target.value) === 1;
            setCatalogExtraRowUnitPriceEdited({
              ...catalogExtraRowUnitPriceEdited,
              requireAnswer: requireAnswerNew,
            });
            handleUpdate({
              requireAnswer: requireAnswerNew,
            });
          }}
        >
          <MenuItem value="1">{t("yes")}</MenuItem>
          <MenuItem value="0">{t("no")}</MenuItem>
        </Select>
      </td>
      <td className={classes.tdShowPrint}>
        <Select
          fullWidth
          value={catalogExtraRowUnitPriceEdited.showPrint ? 1 : 0}
          disabled={!hasPermissionEdit}
          onChange={(
            event: React.ChangeEvent<{ name?: string; value: any }>
          ) => {
            const showPrintNew = parseInt(event.target.value) === 1;
            setCatalogExtraRowUnitPriceEdited({
              ...catalogExtraRowUnitPriceEdited,
              showPrint: showPrintNew,
            });
            handleUpdate({
              showPrint: showPrintNew,
            });
          }}
        >
          <MenuItem value="1">{t("yes")}</MenuItem>
          <MenuItem value="0">{t("no")}</MenuItem>
        </Select>
      </td>
      <td className={classes.tdButtons}>
        {hasPermissionEdit && (
          <Button
            variant="light"
            size="sm"
            className={classes.btn}
            onClick={handleClickAssignMachine}
          >
            <FontAwesomeIcon icon={faTasks} />{" "}
            <small>{catalogExtraRowUnitPriceEdited.machineSet.length}</small>
          </Button>
        )}
        {hasPermissionDelete && (
          <ButtonLoad
            loading={loadingDelete}
            variant="light"
            size="sm"
            className={classes.btn}
            onClick={handleClickDelete}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonLoad>
        )}
        {openDialog && (
          <DialogCatalogExtraRowUnitPriceMachine
            open={openDialog}
            setOpen={setOpenDialog}
            catalogExtraRowUnitPrice={catalogExtraRowUnitPriceEdited}
          />
        )}
      </td>
    </tr>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    tdProductNumber: {
      width: "300px",
    },
    ddlProductNumber: {
      width: "280px",
    },
    tdName: {
      width: "calc(46% - 300px)",
      minWidth: "200px",
    },
    tdUnit: {
      width: "4%",
    },
    tdUnitPrice: {
      width: "10%",
    },
    tdUnitPriceVat: {
      width: "10%",
    },
    tdMinCharge: {
      width: "5%",
    },
    tdAllowDiscount: {
      width: "5%",
    },
    tdRequireAnswer: {
      width: "5%",
    },
    tdShowPrint: {
      width: "5%",
    },
    tdButtons: {
      width: "10%",
      minWidth: "120px",
    },
    btn: {
      marginRight: spacing(0.5),
    },
  });

export default withStyles(styles)(CatalogExtraUnitPriceRow);
