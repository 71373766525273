import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  Box,
  createStyles,
  DialogContent,
  Hidden,
  Tab,
  Theme,
} from "@material-ui/core";
import { OperationHourSource, ReservationType } from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import ReservationButtonReturnChecked from "./ReservationButtonReturnChecked";
import DialogContentCatalogExtraReservation from "../CatalogExtraUnitPrice/DialogContentCatalogExtraReservation";
import MachineBulkBreakdowns from "../Machine/MachineBulkBreakdowns";
import DialogContentInspectionReturnAnswers from "../InspectionReturn/DialogContentInspectionReturnAnswers";
import { hasReservationCatalogExtra } from "../../utils/reservations/reservation";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { MachineEmpty } from "../../entity/empties";
import DialogContentOperationHours from "../OperationHour/DialogContentOperationHours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faEuroSign,
  faTasks,
  faWrench,
} from "@fortawesome/pro-light-svg-icons";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reservation: ReservationType;
}

function DialogReservationReturnCheck({
  classes,
  open,
  setOpen,
  reservation,
}: Props) {
  const { t } = useTranslation();

  const isExtraCatalogReservation = hasReservationCatalogExtra(reservation);

  const [tabSelected, setTabSelected] = useState(
    isExtraCatalogReservation ? "1" : "0"
  );

  const handleChange = (event: React.ChangeEvent<{}>, newTab: string) => {
    setTabSelected(newTab);
  };

  const showBulkBreakdowns =
    !isExtraCatalogReservation &&
    reservation.machine &&
    reservation.machine.bulkProduct;
  const showOperationHours =
    !isExtraCatalogReservation &&
    reservation.machine &&
    reservation.machine.hourTracking;

  let tabs: JSX.Element[] = [];
  let panels: JSX.Element[] = [];
  if (!isExtraCatalogReservation) {
    tabs.push(
      <Tab
        key="0"
        icon={<FontAwesomeIcon icon={faTasks} className={classes.iconSize} />}
        label={<Hidden smDown>{t("inspection_return")} </Hidden>}
        value="0"
        title={t("inspection_return")}
      />
    );
    panels.push(
      <TabPanel key="0" value="0" className={classes.tabPanel}>
        {reservation.machine && (
          <DialogContentInspectionReturnAnswers
            reservation={reservation}
            editable={true}
          />
        )}
      </TabPanel>
    );
  }
  tabs.push(
    <Tab
      key="1"
      icon={<FontAwesomeIcon icon={faEuroSign} className={classes.iconSize} />}
      label={<Hidden smDown>{t("catalog_extra_unit_price")} </Hidden>}
      value="1"
      title={t("catalog_extra_unit_price")}
    />
  );
  panels.push(
    <TabPanel key="1" value="1" className={classes.tabPanel}>
      <DialogContentCatalogExtraReservation
        className="p-0"
        reservation={reservation}
        showReservationButtonReturnCheckedCatalog={true}
      />
    </TabPanel>
  );
  if (showBulkBreakdowns) {
    tabs.push(
      <Tab
        key="2"
        icon={<FontAwesomeIcon icon={faWrench} className={classes.iconSize} />}
        label={<Hidden smDown>{t("machine_bulk_breakdowns")} </Hidden>}
        value="2"
        title={t("machine_bulk_breakdowns")}
      />
    );
    panels.push(
      <TabPanel key="2" value="2" className={classes.tabPanel}>
        <MachineBulkBreakdowns
          machine={reservation.machine ? reservation.machine : MachineEmpty}
          reservation={reservation}
        />
      </TabPanel>
    );
  }
  if (showOperationHours) {
    tabs.push(
      <Tab
        key="3"
        icon={<FontAwesomeIcon icon={faClock} className={classes.iconSize} />}
        label={<Hidden smDown>{t("operation_hours")} </Hidden>}
        value="3"
        title={t("operation_hours")}
      />
    );
    panels.push(
      <TabPanel key="3" value="3" className={classes.tabPanel}>
        <DialogContentOperationHours
          reservation={reservation}
          machine={reservation.machine ? reservation.machine : MachineEmpty}
          source={OperationHourSource.ReturnCheck}
        />
      </TabPanel>
    );
  }

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitleWithClose
        id="dialogTitleReservationReturnCheck"
        onClose={() => setOpen(false)}
      >
        {t("toggle_return_checked_at")}
      </DialogTitleWithClose>
      <DialogContent>
        <TabContext value={tabSelected}>
          <Box>
            <TabList onChange={handleChange}>{tabs}</TabList>
          </Box>
          {panels}
        </TabContext>
      </DialogContent>
      <DialogActions>
        {!reservation.returnCheckedCatalogAt && !isExtraCatalogReservation && (
          <div className={`text-muted me-2 ${classes.note}`}>
            {t("check_catalog_before_return_check")}
          </div>
        )}
        <div>
          <ReservationButtonReturnChecked
            reservation={reservation}
            setOpenDialogReturnCheck={setOpen}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    note: {
      maxWidth: "20rem",
    },
    iconSize: {
      fontSize: "1.5em",
    },
    tabPanel: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  });

export default withStyles(styles)(DialogReservationReturnCheck);
