import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { CustomerType } from "../../entity/types";
import {
  LineChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { COLOR_PRIMARY } from "../../utils/constants";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowDown,
  faLongArrowUp,
} from "@fortawesome/pro-light-svg-icons";
import { newDate } from "../../utils/dates";

type ChartRowType = {
  month: string;
  countThis: number;
  countLast: number;
};

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
}

const getKeyMonth = (date: Date) => format(date, "yyyy-MM");

const ChartCustomerReservations = ({ classes, customer }: Props) => {
  const { t } = useTranslation();

  let oldest: boolean | Date = false;
  customer.orderSet.forEach((order) => {
    order.reservationSet.forEach((reservation) => {
      let createdAt = newDate(reservation.createdAt);
      if (oldest === false || createdAt < oldest) {
        oldest = createdAt;
      }
    });
  });

  if (!oldest) {
    return <></>;
  }

  /******************************************/

  let monthLimit = 12;
  let date = new Date();
  let months: string[] = [];
  while (date > oldest && months.length < monthLimit) {
    let keyMonth = getKeyMonth(date);
    if (!months.includes(keyMonth)) {
      months.push(keyMonth);
    }

    date.setDate(date.getDate() - 1);
  }
  months = months.sort();

  /******************************************/

  let reservationsByMonth: { [keyMonth: string]: number } = {};
  customer.orderSet.forEach((order) => {
    order.reservationSet.forEach((reservation) => {
      let keyMonth = getKeyMonth(newDate(reservation.createdAt));
      if (typeof reservationsByMonth[keyMonth] === "undefined") {
        reservationsByMonth[keyMonth] = 0;
      }
      reservationsByMonth[keyMonth]++;
    });
  });

  /******************************************/

  let totalThis = 0;
  let totalLast = 0;

  let countMaxThis = 0;
  let countMaxLast = 0;
  const data: ChartRowType[] = [];
  months.forEach((month) => {
    let date = newDate(month);
    let keyMonthThis = getKeyMonth(date);
    let keyMonthLast = keyMonthThis.replace(
      String(date.getFullYear()),
      String(date.getFullYear() - 1)
    );
    const countThis =
      typeof reservationsByMonth[keyMonthThis] !== "undefined"
        ? reservationsByMonth[keyMonthThis]
        : 0;
    const countLast =
      typeof reservationsByMonth[keyMonthLast] !== "undefined"
        ? reservationsByMonth[keyMonthLast]
        : 0;
    data.push({
      month: t("month_name_short_" + String(date.getMonth() + 1)),
      countThis: countThis,
      countLast: countLast,
    });

    if (countThis > countMaxThis) {
      countMaxThis = countThis;
    }
    if (countLast > countMaxLast) {
      countMaxLast = countLast;
    }

    totalThis += countThis;
    totalLast += countLast;
  });

  const renderCustomLabel = (props: any, color: string) => {
    const { x, y, value } = props;

    let recHeight = 16;
    let recWidth = 16;

    return (
      <g>
        <rect
          x={x - recWidth / 2}
          y={y - recHeight / 2}
          width={recWidth}
          height={recHeight}
          fill="#FFFFFF"
        />
        <text
          x={x}
          y={y}
          fill={color}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}
        </text>
      </g>
    );
  };

  const COLOR_SECONDARY = "#BBBBBB";
  const renderCustomLabelThis = (props: any) => {
    return renderCustomLabel(props, COLOR_PRIMARY);
  };
  const renderCustomLabelLast = (props: any) => {
    return renderCustomLabel(props, COLOR_SECONDARY);
  };

  let chartLen = months.length <= 5 ? months.length * 100 : "98%";

  return (
    <div className="d-flex">
      <div className={classes.conChart}>
        <ResponsiveContainer
          className={classes.chart}
          width={chartLen}
          height={200}
        >
          <LineChart data={data} margin={{ top: 20, right: 10 }}>
            <XAxis dataKey="month" axisLine={false} tickMargin={5} />
            <YAxis
              dataKey={countMaxLast > countMaxThis ? "countLast" : "countThis"}
              axisLine={false}
              tick={false}
            />
            <Tooltip />
            {months.length >= monthLimit && (
              <Line
                type="monotone"
                dataKey="countLast"
                stroke={COLOR_SECONDARY}
                activeDot={false}
                dot={false}
                // @ts-ignore
                label={renderCustomLabelLast}
                name={String(t("statistic_last_year"))}
              />
            )}
            <Line
              type="monotone"
              dataKey="countThis"
              stroke={COLOR_PRIMARY}
              activeDot={false}
              dot={false}
              // @ts-ignore
              label={renderCustomLabelThis}
              name={String(t("statistic_running_year"))}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div>
        <div className={classes.conChartTotal}>
          <div className={`${classes.chartTotal} ${classes.chartTotalThis}`}>
            <span className={classes.chartTotalThisNmb}>{totalThis}</span>
          </div>
          <div className={`${classes.chartTotal} ${classes.chartTotalLast}`}>
            {totalLast}
          </div>
          {totalThis > totalLast && (
            <FontAwesomeIcon
              className={`${classes.chartTotalIcon} ${classes.chartTotalIconUp}`}
              icon={faLongArrowUp}
            />
          )}
          {totalThis < totalLast && (
            <FontAwesomeIcon
              className={`${classes.chartTotalIcon} ${classes.chartTotalIconDown}`}
              icon={faLongArrowDown}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    conChart: {
      flexGrow: 1,
    },
    chart: {
      marginBottom: spacing(2),
    },
    conChartTotal: {
      marginTop: "40px",
      backgroundColor: "#F2F2F2",
      height: "100px",
      width: "100px",
      borderRadius: "50px",
      position: "relative",
    },
    chartTotal: {
      fontSize: "2rem",
      textAlign: "center",
    },
    chartTotalThis: {
      color: palette.primary.main,
      paddingTop: "3px",
    },
    chartTotalThisNmb: {
      borderBottom: "4px solid #FFFFFF",
    },
    chartTotalLast: {
      color: palette.grey["500"],
    },
    chartTotalIcon: {
      position: "absolute",
      right: "18px",
      top: "45px",
    },
    chartTotalIconUp: {
      color: palette.primary.main,
    },
    chartTotalIconDown: {
      color: "#D32F2C",
    },
  });

export default withStyles(styles)(ChartCustomerReservations);
