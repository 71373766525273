import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  QueryMachineInfoQuestionsForDrivesArgs,
  TransportType,
} from "../../entity/types";
import TransportCardDrive from "./TransportCardDrive";
import { useQuery } from "@apollo/client";
import {
  GET_MACHINE_INFO_QUESTIONS_FOR_DRIVES_QUERY,
  QueryResultMachineInfoQuestionsForDrives,
} from "../../apollo/queries/machines";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { TranportReservationSelected } from "./DrivingCreate";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  header: string;
  transports: TransportType[];
  transportReservationsSelected: TranportReservationSelected[];
  setTransportReservationsSelected: React.Dispatch<
    React.SetStateAction<TranportReservationSelected[]>
  >;
}

function TransportList({
  classes,
  header,
  transports,
  transportReservationsSelected,
  setTransportReservationsSelected,
}: Props) {
  const { t } = useTranslation();

  let machineIds: string[] = [];
  transports.forEach((transport) =>
    transport.reservations.forEach((reservation) => {
      if (reservation.machine && !machineIds.includes(reservation.machine.id)) {
        machineIds.push(reservation.machine.id);
      }
    })
  );

  const {
    loading: loadingQuestions,
    error: errorQuestions,
    data: dataQuestions,
  } = useQuery<
    QueryResultMachineInfoQuestionsForDrives,
    QueryMachineInfoQuestionsForDrivesArgs
  >(GET_MACHINE_INFO_QUESTIONS_FOR_DRIVES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("machineInfoQuestionsForDrives"),
    variables: {
      machineIds: machineIds,
    },
  });

  if (loadingQuestions) return <LoadingSimple />;
  if (errorQuestions) return <Error error={errorQuestions} />;
  if (!dataQuestions) return <Error error={t("error_query_failed")} />;

  return (
    <div className="mt-4 mb-3">
      <p className="text-muted">{header}</p>
      {transports.map((transport) => (
        <TransportCardDrive
          key={transport.id}
          transport={transport}
          machineInfoQuestions={
            dataQuestions.machineInfoQuestionsForDrives
              ? dataQuestions.machineInfoQuestionsForDrives
              : []
          }
          transportReservationsSelected={transportReservationsSelected}
          setTransportReservationsSelected={setTransportReservationsSelected}
        />
      ))}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(TransportList);
