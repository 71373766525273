import React, { useEffect } from "react";
import { WithStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CatalogRowType,
  QueryDiscountListsForCatalogRowArgs,
} from "../../entity/types";
import { formatNumber } from "../../utils/formatting";
import { discountRowPercentsType } from "./DialogCatalogRow";
import { ID_EMPTY } from "../../utils/constants";
import DiscountRowPercent from "./DiscountRowPercent";
import { useQuery } from "@apollo/client";
import {
  GET_DISCOUNT_LISTS_FOR_CATALOG_ROW_QUERY,
  QueryResultDiscountListsForCatalogRow,
} from "../../apollo/queries/catalogs";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { DiscountRowEmpty } from "../../entity/empties";

interface Props extends WithStyles<typeof styles> {
  discountRowPercents: discountRowPercentsType;
  setDiscountRowPercents: React.Dispatch<
    React.SetStateAction<discountRowPercentsType>
  >;
  catalogRow: CatalogRowType;
}

function DiscountRowPercents({
  classes,
  discountRowPercents,
  setDiscountRowPercents,
  catalogRow,
}: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultDiscountListsForCatalogRow,
    QueryDiscountListsForCatalogRowArgs
  >(GET_DISCOUNT_LISTS_FOR_CATALOG_ROW_QUERY, {
    fetchPolicy: getQueryFetchPolicy("discountListsForCatalogRow"),
    skip: catalogRow.id === ID_EMPTY,
    variables: { catalogRowId: catalogRow.id },
  });

  useEffect(() => {
    if (data) {
      let discountRowPercentsNew = { ...discountRowPercents };
      data.discountListsForCatalogRow?.forEach((discountList) => {
        const discountRow =
          discountList.discountrowSetForCatalogRow &&
          discountList.discountrowSetForCatalogRow.length > 0
            ? discountList.discountrowSetForCatalogRow[0]
            : DiscountRowEmpty;

        discountRowPercentsNew[discountRow.id] = {
          percent: formatNumber(discountRow.percent, 2),
          locked: discountList.locked,
        };
      });
      setDiscountRowPercents(discountRowPercentsNew);
    }
    // eslint-disable-next-line
  }, [data, catalogRow]);

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <div>
      {data.discountListsForCatalogRow?.map((discountList) => {
        const discountRow =
          discountList.discountrowSetForCatalogRow &&
          discountList.discountrowSetForCatalogRow.length > 0
            ? discountList.discountrowSetForCatalogRow[0]
            : DiscountRowEmpty;
        return (
          <DiscountRowPercent
            key={discountList.id}
            discountList={discountList}
            discountRow={discountRow}
            discountRowPercents={discountRowPercents}
            setDiscountRowPercents={setDiscountRowPercents}
            catalogRow={catalogRow}
          />
        );
      })}
    </div>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    accordionDiscountList: {
      marginBottom: spacing(3),
    },
    txt: {
      width: "75px",
      marginTop: spacing(1.5),
    },
    txtSm: {
      width: "75px",
    },
    valSm: {
      padding: "4px 0",
    },
  });

export default withStyles(styles)(DiscountRowPercents);
