import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Avatar,
  Button,
  createStyles,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
} from "@material-ui/core";
import {
  CatalogCategoryType,
  CatalogCategoryUpperType,
  CatalogRowType,
  QueryCatalogCategoriesForUpperArgs,
  ReservationType,
} from "../../entity/types";
import { faCalendarAlt, faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { getUrlCalendar } from "../../utils/urls";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EfficiencyCatalogCategory from "./EfficiencyCatalogCategory";
import { ID_EMPTY } from "../../utils/constants";
import { useQuery } from "@apollo/client";
import Error from "../Shared/Error";
import {
  GET_CATALOG_CATEGORIES_FOR_UPPER_QUERY,
  QueryResultCatalogCategoriesForUpper,
} from "../../apollo/queries/catalogs";
import LoadingSimple from "../Shared/LoadingSimple";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  catalog_id: string;
  catalogCategoryUpper: CatalogCategoryUpperType;
  reservations: ReservationType[];
}

function CalendarSelectRowContent({
  classes,
  catalog_id,
  catalogCategoryUpper,
  reservations,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [catalogRows, setCatalogRows] = useState<CatalogRowType[]>([]);

  const { loading, error, data } = useQuery<
    QueryResultCatalogCategoriesForUpper,
    QueryCatalogCategoriesForUpperArgs
  >(GET_CATALOG_CATEGORIES_FOR_UPPER_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalogCategoriesForUpper"),
    variables: {
      catalogCategoryUpperId: catalogCategoryUpper.id,
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickCalendarCategory = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    catalogCategory: CatalogCategoryType
  ) => {
    event.stopPropagation();

    let catalog_category_upper_id = catalogCategoryUpper.id;
    let catalog_category_id = catalogCategory.id;
    let catalog_row_id = ID_EMPTY;

    history.push(
      getUrlCalendar(
        catalog_id,
        catalog_category_upper_id,
        catalog_category_id,
        catalog_row_id
      )
    );
  };

  const onClickCalendarRow = (
    event:
      | React.MouseEvent<HTMLLIElement>
      | React.MouseEvent<HTMLAnchorElement>,
    catalogRow: CatalogRowType
  ) => {
    handleClose();

    let catalog_category_upper_id = ID_EMPTY;
    let catalog_category_id = ID_EMPTY;
    let catalog_row_id = catalogRow.id;

    data.catalogCategoriesForUpper?.forEach((catalogCategory) => {
      catalogCategory.catalogrowSet.forEach((catalogRowLooped) => {
        if (catalogRow.id === catalogRowLooped.id) {
          catalog_category_upper_id = catalogCategoryUpper.id;
          catalog_category_id = catalogCategory.id;
          return;
        }
      });
    });

    history.push(
      getUrlCalendar(
        catalog_id,
        catalog_category_upper_id,
        catalog_category_id,
        catalog_row_id
      )
    );
  };

  const onClickListItem = (
    event:
      | React.MouseEvent<HTMLLIElement>
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLDivElement>,
    catalogCategory: CatalogCategoryType
  ) => {
    setAnchorEl(event.currentTarget);
    setCatalogRows(catalogCategory.catalogrowSet);
  };

  return (
    <div className="w-100">
      <List>
        {data.catalogCategoriesForUpper?.map((catalogCategory) => (
          <ListItem
            button
            key={catalogCategory.id}
            onClick={(event) => onClickListItem(event, catalogCategory)}
          >
            <Button
              className="me-3"
              onClick={(event) =>
                onClickCalendarCategory(event, catalogCategory)
              }
            >
              <Avatar className={classes.avatarLower}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </Avatar>
            </Button>
            <ListItemText primary={catalogCategory.name} />
            <EfficiencyCatalogCategory
              catalogCategory={catalogCategory}
              reservationsToday={reservations}
            />
            <FontAwesomeIcon size="lg" icon={faEllipsisV} />
          </ListItem>
        ))}
      </List>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {catalogRows.length === 0 && (
          <Alert className="ms-3 me-3 mb-0" variant="danger">
            {t("error_no_catalog_rows")}
          </Alert>
        )}
        {catalogRows.map((catalogRow) => (
          <MenuItem
            key={catalogRow.id}
            onClick={(event) => {
              onClickCalendarRow(event, catalogRow);
            }}
          >
            {catalogRow.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    avatarLower: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
    },
  });

export default withStyles(styles)(CalendarSelectRowContent);
