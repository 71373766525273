import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { CustomerType, DiscountListType } from "../../entity/types";
import DiscountTable from "../Discount/table/DiscountTable";
import { updateVisibility } from "../../utils/discounts/search";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  discountList: DiscountListType;
  setDiscountList: React.Dispatch<React.SetStateAction<DiscountListType>>;
}

const CustomerShowOfferNew = ({
  classes,
  customer,
  discountList,
  setDiscountList,
}: Props) => {
  const [search, setSearch] = useState("");
  const [catalogCategoriesUpperVisible, setCatalogCategoriesUpperVisible] =
    useState<{
      [key: string]: boolean;
    }>({});
  const [catalogCategoriesVisible, setCatalogCategoriesVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const [catalogRowsVisible, setCatalogRowsVisible] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    let {
      catalogCategoryUpperVisible,
      catalogCategoryVisible,
      catalogRowVisible,
    } = updateVisibility(discountList.catalog, search);
    setCatalogCategoriesUpperVisible(catalogCategoryUpperVisible);
    setCatalogCategoriesVisible(catalogCategoryVisible);
    setCatalogRowsVisible(catalogRowVisible);
  }, [discountList, search]);

  return (
    <DiscountTable
      catalog={discountList.catalog}
      discountList={discountList}
      setDiscountList={setDiscountList}
      search={search}
      setSearch={setSearch}
      catalogRowsVisible={catalogRowsVisible}
      catalogCategoriesVisible={catalogCategoriesVisible}
      catalogCategoriesUpperVisible={catalogCategoriesUpperVisible}
      customer={customer}
    />
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CustomerShowOfferNew);
