import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from "material-table";
import {
  tableIcons,
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
} from "../../utils/TableProps";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { LogType } from "../../entity/types";
import { Col, Row } from "react-bootstrap";
import format from "date-fns/format";
import { DATE_FORMAT_ISO, LOG_TYPES } from "../../utils/constants";
import { parse } from "date-fns";
import { sortDictByValue } from "../../utils/collections";
import HeaderPage from "../Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {
  logs: LogType[];
  dateFrom: Date;
  setDateFrom: React.Dispatch<React.SetStateAction<Date>>;
  dateTo: Date;
  setDateTo: React.Dispatch<React.SetStateAction<Date>>;
  logTypesShown: string[];
  setLogTypesShown: React.Dispatch<React.SetStateAction<string[]>>;
}

const LogList = ({
  classes,
  logs,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  logTypesShown,
  setLogTypesShown,
}: Props) => {
  const { t } = useTranslation();

  let logsFiltered: LogType[] = [];
  logs.forEach((log) => {
    if (logTypesShown.length === 0 || logTypesShown.includes(log.typeKey)) {
      logsFiltered.push({ ...log });
    }
  });

  let logTypesTranslated: {
    [key: string]: string;
  } = {};
  LOG_TYPES.forEach((key) => {
    logTypesTranslated[key] = t("log_type_" + key);
  });
  logTypesTranslated = sortDictByValue(logTypesTranslated);

  return (
    <>
      <HeaderPage title={t("logs")} />
      <div className="containerInner">
        <Row>
          <Col sm={4} md={4} lg={3}>
            <FormControl fullWidth>
              <TextField
                type="date"
                label={t("date_from")}
                value={format(dateFrom, DATE_FORMAT_ISO)}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  let date = parse(
                    event.target.value,
                    DATE_FORMAT_ISO,
                    new Date()
                  );
                  setDateFrom(date);
                }}
              />
            </FormControl>
          </Col>
          <Col sm={4} md={4} lg={3}>
            <FormControl fullWidth>
              <TextField
                type="date"
                label={t("date_to")}
                value={format(dateTo, DATE_FORMAT_ISO)}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  let date = parse(
                    event.target.value,
                    DATE_FORMAT_ISO,
                    new Date()
                  );
                  setDateTo(date);
                }}
              />
            </FormControl>
          </Col>
          <Col sm={4} md={4} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="lblLogListDataType">{t("data_type")}</InputLabel>
              <Select
                autoWidth
                labelId="lblLogListDataType"
                multiple={true}
                value={logTypesShown}
                onChange={(event) =>
                  setLogTypesShown(event.target.value as string[])
                }
              >
                {Object.entries(logTypesTranslated).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
        </Row>
        <MaterialTable
          style={getTableStyle()}
          title={""}
          icons={tableIcons}
          localization={GetTableLocalization(t)}
          columns={[
            { title: t("created_at"), field: "createdAt", type: "datetime" },
            {
              title: t("created_by"),
              render: (log: LogType) =>
                log.createdBy
                  ? log.createdBy.lastName + " " + log.createdBy.firstName
                  : "",
            },
            {
              title: t("data_type"),
              render: (log: LogType) => t("log_type_" + log.typeKey),
            },
            {
              title: t("data_method"),
              render: (log: LogType) => t("log_method_" + log.method),
            },
            {
              title: t("foreign_key"),
              type: "numeric",
              render: (log: LogType) =>
                log.foreignKey === 0 ? "" : log.foreignKey,
            },
            {
              title: t("data"),
              render: (log: LogType) =>
                log.data === "''" || log.data === "{}" ? "" : log.data,
            },
          ]}
          data={logsFiltered}
          options={getTableOptions({
            paging: true,
            pageSize: 50,
            pageSizeOptions: [25, 50, 100],
          })}
        />
      </div>
    </>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(LogList);
