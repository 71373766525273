import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { Container } from "react-bootstrap";

interface Props extends WithStyles<typeof styles> {}

function Unauthorized({ classes }: Props) {
  const { t } = useTranslation();

  return (
    <Container className="containerMain">
      <h2 className="text-danger">
        <FontAwesomeIcon icon={faExclamationTriangle} /> {t("unauthorized")}
      </h2>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(Unauthorized);
