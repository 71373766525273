import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CatalogCategoryUpperType,
  CatalogRowType,
  CatalogType,
  MachineType,
  QueryMachinesForCatalogRowArgs,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { Col, Row } from "react-bootstrap";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { renderSelectGroupCategoryUpperCategory } from "../../utils/catalogs/select_group";
import { ID_EMPTY } from "../../utils/constants";
import { isLocked } from "../../utils/catalogs/locked";
import { useQuery } from "@apollo/client";
import Error from "../Shared/Error";
import LoadingSimple from "../Shared/LoadingSimple";
import {
  GET_CATALOG_ROW_DISCOUNTS_MACHINES_QUERY,
  QueryResultCatalogRowDiscountsMachines,
} from "../../apollo/queries/catalogs";
import { discountRowPercentsType } from "./DialogCatalogRow";
import { getMachineName } from "../../utils/machines/machine";
import SelectRequired from "../Shared/SelectRequired";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import TextFieldFocus from "../Shared/TextFieldFocus";
import { Autocomplete } from "@material-ui/lab";
import DiscountRowPercents from "./DiscountRowPercents";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
  catalogRow: CatalogRowType;
  setCatalogRow: React.Dispatch<React.SetStateAction<CatalogRowType>>;
  catalogCategoriesUpper: CatalogCategoryUpperType[];
  machineIds: string[];
  setMachineIds: React.Dispatch<React.SetStateAction<string[]>>;
  discountRowPercents: discountRowPercentsType;
  setDiscountRowPercents: React.Dispatch<
    React.SetStateAction<discountRowPercentsType>
  >;
}

function DialogContentCatalogRow({
  classes,
  catalog,
  catalogRow,
  setCatalogRow,
  catalogCategoriesUpper,
  machineIds,
  setMachineIds,
  discountRowPercents,
  setDiscountRowPercents,
}: Props) {
  const { t } = useTranslation();

  const [extraHoursCompany, setExtraHoursCompany] = useState(
    formatNumber(catalogRow.extraHoursCompany, 2)
  );
  const [extraHoursPrivate, setExtraHoursPrivate] = useState(
    formatNumber(catalogRow.extraHoursPrivate, 2)
  );
  const [machinesSelected, setMachinesSelected] = useState<MachineType[]>([]);

  const { loading, error, data } = useQuery<
    QueryResultCatalogRowDiscountsMachines,
    QueryMachinesForCatalogRowArgs
  >(GET_CATALOG_ROW_DISCOUNTS_MACHINES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("machines"),
    skip: catalogRow.id === ID_EMPTY,
    variables: { catalogRowId: catalogRow.id },
    onCompleted: (result) => {
      setMachinesSelected(
        result.machines
          ? result.machines.filter((machine) => machineIds.includes(machine.id))
          : []
      );
    },
  });

  useEffect(() => {
    let arr: string[] = [];
    if (data) {
      arr = data.machinesForCatalogRow?.map(
        (machine) => machine.id
      ) as string[];
    }
    setMachineIds(arr);
  }, [data, setMachineIds]);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "catalogs.add_catalogrow",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs.change_catalogrow",
  ]);
  const disabled = catalogRow.id ? !hasPermissionEdit : !hasPermissionAdd;

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  /* We can't check data here because it has a skip condition */

  const lockedCatalog = isLocked(catalog);

  return (
    <DialogContent>
      <FormControl fullWidth>
        <InputLabel id="lblCustomerOwner">{t("catalog_category")}</InputLabel>
        <SelectRequired
          autoWidth
          labelId="lblCustomerOwner"
          value={
            catalogRow.catalogCategory
              ? catalogRow.catalogCategory.id
              : ID_EMPTY
          }
          disabled={disabled || lockedCatalog}
          onChange={(event) => {
            setCatalogRow({
              ...catalogRow,
              catalogCategory: {
                ...catalogRow.catalogCategory,
                id: String(event.target.value),
              },
            });
          }}
        >
          <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
          {catalogCategoriesUpper.map((catalogCategoryUpper) =>
            renderSelectGroupCategoryUpperCategory(catalogCategoryUpper)
          )}
        </SelectRequired>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="txtCatalogName"
          label={t("name")}
          onChange={(event) => {
            setCatalogRow({
              ...catalogRow,
              name: event.target.value,
            });
          }}
          value={catalogRow.name}
          disabled={disabled}
          autoFocus={true}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="txtCatalogProductNumberDay"
          label={t("product_number_day")}
          onChange={(event) => {
            setCatalogRow({
              ...catalogRow,
              productNumberDay: event.target.value,
            });
          }}
          value={catalogRow.productNumberDay}
          disabled={disabled}
          inputProps={{ maxLength: 32 }}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="txtCatalogProductNumberMonth"
          label={t("product_number_month")}
          onChange={(event) => {
            setCatalogRow({
              ...catalogRow,
              productNumberMonth: event.target.value,
            });
          }}
          value={catalogRow.productNumberMonth}
          disabled={disabled}
          inputProps={{ maxLength: 32 }}
        />
      </FormControl>
      <br />
      <br />
      <Row>
        <Col>
          <FormControl fullWidth>
            <TextFieldFocus
              label={t("hour_limit_day_company")}
              onChange={(event) => {
                setCatalogRow({
                  ...catalogRow,
                  hourLimitDayCompany: Number(event.target.value),
                });
              }}
              value={catalogRow.hourLimitDayCompany}
              disabled={disabled || lockedCatalog}
            />
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth>
            <TextFieldFocus
              label={t("hour_limit_day_private")}
              onChange={(event) => {
                setCatalogRow({
                  ...catalogRow,
                  hourLimitDayPrivate: Number(event.target.value),
                });
              }}
              value={catalogRow.hourLimitDayPrivate}
              disabled={disabled || lockedCatalog}
            />
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormControl fullWidth>
            <TextFieldFocus
              label={t("hour_limit_month_company")}
              onChange={(event) => {
                setCatalogRow({
                  ...catalogRow,
                  hourLimitMonthCompany: Number(event.target.value),
                });
              }}
              value={catalogRow.hourLimitMonthCompany}
              disabled={disabled || lockedCatalog}
            />
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth>
            <TextFieldFocus
              label={t("hour_limit_month_private")}
              onChange={(event) => {
                setCatalogRow({
                  ...catalogRow,
                  hourLimitMonthPrivate: Number(event.target.value),
                });
              }}
              value={catalogRow.hourLimitMonthPrivate}
              disabled={disabled || lockedCatalog}
            />
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormControl fullWidth>
            <TextFieldFocus
              label={t("extra_hours_company")}
              onChange={(event) => {
                setExtraHoursCompany(event.target.value);
              }}
              onBlur={() => {
                let value = formatNumber(extraHoursCompany, 2);
                setExtraHoursCompany(value);
                setCatalogRow({
                  ...catalogRow,
                  extraHoursCompany: parseNumber(value),
                });
              }}
              value={extraHoursCompany}
              disabled={disabled || lockedCatalog}
            />
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth>
            <TextFieldFocus
              label={t("extra_hours_private")}
              onChange={(event) => {
                setExtraHoursPrivate(event.target.value);
              }}
              onBlur={() => {
                let value = formatNumber(extraHoursPrivate, 2);
                setExtraHoursPrivate(value);
                setCatalogRow({
                  ...catalogRow,
                  extraHoursPrivate: parseNumber(value),
                });
              }}
              value={extraHoursPrivate}
              disabled={disabled || lockedCatalog}
            />
          </FormControl>
        </Col>
      </Row>
      <br />
      <FormControl fullWidth>
        <TextField
          id="txtCatalogInformation"
          label={t("information")}
          multiline={true}
          onChange={(event) => {
            setCatalogRow({
              ...catalogRow,
              information: event.target.value,
            });
          }}
          value={catalogRow.information}
          disabled={disabled || lockedCatalog}
        />
      </FormControl>
      <br />
      <br />
      {data && (
        <Autocomplete
          className="mb-3"
          options={data.machines ? data.machines : []}
          getOptionLabel={(machine: MachineType) =>
            getMachineName(machine, true)
          }
          renderInput={(params) => (
            <TextField {...params} label={t("machines")} variant="outlined" />
          )}
          value={machinesSelected}
          disabled={disabled || lockedCatalog}
          multiple={true}
          onChange={(event, machines) => {
            setMachinesSelected(machines);
            setMachineIds(machines.map((machine) => machine.id));
          }}
        />
      )}
      {data && (
        <Accordion>
          <AccordionSummary>{t("discount_rows")}</AccordionSummary>
          <AccordionDetails className={classes.accordionDiscountLists}>
            <DiscountRowPercents
              discountRowPercents={discountRowPercents}
              setDiscountRowPercents={setDiscountRowPercents}
              catalogRow={catalogRow}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </DialogContent>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    accordionDiscountLists: {
      display: "block",
    },
  });

export default withStyles(styles)(DialogContentCatalogRow);
