import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogType,
  DiscountListType,
  QueryDiscountListsArgs,
} from "../../entity/types";
import { useQuery } from "@apollo/client";
import {
  GET_DISCOUNT_LISTS_QUERY,
  QueryResultDiscountLists,
} from "../../apollo/queries/discounts";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import { ListGroup } from "react-bootstrap";
import CreateDiscountList from "./CreateDiscountList";
import { LinkContainer } from "react-router-bootstrap";
import ListGroupItemContentDiscountList from "../Shared/ListGroupItemContentDiscountList";
import { useTranslation } from "react-i18next";
import { getUrlSettingsDiscount } from "../../utils/urls";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
}

const DiscountList = ({ classes, catalog }: Props) => {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultDiscountLists,
    QueryDiscountListsArgs
  >(GET_DISCOUNT_LISTS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("discountLists"),
    variables: {
      catalogId: catalog.id,
    },
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <div className="containerInner">
      <ListGroup>
        {(data.discountLists ? data.discountLists : []).map(
          (discountList: DiscountListType) => {
            if (discountList.isOffer) {
              return <React.Fragment key={discountList.id} />;
            }

            return (
              <LinkContainer
                key={discountList.id}
                to={getUrlSettingsDiscount(discountList.id)}
              >
                <ListGroup.Item action>
                  <ListGroupItemContentDiscountList
                    discountList={discountList}
                  />
                </ListGroup.Item>
              </LinkContainer>
            );
          }
        )}
      </ListGroup>
      <CreateDiscountList catalog={catalog} />
    </div>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DiscountList);
