import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  TextField,
  Theme,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import {
  Mutation,
  MutationSplitOrderArgs,
  OrderType,
  QueryReservationEditableDatesArgs,
} from "../../entity/types";
import { getReservationProductName } from "../../utils/reservations/reservation";
import { addStringToArr, removeStringFromArr } from "../../utils/collections";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/pro-light-svg-icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_RESERVATION_EDITABLE_DATES_QUERY,
  QueryResultReservationEditableDates,
} from "../../apollo/queries/reservations";
import format from "date-fns/format";
import { DATE_FORMAT_ISO } from "../../utils/constants";
import { handleError } from "../../entity/ErrorHandler";
import { SPLIT_ORDER_MUTATION } from "../../apollo/mutations/orders";
import Error from "../Shared/Error";
import { updateCacheReservationAndBreakdowns } from "../../utils/cache";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  order: OrderType;
  setOrderId?: React.Dispatch<React.SetStateAction<string>>;
}

function DialogOrderSplit({
  classes,
  open,
  setOpen,
  order,
  setOrderId,
}: Props) {
  const { t } = useTranslation();

  const [reservationsIdsSelected, setReservationsIdsSelected] = useState<
    string[]
  >([]);
  const [dateReservationEnd, setDateReservationEnd] = useState(
    format(new Date(), DATE_FORMAT_ISO)
  );

  const { loading, error, data } = useQuery<
    QueryResultReservationEditableDates,
    QueryReservationEditableDatesArgs
  >(GET_RESERVATION_EDITABLE_DATES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("reservationEditableDates"),
    variables: {
      reservationIds: reservationsIdsSelected,
    },
  });

  const [splitOrder] = useMutation<Mutation, MutationSplitOrderArgs>(
    SPLIT_ORDER_MUTATION,
    {
      onError: (error) => {
        handleError(error);
      },
      onCompleted: (result) => {
        setOpen(false);
        if (result.splitOrder?.order && setOrderId) {
          setOrderId(result.splitOrder.order.id);
        }
      },
      update: (cache) => {
        updateCacheReservationAndBreakdowns(cache);
      },
    }
  );

  if (error) return <Error error={error} />;

  const onClickMove = () => {
    splitOrder({
      variables: {
        orderId: order.id,
        reservationIds: reservationsIdsSelected,
        dateEnd: dateReservationEnd,
      },
    });
  };

  const dateSelectionStart = data?.reservationEditableDates
    ? data.reservationEditableDates.dateStart
    : null;
  const dateSelectionEnd = data?.reservationEditableDates
    ? data.reservationEditableDates.dateEnd
    : null;

  return (
    <Dialog open={open}>
      <form>
        <DialogTitle>{t("order_split")}</DialogTitle>
        <DialogContent className={loading ? "loading" : ""}>
          <Paper>
            <MenuList>
              {order.reservationSet.map((reservation) => {
                const selected = reservationsIdsSelected.includes(
                  reservation.id
                );
                return (
                  <MenuItem
                    key={reservation.id}
                    selected={selected}
                    onClick={() => {
                      if (selected) {
                        setReservationsIdsSelected(
                          removeStringFromArr(
                            reservationsIdsSelected,
                            reservation.id
                          )
                        );
                      } else {
                        setReservationsIdsSelected(
                          addStringToArr(
                            reservationsIdsSelected,
                            reservation.id
                          )
                        );
                      }
                    }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        size="lg"
                        icon={selected ? faCheckSquare : faSquare}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      {getReservationProductName(t, reservation)}
                    </ListItemText>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Paper>
          <FormControl className="mt-3" fullWidth>
            <TextField
              label={t("date_order_split")}
              type="date"
              value={dateReservationEnd}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ min: dateSelectionStart, max: dateSelectionEnd }}
              onChange={(event) => {
                setDateReservationEnd(event.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="primary" onClick={onClickMove}>
            {t("reservations_move")}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogOrderSplit);
