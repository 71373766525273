import React from "react";
import { checkPermission } from "../../utils/permissions";

const Authorization =
  (
    permissions: string[],
    permissionsNeeded: string[],
    permissionCheckMethod: string = "OR"
  ) =>
  (
    WrappedComponent: any,
    NoRightsComponent: any = () => {
      return null;
    }
  ) => {
    return class extends React.Component {
      render() {
        const hasPermission = checkPermission(
          permissions,
          permissionsNeeded,
          permissionCheckMethod
        );

        if (hasPermission) {
          return <WrappedComponent {...this.props} />;
        }
        return <NoRightsComponent />;
      }
    };
  };

export default Authorization;
