import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { CustomerType, QueryEventsArgs } from "../../entity/types";
import { useQuery } from "@apollo/client";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import {
  GET_EVENTS_BY_CUSTOMER_QUERY,
  QueryResultEvents,
} from "../../apollo/queries/events";
import CustomerShowEventsTable from "./CustomerShowEventsTable";
import { useTranslation } from "react-i18next";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerType>>;
}

const CustomerShowEvents = ({ classes, customer, setCustomer }: Props) => {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<QueryResultEvents, QueryEventsArgs>(
    GET_EVENTS_BY_CUSTOMER_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("events"),
      variables: { customerId: customer.id },
    }
  );

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <CustomerShowEventsTable
      customer={customer}
      events={data.events ? data.events : []}
      eventsOfferCatalog={
        data.eventsOfferCatalog ? data.eventsOfferCatalog : []
      }
      eventsOfferDiscountList={
        data.eventsOfferDiscountList ? data.eventsOfferDiscountList : []
      }
      discountLists={data.customer ? data.customer.customersDiscountLists : []}
      references={data.customer ? data.customer.referenceSet : []}
      orders={data.customer ? data.customer.orderSet : []}
    />
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CustomerShowEvents);
