import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  DELETE_PRODUCT_CODE_MUTATION,
  UPDATE_PRODUCT_CODE_MUTATION,
} from "../../apollo/mutations/product_codes";
import {
  Mutation,
  MutationDeleteProductCodeArgs,
  MutationUpdateProductCodeArgs,
  ProductCodeType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import ButtonLoad from "../Shared/ButtonLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { dialogConfirm } from "../../utils/dialogs";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  productCode: ProductCodeType;
}

const ProductCodeRow = ({ classes, productCode }: Props) => {
  const { t } = useTranslation();

  const [deleteProductCode, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteProductCodeArgs
  >(DELETE_PRODUCT_CODE_MUTATION, {
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("productCodes"),
      });
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [updateProductCode, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateProductCodeArgs
  >(UPDATE_PRODUCT_CODE_MUTATION, {
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("productCodes"),
      });
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionProductRowChange = checkPermission(myPermissions, [
    "product_codes.change_productcode",
  ]);
  const hasPermissionProductCodeDelete = checkPermission(myPermissions, [
    "product_codes.delete_productcode",
  ]);

  const [productCodeEdit, setProductCodeEdit] = useState({
    ...productCode,
    code: productCode.code,
    description: productCode.description,
  });

  const handleUpdate = (values: { [key: string]: string | number } = {}) => {
    const base: MutationUpdateProductCodeArgs = {
      productCodeId: productCodeEdit.id,
      code: productCodeEdit.code,
      description: productCodeEdit.description,
    };
    updateProductCode({
      variables: { ...base, ...values },
    });
  };

  const onClickDeleteProductCode = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteProductCode({
        variables: {
          productCodeId: productCode.id,
        },
      });
    });
  };

  return (
    <tr className={loadingUpdate ? "loading" : ""}>
      <td>
        <FormControl>
          <TextField
            value={productCodeEdit.code}
            disabled={!hasPermissionProductRowChange}
            onChange={(event) => {
              setProductCodeEdit({
                ...productCodeEdit,
                code: event.target.value,
              });
            }}
            onBlur={(event) => {
              handleUpdate({ code: event.target.value });
            }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl>
          <TextField
            value={productCodeEdit.description}
            disabled={!hasPermissionProductRowChange}
            onChange={(event) => {
              setProductCodeEdit({
                ...productCodeEdit,
                description: event.target.value,
              });
            }}
            onBlur={(event) => {
              handleUpdate({ description: event.target.value });
            }}
          />
        </FormControl>
      </td>
      <td>
        {hasPermissionProductCodeDelete && (
          <ButtonLoad
            loading={loadingDelete}
            variant="light"
            size="sm"
            className={classes.btn}
            onClick={onClickDeleteProductCode}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonLoad>
        )}
      </td>
    </tr>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    btn: {
      minWidth: "2rem",
    },
  });

export default withStyles(styles)(ProductCodeRow);
