import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogExtraRowUnitPriceType,
  Mutation,
  MutationCreateCatalogExtraRowInvoiceArgs,
  ReservationType,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { CREATE_CATALOG_EXTRA_ROW_INVOICE_MUTATION } from "../../apollo/mutations/catalogs_extra";
import { handleError } from "../../entity/ErrorHandler";
import { CatalogExtraRowInvoiceEmpty } from "../../entity/empties";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  catalogExtraRowUnitPrice: CatalogExtraRowUnitPriceType;
  reservation: ReservationType;
  productCodeId: string;
}

function CatalogExtraRowUnitPriceTr({
  classes,
  catalogExtraRowUnitPrice,
  reservation,
  productCodeId,
}: Props) {
  const { t } = useTranslation();

  const [createCatalogExtraRowInvoice] = useMutation<
    Mutation,
    MutationCreateCatalogExtraRowInvoiceArgs
  >(CREATE_CATALOG_EXTRA_ROW_INVOICE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogExtraRowsInvoiceReservation"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "catalogs_extra.add_catalogextrarowinvoice",
  ]);

  const onClickAdd = () => {
    createCatalogExtraRowInvoice({
      variables: {
        catalogExtraRowUnitPriceId: catalogExtraRowUnitPrice.id,
        reservationId: reservation.id,
        productCodeId: productCodeId,
        titleOverride: CatalogExtraRowInvoiceEmpty.titleOverride,
        quantity: catalogExtraRowUnitPrice.minCharge,
        unitPriceOverride: CatalogExtraRowInvoiceEmpty.unitPriceOverride,
        answer: CatalogExtraRowInvoiceEmpty.answer,
      },
    });
  };

  return (
    <tr className="text-muted">
      <td>{catalogExtraRowUnitPrice.name}</td>
      <td></td>
      <td></td>
      <td></td>
      <td>{catalogExtraRowUnitPrice.minCharge}</td>
      <td></td>
      <td>
        {hasPermissionAdd && (
          <Button onClick={onClickAdd} variant="light" title={t("add")}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        )}
      </td>
    </tr>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CatalogExtraRowUnitPriceTr);
