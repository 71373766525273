import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  TableCell,
  TableFooter,
  TableRow,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  CommissionReportRowType,
  CommissionReportType,
  CommissionUserSettingType,
  UserType,
} from "../../entity/types";
import { WithStyles } from "@material-ui/core/styles";
import {
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  tableIcons,
} from "../../utils/TableProps";
import { formatNumber, parseNumber } from "../../utils/formatting";
import MaterialTable, { Column, MTableBody } from "material-table";
import { doesMatchAllTerms } from "../../utils/search";
import { getReservationProductName } from "../../utils/reservations/reservation";
import CommissionHeaderUserSalary from "./CommissionHeaderUserSalary";
import {
  commissionsFilterDateRange,
  commissionsFilterReservation,
  commissionsRenderDateRange,
  commissionsRenderReservation,
} from "../../utils/commissions/commissions";

interface Props extends WithStyles<typeof styles> {
  commissionReport: CommissionReportType;
  user: UserType;
  commissionUserSetting: CommissionUserSettingType;
}

function CommissionReport({
  classes,
  commissionReport,
  user,
  commissionUserSetting,
}: Props) {
  const { t } = useTranslation();

  const columns: Column<CommissionReportRowType>[] = [
    {
      title: t("commission_source"),
      field: "source",
      render: (commissionReportRow) =>
        t("commission_source_" + commissionReportRow.source),
      customFilterAndSearch: (term: string, commissionReportRow) =>
        doesMatchAllTerms(
          term,
          t("commission_source_" + commissionReportRow.source)
        ),
    },
    {
      title: t("customer"),
      field: "reservation.order.customer.name",
    },
    {
      title: t("commission_case"),
      field: "case",
      render: (commissionReportRow) =>
        t("commission_CASE_" + commissionReportRow.case),
      customFilterAndSearch: (term: string, commissionReportRow) =>
        doesMatchAllTerms(
          term,
          t("commission_CASE_" + commissionReportRow.case)
        ),
    },
    {
      title: t("reservation"),
      field: "reservation.id",
      type: "numeric",
      customFilterAndSearch: (term: string, commissionReportRow) =>
        commissionsFilterReservation(t, term, commissionReportRow.reservation),
      render: (commissionReportRow) =>
        commissionsRenderReservation(t, commissionReportRow.reservation),
    },
    {
      title: t("machine"),
      render: (commissionReportRow) =>
        getReservationProductName(t, commissionReportRow.reservation, true),
      customFilterAndSearch: (term: string, commissionReportRow) =>
        doesMatchAllTerms(
          term,
          getReservationProductName(t, commissionReportRow.reservation, true)
        ),
    },
    {
      title: t("date_range_header"),
      field: "dateFrom",
      type: "date",
      render: (commissionReportRow) =>
        commissionsRenderDateRange(t, commissionReportRow),
      customFilterAndSearch: (term: string, commissionReportRow) =>
        commissionsFilterDateRange(t, term, commissionReportRow),
    },
    {
      title: t("commission_period_revenue"),
      field: "revenue",
      type: "numeric",
      render: (commissionReportRow) =>
        formatNumber(commissionReportRow.revenue, 2),
    },
    {
      title: t("discount_percent"),
      field: "discountPercent",
      type: "numeric",
      render: (commissionReportRow) =>
        formatNumber(commissionReportRow.discountPercent, 2),
    },
    {
      title: t("commission_percent"),
      field: "commissionPercent",
      type: "numeric",
      render: (commissionReportRow) =>
        formatNumber(commissionReportRow.commissionPercent, 2),
    },
    {
      title: t("commission_in_eur"),
      field: "commission",
      type: "currency",
      render: (commissionReportRow) =>
        formatNumber(commissionReportRow.commission, 2),
    },
  ];

  const data = (
    commissionReport.commissionreportrowSet
      ? commissionReport.commissionreportrowSet
      : []
  ).map((o) => ({ ...o }));

  return (
    <div className="mt-4">
      <CommissionHeaderUserSalary
        user={user}
        commissionUserSetting={commissionUserSetting}
      />
      <MaterialTable
        style={getTableStyle()}
        title={""}
        localization={GetTableLocalization(t)}
        columns={columns}
        data={data}
        icons={tableIcons}
        options={getTableOptions()}
        components={{
          Body: (props) => {
            let totalObj = {
              revenue: 0,
              commission: 0,
            };
            props.renderData.forEach(
              (commissionReportRow: CommissionReportRowType) => {
                totalObj.revenue += parseNumber(commissionReportRow.revenue);
                totalObj.commission += parseNumber(
                  commissionReportRow.commission
                );
              }
            );
            return (
              <>
                <MTableBody {...props} />
                <TableFooter>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell align="right">
                      {formatNumber(totalObj.revenue)}
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell align="right">
                      {formatNumber(totalObj.commission)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </>
            );
          },
        }}
      />
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CommissionReport);
