import { gql } from "@apollo/client";

export const PROFILE_PERMISSION_DELETE_MUTATION = gql`
  mutation permissionForGroupDelete($groupId: ID!, $permissionId: ID!) {
    permissionForGroupDelete(groupId: $groupId, permissionId: $permissionId) {
      permission {
        id
      }
    }
  }
`;

export const PROFILE_PERMISSION_ADD_MUTATION = gql`
  mutation permissionForGroupAdd($groupId: ID!, $permissionId: ID!) {
    permissionForGroupAdd(groupId: $groupId, permissionId: $permissionId) {
      permission {
        id
      }
    }
  }
`;

export const CREATE_PROFILE_MUTATION = gql`
  mutation createGroup($name: String!) {
    createGroup(name: $name) {
      group {
        id
        name
      }
    }
  }
`;

export const DELETE_PROFILE_MUTATION = gql`
  mutation deleteGroup($groupId: ID!) {
    deleteGroup(groupId: $groupId) {
      groupId
    }
  }
`;

export const UPDATE_PROFILE_MUTATION = gql`
  mutation updateGroup($groupId: ID!, $name: String!) {
    updateGroup(groupId: $groupId, name: $name) {
      group {
        id
        name
      }
    }
  }
`;
