import { gql } from "@apollo/client";
import { FRAG_INVOICE_SHARED } from "../queries/invoices";

export const CREATE_INVOICE_MUTATION = gql`
  ${FRAG_INVOICE_SHARED}
  mutation createInvoice(
    $referenceId: ID!
    $reservationIds: [ID]!
    $transportInvoicingIds: [ID]!
    $transportInvoicingSummaryIds: [ID]!
    $catalogExtraRowInvoiceIds: [ID]!
    $operationHourIds: [ID]!
    $isAdvance: Boolean!
    $dateTo: Date
  ) {
    createInvoice(
      referenceId: $referenceId
      reservationIds: $reservationIds
      transportInvoicingIds: $transportInvoicingIds
      transportInvoicingSummaryIds: $transportInvoicingSummaryIds
      catalogExtraRowInvoiceIds: $catalogExtraRowInvoiceIds
      operationHourIds: $operationHourIds
      isAdvance: $isAdvance
      dateTo: $dateTo
    ) {
      invoice {
        ...InvoiceFields
      }
    }
  }
`;

export const UPDATE_INVOICE_MUTATION = gql`
  mutation updateInvoice($invoiceId: ID!, $information: String!) {
    updateInvoice(invoiceId: $invoiceId, information: $information) {
      invoice {
        id
        information
      }
    }
  }
`;

export const SEND_INVOICE_MUTATION = gql`
  mutation sendInvoice(
    $invoiceId: ID!
    $invoiceSendMethod: InvoiceSendMethods!
    $invoiceDeliveryMethod: InvoiceDeliveryMethods!
    $emailInvoicing: String
  ) {
    sendInvoice(
      invoiceId: $invoiceId
      invoiceSendMethod: $invoiceSendMethod
      invoiceDeliveryMethod: $invoiceDeliveryMethod
      emailInvoicing: $emailInvoicing
    ) {
      invoice {
        id
        status
        sentAt
        invoicerowSet {
          id
        }
      }
    }
  }
`;

export const SEND_INVOICE_PAYMENT_MUTATION = gql`
  mutation sendInvoicePayment($invoiceId: ID!) {
    sendInvoicePayment(invoiceId: $invoiceId) {
      invoice {
        id
      }
    }
  }
`;

export const DO_INVOICE_APPROVE_MUTATION = gql`
  mutation doInvoiceApprove($invoiceId: ID!) {
    doInvoiceApprove(invoiceId: $invoiceId) {
      invoice {
        id
      }
    }
  }
`;

export const DO_INVOICE_SEND_MUTATION = gql`
  mutation doInvoiceSend($invoiceId: ID!) {
    doInvoiceSend(invoiceId: $invoiceId) {
      invoice {
        id
      }
    }
  }
`;

export const UPDATE_INVOICE_ROW_MUTATION = gql`
  mutation updateInvoiceRow(
    $invoiceRowId: ID!
    $productNumber: String!
    $title: String!
    $description: String!
    $quantity: Decimal!
    $unitPrice: Decimal!
  ) {
    updateInvoiceRow(
      invoiceRowId: $invoiceRowId
      productNumber: $productNumber
      title: $title
      description: $description
      quantity: $quantity
      unitPrice: $unitPrice
    ) {
      invoiceRow {
        id
        productNumber
        title
        description
        quantity
        unitPrice
      }
    }
  }
`;
