import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Button, Image } from "react-bootstrap";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  src: string;
}

function DialogImage({ classes, open, setOpen, src }: Props) {
  const { t } = useTranslation();

  const onClickClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogContent>
        <Image src={src} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickClose} variant="secondary">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogImage);
