import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  Paper,
  TextField,
  Theme,
} from "@material-ui/core";
import { PermissionsContext, SettingsContext } from "../Root";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { formatNumber, parseNumber } from "../utils/formatting";
import { useMutation } from "@apollo/client";
import { handleError } from "../entity/ErrorHandler";
import { UPDATE_SETTINGS_MUTATION } from "../apollo/mutations/settings";
import { checkPermission } from "../utils/permissions";
import { Mutation, MutationUpdateSettingsArgs } from "../entity/types";
import { CONTAINER_MAIN_WIDTH_S } from "../utils/constants";
import TextFieldFocus from "../components/Shared/TextFieldFocus";
import HeaderPage from "../components/Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {}

function SettingsOther({ classes }: Props) {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);

  const [settingsEdited, setSettingsEdited] = useState({
    ...settings,
    vat: formatNumber(settings.vat, 1),
    insurancePercent: formatNumber(settings.insurancePercent, 2),
    billingSurchargeAmount: formatNumber(settings.billingSurchargeAmount, 2),
    billingSurchargeLimit: formatNumber(settings.billingSurchargeLimit, 2),
    openingFeeAmount: formatNumber(settings.openingFeeAmount, 2),
    openingFeeLimit: formatNumber(settings.openingFeeLimit, 2),
  });

  const [updateSettings, { loading }] = useMutation<
    Mutation,
    MutationUpdateSettingsArgs
  >(UPDATE_SETTINGS_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "settings.change_settings",
  ]);

  const handleUpdateSettings = (values: { [key: string]: string | number }) => {
    const base: MutationUpdateSettingsArgs = {
      vat: parseNumber(settingsEdited.vat),
      productNumberSingle: settingsEdited.productNumberSingle,
      productNumberOpHours: settingsEdited.productNumberOpHours,
      billingSurchargeAmount: parseNumber(
        settingsEdited.billingSurchargeAmount
      ),
      billingSurchargeLimit: parseNumber(settingsEdited.billingSurchargeLimit),
      insurancePercent: parseNumber(settingsEdited.insurancePercent),
      productNumberInsurance: settingsEdited.productNumberInsurance,
      productNumberBillingSurcharge:
        settingsEdited.productNumberBillingSurcharge,
      productNumberOpeningFee: settingsEdited.productNumberOpeningFee,
      openingFeeAmount: parseNumber(settingsEdited.openingFeeAmount),
      openingFeeLimit: parseNumber(settingsEdited.openingFeeLimit),
      textConfirmation1: settingsEdited.textConfirmation1,
      textConfirmation2: settingsEdited.textConfirmation2,
      textOffer0: settingsEdited.textOffer0,
      textOffer1: settingsEdited.textOffer1,
      textOffer2: settingsEdited.textOffer2,
      textContract1: settingsEdited.textContract1,
      textContract2: settingsEdited.textContract2,
    };

    updateSettings({
      variables: { ...base, ...values },
    });
  };

  return (
    <Container fluid className={`containerMain ${classes.container}`}>
      <HeaderPage title={t("settings_other")} />
      <div className={`containerInner ${loading ? "loading" : ""}`}>
        <div>
          <FormControl className={classes.txt}>
            <TextFieldFocus
              label={t("vat_percent")}
              value={settingsEdited.vat}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setSettingsEdited({
                  ...settingsEdited,
                  vat: event.target.value,
                });
              }}
              onBlur={() => {
                const vat = parseNumber(settingsEdited.vat);
                setSettingsEdited({
                  ...settingsEdited,
                  vat: formatNumber(vat, 1),
                });
                handleUpdateSettings({
                  vat: vat.toFixed(1),
                });
              }}
            />
          </FormControl>
        </div>
        <div>
          <FormControl className={classes.txt}>
            <TextField
              label={t("product_number_single")}
              value={settingsEdited.productNumberSingle}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setSettingsEdited({
                  ...settingsEdited,
                  productNumberSingle: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdateSettings({
                  productNumberSingle: settingsEdited.productNumberSingle,
                });
              }}
            />
          </FormControl>
        </div>
        <div>
          <FormControl className={classes.txt}>
            <TextField
              label={t("product_number_operation_hours")}
              value={settingsEdited.productNumberOpHours}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setSettingsEdited({
                  ...settingsEdited,
                  productNumberOpHours: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdateSettings({
                  productNumberOpHours: settingsEdited.productNumberOpHours,
                });
              }}
            />
          </FormControl>
        </div>
        <h4 className="mt-4">{t("settings_catalog_other")}</h4>
        <Paper variant="outlined" className="mt-3 p-3 pb-2">
          <FormControl className={classes.txt}>
            <TextFieldFocus
              label={t("insurance_percent")}
              value={settingsEdited.insurancePercent}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setSettingsEdited({
                  ...settingsEdited,
                  insurancePercent: event.target.value,
                });
              }}
              onBlur={() => {
                const insurancePercent = parseNumber(
                  settingsEdited.insurancePercent
                );
                setSettingsEdited({
                  ...settingsEdited,
                  insurancePercent: formatNumber(insurancePercent, 2),
                });
                handleUpdateSettings({
                  insurancePercent: insurancePercent.toFixed(2),
                });
              }}
            />
          </FormControl>
          <div className={classes.suffix}>{t("insurance_percent_suffix")}</div>
          <div>
            <FormControl className={classes.txt}>
              <TextField
                label={t("product_number")}
                value={settingsEdited.productNumberInsurance}
                disabled={!hasPermissionEdit}
                onChange={(event) => {
                  setSettingsEdited({
                    ...settingsEdited,
                    productNumberInsurance: event.target.value,
                  });
                }}
                onBlur={() => {
                  handleUpdateSettings({
                    productNumberInsurance:
                      settingsEdited.productNumberInsurance,
                  });
                }}
              />
            </FormControl>
          </div>
        </Paper>
        <Paper variant="outlined" className="mt-3 p-3 pb-2">
          <FormControl className={classes.txt}>
            <TextFieldFocus
              label={t("billing_surcharge_amount")}
              value={settingsEdited.billingSurchargeAmount}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setSettingsEdited({
                  ...settingsEdited,
                  billingSurchargeAmount: event.target.value,
                });
              }}
              onBlur={() => {
                const billingSurchargeAmount = parseNumber(
                  settingsEdited.billingSurchargeAmount
                );
                setSettingsEdited({
                  ...settingsEdited,
                  billingSurchargeAmount: formatNumber(
                    billingSurchargeAmount,
                    2
                  ),
                });
                handleUpdateSettings({
                  billingSurchargeAmount: billingSurchargeAmount.toFixed(2),
                });
              }}
            />
          </FormControl>
          <div className={classes.suffix}>
            {t("billing_surcharge_amount_suffix")}
          </div>
          <div>
            <FormControl className={classes.txt}>
              <TextFieldFocus
                label={t("billing_surcharge_limit")}
                value={settingsEdited.billingSurchargeLimit}
                disabled={!hasPermissionEdit}
                onChange={(event) => {
                  setSettingsEdited({
                    ...settingsEdited,
                    billingSurchargeLimit: event.target.value,
                  });
                }}
                onBlur={() => {
                  const billingSurchargeLimit = parseNumber(
                    settingsEdited.billingSurchargeLimit
                  );
                  setSettingsEdited({
                    ...settingsEdited,
                    billingSurchargeLimit: formatNumber(
                      billingSurchargeLimit,
                      2
                    ),
                  });
                  handleUpdateSettings({
                    billingSurchargeLimit: billingSurchargeLimit.toFixed(2),
                  });
                }}
              />
            </FormControl>
            <div className={classes.suffix}>
              {t("billing_surcharge_limit_suffix")}
            </div>
          </div>
          <div>
            <FormControl className={classes.txt}>
              <TextField
                label={t("product_number")}
                value={settingsEdited.productNumberBillingSurcharge}
                disabled={!hasPermissionEdit}
                onChange={(event) => {
                  setSettingsEdited({
                    ...settingsEdited,
                    productNumberBillingSurcharge: event.target.value,
                  });
                }}
                onBlur={() => {
                  handleUpdateSettings({
                    productNumberBillingSurcharge:
                      settingsEdited.productNumberBillingSurcharge,
                  });
                }}
              />
            </FormControl>
          </div>
        </Paper>
        <Paper variant="outlined" className="mt-3 p-3 pb-2">
          <FormControl className={classes.txt}>
            <TextFieldFocus
              label={t("opening_fee_amount")}
              value={settingsEdited.openingFeeAmount}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setSettingsEdited({
                  ...settingsEdited,
                  openingFeeAmount: event.target.value,
                });
              }}
              onBlur={() => {
                const openingFeeAmount = parseNumber(
                  settingsEdited.openingFeeAmount
                );
                setSettingsEdited({
                  ...settingsEdited,
                  openingFeeAmount: formatNumber(openingFeeAmount, 2),
                });
                handleUpdateSettings({
                  openingFeeAmount: openingFeeAmount.toFixed(2),
                });
              }}
            />
          </FormControl>
          <div className={classes.suffix}>{t("opening_fee_amount_suffix")}</div>
          <div>
            <FormControl className={classes.txt}>
              <TextFieldFocus
                label={t("opening_fee_limit")}
                value={settingsEdited.openingFeeLimit}
                disabled={!hasPermissionEdit}
                onChange={(event) => {
                  setSettingsEdited({
                    ...settingsEdited,
                    openingFeeLimit: event.target.value,
                  });
                }}
                onBlur={() => {
                  const openingFeeLimit = parseNumber(
                    settingsEdited.openingFeeLimit
                  );
                  setSettingsEdited({
                    ...settingsEdited,
                    openingFeeLimit: formatNumber(openingFeeLimit, 2),
                  });
                  handleUpdateSettings({
                    openingFeeLimit: openingFeeLimit.toFixed(2),
                  });
                }}
              />
            </FormControl>
            <div className={classes.suffix}>
              {t("opening_fee_limit_suffix")}
            </div>
          </div>
          <div>
            <FormControl className={classes.txt}>
              <TextField
                label={t("product_number")}
                value={settingsEdited.productNumberOpeningFee}
                disabled={!hasPermissionEdit}
                onChange={(event) => {
                  setSettingsEdited({
                    ...settingsEdited,
                    productNumberOpeningFee: event.target.value,
                  });
                }}
                onBlur={() => {
                  handleUpdateSettings({
                    productNumberOpeningFee:
                      settingsEdited.productNumberOpeningFee,
                  });
                }}
              />
            </FormControl>
          </div>
        </Paper>
        <h4 className="mt-5">{t("settings_pdf_texts")}</h4>
        <Paper variant="outlined" className="mt-3 p-3 pb-2">
          <FormControl fullWidth className={classes.txt}>
            <TextField
              label={t("pdf_text_confirmation_1")}
              value={settingsEdited.textConfirmation1}
              disabled={!hasPermissionEdit}
              multiline
              minRows={2}
              onChange={(event) => {
                setSettingsEdited({
                  ...settingsEdited,
                  textConfirmation1: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdateSettings({
                  textConfirmation1: settingsEdited.textConfirmation1,
                });
              }}
            />
          </FormControl>
          <FormControl fullWidth className={classes.txt}>
            <TextField
              label={t("pdf_text_confirmation_2")}
              value={settingsEdited.textConfirmation2}
              disabled={!hasPermissionEdit}
              multiline
              minRows={2}
              onChange={(event) => {
                setSettingsEdited({
                  ...settingsEdited,
                  textConfirmation2: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdateSettings({
                  textConfirmation2: settingsEdited.textConfirmation2,
                });
              }}
            />
          </FormControl>

          <FormControl fullWidth className={`${classes.txt} mt-4`}>
            <TextField
              label={t("pdf_text_offer_0")}
              value={settingsEdited.textOffer0}
              disabled={!hasPermissionEdit}
              multiline
              minRows={2}
              onChange={(event) => {
                setSettingsEdited({
                  ...settingsEdited,
                  textOffer0: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdateSettings({
                  textOffer0: settingsEdited.textOffer0,
                });
              }}
            />
          </FormControl>
          <FormControl fullWidth className={classes.txt}>
            <TextField
              label={t("pdf_text_offer_1")}
              value={settingsEdited.textOffer1}
              disabled={!hasPermissionEdit}
              multiline
              minRows={2}
              onChange={(event) => {
                setSettingsEdited({
                  ...settingsEdited,
                  textOffer1: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdateSettings({
                  textOffer1: settingsEdited.textOffer1,
                });
              }}
            />
          </FormControl>
          <FormControl fullWidth className={classes.txt}>
            <TextField
              label={t("pdf_text_offer_2")}
              value={settingsEdited.textOffer2}
              disabled={!hasPermissionEdit}
              multiline
              minRows={2}
              onChange={(event) => {
                setSettingsEdited({
                  ...settingsEdited,
                  textOffer2: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdateSettings({
                  textOffer2: settingsEdited.textOffer2,
                });
              }}
            />
          </FormControl>

          <FormControl fullWidth className={`${classes.txt} mt-4`}>
            <TextField
              label={t("pdf_text_contract_1")}
              value={settingsEdited.textContract1}
              disabled={!hasPermissionEdit}
              multiline
              minRows={2}
              onChange={(event) => {
                setSettingsEdited({
                  ...settingsEdited,
                  textContract1: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdateSettings({
                  textContract1: settingsEdited.textContract1,
                });
              }}
            />
          </FormControl>
          <FormControl fullWidth className={classes.txt}>
            <TextField
              label={t("pdf_text_contract_2")}
              value={settingsEdited.textContract2}
              disabled={!hasPermissionEdit}
              multiline
              minRows={2}
              onChange={(event) => {
                setSettingsEdited({
                  ...settingsEdited,
                  textContract2: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdateSettings({
                  textContract2: settingsEdited.textContract2,
                });
              }}
            />
          </FormControl>
        </Paper>
      </div>
    </Container>
  );
}

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    container: {
      maxWidth: CONTAINER_MAIN_WIDTH_S,
    },
    txt: {
      minWidth: "300px",
      marginBottom: spacing(3),
    },
    suffix: {
      paddingTop: "1.3rem",
      display: "inline-block",
      marginLeft: spacing(1),

      [breakpoints.down("sm")]: {
        marginTop: spacing(-2),
        marginLeft: 0,
        paddingTop: 0,
        paddingBottom: spacing(1),
        display: "block",
      },
    },
  });

export default withStyles(styles)(SettingsOther);
