import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { OrderType } from "../../entity/types";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import DialogOrderSplit from "./DialogOrderSplit";

interface Props extends WithStyles<typeof styles> {
  order: OrderType;
  setOrderId?: React.Dispatch<React.SetStateAction<string>>;
}

function ButtonOrderSplit({ classes, order, setOrderId }: Props) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddReservation = checkPermission(myPermissions, [
    "reservations.add_reservation",
  ]);
  const hasPermissionChangeReservation = checkPermission(myPermissions, [
    "reservations.change_reservation",
  ]);
  const hasPermissionAddOrder = checkPermission(myPermissions, [
    "orders.add_order",
  ]);
  const hasPermissionChangeOrder = checkPermission(myPermissions, [
    "orders.change_order",
  ]);

  const disabled =
    !hasPermissionAddReservation ||
    !hasPermissionChangeReservation ||
    !hasPermissionAddOrder ||
    !hasPermissionChangeOrder;

  return (
    <>
      <Button
        variant="light"
        onClick={() => {
          setOpen(!open);
        }}
        disabled={disabled}
      >
        {t("order_split")}
      </Button>
      <DialogOrderSplit
        open={open}
        setOpen={setOpen}
        order={order}
        setOrderId={setOrderId}
      />
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ButtonOrderSplit);
