import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { CREATE_PROFILE_MUTATION } from "../../apollo/mutations/profiles";
import { Button } from "react-bootstrap";
import { handleError } from "../../entity/ErrorHandler";
import { GET_PROFILES_QUERY } from "../../apollo/queries/profiles";
import { PermissionsContext } from "../../Root";
import Authorization from "../Auth/Authorization";
import { Mutation, MutationCreateGroupArgs } from "../../entity/types";

interface Props extends WithStyles<typeof styles> {}

function CreateProfile({ classes }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const permissions = useContext(PermissionsContext);
  const Auth = {
    ProfileCreate: Authorization(permissions, ["auth.add_group"]),
  };

  const [createProfile] = useMutation<Mutation, MutationCreateGroupArgs>(
    CREATE_PROFILE_MUTATION,
    {
      refetchQueries: [{ query: GET_PROFILES_QUERY }],
      onCompleted: (result) => {
        setName("");
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createProfile({ variables: { name } });
    setOpen(false);
  };

  const ButtonProfile = Auth.ProfileCreate(() => {
    return (
      <Button
        onClick={() => setOpen(true)}
        className="addButton"
        variant="primary"
        size="lg"
      >
        {open ? <ClearIcon /> : <AddIcon />}
      </Button>
    );
  });

  return (
    <>
      <ButtonProfile />
      <Dialog open={open}>
        <form onSubmit={(event) => handleSubmit(event)}>
          <DialogTitle>{t("new_profile")}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                label={t("name")}
                className={classes.textField}
                onChange={(event) => setName(event.target.value)}
                value={name}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="secondary">
              {t("cancel")}
            </Button>
            <Button disabled={!name.trim()} type="submit" variant="primary">
              {t("save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    textField: {
      margin: spacing(1),
    },
  });

export default withStyles(styles)(CreateProfile);
