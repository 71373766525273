import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import {
  CatalogExtraCategoryRentalType,
  CatalogExtraCategoryUpperRentalType,
  Mutation,
  MutationCreateCatalogExtraCategoryRentalArgs,
  MutationUpdateCatalogExtraCategoryRentalArgs,
} from "../../entity/types";
import DialogContentCatalogExtraCategoryRental from "./DialogContentCatalogExtraCategoryRental";
import { useMutation } from "@apollo/client";
import { CatalogExtraCategoryRentalEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import {
  CREATE_CATALOG_EXTRA_CATEGORY_RENTAL_MUTATION,
  UPDATE_CATALOG_EXTRA_CATEGORY_RENTAL_MUTATION,
} from "../../apollo/mutations/catalogs_extra";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalogExtraCategoryRental: CatalogExtraCategoryRentalType;
  catalogExtraCategoriesUpperRental: CatalogExtraCategoryUpperRentalType[];
}

function DialogCatalogExtraCategoryRental({
  classes,
  open,
  setOpen,
  catalogExtraCategoryRental,
  catalogExtraCategoriesUpperRental,
}: Props) {
  const { t } = useTranslation();
  const [
    catalogExtraCategoryRentalEdited,
    setCatalogExtraCategoryRentalEdited,
  ] = useState(catalogExtraCategoryRental);

  const [createCatalogExtraCategoryRental, { loading: loadingCreate }] =
    useMutation<Mutation, MutationCreateCatalogExtraCategoryRentalArgs>(
      CREATE_CATALOG_EXTRA_CATEGORY_RENTAL_MUTATION,
      {
        onCompleted: (result) => {
          setCatalogExtraCategoryRentalEdited(CatalogExtraCategoryRentalEmpty);
        },
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("catalogExtraCategoriesUpperRental"),
          });
        },
      }
    );
  const [updateCatalogExtraCategoryRental, { loading: loadingUpdate }] =
    useMutation<Mutation, MutationUpdateCatalogExtraCategoryRentalArgs>(
      UPDATE_CATALOG_EXTRA_CATEGORY_RENTAL_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("catalogExtraCategoriesUpperRental"),
          });
        },
      }
    );

  const handleSubmitCatalogExtra = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (catalogExtraCategoryRentalEdited.id !== ID_EMPTY) {
      updateCatalogExtraCategoryRental({
        variables: {
          catalogExtraCategoryRentalId: catalogExtraCategoryRentalEdited.id,
          catalogExtraCategoryUpperRentalId:
            catalogExtraCategoryRentalEdited.catalogExtraCategoryUpperRental.id,
          name: catalogExtraCategoryRentalEdited.name,
        },
      });
    } else {
      createCatalogExtraCategoryRental({
        variables: {
          catalogExtraCategoryUpperRentalId:
            catalogExtraCategoryRentalEdited.catalogExtraCategoryUpperRental.id,
          name: catalogExtraCategoryRentalEdited.name,
        },
      });
    }
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <form
        className={loadingCreate || loadingUpdate ? "loading" : ""}
        onSubmit={(event) => handleSubmitCatalogExtra(event)}
      >
        <DialogTitle>
          {t(
            catalogExtraCategoryRentalEdited.id !== ID_EMPTY
              ? "edit_catalog_extra_category"
              : "new_catalog_extra_category"
          )}
        </DialogTitle>
        <DialogContentCatalogExtraCategoryRental
          catalogExtraCategoryRental={catalogExtraCategoryRentalEdited}
          setCatalogExtraCategoryRental={setCatalogExtraCategoryRentalEdited}
          catalogExtraCategoriesUpperRental={catalogExtraCategoriesUpperRental}
        />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <Button
            disabled={!catalogExtraCategoryRentalEdited.name.trim()}
            type="submit"
            variant="primary"
          >
            {t("save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCatalogExtraCategoryRental);
