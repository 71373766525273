import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  Box,
  createStyles,
  Dialog,
  DialogContent,
  Hidden,
  Tab,
  Theme,
} from "@material-ui/core";
import {
  QueryReservationImageGivesArgs,
  QueryReservationImageReturnsArgs,
  ReservationImageType,
  ReservationType,
} from "../../entity/types";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentInspectionReturnAnswers from "../InspectionReturn/DialogContentInspectionReturnAnswers";
import DialogContentInspectionGiveAnswers from "../InspectionGive/DialogContentInspectionGiveAnswers";
import ReservationImages from "../WorkQueues/ReservationImages";
import { useQuery } from "@apollo/client";
import {
  GET_RESERVATION_IMAGE_GIVES_QUERY,
  GET_RESERVATION_IMAGE_RETURNS_QUERY,
  QueryResultReservationImageGives,
  QueryResultReservationImageReturns,
} from "../../apollo/queries/reservations";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import Error from "../Shared/Error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faTasks,
  faWarehouseAlt,
} from "@fortawesome/pro-light-svg-icons";
import { getReservationProductName } from "../../utils/reservations/reservation";
import format from "date-fns/format";
import LoadingDialog from "../Shared/LoadingDialog";
import ReservationImagesBasic from "./ReservationImagesBasic";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reservation: ReservationType;
}

function DialogReservationInfo({ classes, open, setOpen, reservation }: Props) {
  const { t } = useTranslation();
  const [tabSelected, setTabSelected] = useState("1");

  const handleChange = (event: React.ChangeEvent<{}>, newTab: string) => {
    setTabSelected(newTab);
  };

  const {
    loading: loadingGives,
    error: errorGives,
    data: dataGives,
  } = useQuery<
    QueryResultReservationImageGives,
    QueryReservationImageGivesArgs
  >(GET_RESERVATION_IMAGE_GIVES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("reservationImageGives"),
    variables: {
      reservationId: reservation.id,
    },
  });

  const {
    loading: loadingReturns,
    error: errorReturns,
    data: dataReturns,
  } = useQuery<
    QueryResultReservationImageReturns,
    QueryReservationImageReturnsArgs
  >(GET_RESERVATION_IMAGE_RETURNS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("reservationImageReturns"),
    variables: {
      reservationId: reservation.id,
    },
  });

  if (loadingGives || loadingReturns) return <LoadingDialog />;
  if (!loadingGives && errorGives) return <Error error={errorGives} />;
  if (!loadingReturns && errorReturns) return <Error error={errorReturns} />;

  const reservationGiveImages: ReservationImageType[] =
    dataGives && dataGives.reservationImageGives
      ? dataGives.reservationImageGives
      : [];

  const reservationReturnImages: ReservationImageType[] =
    dataReturns && dataReturns.reservationImageReturns
      ? dataReturns.reservationImageReturns
      : [];

  const dateRented = new Date(
    reservation.giveAt ? reservation.giveAt : reservation.dateRented
  );
  const dateReturned = new Date(
    reservation.returnAt ? reservation.returnAt : reservation.dateReturned
  );

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitleWithClose
        id="dialogReservationInfo"
        onClose={() => setOpen(false)}
      >
        {getReservationProductName(t, reservation, true) + " / "}
        {format(dateRented, t("format_datetime")) +
          " - " +
          format(dateReturned, t("format_datetime"))}
      </DialogTitleWithClose>
      <DialogContent className={classes.dialogContent}>
        <TabContext value={tabSelected}>
          <Box>
            <TabList onChange={handleChange}>
              <Tab
                className={classes.tabStyle}
                title={t("inspection_give")}
                key="1"
                icon={
                  <FontAwesomeIcon
                    icon={faWarehouseAlt}
                    className={classes.iconSize}
                  />
                }
                label={<Hidden smDown>{t("inspection_give")} </Hidden>}
                value="1"
              />
              <Tab
                key="2"
                className={classes.tabStyle}
                title={t("toggle_give_images_at")}
                icon={
                  <FontAwesomeIcon
                    icon={faCamera}
                    className={classes.iconSize}
                  />
                }
                label={<Hidden smDown>{t("toggle_give_images_at")} </Hidden>}
                value="2"
              />
              <Tab
                key="3"
                className={classes.tabStyle}
                title={t("toggle_return_images_at")}
                icon={
                  <FontAwesomeIcon
                    icon={faCamera}
                    className={classes.iconSize}
                  />
                }
                label={<Hidden smDown>{t("toggle_return_images_at")} </Hidden>}
                value="3"
              />
              <Tab
                key="4"
                className={classes.tabStyle}
                title={t("reservation_images")}
                icon={
                  <FontAwesomeIcon
                    icon={faCamera}
                    className={classes.iconSize}
                  />
                }
                label={<Hidden smDown>{t("reservation_images")} </Hidden>}
                value="4"
              />
              <Tab
                key="5"
                className={classes.tabStyle}
                title={t("inspection_return")}
                icon={
                  <FontAwesomeIcon
                    icon={faTasks}
                    className={classes.iconSize}
                  />
                }
                label={<Hidden smDown>{t("inspection_return")} </Hidden>}
                value="5"
              />
            </TabList>
          </Box>
          <TabPanel key="1" value="1">
            <DialogContentInspectionGiveAnswers
              reservation={reservation}
              editable={true}
            />
          </TabPanel>
          <TabPanel key="2" value="2">
            <ReservationImages reservationImages={reservationGiveImages} />
          </TabPanel>
          <TabPanel key="3" value="3">
            <ReservationImages reservationImages={reservationReturnImages} />
          </TabPanel>
          <TabPanel key="4" value="4">
            <ReservationImagesBasic reservationId={reservation.id} />
          </TabPanel>
          <TabPanel key="5" value="5">
            <DialogContentInspectionReturnAnswers
              reservation={reservation}
              editable={true}
            />
          </TabPanel>
        </TabContext>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    tabStyle: {
      width: "20%",
      minWidth: "20%",
      padding: "0",
      margin: "0",
    },
    dialogContent: {
      minHeight: "80vh",
      [theme.breakpoints.down("sm")]: {
        padding: "0",
      },
    },
    iconSize: {
      fontSize: "1.5em",
    },
  });

export default withStyles(styles)(DialogReservationInfo);
