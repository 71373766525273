import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogCategoryType,
  LocationType,
  ReservationType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../Root";
import { getLocationIdDefault } from "../../utils/locations/location";
import { ProgressBar } from "react-bootstrap";
import { LocationEmpty, MachineEmpty } from "../../entity/empties";
import { ID_EMPTY } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  catalogCategory: CatalogCategoryType;
  reservationsToday: ReservationType[];
}

function EfficiencyCatalogCategory({
  classes,
  catalogCategory,
  reservationsToday,
}: Props) {
  const { t } = useTranslation();

  const user = useContext(UserContext);
  const locationIdDefault = getLocationIdDefault(user);

  let machineReservedToday: { [machine_id: string]: boolean } = {};
  reservationsToday.forEach((reservation) => {
    const machine = reservation.machine ? reservation.machine : MachineEmpty;
    machineReservedToday[machine.id] = true;
  });

  let count: { [locationId: string]: number } = {
    [ID_EMPTY]: 0,
  };
  let total: { [locationId: string]: number } = {
    [ID_EMPTY]: 0,
  };
  let locations: LocationType[] = [];
  catalogCategory.catalogrowSet.forEach((catalogRow) => {
    catalogRow.machineSet.forEach((machine) => {
      const key = machine.location ? machine.location.id : ID_EMPTY;
      if (typeof total[key] === "undefined") {
        total[key] = 0;
      }
      total[key]++;
      total[ID_EMPTY]++;

      if (typeof machineReservedToday[machine.id] !== "undefined") {
        if (typeof count[key] === "undefined") {
          count[key] = 0;
        }
        count[key]++;
        count[ID_EMPTY]++;
      }

      if (machine.location && !locations.includes(machine.location)) {
        locations.push(machine.location);
      }
    });
  });
  locations.push(LocationEmpty);
  const percent: { [locationId: string]: number } = {};
  locations.forEach((location) => {
    if (typeof count[location.id] === "undefined") {
      count[location.id] = 0;
    }
    if (typeof percent[location.id] === "undefined") {
      percent[location.id] = 0;
    }
    percent[location.id] =
      total[location.id] !== 0
        ? Math.round((count[location.id] / total[location.id]) * 100)
        : 0;
  });

  return (
    <div className={classes.conEfficiencyCatalogCategory}>
      {locations.map((location) => {
        if (location.id === ID_EMPTY || location.id === locationIdDefault) {
          return (
            <div
              key={location.id}
              className={classes.efficiencyCatalogCategory}
            >
              <span className="text-muted pe-1">
                {location.id === ID_EMPTY ? t("all") : location.name}
              </span>
              <span className="text-muted d-none d-md-inline">
                {t("x_of_x", {
                  count: count[location.id],
                  total: total[location.id],
                })}
              </span>
              <ProgressBar
                now={percent[location.id]}
                label={`${percent[location.id]}%`}
                variant="info"
              />
            </div>
          );
        }
        return <React.Fragment key={location.id}></React.Fragment>;
      })}
    </div>
  );
}

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    conEfficiencyCatalogCategory: {
      width: "33%",
      display: "flex",
    },
    efficiencyCatalogCategory: {
      marginBottom: spacing(1),
      marginRight: spacing(1),
      width: "100px",
    },
  });

export default withStyles(styles)(EfficiencyCatalogCategory);
