import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import {
  URL_REPORTS_COMMISSIONS,
  URL_INVOICES_CREATE,
  URL_INVOICES_OPEN,
  URL_INVOICES_SENT,
} from "../../utils/urls";

type Props = {
  classes: ClassNameMap;
  Auth: any;
};

const AppButtonManagement = ({ classes, Auth }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const LinkManagementInvoicesCreate = Auth.ManagementInvoicesCreate(() => {
    return (
      <LinkContainer to={URL_INVOICES_CREATE}>
        <MenuItem onClick={handleClose}>
          {t("management_invoices_create")}
        </MenuItem>
      </LinkContainer>
    );
  });
  const LinkManagementInvoicesOpen = Auth.ManagementInvoicesOpen(() => {
    return (
      <LinkContainer to={URL_INVOICES_OPEN}>
        <MenuItem onClick={handleClose}>
          {t("management_invoices_open")}
        </MenuItem>
      </LinkContainer>
    );
  });
  const LinkManagementInvoicesSent = Auth.ManagementInvoicesSent(() => {
    return (
      <LinkContainer to={URL_INVOICES_SENT}>
        <MenuItem onClick={handleClose}>
          {t("management_invoices_sent")}
        </MenuItem>
      </LinkContainer>
    );
  });
  const LinkManagementCommissions = Auth.ManagementCommissions(() => {
    return (
      <LinkContainer to={URL_REPORTS_COMMISSIONS}>
        <MenuItem onClick={handleClose}>
          {t("management_commission_report")}
        </MenuItem>
      </LinkContainer>
    );
  });

  return (
    <div>
      <Button
        className={classes.btn}
        color="primary"
        aria-controls="menuManagement"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <div className={classes.faIconCon}>
          <FontAwesomeIcon size="lg" icon={faChartLine} />
        </div>
        <div>{t("management")}</div>
      </Button>
      <Menu
        id="menuManagement"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <LinkManagementInvoicesCreate />
        <LinkManagementInvoicesOpen />
        <LinkManagementInvoicesSent />
        <LinkManagementCommissions />
      </Menu>
    </div>
  );
};

export default AppButtonManagement;
