import { DiscountListType, DiscountRowType } from "../../entity/types";
import { getPercentPrefixDB } from "./prefixes";
import { formatNumber } from "../formatting";

export function formatPercentDiscountList(discountList: DiscountListType) {
  const discountRows = discountList.discountrowSet.map(
    (discountRowLooped: DiscountRowType) => ({
      ...discountRowLooped,
      percent:
        getPercentPrefixDB(discountRowLooped.percent) +
        formatNumber(discountRowLooped.percent, 2),
    })
  );
  return { ...discountList, discountrowSet: discountRows };
}
