import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  Menu,
  MenuItem,
  Theme,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import {
  GroupType,
  LocationType,
  Mutation,
  MutationDeleteUserArgs,
  MutationUpdateUserArgs,
  UserType,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import {
  DELETE_USER_MUTATION,
  UPDATE_USER_MUTATION,
} from "../../apollo/mutations/users";
import DialogContentUser from "./DialogContentUser";
import { Button as ButtonBootstrap } from "react-bootstrap";
import { UserEmpty } from "../../entity/empties";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { dialogConfirm } from "../../utils/dialogs";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { hasUserRequired } from "../../utils/users/user";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  user: UserType;
  groups: GroupType[];
  locations: LocationType[];
}

const UserListMenu = ({ classes, user, groups, locations }: Props) => {
  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userSelected, setUserSelected] = useState<UserType>(UserEmpty);

  const [deleteUser, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteUserArgs
  >(DELETE_USER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("users"),
      });
    },
  });

  const [updateUser] = useMutation<Mutation, MutationUpdateUserArgs>(
    UPDATE_USER_MUTATION,
    {
      onCompleted: (result) => {
        setUserSelected(UserEmpty);
        setOpenEdit(false);
      },
      onError: (error) => {
        handleError(error);
      },
      update: (cache) => {
        cache.evict({
          id: ROOT_QUERY,
          fieldName: getQueryKey("users"),
        });
      },
    }
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "auth.change_user",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "auth.delete_user",
  ]);

  if (!hasPermissionEdit && !hasPermissionDelete) {
    return <></>;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickEdit = () => {
    handleClose();
    user.password = "";
    setUserSelected(user);
    setOpenEdit(true);
  };

  const handleClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteUser({ variables: { userId: user.id } }).then(() => {
        handleClose();
      });
    });
  };

  const handleSubmitEdit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateUser({
      variables: {
        userId: userSelected.id,
        firstName: userSelected.firstName,
        lastName: userSelected.lastName,
        email: userSelected.email,
        password: userSelected.password,
        phone: userSelected.UserInfo?.phone,
        address: userSelected.UserInfo?.address,
        postcode: userSelected.UserInfo?.postcode,
        district: userSelected.UserInfo?.district,
        language: userSelected.UserInfo?.language.toLowerCase()
      },
    });
  };

  return (
    <div>
      <Button
        className="btnMaterialTableMenu"
        aria-controls="menuUser"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <FontAwesomeIcon size="lg" icon={faEllipsisV} />
      </Button>
      <Menu
        id="menuUser"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {hasPermissionEdit && (
          <MenuItem onClick={handleClickEdit}>{t("edit")}</MenuItem>
        )}
        {hasPermissionDelete && (
          <MenuItem
            className={loadingDelete ? "loading" : ""}
            onClick={handleClickDelete}
          >
            {t("delete")}
          </MenuItem>
        )}
      </Menu>
      <Dialog open={openEdit}>
        <form onSubmit={(event) => handleSubmitEdit(event)}>
          <DialogTitle>{t("edit_user")}</DialogTitle>
          <DialogContentUser
            user={userSelected}
            setUser={setUserSelected}
            groups={groups}
            locations={locations}
          />
          <DialogActions>
            <ButtonBootstrap
              onClick={() => setOpenEdit(false)}
              variant="secondary"
            >
              {t("cancel")}
            </ButtonBootstrap>
            <ButtonBootstrap
              type="submit"
              variant="primary"
              disabled={!hasUserRequired(userSelected)}
            >
              {t("save")}
            </ButtonBootstrap>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(UserListMenu);
