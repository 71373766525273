import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable, {
  Action,
  Column,
  Components,
  Options,
} from "material-table";
import {
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  tableIcons,
} from "../../utils/TableProps";
import { useTranslation } from "react-i18next";
import { CatalogRowType, MachineType } from "../../entity/types";
import { createStyles, Theme } from "@material-ui/core";
import MachineInformationAnswered, {
  countMachineInformationAnswered,
  countMachineInformationTotal,
} from "./MachineInformationAnswered";
import { doesMatchAllTerms } from "../../utils/search";

interface Props extends WithStyles<typeof styles> {
  machines: MachineType[];
  catalogIdSelected: string;
  actions?: Action<any>[];
  options?: Options<any>;
  onSelectionChange?:
    | ((data: MachineType[], rowData?: MachineType) => void)
    | undefined;
  components?: Components;
  showExtendedList?: boolean;
  selectionEnabled?: boolean;
}

const MaterialTableMachine = ({
  classes,
  machines,
  catalogIdSelected,
  actions = [],
  options = {},
  onSelectionChange = undefined,
  components = {},
  showExtendedList = false,
  selectionEnabled = true,
}: Props) => {
  const { t } = useTranslation();

  const optionsDefault = {
    paging: true,
    pageSize: 50,
    pageSizeOptions: [25, 50, 100],
    selectionProps: (rowData: MachineType) => ({
      disabled: !selectionEnabled,
    }),
  };

  const data = machines.map((o) => ({ ...o }));

  const renderCatalogRows = (machine: MachineType) => {
    let rowNames: string[] = [];
    machine.catalogRows.forEach(function (catalogRow: CatalogRowType) {
      if (
        !catalogIdSelected ||
        catalogRow.catalogCategory.catalogCategoryUpper.catalog.id ===
          catalogIdSelected
      ) {
        rowNames.push(catalogRow.name);
      }
    });
    return rowNames.join(", ");
  };

  const getLocationText = (machine: MachineType) =>
    String(machine.bulkProduct ? t("bulk_product") : machine.location?.name);

  let columns: Column<any>[] = [
    {
      title: t("machine_make"),
      field: "machineModel.machineMake.title",
      customFilterAndSearch: (term: string, machine: MachineType) =>
        doesMatchAllTerms(term, machine.machineModel.machineMake.title),
    },
    {
      title: t("machine_model"),
      field: "machineModel.title",
      customFilterAndSearch: (term: string, machine: MachineType) =>
        doesMatchAllTerms(term, machine.machineModel.title),
    },
    { title: t("information"), field: "information" },
    { title: t("identifier"), field: "identifier" },
    { title: t("serial"), field: "serial" },
    {
      title: t("location"),
      field: "location.name",
      render: (machine: MachineType) => getLocationText(machine),
      customFilterAndSearch: (term: string, machine: MachineType) =>
        doesMatchAllTerms(term, getLocationText(machine)),
    },
  ];
  if (showExtendedList) {
    columns.push({
      title: t("catalog_rows"),
      render: (machine: MachineType) => {
        let rowNames = renderCatalogRows(machine);
        return rowNames;
      },
      customFilterAndSearch: (term: string, machine: MachineType) => {
        let rowNames = renderCatalogRows(machine);
        return doesMatchAllTerms(term, rowNames);
      },
      customSort: (machineA: MachineType, machineB: MachineType) => {
        let rowNamesA = renderCatalogRows(machineA);
        let rowNamesB = renderCatalogRows(machineB);
        return rowNamesA.localeCompare(rowNamesB);
      },
    });
    columns.push({
      title: t("machine_information"),
      render: (machine: MachineType) => {
        return <MachineInformationAnswered machine={machine} />;
      },
      customFilterAndSearch: (term: string, machine: MachineType) => {
        let total = countMachineInformationTotal(machine);
        let count = countMachineInformationAnswered(machine);

        const x_of_x = t("x_of_x_slim", {
          count: count,
          total: total,
        });
        return x_of_x.toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (machineA: MachineType, machineB: MachineType) => {
        const machineAUnanswered =
          countMachineInformationTotal(machineA) -
          countMachineInformationAnswered(machineA);
        const machineBUnanswered =
          countMachineInformationTotal(machineB) -
          countMachineInformationAnswered(machineB);

        const unansweredMachineInformationCountDifference =
          machineAUnanswered - machineBUnanswered;

        if (unansweredMachineInformationCountDifference !== 0) {
          return unansweredMachineInformationCountDifference;
        }

        return (
          countMachineInformationTotal(machineA) -
          countMachineInformationTotal(machineB)
        );
      },
    });
  }

  return (
    <MaterialTable
      style={getTableStyle()}
      title={""}
      icons={tableIcons}
      localization={GetTableLocalization(t)}
      columns={columns}
      data={data}
      options={getTableOptions({ ...optionsDefault, ...options })}
      actions={actions}
      onSelectionChange={onSelectionChange}
      components={components}
    />
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MaterialTableMachine);
