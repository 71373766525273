import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { createStyles, Theme } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import ProfileList from "../components/Profile/ProfileList";
import {
  GET_PROFILES_QUERY,
  QueryResultProfiles,
} from "../apollo/queries/profiles";
import CreateProfile from "../components/Profile/CreateProfile";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import { CONTAINER_MAIN_WIDTH_L } from "../utils/constants";
import HeaderPage from "../components/Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {}

function SettingsProfiles({ classes }: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<QueryResultProfiles>(
    GET_PROFILES_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("groups"),
    }
  );

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <Container fluid className={`containerMain ${classes.container}`}>
      <HeaderPage title={t("profiles")} />
      <div className="containerInner">
        <CreateProfile />
        <ProfileList
          groups={data.groups ? data.groups : []}
          permissions={data.permissions ? data.permissions : []}
        />
      </div>
    </Container>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: CONTAINER_MAIN_WIDTH_L,
    },
  });

export default withStyles(styles)(SettingsProfiles);
