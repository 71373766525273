import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import { CommissionTemplateType } from "../../entity/types";
import CommissionLevelsForRange from "./CommissionLevelsForRange";

interface Props extends WithStyles<typeof styles> {
  commissionTemplate: CommissionTemplateType;
}

function CommissionRangesForTemplate({ classes, commissionTemplate }: Props) {
  return (
    <div>
      {commissionTemplate.commissionrangeSet.map((commissionRange, index) => {
        const commissionRangePrev =
          index > 0 ? commissionTemplate.commissionrangeSet[index - 1] : null;

        const commissionLevels = commissionTemplate.commissionlevelSet.filter(
          (commissionLevel) =>
            commissionLevel.commissionRange.id === commissionRange.id
        );

        return (
          <div key={commissionRange.id} className="mb-5">
            <CommissionLevelsForRange
              commissionTemplateId={commissionTemplate.id}
              commissionRange={commissionRange}
              commissionRangePrev={commissionRangePrev}
              commissionLevels={commissionLevels}
              isLast={
                commissionTemplate.commissionrangeSet.length - 1 === index
              }
            />
          </div>
        );
      })}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    btn: {
      minWidth: "2rem",
    },
  });

export default withStyles(styles)(CommissionRangesForTemplate);
