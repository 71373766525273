import { gql } from "@apollo/client";
import { Mutation } from "../../entity/types";

export const CREATE_ORDER_MUTATION = gql`
  mutation createOrder(
    $customerId: ID!
    $referenceId: ID!
    $locationId: ID!
    $information: String!
    $informationInvoice: String!
    $confirmationType: String!
    $status: String!
    $advanceValidUntil: Date
    $isBusinessIfNoCustomer: Boolean!
    $hasInsurance: Boolean!
    $updatePricesInCatalogSwitch: Boolean!
  ) {
    createOrder(
      customerId: $customerId
      referenceId: $referenceId
      locationId: $locationId
      information: $information
      informationInvoice: $informationInvoice
      confirmationType: $confirmationType
      status: $status
      advanceValidUntil: $advanceValidUntil
      isBusinessIfNoCustomer: $isBusinessIfNoCustomer
      hasInsurance: $hasInsurance
      updatePricesInCatalogSwitch: $updatePricesInCatalogSwitch
    ) {
      order {
        id
      }
    }
  }
`;

export const UPDATE_ORDER_MUTATION = gql`
  mutation updateOrder(
    $orderId: ID!
    $customerId: ID!
    $referenceId: ID!
    $locationId: ID!
    $information: String!
    $informationInvoice: String!
    $confirmationType: String!
    $hasInsurance: Boolean!
    $updatePricesInCatalogSwitch: Boolean!
  ) {
    updateOrder(
      orderId: $orderId
      customerId: $customerId
      referenceId: $referenceId
      locationId: $locationId
      information: $information
      informationInvoice: $informationInvoice
      confirmationType: $confirmationType
      hasInsurance: $hasInsurance
      updatePricesInCatalogSwitch: $updatePricesInCatalogSwitch
    ) {
      order {
        id
        customer {
          id
        }
        reference {
          id
        }
        location {
          id
        }
        information
        informationInvoice
        confirmationType
        hasInsurance
        updatePricesInCatalogSwitch
        commissionSet {
          id
        }
      }
    }
  }
`;

export const UPDATE_ORDER_STATUS_MUTATION = gql`
  mutation updateOrderStatus(
    $orderId: ID!
    $status: String!
    $advanceValidUntil: Date
  ) {
    updateOrderStatus(
      orderId: $orderId
      status: $status
      advanceValidUntil: $advanceValidUntil
    ) {
      order {
        id
        status
        advanceValidUntil
        reservationSet {
          id
          machine {
            id
            location {
              id
            }
          }
        }
      }
    }
  }
`;

export const DELETE_ORDER_MUTATION = gql`
  mutation deleteOrder($orderId: ID!) {
    deleteOrder(orderId: $orderId) {
      orderId
    }
  }
`;

export const SPLIT_ORDER_MUTATION = gql`
  mutation splitOrder($orderId: ID!, $reservationIds: [ID]!, $dateEnd: Date!) {
    splitOrder(
      orderId: $orderId
      reservationIds: $reservationIds
      dateEnd: $dateEnd
    ) {
      order {
        id
      }
    }
  }
`;

export interface MutationResultContractPdf {
  getContractPdf: Mutation["getContractPdf"];
}
export const GET_CONTRACT_PDF_MUTATION = gql`
  mutation getContractPdf($orderId: ID!) {
    getContractPdf(orderId: $orderId) {
      url
    }
  }
`;

export interface MutationResultConfirmationPdf {
  getConfirmationPdf: Mutation["getConfirmationPdf"];
}
export const GET_CONFIRMATION_PDF_MUTATION = gql`
  mutation getConfirmationPdf($orderId: ID!) {
    getConfirmationPdf(orderId: $orderId) {
      url
    }
  }
`;

export interface MutationResultOrderOfferPdf {
  getOrderOfferPdf: Mutation["getOrderOfferPdf"];
}
export const GET_ORDER_OFFER_PDF_MUTATION = gql`
  mutation getOrderOfferPdf($orderId: ID!, $offerText: String!) {
    getOrderOfferPdf(orderId: $orderId, offerText: $offerText) {
      url
    }
  }
`;
