import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  createStyles,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Theme,
} from "@material-ui/core";
import {
  InspectionReturnCategoryType,
  InspectionReturnTagType,
  MachineType,
  Mutation,
  MutationAddInspectionReturnTagToMachineArgs,
  MutationCopyInspectionReturnTagsArgs,
  MutationCreateInspectionReturnToMachineArgs,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { GET_MACHINE_WITH_INSPECTION_RETURN_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { findFromSetById } from "../../utils/collections";
import {
  InspectionReturnQuestionEmpty,
  InspectionReturnTagEmpty,
} from "../../entity/empties";
import {
  ADD_INSPECTION_RETURN_TAG_TO_MACHINE_MUTATION,
  COPY_INSPECTION_RETURN_TAGS_MUTATION,
  CREATE_INSPECTION_RETURN_TO_MACHINE_MUTATION,
} from "../../apollo/mutations/inspections";
import { handleError } from "../../entity/ErrorHandler";
import { ID_EMPTY } from "../../utils/constants";
import { getMachineName } from "../../utils/machines/machine";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
  machines: MachineType[];
  inspectionReturnTags: InspectionReturnTagType[];
  inspectionReturnCategories: InspectionReturnCategoryType[];
}

function DialogContentInspectionReturnActions({
  classes,
  machine,
  machines,
  inspectionReturnTags,
  inspectionReturnCategories,
}: Props) {
  const { t } = useTranslation();

  const [inspectionReturnTagIdsToAdd, setInspectionReturnTagIdsToAdd] =
    useState<string[]>([]);
  const [
    inspectionReturnQuestionIdsToAdd,
    setInspectionReturnQuestionIdsToAdd,
  ] = useState<string[]>([]);

  const [addInspectionReturnTagToMachine, resultAdd] = useMutation<
    Mutation,
    MutationAddInspectionReturnTagToMachineArgs
  >(ADD_INSPECTION_RETURN_TAG_TO_MACHINE_MUTATION, {
    refetchQueries: [
      {
        query: GET_MACHINE_WITH_INSPECTION_RETURN_CATEGORIES_QUERY,
        variables: {
          machineId: machine.id,
        },
      },
    ],
    onCompleted: () => {
      setInspectionReturnTagIdsToAdd([]);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [createInspectionReturnToMachine] = useMutation<
    Mutation,
    MutationCreateInspectionReturnToMachineArgs
  >(CREATE_INSPECTION_RETURN_TO_MACHINE_MUTATION, {
    refetchQueries: [
      {
        query: GET_MACHINE_WITH_INSPECTION_RETURN_CATEGORIES_QUERY,
        variables: {
          machineId: machine.id,
        },
      },
    ],
    onCompleted: () => {
      setInspectionReturnQuestionIdsToAdd([]);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [copyInspectionReturnTags, resultCopy] = useMutation<
    Mutation,
    MutationCopyInspectionReturnTagsArgs
  >(COPY_INSPECTION_RETURN_TAGS_MUTATION, {
    refetchQueries: [
      {
        query: GET_MACHINE_WITH_INSPECTION_RETURN_CATEGORIES_QUERY,
        variables: {
          machineId: machine.id,
        },
      },
    ],
    onError: (error) => {
      handleError(error);
    },
  });

  const handleAddTagsToMachine = () => {
    if (inspectionReturnTagIdsToAdd.length > 0) {
      addInspectionReturnTagToMachine({
        variables: {
          machineId: machine.id,
          inspectionReturnTagIds: inspectionReturnTagIdsToAdd,
        },
      });
    }
  };

  const handleAddQuestionToMachine = () => {
    if (inspectionReturnQuestionIdsToAdd.length > 0) {
      createInspectionReturnToMachine({
        variables: {
          machineId: machine.id,
          inspectionReturnQuestionIds: inspectionReturnQuestionIdsToAdd,
        },
      });
    }
  };

  const onChangeCopyInspectionReturn = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    copyInspectionReturnTags({
      variables: {
        machineIdFrom: String(event.target.value),
        machineIdTo: machine.id,
      },
    });
  };

  const renderSelectGroup = (
    inspectionReturnCategories: InspectionReturnCategoryType[]
  ) => {
    return inspectionReturnCategories.map((inspectionReturnCategory) => {
      const itemsInner =
        inspectionReturnCategory.inspectionreturnquestionSet.map(
          (inspectionReturnQuestion) => {
            return (
              <MenuItem
                key={inspectionReturnQuestion.id}
                value={inspectionReturnQuestion.id}
              >
                <Checkbox
                  checked={
                    inspectionReturnQuestionIdsToAdd.indexOf(
                      inspectionReturnQuestion.id
                    ) > -1
                  }
                />
                {inspectionReturnQuestion.question}
              </MenuItem>
            );
          }
        );

      return [
        <ListSubheader>{inspectionReturnCategory.name}</ListSubheader>,
        itemsInner,
      ];
    });
  };

  return (
    <Paper
      className={`${classes.paper} ${
        resultAdd.loading || resultCopy.loading ? "loading" : ""
      } mb-5`}
    >
      <FormControl fullWidth>
        <InputLabel id="lblDialogInspectionReturnTags">
          {t("inspections_from_tag")}
        </InputLabel>
        <Select
          value={inspectionReturnTagIdsToAdd}
          labelId="lblDialogInspectionReturnTags"
          multiple={true}
          renderValue={(value) => {
            let names: string[] = [];
            (value as string[]).forEach((id) => {
              const inspectionReturnTag = findFromSetById(
                id,
                inspectionReturnTags,
                InspectionReturnTagEmpty
              );
              if (inspectionReturnTag.id !== ID_EMPTY) {
                names.push(inspectionReturnTag.name);
              }
            });
            return names.join(", ");
          }}
          onChange={(event) => {
            setInspectionReturnTagIdsToAdd(event.target.value as string[]);
          }}
          onClose={() => {
            handleAddTagsToMachine();
          }}
        >
          {inspectionReturnTags.map((inspectionReturnTag) => (
            <MenuItem
              key={inspectionReturnTag.id}
              value={inspectionReturnTag.id}
            >
              <Checkbox
                checked={
                  inspectionReturnTagIdsToAdd.indexOf(inspectionReturnTag.id) >
                  -1
                }
              />
              {inspectionReturnTag.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblDialogInspectionReturnAdd">
          {t("add_inspection_questions")}
        </InputLabel>
        <Select
          labelId="lblDialogInspectionReturnAdd"
          value={inspectionReturnQuestionIdsToAdd}
          multiple={true}
          renderValue={(value) => {
            let names: string[] = [];
            (value as string[]).forEach((id) => {
              let inspectionReturnQuestion = InspectionReturnQuestionEmpty;
              inspectionReturnCategories.forEach((inspectionReturnCategory) => {
                const inspectionReturnQuestionFound = findFromSetById(
                  id,
                  inspectionReturnCategory.inspectionreturnquestionSet,
                  InspectionReturnTagEmpty
                );
                if (inspectionReturnQuestionFound.id !== ID_EMPTY) {
                  inspectionReturnQuestion = inspectionReturnQuestionFound;
                  return;
                }
              });
              if (inspectionReturnQuestion.id !== ID_EMPTY) {
                names.push(inspectionReturnQuestion.question);
              }
            });
            return names.join(", ");
          }}
          onChange={(event) => {
            setInspectionReturnQuestionIdsToAdd(event.target.value as string[]);
          }}
          onClose={() => {
            handleAddQuestionToMachine();
          }}
        >
          {renderSelectGroup(inspectionReturnCategories)}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblDialogInspectionReturnCopy">
          {t("add_inspection_copy")}
        </InputLabel>
        <Select
          value=""
          labelId="lblDialogInspectionReturnCopy"
          onChange={onChangeCopyInspectionReturn}
        >
          {machines.map((machineLooped) => {
            if (machineLooped.id === machine.id) {
              return undefined;
            }
            return (
              <MenuItem key={machineLooped.id} value={machineLooped.id}>
                {getMachineName(machineLooped, true)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Paper>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      padding: spacing(2),
    },
  });

export default withStyles(styles)(DialogContentInspectionReturnActions);
