import React, { useContext } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import {
  InspectionGiveQuestionType,
  InspectionGiveToMachineType,
  Mutation,
  MutationDeleteInspectionGiveToMachineArgs,
} from "../../entity/types";
import ButtonLoad from "../Shared/ButtonLoad";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { dialogConfirm } from "../../utils/dialogs";
import { DELETE_INSPECTION_GIVE_TO_MACHINE_MUTATION } from "../../apollo/mutations/inspections";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionGiveToMachine: InspectionGiveToMachineType;
  inspectionGiveQuestion: InspectionGiveQuestionType;
}

function InspectionGiveToMachineQuestionRow({
  classes,
  inspectionGiveToMachine,
  inspectionGiveQuestion,
}: Props) {
  const { t } = useTranslation();

  const [deleteInspectionGiveToMachine, { loading: loadingDelete }] =
    useMutation<Mutation, MutationDeleteInspectionGiveToMachineArgs>(
      DELETE_INSPECTION_GIVE_TO_MACHINE_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "inspectionGiveCategories",
          });
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "inspections.delete_inspectiongivetomachine",
  ]);

  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteInspectionGiveToMachine({
        variables: {
          inspectionGiveToMachineId: inspectionGiveToMachine.id,
        },
      });
    });
  };

  return (
    <tr>
      <td>{inspectionGiveQuestion.question}</td>
      <td>
        {hasPermissionDelete && (
          <ButtonLoad
            loading={loadingDelete}
            size="sm"
            variant="light"
            onClick={onClickDelete}
          >
            {<FontAwesomeIcon icon={faTrash} />}
          </ButtonLoad>
        )}
      </td>
    </tr>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InspectionGiveToMachineQuestionRow);
