import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { useTranslation } from "react-i18next";
import {
  GET_LOCATIONS_QUERY,
  QueryResultLocations,
} from "../apollo/queries/locations";
import Calendar from "../components/Calendar/Calendar";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {}

function CalendarShow({ classes }: Props) {
  const { t } = useTranslation();

  let catalog_id: string,
    catalog_category_upper_id: string,
    catalog_category_id: string,
    catalog_row_id: string;
  ({
    catalog_id,
    catalog_category_upper_id,
    catalog_category_id,
    catalog_row_id,
  } = useParams());

  const { loading, error, data } = useQuery<QueryResultLocations>(
    GET_LOCATIONS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("locations"),
    }
  );

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <Container fluid className="containerMain">
      <div className="containerInner">
        <Calendar
          catalogId={catalog_id}
          catalogCategoryUpperId={catalog_category_upper_id}
          catalogCategoryId={catalog_category_id}
          catalogRowId={catalog_row_id}
          locations={data.locations ? data.locations : []}
        />
      </div>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CalendarShow);
