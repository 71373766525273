import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { ButtonProps } from "react-bootstrap/Button";
import { Button } from "react-bootstrap";

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode;
  loading: boolean;
}

const ButtonLoad = ({
  children,
  classes,
  loading,
  ...other
}: Props & ButtonProps) => {
  const className = other.className;
  const disabled = other.disabled || loading;
  delete other.className;
  delete other.disabled;

  return (
    <Button
      className={`${className} ${loading ? "loading" : ""}`}
      disabled={disabled}
      {...other}
    >
      {children}
    </Button>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ButtonLoad);
