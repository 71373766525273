import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Checkbox, createStyles, Theme } from "@material-ui/core";
import {
  CatalogCategoryType,
  DiscountListType,
  DiscountRowType,
} from "../../../entity/types";
import {
  addStringToArr,
  removeStringFromArr,
} from "../../../utils/collections";
import { PermissionsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";

interface Props extends WithStyles<typeof styles> {
  discountList: DiscountListType;
  catalogCategory: CatalogCategoryType;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  catalogRowsVisible: { [key: string]: boolean };
}

const DiscountTheadCategory = ({
  classes,
  discountList,
  catalogCategory,
  selectedRows,
  setSelectedRows,
  catalogRowsVisible,
}: Props) => {
  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "discounts.change_discountrow",
  ]);

  const onChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    let rowIds: string[] = [];
    discountList.discountrowSet.forEach(function (
      discountRowLooped: DiscountRowType
    ) {
      if (
        discountRowLooped.catalogRow.catalogCategory.id ===
          catalogCategory.id &&
        catalogRowsVisible[discountRowLooped.catalogRow.id]
      ) {
        rowIds.push(discountRowLooped.id);
      }
    });

    if (event.target.checked) {
      setSelectedRows(addStringToArr(selectedRows, rowIds));
    } else {
      setSelectedRows(removeStringFromArr(selectedRows, rowIds));
    }
  };

  const checkboxShow = catalogCategory.catalogrowSet.length > 1;
  let checkboxSelected = false;
  if (checkboxShow) {
    checkboxSelected = true;
    discountList.discountrowSet.forEach(function (discountRowLooped) {
      if (
        discountRowLooped.catalogRow.catalogCategory.id ===
          catalogCategory.id &&
        selectedRows.indexOf(discountRowLooped.id) < 0
      ) {
        checkboxSelected = false;
        return;
      }
    });
  }

  return (
    <thead>
      <tr className="noBorder">
        {hasPermissionEdit && !discountList.locked && (
          <th>
            {checkboxShow && (
              <Checkbox
                checked={checkboxSelected}
                onChange={(event) => {
                  onChangeSelect(event);
                }}
              />
            )}
          </th>
        )}
        <th colSpan={5} className={classes.thCategory}>
          {catalogCategory.name}
        </th>
        <th className="cellPriceChange"></th>
        <th colSpan={4}></th>
        <th className="cellPriceChange"></th>
      </tr>
    </thead>
  );
};

const styles = ({ palette }: Theme) =>
  createStyles({
    thCategory: {
      color: palette.primary.main,
      verticalAlign: "middle !important",
    },
  });

export default withStyles(styles)(DiscountTheadCategory);
