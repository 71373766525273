import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Catalog from "../components/Catalog/table/Catalog";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QueryCatalogArgs } from "../entity/types";
import {
  GET_CATALOG_WITH_SOURCE_QUERY,
  QueryResultCatalogWithSource,
} from "../apollo/queries/catalogs";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import CreateCatalogCategoriesMenu from "../components/Catalog/CreateCatalogCategoriesMenu";
import CatalogSetActive from "../components/Catalog/CatalogSetActive";
import { CONTAINER_MAIN_WIDTH_XL } from "../utils/constants";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import HeaderPage from "../components/Shared/HeaderPage";
import MachinesWithoutCatalogRow from "../components/Machine/MachinesWithoutCatalogRow";

interface Props extends WithStyles<typeof styles> {}

function SettingsCatalogEdit({ classes }: Props) {
  const { t } = useTranslation();

  let catalog_id: string;
  ({ catalog_id } = useParams());

  const { loading, error, data } = useQuery<
    QueryResultCatalogWithSource,
    QueryCatalogArgs
  >(GET_CATALOG_WITH_SOURCE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalog"),
    variables: {
      catalogId: catalog_id,
    },
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data || !data.catalog) return <Error error={t("error_query_failed")} />;

  return (
    <Container fluid className={`containerMain ${classes.container}`}>
      <HeaderPage title={data.catalog.name} />
      <div className="containerInner">
        <CreateCatalogCategoriesMenu catalog={data.catalog} />
        <CatalogSetActive catalog={data.catalog} />
        <Catalog catalog={data.catalog} />
        <MachinesWithoutCatalogRow catalogId={data.catalog.id} />
      </div>
    </Container>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: CONTAINER_MAIN_WIDTH_XL,
    },
  });

export default withStyles(styles)(SettingsCatalogEdit);
