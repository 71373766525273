import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { WithStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import {
  GET_COMMISSION_TEMPLATES_WITH_LEVELS_QUERY,
  QueryResultCommissionTemplatesWithLevels,
} from "../../apollo/queries/commissions";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { CommissionTemplateEmpty } from "../../entity/empties";
import { ID_EMPTY } from "../../utils/constants";
import { findFromSetById } from "../../utils/collections";
import CommissionRangesForTemplate from "./CommissionRangesForTemplate";

interface Props extends WithStyles<typeof styles> {}

function CommissionLevels({ classes }: Props) {
  const { t } = useTranslation();

  const [commissionTemplateSelected, setCommissionTemplateSelected] = useState(
    CommissionTemplateEmpty
  );

  const {
    loading: loadingTemplates,
    error: errorTemplates,
    data: dataTemplates,
  } = useQuery<QueryResultCommissionTemplatesWithLevels>(
    GET_COMMISSION_TEMPLATES_WITH_LEVELS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("commissionTemplates"),
    }
  );

  useEffect(() => {
    if (commissionTemplateSelected.id !== ID_EMPTY) {
      setCommissionTemplateSelected(
        findFromSetById(
          commissionTemplateSelected.id,
          dataTemplates?.commissionTemplates
            ? dataTemplates.commissionTemplates
            : [],
          CommissionTemplateEmpty
        )
      );
    }
    // eslint-disable-next-line
  }, [dataTemplates]);

  if (loadingTemplates) return <LoadingSimple />;
  if (errorTemplates) return <Error error={errorTemplates} />;
  if (!dataTemplates) return <Error error={t("error_query_failed")} />;

  return (
    <div>
      <FormControl>
        <InputLabel id="lblCommissionTemplateSel">
          {t("commission_template")}
        </InputLabel>
        <Select
          autoWidth
          labelId="lblCommissionTemplateSel"
          value={commissionTemplateSelected.id}
          onChange={(event) => {
            setCommissionTemplateSelected(
              findFromSetById(
                event.target.value as string,
                dataTemplates?.commissionTemplates
                  ? dataTemplates.commissionTemplates
                  : [],
                CommissionTemplateEmpty
              )
            );
          }}
        >
          <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
          {dataTemplates.commissionTemplates?.map((commissionTemplate) => (
            <MenuItem key={commissionTemplate.id} value={commissionTemplate.id}>
              {commissionTemplate.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="mt-4">
        {commissionTemplateSelected.id !== ID_EMPTY && (
          <CommissionRangesForTemplate
            commissionTemplate={commissionTemplateSelected}
          />
        )}
      </div>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CommissionLevels);
