import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultDiscountLists {
  discountLists: Query["discountLists"];
}
export const GET_DISCOUNT_LISTS_QUERY = gql`
  query getDiscountListsQuery($catalogId: ID!) {
    discountLists(catalogId: $catalogId) {
      id
      name
      isBusiness
      locked
      isOffer
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export interface QueryResultDiscountList {
  discountList: Query["discountList"];
}
export const GET_DISCOUNT_LIST_QUERY = gql`
  query getDiscountListQuery($discountListId: ID!) {
    discountList(discountListId: $discountListId) {
      id
      name
      isBusiness
      locked
      discountrowSet {
        id
        percent
        showPrint
        catalogRow {
          id
          catalogCategory {
            id
            catalogCategoryUpper {
              id
              catalog {
                id
              }
            }
          }
        }
      }
      catalog {
        id
        catalogcategoryupperSet {
          id
          name
          catalogcategorySet {
            id
            name
            catalogrowSet {
              id
              name
              priceDayCompany
              priceMonthCompany
              priceDayPrivate
              priceMonthPrivate
            }
          }
        }
      }
      customer {
        id
        name
      }
    }
  }
`;
