import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultLocations {
  locations: Query["locations"];
}
export const GET_LOCATIONS_QUERY = gql`
  query getLocationsQuery {
    locations {
      id
      name
      transferDistanceIncluded
      transferDistanceLimit
      dimIdentifier
      createdAt
    }
  }
`;
