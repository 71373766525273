import { gql } from "@apollo/client";

export const CREATE_HOLIDAY_MUTATION = gql`
  mutation createHoliday($holidayType: String!, $date: Date!, $name: String!) {
    createHoliday(holidayType: $holidayType, date: $date, name: $name) {
      holiday {
        id
        holidayType
        date
        name
        createdAt
      }
    }
  }
`;

export const UPDATE_HOLIDAY_MUTATION = gql`
  mutation updateHoliday(
    $holidayId: ID!
    $holidayType: String!
    $date: Date!
    $name: String!
  ) {
    updateHoliday(
      holidayId: $holidayId
      holidayType: $holidayType
      date: $date
      name: $name
    ) {
      holiday {
        id
        holidayType
        date
        name
      }
    }
  }
`;

export const DELETE_HOLIDAY_MUTATION = gql`
  mutation deleteHoliday($holidayId: ID!) {
    deleteHoliday(holidayId: $holidayId) {
      holidayId
    }
  }
`;
