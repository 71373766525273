import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Dialog, DialogContent, Theme } from "@material-ui/core";
import LoadingSimple from "./LoadingSimple";

interface Props extends WithStyles<typeof styles> {}

const LoadingDialog = ({ classes }: Props) => {
  return (
    <Dialog open={true}>
      <DialogContent>
        <LoadingSimple />
      </DialogContent>
    </Dialog>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(LoadingDialog);
