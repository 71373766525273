import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  createStyles,
  DialogContent,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CustomerCreditRiskClass,
  CustomerType,
  Mutation,
  MutationUpdateCustomerArgs,
  UserType,
} from "../../entity/types";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { Col, Row } from "react-bootstrap";
import { PermissionsContext, UserContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { useMutation, useQuery } from "@apollo/client";
import Error from "../Shared/Error";
import { GET_USERS_QUERY, QueryResultUsers } from "../../apollo/queries/users";
import LoadingSimple from "../Shared/LoadingSimple";
import { UPDATE_CUSTOMER_MUTATION } from "../../apollo/mutations/customers";
import { handleError } from "../../entity/ErrorHandler";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { getQueryKey } from "../../utils/cache";
import SelectRequired from "../Shared/SelectRequired";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerType>>;
}

function DialogContentCustomer({ classes, customer, setCustomer }: Props) {
  const { t } = useTranslation();

  const user = useContext(UserContext);

  const { loading, error, data } = useQuery<QueryResultUsers>(GET_USERS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("users"),
  });

  const [updateCustomer, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCustomerArgs
  >(UPDATE_CUSTOMER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customers"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "customers.add_customer",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "customers.change_customer",
  ]);
  const hasPermissionChangeUserOwner = checkPermission(myPermissions, [
    "customers.change_user_owner",
  ]);
  const disabled = customer.id ? !hasPermissionEdit : !hasPermissionAdd;

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const handleUpdate = (values: {
    [key: string]: string | null | boolean | Date;
  }) => {
    if (customer.id !== ID_EMPTY) {
      const base: MutationUpdateCustomerArgs = {
        customerId: customer.id,
        businessId: customer.businessId,
        name: customer.name,
        contactPersonFirstname: customer.contactPersonFirstname,
        contactPersonLastname: customer.contactPersonLastname,
        email: customer.email,
        phone: customer.phone,
        visitAddress: customer.visitAddress,
        visitPostcode: customer.visitPostcode,
        visitDistrict: customer.visitDistrict,
        invoicingAddress: customer.invoicingAddress,
        invoicingDistrict: customer.invoicingDistrict,
        invoicingEmail: customer.invoicingEmail,
        invoicingPostcode: customer.invoicingPostcode,
        invoicingEAddress: customer.invoicingEAddress,
        invoicingEOperator: customer.invoicingEOperator,
        industry: customer.industry,
        contactNext: customer.contactNext,
        isBusiness: customer.isBusiness,
        creditInformationOk: customer.creditInformationOk,
        hasInsurance: customer.hasInsurance,
        creditInformationCheckedAt: customer.creditInformationCheckedAt,
        creditRiskClass: customer.creditRiskClass,
        preInvoicingAt: customer.preInvoicingAt,
        preInvoicingReason: customer.preInvoicingReason,
        blockedAt: customer.blockedAt,
        blockedReason: customer.blockedReason,
        userOwnerId: customer.userOwner ? customer.userOwner.id : user.id,
        customerIdParent: customer.customerParent
          ? customer.customerParent.id
          : ID_EMPTY,
        useParentInvoicing: customer.useParentInvoicing,
        useParentPricing: customer.useParentPricing,
      };

      updateCustomer({
        variables: { ...base, ...values },
      });
    }
  };

  let showUsingParentInvoicing =
    customer.customerParent && customer.customerParent.useParentInvoicing;

  const invoicingAdded =
    customer.invoicingAddress ||
    customer.invoicingEAddress ||
    customer.invoicingEmail;

  return (
    <DialogContent className={loadingUpdate ? "loading" : ""}>
      <div>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue="top"
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={t("company")}
              checked={Boolean(customer.isBusiness) === true}
              onChange={() => {
                const customerNew = { ...customer, isBusiness: true };
                setCustomer(customerNew);
                handleUpdate({ isBusiness: true });
              }}
              disabled={disabled}
            />
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label={t("private")}
              checked={Boolean(customer.isBusiness) === false}
              onChange={() => {
                const customerNew = { ...customer, isBusiness: false };
                setCustomer(customerNew);
                handleUpdate({ isBusiness: false });
              }}
              disabled={disabled}
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        {customer.id !== ID_EMPTY && (
          <FormControl fullWidth>
            <TextField
              label={t("customer_number")}
              inputProps={{ maxLength: 20 }}
              value={customer.customerNumber}
              disabled={true}
            />
          </FormControl>
        )}
        <FormControl fullWidth>
          <TextField
            label={t("business_id")}
            onChange={(event) => {
              setCustomer({ ...customer, businessId: event.target.value });
            }}
            inputProps={{ maxLength: 9 }}
            value={customer.businessId}
            disabled={disabled}
            required={customer.isBusiness}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label={t("name")}
            onChange={(event) => {
              setCustomer({ ...customer, name: event.target.value });
            }}
            onBlur={() => handleUpdate({ name: customer.name })}
            value={customer.name}
            disabled={disabled}
            required={true}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label={t("industry")}
            onChange={(event) => {
              setCustomer({ ...customer, industry: event.target.value });
            }}
            onBlur={() => handleUpdate({ industry: customer.industry })}
            value={customer.industry}
            disabled={disabled}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            type="date"
            label={t("contact_next")}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setCustomer({ ...customer, contactNext: event.target.value });
            }}
            onBlur={() => handleUpdate({ contactNext: customer.contactNext })}
            value={customer.contactNext ? customer.contactNext : ""}
            disabled={disabled}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="lblCustomerOwner">{t("customer_owner")}</InputLabel>
          <SelectRequired
            autoWidth
            labelId="lblCustomerOwner"
            value={customer.userOwner ? customer.userOwner.id : ID_EMPTY}
            onChange={(event) => {
              const userOwnerId = String(event.target.value);
              let customerNew = {
                ...customer,
                userOwner: customer.userOwner
                  ? {
                      ...customer.userOwner,
                      id: userOwnerId,
                    }
                  : undefined,
              };
              setCustomer(customerNew);
              handleUpdate({ userOwnerId: userOwnerId });
            }}
            disabled={disabled || !hasPermissionChangeUserOwner}
          >
            {data.users &&
              data.users.map((userLooped: UserType) => (
                <MenuItem
                  key={userLooped.id}
                  value={userLooped.id}
                >{`${userLooped.lastName} ${userLooped.firstName}`}</MenuItem>
              ))}
          </SelectRequired>
        </FormControl>
      </div>
      <br />
      <br />
      <b>{t("customer_contact")}</b>
      <Row>
        <Col>
          <FormControl fullWidth>
            <TextField
              label={t("firstname")}
              onChange={(event) => {
                setCustomer({
                  ...customer,
                  contactPersonFirstname: event.target.value,
                });
              }}
              onBlur={() =>
                handleUpdate({
                  contactPersonFirstname: customer.contactPersonFirstname,
                })
              }
              value={customer.contactPersonFirstname}
              required={true}
              disabled={disabled}
            />
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth>
            <TextField
              label={t("lastname")}
              onChange={(event) => {
                setCustomer({
                  ...customer,
                  contactPersonLastname: event.target.value,
                });
              }}
              onBlur={() =>
                handleUpdate({
                  contactPersonLastname: customer.contactPersonLastname,
                })
              }
              value={customer.contactPersonLastname}
              required={true}
              disabled={disabled}
            />
          </FormControl>
        </Col>
      </Row>
      <FormControl fullWidth>
        <TextField
          type="email"
          label={t("email")}
          onChange={(event) => {
            setCustomer({
              ...customer,
              email: event.target.value,
            });
          }}
          onBlur={() => handleUpdate({ email: customer.email })}
          value={customer.email}
          required={true}
          disabled={disabled}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("phone")}
          onChange={(event) => {
            setCustomer({
              ...customer,
              phone: event.target.value,
            });
          }}
          onBlur={() => handleUpdate({ phone: customer.phone })}
          value={customer.phone}
          required={true}
          disabled={disabled}
        />
      </FormControl>
      <br />
      <br />
      <b>{t("address_visit")}</b>
      <FormControl fullWidth>
        <TextField
          label={t("address")}
          onChange={(event) => {
            setCustomer({ ...customer, visitAddress: event.target.value });
          }}
          onBlur={() => handleUpdate({ visitAddress: customer.visitAddress })}
          value={customer.visitAddress}
          disabled={disabled}
        />
      </FormControl>
      <Row>
        <Col>
          <FormControl fullWidth>
            <TextField
              label={t("postcode")}
              onChange={(event) => {
                setCustomer({ ...customer, visitPostcode: event.target.value });
              }}
              onBlur={() =>
                handleUpdate({ visitPostcode: customer.visitPostcode })
              }
              value={customer.visitPostcode}
              disabled={disabled}
            />
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth>
            <TextField
              label={t("district")}
              onChange={(event) => {
                setCustomer({ ...customer, visitDistrict: event.target.value });
              }}
              onBlur={() =>
                handleUpdate({ visitDistrict: customer.visitDistrict })
              }
              value={customer.visitDistrict}
              disabled={disabled}
            />
          </FormControl>
        </Col>
      </Row>
      <br />
      <br />
      <b>{t("address_invoicing")}</b>
      {customer.customersChildren.length ? (
        <FormControl fullWidth>
          <FormControlLabel
            className="pt-3 pb-1"
            label={t("use_parent_invoicing_data")}
            control={
              <Checkbox
                checked={Boolean(customer.useParentInvoicing)}
                onChange={(event) => {
                  let customerNew = {
                    ...customer,
                    useParentInvoicing: event.target.checked,
                  };
                  setCustomer(customerNew);
                  handleUpdate({ useParentInvoicing: event.target.checked });
                }}
              />
            }
          />
        </FormControl>
      ) : showUsingParentInvoicing ? (
        <FormControl fullWidth>
          <i>{t("using_parent_invoicing_data")}</i>
        </FormControl>
      ) : (
        ""
      )}
      <FormControl fullWidth>
        <TextField
          label={t("address")}
          onChange={(event) => {
            setCustomer({ ...customer, invoicingAddress: event.target.value });
          }}
          onBlur={() =>
            handleUpdate({ invoicingAddress: customer.invoicingAddress })
          }
          value={customer.invoicingAddress}
          required={true}
          disabled={disabled || showUsingParentInvoicing}
        />
      </FormControl>
      <Row>
        <Col>
          <FormControl fullWidth>
            <TextField
              label={t("postcode")}
              onChange={(event) => {
                setCustomer({
                  ...customer,
                  invoicingPostcode: event.target.value,
                });
              }}
              onBlur={() =>
                handleUpdate({ invoicingPostcode: customer.invoicingPostcode })
              }
              value={customer.invoicingPostcode}
              required={true}
              disabled={disabled || showUsingParentInvoicing}
            />
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth>
            <TextField
              label={t("district")}
              onChange={(event) => {
                setCustomer({
                  ...customer,
                  invoicingDistrict: event.target.value,
                });
              }}
              onBlur={() =>
                handleUpdate({ invoicingDistrict: customer.invoicingDistrict })
              }
              value={customer.invoicingDistrict}
              required={true}
              disabled={disabled || showUsingParentInvoicing}
            />
          </FormControl>
        </Col>
      </Row>
      <FormControl fullWidth>
        <TextField
          type="email"
          label={t("invoicing_email")}
          onChange={(event) => {
            setCustomer({
              ...customer,
              invoicingEmail: event.target.value,
            });
          }}
          onBlur={() =>
            handleUpdate({ invoicingDistrict: customer.invoicingEmail })
          }
          value={customer.invoicingEmail}
          required={!invoicingAdded}
          disabled={disabled || showUsingParentInvoicing}
        />
      </FormControl>
      <Row>
        <Col>
          <FormControl fullWidth>
            <TextField
              label={t("invoicing_e_address")}
              onChange={(event) => {
                setCustomer({
                  ...customer,
                  invoicingEAddress: event.target.value,
                });
              }}
              onBlur={() =>
                handleUpdate({ invoicingEAddress: customer.invoicingEAddress })
              }
              value={customer.invoicingEAddress}
              required={!invoicingAdded}
              disabled={disabled || showUsingParentInvoicing}
            />
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth>
            <TextField
              label={t("invoicing_e_operator")}
              onChange={(event) => {
                setCustomer({
                  ...customer,
                  invoicingEOperator: event.target.value,
                });
              }}
              onBlur={() =>
                handleUpdate({
                  invoicingEOperator: customer.invoicingEOperator,
                })
              }
              value={customer.invoicingEOperator}
              required={!invoicingAdded}
              disabled={disabled || showUsingParentInvoicing}
            />
          </FormControl>
        </Col>
      </Row>
      <br />
      <br />
      <b>{t("credit_information")}</b>
      <Row>
        <Col>
          <FormControl fullWidth>
            <InputLabel id="lblCustomerCreditInformationOk">
              {t("credit_information_ok")}
            </InputLabel>
            <Select
              autoWidth
              labelId="lblCustomerCreditInformationOk"
              value={customer.creditInformationOk ? 1 : 0}
              onChange={(event) => {
                const creditInformationOk = Boolean(event.target.value);
                let customerNew = {
                  ...customer,
                  creditInformationOk: creditInformationOk,
                };
                setCustomer(customerNew);
                handleUpdate({ creditInformationOk: creditInformationOk });
              }}
              disabled={disabled}
            >
              <MenuItem value={0}>{t("no")}</MenuItem>
              <MenuItem value={1}>{t("yes")}</MenuItem>
            </Select>
          </FormControl>
        </Col>
        <Col>
          {customer.creditInformationOk && (
            <FormControl fullWidth>
              <TextField
                type="date"
                label={t("credit_information_checked_at")}
                onChange={(event) => {
                  setCustomer({
                    ...customer,
                    creditInformationCheckedAt: event.target.value,
                  });
                }}
                value={
                  customer.creditInformationCheckedAt
                    ? customer.creditInformationCheckedAt
                    : ""
                }
                onBlur={() =>
                  handleUpdate({
                    creditInformationCheckedAt:
                      customer.creditInformationCheckedAt,
                  })
                }
                disabled={disabled}
                InputLabelProps={{
                  shrink: true,
                }}
                required={true}
              />
            </FormControl>
          )}
        </Col>
      </Row>
      {customer.creditInformationOk && (
        <Row>
          <Col>
            <FormControl fullWidth>
              <InputLabel id="lblCustomerCreditRiskClass">
                {t("credit_risk_class")}
              </InputLabel>
              <Select
                autoWidth
                labelId="lblCustomerCreditRiskClass"
                value={customer.creditRiskClass}
                onChange={(event) => {
                  const creditRiskClass = event.target
                    .value as CustomerCreditRiskClass;
                  const preInvoicingAt =
                    [
                      CustomerCreditRiskClass.Class_4,
                      CustomerCreditRiskClass.Class_5,
                    ].includes(creditRiskClass) && !customer.preInvoicingAt
                      ? new Date()
                      : customer.preInvoicingAt;

                  let customerNew = {
                    ...customer,
                    creditRiskClass: creditRiskClass,
                    preInvoicingAt: preInvoicingAt,
                  };
                  setCustomer(customerNew);
                  handleUpdate({ creditRiskClass: creditRiskClass });
                }}
                disabled={disabled}
              >
                <MenuItem value={CustomerCreditRiskClass.ClassNone}>
                  {t("credit_risk_class_" + CustomerCreditRiskClass.ClassNone)}
                </MenuItem>
                <MenuItem value={CustomerCreditRiskClass.Class_1}>
                  {t("credit_risk_class_" + CustomerCreditRiskClass.Class_1)}
                </MenuItem>
                <MenuItem value={CustomerCreditRiskClass.Class_2}>
                  {t("credit_risk_class_" + CustomerCreditRiskClass.Class_2)}
                </MenuItem>
                <MenuItem value={CustomerCreditRiskClass.Class_3}>
                  {t("credit_risk_class_" + CustomerCreditRiskClass.Class_3)}
                </MenuItem>
                <MenuItem value={CustomerCreditRiskClass.Class_4}>
                  {t("credit_risk_class_" + CustomerCreditRiskClass.Class_4)}
                </MenuItem>
                <MenuItem value={CustomerCreditRiskClass.Class_5}>
                  {t("credit_risk_class_" + CustomerCreditRiskClass.Class_5)}
                </MenuItem>
              </Select>
            </FormControl>
          </Col>
          <Col></Col>
        </Row>
      )}
      <Row>
        <Col>
          <FormControlLabel
            className="pt-3 pb-1"
            label={t("pre_invoicing")}
            control={
              <Checkbox
                checked={Boolean(customer.preInvoicingAt)}
                onChange={(event) => {
                  const preInvoicingAt = event.target.checked
                    ? new Date()
                    : null;
                  let customerNew = {
                    ...customer,
                    preInvoicingAt: preInvoicingAt,
                  };
                  setCustomer(customerNew);
                  handleUpdate({ preInvoicingAt: preInvoicingAt });
                }}
              />
            }
          />
        </Col>
        <Col>
          {Boolean(customer.preInvoicingAt) && (
            <FormControl fullWidth>
              <TextField
                label={t("pre_invoicing_reason")}
                onChange={(event) => {
                  setCustomer({
                    ...customer,
                    preInvoicingReason: event.target.value,
                  });
                }}
                onBlur={() =>
                  handleUpdate({
                    preInvoicingReason: customer.preInvoicingReason,
                  })
                }
                value={customer.preInvoicingReason}
                disabled={disabled}
                required={true}
              />
            </FormControl>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <FormControlLabel
            className="pt-3 pb-1"
            label={t("customer_blocked")}
            control={
              <Checkbox
                checked={Boolean(customer.blockedAt)}
                onChange={(event) => {
                  const blockedAt = event.target.checked ? new Date() : null;
                  let customerNew = {
                    ...customer,
                    blockedAt: blockedAt,
                  };
                  setCustomer(customerNew);
                  handleUpdate({
                    blockedAt: blockedAt,
                  });
                }}
              />
            }
          />
        </Col>
        <Col>
          {Boolean(customer.blockedAt) && (
            <FormControl fullWidth>
              <TextField
                label={t("customer_blocked_reason")}
                onChange={(event) => {
                  setCustomer({
                    ...customer,
                    blockedReason: event.target.value,
                  });
                }}
                onBlur={() =>
                  handleUpdate({ blockedReason: customer.blockedReason })
                }
                value={customer.blockedReason}
                disabled={disabled}
                required={true}
              />
            </FormControl>
          )}
        </Col>
      </Row>
      <FormControl fullWidth className="mt-2">
        <InputLabel id="lblCustomerHasInsurance">
          {t("has_insurance")}
        </InputLabel>
        <Select
          autoWidth
          labelId="lblCustomerHasInsurance"
          value={customer.hasInsurance ? 1 : 0}
          onChange={(event) => {
            const hasInsurance = Boolean(event.target.value);
            let customerNew = {
              ...customer,
              hasInsurance: hasInsurance,
            };
            setCustomer(customerNew);
            handleUpdate({ hasInsurance: hasInsurance });
          }}
          disabled={disabled}
        >
          <MenuItem value={0}>{t("no")}</MenuItem>
          <MenuItem value={1}>{t("yes")}</MenuItem>
        </Select>
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentCustomer);
