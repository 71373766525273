import { WatchQueryFetchPolicy } from "@apollo/client";
import { Query } from "../entity/types";
import { MS_PER_MINUTE } from "./dates";

// Record of keys and the last fetched timestamp
// These are universal for all calls and hooks
const keys = new Map<string, number>();

/**
 * This function accepts a unique key and an expiration date. It returns "network-only" if the cache key is expired
 * or "cache-first" if the key is still valid for the given expiration time
 *
 * @param key - the unique query name you want to use for setting the expiration time
 */
export const getQueryFetchPolicy = (
  key: keyof Query
): WatchQueryFetchPolicy => {
  const lastFetchTimestamp = keys.get(key);
  const diffFromNow = lastFetchTimestamp
    ? Date.now() - lastFetchTimestamp
    : Number.MAX_SAFE_INTEGER;
  let fetchPolicy: WatchQueryFetchPolicy = "cache-first";

  // Is Expired?
  if (diffFromNow > getExpirationMs(key)) {
    keys.set(key, Date.now());
    fetchPolicy = "cache-and-network";
  }

  return fetchPolicy;
};

const getExpirationMs = (key: keyof Query) => {
  switch (key) {
    case "catalog":
    case "catalogActive":
    case "catalogCategoriesForUpper":
    case "catalogCategoriesUpper":
    case "catalogExtraCategoriesUnitPrice":
    case "catalogExtraCategoriesUpperRental":
    case "catalogExtraRowsInvoiceReservation":
    case "catalogExtraRowsRental":
    case "catalogExtraRowsRentalByLocationMachines":
    case "catalogExtraRowsUnitPrice":
    case "catalogRow":
    case "catalogTransferMethods":
    case "catalogs":
    case "machine":
    case "machineBreakdowns":
    case "machineBreakdownsUnfixed":
    case "machineBulkAmountsForMachine":
    case "machineBulkBreakdownsForMachine":
    case "machineBulkBreakdownsUnfixed":
    case "machineInfoCategories":
    case "machineInfoQuestionsForDrives":
    case "machineInfoTags":
    case "machineMakes":
    case "machines":
    case "machinesForCatalog":
    case "machinesForCatalogRow":
    case "machinesForCatalogRowAvailable":
    case "machinesForCatalogRows":
    case "machinesForLocation":
    case "machinesSearchAvailable":
    case "discountList":
    case "discountLists":
    case "discountListsForCatalogRow":
    case "discountRows":
    case "settings":
    case "commissionTemplates":
    case "commissionReportForMonth":
    case "commissionRowsForUser":
    case "commissionUserSetting":
    case "commissionUserSettings":
    case "costcenters":
    case "groups":
    case "holidays":
    case "inspectionReturnAnswersForReservation":
    case "inspectionReturnCategories":
    case "inspectionReturnTags":
    case "invoicesSent":
    case "locations":
    case "productCodes":
      return MS_PER_MINUTE * 240;

    case "me":
    case "myPermissions":
    case "permissions":
    case "events":
    case "eventsOfferCatalog":
    case "eventsOfferDiscountList":
    case "customer":
    case "customerPriceForCatalogRow":
    case "customers":
    case "customersPrimary":
    case "customersOpenReservations":
    case "user":
    case "users":
    case "references":
    case "referencesByIds":
    case "order":
    case "orders":
    case "ordersForUser":
    case "invoicesOpen":
    case "logs":
    case "operationHoursForMachine":
    case "drives":
    case "drivesForWorkQueues":
    case "transportInvoicingSummariesByOrder":
    case "transportInvoicingsByOrder":
    case "transportsByOrder":
    case "transportsUnhandled":
    case "orderCanChangeCustomer":
    case "reservationsForOrder":
        return MS_PER_MINUTE * 120;

    case "bulkAmountsByDate":
    case "reservationEditableDates":
    case "reservationImageGives":
    case "reservationImageReturns":
    case "reservationImageBasics":
    case "reservationPrice":
    case "reservations":
    case "reservationsCalendar":
    case "reservationsCommissions":
    case "reservationsUpcomingByMachine":
    case "reservationsWorkQueues":
    case "reservationsWorkQueuesReturn":
    case "orderTotal":
    case "invoiceableRows":
    case "invoicingPriceForReservation":
    case "newWaitingOrCompletedIdentificationRequests":
    case "identificationRequests":
        return MS_PER_MINUTE * 0.01; // TODO Palauta 30?

    default:
      return MS_PER_MINUTE * 30;
  }
};

//     
