import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CatalogExtraCategoryUpperRentalType,
  CatalogExtraRowRentalType,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY } from "../../utils/constants";
import { useQuery } from "@apollo/client";
import {
  GET_LOCATIONS_QUERY,
  QueryResultLocations,
} from "../../apollo/queries/locations";
import Error from "../Shared/Error";
import SelectRequired from "../Shared/SelectRequired";
import LoadingSimple from "../Shared/LoadingSimple";
import { renderSelectGroup } from "../../utils/catalog_extra/catalog_extra";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  catalogExtraRowRental: CatalogExtraRowRentalType;
  setCatalogExtraRowRental: React.Dispatch<
    React.SetStateAction<CatalogExtraRowRentalType>
  >;
  catalogExtraCategoriesUpperRental: CatalogExtraCategoryUpperRentalType[];
}

function DialogContentCatalogExtraRowRental({
  classes,
  catalogExtraRowRental,
  setCatalogExtraRowRental,
  catalogExtraCategoriesUpperRental,
}: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<QueryResultLocations>(
    GET_LOCATIONS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("locations"),
    }
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "catalogs_extra.add_catalogextrarowrental",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs_extra.change_catalogextrarowrental",
  ]);
  const disabled = catalogExtraRowRental.id
    ? !hasPermissionEdit
    : !hasPermissionAdd;

  if (error) return <Error error={error} />;
  if (loading) return <LoadingSimple />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <DialogContent>
      <FormControl fullWidth>
        <InputLabel id="lblCatalogExtraRowCategory">
          {t("catalog_extra_category")}
        </InputLabel>
        <SelectRequired
          autoWidth
          labelId="lblCatalogExtraRowCategory"
          value={
            catalogExtraRowRental.catalogExtraCategoryRental
              ? catalogExtraRowRental.catalogExtraCategoryRental.id
              : ID_EMPTY
          }
          disabled={disabled}
          onChange={(event) => {
            setCatalogExtraRowRental({
              ...catalogExtraRowRental,
              catalogExtraCategoryRental: {
                ...catalogExtraRowRental.catalogExtraCategoryRental,
                id: String(event.target.value),
              },
            });
          }}
        >
          {catalogExtraCategoriesUpperRental.map(
            (catalogExtraCategoryUpperRental) =>
              renderSelectGroup(catalogExtraCategoryUpperRental)
          )}
        </SelectRequired>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblCatalogExtraRowLocation">{t("location")}</InputLabel>
        <SelectRequired
          autoWidth
          labelId="lblCatalogExtraRowLocation"
          value={catalogExtraRowRental.location.id}
          disabled={disabled}
          onChange={(event) => {
            setCatalogExtraRowRental({
              ...catalogExtraRowRental,
              location: {
                ...catalogExtraRowRental.location,
                id: String(event.target.value),
              },
            });
          }}
        >
          <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
          {data?.locations?.map((location) => (
            <MenuItem key={location.id} value={location.id}>
              {location.name}
            </MenuItem>
          ))}
        </SelectRequired>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("product_number")}
          onChange={(event) => {
            setCatalogExtraRowRental({
              ...catalogExtraRowRental,
              productNumber: event.target.value,
            });
          }}
          value={catalogExtraRowRental.productNumber}
          disabled={disabled}
          inputProps={{ maxLength: 32 }}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("identifier")}
          onChange={(event) => {
            setCatalogExtraRowRental({
              ...catalogExtraRowRental,
              identifier: event.target.value,
            });
          }}
          value={catalogExtraRowRental.identifier}
          disabled={disabled}
          inputProps={{ maxLength: 5 }}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("name")}
          onChange={(event) => {
            setCatalogExtraRowRental({
              ...catalogExtraRowRental,
              name: event.target.value,
            });
          }}
          value={catalogExtraRowRental.name}
          required={true}
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          autoFocus={true}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("information")}
          onChange={(event) => {
            setCatalogExtraRowRental({
              ...catalogExtraRowRental,
              information: event.target.value,
            });
          }}
          value={catalogExtraRowRental.information}
          disabled={disabled}
          inputProps={{ maxLength: 250 }}
        />
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentCatalogExtraRowRental);
