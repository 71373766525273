import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import {
  URL_WORK_QUEUES_DRIVING_CREATE,
  URL_WORK_QUEUES_MAINTENANCE,
  URL_WORK_QUEUES_RENTING,
} from "../../utils/urls";
import { AuthType } from "../../Root";

type Props = {
  classes: ClassNameMap;
  Auth: AuthType;
};

const AppButtonWorkQueues = ({ classes, Auth }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const LinkWorkQueuesDrivingCreate = Auth.WorkQueuesDrivingCreate(() => {
    return (
      <LinkContainer to={URL_WORK_QUEUES_DRIVING_CREATE}>
        <MenuItem onClick={handleClose}>{t("work_queues_driving")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkWorkQueuesRenting = Auth.WorkQueuesRenting(() => {
    return (
      <LinkContainer to={URL_WORK_QUEUES_RENTING}>
        <MenuItem onClick={handleClose}>{t("work_queues_renting")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkWorkQueuesMaintenance = Auth.WorkQueuesMaintenance(() => {
    return (
      <LinkContainer to={URL_WORK_QUEUES_MAINTENANCE}>
        <MenuItem onClick={handleClose}>
          {t("work_queues_maintenance")}
        </MenuItem>
      </LinkContainer>
    );
  });

  return (
    <div>
      <Button
        className={classes.btn}
        color="primary"
        aria-controls="menuWorkQueues"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <div className={classes.faIconCon}>
          <FontAwesomeIcon size="lg" icon={faList} />
        </div>
        <div>{t("work_queues")}</div>
      </Button>
      <Menu
        id="menuWorkQueues"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <LinkWorkQueuesDrivingCreate />
        <LinkWorkQueuesRenting />
        <LinkWorkQueuesMaintenance />
      </Menu>
    </div>
  );
};

export default AppButtonWorkQueues;
