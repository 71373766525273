import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultLogs {
  logs: Query["logs"];
}
export const GET_LOGS_QUERY = gql`
  query getLogsQuery($dateFrom: Date!, $dateTo: Date!) {
    logs(dateFrom: $dateFrom, dateTo: $dateTo) {
      typeKey
      method
      foreignKey
      data
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;
