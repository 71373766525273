import {
  CatalogExtraCategoryUpperRentalType,
  CatalogExtraRowInvoiceType,
} from "../../entity/types";
import { ListSubheader, MenuItem, Theme } from "@material-ui/core";
import React from "react";

export const getCatalogExtraRowInvoiceTitle = (
  catalogExtraRowInvoice: CatalogExtraRowInvoiceType
) => {
  if (catalogExtraRowInvoice.titleOverride) {
    return catalogExtraRowInvoice.titleOverride;
  } else if (catalogExtraRowInvoice.catalogExtraRowUnitPrice) {
    return catalogExtraRowInvoice.catalogExtraRowUnitPrice.name;
  }
  return "";
};

export const renderSelectGroup = (
  catalogExtraCategoryUpperRental: CatalogExtraCategoryUpperRentalType
) => {
  const itemsInner =
    catalogExtraCategoryUpperRental.catalogextracategoryrentalSet.map(
      (catalogExtraCategoryRental) => {
        return (
          <MenuItem
            key={catalogExtraCategoryRental.id}
            value={catalogExtraCategoryRental.id}
          >
            {catalogExtraCategoryRental.name}
          </MenuItem>
        );
      }
    );

  return [
    <ListSubheader>{catalogExtraCategoryUpperRental.name}</ListSubheader>,
    itemsInner,
  ];
};

export const getCatalogExtraSortStyles = ({ spacing, palette }: Theme) => ({
  cursor: "move",
  marginRight: spacing(0.5),
  padding: spacing(1),
  paddingLeft: spacing(2),
  paddingRight: spacing(2),
  "&:hover": {
    backgroundColor: palette.grey["100"],
  },
});
