import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { CatalogExtraCategoryUnitPriceType } from "../../../entity/types";
import CatalogExtraUnitPriceTable from "./CatalogExtraUnitPriceTable";
import HeaderPage from "../../Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {
  catalogExtraCategoriesUnitPrice: CatalogExtraCategoryUnitPriceType[];
}

function CatalogExtraUnitPrice({
  classes,
  catalogExtraCategoriesUnitPrice,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <HeaderPage title={t("catalog_extra_unit_price")} />
      <div className={classes.conCatalogExtra}>
        {catalogExtraCategoriesUnitPrice.map(
          (catalogExtraCategoryUnitPrice) => (
            <CatalogExtraUnitPriceTable
              key={catalogExtraCategoryUnitPrice.id}
              catalogExtraCategoryUnitPrice={catalogExtraCategoryUnitPrice}
            />
          )
        )}
      </div>
    </>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    conCatalogExtra: {
      backgroundColor: "#FFFFFF",
      padding: spacing(3),
    },
  });

export default withStyles(styles)(CatalogExtraUnitPrice);
