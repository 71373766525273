import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Box, createStyles, Tab, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { WithStyles } from "@material-ui/core/styles";
import HeaderPage from "../components/Shared/HeaderPage";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import CommissionReportRealtime from "../components/Commission/CommissionReportRealtime";
import CommissionReportArchive from "../components/Commission/CommissionReportArchive";
import { useQuery } from "@apollo/client";
import {
  GET_USERS_SIMPLE_QUERY,
  QueryResultUsersSimple,
} from "../apollo/queries/users";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import Error from "../components/Shared/Error";
import Loading from "../components/Shared/Loading";
import { PermissionsContext, UserContext } from "../Root";
import { checkPermission } from "../utils/permissions";
import {
  GET_COMMISSION_USER_SETTINGS_QUERY,
  QueryResultCommissionUserSettings,
} from "../apollo/queries/commissions";
import { CONTAINER_MAIN_WIDTH_XL } from "../utils/constants";
import { CommissionUserSettingType } from "../entity/types";

export interface CommissionUserSettingsByUserType {
  [user_id: string]: CommissionUserSettingType;
}

interface Props extends WithStyles<typeof styles> {}

function ManagementCommissions({ classes }: Props) {
  const { t } = useTranslation();

  const userLoggedIn = useContext(UserContext);

  const [tabSelected, setTabSelected] = useState("0");

  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers,
  } = useQuery<QueryResultUsersSimple>(GET_USERS_SIMPLE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("users"),
  });

  const {
    loading: loadingCommissionUserSettings,
    error: errorCommissionUserSettings,
    data: dataCommissionUserSettings,
  } = useQuery<QueryResultCommissionUserSettings>(
    GET_COMMISSION_USER_SETTINGS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("commissionUserSettings"),
    }
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionViewOthers = checkPermission(myPermissions, [
    "commissions.show_others_commissions",
  ]);

  if (loadingUsers) return <Loading />;
  if (errorUsers) return <Error error={errorUsers} />;
  if (!dataUsers) return <Error error={t("error_query_failed")} />;
  if (loadingCommissionUserSettings) return <Loading />;
  if (errorCommissionUserSettings)
    return <Error error={errorCommissionUserSettings} />;
  if (!dataCommissionUserSettings)
    return <Error error={t("error_query_failed")} />;

  const commissionUserSettings = (
    dataCommissionUserSettings.commissionUserSettings
      ? dataCommissionUserSettings.commissionUserSettings
      : []
  ).filter((commissionUserSetting) => commissionUserSetting.showCommissions);

  const commissionUserSettingsByUser: CommissionUserSettingsByUserType = {};
  commissionUserSettings.forEach((commissionUserSetting) => {
    commissionUserSettingsByUser[commissionUserSetting.user.id] =
      commissionUserSetting;
  });

  const usersThatHaveCommissions = (
    dataUsers.users ? dataUsers.users : []
  ).filter((user) => {
    const userSetting = commissionUserSettingsByUser[user.id];
    if (!hasPermissionViewOthers && user.id !== userLoggedIn.id) {
      return false;
    }
    return userSetting ? userSetting.showCommissions : false;
  });

  return (
    <Container fluid className={`containerMain ${classes.container}`}>
      <HeaderPage title={t("management_commission_report")} />
      <div className="containerInner">
        <TabContext value={tabSelected}>
          <Box>
            <TabList onChange={(event, newTab) => setTabSelected(newTab)}>
              <Tab label={t("commission_report_realtime")} value="0" />
              <Tab label={t("commission_report_archive")} value="1" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <CommissionReportRealtime
              users={usersThatHaveCommissions}
              commissionUserSettingsByUser={commissionUserSettingsByUser}
            />
          </TabPanel>
          <TabPanel value="1">
            <CommissionReportArchive
              users={usersThatHaveCommissions}
              commissionUserSettingsByUser={commissionUserSettingsByUser}
            />
          </TabPanel>
        </TabContext>
      </div>
    </Container>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: CONTAINER_MAIN_WIDTH_XL,
    },
    total: {
      fontSize: "1rem",
    },
  });

export default withStyles(styles)(ManagementCommissions);
