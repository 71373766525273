import { CatalogEmpty } from "../../entity/empties";
import { CatalogType } from "../../entity/types";
import { parseNumber } from "../formatting";
import React from "react";
import { findFromSetById } from "../collections";
import { ID_EMPTY } from "../constants";
import { isDateOlderThanHours, newDate } from "../dates";

export function getSelectedCatalog(
  catalogs: CatalogType[],
  catalogId: string,
  setCatalogId: React.Dispatch<React.SetStateAction<string>>
): CatalogType {
  let catalog = CatalogEmpty;

  if (catalogId !== ID_EMPTY) {
    catalogs.forEach(function (catalogLooped: CatalogType) {
      if (parseNumber(catalogId) === parseNumber(catalogLooped.id)) {
        catalog = catalogLooped;
      }
    });
  } else {
    let catalogIdDefault = getCatalogIdActive();
    setCatalogId(catalogIdDefault);
    catalog = findFromSetById(catalogIdDefault, catalogs, CatalogEmpty);
  }
  return catalog;
}

export const skipCatalogActiveCheck = () => {
  const lastCheck = localStorage.getItem("catalogIdActiveSet");
  let lastCheckOutdated = true;
  if (lastCheck !== null) {
    lastCheckOutdated = isDateOlderThanHours(newDate(lastCheck), 2);
  }
  return getCatalogIdActive() !== ID_EMPTY && !lastCheckOutdated;
};

export const setCatalogIdActive = (catalogId: string) => {
  localStorage.setItem("catalogIdActive", catalogId);
  localStorage.setItem("catalogIdActiveSet", new Date().toISOString());
};

export const getCatalogIdActive = () => {
  const catalogIdStorage =
    localStorage.getItem("catalogIdActive") === null ||
    localStorage.getItem("catalogIdActive") === ""
      ? ID_EMPTY
      : String(localStorage.getItem("catalogIdActive"));

  if (catalogIdStorage !== ID_EMPTY) {
    return catalogIdStorage;
  }
  return ID_EMPTY;
};

export const setCatalogIdSelected = (catalogId: string) => {
  localStorage.setItem("catalogIdSelected", catalogId);
};

export const getCatalogIdSelected = () => {
  const catalogIdStorage =
    localStorage.getItem("catalogIdSelected") === null ||
    localStorage.getItem("catalogIdSelected") === ""
      ? ID_EMPTY
      : String(localStorage.getItem("catalogIdSelected"));

  if (catalogIdStorage !== ID_EMPTY) {
    return catalogIdStorage;
  }
  return getCatalogIdActive();
};
