import { ID_EMPTY } from "../constants";
import { UserType } from "../../entity/types";

export const hasUserRequired = (user: UserType) => {
  const passOk = user.id === ID_EMPTY ? user.password.trim() !== "" : true;
  return (
    user.firstName.trim() !== "" &&
    user.lastName.trim() !== "" &&
    user.email.trim() !== "" &&
    passOk &&
    user.groups[0] &&
    user.groups[0].id !== ID_EMPTY
  );
};

export const setUserIdSelected = (userId: string) => {
  localStorage.setItem("userIdSelected", userId);
};
