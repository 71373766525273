import React from "react";

//import OperatorMainButtons from "./OperatorMainButtons";
import OperatorNewRequests from "./OperatorNewRequests";
import OperatorWaitingRequests from "./OperatorWaitingRequests";
import OperatorCompletedRequests from "./OperatorCompletedRequests";
import { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Box, createStyles, Tab, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import InvoicesCreateListReferences from "../components/Invoice/InvoicesCreateListReferences";
import HeaderPage from "../components/Shared/HeaderPage";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import InvoicesCreateListCustomers from "../components/Invoice/InvoicesCreateListCustomers";

interface Props extends WithStyles<typeof styles> {}

function OperatorMainPage({ classes }: Props) {
  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = useState("0");

  return (
    <Container className="containerMain">
      <HeaderPage title={t("operator_main_page")} />
      <div className="containerInner">
        <TabContext value={tabSelected}>
          <Box>
            <TabList onChange={(event, newTab) => setTabSelected(newTab)}>
              <Tab label={t("operator_new_identification_requests")} value="0" />
              <Tab label={t("operator_waiting_identification_requests")} value="1" />
              <Tab label={t("operator_completed_identification_requests")} value="2" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <OperatorNewRequests/>
          </TabPanel>
          <TabPanel value="1">
            <OperatorWaitingRequests/>
          </TabPanel>
          <TabPanel value="2">
            <OperatorCompletedRequests/>
          </TabPanel>
        </TabContext>
      </div>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(OperatorMainPage);

