function intersect(a: unknown[], b: unknown[]) {
  return a.filter(Set.prototype.has, new Set(b));
}

export function checkPermission(
  permissions: string[],
  permissionsNeeded: string[],
  permissionCheckMethod: string = "OR"
) {
  const permissionsFound = intersect(permissionsNeeded, permissions);

  let hasPermission = false;
  if (permissionCheckMethod === "OR" && permissionsFound.length > 0) {
    hasPermission = true;
  } else if (
    permissionCheckMethod === "AND" &&
    permissionsFound.length === permissionsNeeded.length
  ) {
    hasPermission = true;
  }

  return hasPermission;
}
