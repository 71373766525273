import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Container } from "react-bootstrap";
import DiscountTable from "../components/Discount/table/DiscountTable";
import { useQuery } from "@apollo/client";
import { QueryCatalogArgs, QueryCustomerArgs } from "../entity/types";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import {
  CatalogEmpty,
  CustomerEmpty,
  DiscountListEmptyLocked,
} from "../entity/empties";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import {
  GET_CUSTOMER_QUERY,
  QueryResultCustomer,
} from "../apollo/queries/customers";
import {
  GET_CATALOG_QUERY,
  QueryResultCatalog,
} from "../apollo/queries/catalogs";
import { updateVisibility } from "../utils/discounts/search";
import { ID_EMPTY } from "../utils/constants";
import { getUrlCustomerTab } from "../utils/urls";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {}

function CustomerOfferCatalogShow({ classes }: Props) {
  const { t } = useTranslation();
  let customer_id: string, catalog_id: string;
  ({ customer_id, catalog_id } = useParams());

  const [customer, setCustomer] = useState(CustomerEmpty);
  const [catalog, setCatalog] = useState(CatalogEmpty);
  const [discountList, setDiscountList] = useState(DiscountListEmptyLocked);
  const [search, setSearch] = useState("");
  const [catalogCategoriesUpperVisible, setCatalogCategoriesUpperVisible] =
    useState<{
      [key: string]: boolean;
    }>({});
  const [catalogCategoriesVisible, setCatalogCategoriesVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const [catalogRowsVisible, setCatalogRowsVisible] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    let {
      catalogCategoryUpperVisible,
      catalogCategoryVisible,
      catalogRowVisible,
    } = updateVisibility(catalog, search);
    setCatalogCategoriesUpperVisible(catalogCategoryUpperVisible);
    setCatalogCategoriesVisible(catalogCategoryVisible);
    setCatalogRowsVisible(catalogRowVisible);
  }, [catalog, search]);

  const { loading: loadingCatalog, error: errorCatalog } = useQuery<
    QueryResultCatalog,
    QueryCatalogArgs
  >(GET_CATALOG_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalog"),
    variables: {
      catalogId: catalog_id,
    },
    onCompleted: (data) => {
      if (data.catalog) {
        setCatalog(data.catalog);
      }
    },
  });

  const { loading: loadingCustomer, error: errorCustomer } = useQuery<
    QueryResultCustomer,
    QueryCustomerArgs
  >(GET_CUSTOMER_QUERY, {
    fetchPolicy: getQueryFetchPolicy("customer"),
    variables: {
      id: customer_id,
    },
    onCompleted: (data) => {
      if (data.customer) {
        setCustomer(data.customer);
      }
    },
  });

  if (loadingCatalog || loadingCustomer) return <Loading />;
  if (errorCatalog) return <Error error={errorCatalog} />;
  if (errorCustomer) return <Error error={errorCustomer} />;

  if (customer.id === ID_EMPTY || catalog.id === ID_EMPTY) {
    return <Error error={t("error_catalog_not_found")} />;
  }

  return (
    <Container className="containerMain">
      <h2 className="headerPage">
        <LinkContainer to={getUrlCustomerTab(customer.id, "events")}>
          <Button variant="outline-light" title={t("back_to_customer_events")}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </LinkContainer>
        &nbsp;
        {catalog.name}
      </h2>
      <div className="containerInner">
        <DiscountTable
          catalog={catalog}
          discountList={discountList}
          setDiscountList={setDiscountList}
          search={search}
          setSearch={setSearch}
          catalogRowsVisible={catalogRowsVisible}
          catalogCategoriesVisible={catalogCategoriesVisible}
          catalogCategoriesUpperVisible={catalogCategoriesUpperVisible}
          customer={customer}
        />
      </div>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CustomerOfferCatalogShow);
