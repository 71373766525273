import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Mutation,
  MutationCreateTransportInvoicingSummaryArgs,
  OrderType,
  QueryTransportInvoicingSummariesByOrderArgs,
  ReferenceType,
  TransportInvoicingType,
} from "../../entity/types";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_TRANSPORT_INVOICING_SUMMARIES_BY_ORDER_QUERY,
  QueryResultTransportInvoicingSummariesByOrder,
} from "../../apollo/queries/transports";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import { Button, Table } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import { handleError } from "../../entity/ErrorHandler";
import { CREATE_TRANSPORT_INVOICING_SUMMARY_MUTATION } from "../../apollo/mutations/transports";
import { ID_EMPTY } from "../../utils/constants";
import TableRowTransportInvoicingSummary from "./TableRowTransportInvoicingSummary";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { getPercentFactor } from "../../utils/calc";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  order: OrderType;
  references: ReferenceType[];
  total: number;
  transportInvoicings: TransportInvoicingType[];
}

function TransportInvoicingSummary({
  classes,
  order,
  references,
  total,
  transportInvoicings,
}: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultTransportInvoicingSummariesByOrder,
    QueryTransportInvoicingSummariesByOrderArgs
  >(GET_TRANSPORT_INVOICING_SUMMARIES_BY_ORDER_QUERY, {
    fetchPolicy: getQueryFetchPolicy("transportInvoicingSummariesByOrder"),
    variables: {
      orderId: order.id,
    },
  });

  const [createTransportInvoicingSummary, { loading: loadingCreate }] =
    useMutation<Mutation, MutationCreateTransportInvoicingSummaryArgs>(
      CREATE_TRANSPORT_INVOICING_SUMMARY_MUTATION,
      {
        refetchQueries: [
          {
            query: GET_TRANSPORT_INVOICING_SUMMARIES_BY_ORDER_QUERY,
            variables: { orderId: order.id },
          },
        ],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  if (loading || loadingCreate) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const onClickAddRow = () => {
    createTransportInvoicingSummary({
      variables: {
        orderId: order.id,
        referenceId: ID_EMPTY,
        percent: 0,
        price: 0,
      },
    });
  };

  const percentTotal = data.transportInvoicingSummariesByOrder
    ? data.transportInvoicingSummariesByOrder.reduce(
        (sum, transportInvoicingSummary) =>
          sum + parseNumber(transportInvoicingSummary.percent),
        0
      )
    : 0;

  const portionTotal = data.transportInvoicingSummariesByOrder
    ? data.transportInvoicingSummariesByOrder.reduce(
        (sum, transportInvoicingSummary) =>
          sum + total * getPercentFactor(transportInvoicingSummary.percent),
        0
      )
    : 0;

  return (
    <div className="mb-1 mt-5">
      <h4 className="mb-3">{t("transport_invoicing_summary_rows")}</h4>
      {data.transportInvoicingSummariesByOrder &&
        data.transportInvoicingSummariesByOrder.length > 0 && (
          <Table className="w-auto" borderless>
            <thead>
              <tr>
                <th>{t("reference")}</th>
                <th>{t("percent")}</th>
                <th>{t("total")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.transportInvoicingSummariesByOrder?.map(
                (transportInvoicingSummary) => (
                  <TableRowTransportInvoicingSummary
                    key={transportInvoicingSummary.id}
                    order={order}
                    transportInvoicingSummary={transportInvoicingSummary}
                    references={references}
                    total={total}
                  />
                )
              )}
            </tbody>
            <tfoot>
              <tr>
                <th></th>
                <th>{formatNumber(percentTotal, 2)}</th>
                <th>{formatNumber(portionTotal, 2)}</th>
                <th></th>
              </tr>
            </tfoot>
          </Table>
        )}
      <Button
        onClick={onClickAddRow}
        className="btnRoundSmall d-inline-block"
        variant="primary"
      >
        <AddIcon />
      </Button>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(TransportInvoicingSummary);
