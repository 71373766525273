import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { CustomerType } from "../../entity/types";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSitemap, faStar } from "@fortawesome/pro-light-svg-icons";
import format from "date-fns/format";
import { LinkContainer } from "react-router-bootstrap";
import { getUrlCustomerTab } from "../../utils/urls";
import { newDate } from "../../utils/dates";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  customerSelected: CustomerType;
  isFamilyHead: boolean;
}

const CustomerShowBasicFamilyRow = ({
  classes,
  customer,
  customerSelected,
  isFamilyHead,
}: Props) => {
  const { t } = useTranslation();

  return (
    <LinkContainer to={getUrlCustomerTab(customer.id, "basic")}>
      <tr
        className={`${classes.tr} ${
          customerSelected.id === customer.id ? "table-active" : ""
        }`}
      >
        <td>
          <FontAwesomeIcon
            className="me-2"
            icon={isFamilyHead ? faStar : faSitemap}
          />
          {t(isFamilyHead ? "customer_family_head" : "customer_family_child")}
        </td>
        <td>
          {customer.name}
          <br />
          {customer.contactPersonLastname +
            " " +
            customer.contactPersonFirstname}
        </td>
        <td>
          {customer.userOwner
            ? customer.userOwner.lastName + " " + customer.userOwner.firstName
            : ""}
          <br />
          {customer.contactNext
            ? format(newDate(customer.contactNext), t("format_date"))
            : ""}
        </td>
      </tr>
    </LinkContainer>
  );
};

const styles = ({ spacing }: Theme) =>
  createStyles({
    tr: {
      cursor: "pointer",
    },
  });

export default withStyles(styles)(CustomerShowBasicFamilyRow);
