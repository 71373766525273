import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { CREATE_SIMPLE_IDENTIFICATIONREQUEST } from "../../apollo/mutations/identification_requests";
import { Button } from "react-bootstrap";
import { handleError } from "../../entity/ErrorHandler";
import {
  GroupType,
  LocationType,
  Mutation,
  MutationCreateSimpleIdentificationRequestArgs,
  IdentificationRequestType,
} from "../../entity/types";
import { SimpleIdentificationRequestEmpty } from "../../entity/empties";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { GET_IDENTIFICATION_REQUESTS_BY_STATE } from "../../apollo/queries/identification_requests";
// import { CREATE_IDENTIFICATION_REQUEST } from "../../apollo/mutations/identification_requests";

interface Props extends WithStyles<typeof styles> {
}

function CreateSimpleIdentificationRequestWithoutConfirmation({ classes }: Props) {
  const { t } = useTranslation();
  const [request, setRequest] = useState<IdentificationRequestType>(SimpleIdentificationRequestEmpty);

  const [createElem] = useMutation<Mutation, MutationCreateSimpleIdentificationRequestArgs>(
    CREATE_SIMPLE_IDENTIFICATIONREQUEST,
    {
      onCompleted: (result) => {
        setRequest(SimpleIdentificationRequestEmpty);
      },
      onError: (error) => {
        handleError(error);
      },
      update: (cache) => {
        cache.evict({
          id: ROOT_QUERY,
          fieldName: getQueryKey("identificationRequests"),
        });
      },
    }
  );

  
  const myPermissions = useContext(PermissionsContext);
  //const hasPermissionAdd = checkPermission(myPermissions, ["auth.add_user"]); // TODO identification request permission
  const hasPermissionAdd = true; //TODO VL esta normikayttajilta
  if (!hasPermissionAdd) {
    return <></>;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createElem({
      variables: {
        private: true
      },
      refetchQueries: [{
        query: GET_IDENTIFICATION_REQUESTS_BY_STATE, 
        variables: { state: "new" }}]
    });
  };

  return (
    <>
      <Button
        onClick={() => {
          createElem({
            variables: {
              private: true
            },
            refetchQueries: [{
              query: GET_IDENTIFICATION_REQUESTS_BY_STATE, 
              variables: { state: "new" }}]
          });
        }}
        className="addButton"
        variant="primary"
        size="lg"
      >
        <AddIcon/>
      </Button>
      
    </>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: spacing(2),
    },
    textField: {
      marginTop: spacing(1),
      marginBottom: spacing(1),
    },
  });

export default withStyles(styles)(CreateSimpleIdentificationRequestWithoutConfirmation);
