import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Button, Table } from "react-bootstrap";
import {
  InspectionGiveToMachineType,
  MachineType,
  QueryMachineArgs,
} from "../../entity/types";
import { useQuery } from "@apollo/client";
import {
  GET_MACHINE_WITH_INSPECTION_GIVE_CATEGORIES_QUERY,
  QueryResultMachineWithInspectionGiveCategories,
} from "../../apollo/queries/inspections";
import Error from "../Shared/Error";
import { MachineEmpty } from "../../entity/empties";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import DialogContentInspectionGiveActions from "./DialogContentInspectionGiveActions";
import { getMachineName } from "../../utils/machines/machine";
import InspectionGiveToMachineQuestionRow from "./InspectionGiveToMachineQuestionRow";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import { WithStyles } from "@material-ui/core/styles";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  machine: MachineType;
}

function DialogInspectionGive({ classes, open, setOpen, machine }: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultMachineWithInspectionGiveCategories,
    QueryMachineArgs
  >(GET_MACHINE_WITH_INSPECTION_GIVE_CATEGORIES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("machine"),
    variables: {
      machineId: machine.id,
    },
  });

  if (error) return <Error error={error} />;

  let inspectionGiveCategoryIdsVisible: string[] = [];
  let inspectionGiveQuestionToMachine: {
    [question_id: string]: InspectionGiveToMachineType;
  } = {};
  data?.machine?.inspectiongivetomachineSet.forEach(
    (inspectionGiveToMachine) => {
      const question_id = inspectionGiveToMachine.inspectionGiveQuestion.id;
      const category_id =
        inspectionGiveToMachine.inspectionGiveQuestion.inspectionGiveCategory
          .id;
      inspectionGiveQuestionToMachine[question_id] = inspectionGiveToMachine;
      if (!inspectionGiveCategoryIdsVisible.includes(category_id)) {
        inspectionGiveCategoryIdsVisible.push(category_id);
      }
    }
  );

  const onClickClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitleWithClose
        id="dialogTitleInspectionGive"
        onClose={onClickClose}
      >
        {t("inspection_give")}{" "}
        <small className="text-muted ms-3">{getMachineName(machine)}</small>
      </DialogTitleWithClose>
      <DialogContent className={loading ? "loading" : ""}>
        <p className="text-muted mb-4">
          <FontAwesomeIcon
            className={`${classes.info} me-1`}
            icon={faInfoCircle}
          />
          {t("explain_inspection_to_machine")}
        </p>
        <DialogContentInspectionGiveActions
          machine={data?.machine ? data.machine : MachineEmpty}
          machines={data?.machines ? data.machines : []}
          inspectionGiveTags={
            data?.inspectionGiveTags ? data.inspectionGiveTags : []
          }
          inspectionGiveCategories={
            data?.inspectionGiveCategories ? data.inspectionGiveCategories : []
          }
        />
        {data?.inspectionGiveCategories?.map((inspectionGiveCategory) => {
          if (
            !inspectionGiveCategoryIdsVisible.includes(
              inspectionGiveCategory.id
            )
          ) {
            return undefined;
          }
          return (
            <div key={inspectionGiveCategory.id} className="mb-5">
              <h5 className="mb-2">{inspectionGiveCategory.name}</h5>
              <Table borderless size="sm" className="w-auto">
                <tbody>
                  {inspectionGiveCategory.inspectiongivequestionSet.map(
                    (inspectionGiveQuestion) => {
                      if (
                        typeof inspectionGiveQuestionToMachine[
                          inspectionGiveQuestion.id
                        ] === "undefined"
                      ) {
                        return undefined;
                      }
                      return (
                        <InspectionGiveToMachineQuestionRow
                          key={inspectionGiveQuestion.id}
                          inspectionGiveToMachine={
                            inspectionGiveQuestionToMachine[
                              inspectionGiveQuestion.id
                            ]
                          }
                          inspectionGiveQuestion={inspectionGiveQuestion}
                        />
                      );
                    }
                  )}
                </tbody>
              </Table>
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickClose} variant="secondary">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    info: {
      color: palette.secondary.main,
    },
  });

export default withStyles(styles)(DialogInspectionGive);
