/* When user inputs a percent (defaults to -) */
import { parseNumber } from "../formatting";

export function getPercentPrefixUserInput(percent: string) {
  const percentNumber = parseNumber(percent);
  if (percentNumber === 0) {
    return "";
  }

  let chr = percent.charAt(0);
  let prefix = "-";
  if (chr === "+") {
    prefix = "+";
  } else if (chr === "-") {
    prefix = "";
  }

  return prefix;
}

/* When the percent is used in calculation (no +) */
export function getPercentPrefixCalc(percent: string) {
  let prefix = "";
  let chr = percent.charAt(0);
  if (chr !== "+" && chr !== "-") {
    prefix = "-";
  }

  return prefix;
}

/* When percent is from the database (defaults to +) */
export function getPercentPrefixDB(percent: string) {
  let prefix = "+";
  let chr = percent.charAt(0);
  if (chr === "+" || chr === "-") {
    prefix = "";
  }

  return prefix;
}
