import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { createStyles, Theme } from "@material-ui/core";
import { OperationVariables, useQuery } from "@apollo/client";
import UserList from "../components/User/UserList";
import { IdentificationRequestArgs } from "../entity/types";
import { GET_IDENTIFICATION_REQUESTS_BY_STATE, QueryResultIdentificationRequestsByState } from "../apollo/queries/identification_requests";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  GET_PROFILES_QUERY,
  QueryResultProfiles,
} from "../apollo/queries/profiles";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import OperatorWaitingRequestsTable from "./OperatorWaitingRequestsTable";


interface Props extends WithStyles<typeof styles> {}
type Varrit = object

function OperatorWaitingRequests({ classes }: Props) {
  const { t } = useTranslation();
  const {
    loading: loading,
    error: error,
    data: dataIdentificationRequests,
  } = useQuery<QueryResultIdentificationRequestsByState, IdentificationRequestArgs>(GET_IDENTIFICATION_REQUESTS_BY_STATE, 
    { 
     pollInterval: 2000,
     variables:  {"__activeOrganisation": 1, "state": "waiting"},
    });


  if (loading) return <Loading />;
  
  if (error) return <Error error={error} />;
  if (!dataIdentificationRequests) {
    return <Error error={t("error_query_failed")} />;
  }

  return (
    <Container className="containerMain">
      <OperatorWaitingRequestsTable
        elems={dataIdentificationRequests.identificationRequestsByState ? 
          dataIdentificationRequests.identificationRequestsByState : []}
      />
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(OperatorWaitingRequests);
