import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogCategoryUpperType,
  CatalogRowType,
  CatalogType,
  IdPercentPrintInputType,
  Mutation,
  MutationCreateCatalogRowArgs,
  MutationUpdateCatalogRowArgs,
  MutationUpdateCatalogRowLockedArgs,
  MutationUpdateDiscountRowPercentsPrintsArgs,
} from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import {
  CREATE_CATALOG_ROW_MUTATION,
  UPDATE_CATALOG_ROW_LOCKED_MUTATION,
  UPDATE_CATALOG_ROW_MUTATION,
} from "../../apollo/mutations/catalogs";
import { GET_CATALOG_ROW_DISCOUNTS_MACHINES_QUERY } from "../../apollo/queries/catalogs";
import { CatalogRowEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import DialogContentCatalogRow from "./DialogContentCatalogRow";
import { ID_EMPTY } from "../../utils/constants";
import { isLocked } from "../../utils/catalogs/locked";
import { UPDATE_DISCOUNT_ROW_PERCENTS_PRINTS_MUTATION } from "../../apollo/mutations/discounts";
import { parseNumber } from "../../utils/formatting";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import {
  updateCacheCatalogEdit,
  updateCacheCatalogRowEdit,
} from "../../utils/cache";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalogCategoriesUpper: CatalogCategoryUpperType[];
  catalogRowOrig: CatalogRowType;
  setName?: React.Dispatch<React.SetStateAction<string>>;
  catalog: CatalogType;
}

export type discountRowPercentsType = {
  [discountRowId: string]: {
    percent: string;
    locked: boolean;
  };
};

function DialogCatalogRow({
  classes,
  open,
  setOpen,
  catalogCategoriesUpper,
  catalogRowOrig,
  setName,
  catalog,
}: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditDiscountRow = checkPermission(myPermissions, [
    "discounts.change_discountrow",
  ]);

  const [catalogRow, setCatalogRow] = useState<CatalogRowType>(catalogRowOrig);
  const [machineIds, setMachineIds] = useState<string[]>([]);
  const [discountRowPercents, setDiscountRowPercents] =
    useState<discountRowPercentsType>({});

  const [createCatalogRow, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateCatalogRowArgs
  >(CREATE_CATALOG_ROW_MUTATION, {
    onCompleted: (result) => {
      setCatalogRow(CatalogRowEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheCatalogEdit(cache);
    },
  });
  const [updateCatalogRow, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCatalogRowArgs
  >(UPDATE_CATALOG_ROW_MUTATION, {
    onCompleted: () => {
      if (setName) {
        setName(catalogRow.name);
      }
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheCatalogRowEdit(cache);
    },
  });
  const [updateCatalogRowLocked, { loading: loadingUpdateLocked }] =
    useMutation<Mutation, MutationUpdateCatalogRowLockedArgs>(
      UPDATE_CATALOG_ROW_LOCKED_MUTATION,
      {
        onCompleted: () => {
          if (setName) {
            setName(catalogRow.name);
          }
        },
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const [
    updateDiscountRowPercentsPrints,
    { loading: loadingUpdatePercentsPrints },
  ] = useMutation<Mutation, MutationUpdateDiscountRowPercentsPrintsArgs>(
    UPDATE_DISCOUNT_ROW_PERCENTS_PRINTS_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_CATALOG_ROW_DISCOUNTS_MACHINES_QUERY,
          variables: { catalogRowId: catalogRow.id },
        },
      ],
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const lockedCatalog = isLocked(catalog);

  const handleSubmitCatalog = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const callback = () => {
      setOpen(false);
    };

    if (catalogRow.id !== ID_EMPTY) {
      const options = {
        variables: {
          catalogRowId: catalogRow.id,
          catalogCategoryId: catalogRow.catalogCategory
            ? catalogRow.catalogCategory.id
            : ID_EMPTY,
          name: catalogRow.name,
          information: catalogRow.information,
          billingDaysWeekCompany: catalogRow.billingDaysWeekCompany,
          priceDayCompany: catalogRow.priceDayCompany,
          priceMonthCompany: catalogRow.priceMonthCompany,
          billingDaysWeekPrivate: catalogRow.billingDaysWeekPrivate,
          priceDayPrivate: catalogRow.priceDayPrivate,
          priceMonthPrivate: catalogRow.priceMonthPrivate,
          hourLimitDayCompany: catalogRow.hourLimitDayCompany,
          hourLimitDayPrivate: catalogRow.hourLimitDayPrivate,
          hourLimitMonthCompany: catalogRow.hourLimitMonthCompany,
          hourLimitMonthPrivate: catalogRow.hourLimitMonthPrivate,
          extraHoursCompany: catalogRow.extraHoursCompany,
          extraHoursPrivate: catalogRow.extraHoursPrivate,
          productNumberDay: catalogRow.productNumberDay,
          productNumberMonth: catalogRow.productNumberMonth,
          machineIds: machineIds,
        },
      };

      if (lockedCatalog) {
        updateCatalogRowLocked(options).then(callback);
      } else {
        updateCatalogRow(options).then(callback);
      }

      /*******************************************************/

      if (hasPermissionEditDiscountRow) {
        let idPercentPrintInput: Array<IdPercentPrintInputType> = [];
        Object.entries(discountRowPercents).forEach(([discountRowId, row]) => {
          if (!row.locked) {
            idPercentPrintInput.push({
              id: discountRowId,
              percent: String(parseNumber(row.percent)),
            });
          }
        });
        updateDiscountRowPercentsPrints({
          variables: {
            idPercentPrintInput,
          },
        });
      }
    } else {
      createCatalogRow({
        variables: {
          catalogCategoryId: catalogRow.catalogCategory
            ? catalogRow.catalogCategory.id
            : ID_EMPTY,
          name: catalogRow.name,
          information: catalogRow.information,
          billingDaysWeekCompany: catalogRow.billingDaysWeekCompany,
          priceDayCompany: catalogRow.priceDayCompany,
          priceMonthCompany: catalogRow.priceMonthCompany,
          billingDaysWeekPrivate: catalogRow.billingDaysWeekPrivate,
          priceDayPrivate: catalogRow.priceDayPrivate,
          priceMonthPrivate: catalogRow.priceMonthPrivate,
          hourLimitDayCompany: catalogRow.hourLimitDayCompany,
          hourLimitDayPrivate: catalogRow.hourLimitDayPrivate,
          hourLimitMonthCompany: catalogRow.hourLimitMonthCompany,
          hourLimitMonthPrivate: catalogRow.hourLimitMonthPrivate,
          extraHoursCompany: catalogRow.extraHoursCompany,
          extraHoursPrivate: catalogRow.extraHoursPrivate,
          productNumberDay: catalogRow.productNumberDay,
          productNumberMonth: catalogRow.productNumberMonth,
          machineIds: machineIds,
        },
      }).then(callback);
    }
  };

  return (
    <Dialog open={open}>
      <form
        className={classes.form}
        onSubmit={(event) => handleSubmitCatalog(event)}
      >
        <DialogTitle>
          {t(
            catalogRow.id !== ID_EMPTY ? "edit_catalog_row" : "new_catalog_row"
          )}
        </DialogTitle>
        <DialogContentCatalogRow
          catalog={catalog}
          catalogRow={catalogRow}
          setCatalogRow={setCatalogRow}
          catalogCategoriesUpper={catalogCategoriesUpper}
          machineIds={machineIds}
          setMachineIds={setMachineIds}
          discountRowPercents={discountRowPercents}
          setDiscountRowPercents={setDiscountRowPercents}
        />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <ButtonLoad
            loading={
              loadingCreate ||
              loadingUpdate ||
              loadingUpdateLocked ||
              loadingUpdatePercentsPrints
            }
            disabled={!catalogRow.name.trim() || !catalogRow.catalogCategory.id}
            type="submit"
            variant="primary"
          >
            {t("save")}
          </ButtonLoad>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: spacing(2),
    },
    textField: {
      marginTop: spacing(1),
      marginBottom: spacing(1),
    },
  });

export default withStyles(styles)(DialogCatalogRow);
