import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Hidden, Paper, Theme } from "@material-ui/core";
import { DriveType, OrderType, TransportMethod } from "../../entity/types";
import { useTranslation } from "react-i18next";
import WorkQueueCardRenting from "./WorkQueueCardRenting";
import {
  getAddressSingleLine,
  timeWithoutSeconds,
} from "../../utils/formatting";
import format from "date-fns/format";
import WorkQueueCardRentingTiny from "./WorkQueueCardRentingTiny";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { newDate } from "../../utils/dates";
import WorkQueuePdfButtons from "./WorkQueuePdfButtons";

export type DriveListType = "give" | "return";

interface Props extends WithStyles<typeof styles> {
  drive: DriveType;
}

function WorkQueueCardDrive({ classes, drive }: Props) {
  const { t } = useTranslation();

  const [showLargeCards, setShowLargeCards] = useState(false);

  const orders: OrderType[] = drive.drivecontentSet.reduce(
    (arr, driveContent) => {
      if (
        !arr.some((order) => order.id === driveContent.reservation.order.id)
      ) {
        arr.push(driveContent.reservation.order);
      }
      return arr;
    },
    [] as OrderType[]
  );

  return (
    <Paper className={classes.cardDrive} variant="outlined">
      <Button
        className={classes.btnToggle}
        variant="light"
        onClick={() => setShowLargeCards(!showLargeCards)}
      >
        <Hidden smDown>
          <span className="me-2">{drive.drivecontentSet.length}</span>
        </Hidden>
        <FontAwesomeIcon icon={showLargeCards ? faChevronUp : faChevronDown} />
      </Button>
      <WorkQueuePdfButtons driveId={drive.id} orders={orders} />
      {drive.drivecontentSet.map((driveContent, index, driveContents) => {
        const driveContentPrev = index > 0 ? driveContents[index - 1] : null;
        const isSameTransport =
          driveContentPrev &&
          driveContentPrev.transport.id === driveContent.transport.id;
        const type: DriveListType =
          driveContent.transport.method === TransportMethod.Send
            ? "give"
            : "return";
        return (
          <div key={driveContent.id} className="mt-3">
            {!isSameTransport && (
              <div className="conTransportDetails">
                <div>
                  <b>
                    {t("transport_method_" + driveContent.transport.method)}
                  </b>{" "}
                  {driveContent.transport.dateDeadline
                    ? format(
                        newDate(driveContent.transport.dateDeadline),
                        t("format_date")
                      )
                    : ""}{" "}
                  {timeWithoutSeconds(driveContent.transport.timeDeadline)}
                </div>
                <div>
                  {getAddressSingleLine(
                    t,
                    driveContent.transport.address,
                    driveContent.transport.postcode,
                    driveContent.transport.district
                  )}
                </div>
                {driveContent.transport.information && (
                  <div className="text-muted">
                    {driveContent.transport.information}
                  </div>
                )}
              </div>
            )}
            {showLargeCards && (
              <WorkQueueCardRenting
                reservation={driveContent.reservation}
                type={type}
                transport={driveContent.transport}
              />
            )}
            {!showLargeCards && (
              <WorkQueueCardRentingTiny
                reservation={driveContent.reservation}
                type={type}
              />
            )}
          </div>
        );
      })}
    </Paper>
  );
}

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    cardDrive: {
      padding: spacing(2),
      paddingTop: 0,
      marginTop: spacing(2),
      position: "relative",
    },
    btnPdf: {
      position: "absolute",
      top: spacing(1),
      right: spacing(10),
      [breakpoints.down("sm")]: {
        right: spacing(7),
      },
    },
    btnToggle: {
      position: "absolute",
      top: spacing(1),
      right: spacing(1),
    },
  });

export default withStyles(styles)(WorkQueueCardDrive);
