import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import { getCellClassName } from "../../utils/calendar/calendar";

interface Props extends WithStyles<typeof styles> {
  date: Date;
}

function CalendarTheadTh({ classes, date }: Props) {
  const { t } = useTranslation();

  return (
    <th
      className={`text-center ${getCellClassName(date, [], [], [])}`}
      title={format(date, t("format_date"))}
    >
      {date.getDate()}
      <br />
      <small className="text-muted">
        {t("day_name_short_" + date.getDay())}
      </small>
    </th>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CalendarTheadTh);
