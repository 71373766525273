import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import { createStyles, Paper, Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { MachineInfoQuestionType, TransportType } from "../../entity/types";
import { Table } from "react-bootstrap";
import {
  checkIfReservationHandled,
  getReservationProductName,
} from "../../utils/reservations/reservation";
import format from "date-fns/format";
import { timeWithoutSeconds } from "../../utils/formatting";
import { TranportReservationSelected } from "./DrivingCreate";
import { newDate } from "../../utils/dates";

interface Props extends WithStyles<typeof styles> {
  transport: TransportType;
  machineInfoQuestions: MachineInfoQuestionType[];
  transportReservationsSelected: TranportReservationSelected[];
  setTransportReservationsSelected: React.Dispatch<
    React.SetStateAction<TranportReservationSelected[]>
  >;
}

function TransportCardDrive({
  classes,
  transport,
  machineInfoQuestions,
  transportReservationsSelected,
  setTransportReservationsSelected,
}: Props) {
  const { t } = useTranslation();

  const getIndexOfRes = (item: TranportReservationSelected) =>
    transportReservationsSelected.findIndex(
      (x) =>
        x.transport.id === item.transport.id &&
        x.reservation.id === item.reservation.id
    );

  const onClickReservation = (
    index: number,
    item: TranportReservationSelected
  ) => {
    if (index >= 0) {
      const arrNew = [...transportReservationsSelected];
      arrNew.splice(index, 1);
      setTransportReservationsSelected(arrNew);
    } else {
      setTransportReservationsSelected([
        ...transportReservationsSelected,
        item,
      ]);
    }
  };

  return (
    <Paper className={`${classes.conTransport} mt-3`}>
      {transport.dateDeadline && (
        <div className={classes.conDatetime}>
          {format(newDate(transport.dateDeadline), t("format_date"))}{" "}
          {timeWithoutSeconds(transport.timeDeadline)}
        </div>
      )}
      <Table className="w-auto mt-2" borderless size="sm">
        <thead>
          <tr>
            <th>{t("transport_method_" + transport.method)}</th>
            <th>{transport.catalogTransferMethod?.name}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>{transport.address}</td>
          </tr>
          <tr>
            <td>{transport.postcode}</td>
            <td>{transport.district}</td>
          </tr>
          <tr>
            <td colSpan={2}>{transport.information}</td>
          </tr>
        </tbody>
      </Table>
      <Table className="w-auto" borderless size="sm">
        <tbody>
          {transport.reservations.map((reservation) => {
            const item: TranportReservationSelected = {
              transport: transport,
              reservation: reservation,
            };
            const index = getIndexOfRes(item);

            let trs = [];
            const handled = checkIfReservationHandled(transport, reservation);

            if (!handled) {
              trs.push([
                <tr
                  key={reservation.id}
                  className={`cursor-pointer ${classes.trReservation} ${
                    index >= 0 ? "table-active" : ""
                  }`}
                  onClick={() => onClickReservation(index, item)}
                >
                  <td>{getReservationProductName(t, reservation, true)}</td>
                  <td>{reservation.machine?.location?.name}</td>
                </tr>,
              ]);

              machineInfoQuestions.forEach((machineInfoQuestion) => {
                machineInfoQuestion.machineinfoanswerSet.forEach(
                  (machineInfoAnswer) => {
                    if (
                      machineInfoAnswer.machine.id === reservation.machine?.id
                    ) {
                      trs.push(
                        <tr
                          key={`trQuestion${machineInfoAnswer.id}`}
                          className="text-muted"
                        >
                          <td>{machineInfoQuestion.question}</td>
                          <td>{machineInfoAnswer.answer}</td>
                        </tr>
                      );
                    }
                  }
                );
              });
            }

            return trs;
          })}
        </tbody>
      </Table>
    </Paper>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    conTransport: {
      padding: spacing(2),
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
      position: "relative",
    },
    conDatetime: {
      position: "absolute",
      top: spacing(2),
      right: spacing(2),
    },
    trReservation: {
      "& td, th": {
        paddingTop: spacing(1),
      },
      "&:hover td, &:hover th": {
        backgroundColor: "#ECECEC",
      },
    },
  });

export default withStyles(styles)(TransportCardDrive);
