import React, { ReactElement, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Menu, MenuItem, Theme } from "@material-ui/core";
import { Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getUrlCalendar } from "../../utils/urls";
import { useQuery } from "@apollo/client";
import {
  GET_CATALOG_WITH_ROWS_QUERY,
  QueryResultCatalogWithRows,
} from "../../apollo/queries/catalogs";
import {
  CatalogCategoryType,
  CatalogCategoryUpperType,
  CatalogRowType,
  QueryCatalogArgs,
} from "../../entity/types";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { getCatalogIdActive } from "../../utils/catalogs/selected_catalog";
import { useHistory } from "react-router-dom";
import { ID_EMPTY } from "../../utils/constants";
import {
  setLocalStorageCustomerId,
  setLocalStorageOrderId,
} from "../../utils/calendar/calendar";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  customerId: string;
}

function LinkCalendarSelect({ classes, customerId }: Props): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const catalogId = getCatalogIdActive();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { loading, error, data } = useQuery<
    QueryResultCatalogWithRows,
    QueryCatalogArgs
  >(GET_CATALOG_WITH_ROWS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalog"),
    variables: {
      catalogId: catalogId,
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const selectCustomer = () => {
    setLocalStorageOrderId("");
    setLocalStorageCustomerId(customerId);
  };

  const onClickCategoryUpper = (
    catalogCategoryUpper: CatalogCategoryUpperType
  ) => {
    selectCustomer();
    history.push(
      getUrlCalendar(catalogId, catalogCategoryUpper.id, ID_EMPTY, ID_EMPTY)
    );
  };

  const onClickCategory = (
    catalogCategoryUpper: CatalogCategoryUpperType,
    catalogCategory: CatalogCategoryType
  ) => {
    selectCustomer();
    history.push(
      getUrlCalendar(
        catalogId,
        catalogCategoryUpper.id,
        catalogCategory.id,
        ID_EMPTY
      )
    );
  };

  const onClickRow = (
    catalogCategoryUpper: CatalogCategoryUpperType,
    catalogCategory: CatalogCategoryType,
    catalogRow: CatalogRowType
  ) => {
    selectCustomer();
    history.push(
      getUrlCalendar(
        catalogId,
        catalogCategoryUpper.id,
        catalogCategory.id,
        catalogRow.id
      )
    );
  };

  const items: ReactElement<typeof MenuItem>[] = [];
  data.catalog?.catalogcategoryupperSet.forEach((catalogCategoryUpper) => {
    items.push(
      <MenuItem
        key={catalogCategoryUpper.id}
        onClick={() => onClickCategoryUpper(catalogCategoryUpper)}
      >
        {catalogCategoryUpper.name}
      </MenuItem>
    );

    catalogCategoryUpper.catalogcategorySet.forEach((catalogCategory) => {
      items.push(
        <MenuItem
          key={`${catalogCategoryUpper.id}_${catalogCategory.id}`}
          onClick={() => onClickCategory(catalogCategoryUpper, catalogCategory)}
        >
          <span className="ms-4">{catalogCategory.name}</span>
        </MenuItem>
      );

      catalogCategory.catalogrowSet.forEach((catalogRow) => {
        items.push(
          <MenuItem
            key={`${catalogCategoryUpper.id}_${catalogCategory.id}_${catalogRow.id}`}
            onClick={() =>
              onClickRow(catalogCategoryUpper, catalogCategory, catalogRow)
            }
          >
            <span className="ms-5">{catalogRow.name}</span>
          </MenuItem>
        );
      });
    });
  });

  return (
    <>
      <Navbar.Text className="cursor-pointer nav-link" onClick={onClick}>
        {t("calendar")}
      </Navbar.Text>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {items}
      </Menu>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(LinkCalendarSelect);
