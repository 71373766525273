import { gql } from "@apollo/client";

export const CREATE_PRODUCT_CODE_MUTATION = gql`
  mutation createProductCode($code: String!, $description: String) {
    createProductCode(code: $code, description: $description) {
      productCode {
        id
        code
        description
        createdAt
      }
    }
  }
`;

export const UPDATE_PRODUCT_CODE_MUTATION = gql`
  mutation updateProductCode(
    $productCodeId: ID!
    $code: String
    $description: String
  ) {
    updateProductCode(
      productCodeId: $productCodeId
      code: $code
      description: $description
    ) {
      productCode {
        id
        code
        description
      }
    }
  }
`;

export const DELETE_PRODUCT_CODE_MUTATION = gql`
  mutation deleteProductCode($productCodeId: ID!) {
    deleteProductCode(productCodeId: $productCodeId) {
      productCodeId
    }
  }
`;
