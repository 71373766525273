import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  CustomerType,
  Mutation,
  MutationUpdateCustomerArgs,
} from "../../entity/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import InvoiceableRowsCustomer from "./InvoiceableRowsCustomer";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOMER_MUTATION } from "../../apollo/mutations/customers";
import { handleError } from "../../entity/ErrorHandler";
import { DATE_FORMAT_ISO } from "../../utils/constants";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import ButtonLoad from "../Shared/ButtonLoad";
import format from "date-fns/format";
import { newDate } from "../../utils/dates";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  dateTo: Date;
  locationIdsSelected: string[];
  hideSurchargeInvoices: boolean;
  onlyEndedReservations: boolean;
}

function InvoiceCustomer({
  classes,
  customer,
  dateTo,
  locationIdsSelected,
  hideSurchargeInvoices,
  onlyEndedReservations,
}: Props) {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const [updateCustomer, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCustomerArgs
  >(UPDATE_CUSTOMER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "customers.change_customer",
  ]);

  const onClickHandled = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    updateCustomer({
      variables: {
        customerId: customer.id,
        handledInvoicingAt: format(new Date(), DATE_FORMAT_ISO),
      },
    });

    setIsExpanded(false);
  };

  return (
    <Accordion
      expanded={isExpanded}
      onChange={(event, expanded) => setIsExpanded(expanded)}
    >
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
        <Typography className="flex-grow-1">
          {customer.name}
          {customer.handledInvoicingAt && (
            <small className="text-muted ms-3" title={t("invoice_set_handled")}>
              {format(newDate(customer.handledInvoicingAt), t("format_date"))}
            </small>
          )}
        </Typography>
        {isExpanded && hasPermissionEdit && (
          <ButtonLoad
            loading={loadingUpdate}
            variant="light"
            className="me-3"
            onClick={onClickHandled}
          >
            {t("invoice_set_handled")}
          </ButtonLoad>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {isExpanded && (
          <InvoiceableRowsCustomer
            customer={customer}
            dateTo={dateTo}
            locationIdsSelected={locationIdsSelected}
            hideSurchargeInvoices={hideSurchargeInvoices}
            onlyEndedReservations={onlyEndedReservations}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InvoiceCustomer);
