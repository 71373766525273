import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import Error from "../Shared/Error";
import {
  GET_LOCATIONS_QUERY,
  QueryResultLocations,
} from "../../apollo/queries/locations";
import LoadingSimple from "./LoadingSimple";
import { ID_EMPTY } from "../../utils/constants";
import { removeStringFromArr } from "../../utils/collections";
import { setLocalStorageLocationId } from "../../utils/locations/location";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  locationIdsSelected: string[];
  setLocationIdsSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

function LocationFilter({
  classes,
  locationIdsSelected,
  setLocationIdsSelected,
}: Props) {
  const { t } = useTranslation();

  const [locationIdsSelectedEdited, setLocationIdsSelectedEdited] = useState(
    locationIdsSelected.length === 0 ? [ID_EMPTY] : locationIdsSelected
  );

  const {
    loading: loadingLocations,
    error: errorLocations,
    data: dataLocations,
  } = useQuery<QueryResultLocations>(GET_LOCATIONS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("locations"),
  });

  if (loadingLocations) return <LoadingSimple />;
  if (errorLocations) return <Error error={errorLocations} />;
  if (!dataLocations) return <Error error={t("error_query_failed")} />;

  return (
    <FormControl fullWidth>
      <InputLabel id="lblLocationFilter">{t("location")}</InputLabel>
      <Select
        autoWidth
        labelId="lblLocationFilter"
        value={locationIdsSelectedEdited}
        multiple={true}
        onChange={(event) => {
          let locationIdsSelectedNew = event.target.value as string[];

          const hadOnlyAll =
            locationIdsSelectedEdited.length === 1 &&
            locationIdsSelectedEdited.includes(ID_EMPTY);
          const hasAll = locationIdsSelectedNew.includes(ID_EMPTY);

          if (hadOnlyAll && locationIdsSelectedNew.length > 1) {
            locationIdsSelectedNew = removeStringFromArr(
              locationIdsSelectedNew,
              ID_EMPTY
            );
          } else if (hasAll) {
            locationIdsSelectedNew = [ID_EMPTY];
          }

          setLocationIdsSelectedEdited(locationIdsSelectedNew);
        }}
        onClose={() => {
          const hasOnlyAll =
            locationIdsSelectedEdited.length === 1 &&
            locationIdsSelectedEdited.includes(ID_EMPTY);
          setLocationIdsSelected(hasOnlyAll ? [] : locationIdsSelectedEdited);

          if (locationIdsSelectedEdited.length === 1) {
            setLocalStorageLocationId(locationIdsSelectedEdited[0]);
          }
        }}
      >
        <MenuItem value={ID_EMPTY}>{t("all")}</MenuItem>
        {dataLocations.locations?.map((location) => (
          <MenuItem value={location.id} key={location.id}>
            {location.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(LocationFilter);
