import { gql } from "@apollo/client";

export const CREATE_TRANSPORT_MUTATION = gql`
  mutation createTransport(
    $orderId: ID!
    $catalogTransferMethodId: ID!
    $reservationIds: [ID]!
    $method: String!
    $address: String!
    $postcode: String!
    $district: String!
    $information: String!
    $dateDeadline: Date
    $timeDeadline: Time
    $dateEndUsage: Date
    $timeEndUsage: Time
  ) {
    createTransport(
      orderId: $orderId
      catalogTransferMethodId: $catalogTransferMethodId
      reservationIds: $reservationIds
      method: $method
      address: $address
      postcode: $postcode
      district: $district
      information: $information
      dateDeadline: $dateDeadline
      timeDeadline: $timeDeadline
      dateEndUsage: $dateEndUsage
      timeEndUsage: $timeEndUsage
    ) {
      transport {
        id
      }
    }
  }
`;

export const UPDATE_TRANSPORT_MUTATION = gql`
  mutation updateTransport(
    $transportId: ID!
    $orderId: ID!
    $catalogTransferMethodId: ID!
    $reservationIds: [ID]!
    $method: String!
    $address: String!
    $postcode: String!
    $district: String!
    $information: String!
    $dateDeadline: Date
    $timeDeadline: Time
    $dateEndUsage: Date
    $timeEndUsage: Time
  ) {
    updateTransport(
      transportId: $transportId
      orderId: $orderId
      catalogTransferMethodId: $catalogTransferMethodId
      reservationIds: $reservationIds
      method: $method
      address: $address
      postcode: $postcode
      district: $district
      information: $information
      dateDeadline: $dateDeadline
      timeDeadline: $timeDeadline
      dateEndUsage: $dateEndUsage
      timeEndUsage: $timeEndUsage
    ) {
      transport {
        id
        order {
          id
        }
        catalogTransferMethod {
          id
        }
        reservations {
          id
        }
        method
        address
        postcode
        district
        information
        dateDeadline
        timeDeadline
      }
    }
  }
`;

export const DELETE_TRANSPORT_MUTATION = gql`
  mutation deleteTransport($transportId: ID!) {
    deleteTransport(transportId: $transportId) {
      transportId
    }
  }
`;

export const CREATE_TRANSPORT_INVOICING_MUTATION = gql`
  mutation createTransportInvoicing(
    $orderId: ID!
    $referenceId: ID!
    $catalogTransferMethodId: ID!
    $method: String!
    $rowType: String!
    $title: String!
    $description: String!
    $unitPrice: Decimal!
    $quantity: Decimal!
    $discountPercent: Decimal!
  ) {
    createTransportInvoicing(
      orderId: $orderId
      referenceId: $referenceId
      catalogTransferMethodId: $catalogTransferMethodId
      method: $method
      rowType: $rowType
      title: $title
      description: $description
      unitPrice: $unitPrice
      quantity: $quantity
      discountPercent: $discountPercent
    ) {
      transportInvoicing {
        id
      }
    }
  }
`;

export const UPDATE_TRANSPORT_INVOICING_MUTATION = gql`
  mutation updateTransportInvoicing(
    $transportInvoicingId: ID!
    $orderId: ID!
    $referenceId: ID!
    $catalogTransferMethodId: ID!
    $method: String!
    $rowType: String!
    $title: String!
    $description: String!
    $unitPrice: Decimal!
    $quantity: Decimal!
    $discountPercent: Decimal!
  ) {
    updateTransportInvoicing(
      transportInvoicingId: $transportInvoicingId
      orderId: $orderId
      referenceId: $referenceId
      catalogTransferMethodId: $catalogTransferMethodId
      method: $method
      rowType: $rowType
      title: $title
      description: $description
      unitPrice: $unitPrice
      quantity: $quantity
      discountPercent: $discountPercent
    ) {
      transportInvoicing {
        id
        order {
          id
        }
        reference {
          id
        }
        catalogTransferMethod {
          id
        }
        method
        rowType
        title
        description
        unitPrice
        quantity
        discountPercent
      }
    }
  }
`;

export const DELETE_TRANSPORT_INVOICING_MUTATION = gql`
  mutation deleteTransportInvoicing($transportInvoicingId: ID!) {
    deleteTransportInvoicing(transportInvoicingId: $transportInvoicingId) {
      transportInvoicingId
    }
  }
`;

export const CREATE_TRANSPORT_INVOICING_SUMMARY_MUTATION = gql`
  mutation createTransportInvoicingSummary(
    $orderId: ID!
    $referenceId: ID!
    $percent: Decimal!
    $price: Decimal!
  ) {
    createTransportInvoicingSummary(
      orderId: $orderId
      referenceId: $referenceId
      percent: $percent
      price: $price
    ) {
      transportInvoicingSummary {
        id
      }
    }
  }
`;

export const UPDATE_TRANSPORT_INVOICING_SUMMARY_MUTATION = gql`
  mutation updateTransportInvoicingSummary(
    $transportInvoicingSummaryId: ID!
    $orderId: ID!
    $referenceId: ID!
    $percent: Decimal!
    $price: Decimal!
  ) {
    updateTransportInvoicingSummary(
      transportInvoicingSummaryId: $transportInvoicingSummaryId
      orderId: $orderId
      referenceId: $referenceId
      percent: $percent
      price: $price
    ) {
      transportInvoicingSummary {
        id
        order {
          id
        }
        reference {
          id
        }
        percent
        price
      }
    }
  }
`;

export const DELETE_TRANSPORT_INVOICING_SUMMARY_MUTATION = gql`
  mutation deleteTransportInvoicingSummary($transportInvoicingSummaryId: ID!) {
    deleteTransportInvoicingSummary(
      transportInvoicingSummaryId: $transportInvoicingSummaryId
    ) {
      transportInvoicingSummaryId
    }
  }
`;

export const CREATE_QUICK_TRANSPORT_MUTATION = gql`
  mutation createQuickTransport(
    $orderId: ID!
    $method: String!
    $catalogTransferMethodId: ID!
    $km: Int!
    $address: String
    $postcode: String
    $district: String
  ) {
    createQuickTransport(
      orderId: $orderId
      method: $method
      catalogTransferMethodId: $catalogTransferMethodId
      km: $km
      address: $address
      postcode: $postcode
      district: $district
    ) {
      order {
        id
        reservationSet {
          id
          deliveryMethod
          transportSet {
            id
          }
        }
        transportinvoicingSet {
          id
        }
      }
    }
  }
`;
