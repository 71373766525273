import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControlLabel,
  Menu,
  Radio,
  RadioGroup,
  Theme,
} from "@material-ui/core";
import {
  CatalogType,
  CloneDiscountList,
  CloneDiscountListFromCatalog,
  CustomerType,
  DiscountListType,
  MutationCloneDiscountListArgs,
  MutationCloneDiscountListFromCatalogArgs,
  MutationSelectCustomerCatalogArgs,
  MutationSelectCustomerDiscountListArgs,
  MutationSelectLinkedCustomerPricingArgs,
  SelectCustomerCatalog,
  SelectCustomerDiscountList,
  SelectLinkedCustomerPricing,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { ListGroup } from "react-bootstrap";
import ListGroupItemContentDiscountList from "../Shared/ListGroupItemContentDiscountList";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import {
  GET_CATALOGS_WITH_DISCOUNT_LISTS_QUERY,
  QueryResultCatalogsWithDiscountLists,
} from "../../apollo/queries/catalogs";
import format from "date-fns/format";
import MenuItem from "@material-ui/core/MenuItem";
import { handleError } from "../../entity/ErrorHandler";
import {
  SELECT_CUSTOMER_CATALOG_MUTATION,
  SELECT_CUSTOMER_DISCOUNT_LIST_MUTATION,
  SELECT_LINKED_CUSTOMER_PRICING,
} from "../../apollo/mutations/customers";
import { CatalogEmpty, DiscountListEmpty } from "../../entity/empties";
import { GET_CUSTOMER_QUERY } from "../../apollo/queries/customers";
import {
  CLONE_DISCOUNT_LIST_FROM_CATALOG_MUTATION,
  CLONE_DISCOUNT_LIST_MUTATION,
} from "../../apollo/mutations/discounts";
import CustomerPriceSource from "./CustomerPriceSource";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { newDate } from "../../utils/dates";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import FormControl from "@material-ui/core/FormControl";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { getCatalogIdActive } from "../../utils/catalogs/selected_catalog";
import {
  GET_DISCOUNT_LIST_QUERY,
  QueryResultDiscountList,
} from "../../apollo/queries/discounts";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerType>>;
}

const CustomerShowOfferSelect = ({ classes, customer, setCustomer }: Props) => {
  const { t } = useTranslation();
  const [anchorElMenuDiscountList, setAnchorElMenuDiscountList] =
    useState<null | HTMLElement>(null);
  const [anchorElMenuCatalog, setAnchorElMenuCatalog] =
    useState<null | HTMLElement>(null);
  const [discountList, setDiscountList] = useState(DiscountListEmpty);
  const [catalog, setCatalog] = useState(CatalogEmpty);
  const catalogIdActive = getCatalogIdActive();

  const {
    loading: dlLoading,
    error: dlError,
    data: dlData,
  } = useQuery<QueryResultDiscountList>(GET_DISCOUNT_LIST_QUERY, {
    variables: {
      discountListId:
        customer.discountListSelected && customer.discountListSelected.id
          ? customer.discountListSelected.id
          : customer.customerParent &&
            customer.customerParent.discountListSelected &&
            customer.customerParent.discountListSelected.id
          ? customer.customerParent.discountListSelected.id
          : ID_EMPTY,
    },
  });

  let getId = "";
  if (catalogIdActive !== ID_EMPTY) {
    getId = catalogIdActive;
  } else if (customer.catalogSelected && customer.catalogSelected.id) {
    getId = customer.catalogSelected.id;
  }

  const { loading, error, data } =
    useQuery<QueryResultCatalogsWithDiscountLists>(
      GET_CATALOGS_WITH_DISCOUNT_LISTS_QUERY,
      {
        fetchPolicy: getQueryFetchPolicy("catalogs"),
        variables: {
          catalogIds: getId,
        },
      }
    );

  const [selectCustomerDiscountList] = useMutation<
    SelectCustomerDiscountList,
    MutationSelectCustomerDiscountListArgs
  >(SELECT_CUSTOMER_DISCOUNT_LIST_MUTATION, {
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogs"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customer"),
      });
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [selectCustomerCatalog] = useMutation<
    SelectCustomerCatalog,
    MutationSelectCustomerCatalogArgs
  >(SELECT_CUSTOMER_CATALOG_MUTATION, {
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogs"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customer"),
      });
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [cloneDiscountList] = useMutation<
    CloneDiscountList,
    MutationCloneDiscountListArgs
  >(CLONE_DISCOUNT_LIST_MUTATION, {
    refetchQueries: [
      { query: GET_CUSTOMER_QUERY, variables: { id: customer.id } },
    ],
    onError: (error) => {
      handleError(error);
    },
  });

  const [cloneDiscountListFromCatalog] = useMutation<
    CloneDiscountListFromCatalog,
    MutationCloneDiscountListFromCatalogArgs
  >(CLONE_DISCOUNT_LIST_FROM_CATALOG_MUTATION, {
    refetchQueries: [
      { query: GET_CUSTOMER_QUERY, variables: { id: customer.id } },
    ],
    onError: (error) => {
      handleError(error);
    },
  });

  const [SelectLinkedCustomerPricing, { loading: loadingUpdate }] = useMutation<
    SelectLinkedCustomerPricing,
    MutationSelectLinkedCustomerPricingArgs
  >(SELECT_LINKED_CUSTOMER_PRICING, {
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogs"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customer"),
      });
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "customers.change_customer",
  ]);

  if (loading || dlLoading) return <Loading />;
  if (error) return <Error error={error} />;
  if (dlError) return <Error error={dlError} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const onClickDiscountList = (
    event: React.MouseEvent<Element>,
    discountList: DiscountListType
  ) => {
    if (!hasPermissionEdit) {
      return;
    }

    setAnchorElMenuDiscountList(event.target as HTMLElement);
    setDiscountList(discountList);
  };

  const onclickDiscountListSelect = () => {
    handleMenuClose();
    selectCustomerDiscountList({
      variables: { customerId: customer.id, discountListId: discountList.id },
    });
  };

  const onclickCatalogSelect = () => {
    handleMenuClose();
    selectCustomerCatalog({
      variables: { customerId: customer.id, catalogId: catalog.id },
    });
  };

  const onClickCatalog = (
    event: React.MouseEvent<Element>,
    catalog: CatalogType
  ) => {
    if (!hasPermissionEdit) {
      return;
    }

    setAnchorElMenuCatalog(event.target as HTMLElement);
    setCatalog(catalog);
  };

  const onclickDiscountListCopy = () => {
    handleMenuClose();
    cloneDiscountList({
      variables: {
        discountListId: discountList.id,
        customerId: customer.id,
      },
    });
  };

  const onclickCatalogCopy = () => {
    handleMenuClose();
    cloneDiscountListFromCatalog({
      variables: {
        catalogId: catalog.id,
        customerId: customer.id,
      },
    });
  };

  const handleMenuClose = () => {
    setAnchorElMenuDiscountList(null);
    setAnchorElMenuCatalog(null);
  };

  const handleRadioButtonChange = (values: {
    [key: string]: string | null | boolean | number | Date;
  }) => {
    if (customer.id !== ID_EMPTY) {
      const base: MutationSelectLinkedCustomerPricingArgs = {
        customerId: customer.id,
        useParentPricing: customer.useParentPricing,
      };
      SelectLinkedCustomerPricing({
        variables: { ...base, ...values },
      });
    }
  };

  const showError = () => {
    if (customer.catalogSelected && customer.catalogSelected.id) {
      if (customer.catalogSelected.id !== catalogIdActive) {
        return true;
      }
    } else if (
      customer.discountListSelected &&
      customer.discountListSelected.id
    ) {
      if (
        dlData &&
        dlData.discountList &&
        dlData.discountList.catalog.id !== catalogIdActive
      ) {
        return true;
      }
    } else if (customer.customerParent) {
      if (
        customer.customerParent.catalogSelected &&
        customer.customerParent.catalogSelected.id &&
        customer.customerParent.catalogSelected.id !== catalogIdActive
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className={loadingUpdate ? "loading" : ""}>
      <b>{t("customer_active_price_list")}</b>
      <CustomerPriceSource customer={customer} />
      {showError() ? (
        <div className="bg-warning mb-3 p-3">{t("error_outdated_catalog")}</div>
      ) : (
        ""
      )}
      {customer.customerParent ? (
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue="top"
          >
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label={t("choose_price_list")}
              checked={Boolean(customer.useParentPricing) === false}
              onChange={() => {
                const newCustomer = { ...customer, userParentPricing: false };
                setCustomer(newCustomer);
                handleRadioButtonChange({ useParentPricing: false });
              }}
            />
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={t("using_parent_pricing")}
              checked={Boolean(customer.useParentPricing) === true}
              onChange={() => {
                const newCustomer = { ...customer, userParentPricing: true };
                setCustomer(newCustomer);
                handleRadioButtonChange({ useParentPricing: true });
              }}
            />
          </RadioGroup>
        </FormControl>
      ) : (
        <h5 className={classes.h5}>{t("choose_price_list")}</h5>
      )}

      {(data.catalogs ? data.catalogs : []).map((catalog: CatalogType) => (
        <ListGroup className="mt-4" key={catalog.id}>
          <ListGroup.Item
            action={hasPermissionEdit}
            onClick={(event) => {
              onClickCatalog(event, catalog);
            }}
            variant={
              customer.catalogSelected &&
              customer.catalogSelected.id === catalog.id
                ? "secondary"
                : ""
            }
          >
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">{catalog.name}</h5>
              <small title={t("created_at")}>
                {format(newDate(catalog.createdAt), t("format_datetime"))}
              </small>
            </div>
            {catalog.createdBy && (
              <p>
                {catalog.createdBy.lastName} {catalog.createdBy.firstName}
              </p>
            )}
          </ListGroup.Item>
          {catalog.discountlistSet.map((discountList: DiscountListType) => {
            if (
              Boolean(customer.useParentPricing) === true &&
              (customer.discountListSelected === null ||
                (customer.discountListSelected &&
                  customer.discountListSelected.id !== discountList.id))
            ) {
              return null;
            }

            if (
              (discountList.isOffer &&
                discountList.customer?.id !== customer.id) ||
              customer.isBusiness !== discountList.isBusiness ||
              !discountList.locked
            ) {
              return null;
            }

            return (
              <ListGroup.Item
                key={discountList.id}
                action={hasPermissionEdit}
                onClick={(event) => {
                  onClickDiscountList(event, discountList);
                }}
                variant={
                  customer.discountListSelected &&
                  customer.discountListSelected.id === discountList.id
                    ? "secondary"
                    : ""
                }
              >
                <ListGroupItemContentDiscountList
                  discountList={discountList}
                  customer={customer}
                />
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ))}
      <Menu
        anchorEl={anchorElMenuDiscountList}
        keepMounted
        open={Boolean(anchorElMenuDiscountList)}
        onClose={handleMenuClose}
      >
        <CustomerPriceSource
          customer={customer}
          catalogId={ID_EMPTY}
          discountListId={discountList.id}
          menuItem={t("use_discount_list_show")}
        />
        {Boolean(customer.useParentPricing) === false ? (
          <MenuItem onClick={onclickDiscountListSelect}>
            {t("use_discount_list")}
          </MenuItem>
        ) : (
          ""
        )}
        {Boolean(customer.useParentPricing) === false ? (
          <MenuItem onClick={onclickDiscountListCopy}>
            {t("use_discount_list_copy")}
          </MenuItem>
        ) : (
          ""
        )}
      </Menu>
      <Menu
        anchorEl={anchorElMenuCatalog}
        keepMounted
        open={Boolean(anchorElMenuCatalog)}
        onClose={handleMenuClose}
      >
        <CustomerPriceSource
          customer={customer}
          catalogId={catalog.id}
          discountListId={ID_EMPTY}
          menuItem={t("use_catalog_show")}
        />
        {Boolean(customer.useParentPricing) === false ? (
          <MenuItem onClick={onclickCatalogSelect}>{t("use_catalog")}</MenuItem>
        ) : (
          ""
        )}
        {Boolean(customer.useParentPricing) === false ? (
          <MenuItem onClick={onclickCatalogCopy}>
            {t("use_catalog_copy")}
          </MenuItem>
        ) : (
          ""
        )}
      </Menu>
    </div>
  );
};

const styles = ({ palette }: Theme) =>
  createStyles({
    h5: {
      color: palette.primary.main,
    },
  });

export default withStyles(styles)(CustomerShowOfferSelect);
