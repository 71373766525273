import { gql } from "@apollo/client";
import { Mutation } from "../../entity/types";

export const CREATE_DRIVE_MUTATION = gql`
  mutation createDrive($transportReservation: [TransportReservationType]!) {
    createDrive(transportReservation: $transportReservation) {
      drive {
        id
      }
    }
  }
`;

export const UPDATE_DRIVE_MUTATION = gql`
  mutation updateDrive($driveId: ID!) {
    updateDrive(driveId: $driveId) {
      drive {
        id
      }
    }
  }
`;

export const DELETE_DRIVE_MUTATION = gql`
  mutation deleteDrive($driveId: ID!) {
    deleteDrive(driveId: $driveId) {
      driveId
    }
  }
`;

export const DELETE_DRIVE_CONTENT_MUTATION = gql`
  mutation deleteDriveContent($driveContentId: ID!) {
    deleteDriveContent(driveContentId: $driveContentId) {
      driveContentId
    }
  }
`;

export interface MutationResultDrivePdf {
  getDrivePdf: Mutation["getDrivePdf"];
}
export const GET_DRIVE_PDF_MUTATION = gql`
  mutation getDrivePdf($driveId: ID!) {
    getDrivePdf(driveId: $driveId) {
      url
    }
  }
`;
