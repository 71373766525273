import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PermissionsContext, SettingsContext } from "../../Root";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { useMutation } from "@apollo/client";
import { Mutation, MutationUpdateSettingsArgs } from "../../entity/types";
import { UPDATE_SETTINGS_MUTATION } from "../../apollo/mutations/settings";
import { handleError } from "../../entity/ErrorHandler";
import { checkPermission } from "../../utils/permissions";
import TextFieldFocus from "../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {}

function CommissionSettings({ classes }: Props) {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);

  const [settingsEdited, setSettingsEdited] = useState({
    ...settings,
    commissionOwner: formatNumber(settings.commissionOwner, 2),
    commissionOrderer: formatNumber(settings.commissionOrderer, 2),
    commissionRentThrough: formatNumber(settings.commissionRentThrough, 2),
  });

  const [updateSettings, { loading }] = useMutation<
    Mutation,
    MutationUpdateSettingsArgs
  >(UPDATE_SETTINGS_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "settings.change_settings",
  ]);

  const handleUpdateSettings = (values: { [key: string]: string | number }) => {
    const base: MutationUpdateSettingsArgs = {
      commissionOwner: parseNumber(settingsEdited.commissionOwner),
      commissionOrderer: parseNumber(settingsEdited.commissionOrderer),
      commissionRentThrough: parseNumber(settingsEdited.commissionRentThrough),
    };

    updateSettings({
      variables: { ...base, ...values },
    });
  };

  return (
    <div className={`mb-2 ${loading ? "loading" : ""}`}>
      <FormControl className={classes.txt}>
        <TextFieldFocus
          label={t("commission_owner")}
          value={settingsEdited.commissionOwner}
          disabled={!hasPermissionEdit}
          onChange={(event) => {
            setSettingsEdited({
              ...settingsEdited,
              commissionOwner: event.target.value,
            });
          }}
          onBlur={() => {
            const commissionOwner = parseNumber(settingsEdited.commissionOwner);
            setSettingsEdited({
              ...settingsEdited,
              commissionOwner: formatNumber(commissionOwner, 2),
            });
            handleUpdateSettings({
              commissionOwner: commissionOwner.toFixed(2),
            });
          }}
        />
      </FormControl>
      <FormControl className={classes.txt}>
        <TextFieldFocus
          label={t("commission_orderer")}
          value={settingsEdited.commissionOrderer}
          disabled={!hasPermissionEdit}
          onChange={(event) => {
            setSettingsEdited({
              ...settingsEdited,
              commissionOrderer: event.target.value,
            });
          }}
          onBlur={() => {
            const commissionOrderer = parseNumber(
              settingsEdited.commissionOrderer
            );
            setSettingsEdited({
              ...settingsEdited,
              commissionOrderer: formatNumber(commissionOrderer, 2),
            });
            handleUpdateSettings({
              commissionOrderer: commissionOrderer.toFixed(2),
            });
          }}
        />
      </FormControl>
      <FormControl className={classes.txt}>
        <TextFieldFocus
          label={t("commission_rent_through")}
          value={settingsEdited.commissionRentThrough}
          disabled={!hasPermissionEdit}
          onChange={(event) => {
            setSettingsEdited({
              ...settingsEdited,
              commissionRentThrough: event.target.value,
            });
          }}
          onBlur={() => {
            const commissionRentThrough = parseNumber(
              settingsEdited.commissionRentThrough
            );
            setSettingsEdited({
              ...settingsEdited,
              commissionRentThrough: formatNumber(commissionRentThrough, 2),
            });
            handleUpdateSettings({
              commissionRentThrough: commissionRentThrough.toFixed(2),
            });
          }}
        />
      </FormControl>
    </div>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    txt: {
      minWidth: "300px",
      marginBottom: spacing(3),
      marginRight: spacing(2),
    },
  });

export default withStyles(styles)(CommissionSettings);
