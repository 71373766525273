import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  CatalogExtraRowRentalType,
  CatalogRowType,
  CustomerType,
  OrderType,
  QueryCatalogRowArgs,
  QueryCustomerPriceForCatalogRowArgs,
  ReservationType,
} from "../../entity/types";
import {
  GET_CUSTOMER_PRICE_FOR_CATALOG_ROW_QUERY,
  QueryResultCustomerPriceForCatalogRow,
} from "../../apollo/queries/customers";
import { useQuery } from "@apollo/client";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { Table } from "react-bootstrap";
import { ID_EMPTY } from "../../utils/constants";
import { handleError } from "../../entity/ErrorHandler";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import {
  GET_CATALOG_ROW_QUERY,
  QueryResultCatalogRow,
} from "../../apollo/queries/catalogs";
import { CatalogRowEmpty } from "../../entity/empties";
import CalendarCustomerPriceRowDay from "./CalendarCustomerPriceRowDay";
import CalendarCustomerPriceRowMonth from "./CalendarCustomerPriceRowMonth";

interface Props extends WithStyles<typeof styles> {
  order: OrderType;
  customer: CustomerType;
  catalogRowId: string;
  catalogExtraRowRental: CatalogExtraRowRentalType | undefined;
  reservation?: ReservationType;
}

function CalendarCustomerPrices({
  classes,
  order,
  customer,
  catalogRowId,
  catalogExtraRowRental,
  reservation,
}: Props) {
  const { t } = useTranslation();

  const {
    loading: loadingCatalogRow,
    error: errorCatalogRow,
    data: dataCatalogRow,
  } = useQuery<QueryResultCatalogRow, QueryCatalogRowArgs>(
    GET_CATALOG_ROW_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("catalogRow"),
      variables: {
        catalogRowId: catalogRowId,
      },
      skip: catalogRowId === ID_EMPTY,
    }
  );

  const {
    loading: loadingPrice,
    error: errorPrice,
    data: dataPrice,
  } = useQuery<
    QueryResultCustomerPriceForCatalogRow,
    QueryCustomerPriceForCatalogRowArgs
  >(GET_CUSTOMER_PRICE_FOR_CATALOG_ROW_QUERY, {
    fetchPolicy: getQueryFetchPolicy("customerPriceForCatalogRow"),
    variables: {
      customerId: customer.id,
      catalogRowId: catalogRowId,
    },
    skip: Boolean(catalogExtraRowRental),
  });

  if (loadingCatalogRow || loadingPrice) return <LoadingSimple />;
  if (errorCatalogRow) return <Error error={errorCatalogRow} />;
  if (errorPrice) return <Error error={errorPrice} />;
  /* Don't check data here since it has a skip property */

  const catalogRow: CatalogRowType = dataCatalogRow?.catalogRow
    ? dataCatalogRow.catalogRow
    : CatalogRowEmpty;

  const isBusiness =
    customer.id !== ID_EMPTY
      ? customer.isBusiness
      : order.isBusinessIfNoCustomer;

  let priceDay = 0;
  let priceMonth = 0;
  let billingDaysWeek = 0;
  if (catalogExtraRowRental) {
    if (
      catalogExtraRowRental.priceDayCompany === undefined ||
      catalogExtraRowRental.priceDayPrivate === undefined
    ) {
      handleError(t("error_catalog_extra_row_no_price_day"));
    }
    if (
      catalogExtraRowRental.priceMonthCompany === undefined ||
      catalogExtraRowRental.priceMonthPrivate === undefined
    ) {
      handleError(t("error_catalog_extra_row_no_price_month"));
    }
    if (
      catalogExtraRowRental.billingDaysWeekCompany === undefined ||
      catalogExtraRowRental.billingDaysWeekPrivate === undefined
    ) {
      handleError(t("error_catalog_extra_row_no_billing_days_week"));
    }

    priceDay = isBusiness
      ? catalogExtraRowRental.priceDayCompany
      : catalogExtraRowRental.priceDayPrivate;
    priceMonth = isBusiness
      ? catalogExtraRowRental.priceMonthCompany
      : catalogExtraRowRental.priceMonthPrivate;
    billingDaysWeek = isBusiness
      ? catalogExtraRowRental.billingDaysWeekCompany
      : catalogExtraRowRental.billingDaysWeekPrivate;
  } else if (dataPrice && dataPrice.customerPriceForCatalogRow) {
    priceDay = dataPrice.customerPriceForCatalogRow.price_day;
    priceMonth = dataPrice.customerPriceForCatalogRow.price_month;
    billingDaysWeek = isBusiness
      ? catalogRow.billingDaysWeekCompany
      : catalogRow.billingDaysWeekPrivate;
  }

  const showDiscount = !!reservation;

  return (
    <Table className={classes.table} borderless size="sm">
      <tbody>
        <tr>
          <td colSpan={2}>{isBusiness && <small>{t("company")}</small>}</td>
          {showDiscount && (
            <td className="ps-4">
              <small>{t("total_after_discount")}</small>
            </td>
          )}
          <td colSpan={3}></td>
        </tr>
        <CalendarCustomerPriceRowDay
          priceDay={priceDay}
          reservation={reservation}
          billingDaysWeek={billingDaysWeek}
          isBusiness={isBusiness}
        />
        <CalendarCustomerPriceRowMonth
          priceMonth={priceMonth}
          reservation={reservation}
          catalogRow={catalogRow}
          catalogExtraRowRental={catalogExtraRowRental}
          isBusiness={isBusiness}
        />
      </tbody>
    </Table>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    table: {
      whiteSpace: "nowrap",
    },
  });

export default withStyles(styles)(CalendarCustomerPrices);
