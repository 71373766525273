import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { CustomerType } from "../../entity/types";
import MaterialTable from "material-table";
import {
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  tableIcons,
} from "../../utils/TableProps";
import { useTranslation } from "react-i18next";
import { faSitemap, faStar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { getUrlCustomerTab } from "../../utils/urls";
import { doesMatchAllTerms } from "../../utils/search";
import HeaderPage from "../Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {
  customers: CustomerType[];
}

const CustomerList = ({ classes, customers }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const data = customers.map((o) => ({ ...o }));

  data.sort((a, b) => {
    if (a.contactNext === b.contactNext) {
      return a.name.localeCompare(b.name);
    }
    if (a.contactNext === null) {
      return 1;
    }
    if (b.contactNext === null) {
      return 0;
    }
    return a.contactNext.localeCompare(b.contactNext);
  });

  return (
    <>
      <HeaderPage title={t("customers")} />
      <div className="containerInner">
        <MaterialTable
          style={getTableStyle()}
          title={""}
          icons={tableIcons}
          localization={GetTableLocalization(t)}
          columns={[
            {
              title: "",
              render: (customer: CustomerType) => {
                if (customer.customerParent)
                  return <FontAwesomeIcon icon={faSitemap} />;
                if (customer.customersChildren.length > 0)
                  return <FontAwesomeIcon icon={faStar} />;
                return "";
              },
              width: "1%",
              sorting: false,
            },
            {
              title: t("name"),
              field: "name",
              customFilterAndSearch: (term: string, customer: CustomerType) =>
                doesMatchAllTerms(term, customer.name),
            },
            {
              title: t("customer_number"),
              field: "customerNumber",
            },
            {
              title: t("customer_contact"),
              field: "contactPersonLastname",
              render: (customer: CustomerType) => {
                return `${customer.contactPersonLastname} ${customer.contactPersonFirstname}`;
              },
              customFilterAndSearch: (term: string, customer: CustomerType) => {
                const name =
                  customer.contactPersonLastname +
                  " " +
                  customer.contactPersonFirstname;
                return doesMatchAllTerms(term, name);
              },
            },
            {
              title: t("address_full"),
              field: "visitAddress",
              render: (customer: CustomerType) => {
                return (
                  <>
                    {customer.visitAddress}
                    <br />
                    {customer.visitPostcode} {customer.visitDistrict}
                  </>
                );
              },
              customFilterAndSearch: (term: string, customer: CustomerType) => {
                const address =
                  customer.visitAddress +
                  " " +
                  customer.visitPostcode +
                  " " +
                  customer.visitDistrict;
                return doesMatchAllTerms(term, address);
              },
            },
            { title: t("customer_owner"), field: "userOwner.firstName" },
            { title: t("contact_next"), field: "contactNext", type: "date" },
            {
              title: t("credit_information_ok"),
              field: "creditInformationOk",
              render: (customer: CustomerType) => {
                return customer.creditInformationOk ? t("yes") : t("no");
              },
              customFilterAndSearch: (term: string, customer: CustomerType) => {
                const answer = customer.creditInformationOk
                  ? t("yes")
                  : t("no");
                return answer.toLowerCase().indexOf(term.toLowerCase()) !== -1;
              },
            },
            {
              title: t("credit_information_checked_at"),
              field: "creditInformationCheckedAt",
              type: "date",
            },
            {
              title: t("credit_risk_class"),
              field: "creditRiskClass",
              render: (customer: CustomerType) => {
                return t("credit_risk_class_" + customer.creditRiskClass);
              },
              customFilterAndSearch: (term: string, customer: CustomerType) => {
                return (
                  t("credit_risk_class_" + customer.creditRiskClass)
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) !== -1
                );
              },
            },
          ]}
          data={data}
          options={getTableOptions({
            paging: true,
            pageSize: 25,
            pageSizeOptions: [25, 50, 100],
          })}
          onRowClick={(evt, selectedRow) => {
            if (selectedRow) {
              history.push(getUrlCustomerTab(selectedRow.id, "basic"));
            }
          }}
        />
      </div>
    </>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CustomerList);
