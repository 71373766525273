import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Checkbox, createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DiscountListType, DiscountRowType } from "../../../entity/types";
import {
  addStringToArr,
  removeStringFromArr,
} from "../../../utils/collections";
import { PermissionsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";

interface Props extends WithStyles<typeof styles> {
  discountList: DiscountListType;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  catalogRowsVisible: { [key: string]: boolean };
}

const DiscountThead = ({
  classes,
  discountList,
  selectedRows,
  setSelectedRows,
  catalogRowsVisible,
}: Props) => {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "discounts.change_discountrow",
  ]);

  const onChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    let rowIds: string[] = [];
    discountList.discountrowSet.forEach(function (
      discountRowLooped: DiscountRowType
    ) {
      if (
        discountRowLooped.catalogRow.catalogCategory.catalogCategoryUpper
          .catalog.id === discountList.catalog.id &&
        catalogRowsVisible[discountRowLooped.catalogRow.id]
      ) {
        rowIds.push(discountRowLooped.id);
      }
    });

    if (event.target.checked) {
      setSelectedRows(addStringToArr(selectedRows, rowIds));
    } else {
      setSelectedRows(removeStringFromArr(selectedRows, rowIds));
    }
  };

  let checkboxSelected = true;
  discountList.discountrowSet.forEach(function (discountRowLooped) {
    if (
      discountRowLooped.catalogRow.catalogCategory.catalogCategoryUpper.catalog
        .id === discountList.catalog.id &&
      selectedRows.indexOf(discountRowLooped.id) < 0
    ) {
      checkboxSelected = false;
      return;
    }
  });

  return (
    <thead>
      <tr className="noBorder">
        {hasPermissionEdit && !discountList.locked && (
          <th>
            <Checkbox
              checked={checkboxSelected}
              onChange={(event) => {
                onChangeSelect(event);
              }}
            />
          </th>
        )}
        <th></th>
        <th colSpan={4}>
          {t(
            discountList.isBusiness
              ? "price_list_companies"
              : "price_list_private"
          )}
        </th>
        <th className="cellPriceChange">
          {t("price_change")}
          <br />
          <small>{t("price_change_discount_explained")}</small>
        </th>
        <th colSpan={4}>
          {t(
            discountList.isBusiness
              ? "price_list_offer_companies"
              : "price_list_offer_private"
          )}
        </th>
        <th className="cellPriceChange">
          <span>{t("show_print")}</span>
        </th>
      </tr>
    </thead>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DiscountThead);
