import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  TextField,
  Theme,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { QueryCustomersOpenReservationsArgs } from "../../entity/types";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import InvoiceListFilter from "./InvoiceListFilter";
import { resetDateTime } from "../../utils/dates";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import {
  GET_CUSTOMERS_OPEN_RESERVATIONS_QUERY,
  QueryResultCustomersOpenReservations,
} from "../../apollo/queries/customers";
import { getLocationSelected } from "../../utils/locations/location";
import { UserContext } from "../../Root";
import InvoiceCustomer from "./InvoiceCustomer";

interface Props extends WithStyles<typeof styles> {
  isAdvance?: Boolean;
}

function InvoicesCreateListCustomers({ classes, isAdvance }: Props) {
  const { t } = useTranslation();

  const user = useContext(UserContext);

  const locationIdDefault = getLocationSelected(user);
  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([
    locationIdDefault,
  ]);
  const [dateTo, setDateTo] = useState(resetDateTime(new Date()));
  const [hideSurchargeInvoices, setHideSurchargeInvoices] = useState(false);
  const [onlyEndedReservations, setOnlyEndedReservations] = useState(false);
  const [dateHandled, setDateHandled] = useState<string>("");

  const { loading, error, data } = useQuery<
    QueryResultCustomersOpenReservations,
    QueryCustomersOpenReservationsArgs
  >(GET_CUSTOMERS_OPEN_RESERVATIONS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("customersOpenReservations"),
    variables: {
      locationIds: locationIdsSelected,
      onlyEndedReservations: onlyEndedReservations,
      dateHandled: dateHandled ? dateHandled : undefined,
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <div>
      {!isAdvance && (
        <>
          <InvoiceListFilter
            dateTo={dateTo}
            setDateTo={setDateTo}
            locationIdsSelected={locationIdsSelected}
            setLocationIdsSelected={setLocationIdsSelected}
            onlyEndedReservations={onlyEndedReservations}
            setOnlyEndedReservations={setOnlyEndedReservations}
          />
          <Row className="mb-4">
            <Col sm={6} md={6} lg={4} xl={3} className="mb-2">
              <FormControl fullWidth>
                <FormControlLabel
                  label={t("invoiceable_filter_surcharge")}
                  control={
                    <Checkbox
                      checked={hideSurchargeInvoices}
                      onChange={(event) => {
                        setHideSurchargeInvoices(event.target.checked);
                      }}
                    />
                  }
                />
              </FormControl>
            </Col>
            <Col sm={3} md={3} lg={2} xl={2} className="mb-2">
              <FormControl fullWidth>
                <TextField
                  label={t("date_handled_filter")}
                  type="date"
                  value={dateHandled}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => {
                    setDateHandled(event.target.value);
                  }}
                />
              </FormControl>
            </Col>
          </Row>
        </>
      )}
      <div>
        {data.customersOpenReservations?.map((customer) => (
          <InvoiceCustomer
            key={customer.id}
            customer={customer}
            dateTo={dateTo}
            locationIdsSelected={locationIdsSelected}
            hideSurchargeInvoices={hideSurchargeInvoices}
            onlyEndedReservations={onlyEndedReservations}
          />
        ))}
      </div>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InvoicesCreateListCustomers);
