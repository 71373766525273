import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { Col, Row, Form } from "react-bootstrap";
import { GroupType, IdentificationRequestType, LocationType, UserType } from "../../entity/types";
import { PermissionsContext, UserContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import { SET_IS_LOGGED_IN_MUTATION } from "../../apollo/client/is_logged_in";
import { handleError } from "../../entity/ErrorHandler";
import i18n from "i18next";
import { findFromSetById } from "../../utils/collections";
import { ID_EMPTY } from "../../utils/constants";
import SelectRequired from "../Shared/SelectRequired";
import { onLogout } from "../../utils/login";

interface Props extends WithStyles<typeof styles> {
  identificationRequest: IdentificationRequestType;
  setIdentificationRequest: Function;
  emptyPasswordOnBlur?: boolean;
}


function DialogContentSimpleIdentificationRequest({
  classes,
  identificationRequest,
  setIdentificationRequest,
  emptyPasswordOnBlur = false,
}: Props) {
  const { t } = useTranslation();
  const [id, setUsername] = useState(identificationRequest.id);
  const currentUser = useContext(UserContext);
  const [setIsLoggedIn] = useMutation(SET_IS_LOGGED_IN_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });
  let isMe = identificationRequest.id !== ID_EMPTY && identificationRequest.id === currentUser.id;

  const handleUsernameChange = () => {
    /* User must login again if username is changed */
    if (isMe) {
      onLogout();
      setIsLoggedIn({ variables: { isLoggedIn: false } });
      window.location.reload();
    }
  };

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddUser = checkPermission(myPermissions, [
    "auth.add_user",
  ]);
  const hasPermissionEditUser = checkPermission(myPermissions, [
    "auth.change_user",
  ]);
  const disabled = isMe
    ? false
    : identificationRequest.id
    ? !hasPermissionEditUser
    : !hasPermissionAddUser;
  const showProfileSelect = identificationRequest.id
    ? hasPermissionEditUser
    : hasPermissionAddUser;

  const handleChangeName = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (identificationRequest.id === ID_EMPTY) {
      setIdentificationRequest({
        ...identificationRequest
      });
    }
  };

  let languages = [];
  if (i18n.options.resources) {
    for (let key of Object.keys(i18n.options.resources)) {
      languages.push({ key: key.toUpperCase(), name: t("language_" + key) });
    }
  }

  return (
    <DialogContent>
    
    </DialogContent>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  });

export default withStyles(styles)(DialogContentSimpleIdentificationRequest);
