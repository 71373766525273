import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  GroupType,
  LocationType,
  Mutation,
  MutationUpdateUserArgs,
  UserType,
} from "../../entity/types";
import { Container } from "react-bootstrap";
import CardHeader from "@material-ui/core/CardHeader";
import AvatarUser from "../Shared/AvatarUser";
import format from "date-fns/format";
import DialogContentUser from "../User/DialogContentUser";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_MUTATION } from "../../apollo/mutations/users";
import { handleError, handleMessage } from "../../entity/ErrorHandler";
import { ME_QUERY } from "../../apollo/queries/login";
import { CONTAINER_MAIN_WIDTH_S, ID_EMPTY } from "../../utils/constants";
import { newDate } from "../../utils/dates";

interface Props extends WithStyles<typeof styles> {
  userSelected: UserType;
  groups: GroupType[];
  locations: LocationType[];
}

function ProfileView({ classes, userSelected, groups, locations }: Props) {
  const { t } = useTranslation();
  const [user, setUserState] = useState<UserType>(userSelected);

  const [updateUser] = useMutation<Mutation, MutationUpdateUserArgs>(
    UPDATE_USER_MUTATION,
    {
      refetchQueries: [{ query: ME_QUERY }],
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const setUser = (userBeingSet: UserType) => {
    setUserState(userBeingSet);
    //var groupId = userBeingSet.groups[0] ? userBeingSet.groups[0].id : ID_EMPTY;
    updateUser({
      variables: {
        userId: userBeingSet.id,
        firstName: userBeingSet.firstName,
        lastName: userBeingSet.lastName,
        email: userBeingSet.email,
        password: userBeingSet.password ? userBeingSet.password : "",
        language: userBeingSet.language.toLowerCase()
      },
    }).then((result) => {
      if (result.data?.updateUser?.user?.id && userBeingSet.password) {
        handleMessage(t("success_password_changed"));
      }
    });
  };

  return (
    <Container fluid className={`containerMain ${classes.container}`}>
      <h2>
        <CardHeader
          avatar={<AvatarUser user={user} />}
          title={`${user.firstName} ${user.lastName}`}
          subheader={`${t("joined")} ${format(
            newDate(user.dateJoined),
            t("format_date")
          )}`}
        />
      </h2>

      <div className="containerInner">
        <DialogContentUser
          user={user}
          setUser={setUser}
          groups={groups}
          locations={locations}
          emptyPasswordOnBlur={true}
        />
      </div>
    </Container>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: CONTAINER_MAIN_WIDTH_S,
    },
  });

export default withStyles(styles)(ProfileView);
