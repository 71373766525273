import {
  CommissionReportRowType,
  CommissionRowRentThroughType,
  CommissionRowType,
  ReservationType,
} from "../../entity/types";
import { TFunction } from "react-i18next";
import { format } from "date-fns";
import { newDate } from "../dates";
import React from "react";
import { doesMatchAllTerms } from "../search";

export function commissionsRenderDateRange(
  t: TFunction<"translation">,
  commissionRow:
    | CommissionRowType
    | CommissionRowRentThroughType
    | CommissionReportRowType
) {
  return (
    <div className="text-nowrap">
      {t("date_range", {
        start: format(newDate(commissionRow.dateFrom), t("format_date_short")),
        end: format(newDate(commissionRow.dateTo), t("format_date_short")),
      })}
    </div>
  );
}

export function commissionsFilterDateRange(
  t: TFunction<"translation">,
  term: string,
  commissionRow:
    | CommissionRowType
    | CommissionRowRentThroughType
    | CommissionReportRowType
) {
  return doesMatchAllTerms(
    term,
    t("date_range", {
      start: format(newDate(commissionRow.dateFrom), t("format_date_short")),
      end: format(newDate(commissionRow.dateTo), t("format_date_short")),
    })
  );
}

export function commissionsRenderReservation(
  t: TFunction<"translation">,
  reservation: ReservationType
) {
  return (
    <>
      <div>{"#" + reservation.id}</div>
      <div className="text-nowrap">
        {t("date_range", {
          start: format(
            newDate(reservation.dateRented),
            t("format_date_short")
          ),
          end: format(
            newDate(reservation.dateReturned),
            t("format_date_short")
          ),
        })}
      </div>
      {reservation.dateBillingMin && (
        <div title={t("date_billing_min")}>
          {format(newDate(reservation.dateBillingMin), t("format_date_short"))}
        </div>
      )}
    </>
  );
}

export function commissionsFilterReservation(
  t: TFunction<"translation">,
  term: string,
  reservation: ReservationType
) {
  return doesMatchAllTerms(
    term,
    "#" +
      reservation.id +
      " " +
      t("date_range", {
        start: format(newDate(reservation.dateRented), t("format_date_short")),
        end: format(newDate(reservation.dateReturned), t("format_date_short")),
      }) +
      (reservation.dateBillingMin
        ? " " +
          format(newDate(reservation.dateBillingMin), t("format_date_short"))
        : "")
  );
}
