import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CustomerType,
  EventType,
  Mutation,
  MutationCreateEventArgs,
} from "../../entity/types";
import { Button } from "react-bootstrap";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { EventEmpty } from "../../entity/empties";
import { CREATE_EVENT_MUTATION } from "../../apollo/mutations/events";
import DialogContentEvent from "../Event/DialogContentEvent";
import { GET_EVENTS_BY_CUSTOMER_QUERY } from "../../apollo/queries/events";
import { format } from "date-fns";
import { DATE_FORMAT_ISO } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  customer: CustomerType;
}

function DialogCustomerEvent({ classes, open, setOpen, customer }: Props) {
  const { t } = useTranslation();
  const [event, setEvent] = useState<EventType>({
    ...EventEmpty,
    date: format(new Date(), DATE_FORMAT_ISO),
  });

  const [createEvent] = useMutation<Mutation, MutationCreateEventArgs>(
    CREATE_EVENT_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_EVENTS_BY_CUSTOMER_QUERY,
          variables: { customerId: customer.id },
        },
      ],
      onCompleted: (result) => {
        setEvent(EventEmpty);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createEvent({
      variables: {
        eventType: event.eventType,
        date: event.date,
        information: event.information,
        customerId: customer.id,
      },
    });
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <DialogTitle>{t("new_event")}</DialogTitle>
        <DialogContentEvent event={event} setEvent={setEvent} />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <Button disabled={!event.date.trim()} type="submit" variant="primary">
            {t("save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCustomerEvent);
