import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { createStyles, Menu, MenuItem, Theme } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import DialogCatalogExtraCategoryUnitPrice from "./DialogCatalogExtraCategoryUnitPrice";
import DialogCatalogExtraRowUnitPrice from "./DialogCatalogExtraRowUnitPrice";
import {
  CatalogExtraCategoryUnitPriceEmpty,
  CatalogExtraRowUnitPriceEmpty,
} from "../../entity/empties";
import { CatalogExtraCategoryUnitPriceType } from "../../entity/types";

interface Props extends WithStyles<typeof styles> {
  catalogExtraCategoriesUnitPrice: CatalogExtraCategoryUnitPriceType[];
}

function CreateCatalogExtraUnitPriceMenu({
  classes,
  catalogExtraCategoriesUnitPrice,
}: Props) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialogCatalogExtraCategory, setOpenDialogCatalogExtraCategory] =
    useState(false);
  const [openDialogCatalogExtraRow, setOpenDialogCatalogExtraRow] =
    useState(false);
  const [catalogExtraRowUnitPrice, setCatalogExtraRowUnitPrice] = useState(
    CatalogExtraRowUnitPriceEmpty
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddCatalogCategory = checkPermission(myPermissions, [
    "catalogs_extra.add_catalogextracategoryunitprice",
  ]);
  const hasPermissionAddCatalogRow = checkPermission(myPermissions, [
    "catalogs_extra.add_catalogextrarowunitprice",
  ]);

  if (!hasPermissionAddCatalogCategory && !hasPermissionAddCatalogRow) {
    return <></>;
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className="addButton"
        variant="primary"
        size="lg"
        aria-controls="menuCatalogExtra"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {Boolean(anchorEl) ? <ClearIcon /> : <AddIcon />}
      </Button>
      <Menu
        id="menuCatalogExtra"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {hasPermissionAddCatalogCategory && (
          <MenuItem
            onClick={() => {
              setOpenDialogCatalogExtraCategory(true);
              handleCloseMenu();
            }}
          >
            {t("new_catalog_extra_category")}
          </MenuItem>
        )}
        {hasPermissionAddCatalogRow && (
          <MenuItem
            onClick={() => {
              setOpenDialogCatalogExtraRow(true);
              handleCloseMenu();
            }}
          >
            {t("new_catalog_extra_row")}
          </MenuItem>
        )}
      </Menu>
      <DialogCatalogExtraCategoryUnitPrice
        open={openDialogCatalogExtraCategory}
        setOpen={setOpenDialogCatalogExtraCategory}
        catalogExtraCategoryUnitPriceOrig={CatalogExtraCategoryUnitPriceEmpty}
      />
      <DialogCatalogExtraRowUnitPrice
        open={openDialogCatalogExtraRow}
        setOpen={setOpenDialogCatalogExtraRow}
        catalogExtraRowUnitPrice={catalogExtraRowUnitPrice}
        setCatalogExtraRowUnitPrice={setCatalogExtraRowUnitPrice}
        catalogExtraCategoriesUnitPrice={catalogExtraCategoriesUnitPrice}
      />
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CreateCatalogExtraUnitPriceMenu);
