import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultHolidays {
  holidays: Query["holidays"];
}
export const GET_HOLIDAYS_QUERY = gql`
  query getHolidaysQuery {
    holidays {
      id
      holidayType
      name
      date
      createdAt
    }
  }
`;
