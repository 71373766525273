import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  Mutation,
  MutationCreateMachineMakeArgs,
  MutationCreateMachineModelArgs,
} from "../../entity/types";
import { Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { useMutation, useQuery } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import {
  CREATE_MACHINE_MAKE_MUTATION,
  CREATE_MACHINE_MODEL_MUTATION,
} from "../../apollo/mutations/machines";
import {
  GET_MAKES_AND_MODELS_QUERY,
  QueryResultMakesAndModels,
} from "../../apollo/queries/machines";
import LoadingDialog from "../Shared/LoadingDialog";
import Error from "../Shared/Error";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY } from "../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/pro-light-svg-icons";
import MachineModelFlex from "./MachineModelFlex";
import MachineMakeFlex from "./MachineMakeFlex";
import { findFromSetById } from "../../utils/collections";
import { MachineMakeEmpty } from "../../entity/empties";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  machineMakeIdDefault?: string;
}

function DialogMachineMakeAndModel({
  classes,
  open,
  setOpen,
  machineMakeIdDefault = ID_EMPTY,
}: Props) {
  const { t } = useTranslation();

  const [machineMakeIdSelected, setMachineMakeIdSelected] =
    useState(machineMakeIdDefault);
  const [makeTitleNew, setMakeTitleNew] = useState("");
  const [modelTitleNew, setModelTitleNew] = useState("");

  const { loading, error, data } = useQuery<QueryResultMakesAndModels>(
    GET_MAKES_AND_MODELS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("machineMakes"),
    }
  );

  const [createMachineMake, { loading: loadingCreateMake }] = useMutation<
    Mutation,
    MutationCreateMachineMakeArgs
  >(CREATE_MACHINE_MAKE_MUTATION, {
    refetchQueries: [{ query: GET_MAKES_AND_MODELS_QUERY }],
    onError: (error) => {
      handleError(error);
    },
    onCompleted: (data) => {
      setMakeTitleNew("");
      if (data.createMachineMake?.machineMake) {
        setMachineMakeIdSelected(data.createMachineMake.machineMake.id);
      }
    },
  });

  const [createMachineModel, { loading: loadingCreateModel }] = useMutation<
    Mutation,
    MutationCreateMachineModelArgs
  >(CREATE_MACHINE_MODEL_MUTATION, {
    refetchQueries: [{ query: GET_MAKES_AND_MODELS_QUERY }],
    onError: (error) => {
      handleError(error);
    },
    onCompleted: () => {
      setModelTitleNew("");
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddMake = checkPermission(myPermissions, [
    "machines.add_machinemake",
  ]);
  const hasPermissionAddModel = checkPermission(myPermissions, [
    "machines.add_machinemodel",
  ]);

  if (loading || loadingCreateMake || loadingCreateModel)
    return <LoadingDialog />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setOpen(false);
  };

  const handleCreateMake = () => {
    createMachineMake({
      variables: {
        title: makeTitleNew,
      },
    });
  };

  const handleCreateModel = () => {
    createMachineModel({
      variables: {
        machineMakeId: machineMakeIdSelected,
        title: modelTitleNew,
      },
    });
  };

  return (
    <Dialog open={open}>
      <form onSubmit={(event) => handleSubmit(event)}>
        <DialogTitleWithClose
          id="dialogTitleMachineMakeAndModel"
          onClose={() => setOpen(false)}
        >
          {t("new_machine_make_model")}
        </DialogTitleWithClose>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="lblMachineMake">{t("machine_make")}</InputLabel>
            <Select
              autoWidth
              labelId="lblMachineMake"
              value={machineMakeIdSelected}
              onChange={(event) => {
                setMachineMakeIdSelected(
                  event.target.value ? String(event.target.value) : ID_EMPTY
                );
              }}
            >
              <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
              {data.machineMakes?.map((machineMake) => (
                <MenuItem key={machineMake.id} value={machineMake.id}>
                  {machineMake.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {machineMakeIdSelected !== ID_EMPTY && (
            <MachineMakeFlex
              machineMake={findFromSetById(
                machineMakeIdSelected,
                data.machineMakes ? data.machineMakes : [],
                MachineMakeEmpty
              )}
            />
          )}
          {hasPermissionAddMake && (
            <FormControl fullWidth>
              <TextField
                label={t("new_machine_make")}
                value={makeTitleNew}
                inputProps={{
                  maxLength: 100,
                }}
                InputProps={{
                  endAdornment: (
                    <Button variant="link" onClick={handleCreateMake}>
                      <FontAwesomeIcon icon={faCaretRight} />
                    </Button>
                  ),
                }}
                onChange={(event) => {
                  setMakeTitleNew(event.target.value);
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    handleCreateMake();
                  }
                }}
              />
            </FormControl>
          )}
          <br />
          <br />
          {machineMakeIdSelected !== ID_EMPTY && (
            <div>
              <p className="text-muted">{t("machine_models")}</p>
              {data.machineMakes?.map((machineMake) => {
                if (machineMake.id === machineMakeIdSelected) {
                  return machineMake.machinemodelSet.map((machineModel) => {
                    return (
                      <MachineModelFlex
                        key={machineModel.id}
                        machineModel={machineModel}
                      />
                    );
                  });
                } else {
                  return undefined;
                }
              })}
              {hasPermissionAddModel && (
                <FormControl fullWidth>
                  <TextField
                    label={t("new_machine_model")}
                    value={modelTitleNew}
                    inputProps={{
                      maxLength: 100,
                    }}
                    InputProps={{
                      endAdornment: (
                        <Button variant="link" onClick={handleCreateModel}>
                          <FontAwesomeIcon icon={faCaretRight} />
                        </Button>
                      ),
                    }}
                    onChange={(event) => {
                      setModelTitleNew(event.target.value);
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        handleCreateModel();
                      }
                    }}
                  />
                </FormControl>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("close")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogMachineMakeAndModel);
