import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import {
  CatalogExtraCategoryUnitPriceType,
  Mutation,
  MutationCreateCatalogExtraCategoryUnitPriceArgs,
  MutationUpdateCatalogExtraCategoryUnitPriceArgs,
} from "../../entity/types";
import DialogContentCatalogExtraCategoryUnitPrice from "./DialogContentCatalogExtraCategoryUnitPrice";
import { useMutation } from "@apollo/client";
import { CatalogExtraCategoryUnitPriceEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import {
  CREATE_CATALOG_EXTRA_CATEGORY_UNIT_PRICE_MUTATION,
  UPDATE_CATALOG_EXTRA_CATEGORY_UNIT_PRICE_MUTATION,
} from "../../apollo/mutations/catalogs_extra";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalogExtraCategoryUnitPriceOrig: CatalogExtraCategoryUnitPriceType;
}

function DialogCatalogExtraCategoryUnitPrice({
  classes,
  open,
  setOpen,
  catalogExtraCategoryUnitPriceOrig,
}: Props) {
  const { t } = useTranslation();
  const [catalogExtraCategoryUnitPrice, setCatalogExtraCategoryUnitPrice] =
    useState(catalogExtraCategoryUnitPriceOrig);

  const [createCatalogExtraCategoryUnitPrice] = useMutation<
    Mutation,
    MutationCreateCatalogExtraCategoryUnitPriceArgs
  >(CREATE_CATALOG_EXTRA_CATEGORY_UNIT_PRICE_MUTATION, {
    onCompleted: (result) => {
      setCatalogExtraCategoryUnitPrice(CatalogExtraCategoryUnitPriceEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogExtraCategoriesUnitPrice"),
      });
    },
  });
  const [updateCatalogExtraCategoryUnitPrice] = useMutation<
    Mutation,
    MutationUpdateCatalogExtraCategoryUnitPriceArgs
  >(UPDATE_CATALOG_EXTRA_CATEGORY_UNIT_PRICE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSubmitCatalogExtra = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (catalogExtraCategoryUnitPrice.id !== ID_EMPTY) {
      updateCatalogExtraCategoryUnitPrice({
        variables: {
          catalogExtraCategoryUnitPriceId: catalogExtraCategoryUnitPrice.id,
          name: catalogExtraCategoryUnitPrice.name,
          printSeparately: catalogExtraCategoryUnitPrice.printSeparately,
        },
      });
    } else {
      createCatalogExtraCategoryUnitPrice({
        variables: {
          name: catalogExtraCategoryUnitPrice.name,
          printSeparately: catalogExtraCategoryUnitPrice.printSeparately,
        },
      });
    }
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <form onSubmit={(event) => handleSubmitCatalogExtra(event)}>
        <DialogTitle>
          {t(
            catalogExtraCategoryUnitPrice.id
              ? "edit_catalog_extra_category"
              : "new_catalog_extra_category"
          )}
        </DialogTitle>
        <DialogContentCatalogExtraCategoryUnitPrice
          catalogExtraCategoryUnitPrice={catalogExtraCategoryUnitPrice}
          setCatalogExtraCategoryUnitPrice={setCatalogExtraCategoryUnitPrice}
        />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <Button
            disabled={!catalogExtraCategoryUnitPrice.name.trim()}
            type="submit"
            variant="primary"
          >
            {t("save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCatalogExtraCategoryUnitPrice);
