import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Button, createStyles, Theme } from "@material-ui/core";
import { CustomerType, ReservationType } from "../../entity/types";
import MaterialTable from "material-table";
import {
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  tableIcons,
} from "../../utils/TableProps";
import { useTranslation } from "react-i18next";
import ChartCustomerReservations from "./ChartCustomerReservations";
import { getReservationProductName } from "../../utils/reservations/reservation";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DialogReservationInfo from "../Reservation/DialogReservationInfo";
import { ReservationEmpty } from "../../entity/empties";
import DialogOrderId from "./DialogOrderId";
import { ID_EMPTY } from "../../utils/constants";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";

type ReservationRowType = {
  reservation: ReservationType;
  id: string;
  orderId: string;
  orderStatus: string;
  orderCreatedAt: string;
  reference: string;
  catalogRow: string;
  machine: string;
  dateRented: string;
  dateReturned: string;
  createdAt: string;
};

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
}

const CustomerShowReservations = ({ classes, customer }: Props) => {
  const { t } = useTranslation();

  const [reservationInfoOpen, setReservationInfoOpen] = useState(false);
  const [openDialogOrder, setOpenDialogOrder] = useState(false);
  const [orderIdSelected, setOrderIdSelected] = useState(ID_EMPTY);
  const [reservationIdSelected, setReservationIdSelected] = useState(ID_EMPTY);
  const [reservationSelected, setReservationSelected] =
    useState(ReservationEmpty);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionViewOrder = checkPermission(myPermissions, [
    "orders.view_order",
  ]);

  const data: ReservationRowType[] = [];
  customer.orderSet.forEach((order) => {
    order.reservationSet.forEach((reservation) => {
      data.push({
        reservation: reservation,
        id: reservation.id,
        orderId: order.id,
        orderStatus: t("order_status_" + order.status),
        orderCreatedAt: order.createdAt,
        reference: order.reference ? order.reference.name : "",
        catalogRow: reservation.catalogRow ? reservation.catalogRow.name : "",
        machine: getReservationProductName(t, reservation),
        dateRented: reservation.giveAt
          ? reservation.giveAt
          : reservation.dateRented,
        dateReturned: reservation.returnAt
          ? reservation.returnAt
          : reservation.dateReturned,
        createdAt: reservation.createdAt,
      });
    });
  });

  data.sort((a, b) => b.createdAt.localeCompare(a.createdAt));

  const onClickOrder = (orderId: string) => {
    setOrderIdSelected(orderId);
    setReservationIdSelected(ID_EMPTY);
    setOpenDialogOrder(true);
  };

  const onClickReservation = (orderId: string, reservationId: string) => {
    setOrderIdSelected(orderId);
    setReservationIdSelected(reservationId);
    setOpenDialogOrder(true);
  };

  return (
    <div>
      <ChartCustomerReservations customer={customer} />
      <MaterialTable
        style={getTableStyle()}
        title={""}
        icons={tableIcons}
        localization={GetTableLocalization(t)}
        columns={[
          {
            title: t("order_id_header"),
            field: "orderId",
            render: (row) => (
              <Button
                onClick={() => onClickOrder(row.orderId)}
                variant="outlined"
                disabled={!hasPermissionViewOrder}
              >
                #{row.orderId}
              </Button>
            ),
          },
          {
            title: t("reservation_id_header"),
            field: "id",
            render: (row) => (
              <Button
                onClick={() => onClickReservation(row.orderId, row.id)}
                variant="outlined"
                disabled={!hasPermissionViewOrder}
              >
                #{row.id}
              </Button>
            ),
          },
          { title: t("reference"), field: "reference" },
          { title: t("order_status"), field: "orderStatus" },
          { title: t("catalog_row"), field: "catalogRow" },
          { title: t("machine"), field: "machine" },
          { title: t("date_rented"), field: "dateRented", type: "date" },
          { title: t("date_returned"), field: "dateReturned", type: "date" },
          { title: t("created_at"), field: "orderCreatedAt", type: "datetime" },
        ]}
        data={data}
        options={getTableOptions()}
        components={{
          Action: (props) => (
            <Button
              className="btnMaterialTableMenu"
              aria-controls="menuUser"
              aria-haspopup="true"
              onClick={() => {
                setReservationSelected(props.data.reservation);
                setReservationInfoOpen(true);
              }}
            >
              <FontAwesomeIcon size="lg" icon={faEllipsisV} />
            </Button>
          ),
        }}
        actions={[
          {
            icon: "menu",
            onClick: () => {},
          },
        ]}
      />
      {reservationInfoOpen && (
        <DialogReservationInfo
          open={reservationInfoOpen}
          setOpen={setReservationInfoOpen}
          reservation={reservationSelected}
        />
      )}
      {openDialogOrder && (
        <DialogOrderId
          open={openDialogOrder}
          setOpen={setOpenDialogOrder}
          orderId={orderIdSelected}
          reservationIdSelected={reservationIdSelected}
        />
      )}
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    reservationFav: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  });

export default withStyles(styles)(CustomerShowReservations);
