import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import format from "date-fns/format";
import { DATE_FORMAT_ISO } from "../../utils/constants";
import { parse } from "date-fns";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  fetchData: Function;
  loadingData: boolean;
  dateFromDefault: Date;
  dateToDefault: Date;
}

const ReportDateFilter = ({
  classes,
  fetchData,
  loadingData,
  dateFromDefault,
  dateToDefault,
}: Props) => {
  const { t } = useTranslation();
  const [dateFrom, setDateFrom] = useState(dateFromDefault);
  const [dateTo, setDateTo] = useState(dateToDefault);

  const handleButtonClick = () => {
    fetchData(dateFrom, dateTo);
  };

  return (
    <Row className="mb-2">
      <Col sm={6} md={4} lg={2} xl={2} className="mb-2">
        <div>
          <FormControl fullWidth>
            <TextField
              type="date"
              label={t("date_from")}
              value={format(dateFrom, DATE_FORMAT_ISO)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                let date = parse(
                  event.target.value,
                  DATE_FORMAT_ISO,
                  new Date()
                );
                setDateFrom(date);
              }}
            />
          </FormControl>
        </div>
      </Col>
      <Col sm={6} md={4} lg={2} xl={2} className="mb-2">
        <div>
          <FormControl fullWidth>
            <TextField
              type="date"
              label={t("date_to")}
              value={format(dateTo, DATE_FORMAT_ISO)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                let date = parse(
                  event.target.value,
                  DATE_FORMAT_ISO,
                  new Date()
                );
                setDateTo(date);
              }}
            />
          </FormControl>
        </div>
      </Col>
      <Col className="mb-2">
        <ButtonLoad
          loading={loadingData}
          variant="primary"
          onClick={handleButtonClick}
        >
          {t("create_report")}
        </ButtonLoad>
      </Col>
    </Row>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ReportDateFilter);
