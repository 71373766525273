import React, { useContext } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import {
  InspectionReturnQuestionType,
  InspectionReturnToMachineType,
  Mutation,
  MutationDeleteInspectionReturnToMachineArgs,
} from "../../entity/types";
import ButtonLoad from "../Shared/ButtonLoad";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { dialogConfirm } from "../../utils/dialogs";
import { DELETE_INSPECTION_RETURN_TO_MACHINE_MUTATION } from "../../apollo/mutations/inspections";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionReturnToMachine: InspectionReturnToMachineType;
  inspectionReturnQuestion: InspectionReturnQuestionType;
}

function InspectionReturnToMachineQuestionRow({
  classes,
  inspectionReturnToMachine,
  inspectionReturnQuestion,
}: Props) {
  const { t } = useTranslation();

  const [deleteInspectionReturnToMachine, { loading: loadingDelete }] =
    useMutation<Mutation, MutationDeleteInspectionReturnToMachineArgs>(
      DELETE_INSPECTION_RETURN_TO_MACHINE_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "inspectionReturnCategories",
          });
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "inspections.delete_inspectionreturntomachine",
  ]);

  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteInspectionReturnToMachine({
        variables: {
          inspectionReturnToMachineId: inspectionReturnToMachine.id,
        },
      });
    });
  };

  return (
    <tr>
      <td>{inspectionReturnQuestion.question}</td>
      <td>
        {hasPermissionDelete && (
          <ButtonLoad
            loading={loadingDelete}
            size="sm"
            variant="light"
            onClick={onClickDelete}
          >
            {<FontAwesomeIcon icon={faTrash} />}
          </ButtonLoad>
        )}
      </td>
    </tr>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InspectionReturnToMachineQuestionRow);
