import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, FormControl, Theme } from "@material-ui/core";
import {
  LocationType,
  MachineBulkAmountType,
  MachineType,
} from "../../entity/types";
import { parseNumber } from "../../utils/formatting";
import TextFieldFocus from "../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
  setMachine: React.Dispatch<React.SetStateAction<MachineType>>;
  location: LocationType;
  machineBulkAmount: MachineBulkAmountType;
}

function MachineBulkAmountTr({
  classes,
  machine,
  setMachine,
  location,
  machineBulkAmount,
}: Props) {
  const { t } = useTranslation();

  const [machineBulkAmountEdited, setMachineBulkAmountEdited] =
    useState(machineBulkAmount);

  return (
    <tr>
      <th>{location.name}</th>
      <td>
        <FormControl>
          <TextFieldFocus
            type="number"
            onChange={(event) => {
              const machineBulkAmountEditedNew = {
                ...machineBulkAmountEdited,
                bulkAmount: parseNumber(event.target.value),
                location: location,
              };
              setMachineBulkAmountEdited(machineBulkAmountEditedNew);

              const hasRow = machine.machinebulkamountSet.some(
                (machineBulkAmountLooped) =>
                  machineBulkAmountLooped.location.id ===
                  machineBulkAmountEditedNew.location.id
              );

              let machineBulkAmountSet: MachineBulkAmountType[] =
                machine.machinebulkamountSet.map((machineBulkAmountLooped) =>
                  machineBulkAmountLooped.location.id ===
                  machineBulkAmountEditedNew.location.id
                    ? machineBulkAmountEditedNew
                    : machineBulkAmountLooped
                );

              if (!hasRow) {
                machineBulkAmountSet.push(machineBulkAmountEditedNew);
              }

              setMachine({
                ...machine,
                machinebulkamountSet: machineBulkAmountSet,
              });
            }}
            value={machineBulkAmountEdited.bulkAmount}
            inputProps={{ maxLength: 13 }}
          />
        </FormControl>
      </td>
      <td>{t("pcs")}</td>
    </tr>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MachineBulkAmountTr);
