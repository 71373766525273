import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { resetDateTime } from "../utils/dates";
import format from "date-fns/format";
import { DATE_FORMAT_ISO } from "../utils/constants";
import { parse } from "date-fns";
import InvoicesSentList from "../components/Invoice/InvoicesSentList";
import HeaderPage from "../components/Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {}

function ManagementInvoicesSent({ classes }: Props) {
  const { t } = useTranslation();

  const dateFromDefault = resetDateTime(new Date());
  dateFromDefault.setDate(dateFromDefault.getDate() - 7);
  const dateToDefault = resetDateTime(new Date());

  const [dateFrom, setDateFrom] = useState(dateFromDefault);
  const [dateTo, setDateTo] = useState(dateToDefault);

  return (
    <Container className="containerMain">
      <HeaderPage title={t("management_invoices_sent")} />
      <div className="containerInner">
        <div className="mb-4">
          <FormControl className="mb-2">
            <TextField
              className="me-4"
              type="date"
              label={t("date_from")}
              value={format(dateFrom, DATE_FORMAT_ISO)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                let date = parse(
                  event.target.value,
                  DATE_FORMAT_ISO,
                  new Date()
                );
                setDateFrom(date);
              }}
            />
          </FormControl>
          <FormControl>
            <TextField
              type="date"
              label={t("date_to")}
              value={format(dateTo, DATE_FORMAT_ISO)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                let date = parse(
                  event.target.value,
                  DATE_FORMAT_ISO,
                  new Date()
                );
                setDateTo(date);
              }}
            />
          </FormControl>
        </div>
        <InvoicesSentList dateFrom={dateFrom} dateTo={dateTo} />
      </div>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ManagementInvoicesSent);
