import { CatalogExtraRowRentalType, MachineType } from "../../entity/types";

export function getMachineName(
  machine: MachineType,
  showMachineIdentifier = false
) {
  return (
    (showMachineIdentifier && machine.identifier !== ""
      ? machine.identifier + " "
      : "") +
    machine.machineModel.machineMake.title +
    (machine.machineModel.machineMake.title !== "" ? " " : "") +
    machine.machineModel.title
  );
}

export function getCatalogExtraRowRentalName(
  catalogExtraRowRental: CatalogExtraRowRentalType
) {
  return catalogExtraRowRental.productNumber + " " + catalogExtraRowRental.name;
}
