import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { CircularProgress, createStyles, Theme } from "@material-ui/core";
import imgLogo from "../../pictures/logo_inverted.png";
import { useTranslation } from "react-i18next";

interface Props extends WithStyles<typeof styles> {}

const Loading = ({ classes }: Props) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShow(true);
    }, 750);

    return () => clearTimeout(timeoutId);
  });

  return (
    <div
      className={`conLoadingSpinner ${show ? "" : "d-none"}`}
      title={t("loading")}
    >
      <CircularProgress />
      <div className="loadingSpinner">
        <img src={imgLogo} alt={t("app_name")} />
      </div>
      <div className="text-muted">{t("loading")}</div>
    </div>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(Loading);
