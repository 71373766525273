import { gql } from "@apollo/client";
import { Mutation } from "../../entity/types";

export const CREATE_COMMISSION_TEMPLATE_MUTATION = gql`
  mutation createCommissionTemplate($name: String!, $baseSalary: Decimal!) {
    createCommissionTemplate(name: $name, baseSalary: $baseSalary) {
      commissionTemplate {
        id
        name
        baseSalary
        createdAt
      }
    }
  }
`;

export const CREATE_COMMISSION_RANGE_MUTATION = gql`
  mutation createCommissionRange($commissionTemplateId: ID!, $daysMax: Int!) {
    createCommissionRange(
      commissionTemplateId: $commissionTemplateId
      daysMax: $daysMax
    ) {
      commissionRange {
        id
        commissionTemplate {
          id
        }
        daysMax
        createdAt
      }
    }
  }
`;

export const UPDATE_COMMISSION_RANGE_MUTATION = gql`
  mutation updateCommissionRange($commissionRangeId: ID!, $daysMax: Int!) {
    updateCommissionRange(
      commissionRangeId: $commissionRangeId
      daysMax: $daysMax
    ) {
      commissionRange {
        id
        daysMax
        createdAt
      }
    }
  }
`;

export const DELETE_COMMISSION_RANGE_MUTATION = gql`
  mutation deleteCommissionRange($commissionRangeId: ID!) {
    deleteCommissionRange(commissionRangeId: $commissionRangeId) {
      commissionRangeId
    }
  }
`;

export const UPDATE_COMMISSION_TEMPLATE_MUTATION = gql`
  mutation updateCommissionTemplate(
    $commissionTemplateId: ID!
    $name: String!
    $baseSalary: Decimal!
  ) {
    updateCommissionTemplate(
      commissionTemplateId: $commissionTemplateId
      name: $name
      baseSalary: $baseSalary
    ) {
      commissionTemplate {
        id
        name
        baseSalary
      }
    }
  }
`;

export const DELETE_COMMISSION_TEMPLATE_MUTATION = gql`
  mutation deleteCommissionTemplate($commissionTemplateId: ID!) {
    deleteCommissionTemplate(commissionTemplateId: $commissionTemplateId) {
      commissionTemplateId
    }
  }
`;

export const CREATE_COMMISSION_LEVEL_MUTATION = gql`
  mutation createCommissionLevel(
    $commissionTemplateId: ID!
    $commissionRangeId: ID!
    $maxDiscount: Decimal!
    $maxCommission: Decimal!
    $minCommission: Decimal!
  ) {
    createCommissionLevel(
      commissionTemplateId: $commissionTemplateId
      commissionRangeId: $commissionRangeId
      maxDiscount: $maxDiscount
      maxCommission: $maxCommission
      minCommission: $minCommission
    ) {
      commissionLevel {
        id
        commissionTemplate {
          id
        }
        commissionRange {
          id
        }
        maxDiscount
        maxCommission
        minCommission
        createdAt
      }
    }
  }
`;

export const UPDATE_COMMISSION_LEVEL_MUTATION = gql`
  mutation updateCommissionLevel(
    $commissionLevelId: ID!
    $maxDiscount: Decimal!
    $maxCommission: Decimal!
    $minCommission: Decimal!
  ) {
    updateCommissionLevel(
      commissionLevelId: $commissionLevelId
      maxDiscount: $maxDiscount
      maxCommission: $maxCommission
      minCommission: $minCommission
    ) {
      commissionLevel {
        id
        maxDiscount
        maxCommission
        minCommission
        createdAt
      }
    }
  }
`;

export const DELETE_COMMISSION_LEVEL_MUTATION = gql`
  mutation deleteCommissionLevel($commissionLevelId: ID!) {
    deleteCommissionLevel(commissionLevelId: $commissionLevelId) {
      commissionLevelId
    }
  }
`;

export const UPDATE_COMMISSION_USER_SETTING_MUTATION = gql`
  mutation updateCommissionUserSettingMutation(
    $commissionUserSettingId: ID!
    $commissionTemplateId: ID!
    $showCommissions: Boolean!
  ) {
    updateCommissionUserSetting(
      commissionUserSettingId: $commissionUserSettingId
      commissionTemplateId: $commissionTemplateId
      showCommissions: $showCommissions
    ) {
      commissionUserSetting {
        id
        user {
          id
        }
        createdAt
        showCommissions
      }
    }
  }
`;

export const CREATE_COMMISSION_REPORTS_MUTATION = gql`
  mutation createCommissionReports($year: Int!, $month: Int!, $userIds: [ID]!) {
    createCommissionReports(year: $year, month: $month, userIds: $userIds) {
      commissionReports {
        id
      }
    }
  }
`;

export interface MutationResultCommissionReportPdf {
  getCommissionReportPdf: Mutation["getCommissionReportPdf"];
}
export const GET_COMMISSION_REPORT_PDF_MUTATION = gql`
  mutation getCommissionReportPdf($year: Int!, $month: Int!, $userIds: [ID]!) {
    getCommissionReportPdf(year: $year, month: $month, userIds: $userIds) {
      url
    }
  }
`;
