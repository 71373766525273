import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogCategoryType,
  CatalogCategoryUpperType,
  Mutation,
  MutationCreateCatalogCategoryArgs,
  MutationUpdateCatalogCategoryArgs,
} from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import {
  CREATE_CATALOG_CATEGORY_MUTATION,
  UPDATE_CATALOG_CATEGORY_MUTATION,
} from "../../apollo/mutations/catalogs";
import { CatalogCategoryEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import DialogContentCatalogCategory from "./DialogContentCatalogCategory";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalogCategoryOrig: CatalogCategoryType;
  catalogCategoriesUpper: CatalogCategoryUpperType[];
}

function DialogCatalogCategory({
  classes,
  open,
  setOpen,
  catalogCategoryOrig,
  catalogCategoriesUpper,
}: Props) {
  const { t } = useTranslation();
  const [catalogCategory, setCatalogCategory] =
    useState<CatalogCategoryType>(catalogCategoryOrig);

  const [createCatalogCategory] = useMutation<
    Mutation,
    MutationCreateCatalogCategoryArgs
  >(CREATE_CATALOG_CATEGORY_MUTATION, {
    onCompleted: (result) => {
      setCatalogCategory(CatalogCategoryEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogs"),
      });
    },
  });
  const [updateCatalogCategory] = useMutation<
    Mutation,
    MutationUpdateCatalogCategoryArgs
  >(UPDATE_CATALOG_CATEGORY_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSubmitCatalog = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (catalogCategory.id !== ID_EMPTY) {
      updateCatalogCategory({
        variables: {
          catalogCategoryId: catalogCategory.id,
          catalogCategoryUpperId: catalogCategory.catalogCategoryUpper
            ? catalogCategory.catalogCategoryUpper.id
            : ID_EMPTY,
          name: catalogCategory.name,
          information: catalogCategory.information,
        },
      });
    } else {
      createCatalogCategory({
        variables: {
          catalogCategoryUpperId: catalogCategory.catalogCategoryUpper
            ? catalogCategory.catalogCategoryUpper.id
            : ID_EMPTY,
          name: catalogCategory.name,
          information: catalogCategory.information,
        },
      });
    }
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <form
        className={classes.form}
        onSubmit={(event) => handleSubmitCatalog(event)}
      >
        <DialogTitle>
          {t(
            catalogCategory.id !== ID_EMPTY
              ? "edit_catalog_category"
              : "new_catalog_category"
          )}
        </DialogTitle>
        <DialogContentCatalogCategory
          catalogCategory={catalogCategory}
          setCatalogCategory={setCatalogCategory}
          catalogCategoriesUpper={catalogCategoriesUpper}
        />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <Button
            disabled={
              !catalogCategory.name.trim() ||
              !catalogCategory.catalogCategoryUpper.id
            }
            type="submit"
            variant="primary"
          >
            {t("save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: spacing(2),
    },
    textField: {
      marginTop: spacing(1),
      marginBottom: spacing(1),
    },
  });

export default withStyles(styles)(DialogCatalogCategory);
