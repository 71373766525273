import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { TextFieldProps, TextField } from "@material-ui/core";

interface Props extends WithStyles<typeof styles> {}

const TextFieldFocus = ({ classes, ...other }: Props & TextFieldProps) => {
  const onFocus = other.onFocus;
  delete other.onFocus;

  return (
    <TextField
      {...other}
      onFocus={(event) => {
        event.target.select();
        if (onFocus) {
          onFocus(event);
        }
      }}
    />
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInputBase-root": {
        "& input": {
          textAlign: "center",
        },
      },
    },
  });

export default withStyles(styles)(TextFieldFocus);
