import { gql } from "@apollo/client";
import { FRAG_REFERENCE_SHARED } from "../queries/references";

export const CREATE_REFERENCE_MUTATION = gql`
  ${FRAG_REFERENCE_SHARED}
  mutation createReference($customerId: ID!, $name: String!) {
    createReference(customerId: $customerId, name: $name) {
      reference {
        ...ReferenceFields
      }
    }
  }
`;
