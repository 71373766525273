import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Box, createStyles, Tab, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import InvoicesCreateListReferences from "../components/Invoice/InvoicesCreateListReferences";
import HeaderPage from "../components/Shared/HeaderPage";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import InvoicesCreateListCustomers from "../components/Invoice/InvoicesCreateListCustomers";

interface Props extends WithStyles<typeof styles> {}

function ManagementInvoicesCreate({ classes }: Props) {
  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = useState("0");

  return (
    <Container className="containerMain">
      <HeaderPage title={t("management_invoices_create")} />
      <div className="containerInner">
        <TabContext value={tabSelected}>
          <Box>
            <TabList onChange={(event, newTab) => setTabSelected(newTab)}>
              <Tab label={t("invoices_view_customers")} value="0" />
              <Tab label={t("invoices_view_references")} value="1" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <InvoicesCreateListCustomers />
          </TabPanel>
          <TabPanel value="1">
            <InvoicesCreateListReferences />
          </TabPanel>
        </TabContext>
      </div>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ManagementInvoicesCreate);
