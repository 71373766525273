import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import LocationList from "../components/Location/LocationList";
import {
  GET_LOCATIONS_QUERY,
  QueryResultLocations,
} from "../apollo/queries/locations";
import { useQuery } from "@apollo/client";
import { createStyles, Theme } from "@material-ui/core";
import CreateLocation from "../components/Location/CreateLocation";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {}

function SettingsLocations({ classes }: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<QueryResultLocations>(
    GET_LOCATIONS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("locations"),
    }
  );

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <Container className="containerMain">
      <CreateLocation />
      <LocationList locations={data.locations ? data.locations : []} />
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(SettingsLocations);
