export const setActiveOrganization = (organizationId:number) => {
    localStorage.setItem('__activeOrganisation', organizationId.toString())
}

export const getActiveOrganization = ():string => {
    const activeOrganisation = localStorage.getItem('__activeOrganisation');
    return(
        activeOrganisation ? activeOrganisation : "null"
    )
}

export const deleteActiveOrganization = () => {
    localStorage.removeItem('__activeOrganisation');
}

