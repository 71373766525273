import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";
import { resetDateTime } from "../../utils/dates";
import { UserType } from "../../entity/types";
import { Col, Row } from "react-bootstrap";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { format, parse } from "date-fns";
import { DATE_FORMAT_ISO } from "../../utils/constants";
import CommissionPreviewForUser from "./CommissionPreviewForUser";
import { UserEmpty } from "../../entity/empties";
import { formatNumber } from "../../utils/formatting";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles } from "@material-ui/core/styles";
import { CommissionUserSettingsByUserType } from "../../pages/ManagementCommissions";

interface Props extends WithStyles<typeof styles> {
  users: UserType[];
  commissionUserSettingsByUser: CommissionUserSettingsByUserType;
}

function CommissionReportRealtime({
  classes,
  users,
  commissionUserSettingsByUser,
}: Props) {
  const { t } = useTranslation();

  const currentDate = new Date();
  // Default date range is the last month.
  // first day of the last month
  const dateFromDefault = resetDateTime(
    new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
  );
  // last day of the last month
  const dateToDefault = resetDateTime(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 0)
  );

  const [dateFrom, setDateFrom] = useState(dateFromDefault);
  const [dateTo, setDateTo] = useState(dateToDefault);

  const [userIdsSelected, setUserIdsSelected] = useState<string[]>([]);

  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);

  const addToRevenue = useCallback(
    (result) => {
      setTotalRevenue((prev) => prev + result);
    },
    [setTotalRevenue]
  );

  const addToCommission = useCallback(
    (result) => {
      setTotalCommission((prev) => prev + result);
    },
    [setTotalCommission]
  );

  return (
    <div>
      <Row>
        <Col sm={4} md={4} lg={3}>
          <FormControl fullWidth>
            <TextField
              type="date"
              label={t("date_from")}
              value={format(dateFrom, DATE_FORMAT_ISO)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ max: format(dateTo, DATE_FORMAT_ISO) }}
              onChange={(event) => {
                let date = parse(
                  event.target.value,
                  DATE_FORMAT_ISO,
                  new Date()
                );
                setDateFrom(date);
              }}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={3}>
          <FormControl fullWidth>
            <TextField
              type="date"
              label={t("date_to")}
              value={format(dateTo, DATE_FORMAT_ISO)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ min: format(dateFrom, DATE_FORMAT_ISO) }}
              onChange={(event) => {
                let date = parse(
                  event.target.value,
                  DATE_FORMAT_ISO,
                  new Date()
                );
                setDateTo(date);
              }}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="lblUserListDataType">{t("user")}</InputLabel>
            <Select
              autoWidth
              labelId="lblUserListFilter"
              value={userIdsSelected}
              multiple={true}
              onChange={(event) => {
                setUserIdsSelected(event.target.value as string[]);
              }}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.firstName + " " + user.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
      </Row>
      {userIdsSelected.map((userIdSelected) => {
        const userFiltered = users.find((user) => {
          return user.id === userIdSelected;
        });
        const userSelected = userFiltered ? userFiltered : UserEmpty;
        return (
          <CommissionPreviewForUser
            key={userSelected.id}
            userSelected={userSelected}
            commissionUserSetting={
              commissionUserSettingsByUser[userSelected.id]
            }
            dateFrom={dateFrom}
            dateTo={dateTo}
            addToRevenue={addToRevenue}
            addToCommission={addToCommission}
          />
        );
      })}
      <div className="mt-4">
        <b className={classes.total}>
          {t("revenues_total", {
            total: t("currency", {
              amount: formatNumber(totalRevenue, 2),
            }),
          })}
        </b>
      </div>
      <div className="mt-1">
        <b className={classes.total}>
          {t("commissions_total", {
            total: t("currency", {
              amount: formatNumber(totalCommission, 2),
            }),
          })}
        </b>
      </div>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    total: {
      fontSize: "1rem",
    },
  });

export default withStyles(styles)(CommissionReportRealtime);
