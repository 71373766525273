import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { Button, Nav, Navbar } from "react-bootstrap";
import { CustomerType } from "../../entity/types";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { AuthContext, PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { getUrlCustomerTab, URL_CUSTOMERS } from "../../utils/urls";
import LinkCalendarSelect from "../Calendar/LinkCalendarSelect";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  tab: String;
}

const NavigationCustomer = ({ classes, customer, tab }: Props) => {
  const { t } = useTranslation();

  const Auth = useContext(AuthContext);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionViewEvents = checkPermission(myPermissions, [
    "events.view_event",
  ]);
  const hasPermissionViewOffer = checkPermission(myPermissions, [
    "catalogs.view_catalog",
  ]);

  const LinkCalendar = Auth.Calendar(() => {
    return <LinkCalendarSelect customerId={customer.id} />;
  });

  return (
    <Navbar bg="light" expand="md" className="mb-3">
      <LinkContainer to={URL_CUSTOMERS}>
        <Button variant="outline-light">
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
      </LinkContainer>
      <Navbar.Brand>{customer.name}</Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarCustomer" />
      <Navbar.Collapse className={classes.navBarCustomer} id="navbarCustomer">
        <Nav className="me-auto">
          <LinkContainer to={getUrlCustomerTab(customer.id, "basic")}>
            <Nav.Link>{t("link_customer_basic")}</Nav.Link>
          </LinkContainer>
          {hasPermissionViewEvents && (
            <LinkContainer to={getUrlCustomerTab(customer.id, "events")}>
              <Nav.Link>{t("link_customer_events")}</Nav.Link>
            </LinkContainer>
          )}
          <LinkContainer to={getUrlCustomerTab(customer.id, "reservations")}>
            <Nav.Link>{t("link_customer_reservations")}</Nav.Link>
          </LinkContainer>
          {hasPermissionViewOffer && (
            <LinkContainer to={getUrlCustomerTab(customer.id, "offer")}>
              <Nav.Link>{t("link_customer_offer")}</Nav.Link>
            </LinkContainer>
          )}
          <LinkCalendar />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const styles = ({ breakpoints, spacing }: Theme) =>
  createStyles({
    navBarCustomer: {
      marginLeft: 0,
      [breakpoints.up("md")]: {
        marginLeft: spacing(4),
      },
    },
  });

export default withStyles(styles)(NavigationCustomer);
