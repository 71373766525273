import { gql } from "@apollo/client";
import { Query } from "../../entity/types";
import { FRAG_RESERVATION_SHARED } from "./reservations";

const FRAG_CATALOG_ROW_SHARED = gql`
  fragment CatalogRowFields on CatalogRowType {
    id
    name
    information
    image
    billingDaysWeekCompany
    priceDayCompany
    priceMonthCompany
    billingDaysWeekPrivate
    priceDayPrivate
    priceMonthPrivate
    hourLimitDayCompany
    hourLimitDayPrivate
    hourLimitMonthCompany
    hourLimitMonthPrivate
    extraHoursCompany
    extraHoursPrivate
    productNumberDay
    productNumberMonth
  }
`;

export interface QueryResultCatalogs {
  catalogs: Query["catalogs"];
}
export const GET_CATALOGS_QUERY = gql`
  query getCatalogs {
    catalogs {
      id
      name
      catalogcategoryupperSet {
        id
        name
        catalogcategorySet {
          id
          name
          catalogrowSet {
            id
            name
          }
        }
      }
    }
  }
`;

export interface QueryResultCatalogsWithoutRows {
  catalogs: Query["catalogs"];
}
export const GET_CATALOGS_WITHOUT_ROWS_QUERY = gql`
  query getCatalogsWithoutRows {
    catalogs {
      id
      name
      setActiveAt
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export interface QueryResultCatalogWithRows {
  catalog: Query["catalog"];
}
export const GET_CATALOG_WITH_ROWS_QUERY = gql`
  query getCatalogWithRows($catalogId: ID!) {
    catalog(catalogId: $catalogId) {
      id
      name
      catalogcategoryupperSet {
        id
        name
        catalogcategorySet {
          id
          name
          catalogrowSet {
            id
            name
          }
        }
      }
    }
  }
`;

export interface QueryResultCatalog {
  catalog: Query["catalog"];
}
export const GET_CATALOG_QUERY = gql`
  ${FRAG_CATALOG_ROW_SHARED}
  query getCatalog($catalogId: ID!) {
    catalog(catalogId: $catalogId) {
      id
      name
      catalogcategoryupperSet {
        id
        name
        catalogcategorySet {
          id
          name
          information
          image
          catalogCategoryUpper {
            id
          }
          catalogrowSet {
            ...CatalogRowFields
            catalogCategory {
              id
            }
            machineSet {
              id
              location {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export interface QueryResultCatalogWithCats {
  catalog: Query["catalog"];
}
export const GET_CATALOG_WITH_CATS_QUERY = gql`
  query getCatalogWithCats($catalogId: ID!) {
    catalog(catalogId: $catalogId) {
      id
      name
      catalogcategoryupperSet {
        id
        name
      }
    }
  }
`;

export interface QueryResultCatalogCategoriesForUpper {
  catalogCategoriesForUpper: Query["catalogCategoriesForUpper"];
}
export const GET_CATALOG_CATEGORIES_FOR_UPPER_QUERY = gql`
  query getCatalogCategoriesForUpper($catalogCategoryUpperId: ID!) {
    catalogCategoriesForUpper(catalogCategoryUpperId: $catalogCategoryUpperId) {
      id
      name
      catalogrowSet {
        id
        name
        machineSet {
          id
          location {
            id
            name
          }
        }
      }
    }
  }
`;

export interface QueryResultCatalogWithSource {
  catalog: Query["catalog"];
}
export const GET_CATALOG_WITH_SOURCE_QUERY = gql`
  ${FRAG_CATALOG_ROW_SHARED}
  query getCatalogWithSource($catalogId: ID!) {
    catalog(catalogId: $catalogId) {
      id
      name
      setActiveAt
      catalogcategoryupperSet {
        id
        name
        catalogcategorySet {
          id
          name
          information
          image
          catalogCategoryUpper {
            id
          }
          catalogrowSet {
            ...CatalogRowFields
            catalogCategory {
              id
            }
            catalogRowSource {
              id
            }
          }
        }
      }
      catalogSource {
        id
        name
        catalogcategoryupperSet {
          id
          name
          catalogcategorySet {
            id
            name
            catalogrowSet {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export interface QueryResultCalendar {
  catalogCategoriesUpper: Query["catalogCategoriesUpper"];
  bulkAmountsByDate: Query["bulkAmountsByDate"];
  reservationsCalendar: Query["reservationsCalendar"];
  machineBreakdowns: Query["machineBreakdowns"];
  maintenancesCalendar: Query["maintenancesCalendar"];
}
export const GET_CALENDAR_QUERY = gql`
  ${FRAG_CATALOG_ROW_SHARED}
  ${FRAG_RESERVATION_SHARED}
  query getCalendar(
    $catalogId: ID!
    $catalogCategoryUpperId: ID
    $dateFrom: Date!
    $dateTo: Date!
    $locationId: ID!
    $catalogCategoryId: ID
    $catalogRowId: ID
  ) {
    catalogCategoriesUpper(
      catalogId: $catalogId
      catalogCategoryUpperId: $catalogCategoryUpperId
    ) {
      id
      name
      catalogcategorySet {
        id
        name
        information
        catalogrowSet {
          ...CatalogRowFields
        }
      }
    }
    bulkAmountsByDate(
      dateFrom: $dateFrom
      dateTo: $dateTo
      locationId: $locationId
    ) {
      machineId
      date
      amountReserved
      amountFree
    }
    reservationsCalendar(
      dateFrom: $dateFrom
      dateTo: $dateTo
      locationId: $locationId
      catalogCategoryUpperId: $catalogCategoryUpperId
      catalogCategoryId: $catalogCategoryId
      catalogRowId: $catalogRowId
    ) {
      ...ReservationFields
      giveAt
      returnAt
      returnCheckedAt
      machine {
        id
      }
      catalogExtraRowRental {
        id
      }
      order {
        id
        status
        customer {
          id
          name
          contactPersonLastname
          contactPersonFirstname
        }
      }
    }
    machineBreakdowns(dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      title
      information
      fixByStart
      fixByEnd
      fixedAt
      stillRentable
      billing
      amountBilledSingle
      machine {
        id
      }
      catalogExtraRowRental {
        id
      }
    }
    maintenancesCalendar(
      dateFrom: $dateFrom
      dateTo: $dateTo
      locationId: $locationId
    ) {
      id
      maintenanceType
      estimatedDate
      serviceStartBy
      serviceEndBy
      servicedAt
      machine {
        id
      }
      catalogExtraRowRental {
        id
      }
      maintenanceInterval {
        id
        description
      }
    }
  }
`;

export interface QueryResultCatalogsWithDiscountLists {
  catalogs: Query["catalogs"];
}
export const GET_CATALOGS_WITH_DISCOUNT_LISTS_QUERY = gql`
  query getCatalogsWithDiscountLists($catalogIds: [ID]) {
    catalogs(catalogIds: $catalogIds) {
      id
      name
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      discountlistSet {
        id
        name
        isBusiness
        locked
        isOffer
        createdAt
        customer {
          id
        }
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export interface QueryResultCatalogRowDiscountsMachines {
  machines: Query["machines"];
  machinesForCatalogRow: Query["machinesForCatalogRow"];
}
export const GET_CATALOG_ROW_DISCOUNTS_MACHINES_QUERY = gql`
  query getCatalogRowDiscountsMachines($catalogRowId: ID!) {
    machines {
      id
      identifier
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
    }
    machinesForCatalogRow(catalogRowId: $catalogRowId) {
      id
    }
  }
`;

export interface QueryResultDiscountListsForCatalogRow {
  discountListsForCatalogRow: Query["discountListsForCatalogRow"];
}
export const GET_DISCOUNT_LISTS_FOR_CATALOG_ROW_QUERY = gql`
  query getDiscountListsForCatalogRow($catalogRowId: ID!) {
    discountListsForCatalogRow(catalogRowId: $catalogRowId) {
      id
      name
      locked
      customer {
        id
        name
      }
      discountrowSetForCatalogRow(catalogRowId: $catalogRowId) {
        id
        percent
      }
    }
  }
`;

export interface QueryResultCatalogActive {
  catalogActive: Query["catalogActive"];
}
export const GET_CATALOG_ACTIVE_QUERY = gql`
  query getCatalogActive {
    catalogActive {
      id
    }
  }
`;

export interface QueryResultCatalogRow {
  catalogRow: Query["catalogRow"];
}
export const GET_CATALOG_ROW_QUERY = gql`
  query getCatalogRow($catalogRowId: ID!) {
    catalogRow(catalogRowId: $catalogRowId) {
      id
      billingDaysWeekCompany
      billingDaysWeekPrivate
      hourLimitDayCompany
      hourLimitDayPrivate
      hourLimitMonthCompany
      hourLimitMonthPrivate
      extraHoursCompany
      extraHoursPrivate
    }
  }
`;

export interface QueryResultCatalogActiveWithCategories {
  catalogActive: Query["catalogActive"];
}
export const GET_CATALOG_ACTIVE_CATEGORIES_QUERY = gql`
  query getCatalogActiveWithCategories {
    catalogActive {
      id
      catalogcategoryupperSet {
        id
        name
        catalogcategorySet {
          id
          name
          catalogrowSet {
            id
            name
          }
        }
      }
    }
  }
`;

export interface QueryResultErrorsSettingCatalogActive {
  errorsSettingCatalogActive: Query["errorsSettingCatalogActive"];
}
export const GET_ERRORS_SETTING_CATALOG_ACTIVE_QUERY = gql`
  query getErrorsSettingCatalogActive($catalogId: ID!) {
    errorsSettingCatalogActive(catalogId: $catalogId)
  }
`;
