import {
  setLocalStorageCustomerId,
  setLocalStorageOrderId,
} from "./calendar/calendar";
import { setActiveOrganization } from "../entity/ActiveOrganizationHandler";

export const onLogin = (token: string) => {
  setLocalStorageOrderId("");
  setLocalStorageCustomerId("");
  localStorage.setItem("authToken", token);
  setActiveOrganization(1);

};

export const onLogout = () => {
  setLocalStorageOrderId("");
  setLocalStorageCustomerId("");
  localStorage.removeItem("authToken");
};
