import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Mutation,
  MutationUpdateReservationArgs,
  ReservationType,
} from "../../entity/types";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { getDiscountPercent, getDiscountPrice } from "../../utils/calc";
import TextFieldFocus from "../Shared/TextFieldFocus";
import { useMutation } from "@apollo/client";
import { UPDATE_RESERVATION_MUTATION } from "../../apollo/mutations/reservations";
import { handleError } from "../../entity/ErrorHandler";
import { updateCacheReservation } from "../../utils/cache";
import { CALENDAR_CUSTOMER_PRICE_ROW } from "./styles";

interface Props extends WithStyles<typeof styles> {
  priceDay: number;
  isBusiness: boolean;
  billingDaysWeek: number;
  reservation?: ReservationType;
}

const getPriceDayEdited = (priceDay: number, reservation?: ReservationType) =>
  reservation
    ? formatNumber(
        getDiscountPrice(priceDay, reservation.discountPercentDay),
        2
      )
    : priceDay;

function CalendarCustomerPriceRowDay({
  classes,
  priceDay,
  isBusiness,
  billingDaysWeek,
  reservation,
}: Props) {
  const { t } = useTranslation();

  const [priceDayEdited, setPriceDayEdited] = useState(
    getPriceDayEdited(priceDay, reservation)
  );

  const [updateReservation, { loading }] = useMutation<
    Mutation,
    MutationUpdateReservationArgs
  >(UPDATE_RESERVATION_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheReservation(cache);
    },
  });

  useEffect(() => {
    setPriceDayEdited(getPriceDayEdited(priceDay, reservation));
  }, [priceDay, reservation]);

  const showDiscount = !!reservation;

  return (
    <tr className={loading ? "loading" : ""}>
      <th className={classes.th}>
        {t(isBusiness ? "price_day_company_vat0" : "price_day_private_vat0")}
      </th>
      <td className={classes.td1} align="right">
        {t("currency", { amount: formatNumber(priceDay, 2) })}
      </td>
      {showDiscount && (
        <td className="ps-4">
          <FormControl className="m-0">
            <TextFieldFocus
              size="small"
              value={priceDayEdited}
              onChange={(event) => {
                setPriceDayEdited(event.target.value);
              }}
              onBlur={() => {
                const priceDayEditedParsed = parseNumber(priceDayEdited);
                const discountPercentDay = getDiscountPercent(
                  priceDay,
                  priceDayEditedParsed
                );
                setPriceDayEdited(formatNumber(priceDayEditedParsed, 2));

                updateReservation({
                  variables: {
                    reservationId: reservation.id,
                    discountPercentDay: discountPercentDay,
                  },
                });
              }}
            />
          </FormControl>
        </td>
      )}
      <td className={classes.tdSpacing}></td>
      <th className={classes.th}>
        {t(
          isBusiness ? "billing_days_week_company" : "billing_days_week_private"
        )}
      </th>
      <td className={classes.td2}>{billingDaysWeek}</td>
    </tr>
  );
}

const styles = (theme: Theme) => createStyles(CALENDAR_CUSTOMER_PRICE_ROW);

export default withStyles(styles)(CalendarCustomerPriceRowDay);
