import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import { InspectionGiveCategoryType } from "../../entity/types";
import { Col, Row } from "react-bootstrap";
import InspectionGiveSortCategory from "./InspectionGiveSortCategory";
import InspectionGiveSortQuestions from "./InspectionGiveSortQuestions";

interface Props extends WithStyles<typeof styles> {
  inspectionGiveCategories: InspectionGiveCategoryType[];
}

function InspectionGiveSort({ classes, inspectionGiveCategories }: Props) {
  return (
    <Row>
      <Col className={classes.col}>
        <InspectionGiveSortCategory
          inspectionGiveCategories={inspectionGiveCategories}
        />
      </Col>
      <Col className={classes.col}>
        <InspectionGiveSortQuestions
          inspectionGiveCategories={inspectionGiveCategories}
        />
      </Col>
    </Row>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    col: {
      marginBottom: spacing(3),
      userSelect: "none",
    },
  });

export default withStyles(styles)(InspectionGiveSort);
