import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CatalogExtraCategoryRentalType,
  CatalogExtraCategoryUpperRentalType,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import SelectRequired from "../Shared/SelectRequired";
import { ID_EMPTY } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  catalogExtraCategoryRental: CatalogExtraCategoryRentalType;
  setCatalogExtraCategoryRental: React.Dispatch<
    React.SetStateAction<CatalogExtraCategoryRentalType>
  >;
  catalogExtraCategoriesUpperRental: CatalogExtraCategoryUpperRentalType[];
}

function DialogContentCatalogExtraCategoryRental({
  classes,
  catalogExtraCategoryRental,
  setCatalogExtraCategoryRental,
  catalogExtraCategoriesUpperRental,
}: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "catalogs_extra.add_catalogextracategoryrental",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs_extra.change_catalogextracategoryrental",
  ]);
  const disabled = catalogExtraCategoryRental.id
    ? !hasPermissionEdit
    : !hasPermissionAdd;

  return (
    <DialogContent>
      <FormControl fullWidth>
        <InputLabel id="lblCatalogExtraCategoryCategoryUpper">
          {t("catalog_extra_category_upper")}
        </InputLabel>
        <SelectRequired
          autoWidth
          labelId="lblCatalogExtraCategoryCategoryUpper"
          value={
            catalogExtraCategoryRental.catalogExtraCategoryUpperRental
              ? catalogExtraCategoryRental.catalogExtraCategoryUpperRental.id
              : ID_EMPTY
          }
          disabled={disabled}
          onChange={(event) => {
            setCatalogExtraCategoryRental({
              ...catalogExtraCategoryRental,
              catalogExtraCategoryUpperRental: {
                ...catalogExtraCategoryRental.catalogExtraCategoryUpperRental,
                id: String(event.target.value),
              },
            });
          }}
        >
          <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
          {catalogExtraCategoriesUpperRental.map(
            (catalogExtraCategoryUpperRental) => (
              <MenuItem
                key={catalogExtraCategoryUpperRental.id}
                value={catalogExtraCategoryUpperRental.id}
              >
                {catalogExtraCategoryUpperRental.name}
              </MenuItem>
            )
          )}
        </SelectRequired>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("name")}
          onChange={(event) => {
            setCatalogExtraCategoryRental({
              ...catalogExtraCategoryRental,
              name: event.target.value,
            });
          }}
          value={catalogExtraCategoryRental.name}
          required={true}
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          autoFocus={true}
        />
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentCatalogExtraCategoryRental);
