import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { InvoiceType } from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import ManagementInvoice from "../Management/ManagementInvoice";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";

interface Props extends WithStyles<typeof styles> {
  invoice: InvoiceType;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function DialogInvoice({ classes, invoice, open, setOpen }: Props) {
  const { t } = useTranslation();

  const callbackAfterSend = () => setOpen(false);

  return (
    <Dialog open={open} maxWidth="xl">
      <DialogTitleWithClose
        id="dialogTitleInvoice"
        onClose={() => setOpen(false)}
      >
        {t("management_invoices_open")}
      </DialogTitleWithClose>
      <DialogContent>
        <ManagementInvoice
          invoice={invoice}
          readonly={false}
          callbackAfterSend={callbackAfterSend}
        />
      </DialogContent>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogInvoice);
