import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import {
  MachineBreakdownType,
  MachineInfoAnswerType,
  MachineType,
  MaintenanceIntervalIntervalType,
  MaintenanceSettingScheduleType,
  MaintenanceType,
  QueryMachineArgs,
} from "../../entity/types";
import { useQuery } from "@apollo/client";
import {
  GET_MACHINE_WITH_MACHINE_INFO_CATEGORIES_QUERY,
  QueryResultMachineWithMachineInfoCategories,
} from "../../apollo/queries/machines";
import Error from "../Shared/Error";
import {
  MachineEmpty,
  MaintenanceSettingTypeEmpty,
  NextMaintenanceTypeEmpty,
} from "../../entity/empties";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import DialogContentMachineInfoActions from "./DialogContentMachineInfoActions";
import DialogContentMachineInfoAnswer from "./DialogContentMachineInfoAnswer";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import MachineInfoProductCard from "./MachineInfoProductCard";
import { getMachineName } from "../../utils/machines/machine";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import format from "date-fns/format";
import { formMaintenanceTimeRange } from "../../utils/maintenances/maintenance";
import { newDate } from "../../utils/dates";
import LoadingDialog from "../Shared/LoadingDialog";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  machine: MachineType;
  editable: boolean;
}

function DialogMachineInfo({
  classes,
  open,
  setOpen,
  machine,
  editable,
}: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultMachineWithMachineInfoCategories,
    QueryMachineArgs
  >(GET_MACHINE_WITH_MACHINE_INFO_CATEGORIES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("machine"),
    variables: {
      machineId: machine.id,
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionChangeAnswer = checkPermission(myPermissions, [
    "machines.change_machineinfoanswer",
  ]);

  if (!hasPermissionChangeAnswer) {
    editable = false;
  }

  if (error) return <Error error={error} />;
  if (loading) return <LoadingDialog />;
  if (!data) return <Error error={t("error_query_failed")} />;

  let machineInfoCategoryIdsVisible: string[] = [];
  let machineInfoQuestionToAnswer: {
    [question_id: string]: MachineInfoAnswerType;
  } = {};
  data?.machine?.machineinfoanswerSet.forEach((machineInfoAnswer) => {
    const question_id = machineInfoAnswer.machineInfoQuestion.id;
    const category_id =
      machineInfoAnswer.machineInfoQuestion.machineInfoCategory.id;
    machineInfoQuestionToAnswer[question_id] = machineInfoAnswer;
    if (!machineInfoCategoryIdsVisible.includes(category_id)) {
      machineInfoCategoryIdsVisible.push(category_id);
    }
  });

  const onClickClose = () => {
    setOpen(false);
  };

  let hoursText = "";
  if (data?.operationHoursForMachine?.length) {
    hoursText = t(
      "operation_hours_" + data.operationHoursForMachine[0].source,
      {
        date: format(
          new Date(data.operationHoursForMachine[0].dateChecked),
          t("format_date")
        ),
        hours: data.operationHoursForMachine[0].hours,
      }
    );
  }

  const getMaintenanceHistoryInfo = (maintenance: MaintenanceType) => {
    return t("machine_maintenance_history_row", {
      hoursOrDays:
        data?.machine?.maintenancesetting?.scheduleType &&
        data.machine.maintenancesetting.scheduleType ===
          MaintenanceSettingScheduleType.Days
          ? maintenance.maintenanceInterval.days
          : maintenance.maintenanceInterval.hours,
      timeRange: formMaintenanceTimeRange(
        t,
        maintenance.maintenanceInterval.intervalType,
        data?.machine?.maintenancesetting?.scheduleType
          ? data.machine.maintenancesetting.scheduleType
          : MaintenanceSettingScheduleType.None
      ),
      hours: maintenance.operationhour?.hours
        ? maintenance.operationhour.hours + "h "
        : "",
      date: format(newDate(maintenance.servicedAt), t("format_date")),
      serviceTechnician:
        (maintenance.serviceTechnician?.firstName
          ? maintenance.serviceTechnician.firstName + " "
          : "") +
        (maintenance.serviceTechnician?.lastName
          ? maintenance.serviceTechnician.lastName
          : ""),
    });
  };

  const getBreakdownHistoryInfo = (breakdown: MachineBreakdownType) => {
    return (
      breakdown.title +
      " -  " +
      breakdown.information +
      " / " +
      format(newDate(breakdown.fixedAt), t("format_date")) +
      " " +
      breakdown.fixedBy?.firstName +
      " " +
      breakdown.fixedBy?.lastName
    );
  };

  const printNextMaintenance = () => {
    const nextMaintenance = data?.maintenanceNextForMachine
      ? data?.maintenanceNextForMachine
      : NextMaintenanceTypeEmpty;
    const settings = data?.machine?.maintenancesetting
      ? data.machine.maintenancesetting
      : MaintenanceSettingTypeEmpty;

    if (settings.scheduleType === MaintenanceSettingScheduleType.HoursDays) {
      return t("next_maintenance_estimate_2", {
        hours: nextMaintenance.hours,
        days: nextMaintenance.days,
        timeRangeHours: formMaintenanceTimeRange(
          t,
          nextMaintenance.intervalType as MaintenanceIntervalIntervalType,
          MaintenanceSettingScheduleType.Hours
        ),
        timeRangeDays: formMaintenanceTimeRange(
          t,
          nextMaintenance.intervalType as MaintenanceIntervalIntervalType,
          MaintenanceSettingScheduleType.Days
        ),
        estimateDate: format(
          newDate(nextMaintenance.nextMaintenanceDate),
          t("format_date")
        ),
      });
    } else {
      return t("next_maintenance_estimate", {
        hoursOrDays:
          settings.scheduleType === MaintenanceSettingScheduleType.Hours
            ? nextMaintenance.hours
            : nextMaintenance.days,
        timeRange: formMaintenanceTimeRange(
          t,
          nextMaintenance.intervalType as MaintenanceIntervalIntervalType,
          settings.scheduleType
        ),
        estimateDate: format(
          newDate(nextMaintenance.nextMaintenanceDate),
          t("format_date")
        ),
      });
    }
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitleWithClose id="dialogTitleMachineInfo" onClose={onClickClose}>
        {t("machine_information")}{" "}
        <small className="text-muted ms-3">
          {getMachineName(machine)}, {machine.identifier} ({machine.yearModel}){" "}
          {machine.serial}
        </small>
      </DialogTitleWithClose>
      <DialogContent className={loading ? "loading" : ""}>
        {hoursText.length > 0 && (
          <>
            <h5>{t("most_recent_hours")}</h5>
            <p className="mb-4">{hoursText}</p>
          </>
        )}
        {(data?.maintenanceHistoryForMachine ||
          data?.machineBreakdownsForMachine) && (
          <>
            <h5>{t("scheduled_maintenance_and_breakdown_history")}</h5>
            {data?.maintenanceNextForMachine && (
              <p className="mb-2">{printNextMaintenance()}</p>
            )}
            <p className="mb-2">{t("maintenance_history")}:</p>
            {(data?.maintenanceHistoryForMachine ||
              data?.machineBreakdownsForMachine) && (
              <ul>
                {data?.maintenanceHistoryForMachine &&
                  !data.maintenanceHistoryForMachine.length &&
                  data.machine?.maintenancesetting &&
                  data.machine.maintenancesetting.startDate?.length && (
                    <li>
                      {t("maintenance_start_date")}:{" "}
                      {format(
                        newDate(data.machine.maintenancesetting.startDate),
                        t("format_date")
                      )}
                    </li>
                  )}
                {data?.maintenanceHistoryForMachine &&
                  data.maintenanceHistoryForMachine.map((maintenance) => {
                    return (
                      <li key={maintenance.maintenanceInterval.id}>
                        {getMaintenanceHistoryInfo(maintenance)}
                      </li>
                    );
                  })}
                {data?.machineBreakdownsForMachine &&
                  data.machineBreakdownsForMachine.map((breakdown) => {
                    return (
                      <li key={breakdown.id}>
                        {getBreakdownHistoryInfo(breakdown)}
                      </li>
                    );
                  })}
              </ul>
            )}
          </>
        )}
        {editable && (
          <>
            <p className="text-muted mb-4">
              <FontAwesomeIcon
                className={`${classes.info} me-1`}
                icon={faInfoCircle}
              />
              {t("explain_machine_info_to_machine")}
            </p>
            <DialogContentMachineInfoActions
              machine={data?.machine ? data.machine : MachineEmpty}
              machines={data?.machines ? data.machines : []}
              machineInfoTags={
                data?.machineInfoTags ? data.machineInfoTags : []
              }
              machineInfoCategories={
                data?.machineInfoCategories ? data.machineInfoCategories : []
              }
            />
          </>
        )}
        {data?.machineInfoCategories?.map((machineInfoCategory) => {
          if (!machineInfoCategoryIdsVisible.includes(machineInfoCategory.id)) {
            return undefined;
          }
          return (
            <div key={machineInfoCategory.id} className="mb-5">
              <h5>{machineInfoCategory.name}</h5>
              {machineInfoCategory.machineinfoquestionSet.map(
                (machineInfoQuestion) => {
                  if (
                    typeof machineInfoQuestionToAnswer[
                      machineInfoQuestion.id
                    ] === "undefined"
                  ) {
                    return undefined;
                  }
                  if (!editable) {
                    return (
                      <MachineInfoProductCard
                        key={machineInfoQuestion.id}
                        machineInfoQuestion={machineInfoQuestion}
                        machineInfoAnswer={
                          machineInfoQuestionToAnswer[machineInfoQuestion.id]
                        }
                      />
                    );
                  }
                  return (
                    <DialogContentMachineInfoAnswer
                      key={machineInfoQuestion.id}
                      machine={machine}
                      machineInfoQuestion={machineInfoQuestion}
                      machineInfoAnswer={
                        machineInfoQuestionToAnswer[machineInfoQuestion.id]
                      }
                    />
                  );
                }
              )}
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickClose} variant="secondary">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    info: {
      color: palette.secondary.main,
    },
  });

export default withStyles(styles)(DialogMachineInfo);
