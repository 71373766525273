import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  CustomerType,
  Mutation,
  MutationDeleteCustomerArgs,
  MutationUpdateCustomerParentArgs,
} from "../../entity/types";
import DialogContentCustomer from "./DialogContentCustomer";
import { Button, Col, Row } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import {
  DELETE_CUSTOMER_MUTATION,
  UPDATE_CUSTOMER_PARENT_MUTATION,
} from "../../apollo/mutations/customers";
import { handleError } from "../../entity/ErrorHandler";
import { useTranslation } from "react-i18next";
import {
  faPhone,
  faSitemap,
  faStar,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DialogCustomerSub from "./DialogCustomerSub";
import CustomerShowBasicFamily from "./CustomerShowBasicFamily";
import { useHistory } from "react-router-dom";
import DialogCustomerEvent from "./DialogCustomerEvent";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { dialogConfirm } from "../../utils/dialogs";
import { URL_CUSTOMERS } from "../../utils/urls";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import DialogCustomerParent from "./DialogCustomerParent";
import { getQueryKey } from "../../utils/cache";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerType>>;
}

const CustomerShowBasic = ({ classes, customer, setCustomer }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [openCustomerSub, setOpenCustomerSub] = useState(false);
  const [openCustomerEvent, setOpenCustomerEvent] = useState(false);
  const [openDialogCustomerParent, setOpenDialogCustomerParent] =
    useState(false);

  const [updateCustomerParent] = useMutation<
    Mutation,
    MutationUpdateCustomerParentArgs
  >(UPDATE_CUSTOMER_PARENT_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customer"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customers"),
      });
    },
  });
  const [deleteCustomer, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteCustomerArgs
  >(DELETE_CUSTOMER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: () => {
      history.push(URL_CUSTOMERS);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customers"),
      });
    },
  });

  const handleClickCustomerSub = () => {
    setOpenCustomerSub(true);
  };

  const handleClickCustomerDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCustomer({ variables: { customerId: customer.id } });
    });
  };

  const handleClickCustomerEvent = () => {
    setOpenCustomerEvent(true);
  };

  const handleClickChildToParent = () => {
    updateCustomerParent({
      variables: {
        customerId: customer.id,
        customerIdParent: ID_EMPTY,
      },
    });
  };
  const handleClickParentToChild = () => {
    setOpenDialogCustomerParent(true);
  };

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionCustomerAdd = checkPermission(myPermissions, [
    "customers.add_customer",
  ]);
  const hasPermissionCustomerEdit = checkPermission(myPermissions, [
    "customers.change_customer",
  ]);
  const hasPermissionCustomerDelete = checkPermission(myPermissions, [
    "customers.delete_customer",
  ]);
  const hasPermissionEventAdd = checkPermission(myPermissions, [
    "events.add_event",
  ]);

  const isChildCustomer =
    customer.customerParent && customer.customerParent.id !== ID_EMPTY;
  const hasChildren = customer.customersChildren.length > 0;

  return (
    <Row>
      <Col>
        <DialogContentCustomer customer={customer} setCustomer={setCustomer} />
      </Col>
      <Col>
        <br />
        <div className="buttons">
          {hasPermissionCustomerAdd && !isChildCustomer && (
            <Button variant="primary" onClick={handleClickCustomerSub}>
              <FontAwesomeIcon icon={faSitemap} />
              <span className="ms-2">{t("add_sub_customer")}</span>
            </Button>
          )}
          {hasPermissionCustomerEdit && isChildCustomer && (
            <Button variant="primary" onClick={handleClickChildToParent}>
              <FontAwesomeIcon icon={faStar} />
              <span className="ms-2">{t("convert_to_parent_customer")}</span>
            </Button>
          )}
          {hasPermissionCustomerEdit && !isChildCustomer && (
            <>
              <Button
                variant="primary"
                onClick={handleClickParentToChild}
                disabled={hasChildren}
              >
                <FontAwesomeIcon icon={faSitemap} />
                <span className="ms-2">{t("convert_to_child_customer")}</span>
              </Button>
              {openDialogCustomerParent && (
                <DialogCustomerParent
                  customer={customer}
                  open={openDialogCustomerParent}
                  setOpen={setOpenDialogCustomerParent}
                />
              )}
            </>
          )}
          {hasPermissionEventAdd && (
            <Button
              variant="primary"
              onClick={handleClickCustomerEvent}
              disabled={customer.blockedAt}
            >
              <FontAwesomeIcon icon={faPhone} />
              <span className="ms-2">{t("add_customer_event")}</span>
            </Button>
          )}
          {hasPermissionCustomerDelete && (
            <ButtonLoad
              loading={loadingDelete}
              variant="light"
              onClick={handleClickCustomerDelete}
            >
              <FontAwesomeIcon icon={faTrash} />
              <span className="ms-2">{t("delete")}</span>
            </ButtonLoad>
          )}
        </div>
        <CustomerShowBasicFamily customer={customer} />
        <DialogCustomerSub
          open={openCustomerSub}
          setOpen={setOpenCustomerSub}
          customerParent={customer}
        />
        <DialogCustomerEvent
          open={openCustomerEvent}
          setOpen={setOpenCustomerEvent}
          customer={customer}
        />
      </Col>
    </Row>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CustomerShowBasic);
