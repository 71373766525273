import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import { DATE_FORMAT_ISO } from "../../utils/constants";
import format from "date-fns/format";
import { createTimeline, resetDateTime } from "../../utils/dates";
import { handleError } from "../../entity/ErrorHandler";
import {
  GET_RESERVATIONS_WITH_PRICE_QUERY,
  QueryResultReservationsWithPrice,
} from "../../apollo/queries/reservations";
import { QueryReservationsWithPriceArgs } from "../../entity/types";
import ReportDateFilter from "./ReportDateFilter";
import { reservationsByDateType } from "../../utils/reports/report";
import ReportDayContent from "./ReportDayContent";

interface Props extends WithStyles<typeof styles> {}

function ReportDay({ classes }: Props) {
  const dateFromDefault = resetDateTime(new Date());
  dateFromDefault.setDate(dateFromDefault.getDate() - 7);
  const dateToDefault = resetDateTime(new Date());

  const [dateFrom, setDateFrom] = useState(dateFromDefault);
  const [dateTo, setDateTo] = useState(dateToDefault);
  const [fetchRequests, setFetchRequests] = useState(0);

  const [
    getReservations,
    { data: dataReservations, loading: loadingReservations },
  ] = useLazyQuery<
    QueryResultReservationsWithPrice,
    QueryReservationsWithPriceArgs
  >(GET_RESERVATIONS_WITH_PRICE_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      dateFrom: format(dateFromDefault, DATE_FORMAT_ISO),
      dateTo: format(dateToDefault, DATE_FORMAT_ISO),
    },
    onError: (error) => {
      handleError(error);
    },
  });

  useEffect(() => {
    if (fetchRequests) {
      getReservations({
        variables: {
          dateFrom: format(dateFrom, DATE_FORMAT_ISO),
          dateTo: format(dateTo, DATE_FORMAT_ISO),
        },
      });
      setFetchRequests(0);
    }
  }, [fetchRequests, dateFrom, dateTo, getReservations]);

  const fetchReservations = (dateFrom: Date, dateTo: Date) => {
    setDateFrom(dateFrom);
    setDateTo(dateTo);
    setFetchRequests(fetchRequests + 1);
  };

  let reservationsByDate: reservationsByDateType = {};

  const timeline = createTimeline(dateFrom, dateTo);
  dataReservations?.reservationsWithPrice?.forEach((reservationWithPrice) => {
    timeline.forEach((date, i) => {
      let keyDate = format(date, DATE_FORMAT_ISO);
      if (typeof reservationsByDate[keyDate] === "undefined") {
        reservationsByDate[keyDate] = [];
      }
      if (
        keyDate >= reservationWithPrice.dateStart &&
        keyDate <= reservationWithPrice.dateEnd &&
        !reservationWithPrice.datesSkipped.includes(keyDate)
      ) {
        reservationsByDate[keyDate].push(reservationWithPrice);
      }
    });
  });

  return (
    <>
      <ReportDateFilter
        fetchData={fetchReservations}
        loadingData={loadingReservations}
        dateFromDefault={dateFrom}
        dateToDefault={dateTo}
      />
      {Object.keys(reservationsByDate).map((date) => {
        return (
          <ReportDayContent
            key={date}
            date={date}
            reservationsByDate={reservationsByDate[date]}
          />
        );
      })}
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ReportDay);
