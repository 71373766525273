import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  Paper,
  Popper,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { MachineType, ReservationType } from "../../entity/types";
import { getReservationProductName } from "../../utils/reservations/reservation";
import { Button, Table } from "react-bootstrap";
import format from "date-fns/format";
import { DATE_FORMAT_ISO } from "../../utils/constants";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { newDate } from "../../utils/dates";
import { parseNumber } from "../../utils/formatting";
import TextFieldFocus from "../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  reservations: ReservationType[];
  dateSelectionStart: Date | null;
  setDateSelectionEnd: React.Dispatch<React.SetStateAction<Date | null>>;
  machine: MachineType;
  bulkAmount: number;
  setBulkAmount: React.Dispatch<React.SetStateAction<number>>;
}

function PopoverCalendarDateReturned({
  classes,
  anchorEl,
  setAnchorEl,
  reservations,
  dateSelectionStart,
  setDateSelectionEnd,
  machine,
  bulkAmount,
  setBulkAmount,
}: Props) {
  const { t } = useTranslation();

  const [date, setDate] = useState("");

  const reservationsFiltered = reservations.filter(
    (reservation) =>
      !dateSelectionStart ||
      newDate(reservation.dateReturned) > dateSelectionStart
  );

  const onClickSetDate = (dateSelected: Date | null | string) => {
    if (dateSelected instanceof Date) {
      setDate(format(dateSelected, DATE_FORMAT_ISO));
      setDateSelectionEnd(dateSelected);
      setAnchorEl(null);
    } else {
      setDate("");
    }
  };

  return (
    <Popper
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      className={classes.popper}
    >
      <Paper className="p-3 pt-4">
        {reservationsFiltered.length > 0 && (
          <Table borderless className="table-hover">
            <thead>
              <tr>
                <td className="text-muted" colSpan={2}>
                  {t("select_end_date_from_reservation")}
                </td>
              </tr>
            </thead>
            <tbody>
              {reservationsFiltered.map((reservation) => (
                <tr
                  key={reservation.id}
                  className="cursor-pointer"
                  onClick={() =>
                    onClickSetDate(newDate(reservation.dateReturned))
                  }
                >
                  <td>
                    {format(
                      newDate(reservation.dateReturned),
                      t("format_date")
                    )}
                  </td>
                  <td>{getReservationProductName(t, reservation, true)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <div>
          <FormControl fullWidth>
            <TextField
              label={t("date_end")}
              type="date"
              value={date}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: dateSelectionStart
                  ? format(dateSelectionStart, DATE_FORMAT_ISO)
                  : null,
              }}
              onChange={(event) => {
                onClickSetDate(
                  event.target.value === "" ? "" : newDate(event.target.value)
                );
              }}
            />
          </FormControl>
          <Button
            className="mt-1"
            variant="light"
            onClick={() => onClickSetDate(dateSelectionStart)}
          >
            {t("same_date_as_start")}
          </Button>
        </div>
        {machine.bulkProduct && (
          <div className="mt-3">
            <FormControl fullWidth>
              <TextFieldFocus
                label={t("bulk_amount")}
                type="number"
                value={bulkAmount}
                onChange={(event) => {
                  setBulkAmount(parseNumber(event.target.value));
                }}
              />
            </FormControl>
          </div>
        )}
        <IconButton
          size="small"
          aria-label={t("close")}
          className={classes.closeButton}
          onClick={() => setAnchorEl(null)}
        >
          <CloseIcon />
        </IconButton>
      </Paper>
    </Popper>
  );
}

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    optionReservation: {
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
    },
    popper: {
      zIndex: 1000,
      position: "relative",
    },
    closeButton: {
      position: "absolute",
      right: spacing(0.4),
      top: spacing(0.4),
      color: palette.grey[500],
    },
  });

export default withStyles(styles)(PopoverCalendarDateReturned);
