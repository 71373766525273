import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Chip,
  createStyles,
  FormControl,
  MenuList,
  MenuItem,
  Popover,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  MachineInfoQuestionType,
  MachineInfoTagType,
  Mutation,
  MutationAddMachineInfoTagToQuestionArgs,
  MutationCreateMachineInfoTagArgs,
  MutationDeleteMachineInfoTagArgs,
  MutationRemoveMachineInfoTagFromQuestionArgs,
  MutationUpdateMachineInfoTagArgs,
} from "../../entity/types";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faCheck,
  faEdit,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import {
  ADD_MACHINE_INFO_TAG_TO_QUESTION_MUTATION,
  CREATE_MACHINE_INFO_TAG_MUTATION,
  DELETE_MACHINE_INFO_TAG_MUTATION,
  REMOVE_MACHINE_INFO_TAG_FROM_QUESTION_MUTATION,
  UPDATE_MACHINE_INFO_TAG_MUTATION,
} from "../../apollo/mutations/machines";
import { GET_MACHINE_INFO_CATEGORIES_QUERY } from "../../apollo/queries/machines";
import { handleError } from "../../entity/ErrorHandler";
import { MachineInfoTagEmpty } from "../../entity/empties";
import { dialogConfirm } from "../../utils/dialogs";
import { ID_EMPTY } from "../../utils/constants";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  machineInfoQuestion: MachineInfoQuestionType;
  machineInfoTags: MachineInfoTagType[];
}

function MachineInformationQuestionTags({
  classes,
  machineInfoQuestion,
  machineInfoTags,
}: Props) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tagNew, setTagNew] = useState("");
  const [machineInfoTagEdited, setMachineInfoTagEdited] =
    useState(MachineInfoTagEmpty);

  const [deleteMachineInfoTag, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteMachineInfoTagArgs
  >(DELETE_MACHINE_INFO_TAG_MUTATION, {
    refetchQueries: [{ query: GET_MACHINE_INFO_CATEGORIES_QUERY }],
    onCompleted: () => {
      setMachineInfoTagEdited(MachineInfoTagEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [createMachineInfoTag, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateMachineInfoTagArgs
  >(CREATE_MACHINE_INFO_TAG_MUTATION, {
    refetchQueries: [{ query: GET_MACHINE_INFO_CATEGORIES_QUERY }],
    onCompleted: () => {
      setTagNew("");
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [removeMachineInfoTagFromQuestion, { loading: loadingRemove }] =
    useMutation<Mutation, MutationRemoveMachineInfoTagFromQuestionArgs>(
      REMOVE_MACHINE_INFO_TAG_FROM_QUESTION_MUTATION,
      {
        refetchQueries: [{ query: GET_MACHINE_INFO_CATEGORIES_QUERY }],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const [addMachineInfoTagToQuestion, { loading: loadingAdd }] = useMutation<
    Mutation,
    MutationAddMachineInfoTagToQuestionArgs
  >(ADD_MACHINE_INFO_TAG_TO_QUESTION_MUTATION, {
    refetchQueries: [{ query: GET_MACHINE_INFO_CATEGORIES_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const [updateMachineInfoTag, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateMachineInfoTagArgs
  >(UPDATE_MACHINE_INFO_TAG_MUTATION, {
    refetchQueries: [{ query: GET_MACHINE_INFO_CATEGORIES_QUERY }],
    onCompleted: () => {
      setMachineInfoTagEdited(MachineInfoTagEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddTag = checkPermission(myPermissions, [
    "machines.add_machineinfotag",
  ]);
  const hasPermissionEditTag = checkPermission(myPermissions, [
    "machines.change_machineinfotag",
  ]);

  const onClickTags = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCreateTag = () => {
    createMachineInfoTag({
      variables: {
        machineInfoQuestionId: machineInfoQuestion.id,
        name: tagNew,
      },
    });
  };

  const handleRemoveTag = (machineInfoTag: MachineInfoTagType) => {
    removeMachineInfoTagFromQuestion({
      variables: {
        machineInfoQuestionId: machineInfoQuestion.id,
        machineInfoTagId: machineInfoTag.id,
      },
    });
  };

  const onClickMenuTag = (machineInfoTag: MachineInfoTagType) => {
    if (isTagSelected(machineInfoTag)) {
      handleRemoveTag(machineInfoTag);
    } else {
      addMachineInfoTagToQuestion({
        variables: {
          machineInfoQuestionId: machineInfoQuestion.id,
          machineInfoTagId: machineInfoTag.id,
        },
      });
    }
  };

  const onClickMenuTagEdit = (
    event: React.MouseEvent<HTMLElement>,
    machineInfoTag: MachineInfoTagType
  ) => {
    event.stopPropagation();
    setMachineInfoTagEdited(machineInfoTag);
  };

  const isTagSelected = (machineInfoTag: MachineInfoTagType) => {
    let has = false;
    machineInfoQuestion.machineinfotagSet.forEach((machineInfoTagLooped) => {
      if (machineInfoTagLooped.id === machineInfoTag.id) {
        has = true;
        return;
      }
    });
    return has;
  };

  const onClickTagDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteMachineInfoTag({
        variables: {
          machineInfoTagId: machineInfoTagEdited.id,
        },
      });
    });
  };

  const onClickTagSave = () => {
    updateMachineInfoTag({
      variables: {
        machineInfoTagId: machineInfoTagEdited.id,
        name: machineInfoTagEdited.name,
      },
    });
  };

  return (
    <td className={loadingAdd || loadingRemove ? "loading" : ""}>
      {machineInfoQuestion.machineinfotagSet.map((machineInfoTag) => (
        <Chip
          key={machineInfoTag.id}
          className="me-1 mb-1"
          label={machineInfoTag.name}
          onDelete={
            hasPermissionEditTag
              ? () => {
                  handleRemoveTag(machineInfoTag);
                }
              : undefined
          }
        />
      ))}
      {hasPermissionAddTag && (
        <>
          <Button variant="light" size="sm" onClick={onClickTags}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            <div className={classes.popContent}>
              {machineInfoTagEdited.id !== ID_EMPTY && (
                <div>
                  <FormControl fullWidth>
                    <TextField
                      label={t("name")}
                      value={machineInfoTagEdited.name}
                      autoFocus
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(event) => {
                        setMachineInfoTagEdited({
                          ...machineInfoTagEdited,
                          name: event.target.value,
                        });
                      }}
                    />
                  </FormControl>
                  <div className="buttons mt-3 mb-0">
                    <ButtonLoad
                      loading={loadingUpdate}
                      onClick={onClickTagSave}
                    >
                      {t("save")}
                    </ButtonLoad>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        setMachineInfoTagEdited(MachineInfoTagEmpty)
                      }
                    >
                      {t("cancel")}
                    </Button>
                    <ButtonLoad
                      loading={loadingDelete}
                      variant="light"
                      onClick={onClickTagDelete}
                    >
                      {t("delete")}
                    </ButtonLoad>
                  </div>
                </div>
              )}
              {machineInfoTagEdited.id === ID_EMPTY && (
                <>
                  <MenuList>
                    {machineInfoTags.map((machineInfoTag) => (
                      <MenuItem
                        key={machineInfoTag.id}
                        className={classes.tagMenuItem}
                        onClick={() => {
                          onClickMenuTag(machineInfoTag);
                        }}
                      >
                        {isTagSelected(machineInfoTag) && (
                          <FontAwesomeIcon className="me-2" icon={faCheck} />
                        )}
                        {machineInfoTag.name}
                        <Button
                          className={classes.btnMenuTagEdit}
                          variant="light"
                          onClick={(event) =>
                            onClickMenuTagEdit(event, machineInfoTag)
                          }
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </MenuItem>
                    ))}
                  </MenuList>
                  <FormControl fullWidth>
                    <TextField
                      label={t("new_tag")}
                      value={tagNew}
                      inputProps={{
                        maxLength: 100,
                      }}
                      InputProps={{
                        endAdornment: (
                          <ButtonLoad
                            loading={loadingCreate}
                            variant="link"
                            onClick={handleCreateTag}
                          >
                            <FontAwesomeIcon icon={faCaretRight} />
                          </ButtonLoad>
                        ),
                      }}
                      onChange={(event) => {
                        setTagNew(event.target.value);
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          handleCreateTag();
                        }
                      }}
                    />
                  </FormControl>
                </>
              )}
            </div>
          </Popover>
        </>
      )}
    </td>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    popContent: {
      padding: spacing(2),
    },
    tagMenuItem: {
      height: "40px",
    },
    btnMenuTagEdit: {
      position: "absolute",
      right: 0,
    },
  });

export default withStyles(styles)(MachineInformationQuestionTags);
