import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

const FRAG_CUSTOMER_SHARED = gql`
  fragment CustomerFields on CustomerType {
    id
    customerNumber
    name
    contactPersonFirstname
    contactPersonLastname
    visitAddress
    visitPostcode
    visitDistrict
    industry
    contactNext
    isBusiness
    creditInformationOk
    creditInformationCheckedAt
    creditRiskClass
  }
`;

export const FRAG_CUSTOMER_FULL = gql`
  fragment CustomerFullFields on CustomerType {
    id
    customerNumber
    businessId
    name
    contactPersonFirstname
    contactPersonLastname
    email
    phone
    visitAddress
    visitPostcode
    visitDistrict
    invoicingAddress
    invoicingDistrict
    invoicingEmail
    invoicingPostcode
    invoicingEAddress
    invoicingEOperator
    industry
    contactNext
    isBusiness
    creditInformationOk
    creditInformationCheckedAt
    creditRiskClass
    blockedAt
    blockedReason
    preInvoicingAt
    preInvoicingReason
    hasInsurance
    useParentInvoicing
    useParentPricing
    handledInvoicingAt
    userOwner {
      id
    }
    customerParent {
      id
    }
    discountListSelected {
      id
    }
    catalogSelected {
      id
    }
    createdBy {
      id
    }
    customersDiscountLists {
      id
    }
    customersChildren {
      id
    }
    referenceSet {
      id
    }
    orderSet {
      id
    }
    createdAt
  }
`;

export const FRAG_CUSTOMER_CALENDAR = gql`
  fragment CustomerCalendarFields on CustomerType {
    id
    isBusiness
    name
    contactPersonFirstname
    contactPersonLastname
    blockedAt
    hasInsurance
    creditInformationOk
    preInvoicingAt
  }
`;

export const FRAG_CUSTOMER_CALENDAR_ORDER = gql`
  fragment CustomerCalendarOrderFields on CustomerType {
    id
    name
    contactPersonFirstname
    contactPersonLastname
    businessId
    email
    isBusiness
    blockedAt
    creditInformationOk
    preInvoicingAt
    discountListSelected {
      id
      name
    }
    catalogSelected {
      id
      name
    }
  }
`;

export interface QueryResultCustomersForList {
  customers: Query["customers"];
}
export const GET_CUSTOMERS_FOR_LIST_QUERY = gql`
  query getCustomersForList {
    customers {
      id
      customerNumber
      name
      contactPersonFirstname
      contactPersonLastname
      visitAddress
      visitPostcode
      visitDistrict
      contactNext
      creditInformationOk
      creditInformationCheckedAt
      creditRiskClass
      userOwner {
        id
        firstName
        lastName
      }
      customerParent {
        id
      }
      customersChildren {
        id
      }
    }
  }
`;

export interface QueryResultCustomersDialogSelect {
  customers: Query["customers"];
}
export const GET_CUSTOMERS_DIALOG_SELECT_QUERY = gql`
  ${FRAG_CUSTOMER_CALENDAR}
  query getCustomersDialogSelect {
    customers {
      ...CustomerCalendarFields
    }
  }
`;

export interface QueryResultCustomer {
  customer: Query["customer"];
}
export const GET_CUSTOMER_QUERY = gql`
  ${FRAG_CUSTOMER_SHARED}
  query getCustomer($id: ID!) {
    customer(id: $id) {
      ...CustomerFields
      businessId
      email
      phone
      invoicingAddress
      invoicingDistrict
      invoicingEmail
      invoicingPostcode
      invoicingEAddress
      invoicingEOperator
      blockedAt
      blockedReason
      preInvoicingAt
      preInvoicingReason
      hasInsurance
      useParentInvoicing
      useParentPricing
      userOwner {
        id
        firstName
        lastName
      }
      discountListSelected {
        id
        name
        locked
        isOffer
        isBusiness
        discountrowSet {
          id
          percent
          showPrint
          catalogRow {
            id
            catalogCategory {
              id
              catalogCategoryUpper {
                id
                catalog {
                  id
                }
              }
            }
          }
        }
        catalog {
          id
          catalogcategoryupperSet {
            id
            name
            catalogcategorySet {
              id
              name
              catalogrowSet {
                id
                name
                priceDayCompany
                priceMonthCompany
                priceDayPrivate
                priceMonthPrivate
              }
            }
          }
        }
      }
      catalogSelected {
        id
        name
      }
      customerParent {
        id
        name
        contactPersonLastname
        contactPersonFirstname
        userOwner {
          id
          lastName
          firstName
        }
        contactNext
        customersChildren {
          id
          name
          contactPersonLastname
          contactPersonFirstname
          userOwner {
            id
            lastName
            firstName
          }
          contactNext
        }
        useParentInvoicing
        catalogSelected {
          id
        }
        discountListSelected {
          id
        }
      }
      customersChildren {
        id
        name
        contactPersonLastname
        contactPersonFirstname
        userOwner {
          id
          lastName
          firstName
        }
        contactNext
      }
      orderSet {
        id
        reference {
          id
          name
        }
        status
        reservationSet {
          id
          machine {
            id
            information
            identifier
            machineModel {
              id
              title
              machineMake {
                id
                title
              }
            }
          }
          catalogRow {
            id
            name
          }
          dateRented
          dateReturned
          giveAt
          returnAt
          createdAt
        }
        createdAt
      }
      createdAt
      createdBy {
        id
        email
      }
    }
  }
`;

export interface QueryResultCustomerPriceForCatalogRow {
  customerPriceForCatalogRow: Query["customerPriceForCatalogRow"];
}
export const GET_CUSTOMER_PRICE_FOR_CATALOG_ROW_QUERY = gql`
  query getCustomerPriceForCatalogRow($customerId: ID!, $catalogRowId: ID!) {
    customerPriceForCatalogRow(
      customerId: $customerId
      catalogRowId: $catalogRowId
    )
  }
`;

export interface QueryResultCustomersPrimary {
  customersPrimary: Query["customersPrimary"];
}
export const GET_CUSTOMERS_PRIMARY_QUERY = gql`
  query getCustomersPrimary {
    customersPrimary {
      id
      businessId
      name
    }
  }
`;

export interface QueryResultCustomersCalendar {
  customers: Query["customers"];
}
export const GET_CUSTOMERS_CALENDAR_QUERY = gql`
  ${FRAG_CUSTOMER_CALENDAR}
  query getCustomersCalendar {
    customers {
      ...CustomerCalendarFields
    }
  }
`;

export interface QueryResultCustomersOpenReservations {
  customersOpenReservations: Query["customersOpenReservations"];
}
export const GET_CUSTOMERS_OPEN_RESERVATIONS_QUERY = gql`
  query getCustomersOpenReservations(
    $locationIds: [ID]!
    $onlyEndedReservations: Boolean!
    $dateHandled: Date
  ) {
    customersOpenReservations(
      locationIds: $locationIds
      onlyEndedReservations: $onlyEndedReservations
      dateHandled: $dateHandled
    ) {
      id
      name
      handledInvoicingAt
    }
  }
`;
