export const getHostURL = (): string => window.location.origin;

/**
 * Check if current host is localhost (for development customized purpose)
 */
export const isDevEnv = (): boolean => {
  const hostURL = getHostURL();
  return (
    hostURL.startsWith('http://localhost') ||
    hostURL.startsWith('http://127.0.0.1') ||
    hostURL.includes('ngrok.io') ||
    hostURL.includes('localtunnel')
  );
};

export const api_base : String = isDevEnv() ? 'http://localhost:8000' : window.location.origin
export const faker_base : String = 'http://localhost:9002';

