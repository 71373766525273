import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Paper, Theme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { useQuery } from "@apollo/client";
import { QueryMachinesWithoutCatalogRowArgs } from "../../entity/types";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import Error from "../Shared/Error";
import {
  GET_MACHINES_WITHOUT_CATALOG_ROW_QUERY,
  queryResultMachinesWithoutCatalogRow,
} from "../../apollo/queries/machines";
import LoadingSimple from "../Shared/LoadingSimple";
import { Table } from "react-bootstrap";
import { getMachineName } from "../../utils/machines/machine";

interface Props extends WithStyles<typeof styles> {
  catalogId: string;
}

function MachinesWithoutCatalogRow({ classes, catalogId }: Props) {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(true);

  const { loading, error, data } = useQuery<
    queryResultMachinesWithoutCatalogRow,
    QueryMachinesWithoutCatalogRowArgs
  >(GET_MACHINES_WITHOUT_CATALOG_ROW_QUERY, {
    fetchPolicy: getQueryFetchPolicy("machinesWithoutCatalogRow"),
    variables: {
      catalogId: catalogId,
    },
  });

  useEffect(() => {
    setVisible(true);
  }, [data]);

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  if (
    !data.machinesWithoutCatalogRow ||
    data.machinesWithoutCatalogRow.length === 0
  ) {
    return null;
  }

  return (
    <Paper className={`${classes.floater} ${!visible ? "d-none" : ""} p-2`}>
      <Table>
        <thead>
          <tr>
            <th colSpan={2}>
              <div className="d-inline-block pt-3">
                {t("machines_without_catalog_row")}
              </div>
              <IconButton
                aria-label={t("close")}
                className="float-end"
                onClick={() => setVisible(false)}
              >
                <CloseIcon />
              </IconButton>
            </th>
          </tr>
        </thead>
        {data.machinesWithoutCatalogRow && (
          <tbody>
            {data.machinesWithoutCatalogRow.map((machine) => (
              <tr key={machine.id}>
                <td>{machine.identifier}</td>
                <td>{getMachineName(machine)}</td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
    </Paper>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    floater: {
      position: "fixed",
      top: "5vh",
      right: "5vw",
      maxHeight: "25vh",
      overflow: "scroll",
      maxWidth: "75vw",
    },
  });

export default withStyles(styles)(MachinesWithoutCatalogRow);
