import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import InvoicesOpenList from "../components/Invoice/InvoicesOpenList";
import HeaderPage from "../components/Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {}

function ManagementInvoicesOpen({ classes }: Props) {
  const { t } = useTranslation();

  return (
    <Container className="containerMain">
      <HeaderPage title={t("management_invoices_open")} />
      <div className="containerInner">
        <InvoicesOpenList />
      </div>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ManagementInvoicesOpen);
