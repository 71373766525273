import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, TextField, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import {
  CustomerType,
  Mutation,
  MutationUpdateOrderArgs,
  OrderType,
} from "../../entity/types";
import { CustomerEmpty } from "../../entity/empties";
import CalendarCustomerPrices from "./CalendarCustomerPrices";
import {
  CUSTOMER_ID_CATALOG_COMPANY,
  CUSTOMER_ID_CATALOG_PRIVATE,
  ID_EMPTY,
} from "../../utils/constants";
import {
  getCustomerCatalogCompany,
  getCustomerCatalogDefault,
  getCustomerCatalogPrivate,
} from "../../utils/customers/customer";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_CUSTOMERS_CALENDAR_QUERY,
  QueryResultCustomersCalendar,
} from "../../apollo/queries/customers";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { dialogConfirm } from "../../utils/dialogs";
import { UPDATE_ORDER_MUTATION } from "../../apollo/mutations/orders";
import { handleError } from "../../entity/ErrorHandler";
import { updateCacheOrder } from "../../utils/cache";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import CreateCustomer from "../Customer/CreateCustomer";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerType>>;
  catalogRowId: string;
  order: OrderType;
  canChangeCustomer: boolean;
}

function CalendarSelectCustomer({
  classes,
  customer,
  setCustomer,
  catalogRowId,
  order,
  canChangeCustomer,
}: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<QueryResultCustomersCalendar>(
    GET_CUSTOMERS_CALENDAR_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("customers"),
    }
  );

  const [updateOrder, { loading: loadingUpdateOrder }] = useMutation<
    Mutation,
    MutationUpdateOrderArgs
  >(UPDATE_ORDER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheOrder(cache);
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const optionsCustomers: CustomerType[] = [
    getCustomerCatalogPrivate(t),
    getCustomerCatalogCompany(t),
  ].concat(data.customers ? data.customers : []);

  const getLabel = (option: CustomerType) => {
    const contactPerson = (
      option.contactPersonLastname +
      " " +
      option.contactPersonFirstname
    ).trim();
    if (
      [CUSTOMER_ID_CATALOG_COMPANY, CUSTOMER_ID_CATALOG_PRIVATE].includes(
        option.id
      )
    ) {
      return option.name;
    }
    return (
      option.name +
      (contactPerson !== "" ? " - " + contactPerson : "") +
      (!option.creditInformationOk
        ? " | " + t("customer_credit_information_not_ok").toUpperCase()
        : "") +
      (option.preInvoicingAt ? " | " + t("pre_invoicing").toUpperCase() : "") +
      (option.blockedAt ? " | " + t("customer_blocked").toUpperCase() : "")
    );
  };

  const callbackCreateCustomer = (customerCreated: CustomerType) => {
    setCustomer(customerCreated);
  };

  const saveCustomer = (customerNew: CustomerType) => {
    if (order.id !== ID_EMPTY) {
      updateOrder({
        variables: {
          orderId: order.id,
          customerId: customerNew.id,
          referenceId: order.reference ? order.reference.id : ID_EMPTY,
          locationId: order.location.id,
          information: order.information,
          informationInvoice: order.informationInvoice,
          confirmationType: order.confirmationType,
          hasInsurance: order.hasInsurance,
          updatePricesInCatalogSwitch: order.updatePricesInCatalogSwitch,
        },
      });
    }
    setCustomer(customerNew);
  };

  return (
    <div id="conCalendarSelectCustomer">
      <div className="d-flex">
        <Autocomplete
          className={`mt-2 mb-2 flex-grow-1 ${
            loadingUpdateOrder ? "loading" : ""
          }`} /* Without top margin, label gets cut */
          options={optionsCustomers}
          filterOptions={(options, search) => {
            const keyWords = search.inputValue.split(" ");
            return options.filter((option) => {
              return keyWords.every((item) => {
                return getLabel(option)
                  .toLowerCase()
                  .includes(item.toLowerCase());
              });
            });
          }}
          getOptionLabel={(option: CustomerType) => getLabel(option)}
          renderInput={(params) => (
            <TextField {...params} label={t("customer")} variant="outlined" />
          )}
          value={customer.id !== ID_EMPTY ? customer : null}
          getOptionSelected={(a, b) => {
            return a.id === b.id;
          }}
          getOptionDisabled={(option) =>
            parseInt(option.id) > 0 && option.blockedAt !== null
          }
          onChange={(event, customerSelected) => {
            const customerNew = customerSelected
              ? customerSelected
              : CustomerEmpty;

            if (order.id !== ID_EMPTY) {
              if (customerNew.id === ID_EMPTY) {
                setCustomer(CustomerEmpty);
              } else if (order.customer && order.customer.id !== ID_EMPTY) {
                dialogConfirm(t, t("confirm_change_customer"), () => {
                  saveCustomer(customerNew);
                });
              } else {
                saveCustomer(customerNew);
              }
            } else {
              saveCustomer(customerNew);
            }
          }}
          onBlur={() => {
            if (order.id === ID_EMPTY) {
              if (customer.id === ID_EMPTY) {
                setCustomer(getCustomerCatalogDefault(t));
              }
            } else {
              if (order.customer && order.customer.id !== customer.id) {
                setCustomer(order.customer);
              }
            }
          }}
          disabled={!canChangeCustomer}
        />
        <div>
          <CreateCustomer
            btnClass="mt-1 ms-2 btnRound"
            order={order}
            callbackCreateCustomer={callbackCreateCustomer}
          />
        </div>
      </div>
      {catalogRowId !== ID_EMPTY && customer.id !== ID_EMPTY && (
        <CalendarCustomerPrices
          order={order}
          customer={customer}
          catalogRowId={catalogRowId}
          catalogExtraRowRental={undefined}
        />
      )}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CalendarSelectCustomer);
