import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  InspectionReturnAnswerType,
  InspectionReturnQuestionAnswerType,
  InspectionReturnQuestionType,
  Mutation,
  MutationUpdateInspectionReturnAnswerArgs,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { UPDATE_INSPECTION_RETURN_ANSWER_MUTATION } from "../../apollo/mutations/inspections";
import { handleError } from "../../entity/ErrorHandler";
import { InspectionReturnQuestionOptionEmpty } from "../../entity/empties";
import { useTranslation } from "react-i18next";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY } from "../../utils/constants";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionReturnAnswer: InspectionReturnAnswerType;
  inspectionReturnQuestion: InspectionReturnQuestionType;
}

function InspectionReturnAnswer({
  classes,
  inspectionReturnAnswer,
  inspectionReturnQuestion,
}: Props) {
  const { t } = useTranslation();

  const [inspectionReturnAnswerEdited, setInspectionReturnAnswerEdited] =
    useState(inspectionReturnAnswer);

  const [updateInspectionReturnAnswer, { loading: loadingUpdate }] =
    useMutation<Mutation, MutationUpdateInspectionReturnAnswerArgs>(
      UPDATE_INSPECTION_RETURN_ANSWER_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionChangeAnswer = checkPermission(myPermissions, [
    "inspections.change_inspectionreturnanswer",
  ]);

  const handleUpdateAnswer = (
    values: {
      [key: string]: string | number | undefined | boolean;
    } = {}
  ) => {
    let base: MutationUpdateInspectionReturnAnswerArgs = {
      inspectionReturnAnswerId: inspectionReturnAnswerEdited.id,
      inspectionReturnQuestionOptionId:
        inspectionReturnAnswerEdited.inspectionReturnQuestionOption
          ? inspectionReturnAnswerEdited.inspectionReturnQuestionOption.id
          : undefined,
      answer: inspectionReturnAnswerEdited.answer,
      check: inspectionReturnAnswerEdited.check,
    };

    updateInspectionReturnAnswer({
      variables: { ...base, ...values },
    });
  };

  return (
    <div className={loadingUpdate ? "loading" : ""}>
      <FormControl fullWidth className="mb-4">
        {inspectionReturnQuestion.answerType ===
          InspectionReturnQuestionAnswerType.Text && (
          <TextField
            label={inspectionReturnQuestion.question}
            value={inspectionReturnAnswerEdited.answer}
            required={true}
            inputProps={{
              maxLength: 200,
            }}
            disabled={!hasPermissionChangeAnswer}
            onChange={(event) => {
              setInspectionReturnAnswerEdited({
                ...inspectionReturnAnswerEdited,
                answer: event.target.value,
              });
            }}
            onBlur={() => {
              handleUpdateAnswer();
            }}
          />
        )}
        {inspectionReturnQuestion.answerType ===
          InspectionReturnQuestionAnswerType.Options && (
          <>
            <InputLabel id="lblDialogInspectionReturnQuestion">
              {inspectionReturnQuestion.question}
            </InputLabel>
            <Select
              fullWidth
              labelId="lblDialogInspectionReturnQuestion"
              disabled={!hasPermissionChangeAnswer}
              value={
                inspectionReturnAnswerEdited.inspectionReturnQuestionOption
                  ? inspectionReturnAnswerEdited.inspectionReturnQuestionOption
                      .id
                  : ID_EMPTY
              }
              required={true}
              onChange={(event) => {
                let inspectionReturnQuestionOptionId = String(
                  event.target.value
                );
                let inspectionReturnQuestionOption =
                  inspectionReturnAnswerEdited.inspectionReturnQuestionOption;
                if (!inspectionReturnQuestionOption) {
                  inspectionReturnQuestionOption =
                    InspectionReturnQuestionOptionEmpty;
                }

                setInspectionReturnAnswerEdited({
                  ...inspectionReturnAnswerEdited,
                  inspectionReturnQuestionOption: {
                    ...inspectionReturnQuestionOption,
                    id: inspectionReturnQuestionOptionId,
                  },
                });

                handleUpdateAnswer({
                  inspectionReturnQuestionOptionId:
                    inspectionReturnQuestionOptionId !== ID_EMPTY
                      ? inspectionReturnQuestionOptionId
                      : undefined,
                });
              }}
            >
              <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
              {inspectionReturnQuestion.inspectionreturnquestionoptionSet.map(
                (inspectionReturnQuestionOption) => (
                  <MenuItem
                    key={inspectionReturnQuestionOption.id}
                    value={inspectionReturnQuestionOption.id}
                  >
                    {inspectionReturnQuestionOption.title}
                  </MenuItem>
                )
              )}
            </Select>
          </>
        )}
        {inspectionReturnQuestion.answerType ===
          InspectionReturnQuestionAnswerType.Check && (
          <>
            <FormLabel>{inspectionReturnQuestion.question}</FormLabel>
            <RadioGroup
              aria-label={inspectionReturnQuestion.question}
              name="inspectionReturnAnswerCheck"
              value={
                inspectionReturnAnswer.check === true
                  ? "1"
                  : inspectionReturnAnswer.check === false
                  ? "0"
                  : ""
              }
              onChange={(event, value) => {
                const checkNew = value === "1";
                setInspectionReturnAnswerEdited({
                  ...inspectionReturnAnswerEdited,
                  check: checkNew,
                });
                handleUpdateAnswer({ check: checkNew });
              }}
            >
              <FormControlLabel
                value="1"
                label={t("check_ok")}
                control={<Radio />}
              />
              <FormControlLabel
                value="0"
                label={t("check_not_ok")}
                control={<Radio />}
              />
            </RadioGroup>
            {inspectionReturnAnswer.check === false && (
              <TextField
                value={inspectionReturnAnswerEdited.answer}
                required={true}
                inputProps={{
                  maxLength: 200,
                }}
                disabled={!hasPermissionChangeAnswer}
                onChange={(event) => {
                  setInspectionReturnAnswerEdited({
                    ...inspectionReturnAnswerEdited,
                    answer: event.target.value,
                  });
                }}
                onBlur={() => {
                  handleUpdateAnswer();
                }}
              />
            )}
          </>
        )}
      </FormControl>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InspectionReturnAnswer);
