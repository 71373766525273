import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { CatalogExtraCategoryUpperRentalType } from "../../entity/types";
import { Col, Row } from "react-bootstrap";
import CatalogExtraRentalSortCategoriesUpper from "./CatalogExtraRentalSortCategoriesUpper";
import CatalogExtraRentalSortCategories from "./CatalogExtraRentalSortCategories";
import CatalogExtraRentalSortRows from "./CatalogExtraRentalSortRows";

interface Props extends WithStyles<typeof styles> {
  catalogExtraCategoriesUpperRental: CatalogExtraCategoryUpperRentalType[];
  catalogExtraCategoryUpperRentalIdSelected: string;
  setCatalogExtraCategoryUpperRentalIdSelected: React.Dispatch<
    React.SetStateAction<string>
  >;
  catalogExtraCategoryRentalIdSelected: string;
  setCatalogExtraCategoryRentalIdSelected: React.Dispatch<
    React.SetStateAction<string>
  >;
}

function CatalogExtraRentalSort({
  classes,
  catalogExtraCategoriesUpperRental,
  catalogExtraCategoryUpperRentalIdSelected,
  setCatalogExtraCategoryUpperRentalIdSelected,
  catalogExtraCategoryRentalIdSelected,
  setCatalogExtraCategoryRentalIdSelected,
}: Props) {
  return (
    <Row>
      <Col className={classes.col} sm={12} md={4}>
        <CatalogExtraRentalSortCategoriesUpper
          catalogExtraCategoriesUpperRental={catalogExtraCategoriesUpperRental}
        />
      </Col>
      <Col className={classes.col} sm={12} md={4}>
        <CatalogExtraRentalSortCategories
          catalogExtraCategoriesUpperRental={catalogExtraCategoriesUpperRental}
          catalogExtraCategoryUpperRentalIdSelected={
            catalogExtraCategoryUpperRentalIdSelected
          }
          setCatalogExtraCategoryUpperRentalIdSelected={
            setCatalogExtraCategoryUpperRentalIdSelected
          }
        />
      </Col>
      <Col className={classes.col} sm={12} md={4}>
        <CatalogExtraRentalSortRows
          catalogExtraCategoriesUpperRental={catalogExtraCategoriesUpperRental}
          catalogExtraCategoryRentalIdSelected={
            catalogExtraCategoryRentalIdSelected
          }
          setCatalogExtraCategoryRentalIdSelected={
            setCatalogExtraCategoryRentalIdSelected
          }
        />
      </Col>
    </Row>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    col: {
      marginBottom: spacing(3),
      userSelect: "none",
    },
  });

export default withStyles(styles)(CatalogExtraRentalSort);
