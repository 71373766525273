import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  QueryInvoicingPriceForReservationArgs,
  ReservationType,
} from "../../entity/types";
import { useQuery } from "@apollo/client";
import Error from "../Shared/Error";
import {
  GET_INVOICING_PRICE_FOR_RESERVATION_QUERY,
  QueryResultInvoicingPriceForReservation,
} from "../../apollo/queries/reservations";
import { formatNumber } from "../../utils/formatting";
import LoadingSimple from "../Shared/LoadingSimple";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationType;
}

function CalendarCustomerPriceMin({ classes, reservation }: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultInvoicingPriceForReservation,
    QueryInvoicingPriceForReservationArgs
  >(GET_INVOICING_PRICE_FOR_RESERVATION_QUERY, {
    fetchPolicy: getQueryFetchPolicy("invoicingPriceForReservation"),
    variables: {
      reservationId: reservation.id,
      date: reservation.dateBillingMin,
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <div className={`${classes.con} ms-2`}>
      {data?.invoicingPriceForReservation && (
        <span>
          {data.invoicingPriceForReservation.isDayPrice
            ? t("billing_min_day", {
                days: data.invoicingPriceForReservation.days,
                amount: formatNumber(
                  Number(data.invoicingPriceForReservation.total),
                  2
                ),
              })
            : t("billing_min_month", {
                months: formatNumber(
                  Number(data.invoicingPriceForReservation.months),
                  2
                ),
                amount: formatNumber(
                  Number(data.invoicingPriceForReservation.total),
                  2
                ),
              })}
        </span>
      )}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    con: {
      minWidth: "5rem",
      paddingTop: "0.8rem",
    },
  });

export default withStyles(styles)(CalendarCustomerPriceMin);
