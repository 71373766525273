import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogCategoryType,
  CatalogCategoryUpperType,
  CatalogRowType,
  CatalogType,
  Mutation,
  MutationDeleteCatalogCategoryArgs,
  MutationUpdateCatalogRowOrderArgs,
} from "../../../entity/types";
import { Button } from "react-bootstrap";
import CatalogRow from "./CatalogRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsV,
  faEdit,
  faPaperclip,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import {
  DELETE_CATALOG_CATEGORY_MUTATION,
  UPDATE_CATALOG_ROW_ORDER_MUTATION,
} from "../../../apollo/mutations/catalogs";
import { handleError } from "../../../entity/ErrorHandler";
import DialogCatalogCategory from "../DialogCatalogCategory";
import DialogCatalogCategoryUpload from "../DialogCatalogCategoryUpload";
import { PermissionsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { dialogConfirm } from "../../../utils/dialogs";
import { isLocked } from "../../../utils/catalogs/locked";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { COLOR_MUTED, ROOT_QUERY } from "../../../utils/constants";
import { getQueryKey } from "../../../utils/cache";
import ButtonLoad from "../../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
  catalogCategoriesUpper: CatalogCategoryUpperType[];
  catalogCategory: CatalogCategoryType;
  DragHandle: any;
}

function CatalogCategory({
  classes,
  catalog,
  catalogCategoriesUpper,
  catalogCategory,
  DragHandle,
}: Props) {
  const { t } = useTranslation();

  const [deleteCatalogCategory, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteCatalogCategoryArgs
  >(DELETE_CATALOG_CATEGORY_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogs"),
      });
    },
  });

  const [updateCatalogRowOrder, { loading: loadingOrder }] = useMutation<
    Mutation,
    MutationUpdateCatalogRowOrderArgs
  >(UPDATE_CATALOG_ROW_ORDER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogs"),
      });
    },
  });

  const [openDialogCatalogCategory, setOpenDialogCatalogCategory] =
    useState(false);
  const [openDialogCatalogCategoryUpload, setOpenDialogCatalogCategoryUpload] =
    useState(false);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "catalogs.delete_catalogcategory",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs.change_catalogcategory",
  ]);

  const DragHandleRow = SortableHandle(() => (
    <th className={classes.tdSort}>
      <FontAwesomeIcon icon={faArrowsV} />
    </th>
  ));

  const CatalogRowContainer = SortableElement(({ catalogRow }: any) => (
    <CatalogRow
      key={catalogRow.id}
      catalog={catalog}
      catalogRow={catalogRow}
      catalogCategoriesUpper={catalogCategoriesUpper}
      DragHandle={DragHandleRow}
    />
  ));

  const CatalogRowsContainer = SortableContainer(({ items }: any) => {
    return (
      <tbody className={loadingOrder ? "loading" : ""}>
        {items.map((catalogRow: CatalogRowType, index: number) => (
          <CatalogRowContainer
            key={catalogRow.id}
            index={index}
            catalogRow={catalogRow}
          />
        ))}
      </tbody>
    );
  });

  const handleClickCategoryDelete = (catalogCategoryId: string) => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCatalogCategory({
        variables: { catalogCategoryId: catalogCategoryId },
      });
    });
  };
  const handleClickCategoryInfo = (catalogCategory: CatalogCategoryType) => {
    setOpenDialogCatalogCategory(true);
  };
  const handleClickCategoryUpload = (catalogCategory: CatalogCategoryType) => {
    setOpenDialogCatalogCategoryUpload(true);
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    let idToIndex: Array<any> = [];
    arrayMove(catalogCategory.catalogrowSet, oldIndex, newIndex).map(
      (catalogRowLooped: CatalogRowType, index) => {
        idToIndex.push({
          id: catalogRowLooped.id,
          order: index,
        });
        return null;
      }
    );
    updateCatalogRowOrder({ variables: { idOrderInput: idToIndex } });
  };

  return (
    <>
      <thead>
        <tr>
          {hasPermissionEdit ? <DragHandle /> : <th></th>}
          <th>
            <span className={classes.spnCatalogCategory}>
              {catalogCategory.name}
            </span>
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          {!isLocked(catalog) && <th></th>}
          <th>
            {hasPermissionEdit && (
              <Button
                variant="light"
                size="sm"
                className={`${classes.btn} ${
                  catalogCategory.information
                    ? classes.btnHasData
                    : classes.btnNoData
                }`}
                onClick={() => handleClickCategoryInfo(catalogCategory)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
            {hasPermissionEdit && (
              <Button
                variant="light"
                size="sm"
                className={`${classes.btn} ${
                  catalogCategory.image ? classes.btnHasData : classes.btnNoData
                }`}
                onClick={() => handleClickCategoryUpload(catalogCategory)}
              >
                <FontAwesomeIcon icon={faPaperclip} />
              </Button>
            )}
            {hasPermissionDelete && (
              <ButtonLoad
                loading={loadingDelete}
                variant="light"
                size="sm"
                className={classes.btn}
                onClick={() => handleClickCategoryDelete(catalogCategory.id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </ButtonLoad>
            )}
            <DialogCatalogCategory
              open={openDialogCatalogCategory}
              setOpen={setOpenDialogCatalogCategory}
              catalogCategoryOrig={catalogCategory}
              catalogCategoriesUpper={catalogCategoriesUpper}
            />
            <DialogCatalogCategoryUpload
              open={openDialogCatalogCategoryUpload}
              setOpen={setOpenDialogCatalogCategoryUpload}
              catalogCategory={catalogCategory}
            />
          </th>
        </tr>
      </thead>
      <CatalogRowsContainer
        items={catalogCategory.catalogrowSet}
        onSortEnd={onSortEnd}
        useDragHandle
      />
    </>
  );
}

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    theadCategory: {
      "& $thSort": {
        paddingLeft: spacing(6),
      },
    },
    thSort: {
      cursor: "move",
    },
    tdSort: {
      cursor: "move",
      textAlign: "right",
    },
    spnCatalogCategory: {
      color: palette.primary.main,
      marginRight: spacing(2),
    },
    btn: {
      marginLeft: spacing(0.5),
    },
    btnHasData: {},
    btnNoData: {
      color: COLOR_MUTED,
    },
  });

export default withStyles(styles)(CatalogCategory);
