import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogCategoryType,
  CatalogCategoryUpperType,
  CatalogType,
  Mutation,
  MutationDeleteCatalogCategoryUpperArgs,
  MutationUpdateCatalogCategoryOrderArgs,
} from "../../../entity/types";
import { Button, Table } from "react-bootstrap";
import { PermissionsContext, SettingsContext } from "../../../Root";
import { formatNumber } from "../../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsV, faEdit, faTrash } from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import {
  DELETE_CATALOG_CATEGORY_UPPER_MUTATION,
  UPDATE_CATALOG_CATEGORY_ORDER_MUTATION,
} from "../../../apollo/mutations/catalogs";
import { handleError } from "../../../entity/ErrorHandler";
import CatalogCategory from "./CatalogCategory";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import Loading from "../../Shared/Loading";
import { checkPermission } from "../../../utils/permissions";
import DialogCatalogCategoryUpper from "../DialogCatalogCategoryUpper";
import { dialogConfirm } from "../../../utils/dialogs";
import { isLocked } from "../../../utils/catalogs/locked";
import { ROOT_QUERY } from "../../../utils/constants";
import { getQueryKey } from "../../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  catalogCategoriesUpper: CatalogCategoryUpperType[];
  catalogCategoryUpper: CatalogCategoryUpperType;
  DragHandleCategoryUpper: any;
  catalog: CatalogType;
}

function CatalogCategoryUpper({
  classes,
  catalogCategoriesUpper,
  catalogCategoryUpper,
  DragHandleCategoryUpper,
  catalog,
}: Props) {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);
  const [openDialogCatalogCategoryUpper, setOpenDialogCatalogCategoryUpper] =
    useState(false);

  const [deleteCatalogCategoryUpper, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteCatalogCategoryUpperArgs
  >(DELETE_CATALOG_CATEGORY_UPPER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogs"),
      });
    },
  });

  const [updateCatalogCategoryOrder, { loading: loadingOrder }] = useMutation<
    Mutation,
    MutationUpdateCatalogCategoryOrderArgs
  >(UPDATE_CATALOG_CATEGORY_ORDER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogs"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "catalogs.delete_catalogcategoryupper",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs.change_catalogcategoryupper",
  ]);

  if (loadingDelete || loadingOrder) return <Loading />;

  const handleEditCategoryUpper = () => {
    setOpenDialogCatalogCategoryUpper(true);
  };

  const handleDeleteCategoryUpper = (catalogCategoryUpperId: string) => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCatalogCategoryUpper({
        variables: { catalogCategoryUpperId: catalogCategoryUpperId },
      });
    });
  };

  const onSortStart = ({ node }: any) => {
    // I would like to use a state here, but that causes an "_this.helper.parentNode is null" error
    node.closest(".tblCatalog").classList.add("sortingCategory");
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    let elements = document.getElementsByClassName("tblCatalog");
    Array.from(elements).forEach((element) => {
      element.classList.remove("sortingCategory");
    });

    let idToIndex: Array<any> = [];
    arrayMove(catalogCategoryUpper.catalogcategorySet, oldIndex, newIndex).map(
      (catalogCategory: CatalogCategoryType, index) => {
        idToIndex.push({
          id: catalogCategory.id,
          order: index,
        });
        return null;
      }
    );
    updateCatalogCategoryOrder({ variables: { idOrderInput: idToIndex } });
  };

  const DragHandleCategory = SortableHandle(() => (
    <th className={classes.thSort}>
      <FontAwesomeIcon icon={faArrowsV} />
    </th>
  ));

  const CatalogCategoryContainer = SortableElement(
    ({ catalogCategory }: any) => (
      <CatalogCategory
        catalog={catalog}
        catalogCategory={catalogCategory}
        catalogCategoriesUpper={catalogCategoriesUpper}
        DragHandle={DragHandleCategory}
      />
    )
  );

  const TableCategoriesContainer = SortableContainer(({ items }: any) => {
    return (
      <Table className={`tblCatalog hideOnCategoryUpperSort`} borderless hover>
        {items.map((catalogCategory: CatalogCategoryType, index: number) => (
          <CatalogCategoryContainer
            key={catalogCategory.id}
            index={index}
            catalogCategory={catalogCategory}
          />
        ))}
      </Table>
    );
  });

  return (
    <>
      <Table className={`tblCatalog`} borderless hover>
        <thead className={classes.thead}>
          <tr>
            {hasPermissionEdit ? <DragHandleCategoryUpper /> : <th></th>}
            <th className={classes.thTitle}>{catalogCategoryUpper.name}</th>
            <th className="hideOnCategoryUpperSort">
              {t("price_day_company_vat0")}
            </th>
            <th className="hideOnCategoryUpperSort">
              {t("price_day_company_vat1", {
                vat: formatNumber(settings.vat),
              })}
            </th>
            <th className="hideOnCategoryUpperSort">
              {t("price_month_company_vat0")}
            </th>
            <th className="hideOnCategoryUpperSort">
              {t("price_month_company_vat1", {
                vat: formatNumber(settings.vat),
              })}
            </th>
            <th className="hideOnCategoryUpperSort">
              {t("billing_days_week_company")}
            </th>
            <th className="hideOnCategoryUpperSort"></th>
            <th className="hideOnCategoryUpperSort">
              {t("price_day_private_vat0")}
            </th>
            <th className="hideOnCategoryUpperSort">
              {t("price_day_private_vat1", {
                vat: formatNumber(settings.vat),
              })}
            </th>
            <th className="hideOnCategoryUpperSort">
              {t("price_month_private_vat0")}
            </th>
            <th className="hideOnCategoryUpperSort">
              {t("price_month_private_vat1", {
                vat: formatNumber(settings.vat),
              })}
            </th>
            <th className="hideOnCategoryUpperSort">
              {t("billing_days_week_private")}
            </th>
            {!isLocked(catalog) && <th>{t("copy_discounts_from")}</th>}
            <th className="hideOnCategoryUpperSort">
              {hasPermissionEdit && (
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => handleEditCategoryUpper()}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              )}
              {hasPermissionDelete && (
                <Button
                  variant="light"
                  size="sm"
                  onClick={() =>
                    handleDeleteCategoryUpper(catalogCategoryUpper.id)
                  }
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
              <DialogCatalogCategoryUpper
                open={openDialogCatalogCategoryUpper}
                setOpen={setOpenDialogCatalogCategoryUpper}
                catalog={catalog}
                catalogCategoryUpperOrig={catalogCategoryUpper}
              />
            </th>
          </tr>
        </thead>
      </Table>
      <TableCategoriesContainer
        items={catalogCategoryUpper.catalogcategorySet}
        onSortStart={onSortStart}
        onSortEnd={onSortEnd}
        helperClass={classes.sorted}
        useDragHandle
      />
    </>
  );
}

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    thead: {
      "& $thTitle": {
        paddingLeft: 0,
      },
    },
    sorted: {
      backgroundColor: "rgba(0, 0, 0, 0.1)",

      "& $thSort": {
        paddingLeft: spacing(7),
      },
      "& th": {
        paddingRight: spacing(2),
      },
    },
    thSort: {
      cursor: "move",
      textAlign: "right",
    },
    thTitle: {
      color: palette.primary.main,
      fontSize: "1.2rem",
    },
  });

export default withStyles(styles)(CatalogCategoryUpper);
