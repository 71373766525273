import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ApolloError } from "@apollo/client";

interface Props extends WithStyles<typeof styles> {
  error: ApolloError | string;
}

const Error = ({ classes, error }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const errorMsg = error instanceof ApolloError ? error.message : error;

  return (
    <Snackbar
      open={open}
      className={classes.snackbar}
      message={errorMsg}
      action={
        <Button onClick={() => setOpen(false)} color="secondary" size="small">
          {t("close")}
        </Button>
      }
    />
  );
};

const styles = ({ spacing }: Theme) =>
  createStyles({
    snackbar: {
      margin: spacing(1),
    },
  });

export default withStyles(styles)(Error);
