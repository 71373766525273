import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  Select,
  Theme,
} from "@material-ui/core";
import {
  CatalogExtraCategoryRentalType,
  CatalogExtraCategoryUpperRentalType,
} from "../../../entity/types";
import CatalogExtraRentalTable from "./CatalogExtraRentalTable";
import CatalogExtraCategoryUpperRentalTable from "./CatalogExtraCategoryUpperRentalTable";
import { Col, Row } from "react-bootstrap";
import LocationFilter from "../../Shared/LocationFilter";
import { renderSelectGroup } from "../../../utils/catalog_extra/catalog_extra";
import { useTranslation } from "react-i18next";

interface Props extends WithStyles<typeof styles> {
  catalogExtraCategoriesUpperRental: CatalogExtraCategoryUpperRentalType[];
  locationIdsSelected: string[];
  setLocationIdsSelected: React.Dispatch<React.SetStateAction<string[]>>;
  catalogExtraCategoryRentalIds: string[];
  setCatalogExtraCategoryRentalIds: React.Dispatch<
    React.SetStateAction<string[]>
  >;
}

function CatalogExtraRental({
  classes,
  catalogExtraCategoriesUpperRental,
  locationIdsSelected,
  setLocationIdsSelected,
  catalogExtraCategoryRentalIds,
  setCatalogExtraCategoryRentalIds,
}: Props) {
  const { t } = useTranslation();

  const [
    catalogExtraCategoryRentalIdsEdited,
    setCatalogExtraCategoryRentalIdsEdited,
  ] = useState(catalogExtraCategoryRentalIds);

  return (
    <div>
      <Row className="mb-4">
        <Col sm={6} md={6} lg={4} xl={3}>
          <LocationFilter
            locationIdsSelected={locationIdsSelected}
            setLocationIdsSelected={setLocationIdsSelected}
          />
        </Col>
        <Col sm={6} md={6} lg={4} xl={3}>
          <FormControl fullWidth>
            <InputLabel id="lblCatalogExtraFilter">
              {t("catalog_extra_category")}
            </InputLabel>
            <Select
              autoWidth
              labelId="lblCatalogExtraFilter"
              value={catalogExtraCategoryRentalIdsEdited}
              multiple={true}
              onChange={(event) =>
                setCatalogExtraCategoryRentalIdsEdited(
                  event.target.value as string[]
                )
              }
              onClose={() =>
                setCatalogExtraCategoryRentalIds(
                  catalogExtraCategoryRentalIdsEdited
                )
              }
            >
              {catalogExtraCategoriesUpperRental.map(
                (catalogExtraCategoryUpperRental) =>
                  renderSelectGroup(catalogExtraCategoryUpperRental)
              )}
            </Select>
          </FormControl>
        </Col>
      </Row>
      {catalogExtraCategoriesUpperRental.map(
        (catalogExtraCategoryUpperRental, index) => {
          const catalogExtraCategoriesRental: CatalogExtraCategoryRentalType[] =
            catalogExtraCategoryUpperRental.catalogextracategoryrentalSet.filter(
              (catalogExtraCategoryRental) =>
                catalogExtraCategoryRentalIds.length === 0 ||
                catalogExtraCategoryRentalIds.includes(
                  catalogExtraCategoryRental.id
                )
            );

          if (
            catalogExtraCategoryRentalIds.length > 0 &&
            catalogExtraCategoriesRental.length === 0
          ) {
            return <></>;
          }

          return (
            <div key={catalogExtraCategoryUpperRental.id} className="mb-5">
              <CatalogExtraCategoryUpperRentalTable
                catalogExtraCategoryUpperRental={
                  catalogExtraCategoryUpperRental
                }
              />
              {catalogExtraCategoriesRental.map(
                (catalogExtraCategoryRental) => (
                  <CatalogExtraRentalTable
                    key={catalogExtraCategoryRental.id}
                    index={index}
                    catalogExtraCategoryRental={catalogExtraCategoryRental}
                    catalogExtraCategoriesUpperRental={
                      catalogExtraCategoriesUpperRental
                    }
                    locationIdsSelected={locationIdsSelected}
                  />
                )
              )}
            </div>
          );
        }
      )}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CatalogExtraRental);
