import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { HolidayHolidayType, HolidayType } from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";

interface Props extends WithStyles<typeof styles> {
  holiday: HolidayType;
  setHoliday: React.Dispatch<React.SetStateAction<HolidayType>>;
}

function DialogContentHoliday({ classes, holiday, setHoliday }: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "holidays.add_holiday",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "holidays.change_holiday",
  ]);
  const disabled = holiday.id ? !hasPermissionEdit : !hasPermissionAdd;

  return (
    <DialogContent>
      <FormControl fullWidth>
        <InputLabel id="lblHolidayType">{t("type")}</InputLabel>
        <Select
          autoWidth
          labelId="lblHolidayType"
          value={holiday.holidayType}
          disabled={disabled}
          onChange={(event) => {
            setHoliday({
              ...holiday,
              holidayType: event.target.value as HolidayHolidayType,
            });
          }}
        >
          <MenuItem value={HolidayHolidayType.Private}>
            {t("holiday_type_" + HolidayHolidayType.Private)}
          </MenuItem>
          <MenuItem value={HolidayHolidayType.Company}>
            {t("holiday_type_" + HolidayHolidayType.Company)}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("date")}
          type="date"
          value={holiday.date}
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            setHoliday({
              ...holiday,
              date: event.target.value,
            });
          }}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("name")}
          onChange={(event) => {
            setHoliday({
              ...holiday,
              name: event.target.value,
            });
          }}
          value={holiday.name}
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
        />
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentHoliday);
