import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { CONNECT_MACHINE_CATALOG_EXTRA_ROW_UNIT_PRICE } from "../../apollo/mutations/machines";
import {
  CatalogExtraRowUnitPriceType,
  MachineType,
  Mutation,
  MutationConnectMachineCatalogExtraRowUnitPriceArgs,
} from "../../entity/types";
import MaterialTable from "material-table";
import {
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  tableIcons,
} from "../../utils/TableProps";
import MachineCatalogExtraRowUnitPriceCopy from "./MachineCatalogExtraRowUnitPriceCopy";
import { getQueryKey } from "../../utils/cache";
import { ROOT_QUERY } from "../../utils/constants";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import {
  GET_CATALOG_EXTRA_ROWS_UNIT_PRICE_QUERY,
  QueryResultCatalogExtraRowsUnitPrice,
} from "../../apollo/queries/catalogs_extra";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface CatalogExtraRowUnitPriceWithTableDataType
  extends CatalogExtraRowUnitPriceType {
  tableData: { checked: boolean };
}

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  machine: MachineType;
}

function DialogMachineCatalogExtraRowUnitPrice({
  classes,
  open,
  setOpen,
  machine,
}: Props) {
  const { t } = useTranslation();

  const { loading, error, data } =
    useQuery<QueryResultCatalogExtraRowsUnitPrice>(
      GET_CATALOG_EXTRA_ROWS_UNIT_PRICE_QUERY,
      {
        fetchPolicy: getQueryFetchPolicy("catalogExtraRowsUnitPrice"),
      }
    );

  const [connectMachineCatalogExtraRowUnitPrice] = useMutation<
    Mutation,
    MutationConnectMachineCatalogExtraRowUnitPriceArgs
  >(CONNECT_MACHINE_CATALOG_EXTRA_ROW_UNIT_PRICE, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("machinesForLocation"),
      });
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  let catalogExtraRowsUnitPriceWithTableData: CatalogExtraRowUnitPriceWithTableDataType[] =
    [];
  data.catalogExtraRowsUnitPrice?.forEach(function (catalogExtraRowUnitPrice) {
    const checked = catalogExtraRowUnitPrice.machineSet.some(
      (machineLooped) => machineLooped.id === machine.id
    );

    catalogExtraRowsUnitPriceWithTableData.push({
      ...catalogExtraRowUnitPrice,
      tableData: {
        checked: checked,
      },
    });
  });

  let changed: boolean = false;
  let catalogExtraRowUnitPriceIds: string[] = [];
  const onSelectionChange = (rows: CatalogExtraRowUnitPriceType[]) => {
    catalogExtraRowUnitPriceIds = [];
    rows.forEach(function (catalogExtraRowUnitPrice) {
      catalogExtraRowUnitPriceIds.push(catalogExtraRowUnitPrice.id);
    });
    changed = true;
  };

  const onClickSave = () => {
    setOpen(false);
    if (changed) {
      connectMachineCatalogExtraRowUnitPrice({
        variables: {
          data: [
            {
              machineId: machine.id,
              catalogExtraRowIds: catalogExtraRowUnitPriceIds,
            },
          ],
        },
      });
    }
  };

  const onClickCancel = () => {
    setOpen(false);
    changed = false;
    catalogExtraRowUnitPriceIds = [];
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitle>{t("catalog_machines_extra_rows")}</DialogTitle>
      <DialogContent>
        <MachineCatalogExtraRowUnitPriceCopy machine={machine} />
        <MaterialTable
          style={getTableStyle()}
          title={""}
          icons={tableIcons}
          localization={GetTableLocalization(t)}
          columns={[
            { title: t("catalog_extra_row"), field: "name" },
            {
              title: t("catalog_extra_category"),
              field: "catalogExtraCategoryUnitPrice.name",
            },
          ]}
          data={catalogExtraRowsUnitPriceWithTableData}
          options={getTableOptions({ selection: true })}
          onSelectionChange={onSelectionChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickCancel} variant="secondary">
          {t("cancel")}
        </Button>
        <Button onClick={onClickSave} variant="primary">
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogMachineCatalogExtraRowUnitPrice);
