import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { useQuery } from "@apollo/client";
import { Box, createStyles, Tab, Theme } from "@material-ui/core";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  GET_INSPECTION_GIVE_CATEGORIES_QUERY,
  QueryResultInspectionGiveCategories,
} from "../apollo/queries/inspections";
import CreateInspectionGiveCategory from "../components/InspectionGive/CreateInspectionGiveCategory";
import InspectionGiveCategory from "../components/InspectionGive/InspectionGiveCategory";
import { WithStyles } from "@material-ui/core/styles";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import InspectionGiveSort from "../components/InspectionGive/InspectionGiveSort";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import HeaderPage from "../components/Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {}

function SettingsInspectionGive({ classes }: Props) {
  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = useState("0");

  const { loading, error, data } =
    useQuery<QueryResultInspectionGiveCategories>(
      GET_INSPECTION_GIVE_CATEGORIES_QUERY,
      {
        fetchPolicy: getQueryFetchPolicy("inspectionGiveCategories"),
      }
    );

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const handleChange = (event: React.ChangeEvent<{}>, newTab: string) => {
    setTabSelected(newTab);
  };

  return (
    <Container className="containerMain">
      <HeaderPage title={t("inspection_give")} />
      <div className="containerInner">
        <TabContext value={tabSelected}>
          <Box>
            <TabList onChange={handleChange}>
              <Tab label={t("inspection_questions")} value="0" />
              <Tab label={t("inspection_sort")} value="1" />
            </TabList>
          </Box>
          <TabPanel value="0">
            {data.inspectionGiveCategories?.map((inspectionGiveCategory) => (
              <InspectionGiveCategory
                key={inspectionGiveCategory.id}
                inspectionGiveCategory={inspectionGiveCategory}
                inspectionGiveTags={
                  data.inspectionGiveTags ? data.inspectionGiveTags : []
                }
              />
            ))}
          </TabPanel>
          <TabPanel value="1">
            <InspectionGiveSort
              inspectionGiveCategories={
                data.inspectionGiveCategories
                  ? data.inspectionGiveCategories
                  : []
              }
            />
          </TabPanel>
        </TabContext>
      </div>
      <CreateInspectionGiveCategory />
    </Container>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    headerCategory: {
      color: palette.primary.main,
      fontSize: "1.2rem",
    },
  });

export default withStyles(styles)(SettingsInspectionGive);
