import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  InspectionReturnCategoryType,
  Mutation,
  MutationCreateInspectionReturnCategoryArgs,
  MutationUpdateInspectionReturnCategoryArgs,
} from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { InspectionReturnCategoryEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import DialogContentInspectionReturnCategory from "./DialogContentInspectionReturnCategory";
import {
  CREATE_INSPECTION_RETURN_CATEGORY_MUTATION,
  UPDATE_INSPECTION_RETURN_CATEGORY_MUTATION,
} from "../../apollo/mutations/inspections";
import { GET_INSPECTION_RETURN_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { ID_EMPTY } from "../../utils/constants";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  inspectionReturnCategory: InspectionReturnCategoryType;
}

function DialogInspectionReturnCategory({
  classes,
  open,
  setOpen,
  inspectionReturnCategory,
}: Props) {
  const { t } = useTranslation();
  const [inspectionReturnCategoryEdited, setInspectionReturnCategoryEdited] =
    useState<InspectionReturnCategoryType>(inspectionReturnCategory);

  const [createInspectionReturnCategory] = useMutation<
    Mutation,
    MutationCreateInspectionReturnCategoryArgs
  >(CREATE_INSPECTION_RETURN_CATEGORY_MUTATION, {
    refetchQueries: [{ query: GET_INSPECTION_RETURN_CATEGORIES_QUERY }],
    onCompleted: (result) => {
      setInspectionReturnCategoryEdited(InspectionReturnCategoryEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [updateInspectionReturnCategory] = useMutation<
    Mutation,
    MutationUpdateInspectionReturnCategoryArgs
  >(UPDATE_INSPECTION_RETURN_CATEGORY_MUTATION, {
    refetchQueries: [{ query: GET_INSPECTION_RETURN_CATEGORIES_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (inspectionReturnCategoryEdited.id !== ID_EMPTY) {
      updateInspectionReturnCategory({
        variables: {
          inspectionReturnCategoryId: inspectionReturnCategoryEdited.id,
          name: inspectionReturnCategoryEdited.name,
        },
      });
    } else {
      createInspectionReturnCategory({
        variables: {
          name: inspectionReturnCategoryEdited.name,
        },
      });
    }

    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <form className={classes.form} onSubmit={(event) => handleSubmit(event)}>
        <DialogTitle>
          {t(
            inspectionReturnCategoryEdited.id !== ID_EMPTY
              ? "update_inspection_category"
              : "new_inspection_category"
          )}
        </DialogTitle>
        <DialogContentInspectionReturnCategory
          inspectionReturnCategory={inspectionReturnCategoryEdited}
          setInspectionReturnCategory={setInspectionReturnCategoryEdited}
        />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <Button
            disabled={!inspectionReturnCategoryEdited.name.trim()}
            type="submit"
            variant="primary"
          >
            {t("save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: spacing(2),
    },
  });

export default withStyles(styles)(DialogInspectionReturnCategory);
