import { UserType } from "../../entity/types";
import { ID_EMPTY } from "../constants";

export function getLocationIdDefault(user: UserType) {
  return user.UserInfo?.location ? user.UserInfo.location.id : ID_EMPTY;
}

export function getLocationSelected(user: UserType) {
  const locationIdStorage =
    localStorage.getItem("locationId") === null ||
    localStorage.getItem("locationId") === ""
      ? ID_EMPTY
      : String(localStorage.getItem("locationId"));

  return locationIdStorage !== ID_EMPTY
    ? locationIdStorage
    : getLocationIdDefault(user);
}

export function setLocalStorageLocationId(locationId: string) {
  localStorage.setItem("locationId", locationId);
}
