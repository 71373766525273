import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import ReportDay from "../components/Report/ReportDay";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props extends WithStyles<typeof styles> {}

function ManagementReportsDay({ classes }: Props) {
  const { t } = useTranslation();

  return (
    <Container className="containerMain">
      <h2 className="headerPage">{t("management_reports_day")}</h2>
      <div className="containerInner">
        <ReportDay />
      </div>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ManagementReportsDay);
