import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  createStyles,
  List,
  ListItem,
  ListItemText,
  Theme,
} from "@material-ui/core";
import { ReservationWithPriceType } from "../../entity/types";
import { ID_EMPTY } from "../../utils/constants";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import DialogOrderId from "../Customer/DialogOrderId";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/formatting";

interface Props extends WithStyles<typeof styles> {
  date: string;
  reservationsWithPrice: ReservationWithPriceType[];
}

function ReportDayRowContent({ classes, date, reservationsWithPrice }: Props) {
  const { t } = useTranslation();
  const [reservationInfoOpen, setReservationInfoOpen] = useState(false);
  const [reservationIdSelected, setReservationIdSelected] = useState(ID_EMPTY);
  const [orderIdSelected, setOrderIdSelected] = useState(ID_EMPTY);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionViewOrder = checkPermission(myPermissions, [
    "orders.view_order",
  ]);

  reservationsWithPrice.sort((a, b) =>
    a.title > b.title ? 1 : b.title > a.title ? -1 : 0
  );

  const onClickReservation = (reservation: ReservationWithPriceType) => {
    setOrderIdSelected(reservation.orderId);
    setReservationIdSelected(reservation.reservationId);
    setReservationInfoOpen(true);
  };

  const getPricePerDay = (reservationWithPrice: ReservationWithPriceType) => {
    let pricePerDay = 0;
    if (reservationWithPrice.useDayPrice) {
      pricePerDay = reservationWithPrice.dayPrice;
    } else {
      const dtArr = date.split("-");
      const dtStr = dtArr[0] + "-" + dtArr[1];
      reservationWithPrice.monthPrices.forEach((monthPricePerDay) => {
        if (monthPricePerDay.month === dtStr) {
          pricePerDay = monthPricePerDay.pricePerDay;
        }
      });
    }

    return formatNumber(pricePerDay, 2);
  };

  return (
    <div className="w-100">
      <List>
        {reservationsWithPrice?.map((reservationWithPrice) => (
          <ListItem key={reservationWithPrice.reservationId}>
            <Button
              className="me-3"
              onClick={() => onClickReservation(reservationWithPrice)}
              variant="outlined"
              disabled={!hasPermissionViewOrder}
            >
              #{reservationWithPrice.reservationId}
            </Button>
            <ListItemText
              primary={reservationWithPrice.title}
              secondary={t("price_per_day", {
                price: getPricePerDay(reservationWithPrice),
              })}
            />
          </ListItem>
        ))}
      </List>
      {reservationInfoOpen && reservationIdSelected !== ID_EMPTY && (
        <DialogOrderId
          open={reservationInfoOpen}
          setOpen={setReservationInfoOpen}
          orderId={orderIdSelected}
          reservationIdSelected={reservationIdSelected}
        />
      )}
    </div>
  );
}
const styles = (theme: Theme) =>
  createStyles({
    avatarLower: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
    },
  });

export default withStyles(styles)(ReportDayRowContent);
