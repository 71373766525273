import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineBreakdownType,
  MachineBulkBreakdownType,
  ReservationType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { ReservationEmpty } from "../../entity/empties";
import { ID_EMPTY } from "../../utils/constants";
import WorkQueueCardBreakdownBulk from "./WorkQueueCardBreakdownBulk";
import WorkQueueCardBreakdown from "./WorkQueueCardBreakdown";
import { sortReservationsByMachineAndRowExtra } from "../../utils/maintenances/maintenance";

interface Props extends WithStyles<typeof styles> {
  machineBreakdowns: MachineBreakdownType[];
  machineBulkBreakdowns: MachineBulkBreakdownType[];
  reservationsUpcoming: ReservationType[];
}

function WorkQueuesMaintenanceBreakdowns({
  classes,
  machineBreakdowns,
  machineBulkBreakdowns,
  reservationsUpcoming,
}: Props) {
  const { t } = useTranslation();

  let reservations = sortReservationsByMachineAndRowExtra(reservationsUpcoming);
  const reservationNextByMachine = reservations[0];
  const reservationNextByRowExtra = reservations[1];

  return (
    <div className="conWorkQueue">
      <div className="titleWorkQueue">
        {t("work_queues_maintenance_breakdowns")}
      </div>
      {machineBreakdowns.map((machineBreakdown) => {
        const key = machineBreakdown.catalogExtraRowRental
          ? machineBreakdown.catalogExtraRowRental.id
          : machineBreakdown.machine
          ? machineBreakdown.machine.id
          : ID_EMPTY;

        const reservationNext =
          reservationNextByRowExtra[key] !== undefined
            ? reservationNextByRowExtra[key]
            : reservationNextByMachine[key] !== undefined
            ? reservationNextByMachine[key]
            : ReservationEmpty;

        return (
          <WorkQueueCardBreakdown
            key={machineBreakdown.id}
            machineBreakdown={machineBreakdown}
            reservationNext={reservationNext}
          />
        );
      })}
      {machineBulkBreakdowns.map((machineBulkBreakdown) => (
        <WorkQueueCardBreakdownBulk
          key={machineBulkBreakdown.id}
          machineBulkBreakdown={machineBulkBreakdown}
        />
      ))}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(WorkQueuesMaintenanceBreakdowns);
