import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import MaterialTableMachine from "../Machine/MaterialTableMachine";
import {
  CatalogExtraRowRentalType,
  ConnectIdsType,
  MachineType,
  Mutation,
  MutationConnectMachineCatalogExtraRowRentalArgs,
} from "../../entity/types";
import { useMutation, useQuery } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { CONNECT_MACHINE_CATALOG_EXTRA_ROW_RENTAL } from "../../apollo/mutations/machines";
import {
  GET_CATALOG_EXTRA_RENTAL_QUERY,
  QueryResultCatalogExtraRental,
} from "../../apollo/queries/catalogs_extra";
import { ID_EMPTY } from "../../utils/constants";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import CatalogExtraRowRentalMachineCopy from "../CatalogExtraUnitPrice/CatalogExtraRowRentalMachineCopy";
import {
  GET_MACHINES_QUERY,
  QueryResultMachines,
} from "../../apollo/queries/machines";
import LoadingDialog from "../Shared/LoadingDialog";
import Error from "../Shared/Error";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface MachineWithTableDataType extends MachineType {
  tableData: { checked: boolean };
}

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalogExtraRowRental: CatalogExtraRowRentalType;
}

function DialogCatalogExtraRowRentalMachine({
  classes,
  open,
  setOpen,
  catalogExtraRowRental,
}: Props) {
  const { t } = useTranslation();

  const [selectionEnabled, setSelectionEnabled] = useState(true);

  const { loading, error, data } = useQuery<QueryResultMachines>(
    GET_MACHINES_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("machines"),
      notifyOnNetworkStatusChange: true,
    }
  );

  /* We don't want to refetch after every mutation because of lag so we do the refetch when dialog is closed. */
  const { refetch } = useQuery<QueryResultCatalogExtraRental>(
    GET_CATALOG_EXTRA_RENTAL_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("catalogExtraCategoriesUpperRental"),
      skip: true,
    }
  );

  const [connectMachineCatalogExtraRowRental, { loading: loadingConnect }] =
    useMutation<Mutation, MutationConnectMachineCatalogExtraRowRentalArgs>(
      CONNECT_MACHINE_CATALOG_EXTRA_ROW_RENTAL,
      {
        onError: (error) => {
          handleError(error);
        },
        onCompleted: () => {
          setSelectionEnabled(true);
        },
      }
    );

  if (loading) return <LoadingDialog />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const machines: MachineType[] = data.machines ? data.machines : [];

  let machinesWithTabledata: MachineWithTableDataType[] = [];
  machines.forEach(function (machine) {
    let checked = false;
    machine.catalogExtraRowsRental.forEach(function (
      catalogExtraRowRentalLooped
    ) {
      if (catalogExtraRowRentalLooped.id === catalogExtraRowRental.id) {
        checked = true;
      }
    });

    machinesWithTabledata.push({
      ...machine,
      tableData: {
        checked: checked,
      },
    });
  });

  const onClose = () => {
    refetch();
    setOpen(false);
  };

  const onSelectionChange = (rows: MachineType[]) => {
    let data: ConnectIdsType[] = [];

    setSelectionEnabled(false);

    machines.forEach(function (machine) {
      let catalogExtraRowRentalIds: string[] = [];

      /* Add the other catalogExtraRowRentalIds so we don't drop those */
      machine.catalogExtraRowsRental.forEach(function (
        catalogExtraRowRentalLooped
      ) {
        if (catalogExtraRowRentalLooped.id !== catalogExtraRowRental.id) {
          catalogExtraRowRentalIds.push(catalogExtraRowRentalLooped.id);
        }
      });

      /* Add the selected catalogExtraRowRentalIds */
      rows.forEach(function (machineSelected) {
        if (machineSelected.id === machine.id) {
          catalogExtraRowRentalIds.push(catalogExtraRowRental.id);
        }
      });

      data.push({
        machineId: machine.id,
        catalogExtraRowIds: catalogExtraRowRentalIds,
      });
    });

    connectMachineCatalogExtraRowRental({
      variables: {
        data: data,
      },
    });
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="xl">
      <DialogTitleWithClose
        id="dialogTitleCatalogExtraRowRentalMachine"
        onClose={onClose}
      >
        {t("catalog_extra_rows_machines")}
      </DialogTitleWithClose>
      <DialogContent className={loadingConnect ? "loading" : ""}>
        <CatalogExtraRowRentalMachineCopy
          catalogExtraRowRental={catalogExtraRowRental}
        />
        <MaterialTableMachine
          machines={machinesWithTabledata}
          catalogIdSelected={ID_EMPTY}
          options={{ selection: true }}
          onSelectionChange={onSelectionChange}
          selectionEnabled={selectionEnabled}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="secondary">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCatalogExtraRowRentalMachine);
