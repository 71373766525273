import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import {
  CatalogExtraRowRentalType,
  Mutation,
  MutationSplitReservationArgs,
  OrderType,
  QueryCatalogExtraRowsRentalByLocationMachinesArgs,
  QueryReservationEditableDatesArgs,
  ReservationType,
} from "../../entity/types";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_RESERVATION_EDITABLE_DATES_QUERY,
  QueryResultReservationEditableDates,
} from "../../apollo/queries/reservations";
import format from "date-fns/format";
import { DATE_FORMAT_ISO, ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { handleError } from "../../entity/ErrorHandler";
import { SPLIT_RESERVATION_MUTATION } from "../../apollo/mutations/reservations";
import Error from "../Shared/Error";
import {
  GET_CATALOG_EXTRA_ROWS_RENTAL_BY_LOCATION_MACHINES_QUERY,
  QueryResultCatalogExtraRowsRentalByLocationMachines,
} from "../../apollo/queries/catalogs_extra";
import { getQueryKey } from "../../utils/cache";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  order: OrderType;
  reservation: ReservationType;
}

function DialogReservationSplitExtraRow({
  classes,
  open,
  setOpen,
  order,
  reservation,
}: Props) {
  const { t } = useTranslation();

  const TYPE_HAS_MACHINES = "1";
  const TYPE_HAS_NO_MACHINES = "0";

  const [catalogExtraRowRentalIdSelected, setCatalogExtraRowRentalIdSelected] =
    useState("");
  const [dateReservationEnd, setDateReservationEnd] = useState(
    format(new Date(), DATE_FORMAT_ISO)
  );

  const {
    loading: loadingRows,
    error: errorRows,
    data: dataRows,
  } = useQuery<
    QueryResultCatalogExtraRowsRentalByLocationMachines,
    QueryCatalogExtraRowsRentalByLocationMachinesArgs
  >(GET_CATALOG_EXTRA_ROWS_RENTAL_BY_LOCATION_MACHINES_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      machineIds: [reservation.machine ? reservation.machine.id : ID_EMPTY],
    },
  });

  const {
    loading: loadingDates,
    error: errorDates,
    data: dataDates,
  } = useQuery<
    QueryResultReservationEditableDates,
    QueryReservationEditableDatesArgs
  >(GET_RESERVATION_EDITABLE_DATES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("reservationEditableDates"),
    variables: {
      reservationIds: [reservation.id],
    },
  });

  const [splitReservation] = useMutation<
    Mutation,
    MutationSplitReservationArgs
  >(SPLIT_RESERVATION_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: (result) => {
      setOpen(false);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("order"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("reservationsCalendar"),
      });
    },
  });

  if (errorDates) return <Error error={errorDates} />;
  if (errorRows) return <Error error={errorRows} />;

  const onClickMove = () => {
    splitReservation({
      variables: {
        reservationId: reservation.id,
        catalogExtraRowRentalId: catalogExtraRowRentalIdSelected,
        dateEnd: dateReservationEnd,
      },
    });
  };

  const dateSelectionStart = dataDates?.reservationEditableDates
    ? dataDates.reservationEditableDates.dateStart
    : null;
  const dateSelectionEnd = dataDates?.reservationEditableDates
    ? dataDates.reservationEditableDates.dateEnd
    : null;

  let catalogExtraRowsRentalByMachine: {
    [key: string]: CatalogExtraRowRentalType[];
  } = {};
  dataRows?.catalogExtraRowsRentalByLocationMachines?.forEach(
    (catalogExtraRowRental) => {
      const key =
        catalogExtraRowRental.machineSet.length > 0
          ? TYPE_HAS_MACHINES
          : TYPE_HAS_NO_MACHINES;

      if (catalogExtraRowsRentalByMachine[key] === undefined) {
        catalogExtraRowsRentalByMachine[key] = [];
      }
      if (
        reservation.catalogExtraRowRental &&
        catalogExtraRowRental.id !== reservation.catalogExtraRowRental.id
      ) {
        catalogExtraRowsRentalByMachine[key].push(catalogExtraRowRental);
      }
    }
  );

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <form>
        <DialogTitle>{t("reservation_split")}</DialogTitle>
        <DialogContent className={loadingDates || loadingRows ? "loading" : ""}>
          <FormControl fullWidth>
            <InputLabel id="lblReservationSplitMachines">
              {t("catalog_extra_rows_rental")}
            </InputLabel>
            <Select
              labelId="lblReservationSplitMachines"
              value={catalogExtraRowRentalIdSelected}
              onChange={(event: React.ChangeEvent<{ value: any }>) => {
                setCatalogExtraRowRentalIdSelected(event.target.value);
              }}
            >
              {Object.entries(catalogExtraRowsRentalByMachine).map(
                ([key, rows]) => {
                  return [
                    <ListSubheader>
                      {key === TYPE_HAS_MACHINES
                        ? t("catalog_extra_rows_with_machines")
                        : t("catalog_extra_rows_without_machines")}
                    </ListSubheader>,
                    rows.map((catalogExtraRowRental) => (
                      <MenuItem
                        key={catalogExtraRowRental.id}
                        value={catalogExtraRowRental.id}
                      >
                        {catalogExtraRowRental.name}
                      </MenuItem>
                    )),
                  ];
                }
              )}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label={t("date_reservation_split")}
              type="date"
              value={dateReservationEnd}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ min: dateSelectionStart, max: dateSelectionEnd }}
              onChange={(event) => {
                setDateReservationEnd(event.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="light" onClick={onClickMove}>
            {t("switch_reservation_machine")}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogReservationSplitExtraRow);
