import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, Theme } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import {
  CommissionLevelType,
  CommissionRangeType,
  Mutation,
  MutationCreateCommissionLevelArgs,
} from "../../entity/types";
import CommissionRangeHeader from "./CommissionRangeHeader";
import { Button, Table } from "react-bootstrap";
import CommissionLevelRow from "./CommissionLevelRow";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import { checkPermission } from "../../utils/permissions";
import { PermissionsContext } from "../../Root";
import TextFieldFocus from "../Shared/TextFieldFocus";
import ButtonLoad from "../Shared/ButtonLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import { CREATE_COMMISSION_LEVEL_MUTATION } from "../../apollo/mutations/commissions";
import { handleError } from "../../entity/ErrorHandler";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { CommissionLevelEmpty } from "../../entity/empties";

interface Props extends WithStyles<typeof styles> {
  commissionTemplateId: string;
  commissionRange: CommissionRangeType;
  commissionRangePrev: CommissionRangeType | null;
  commissionLevels: CommissionLevelType[];
  isLast: boolean;
}

function CommissionLevelsForRange({
  classes,
  commissionTemplateId,
  commissionRange,
  commissionRangePrev,
  commissionLevels,
  isLast,
}: Props) {
  const { t } = useTranslation();

  const [showCommissionLevelNew, setShowCommissionLevelNew] = useState(false);
  const [commissionLevelNew, setCommissionLevelNew] = useState({
    ...CommissionLevelEmpty,
    maxDiscount: formatNumber(CommissionLevelEmpty.maxDiscount, 2),
    maxCommission: formatNumber(CommissionLevelEmpty.maxCommission, 2),
    minCommission: formatNumber(CommissionLevelEmpty.minCommission, 2),
  });

  const [createCommissionLevel, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateCommissionLevelArgs
  >(CREATE_COMMISSION_LEVEL_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("commissionTemplates"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionCommissionLevelAdd = checkPermission(myPermissions, [
    "commissions.add_commissionlevel",
  ]);

  const onClickAddCommissionLevel = (commissionRange: CommissionRangeType) => {
    createCommissionLevel({
      variables: {
        commissionTemplateId: commissionTemplateId,
        commissionRangeId: commissionRange.id,
        maxDiscount: parseNumber(commissionLevelNew.maxDiscount),
        maxCommission: parseNumber(commissionLevelNew.maxCommission),
        minCommission: parseNumber(commissionLevelNew.minCommission),
      },
    });
    setShowCommissionLevelNew(false);
    setCommissionLevelNew({
      ...CommissionLevelEmpty,
      maxDiscount: formatNumber(CommissionLevelEmpty.maxDiscount),
      maxCommission: formatNumber(CommissionLevelEmpty.maxCommission),
      minCommission: formatNumber(CommissionLevelEmpty.minCommission),
    });
  };

  const onClickShowCommissionLevelNew = () => {
    setShowCommissionLevelNew(true);
  };
  const onClickHideCommissionLevelNew = () => {
    setShowCommissionLevelNew(false);
  };

  return (
    <div className="mb-5">
      <CommissionRangeHeader
        key={commissionRange.id}
        commissionRange={commissionRange}
        commissionRangePrev={commissionRangePrev}
        isLast={isLast}
      />
      <Table borderless className="w-auto">
        <thead>
          <tr>
            <th>{t("commissions_min_discount")}</th>
            <th>{t("commissions_max_discount")}</th>
            <th>{t("commissions_max_commission")}</th>
            <th>{t("commissions_min_commission")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {commissionLevels.map((commissionLevel, index) => {
            const commissionLevelPrev =
              index > 0 ? commissionLevels[index - 1] : null;
            return (
              <CommissionLevelRow
                key={commissionLevel.id}
                commissionLevel={commissionLevel}
                commissionLevelPrev={commissionLevelPrev}
              />
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td>
              {hasPermissionCommissionLevelAdd && !showCommissionLevelNew && (
                <Button
                  onClick={onClickShowCommissionLevelNew}
                  className="btnRoundSmall"
                >
                  <AddIcon />
                </Button>
              )}
            </td>
            <td>
              {showCommissionLevelNew && (
                <FormControl>
                  <TextFieldFocus
                    value={commissionLevelNew.maxDiscount}
                    onChange={(event) => {
                      setCommissionLevelNew({
                        ...commissionLevelNew,
                        maxDiscount: event.target.value,
                      });
                    }}
                    inputProps={{ maxLength: 6 }}
                    onBlur={() => {
                      setCommissionLevelNew({
                        ...commissionLevelNew,
                        maxDiscount: formatNumber(
                          commissionLevelNew.maxDiscount,
                          2
                        ),
                      });
                    }}
                  />
                </FormControl>
              )}
            </td>
            <td>
              {showCommissionLevelNew && (
                <FormControl>
                  <TextFieldFocus
                    value={commissionLevelNew.maxCommission}
                    onChange={(event) => {
                      setCommissionLevelNew({
                        ...commissionLevelNew,
                        maxCommission: event.target.value,
                      });
                    }}
                    inputProps={{ maxLength: 6 }}
                    onBlur={() => {
                      if (
                        commissionLevelNew.maxCommission <
                        commissionLevelNew.minCommission
                      ) {
                        setCommissionLevelNew({
                          ...commissionLevelNew,
                          maxCommission: formatNumber(
                            commissionLevelNew.minCommission,
                            2
                          ),
                        });
                      } else {
                        setCommissionLevelNew({
                          ...commissionLevelNew,
                          maxCommission: formatNumber(
                            commissionLevelNew.maxCommission,
                            2
                          ),
                        });
                      }
                    }}
                  />
                </FormControl>
              )}
            </td>
            <td>
              {showCommissionLevelNew && (
                <FormControl>
                  <TextFieldFocus
                    value={commissionLevelNew.minCommission}
                    onChange={(event) => {
                      setCommissionLevelNew({
                        ...commissionLevelNew,
                        minCommission: event.target.value,
                      });
                    }}
                    inputProps={{ maxLength: 6 }}
                    onBlur={() => {
                      if (
                        commissionLevelNew.minCommission >
                        commissionLevelNew.maxCommission
                      ) {
                        setCommissionLevelNew({
                          ...commissionLevelNew,
                          minCommission: formatNumber(
                            commissionLevelNew.maxCommission,
                            2
                          ),
                        });
                      } else {
                        setCommissionLevelNew({
                          ...commissionLevelNew,
                          minCommission: formatNumber(
                            commissionLevelNew.minCommission,
                            2
                          ),
                        });
                      }
                    }}
                  />
                </FormControl>
              )}
            </td>
            <td>
              {hasPermissionCommissionLevelAdd && showCommissionLevelNew && (
                <ButtonLoad
                  loading={loadingCreate}
                  onClick={() => onClickAddCommissionLevel(commissionRange)}
                  variant="primary"
                  size="sm"
                  className={classes.btn}
                >
                  <FontAwesomeIcon icon={faSave} />
                </ButtonLoad>
              )}
            </td>
            <td>
              {hasPermissionCommissionLevelAdd && showCommissionLevelNew && (
                <Button
                  variant="light"
                  size="sm"
                  className={classes.btn}
                  onClick={onClickHideCommissionLevelNew}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              )}
            </td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    btn: {
      minWidth: "2rem",
    },
  });

export default withStyles(styles)(CommissionLevelsForRange);
