import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  Mutation,
  MutationDeleteReservationArgs,
  ReservationType,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import ButtonLoad from "../Shared/ButtonLoad";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { handleError } from "../../entity/ErrorHandler";
import { DELETE_RESERVATION_MUTATION } from "../../apollo/mutations/reservations";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { reservationCanBeDeleted } from "../../utils/reservations/reservation";
import { dialogConfirm } from "../../utils/dialogs";
import { getQueryKey } from "../../utils/cache";
import { ROOT_QUERY } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationType;
  size?: "lg" | "sm" | undefined;
}

function ButtonReservationDelete({
  classes,
  reservation,
  size = undefined,
}: Props) {
  const { t } = useTranslation();

  const [deleteReservation, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteReservationArgs
  >(DELETE_RESERVATION_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("order"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("bulkAmountsByDate"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("reservationsCalendar"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "reservations.delete_reservation",
  ]);

  const onClickDelete = (reservation: ReservationType) => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteReservation({
        variables: {
          reservationId: reservation.id,
        },
      });
    });
  };

  return (
    <ButtonLoad
      loading={loadingDelete}
      variant="light"
      size={size}
      onClick={() => {
        onClickDelete(reservation);
      }}
      disabled={!hasPermissionDelete || !reservationCanBeDeleted(reservation)}
      title={t("delete_reservation")}
    >
      {size !== "sm" ? t("delete_reservation") : ""}
      {size === "sm" && <FontAwesomeIcon icon={faTrash} />}
    </ButtonLoad>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ButtonReservationDelete);
