import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Chip,
  createStyles,
  FormControl,
  MenuList,
  MenuItem,
  Popover,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  InspectionGiveQuestionType,
  InspectionGiveTagType,
  Mutation,
  MutationAddInspectionGiveTagToQuestionArgs,
  MutationCreateInspectionGiveTagArgs,
  MutationDeleteInspectionGiveTagArgs,
  MutationRemoveInspectionGiveTagFromQuestionArgs,
  MutationUpdateInspectionGiveTagArgs,
} from "../../entity/types";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faCheck,
  faEdit,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import {
  ADD_INSPECTION_GIVE_TAG_TO_QUESTION_MUTATION,
  CREATE_INSPECTION_GIVE_TAG_MUTATION,
  DELETE_INSPECTION_GIVE_TAG_MUTATION,
  REMOVE_INSPECTION_GIVE_TAG_FROM_QUESTION_MUTATION,
  UPDATE_INSPECTION_GIVE_TAG_MUTATION,
} from "../../apollo/mutations/inspections";
import { GET_INSPECTION_GIVE_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { handleError } from "../../entity/ErrorHandler";
import { InspectionGiveTagEmpty } from "../../entity/empties";
import { dialogConfirm } from "../../utils/dialogs";
import { ID_EMPTY } from "../../utils/constants";
import { WithStyles } from "@material-ui/core/styles";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  inspectionGiveQuestion: InspectionGiveQuestionType;
  inspectionGiveTags: InspectionGiveTagType[];
}

function InspectionGiveQuestionTags({
  classes,
  inspectionGiveQuestion,
  inspectionGiveTags,
}: Props) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tagNew, setTagNew] = useState("");
  const [inspectionGiveTagEdited, setInspectionGiveTagEdited] = useState(
    InspectionGiveTagEmpty
  );

  const [deleteInspectionGiveTag, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteInspectionGiveTagArgs
  >(DELETE_INSPECTION_GIVE_TAG_MUTATION, {
    refetchQueries: [{ query: GET_INSPECTION_GIVE_CATEGORIES_QUERY }],
    onCompleted: () => {
      setInspectionGiveTagEdited(InspectionGiveTagEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [createInspectionGiveTag] = useMutation<
    Mutation,
    MutationCreateInspectionGiveTagArgs
  >(CREATE_INSPECTION_GIVE_TAG_MUTATION, {
    refetchQueries: [{ query: GET_INSPECTION_GIVE_CATEGORIES_QUERY }],
    onCompleted: () => {
      setTagNew("");
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [removeInspectionGiveTagFromQuestion, { loading: loadingRemove }] =
    useMutation<Mutation, MutationRemoveInspectionGiveTagFromQuestionArgs>(
      REMOVE_INSPECTION_GIVE_TAG_FROM_QUESTION_MUTATION,
      {
        refetchQueries: [{ query: GET_INSPECTION_GIVE_CATEGORIES_QUERY }],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const [addInspectionGiveTagToQuestion, { loading: loadingAdd }] = useMutation<
    Mutation,
    MutationAddInspectionGiveTagToQuestionArgs
  >(ADD_INSPECTION_GIVE_TAG_TO_QUESTION_MUTATION, {
    refetchQueries: [{ query: GET_INSPECTION_GIVE_CATEGORIES_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const [updateInspectionGiveTag] = useMutation<
    Mutation,
    MutationUpdateInspectionGiveTagArgs
  >(UPDATE_INSPECTION_GIVE_TAG_MUTATION, {
    refetchQueries: [{ query: GET_INSPECTION_GIVE_CATEGORIES_QUERY }],
    onCompleted: () => {
      setInspectionGiveTagEdited(InspectionGiveTagEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddTag = checkPermission(myPermissions, [
    "inspections.add_inspectiongivetag",
  ]);
  const hasPermissionEditTag = checkPermission(myPermissions, [
    "inspections.change_inspectiongivetag",
  ]);

  const onClickTags = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCreateTag = () => {
    createInspectionGiveTag({
      variables: {
        inspectionGiveQuestionId: inspectionGiveQuestion.id,
        name: tagNew,
      },
    });
  };

  const handleRemoveTag = (inspectionGiveTag: InspectionGiveTagType) => {
    removeInspectionGiveTagFromQuestion({
      variables: {
        inspectionGiveQuestionId: inspectionGiveQuestion.id,
        inspectionGiveTagId: inspectionGiveTag.id,
      },
    });
  };

  const onClickMenuTag = (inspectionGiveTag: InspectionGiveTagType) => {
    if (isTagSelected(inspectionGiveTag)) {
      handleRemoveTag(inspectionGiveTag);
    } else {
      addInspectionGiveTagToQuestion({
        variables: {
          inspectionGiveQuestionId: inspectionGiveQuestion.id,
          inspectionGiveTagId: inspectionGiveTag.id,
        },
      });
    }
  };

  const onClickMenuTagEdit = (
    event: React.MouseEvent<HTMLElement>,
    inspectionGiveTag: InspectionGiveTagType
  ) => {
    event.stopPropagation();
    setInspectionGiveTagEdited(inspectionGiveTag);
  };

  const isTagSelected = (inspectionGiveTag: InspectionGiveTagType) => {
    let has = false;
    inspectionGiveQuestion.inspectiongivetagSet.forEach(
      (inspectionGiveTagLooped) => {
        if (inspectionGiveTagLooped.id === inspectionGiveTag.id) {
          has = true;
          return;
        }
      }
    );
    return has;
  };

  const onClickTagDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteInspectionGiveTag({
        variables: {
          inspectionGiveTagId: inspectionGiveTagEdited.id,
        },
      });
    });
  };

  const onClickTagSave = () => {
    updateInspectionGiveTag({
      variables: {
        inspectionGiveTagId: inspectionGiveTagEdited.id,
        name: inspectionGiveTagEdited.name,
      },
    });
  };

  return (
    <td className={loadingAdd || loadingRemove ? "loading" : ""}>
      {inspectionGiveQuestion.inspectiongivetagSet.map((inspectionGiveTag) => (
        <Chip
          key={inspectionGiveTag.id}
          className="me-1 mb-1"
          label={inspectionGiveTag.name}
          onDelete={
            hasPermissionEditTag
              ? () => {
                  handleRemoveTag(inspectionGiveTag);
                }
              : undefined
          }
        />
      ))}
      {hasPermissionAddTag && (
        <>
          <Button variant="light" size="sm" onClick={onClickTags}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            <div className={classes.popContent}>
              {inspectionGiveTagEdited.id !== ID_EMPTY && (
                <div>
                  <FormControl fullWidth>
                    <TextField
                      label={t("name")}
                      value={inspectionGiveTagEdited.name}
                      autoFocus
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(event) => {
                        setInspectionGiveTagEdited({
                          ...inspectionGiveTagEdited,
                          name: event.target.value,
                        });
                      }}
                    />
                  </FormControl>
                  <div className="buttons mt-3 mb-0">
                    <Button onClick={onClickTagSave}>{t("save")}</Button>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        setInspectionGiveTagEdited(InspectionGiveTagEmpty)
                      }
                    >
                      {t("cancel")}
                    </Button>
                    <ButtonLoad
                      loading={loadingDelete}
                      variant="light"
                      onClick={onClickTagDelete}
                    >
                      {t("delete")}
                    </ButtonLoad>
                  </div>
                </div>
              )}
              {inspectionGiveTagEdited.id === ID_EMPTY && (
                <>
                  <MenuList>
                    {inspectionGiveTags.map((inspectionGiveTag) => (
                      <MenuItem
                        key={inspectionGiveTag.id}
                        className={classes.tagMenuItem}
                        onClick={() => {
                          onClickMenuTag(inspectionGiveTag);
                        }}
                      >
                        {isTagSelected(inspectionGiveTag) && (
                          <FontAwesomeIcon className="me-2" icon={faCheck} />
                        )}
                        {inspectionGiveTag.name}
                        <Button
                          className={classes.btnMenuTagEdit}
                          variant="light"
                          onClick={(event) =>
                            onClickMenuTagEdit(event, inspectionGiveTag)
                          }
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </MenuItem>
                    ))}
                  </MenuList>
                  <FormControl fullWidth>
                    <TextField
                      label={t("new_tag")}
                      value={tagNew}
                      inputProps={{
                        maxLength: 100,
                      }}
                      InputProps={{
                        endAdornment: (
                          <Button variant="link" onClick={handleCreateTag}>
                            <FontAwesomeIcon icon={faCaretRight} />
                          </Button>
                        ),
                      }}
                      onChange={(event) => {
                        setTagNew(event.target.value);
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          handleCreateTag();
                        }
                      }}
                    />
                  </FormControl>
                </>
              )}
            </div>
          </Popover>
        </>
      )}
    </td>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    popContent: {
      padding: spacing(2),
    },
    tagMenuItem: {
      height: "40px",
    },
    btnMenuTagEdit: {
      position: "absolute",
      right: 0,
    },
  });

export default withStyles(styles)(InspectionGiveQuestionTags);
