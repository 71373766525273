import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { CommissionUserSettingType, UserType } from "../../entity/types";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/formatting";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  user: UserType;
  commissionUserSetting: CommissionUserSettingType;
}

function CommissionHeaderUserSalary({
  classes,
  user,
  commissionUserSetting,
}: Props) {
  const { t } = useTranslation();

  return (
    <h4 className={classes.h4}>
      {user.firstName} {user.lastName}{" "}
      {commissionUserSetting.commissionTemplate && (
        <small className="ms-3 text-muted">
          {t("base_salary_x", {
            total: t("currency", {
              amount: formatNumber(
                commissionUserSetting.commissionTemplate.baseSalary,
                2
              ),
            }),
          })}
        </small>
      )}
    </h4>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    h4: {
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(4),
    },
  });

export default withStyles(styles)(CommissionHeaderUserSalary);
