import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CustomerType,
  Mutation,
  MutationCreateCustomerArgs,
  MutationSelectCustomerCatalogActiveArgs,
  MutationUpdateOrderArgs,
  OrderType,
  SelectCustomerCatalogActive,
} from "../../entity/types";
import { Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentCustomer from "./DialogContentCustomer";
import DialogActions from "@material-ui/core/DialogActions";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { useMutation } from "@apollo/client";
import {
  CREATE_CUSTOMER_MUTATION,
  MutationResultCreateCustomer,
  SELECT_CUSTOMER_CATALOG_ACTIVE_MUTATION,
} from "../../apollo/mutations/customers";
import { CustomerEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import { UPDATE_ORDER_MUTATION } from "../../apollo/mutations/orders";
import { getQueryKey } from "../../utils/cache";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerType>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  order?: OrderType;
  callbackCreateCustomer?: Function;
}

function DialogCustomer({
  classes,
  customer,
  setCustomer,
  open,
  setOpen,
  order,
  callbackCreateCustomer,
}: Props) {
  const { t } = useTranslation();

  const [createCustomer] = useMutation<
    MutationResultCreateCustomer,
    MutationCreateCustomerArgs
  >(CREATE_CUSTOMER_MUTATION, {
    onCompleted: (result) => {
      setCustomer(CustomerEmpty);
      if (callbackCreateCustomer && result.createCustomer) {
        callbackCreateCustomer(result.createCustomer.customer);
      }
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customers"),
      });
    },
  });

  const [selectCustomerCatalogActive] = useMutation<
    SelectCustomerCatalogActive,
    MutationSelectCustomerCatalogActiveArgs
  >(SELECT_CUSTOMER_CATALOG_ACTIVE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customers"),
      });
    },
  });

  const [updateOrder, { loading: loadingUpdateOrder }] = useMutation<
    Mutation,
    MutationUpdateOrderArgs
  >(UPDATE_ORDER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    createCustomer({
      variables: {
        businessId: customer.businessId,
        name: customer.name,
        contactPersonFirstname: customer.contactPersonFirstname,
        contactPersonLastname: customer.contactPersonLastname,
        email: customer.email,
        phone: customer.phone,
        visitAddress: customer.visitAddress,
        visitPostcode: customer.visitPostcode,
        visitDistrict: customer.visitDistrict,
        invoicingAddress: customer.invoicingAddress,
        invoicingDistrict: customer.invoicingDistrict,
        invoicingEmail: customer.invoicingEmail,
        invoicingPostcode: customer.invoicingPostcode,
        invoicingEAddress: customer.invoicingEAddress,
        invoicingEOperator: customer.invoicingEOperator,
        industry: customer.industry,
        contactNext: customer.contactNext ? customer.contactNext : null,
        isBusiness: customer.isBusiness,
        creditInformationOk: customer.creditInformationOk,
        hasInsurance: customer.hasInsurance,
        creditInformationCheckedAt: customer.creditInformationCheckedAt,
        creditRiskClass: customer.creditRiskClass,
        preInvoicingAt: customer.preInvoicingAt,
        preInvoicingReason: customer.preInvoicingReason,
        blockedAt: customer.blockedAt,
        blockedReason: customer.blockedReason,
        userOwnerId: customer.userOwner ? customer.userOwner.id : ID_EMPTY,
        useParentInvoicing: customer.useParentInvoicing
          ? customer.useParentInvoicing
          : false,
        useParentPricing: customer.useParentPricing
          ? customer.useParentPricing
          : false,
      },
    }).then((result) => {
      const customerId = result.data?.createCustomer?.customer?.id;

      if (customerId) {
        selectCustomerCatalogActive({
          variables: { customerId: customerId },
        });

        if (order && order.id !== ID_EMPTY) {
          updateOrder({
            variables: {
              orderId: order.id,
              customerId: customerId,
              referenceId: order.reference ? order.reference.id : ID_EMPTY,
              locationId: order.location.id,
              information: order.information,
              informationInvoice: order.informationInvoice,
              confirmationType: order.confirmationType,
              hasInsurance: order.hasInsurance,
              updatePricesInCatalogSwitch: order.updatePricesInCatalogSwitch,
            },
          });
        }
      }
    });
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <form onSubmit={(event) => handleSubmit(event)}>
        <DialogTitle>{t("new_customer")}</DialogTitle>
        <DialogContentCustomer customer={customer} setCustomer={setCustomer} />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <ButtonLoad
            loading={loadingUpdateOrder}
            type="submit"
            variant="primary"
          >
            {t("save")}
          </ButtonLoad>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCustomer);
