import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Mutation,
  MutationDeleteTransportInvoicingSummaryArgs,
  MutationUpdateTransportInvoicingSummaryArgs,
  OrderType,
  ReferenceType,
  TransportInvoicingSummaryType,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { dialogConfirm } from "../../utils/dialogs";
import { useMutation } from "@apollo/client";
import {
  DELETE_TRANSPORT_INVOICING_SUMMARY_MUTATION,
  UPDATE_TRANSPORT_INVOICING_SUMMARY_MUTATION,
} from "../../apollo/mutations/transports";
import { GET_TRANSPORT_INVOICING_SUMMARIES_BY_ORDER_QUERY } from "../../apollo/queries/transports";
import { handleError } from "../../entity/ErrorHandler";
import { ID_EMPTY } from "../../utils/constants";
import { ReferenceEmpty } from "../../entity/empties";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { getPercentFactor } from "../../utils/calc";
import { findFromSetById } from "../../utils/collections";

interface Props extends WithStyles<typeof styles> {
  order: OrderType;
  transportInvoicingSummary: TransportInvoicingSummaryType;
  references: ReferenceType[];
  total: number;
}

function TableRowTransportInvoicingSummary({
  classes,
  order,
  transportInvoicingSummary,
  references,
  total,
}: Props) {
  const { t } = useTranslation();

  const [transportInvoicingSummaryEdited, setTransportInvoicingEdited] =
    useState({
      ...transportInvoicingSummary,
      percent: formatNumber(transportInvoicingSummary.percent, 2),
      price: formatNumber(transportInvoicingSummary.price, 2),
    });

  const [updateTransportInvoicingSummary, { loading: loadingUpdate }] =
    useMutation<Mutation, MutationUpdateTransportInvoicingSummaryArgs>(
      UPDATE_TRANSPORT_INVOICING_SUMMARY_MUTATION,
      {
        refetchQueries: [
          {
            query: GET_TRANSPORT_INVOICING_SUMMARIES_BY_ORDER_QUERY,
            variables: { orderId: order.id },
          },
        ],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const [deleteTransportInvoicingSummary, { loading: loadingDelete }] =
    useMutation<Mutation, MutationDeleteTransportInvoicingSummaryArgs>(
      DELETE_TRANSPORT_INVOICING_SUMMARY_MUTATION,
      {
        refetchQueries: [
          {
            query: GET_TRANSPORT_INVOICING_SUMMARIES_BY_ORDER_QUERY,
            variables: { orderId: order.id },
          },
        ],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  // Regenerate summary rows if source row total changes
  useEffect(() => {
    whenPercentChanges(transportInvoicingSummaryEdited.percent);
    // eslint-disable-next-line
  }, [total]);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "transports.change_transportinvoicingsummary",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "transports.delete_transportinvoicingsummary",
  ]);

  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteTransportInvoicingSummary({
        variables: {
          transportInvoicingSummaryId: transportInvoicingSummaryEdited.id,
        },
      });
    });
  };

  const handleUpdate = (
    values: { [key: string]: string | number | [] } = {}
  ) => {
    const base: MutationUpdateTransportInvoicingSummaryArgs = {
      transportInvoicingSummaryId: transportInvoicingSummaryEdited.id,
      orderId: transportInvoicingSummaryEdited.order.id,
      referenceId: transportInvoicingSummaryEdited.reference
        ? transportInvoicingSummaryEdited.reference.id
        : ID_EMPTY,
      percent: parseNumber(transportInvoicingSummaryEdited.percent),
      price: parseNumber(transportInvoicingSummaryEdited.price),
    };

    updateTransportInvoicingSummary({
      variables: { ...base, ...values },
    });
  };

  const whenPercentChanges = (percentRaw: string) => {
    const percentNew = formatNumber(percentRaw, 2);
    const priceNew = total * getPercentFactor(percentNew);
    setTransportInvoicingEdited({
      ...transportInvoicingSummaryEdited,
      percent: percentNew,
      price: formatNumber(priceNew, 2),
    });
    handleUpdate({
      percent: parseNumber(percentNew),
      price: priceNew,
    });
  };

  const hasBeenInvoiced = Boolean(transportInvoicingSummaryEdited.invoiceRow);
  const disabled = !hasPermissionEdit || hasBeenInvoiced;

  return (
    <tr className={loadingDelete || loadingUpdate ? "loading" : ""}>
      <td>
        <FormControl fullWidth>
          <Select
            value={
              transportInvoicingSummaryEdited.reference
                ? transportInvoicingSummaryEdited.reference.id
                : ID_EMPTY
            }
            disabled={disabled}
            onChange={(event) => {
              const idNew = String(event.target.value);
              const referenceNew = findFromSetById(idNew, references, {
                ...ReferenceEmpty,
                id: idNew,
              });

              setTransportInvoicingEdited({
                ...transportInvoicingSummaryEdited,
                reference: referenceNew,
              });
              handleUpdate({ referenceId: idNew });
            }}
          >
            <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
            {references.map((reference) => (
              <MenuItem key={reference.id} value={reference.id}>
                {reference.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </td>
      <td className={classes.cellSmall}>
        <FormControl fullWidth>
          <TextField
            value={transportInvoicingSummaryEdited.percent}
            disabled={disabled}
            onChange={(event) => {
              setTransportInvoicingEdited({
                ...transportInvoicingSummaryEdited,
                percent: event.target.value,
              });
            }}
            onBlur={(event) => {
              whenPercentChanges(event.target.value);
            }}
          />
        </FormControl>
      </td>
      <td className={classes.cellSmall}>
        <TextField
          value={transportInvoicingSummaryEdited.price}
          disabled={true}
        />
      </td>
      <td>
        {hasPermissionDelete && !hasBeenInvoiced && (
          <Button onClick={onClickDelete} variant="light" size="sm">
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
      </td>
    </tr>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    cellSmall: {
      width: "7rem",
    },
  });

export default withStyles(styles)(TableRowTransportInvoicingSummary);
