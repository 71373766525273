import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import fiTranslations from "./languages/fi/translation.json";
import enTranslations from "./languages/en/translation.json";

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translations: enTranslations,
    },
    fi: {
      translations: fiTranslations,
    },
  },
  fallbackLng: "fi",

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },
});

export default i18n;
