import { gql } from "@apollo/client";

export const CREATE_OPERATION_HOUR_MUTATION = gql`
  mutation createOperationHour(
    $reservationId: ID!
    $machineId: ID!
    $maintenanceId: ID!
    $dateChecked: Date!
    $hours: Int!
    $source: String!
  ) {
    createOperationHour(
      reservationId: $reservationId
      machineId: $machineId
      maintenanceId: $maintenanceId
      dateChecked: $dateChecked
      hours: $hours
      source: $source
    ) {
      operationHour {
        id
        dateChecked
        hours
      }
    }
  }
`;

export const UPDATE_OPERATION_HOUR_MUTATION = gql`
  mutation updateOperationHour(
    $operationHourId: ID!
    $dateChecked: Date!
    $hours: Int!
  ) {
    updateOperationHour(
      operationHourId: $operationHourId
      dateChecked: $dateChecked
      hours: $hours
    ) {
      operationHour {
        id
        dateChecked
        hours
      }
    }
  }
`;

export const DELETE_OPERATION_HOUR_MUTATION = gql`
  mutation deleteOperationHour($operationHourId: ID!) {
    deleteOperationHour(operationHourId: $operationHourId) {
      operationHourId
    }
  }
`;
