import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  CommissionTemplateType,
  QueryCommissionUserSettingArgs,
  UserType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import {
  GET_COMMISSION_USER_SETTING_QUERY,
  QueryResultCommissionUserSetting,
} from "../../apollo/queries/commissions";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import CommissionUserSettings from "./CommissionUserSettings";
import { CommissionUserSettingEmpty } from "../../entity/empties";
import { WithStyles } from "@material-ui/core/styles";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  userSeller: UserType;
  commissionTemplates: CommissionTemplateType[];
}

function CommissionSettingsForUser({
  classes,
  userSeller,
  commissionTemplates,
}: Props) {
  const { t } = useTranslation();

  const {
    loading: loadingCommissionUserSetting,
    error: errorCommissionUserSetting,
    data: dataCommissionUserSetting,
  } = useQuery<
    QueryResultCommissionUserSetting,
    QueryCommissionUserSettingArgs
  >(GET_COMMISSION_USER_SETTING_QUERY, {
    fetchPolicy: getQueryFetchPolicy("commissionUserSetting"),
    variables: { userId: userSeller.id },
  });

  if (loadingCommissionUserSetting) return <LoadingSimple />;
  if (errorCommissionUserSetting) {
    return <Error error={errorCommissionUserSetting} />;
  }
  if (!dataCommissionUserSetting) {
    return <Error error={t("error_query_failed")} />;
  }

  const commissionUserSetting = dataCommissionUserSetting.commissionUserSetting
    ? dataCommissionUserSetting.commissionUserSetting
    : CommissionUserSettingEmpty;

  return (
    <>
      <h4>
        {userSeller.firstName} {userSeller.lastName}
      </h4>
      <CommissionUserSettings
        commissionUserSetting={commissionUserSetting}
        commissionTemplates={commissionTemplates}
      />
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CommissionSettingsForUser);
