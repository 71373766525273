import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DialogOrder from "../Calendar/DialogOrder";
import { QueryOrderArgs, ReservationType } from "../../entity/types";
import { useQuery } from "@apollo/client";
import { GET_ORDER_QUERY, QueryResultOrder } from "../../apollo/queries/orders";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import Error from "../Shared/Error";
import LoadingDialog from "../Shared/LoadingDialog";
import {
  CustomerEmpty,
  OrderEmpty,
  ReservationEmpty,
} from "../../entity/empties";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  orderId: string;
  reservationIdSelected: string;
}

const DialogOrderId = ({
  classes,
  open,
  setOpen,
  orderId,
  reservationIdSelected,
}: Props) => {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<QueryResultOrder, QueryOrderArgs>(
    GET_ORDER_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("order"),
      variables: { orderId: orderId },
    }
  );

  if (loading) return <LoadingDialog />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const reservationSelected: ReservationType | undefined =
    data.order?.reservationSet.find(
      (reservationLooped) => reservationLooped.id === reservationIdSelected
    );

  return (
    <DialogOrder
      order={data.order ? data.order : OrderEmpty}
      setOpen={setOpen}
      open={open}
      customer={data.order?.customer ? data.order.customer : CustomerEmpty}
      reservationSelected={
        reservationSelected ? reservationSelected : ReservationEmpty
      }
    />
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogOrderId);
