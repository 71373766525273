import { gql } from "@apollo/client";

export const CREATE_USER_MUTATION = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $phone: String!
    $address: String!
    $postcode: String!
    $district: String!
    $language: String!
    $groupId: ID!
    $locationId: ID
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      phone: $phone
      address: $address
      postcode: $postcode
      district: $district
      language: $language
      groupId: $groupId
      locationId: $locationId
    ) {
      user {
        id
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $userId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $phone: String!
    $address: String!
    $postcode: String!
    $district: String!
    $language: String!
  ) {
    updateUser(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      language: $language
    ) {
      user {
        id
        firstName
        lastName
        email
        password
        roles {
          id
        }
        language
      }
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId) {
      userId
    }
  }
`;
