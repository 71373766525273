import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  MaintenanceImageImageType,
  MaintenanceImageType,
  Mutation,
  MutationDeleteMaintenanceImageArgs,
  MutationUpdateMaintenanceImageArgs,
} from "../../entity/types";
import { Col, FormText, Image, Row } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { dialogConfirm } from "../../utils/dialogs";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import ButtonLoad from "../Shared/ButtonLoad";
import {
  DELETE_MAINTENANCE_IMAGE_MUTATION,
  UPDATE_MAINTENANCE_IMAGE_MUTATION,
} from "../../apollo/mutations/maintenances";
import TextFieldFocus from "../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {
  maintenanceImage: MaintenanceImageType;
}

function MaintenanceImage({ classes, maintenanceImage }: Props) {
  const { t } = useTranslation();

  const [maintenanceImageEdited, setMaintenanceImageEdited] =
    useState(maintenanceImage);

  const [deleteMaintenanceImage, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteMaintenanceImageArgs
  >(DELETE_MAINTENANCE_IMAGE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("maintenanceImages"),
      });
    },
  });

  const [updateMaintenanceImage, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateMaintenanceImageArgs
  >(UPDATE_MAINTENANCE_IMAGE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("maintenanceImages"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "maintenance.delete_maintenanceimage",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "maintenance.change_maintenanceimage",
  ]);

  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteMaintenanceImage({
        variables: {
          maintenanceImageId: maintenanceImageEdited.id,
        },
      });
    });
  };

  const handleUpdate = (values: { [key: string]: string } = {}) => {
    const base: MutationUpdateMaintenanceImageArgs = {
      maintenanceImageId: maintenanceImageEdited.id,
      imageType: maintenanceImageEdited.imageType,
      eurosOwnCosts: maintenanceImageEdited.eurosOwnCosts,
      eurosWarranty: maintenanceImageEdited.eurosWarranty,
      information: maintenanceImageEdited.information,
    };

    updateMaintenanceImage({
      variables: { ...base, ...values },
    });
  };

  return (
    <div key={maintenanceImageEdited.id} className={classes.conImage}>
      <a href={maintenanceImageEdited.image} target="_blank" rel="noreferrer">
        <Image
          className="mb-2"
          src={maintenanceImageEdited.image}
          thumbnail
          fluid
        />
      </a>
      <div
        className={`flex-grow-1 ${classes.imgFields} ${
          loadingUpdate ? "loading" : ""
        }`}
      >
        <div className={classes.imgFieldsInner}>
          <FormText>{t("file_name")}</FormText>
          <p className="mb-0">{maintenanceImageEdited.imageName}</p>
          <FormControl component="fieldset" className={classes.radioContainer}>
            <RadioGroup
              aria-label={t("invoice")}
              name="deliveryMethod"
              value={maintenanceImageEdited.imageType}
              onChange={(event) => {
                setMaintenanceImageEdited({
                  ...maintenanceImageEdited,
                  imageType: event.target.value as MaintenanceImageImageType,
                });
                handleUpdate();
              }}
            >
              <FormControlLabel
                disabled={!hasPermissionEdit}
                value={MaintenanceImageImageType.Invoice}
                control={<Radio />}
                label={t("invoice_image")}
                checked={
                  maintenanceImageEdited.imageType ===
                  MaintenanceImageImageType.Invoice
                }
              />
              <Row className="ms-3">
                <Col xs={12} sm={6} md={4}>
                  <TextFieldFocus
                    type="number"
                    disabled={!hasPermissionEdit}
                    value={maintenanceImageEdited.eurosWarranty}
                    onChange={(event) => {
                      setMaintenanceImageEdited({
                        ...maintenanceImageEdited,
                        eurosWarranty: Number(event.target.value),
                      });
                    }}
                    onBlur={() => handleUpdate()}
                  />
                </Col>
                <Col xs={0} sm={6} md={8} className={classes.textContainer}>
                  <FormText className={classes.textContent}>
                    {t("euros_warranty")}
                  </FormText>
                </Col>
              </Row>
              <Row className="ms-3">
                <Col xs={12} sm={6} md={4}>
                  <TextFieldFocus
                    type="number"
                    disabled={!hasPermissionEdit}
                    value={maintenanceImageEdited.eurosOwnCosts}
                    onChange={(event) => {
                      setMaintenanceImageEdited({
                        ...maintenanceImageEdited,
                        eurosOwnCosts: Number(event.target.value),
                      });
                    }}
                    onBlur={() => handleUpdate()}
                  />
                </Col>
                <Col xs={0} sm={6} md={8} className={classes.textContainer}>
                  <FormText className={classes.textContent}>
                    {t("euros_own_cost")}
                  </FormText>
                </Col>
              </Row>
              <FormControlLabel
                checked={
                  maintenanceImageEdited.imageType ===
                  MaintenanceImageImageType.Other
                }
                disabled={!hasPermissionEdit}
                value={MaintenanceImageImageType.Other}
                control={<Radio />}
                label={t("other_image")}
              />
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label={t("information")}
              value={maintenanceImageEdited.information}
              inputProps={{ maxLength: 100 }}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setMaintenanceImageEdited({
                  ...maintenanceImageEdited,
                  information: event.target.value,
                });
              }}
              onBlur={() => handleUpdate()}
            />
          </FormControl>
        </div>
      </div>
      <ButtonLoad
        loading={loadingDelete}
        className={classes.btnDelete}
        variant="light"
        onClick={() => onClickDelete()}
        disabled={!hasPermissionDelete}
      >
        <FontAwesomeIcon icon={faTrash} />
      </ButtonLoad>
    </div>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    conImage: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    imgFields: {
      display: "flex",
      marginBottom: spacing(2),
    },
    imgFieldsInner: {
      display: "inline-block",
      alignSelf: "flex-end",
      width: "100%",
    },
    btnDelete: {
      position: "absolute",
      top: 0,
      right: 0,
    },

    radioContainer: {
      display: "block",
      marginTop: "2em",
    },
    textContainer: {
      position: "relative",
    },
    textContent: {
      position: "absolute",
      top: "0.5em",
    },
  });

export default withStyles(styles)(MaintenanceImage);
