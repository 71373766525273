import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { createStyles, MenuItem, Theme } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { UPDATE_PROFILE_MUTATION } from "../../apollo/mutations/profiles";
import { handleError } from "../../entity/ErrorHandler";
import { checkPermission } from "../../utils/permissions";
import { PermissionsContext } from "../../Root";
import {
  GroupType,
  Mutation,
  MutationUpdateGroupArgs,
} from "../../entity/types";

interface Props extends WithStyles<typeof styles> {
  group: GroupType;
  handleCloseProfile: Function;
}

function UpdateProfile({ classes, group, handleCloseProfile }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(group.name);

  const permissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(permissions, ["auth.change_group"]);

  const [updateProfile] = useMutation<Mutation, MutationUpdateGroupArgs>(
    UPDATE_PROFILE_MUTATION,
    {
      onError: (error) => {
        handleError(error);
      },
    }
  );

  if (!hasPermissionEdit) {
    return null;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateProfile({
      variables: { groupId: group.id, name },
    });
    setOpen(false);
  };

  const handleClick = () => {
    handleCloseProfile();
    setOpen(true);
  };

  return (
    <>
      <MenuItem onClick={handleClick}>{t("edit")}</MenuItem>
      <Dialog open={open}>
        <form onSubmit={(event) => handleSubmit(event)}>
          <DialogTitle>{t("edit_profile")}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                label={t("name")}
                className={classes.textField}
                onChange={(event) => setName(event.target.value)}
                value={name}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="secondary">
              {t("cancel")}
            </Button>
            <Button disabled={!name.trim()} type="submit" variant="primary">
              {t("save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    textField: {
      margin: spacing(1),
    },
  });

export default withStyles(styles)(UpdateProfile);
