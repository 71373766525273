import { gql } from "@apollo/client";

export const CREATE_CATALOG_EXTRA_CATEGORY_UPPER_RENTAL_MUTATION = gql`
  mutation createCatalogExtraCategoryUpperRental($name: String!) {
    createCatalogExtraCategoryUpperRental(name: $name) {
      catalogExtraCategoryUpperRental {
        id
      }
    }
  }
`;

export const CREATE_CATALOG_EXTRA_CATEGORY_RENTAL_MUTATION = gql`
  mutation createCatalogExtraCategoryRental(
    $catalogExtraCategoryUpperRentalId: ID!
    $name: String!
  ) {
    createCatalogExtraCategoryRental(
      catalogExtraCategoryUpperRentalId: $catalogExtraCategoryUpperRentalId
      name: $name
    ) {
      catalogExtraCategoryRental {
        id
      }
    }
  }
`;

export const CREATE_CATALOG_EXTRA_CATEGORY_UNIT_PRICE_MUTATION = gql`
  mutation createCatalogExtraCategoryUnitPrice(
    $name: String!
    $printSeparately: Boolean!
  ) {
    createCatalogExtraCategoryUnitPrice(
      name: $name
      printSeparately: $printSeparately
    ) {
      catalogExtraCategoryUnitPrice {
        id
      }
    }
  }
`;

export const UPDATE_CATALOG_EXTRA_CATEGORY_UPPER_RENTAL_MUTATION = gql`
  mutation updateCatalogExtraCategoryUpperRental(
    $catalogExtraCategoryUpperRentalId: ID!
    $name: String!
  ) {
    updateCatalogExtraCategoryUpperRental(
      catalogExtraCategoryUpperRentalId: $catalogExtraCategoryUpperRentalId
      name: $name
    ) {
      catalogExtraCategoryUpperRental {
        id
        name
      }
    }
  }
`;

export const UPDATE_CATALOG_EXTRA_CATEGORY_RENTAL_MUTATION = gql`
  mutation updateCatalogExtraCategoryRental(
    $catalogExtraCategoryRentalId: ID!
    $catalogExtraCategoryUpperRentalId: ID!
    $name: String!
  ) {
    updateCatalogExtraCategoryRental(
      catalogExtraCategoryRentalId: $catalogExtraCategoryRentalId
      catalogExtraCategoryUpperRentalId: $catalogExtraCategoryUpperRentalId
      name: $name
    ) {
      catalogExtraCategoryRental {
        id
        name
      }
    }
  }
`;

export const UPDATE_CATALOG_EXTRA_CATEGORY_UNIT_PRICE_MUTATION = gql`
  mutation updateCatalogExtraCategoryUnitPrice(
    $catalogExtraCategoryUnitPriceId: ID!
    $name: String!
    $printSeparately: Boolean!
  ) {
    updateCatalogExtraCategoryUnitPrice(
      catalogExtraCategoryUnitPriceId: $catalogExtraCategoryUnitPriceId
      name: $name
      printSeparately: $printSeparately
    ) {
      catalogExtraCategoryUnitPrice {
        id
        name
        printSeparately
      }
    }
  }
`;

export const CREATE_CATALOG_EXTRA_ROW_RENTAL_MUTATION = gql`
  mutation createCatalogExtraRowRental(
    $catalogExtraCategoryRentalId: ID!
    $locationId: ID!
    $name: String!
    $productNumber: String!
    $identifier: String!
    $information: String!
    $billingDaysWeekCompany: Int!
    $priceDayCompany: Decimal!
    $priceMonthCompany: Decimal!
    $billingDaysWeekPrivate: Int!
    $priceDayPrivate: Decimal!
    $priceMonthPrivate: Decimal!
    $showPrint: Boolean!
  ) {
    createCatalogExtraRowRental(
      catalogExtraCategoryRentalId: $catalogExtraCategoryRentalId
      locationId: $locationId
      name: $name
      productNumber: $productNumber
      identifier: $identifier
      information: $information
      billingDaysWeekCompany: $billingDaysWeekCompany
      priceDayCompany: $priceDayCompany
      priceMonthCompany: $priceMonthCompany
      billingDaysWeekPrivate: $billingDaysWeekPrivate
      priceDayPrivate: $priceDayPrivate
      priceMonthPrivate: $priceMonthPrivate
      showPrint: $showPrint
    ) {
      catalogExtraRowRental {
        id
      }
    }
  }
`;

export const CREATE_CATALOG_EXTRA_ROW_UNIT_PRICE_MUTATION = gql`
  mutation createCatalogExtraRowUnitPrice(
    $catalogExtraCategoryUnitPriceId: ID!
    $name: String!
    $productCodeId: ID!
    $unit: String!
    $unitPrice: Decimal!
    $allowDiscount: Boolean!
    $requireAnswer: Boolean!
    $minCharge: Int!
    $showPrint: Boolean!
  ) {
    createCatalogExtraRowUnitPrice(
      catalogExtraCategoryUnitPriceId: $catalogExtraCategoryUnitPriceId
      name: $name
      productCodeId: $productCodeId
      unit: $unit
      unitPrice: $unitPrice
      allowDiscount: $allowDiscount
      requireAnswer: $requireAnswer
      minCharge: $minCharge
      showPrint: $showPrint
    ) {
      catalogExtraRowUnitPrice {
        id
      }
    }
  }
`;

export const UPDATE_CATALOG_EXTRA_ROW_RENTAL_MUTATION = gql`
  mutation updateCatalogExtraRowRental(
    $catalogExtraRowRentalId: ID!
    $locationId: ID!
    $name: String!
    $productNumber: String!
    $identifier: String!
    $information: String!
    $catalogExtraCategoryRentalId: ID!
    $billingDaysWeekCompany: Int!
    $priceDayCompany: Decimal!
    $priceMonthCompany: Decimal!
    $billingDaysWeekPrivate: Int!
    $priceDayPrivate: Decimal!
    $priceMonthPrivate: Decimal!
    $showPrint: Boolean!
  ) {
    updateCatalogExtraRowRental(
      catalogExtraRowRentalId: $catalogExtraRowRentalId
      locationId: $locationId
      name: $name
      productNumber: $productNumber
      identifier: $identifier
      information: $information
      catalogExtraCategoryRentalId: $catalogExtraCategoryRentalId
      billingDaysWeekCompany: $billingDaysWeekCompany
      priceDayCompany: $priceDayCompany
      priceMonthCompany: $priceMonthCompany
      billingDaysWeekPrivate: $billingDaysWeekPrivate
      priceDayPrivate: $priceDayPrivate
      priceMonthPrivate: $priceMonthPrivate
      showPrint: $showPrint
    ) {
      catalogExtraRowRental {
        id
        location {
          id
        }
        name
        catalogExtraCategoryRental {
          id
        }
        billingDaysWeekCompany
        priceDayCompany
        priceMonthCompany
        billingDaysWeekPrivate
        priceDayPrivate
        priceMonthPrivate
        showPrint
      }
    }
  }
`;

export const MOVE_CATALOG_EXTRA_ROW_RENTAL_TO_LOCATION_MUTATION = gql`
  mutation moveCatalogExtraRowRentalToLocation(
    $catalogExtraRowRentalId: ID!
    $locationId: ID!
  ) {
    moveCatalogExtraRowRentalToLocation(
      catalogExtraRowRentalId: $catalogExtraRowRentalId
      locationId: $locationId
    ) {
      catalogExtraRowRental {
        id
        location {
          id
        }
      }
    }
  }
`;

export const UPDATE_CATALOG_EXTRA_ROW_UNIT_PRICE_MUTATION = gql`
  mutation updateCatalogExtraRowUnitPrice(
    $catalogExtraRowUnitPriceId: ID!
    $catalogExtraCategoryUnitPriceId: ID!
    $name: String!
    $productCodeId: ID!
    $unit: String!
    $unitPrice: Decimal!
    $allowDiscount: Boolean!
    $requireAnswer: Boolean!
    $minCharge: Int!
    $showPrint: Boolean!
  ) {
    updateCatalogExtraRowUnitPrice(
      catalogExtraRowUnitPriceId: $catalogExtraRowUnitPriceId
      catalogExtraCategoryUnitPriceId: $catalogExtraCategoryUnitPriceId
      name: $name
      productCodeId: $productCodeId
      unit: $unit
      unitPrice: $unitPrice
      allowDiscount: $allowDiscount
      requireAnswer: $requireAnswer
      minCharge: $minCharge
      showPrint: $showPrint
    ) {
      catalogExtraRowUnitPrice {
        id
        catalogExtraCategoryUnitPrice {
          id
        }
        name
        productCode {
          id
          code
          description
        }
        unit
        unitPrice
        allowDiscount
        requireAnswer
        minCharge
        showPrint
      }
    }
  }
`;

export const DELETE_CATALOG_EXTRA_ROW_RENTAL_MUTATION = gql`
  mutation deleteCatalogExtraRowRental($catalogExtraRowRentalId: ID!) {
    deleteCatalogExtraRowRental(
      catalogExtraRowRentalId: $catalogExtraRowRentalId
    ) {
      catalogExtraRowRentalId
    }
  }
`;

export const DELETE_CATALOG_EXTRA_ROW_UNIT_PRICE_MUTATION = gql`
  mutation deleteCatalogExtraRowUnitPrice($catalogExtraRowUnitPriceId: ID!) {
    deleteCatalogExtraRowUnitPrice(
      catalogExtraRowUnitPriceId: $catalogExtraRowUnitPriceId
    ) {
      catalogExtraRowUnitPriceId
    }
  }
`;

export const DELETE_CATALOG_EXTRA_CATEGORY_UPPER_RENTAL_MUTATION = gql`
  mutation deleteCatalogExtraCategoryUpperRental(
    $catalogExtraCategoryUpperRentalId: ID!
  ) {
    deleteCatalogExtraCategoryUpperRental(
      catalogExtraCategoryUpperRentalId: $catalogExtraCategoryUpperRentalId
    ) {
      catalogExtraCategoryUpperRentalId
    }
  }
`;

export const DELETE_CATALOG_EXTRA_CATEGORY_RENTAL_MUTATION = gql`
  mutation deleteCatalogExtraCategoryRental(
    $catalogExtraCategoryRentalId: ID!
  ) {
    deleteCatalogExtraCategoryRental(
      catalogExtraCategoryRentalId: $catalogExtraCategoryRentalId
    ) {
      catalogExtraCategoryRentalId
    }
  }
`;

export const DELETE_CATALOG_EXTRA_CATEGORY_UNIT_PRICE_MUTATION = gql`
  mutation deleteCatalogExtraCategoryUnitPrice(
    $catalogExtraCategoryUnitPriceId: ID!
  ) {
    deleteCatalogExtraCategoryUnitPrice(
      catalogExtraCategoryUnitPriceId: $catalogExtraCategoryUnitPriceId
    ) {
      catalogExtraCategoryUnitPriceId
    }
  }
`;

export const CREATE_CATALOG_EXTRA_ROW_INVOICE_MUTATION = gql`
  mutation createCatalogExtraRowInvoice(
    $catalogExtraRowUnitPriceId: ID
    $reservationId: ID!
    $invoiceRowId: ID
    $productCodeId: ID!
    $titleOverride: String!
    $quantity: Decimal!
    $unitPriceOverride: Decimal!
    $answer: String!
  ) {
    createCatalogExtraRowInvoice(
      catalogExtraRowUnitPriceId: $catalogExtraRowUnitPriceId
      reservationId: $reservationId
      invoiceRowId: $invoiceRowId
      productCodeId: $productCodeId
      titleOverride: $titleOverride
      quantity: $quantity
      unitPriceOverride: $unitPriceOverride
      answer: $answer
    ) {
      catalogExtraRowInvoice {
        id
      }
    }
  }
`;

export const UPDATE_CATALOG_EXTRA_ROW_INVOICE_MUTATION = gql`
  mutation updateCatalogExtraRowInvoice(
    $catalogExtraRowInvoiceId: ID!
    $catalogExtraRowUnitPriceId: ID
    $reservationId: ID!
    $invoiceRowId: ID
    $productCodeId: ID!
    $titleOverride: String!
    $quantity: Decimal!
    $unitPriceOverride: Decimal!
    $answer: String!
  ) {
    updateCatalogExtraRowInvoice(
      catalogExtraRowInvoiceId: $catalogExtraRowInvoiceId
      catalogExtraRowUnitPriceId: $catalogExtraRowUnitPriceId
      reservationId: $reservationId
      invoiceRowId: $invoiceRowId
      productCodeId: $productCodeId
      titleOverride: $titleOverride
      quantity: $quantity
      unitPriceOverride: $unitPriceOverride
      answer: $answer
    ) {
      catalogExtraRowInvoice {
        id
        catalogExtraRowUnitPrice {
          id
        }
        reservation {
          id
        }
        invoiceRow {
          id
        }
        quantity
        answer
        productCode {
          id
        }
        titleOverride
        unitPriceOverride
      }
    }
  }
`;

export const DELETE_CATALOG_EXTRA_ROW_INVOICE_MUTATION = gql`
  mutation deleteCatalogExtraRowInvoice($catalogExtraRowInvoiceId: ID!) {
    deleteCatalogExtraRowInvoice(
      catalogExtraRowInvoiceId: $catalogExtraRowInvoiceId
    ) {
      catalogExtraRowInvoiceId
    }
  }
`;

export const COPY_CATALOG_EXTRA_ROW_UNIT_PRICE_MACHINES_MUTATION = gql`
  mutation copyCatalogExtraRowUnitPriceMachines(
    $catalogExtraRowUnitPriceIdCopyFrom: ID!
    $catalogExtraRowUnitPriceIdCopyTo: ID!
  ) {
    copyCatalogExtraRowUnitPriceMachines(
      catalogExtraRowUnitPriceIdCopyFrom: $catalogExtraRowUnitPriceIdCopyFrom
      catalogExtraRowUnitPriceIdCopyTo: $catalogExtraRowUnitPriceIdCopyTo
    ) {
      catalogExtraRowUnitPrice {
        id
        machineSet {
          id
        }
      }
    }
  }
`;

export const COPY_CATALOG_EXTRA_ROW_RENTAL_MACHINES_MUTATION = gql`
  mutation copyCatalogExtraRowRentalMachines(
    $catalogExtraRowRentalIdCopyFrom: ID!
    $catalogExtraRowRentalIdCopyTo: ID!
  ) {
    copyCatalogExtraRowRentalMachines(
      catalogExtraRowRentalIdCopyFrom: $catalogExtraRowRentalIdCopyFrom
      catalogExtraRowRentalIdCopyTo: $catalogExtraRowRentalIdCopyTo
    ) {
      catalogExtraRowRental {
        id
        machineSet {
          id
        }
      }
    }
  }
`;

export const UPDATE_CATALOG_EXTRA_CATEGORY_UPPER_RENTAL_ORDER_MUTATION = gql`
  mutation updateCatalogExtraCategoryUpperRentalOrder(
    $idOrderInput: [IdOrderInputType]
  ) {
    updateCatalogExtraCategoryUpperRentalOrder(idOrderInput: $idOrderInput) {
      catalogExtraCategoriesUpperRental {
        id
        order
      }
    }
  }
`;

export const UPDATE_CATALOG_EXTRA_CATEGORY_RENTAL_ORDER_MUTATION = gql`
  mutation updateCatalogExtraCategoryRentalOrder(
    $idOrderInput: [IdOrderInputType]
  ) {
    updateCatalogExtraCategoryRentalOrder(idOrderInput: $idOrderInput) {
      catalogExtraCategoriesRental {
        id
        order
      }
    }
  }
`;

export const UPDATE_CATALOG_EXTRA_ROW_RENTAL_ORDER_MUTATION = gql`
  mutation updateCatalogExtraRowRentalOrder($idOrderInput: [IdOrderInputType]) {
    updateCatalogExtraRowRentalOrder(idOrderInput: $idOrderInput) {
      catalogExtraRowsRental {
        id
        order
      }
    }
  }
`;
