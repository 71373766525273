import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { CatalogExtraCategoryUpperRentalType } from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";

interface Props extends WithStyles<typeof styles> {
  catalogExtraCategoryUpperRental: CatalogExtraCategoryUpperRentalType;
  setCatalogExtraCategoryUpperRental: React.Dispatch<
    React.SetStateAction<CatalogExtraCategoryUpperRentalType>
  >;
}

function DialogContentCatalogExtraCategoryUpperRental({
  classes,
  catalogExtraCategoryUpperRental,
  setCatalogExtraCategoryUpperRental,
}: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "catalogs_extra.add_catalogextracategoryupperrental",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs_extra.change_catalogextracategoryupperrental",
  ]);
  const disabled = catalogExtraCategoryUpperRental.id
    ? !hasPermissionEdit
    : !hasPermissionAdd;

  return (
    <DialogContent>
      <FormControl fullWidth>
        <TextField
          label={t("name")}
          onChange={(event) => {
            setCatalogExtraCategoryUpperRental({
              ...catalogExtraCategoryUpperRental,
              name: event.target.value,
            });
          }}
          value={catalogExtraCategoryUpperRental.name}
          required={true}
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          autoFocus={true}
        />
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentCatalogExtraCategoryUpperRental);
