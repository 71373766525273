import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import {
  GET_INVOICES_OPEN_QUERY,
  QueryResultInvoicesOpen,
} from "../../apollo/queries/invoices";
import { OrderType, QueryInvoicesOpenArgs } from "../../entity/types";
import ManagementInvoice from "../Management/ManagementInvoice";
import { DATE_FORMAT_ISO, ID_EMPTY } from "../../utils/constants";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import format from "date-fns/format";
import InvoiceListFilter from "./InvoiceListFilter";
import { resetDateTime } from "../../utils/dates";
import { UserContext } from "../../Root";
import { getLocationSelected } from "../../utils/locations/location";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { useTranslation } from "react-i18next";

interface Props extends WithStyles<typeof styles> {
  order?: OrderType;
  isAdvance?: boolean;
}

function InvoicesOpenList({ classes, order, isAdvance }: Props) {
  const { t } = useTranslation();

  const user = useContext(UserContext);

  const locationIdDefault = getLocationSelected(user);
  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([
    locationIdDefault,
  ]);
  const [dateTo, setDateTo] = useState(resetDateTime(new Date()));

  const [removedInvoices, setRemovedInvoices] = useState([""]);

  const {
    loading,
    error,
    data: dataInvoices,
  } = useQuery<QueryResultInvoicesOpen, QueryInvoicesOpenArgs>(
    GET_INVOICES_OPEN_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("invoicesOpen"),
      variables: {
        orderId: order ? order.id : ID_EMPTY,
        locationIds: locationIdsSelected,
        dateTo: format(new Date(), DATE_FORMAT_ISO),
        isAdvance: isAdvance,
      },
    }
  );

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!dataInvoices) return <Error error={t("error_query_failed")} />;

  const removeInvoiceFromDOM = (invoiceId: string) => {
    if (dataInvoices !== undefined) {
      dataInvoices.invoicesOpen?.forEach((invoice) => {
        if (invoice.id === invoiceId) {
          let joined = removedInvoices.concat(invoiceId);
          setRemovedInvoices(joined);
        }
      });
    }
  };

  return (
    <>
      {!isAdvance && (
        <InvoiceListFilter
          locationIdsSelected={locationIdsSelected}
          setLocationIdsSelected={setLocationIdsSelected}
          dateTo={dateTo}
          setDateTo={setDateTo}
        />
      )}
      <div>
        {dataInvoices &&
          dataInvoices.invoicesOpen?.map(
            (invoice) =>
              removedInvoices.indexOf(invoice.id) < 0 && (
                <ManagementInvoice
                  key={invoice.id}
                  invoice={invoice}
                  readonly={false}
                  callbackAfterSend={removeInvoiceFromDOM}
                />
              )
          )}
      </div>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InvoicesOpenList);
