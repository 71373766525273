import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { Button } from "react-bootstrap";
import { handleError } from "../../entity/ErrorHandler";
import DialogContentMachine from "./DialogContentMachine";
import {
  CREATE_MACHINE_MUTATION,
  MutationResultCreateMachine,
  SAVE_MACHINE_BULK_AMOUNT_MUTATION,
} from "../../apollo/mutations/machines";
import {
  CatalogType,
  MachineType,
  Mutation,
  MutationCreateMachineArgs,
  MutationSaveMachineBulkAmountArgs,
} from "../../entity/types";
import { MachineEmpty } from "../../entity/empties";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
}

function CreateMachine({ classes, catalog }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [machine, setMachine] = useState<MachineType>(MachineEmpty);

  const [saveMachineBulkAmount, { loading: loadingSaveBulkAmount }] =
    useMutation<Mutation, MutationSaveMachineBulkAmountArgs>(
      SAVE_MACHINE_BULK_AMOUNT_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const [createMachine, { loading: loadingCreate }] = useMutation<
    MutationResultCreateMachine,
    MutationCreateMachineArgs
  >(CREATE_MACHINE_MUTATION, {
    onCompleted: (result) => {
      const machine_id: string = result.createMachine?.machine
        ? result.createMachine.machine.id
        : ID_EMPTY;
      if (machine_id !== ID_EMPTY) {
        machine.machinebulkamountSet.forEach((machineBulkAmount) => {
          saveMachineBulkAmount({
            variables: {
              machineId: machine_id,
              locationId: machineBulkAmount.location.id,
              bulkAmount: machineBulkAmount.bulkAmount,
            },
          });
        });
      }

      setMachine(MachineEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("machinesForLocation"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("machinesForCatalogRows"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "machines.add_machine",
  ]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let catalogRowIds: string[] = [];
    machine.catalogRows.forEach(function (catalogRow) {
      catalogRowIds.push(catalogRow.id);
    });

    createMachine({
      variables: {
        machineModelId: machine.machineModel.id,
        information: machine.information,
        identifier: machine.identifier,
        serial: machine.serial,
        acquisitionDate: machine.acquisitionDate
          ? machine.acquisitionDate
          : null,
        dateRemoved: machine.dateRemoved ? machine.dateRemoved : null,
        yearModel: machine.yearModel,
        hourTracking: machine.hourTracking,
        rentThrough: machine.rentThrough,
        rentThroughCompany: machine.rentThroughCompany,
        rentThroughInformation: machine.rentThroughInformation,
        rentThroughCommissionCase: machine.rentThroughCommissionCase,
        bulkProduct: machine.bulkProduct,
        catalogRowIds: catalogRowIds,
        locationId: machine.location ? machine.location.id : ID_EMPTY,
      },
    });
    setOpen(false);
  };

  if (!hasPermissionAdd) {
    return <></>;
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className="addButton"
        variant="primary"
        size="lg"
      >
        {open ? <ClearIcon /> : <AddIcon />}
      </Button>
      <Dialog open={open}>
        <form
          className={loadingCreate || loadingSaveBulkAmount ? "loading" : ""}
          onSubmit={(event) => handleSubmit(event)}
        >
          <DialogTitle>{t("new_machine")}</DialogTitle>
          <DialogContentMachine
            machine={machine}
            setMachine={setMachine}
            catalog={catalog}
          />
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="secondary">
              {t("cancel")}
            </Button>
            <Button type="submit" variant="primary">
              {t("save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    textField: {
      marginTop: spacing(1),
      marginBottom: spacing(1),
    },
  });

export default withStyles(styles)(CreateMachine);
