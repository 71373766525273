import React from "react";
import { CatalogCategoryUpperType } from "../../entity/types";
import { ListSubheader, MenuItem } from "@material-ui/core";

export const renderSelectGroupCategoryRow = (
  catalogcategoryUpper: CatalogCategoryUpperType
) => {
  return catalogcategoryUpper.catalogcategorySet.map((catalogcategory) => {
    const itemsInner = catalogcategory.catalogrowSet.map((catalogRow) => {
      return (
        <MenuItem key={catalogRow.id} value={catalogRow.id}>
          {catalogRow.name}
        </MenuItem>
      );
    });

    return [<ListSubheader>{catalogcategory.name}</ListSubheader>, itemsInner];
  });
};

export const renderSelectGroupCategoryUpperCategory = (
  catalogCategoryUpper: CatalogCategoryUpperType
) => {
  const items = catalogCategoryUpper.catalogcategorySet.map(
    (catalogCategory) => {
      return (
        <MenuItem key={catalogCategory.id} value={catalogCategory.id}>
          {catalogCategory.name}
        </MenuItem>
      );
    }
  );
  return [<ListSubheader>{catalogCategoryUpper.name}</ListSubheader>, items];
};
