import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Box,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Theme,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { OrderType } from "../../entity/types";
import InvoicesOpenList from "../Invoice/InvoicesOpenList";
import { TabPanel } from "../Shared/TabPanel";
import InvoicesSentList from "../Invoice/InvoicesSentList";
import { newDate } from "../../utils/dates";
import InvoicesCreateListReferences from "../Invoice/InvoicesCreateListReferences";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  order: OrderType;
}

function DialogAdvanceInvoice({ classes, open, setOpen, order }: Props) {
  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = useState(0);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAllowAdvance = checkPermission(myPermissions, [
    "invoices.allow_advance_invoicing_without_perms",
  ]);
  const hasPermissionAddInvoice =
    checkPermission(myPermissions, ["invoices.add_invoice"]) ||
    hasPermissionAllowAdvance;
  const hasPermissionViewInvoice =
    checkPermission(myPermissions, ["invoices.view_invoice"]) ||
    hasPermissionAllowAdvance;

  const a11yProps = (index: number) => ({
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  });

  const handleChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setTabSelected(newTab);
  };

  const dateFrom = newDate("2020-01-01");
  const dateTo = new Date();

  return (
    <Dialog open={open} maxWidth="xl">
      <DialogTitle>{t("pre_invoicing")}</DialogTitle>
      <DialogContent>
        <Box>
          <Box>
            <Tabs value={tabSelected} onChange={handleChange}>
              {hasPermissionAddInvoice && (
                <Tab
                  label={t("management_invoices_create")}
                  {...a11yProps(0)}
                />
              )}
              {hasPermissionViewInvoice && (
                <Tab label={t("management_invoices_open")} {...a11yProps(1)} />
              )}
              {hasPermissionViewInvoice && (
                <Tab label={t("management_invoices_sent")} {...a11yProps(2)} />
              )}
            </Tabs>
          </Box>
          {hasPermissionAddInvoice && (
            <TabPanel value={tabSelected} index={0}>
              <InvoicesCreateListReferences order={order} isAdvance={true} />
            </TabPanel>
          )}
          {hasPermissionViewInvoice && (
            <TabPanel value={tabSelected} index={1}>
              <InvoicesOpenList order={order} isAdvance={true} />
            </TabPanel>
          )}
          {hasPermissionViewInvoice && (
            <TabPanel value={tabSelected} index={2}>
              <InvoicesSentList
                order={order}
                dateFrom={dateFrom}
                dateTo={dateTo}
              />
            </TabPanel>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogAdvanceInvoice);
