import i18n from "i18next";
import { TFunction } from "react-i18next";
const numeral = require("numeral");

const lang = i18n.language;

if (!numeral.locales.hasOwnProperty("fi")) {
  numeral.register("locale", "fi", {
    delimiters: {
      thousands: " ",
      decimal: ",",
    },
    abbreviations: {
      thousand: "k",
      million: "milj.",
      billion: "mrd.",
      trillion: "bilj.",
    },
    ordinal: function (number: number) {
      return ".";
    },
    currency: {
      symbol: "€",
    },
  });
}
numeral.locale(lang);

export function formatNumber(
  number: number | string,
  decimals: number = 0
): string {
  if (typeof number === "string") {
    number = parseNumber(number);
  }
  let format = "0,0.";
  if (decimals === 0) {
    format += "[00]";
  } else {
    format += "0".repeat(decimals);
  }
  return numeral(number).format(format);
}

export function parseNumber(
  input: number | string | undefined,
  roundToDecimals = -1
): number {
  let number = input;

  if (typeof input === "string" && !isNaN(Number(input))) {
    number = Number(input);
  }
  if (typeof number === "undefined") {
    number = 0;
  }
  if (typeof input === "number") {
    number = input;
  }

  let numeralValue = numeral(number).value();
  if (numeralValue === null) {
    numeralValue = 0;
  }
  if (roundToDecimals >= 0) {
    return Number(numeralValue.toFixed(roundToDecimals));
  }
  return numeralValue;
}

export function timeWithoutSeconds(time: string | null): string {
  if (time === null) return "";
  return time.replace(/(\d+)[:.](\d+)[:.]\d+/, "$1:$2");
}

export function getAddressSingleLine(
  t: TFunction<"translation">,
  address: string,
  postcode: string,
  district: string
): string {
  if (postcode === "" && district === "") {
    return address;
  }
  if (address === "") {
    return (postcode + " " + district).trim();
  }
  return t("address_single_line", {
    address: address,
    postcode: postcode,
    district: district,
  });
}
