import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Checkbox, createStyles, Theme } from "@material-ui/core";
import {
  CatalogRowType,
  DiscountListType,
  Mutation,
  MutationCreateDiscountRowArgs,
  MutationUpdateDiscountRowArgs,
} from "../../../entity/types";
import { formatNumber, parseNumber } from "../../../utils/formatting";
import { PermissionsContext, SettingsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { useMutation } from "@apollo/client";
import {
  CREATE_DISCOUNT_ROW_MUTATION,
  UPDATE_DISCOUNT_ROW_MUTATION,
} from "../../../apollo/mutations/discounts";
import { GET_DISCOUNT_LIST_QUERY } from "../../../apollo/queries/discounts";
import { handleError } from "../../../entity/ErrorHandler";
import {
  addStringToArr,
  removeStringFromArr,
} from "../../../utils/collections";
import {
  getPercentPrefixCalc,
  getPercentPrefixDB,
  getPercentPrefixUserInput,
} from "../../../utils/discounts/prefixes";
import { DiscountRowEmpty } from "../../../entity/empties";
import { ID_EMPTY } from "../../../utils/constants";
import { getPercentFactorDiscount } from "../../../utils/calc";
import TextFieldFocus from "../../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {
  catalogRow: CatalogRowType;
  setDiscountList: React.Dispatch<React.SetStateAction<DiscountListType>>;
  discountList: DiscountListType;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  timeClickedSet: number;
}

const DiscountRow = ({
  classes,
  catalogRow,
  setDiscountList,
  discountList,
  selectedRows,
  setSelectedRows,
  timeClickedSet,
}: Props) => {
  const settings = useContext(SettingsContext);

  let keyRow = -1;
  discountList.discountrowSet.forEach(function (discountRowLooped, key) {
    if (discountRowLooped.catalogRow.id === catalogRow.id) {
      keyRow = key;
      return;
    }
  });

  const discountRow =
    typeof discountList.discountrowSet[keyRow] !== "undefined"
      ? discountList.discountrowSet[keyRow]
      : DiscountRowEmpty;

  useEffect(() => {
    calculateNewPrices(discountRow.percent);
    // eslint-disable-next-line
  }, [timeClickedSet]);

  const percentFactorDefault = getPercentFactorDiscount(discountRow.percent);

  const [priceDayCompany, setPriceDayCompany] = useState(
    formatNumber(Number(catalogRow.priceDayCompany) * percentFactorDefault, 2)
  );
  const [priceDayCompanyVat, setPriceDayCompanyVat] = useState(
    formatNumber(
      Number(catalogRow.priceDayCompany) *
        settings.vatFactor *
        percentFactorDefault,
      2
    )
  );
  const [priceMonthCompany, setPriceMonthCompany] = useState(
    formatNumber(Number(catalogRow.priceMonthCompany) * percentFactorDefault, 2)
  );
  const [priceMonthCompanyVat, setPriceMonthCompanyVat] = useState(
    formatNumber(
      Number(catalogRow.priceMonthCompany) *
        settings.vatFactor *
        percentFactorDefault,
      2
    )
  );

  const [priceDayPrivate, setPriceDayPrivate] = useState(
    formatNumber(Number(catalogRow.priceDayPrivate) * percentFactorDefault, 2)
  );
  const [priceDayPrivateVat, setPriceDayPrivateVat] = useState(
    formatNumber(
      Number(catalogRow.priceDayPrivate) *
        settings.vatFactor *
        percentFactorDefault,
      2
    )
  );
  const [priceMonthPrivate, setPriceMonthPrivate] = useState(
    formatNumber(Number(catalogRow.priceMonthPrivate) * percentFactorDefault, 2)
  );
  const [priceMonthPrivateVat, setPriceMonthPrivateVat] = useState(
    formatNumber(
      Number(catalogRow.priceMonthPrivate) *
        settings.vatFactor *
        percentFactorDefault,
      2
    )
  );

  const [createDiscountRow, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateDiscountRowArgs
  >(CREATE_DISCOUNT_ROW_MUTATION, {
    refetchQueries: [
      {
        query: GET_DISCOUNT_LIST_QUERY,
        variables: {
          discountListId: discountList.id,
        },
      },
    ],
    onError: (error) => {
      handleError(error);
    },
  });

  const [updateDiscountRow, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateDiscountRowArgs
  >(UPDATE_DISCOUNT_ROW_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "discounts.change_discountrow",
  ]);

  const handleDiscountRowUpdate = (values: any) => {
    const base: { [key: string]: any } = {
      percent: parseNumber(discountRow.percent),
      showPrint: discountRow.showPrint,
      discountListId: discountList.id,
      catalogRowId: catalogRow.id,
    };

    let variables = { ...base, ...values };
    if (discountRow.id !== ID_EMPTY) {
      variables.discountRowId = discountRow.id;

      updateDiscountRow({
        variables: variables,
      });
    } else {
      createDiscountRow({
        variables: variables,
      });
    }
  };

  const setPercent = (value: string) => {
    const discountRowsNew = discountList.discountrowSet.map(
      (discountRowLooped) => ({ ...discountRowLooped })
    );
    discountRowsNew[keyRow].percent = value;
    setDiscountList({ ...discountList, discountrowSet: discountRowsNew });
  };
  const setPercentUserInput = (value: string) => {
    let prefix = getPercentPrefixUserInput(value);
    setPercent(prefix + formatNumber(parseNumber(value), 2));
  };
  const setPercentCalc = (
    valueOld: number,
    valueNew: number,
    changedField = ""
  ) => {
    let percentNew = (valueNew / valueOld) * 100 - 100;
    let percentFormatted = formatNumber(percentNew, 2);
    let prefix = getPercentPrefixDB(String(percentNew));
    handlePercentChange(prefix + percentFormatted, changedField);
  };

  const handlePercentChange = (percent: string, changedField = "") => {
    setPercent(percent);
    calculateNewPrices(percent, changedField);
  };

  const calculateNewPrices = (percent: string, changedField = "") => {
    const percentFactorNew = getPercentFactorDiscount(percent);

    if (changedField !== "priceDayCompany") {
      setPriceDayCompany(
        formatNumber(Number(catalogRow.priceDayCompany) * percentFactorNew, 2)
      );
    }
    if (changedField !== "priceDayCompanyVat") {
      setPriceDayCompanyVat(
        formatNumber(
          Number(catalogRow.priceDayCompany) *
            settings.vatFactor *
            percentFactorNew,
          2
        )
      );
    }
    if (changedField !== "priceMonthCompany") {
      setPriceMonthCompany(
        formatNumber(Number(catalogRow.priceMonthCompany) * percentFactorNew, 2)
      );
    }
    if (changedField !== "priceMonthCompanyVat") {
      setPriceMonthCompanyVat(
        formatNumber(
          Number(catalogRow.priceMonthCompany) *
            settings.vatFactor *
            percentFactorNew,
          2
        )
      );
    }

    if (changedField !== "priceDayPrivate") {
      setPriceDayPrivate(
        formatNumber(Number(catalogRow.priceDayPrivate) * percentFactorNew, 2)
      );
    }
    if (changedField !== "priceDayPrivateVat") {
      setPriceDayPrivateVat(
        formatNumber(
          Number(catalogRow.priceDayPrivate) *
            settings.vatFactor *
            percentFactorNew,
          2
        )
      );
    }
    if (changedField !== "priceMonthPrivate") {
      setPriceMonthPrivate(
        formatNumber(Number(catalogRow.priceMonthPrivate) * percentFactorNew, 2)
      );
    }
    if (changedField !== "priceMonthPrivateVat") {
      setPriceMonthPrivateVat(
        formatNumber(
          Number(catalogRow.priceMonthPrivate) *
            settings.vatFactor *
            percentFactorNew,
          2
        )
      );
    }
  };

  const onChangePercent = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    handlePercentChange(event.target.value);
  };

  const onChangePriceDayCompany = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceDayCompany(event.target.value);
    setPercentCalc(
      Number(catalogRow.priceDayCompany),
      parseNumber(event.target.value, 2),
      "priceDayCompany"
    );
  };
  const onChangePriceDayCompanyVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceDayCompanyVat(event.target.value);
    setPercentCalc(
      Number(catalogRow.priceDayCompany) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      "priceDayCompanyVat"
    );
  };
  const onChangePriceMonthCompany = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceMonthCompany(event.target.value);
    setPercentCalc(
      Number(catalogRow.priceMonthCompany),
      parseNumber(event.target.value, 2),
      "priceMonthCompany"
    );
  };
  const onChangePriceMonthCompanyVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceMonthCompanyVat(event.target.value);
    setPercentCalc(
      Number(catalogRow.priceMonthCompany) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      "priceMonthCompanyVat"
    );
  };

  const onBlurPriceDayCompany = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceDayCompany(formatNumber(parseNumber(event.target.value), 2));
    setPercentCalc(
      Number(catalogRow.priceDayCompany),
      parseNumber(event.target.value, 2),
      "priceDayCompany"
    );
  };
  const onBlurPriceDayCompanyVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceDayCompanyVat(formatNumber(parseNumber(event.target.value), 2));
    setPercentCalc(
      Number(catalogRow.priceDayCompany) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      "priceDayCompanyVat"
    );
  };
  const onBlurPriceMonthCompany = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceMonthCompany(formatNumber(parseNumber(event.target.value), 2));
    setPercentCalc(
      Number(catalogRow.priceMonthCompany),
      parseNumber(event.target.value, 2),
      "priceMonthCompany"
    );
  };
  const onBlurPriceMonthCompanyVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceMonthCompanyVat(formatNumber(parseNumber(event.target.value), 2));
    setPercentCalc(
      Number(catalogRow.priceMonthCompany) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      "priceMonthCompanyVat"
    );
  };

  const onChangePriceDayPrivate = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceDayPrivate(event.target.value);
    setPercentCalc(
      Number(catalogRow.priceDayPrivate),
      parseNumber(event.target.value, 2),
      "priceDayPrivate"
    );
  };
  const onChangePriceDayPrivateVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceDayPrivateVat(event.target.value);
    setPercentCalc(
      Number(catalogRow.priceDayPrivate) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      "priceDayPrivateVat"
    );
  };
  const onChangePriceMonthPrivate = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceMonthPrivate(event.target.value);
    setPercentCalc(
      Number(catalogRow.priceMonthPrivate),
      parseNumber(event.target.value, 2),
      "priceMonthPrivate"
    );
  };
  const onChangePriceMonthPrivateVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceMonthPrivateVat(event.target.value);
    setPercentCalc(
      Number(catalogRow.priceMonthPrivate) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      "priceMonthPrivateVat"
    );
  };

  const onBlurPriceDayPrivate = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceDayPrivate(formatNumber(parseNumber(event.target.value), 2));
    setPercentCalc(
      Number(catalogRow.priceDayPrivate),
      parseNumber(event.target.value, 2),
      "priceDayPrivate"
    );
  };
  const onBlurPriceDayPrivateVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceDayPrivateVat(formatNumber(parseNumber(event.target.value), 2));
    setPercentCalc(
      Number(catalogRow.priceDayPrivate) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      "priceDayPrivateVat"
    );
  };
  const onBlurPriceMonthPrivate = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceMonthPrivate(formatNumber(parseNumber(event.target.value), 2));
    setPercentCalc(
      Number(catalogRow.priceMonthPrivate),
      parseNumber(event.target.value, 2),
      "priceMonthPrivate"
    );
  };
  const onBlurPriceMonthPrivateVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceMonthPrivateVat(formatNumber(parseNumber(event.target.value), 2));
    setPercentCalc(
      Number(catalogRow.priceMonthPrivate) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      "priceMonthPrivateVat"
    );
  };

  let disabled = Boolean(
    !hasPermissionEdit || discountList.locked || discountList.id === ID_EMPTY
  );

  return (
    <tr className={loadingUpdate || loadingCreate ? "loading" : ""}>
      {hasPermissionEdit && !discountList.locked && (
        <td>
          <Checkbox
            checked={selectedRows.includes(discountRow.id)}
            onChange={(event) => {
              if (event.target.checked) {
                setSelectedRows(addStringToArr(selectedRows, discountRow.id));
              } else {
                setSelectedRows(
                  removeStringFromArr(selectedRows, discountRow.id)
                );
              }
            }}
          />
        </td>
      )}
      <td className={classes.cellNoInput}>{catalogRow.name}</td>
      {discountList.isBusiness ? (
        <>
          <td className={classes.cellNoInput}>
            {formatNumber(catalogRow.priceDayCompany, 2)}
          </td>
          <td className={classes.cellNoInput}>
            {formatNumber(
              Number(catalogRow.priceDayCompany) * settings.vatFactor,
              2
            )}
          </td>
          <td className={classes.cellNoInput}>
            {formatNumber(catalogRow.priceMonthCompany, 2)}
          </td>
          <td className={classes.cellNoInput}>
            {formatNumber(
              Number(catalogRow.priceMonthCompany) * settings.vatFactor,
              2
            )}
          </td>
        </>
      ) : (
        <>
          <td className={classes.cellNoInput}>
            {formatNumber(catalogRow.priceDayPrivate, 2)}
          </td>
          <td className={classes.cellNoInput}>
            {formatNumber(
              Number(catalogRow.priceDayPrivate) * settings.vatFactor,
              2
            )}
          </td>
          <td className={classes.cellNoInput}>
            {formatNumber(catalogRow.priceMonthPrivate, 2)}
          </td>
          <td className={classes.cellNoInput}>
            {formatNumber(
              Number(catalogRow.priceMonthPrivate) * settings.vatFactor,
              2
            )}
          </td>
        </>
      )}
      <td className="cellPriceChange">
        <div className="d-flex flex-row">
          <div>
            <TextFieldFocus
              value={discountRow.percent}
              disabled={disabled}
              onChange={onChangePercent}
              onBlur={() => {
                const percent = discountRow.percent;

                setPercentUserInput(percent);
                handleDiscountRowUpdate({
                  percent:
                    getPercentPrefixCalc(percent) +
                    parseNumber(percent).toFixed(2),
                });
              }}
            />
          </div>
          <div>%</div>
        </div>
      </td>
      {discountList.isBusiness ? (
        <>
          <td>
            <TextFieldFocus
              className={classes.txt}
              value={priceDayCompany}
              disabled={disabled}
              onChange={onChangePriceDayCompany}
              onBlur={onBlurPriceDayCompany}
            />
          </td>
          <td>
            <TextFieldFocus
              className={classes.txt}
              value={priceDayCompanyVat}
              disabled={disabled}
              onChange={onChangePriceDayCompanyVat}
              onBlur={onBlurPriceDayCompanyVat}
            />
          </td>
          <td>
            <TextFieldFocus
              className={classes.txt}
              value={priceMonthCompany}
              disabled={disabled}
              onChange={onChangePriceMonthCompany}
              onBlur={onBlurPriceMonthCompany}
            />
          </td>
          <td>
            <TextFieldFocus
              className={classes.txt}
              value={priceMonthCompanyVat}
              disabled={disabled}
              onChange={onChangePriceMonthCompanyVat}
              onBlur={onBlurPriceMonthCompanyVat}
            />
          </td>
        </>
      ) : (
        <>
          <td>
            <TextFieldFocus
              className={classes.txt}
              value={priceDayPrivate}
              disabled={disabled}
              onChange={onChangePriceDayPrivate}
              onBlur={onBlurPriceDayPrivate}
            />
          </td>
          <td>
            <TextFieldFocus
              className={classes.txt}
              value={priceDayPrivateVat}
              disabled={disabled}
              onChange={onChangePriceDayPrivateVat}
              onBlur={onBlurPriceDayPrivateVat}
            />
          </td>
          <td>
            <TextFieldFocus
              className={classes.txt}
              value={priceMonthPrivate}
              disabled={disabled}
              onChange={onChangePriceMonthPrivate}
              onBlur={onBlurPriceMonthPrivate}
            />
          </td>
          <td>
            <TextFieldFocus
              className={classes.txt}
              value={priceMonthPrivateVat}
              disabled={disabled}
              onChange={onChangePriceMonthPrivateVat}
              onBlur={onBlurPriceMonthPrivateVat}
            />
          </td>
        </>
      )}
      <td className="cellPriceChange">
        <Checkbox
          checked={discountRow.showPrint}
          disabled={disabled}
          onChange={(event) => {
            discountRow.showPrint = event.target.checked;
            setDiscountList({ ...discountList });
            handleDiscountRowUpdate({
              showPrint: event.target.checked,
            });
          }}
        />
      </td>
    </tr>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    txt: {
      maxWidth: "80px",
    },
    cellNoInput: {
      verticalAlign: "middle !important",
    },
  });

export default withStyles(styles)(DiscountRow);
