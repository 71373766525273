import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineInfoAnswerType,
  MachineInfoQuestionType,
} from "../../entity/types";

interface Props extends WithStyles<typeof styles> {
  machineInfoQuestion: MachineInfoQuestionType;
  machineInfoAnswer: MachineInfoAnswerType;
}

function MachineInfoProductCard({
  classes,
  machineInfoQuestion,
  machineInfoAnswer,
}: Props) {
  if (!machineInfoQuestion.showProductCard) {
    return <></>;
  }

  return (
    <div>
      <small>{machineInfoQuestion.question}</small>
      <p>{machineInfoAnswer.answer}</p>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MachineInfoProductCard);
