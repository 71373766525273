import React, { useContext } from "react";
import {
  createStyles,
  DialogContent,
  FormControl,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { InspectionGiveCategoryType } from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionGiveCategory: InspectionGiveCategoryType;
  setInspectionGiveCategory: React.Dispatch<
    React.SetStateAction<InspectionGiveCategoryType>
  >;
}

function DialogContentInspectionGiveCategory({
  classes,
  inspectionGiveCategory,
  setInspectionGiveCategory,
}: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "inspections.change_inspectiongivecategory",
  ]);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "inspections.add_inspectiongivecategory",
  ]);
  const disabled = inspectionGiveCategory.id
    ? !hasPermissionEdit
    : !hasPermissionAdd;

  return (
    <DialogContent>
      <FormControl fullWidth>
        <TextField
          label={t("name")}
          onChange={(event) => {
            setInspectionGiveCategory({
              ...inspectionGiveCategory,
              name: event.target.value,
            });
          }}
          value={inspectionGiveCategory.name}
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          autoFocus
        />
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentInspectionGiveCategory);
