import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  CatalogExtraCategoryRentalType,
  CatalogExtraRowRentalType,
  CustomerType,
  LocationType,
  MachineBreakdownType,
  MachineType,
  MaintenanceMaintenanceType,
  MaintenanceType,
  OrderType,
  QueryCatalogExtraRowsRentalByLocationMachinesArgs,
  ReservationType,
} from "../../entity/types";
import {
  GET_CATALOG_EXTRA_ROWS_RENTAL_BY_LOCATION_MACHINES_QUERY,
  QueryResultCatalogExtraRowsRentalByLocationMachines,
} from "../../apollo/queries/catalogs_extra";
import { useQuery } from "@apollo/client";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { Button, Table } from "react-bootstrap";

import CalendarTheadTh from "./CalendarTheadTh";
import {
  createBreakdownsByRowExtraDate,
  createMaintenancesByRowExtraDate,
  createReservationsByRowExtraDate,
  getCellClassName,
  getCellTitle,
  selectOrder,
} from "../../utils/calendar/calendar";
import format from "date-fns/format";
import { getMachineName } from "../../utils/machines/machine";
import {
  CALENDAR_INSERT_MODES,
  DATE_FORMAT_ISO,
  ID_EMPTY,
} from "../../utils/constants";
import { handleError } from "../../entity/ErrorHandler";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { datesEqual, newDate } from "../../utils/dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowFromLeft,
  faArrowFromRight,
  faClipboardList,
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";
import MenuCalendarCellOptions from "./MenuCalendarCellOptions";
import CalendarTrMonth from "./CalendarTrMonth";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { CatalogExtraRowRentalEmpty } from "../../entity/empties";
import DialogCatalogExtraRowRentalInfo from "../CatalogExtraRental/DialogCatalogExtraRowRentalInfo";

interface Props extends WithStyles<typeof styles> {
  machinesForThisCatalogRow: MachineType[];
  timeline: Date[];
  dateSelectionStart: Date | null;
  setDateSelectionStart: React.Dispatch<React.SetStateAction<Date | null>>;
  dateSelectionEnd: Date | null;
  setDateSelectionEnd: React.Dispatch<React.SetStateAction<Date | null>>;
  catalogExtraRowRentalSelected: CatalogExtraRowRentalType | null;
  setCatalogExtraRowRentalSelected: React.Dispatch<
    React.SetStateAction<CatalogExtraRowRentalType | null>
  >;
  customer: CustomerType;
  insertMode: CALENDAR_INSERT_MODES;
  setOrderId: React.Dispatch<React.SetStateAction<string>>;
  order: OrderType;
  setMachineSelected: React.Dispatch<React.SetStateAction<MachineType | null>>;
  reservations: ReservationType[];
  machineBreakdowns: MachineBreakdownType[];
  maintenances: MaintenanceType[];
  setMachineBreakdown: React.Dispatch<
    React.SetStateAction<MachineBreakdownType>
  >;
  setOpenDialogMachineBreakdown: React.Dispatch<React.SetStateAction<boolean>>;
  location: LocationType;
}

function CalendarCatalogExtraRows({
  classes,
  machinesForThisCatalogRow,
  timeline,
  dateSelectionStart,
  setDateSelectionStart,
  dateSelectionEnd,
  setDateSelectionEnd,
  catalogExtraRowRentalSelected,
  setCatalogExtraRowRentalSelected,
  customer,
  insertMode,
  setOrderId,
  order,
  setMachineSelected,
  reservations,
  machineBreakdowns,
  maintenances,
  setMachineBreakdown,
  setOpenDialogMachineBreakdown,
  location,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
  const [reservationsMenu, setReservationsMenu] = useState<ReservationType[]>(
    []
  );
  const [breakdownsMenu, setBreakdownsMenu] = useState<MachineBreakdownType[]>(
    []
  );
  const [machinesMenu, setMachinesMenu] = useState<MachineType[]>([]);

  const machineIds = machinesForThisCatalogRow.map((machine) => machine.id);
  const [openProductCard, setOpenProductCard] = useState(false);
  const [extraRowRentalForInfoDialog, setExtraRowRentalForInfoDialog] =
    useState(CatalogExtraRowRentalEmpty);

  const { loading, error, data } = useQuery<
    QueryResultCatalogExtraRowsRentalByLocationMachines,
    QueryCatalogExtraRowsRentalByLocationMachinesArgs
  >(GET_CATALOG_EXTRA_ROWS_RENTAL_BY_LOCATION_MACHINES_QUERY, {
    fetchPolicy: getQueryFetchPolicy(
      "catalogExtraRowsRentalByLocationMachines"
    ),
    variables: { locationId: location.id, machineIds: machineIds },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddOrder = checkPermission(myPermissions, [
    "orders.add_order",
  ]);

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const reservationsByDate = createReservationsByRowExtraDate(reservations);
  const breakdownsByDate = createBreakdownsByRowExtraDate(machineBreakdowns);
  const maintenancesByDate = createMaintenancesByRowExtraDate(maintenances);
  let reservationIdsTitlePrinted: string[] = [];

  let catalogExtraRowsRentalGrouped: {
    [catalogExtraCategoryRentalId: string]: {
      category: CatalogExtraCategoryRentalType;
      rows: CatalogExtraRowRentalType[];
    };
  } = {};
  data.catalogExtraRowsRentalByLocationMachines?.forEach(
    (catalogExtraRowRental) => {
      const key = catalogExtraRowRental.catalogExtraCategoryRental.id;
      if (catalogExtraRowsRentalGrouped[key] === undefined) {
        catalogExtraRowsRentalGrouped[key] = {
          category: catalogExtraRowRental.catalogExtraCategoryRental,
          rows: [],
        };
      }
      catalogExtraRowsRentalGrouped[key]["rows"].push(catalogExtraRowRental);
    }
  );

  const isCellSelected = (
    date: Date,
    dateSelection: null | Date,
    catalogExtraRowRental: CatalogExtraRowRentalType
  ) =>
    dateSelection &&
    datesEqual(date, dateSelection) &&
    catalogExtraRowRentalSelected &&
    catalogExtraRowRental.id === catalogExtraRowRentalSelected.id;

  const doMachineSelection = (
    event: React.MouseEvent<HTMLTableDataCellElement>,
    machinesFiltered: MachineType[],
    machinesInOrder: MachineType[]
  ) => {
    if (insertMode === CALENDAR_INSERT_MODES.BREAKDOWN) {
      return;
    }

    let machinesNew =
      machinesFiltered.length > 0
        ? machinesFiltered
        : machinesForThisCatalogRow;
    machinesInOrder?.forEach((machine) => {
      if (!machinesNew.map((machine) => machine.id).includes(machine.id)) {
        machinesNew = [...machinesNew, machine];
      }
    });

    setMachinesMenu(machinesNew);
    setAnchorElMenu(event.currentTarget);
  };

  const getReservationsToday = (
    date: Date,
    catalogExtraRowRental: CatalogExtraRowRentalType
  ) => {
    const keyDate = format(date, DATE_FORMAT_ISO);
    return reservationsByDate[catalogExtraRowRental.id] !== undefined &&
      reservationsByDate[catalogExtraRowRental.id][keyDate] !== undefined
      ? reservationsByDate[catalogExtraRowRental.id][keyDate]
      : [];
  };

  const getBreakdownsToday = (
    date: Date,
    catalogExtraRowRental: CatalogExtraRowRentalType
  ) => {
    const keyDate = format(date, DATE_FORMAT_ISO);
    return breakdownsByDate[catalogExtraRowRental.id] !== undefined &&
      breakdownsByDate[catalogExtraRowRental.id][keyDate] !== undefined
      ? breakdownsByDate[catalogExtraRowRental.id][keyDate]
      : [];
  };

  const getMaintenancesToday = (
    date: Date,
    catalogExtraRowRental: CatalogExtraRowRentalType
  ) => {
    const keyDate = format(date, DATE_FORMAT_ISO);
    return maintenancesByDate[catalogExtraRowRental.id] !== undefined &&
      maintenancesByDate[catalogExtraRowRental.id][keyDate] !== undefined
      ? maintenancesByDate[catalogExtraRowRental.id][keyDate]
      : [];
  };

  const onClickCell = (
    event: React.MouseEvent<HTMLTableDataCellElement>,
    date: Date,
    catalogExtraRowRental: CatalogExtraRowRentalType,
    machinesFiltered: MachineType[],
    machinesInOrder: MachineType[]
  ) => {
    const reservationsToday = getReservationsToday(date, catalogExtraRowRental);
    const machineBreakdownsToday = getBreakdownsToday(
      date,
      catalogExtraRowRental
    );

    // Clicked existing order and/or machine breakdown without selection so open order/breakdown or menu
    if (
      !dateSelectionStart &&
      (reservationsToday.length > 0 || machineBreakdownsToday.length > 0)
    ) {
      const countOpts =
        reservationsToday.length + machineBreakdownsToday.length;
      if (countOpts > 1) {
        setReservationsMenu(reservationsToday);
        setBreakdownsMenu(machineBreakdownsToday);
        setAnchorElMenu(event.currentTarget);
      } else if (reservationsToday.length > 0) {
        selectOrder(reservationsToday[0].order.id, setOrderId);
      } else if (machineBreakdownsToday.length > 0) {
        setMachineBreakdown(machineBreakdownsToday[0]);
        setOpenDialogMachineBreakdown(true);
      }
      return;
    }

    if (!hasPermissionAddOrder) {
      return;
    }

    if (
      customer.id === ID_EMPTY &&
      insertMode === CALENDAR_INSERT_MODES.RESERVATION
    ) {
      handleError(t("error_customer_not_selected"));
      return;
    }

    // Clicked selected cell again: remove selection
    if (isCellSelected(date, dateSelectionStart, catalogExtraRowRental)) {
      setDateSelectionStart(null);
      setDateSelectionEnd(null);
      setCatalogExtraRowRentalSelected(null);
      setMachineSelected(null);
    }
    // Clicked cell in different catalogExtraRowRental than selected: reset selection to new catalogExtraRowRental
    else if (
      catalogExtraRowRentalSelected &&
      catalogExtraRowRentalSelected.id !== catalogExtraRowRental.id
    ) {
      setDateSelectionStart(date);
      setDateSelectionEnd(null);
      setCatalogExtraRowRentalSelected(catalogExtraRowRental);
      doMachineSelection(event, machinesFiltered, machinesInOrder);
    }
    // Made a selection end click
    else if (
      dateSelectionStart &&
      !dateSelectionEnd &&
      catalogExtraRowRentalSelected &&
      catalogExtraRowRentalSelected.id === catalogExtraRowRental.id
    ) {
      if (date > dateSelectionStart) {
        setDateSelectionEnd(date);
      } else {
        handleError(t("error_selected_date_before_start"));
      }
    }
    // Made a selection start click
    else {
      setDateSelectionStart(date);
      setDateSelectionEnd(null);
      setCatalogExtraRowRentalSelected(catalogExtraRowRental);
      doMachineSelection(event, machinesFiltered, machinesInOrder);
    }
  };

  const getCellContent = (
    date: Date,
    catalogExtraRowRental: CatalogExtraRowRentalType,
    reservations: ReservationType[],
    machineBreakdowns: MachineBreakdownType[],
    maintenances: MaintenanceType[]
  ) => {
    let content: JSX.Element[] = [];

    let countBreakdownsRentableYes = 0;
    let countBreakdownsRentableNo = 0;
    let countBreakdownsFixed = 0;
    let titlesBreakdown: string[] = [];
    machineBreakdowns.forEach((machineBreakdown) => {
      if (machineBreakdown.fixedAt) {
        if (
          format(newDate(machineBreakdown.fixedAt), t("format_date")) ===
          format(newDate(date), t("format_date"))
        ) {
          countBreakdownsFixed++;
        }
      } else if (machineBreakdown.stillRentable) {
        countBreakdownsRentableYes++;
      } else {
        countBreakdownsRentableNo++;
      }
      titlesBreakdown.push(machineBreakdown.title);
    });

    if (
      countBreakdownsRentableYes > 0 ||
      countBreakdownsRentableNo > 0 ||
      countBreakdownsFixed > 0
    ) {
      let classNameBreakdown = "";
      if (countBreakdownsFixed > 0) {
        classNameBreakdown = "iconBreakdownFixed";
      }
      if (countBreakdownsRentableYes > 0) {
        classNameBreakdown = "iconBreakdownRentableYes";
      }
      if (countBreakdownsRentableNo > 0) {
        classNameBreakdown = "iconBreakdownRentableNo";
      }
      content.push(
        <FontAwesomeIcon
          key={`${catalogExtraRowRental.id}_breakdown`}
          className={classNameBreakdown}
          icon={faExclamationTriangle}
          title={titlesBreakdown.join("\n")}
        />
      );
    }

    let countMaintenancesDone = 0;
    let countMaintenancesScheduled = 0;
    let countMaintenancesUpcoming = 0;
    let titlesMaintenance: string[] = [];
    maintenances.forEach((maintenance) => {
      if (maintenance.servicedAt) {
        if (
          format(newDate(maintenance.servicedAt), t("format_date")) ===
          format(newDate(date), t("format_date"))
        ) {
          countMaintenancesDone++;
        }
      } else if (
        maintenance.maintenanceType === MaintenanceMaintenanceType.Scheduled
      ) {
        countMaintenancesScheduled++;
      } else {
        countMaintenancesUpcoming++;
      }
      titlesMaintenance.push(maintenance.maintenanceInterval.description);
    });

    if (
      countMaintenancesDone > 0 ||
      countMaintenancesScheduled > 0 ||
      countMaintenancesUpcoming > 0
    ) {
      let classNameMaintenance = "";
      if (countMaintenancesDone > 0) {
        classNameMaintenance = "iconBreakdownFixed";
      }
      if (countMaintenancesUpcoming > 0) {
        classNameMaintenance = "iconBreakdownRentableYes";
      }
      if (countMaintenancesScheduled > 0) {
        classNameMaintenance = "iconBreakdownRentableNo";
      }
      content.push(
        <FontAwesomeIcon
          key={`${catalogExtraRowRental.id}_maintenance`}
          className={classNameMaintenance}
          icon={faExclamationCircle}
          title={titlesBreakdown.join("\n")}
        />
      );
    }

    if (isCellSelected(date, dateSelectionStart, catalogExtraRowRental)) {
      content.push(
        <FontAwesomeIcon
          key={`${catalogExtraRowRental.id}_arrow_start`}
          className={`iconCell ${
            insertMode === CALENDAR_INSERT_MODES.BREAKDOWN
              ? "iconCellBreakdown"
              : ""
          }`}
          size="lg"
          icon={faArrowFromLeft}
        />
      );
    }
    if (isCellSelected(date, dateSelectionEnd, catalogExtraRowRental)) {
      content.push(
        <FontAwesomeIcon
          key={`${catalogExtraRowRental.id}_arrow_end`}
          className={`iconCell ${
            insertMode === CALENDAR_INSERT_MODES.BREAKDOWN
              ? "iconCellBreakdown"
              : ""
          }`}
          size="lg"
          icon={faArrowFromRight}
        />
      );
    }
    let counter = 0;
    reservations.forEach((reservation) => {
      if (!reservationIdsTitlePrinted.includes(reservation.id)) {
        content.push(
          <div
            key={reservation.id}
            className="cellTitle"
            style={{ marginTop: counter * 1.3 + "em" }}
          >
            {reservation.order.customer ? reservation.order.customer.name : ""}
            {reservations.length > 1 ? " - " : <br />}
            {reservation.order.customer && (
              <span>
                {reservation.order.customer.contactPersonLastname}{" "}
                {reservation.order.customer.contactPersonFirstname}
              </span>
            )}
          </div>
        );
        reservationIdsTitlePrinted.push(reservation.id);
        counter++;
      }
    });
    if (reservations.length > 1) {
      content.push(
        <span
          key={`spnMarkingCounter_${catalogExtraRowRental.id}`}
          className="spnMarkingCounter"
        >
          {reservations.length}
        </span>
      );
    }
    return content;
  };

  const reservationsInOrderWithMachine = order.reservationSet.filter(
    (reservation) => Boolean(reservation.machine)
  );
  const machinesInOrder = reservationsInOrderWithMachine.map(
    (reservation) => reservation.machine as MachineType
  );

  return (
    <div>
      {Object.entries(catalogExtraRowsRentalGrouped).map(
        ([catalogExtraCategoryRentalId, row]) => (
          <div key={catalogExtraCategoryRentalId}>
            {row.rows.length > 0 && (
              <Table className="mb-5 tblCalendar">
                <thead>
                  <CalendarTrMonth timeline={timeline} />
                  <tr>
                    <th className="calCellFirst" title={row.category.name}>
                      <div>{t("catalog_extra_rows_rental")}</div>
                      <small>{row.category.name}</small>
                    </th>
                    {timeline.map((date, i) => (
                      <CalendarTheadTh key={i} date={date} />
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {row.rows.map((catalogExtraRowRental) => {
                    let machineModelIdsFound: string[] = [];
                    let machineNames: string[] = [];
                    let machines: MachineType[] = [];
                    for (let machine of catalogExtraRowRental.machineSet) {
                      if (
                        !machineModelIdsFound.includes(
                          machine.machineModel.id
                        ) &&
                        machineIds.includes(machine.id)
                      ) {
                        machineNames.push(getMachineName(machine));
                        machines.push(machine);
                        machineModelIdsFound.push(machine.machineModel.id);
                      }
                    }

                    return (
                      <tr key={catalogExtraRowRental.id}>
                        <td className="calCellFirst">
                          <span title={catalogExtraRowRental.name}>
                            {catalogExtraRowRental.name}
                            <small className="ms-1">
                              ({catalogExtraRowRental.location.name})
                            </small>
                          </span>
                          <br />
                          {catalogExtraRowRental.identifier !== "" && (
                            <small className="text-muted">
                              {catalogExtraRowRental.identifier}
                            </small>
                          )}
                          {catalogExtraRowRental.identifier !== "" &&
                            machineNames.length > 0 && (
                              <small className="text-muted ms-1 me-1">-</small>
                            )}
                          <small
                            className="text-muted"
                            title={machineNames.join(", ")}
                          >
                            {machineNames.join(", ")}&nbsp;
                          </small>
                          <Button
                            className="me-1"
                            variant="light"
                            size="sm"
                            title={t("open_product_card")}
                            onClick={() => {
                              setExtraRowRentalForInfoDialog(
                                catalogExtraRowRental
                              );
                              setOpenProductCard(!openProductCard);
                            }}
                          >
                            <FontAwesomeIcon icon={faClipboardList} />
                          </Button>
                        </td>
                        {timeline.map((date, i) => {
                          const reservationsToday = getReservationsToday(
                            date,
                            catalogExtraRowRental
                          );
                          const breakdownsToday = getBreakdownsToday(
                            date,
                            catalogExtraRowRental
                          );
                          const maintenancesToday = getMaintenancesToday(
                            date,
                            catalogExtraRowRental
                          );

                          return (
                            <td
                              key={i}
                              className={`calCell ${getCellClassName(
                                date,
                                reservationsToday,
                                breakdownsToday,
                                maintenancesToday
                              )}`}
                              title={getCellTitle(date, reservationsToday)}
                              onClick={(event) => {
                                onClickCell(
                                  event,
                                  date,
                                  catalogExtraRowRental,
                                  machines,
                                  machinesInOrder
                                );
                              }}
                            >
                              {getCellContent(
                                date,
                                catalogExtraRowRental,
                                reservationsToday,
                                breakdownsToday,
                                maintenancesToday
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </div>
        )
      )}
      {openProductCard && (
        <DialogCatalogExtraRowRentalInfo
          open={openProductCard}
          setOpen={setOpenProductCard}
          catalogExtraRowRental={extraRowRentalForInfoDialog}
        />
      )}
      <MenuCalendarCellOptions
        anchorElMenu={anchorElMenu}
        setAnchorElMenu={setAnchorElMenu}
        setOrderId={setOrderId}
        reservationsMenu={reservationsMenu}
        breakdownsMenu={breakdownsMenu}
        machinesMenu={machinesMenu}
        setMachineSelected={setMachineSelected}
        setReservationsMenu={setReservationsMenu}
        setMachinesMenu={setMachinesMenu}
        setMachineBreakdown={setMachineBreakdown}
        setBreakdownsMenu={setBreakdownsMenu}
        setOpenDialogMachineBreakdown={setOpenDialogMachineBreakdown}
        setDateSelectionEnd={setDateSelectionEnd}
        reservationsInOrder={reservationsInOrderWithMachine}
      />
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CalendarCatalogExtraRows);
