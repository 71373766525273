import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  InvoiceRowRowType,
  InvoiceRowType,
  InvoiceSendMethods,
  Mutation,
  MutationUpdateInvoiceRowArgs,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { UPDATE_INVOICE_ROW_MUTATION } from "../../apollo/mutations/invoices";

interface Props extends WithStyles<typeof styles> {
  invoiceRow: InvoiceRowType;
  invoiceSendMethod: string;
  readonly: boolean;
}

function ManagementInvoiceRow({
  classes,
  invoiceRow,
  invoiceSendMethod,
  readonly,
}: Props) {
  const { t } = useTranslation();

  const [invoiceRowEdited, setInvoiceRowEdited] = useState({
    ...invoiceRow,
    quantity: formatNumber(invoiceRow.quantity, 2),
    unitPrice: formatNumber(invoiceRow.unitPrice, 2),
  });

  const [updateInvoiceRow, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateInvoiceRowArgs
  >(UPDATE_INVOICE_ROW_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditInvoiceRow = checkPermission(myPermissions, [
    "invoices.change_invoicerow",
  ]);

  const handleUpdate = (
    values: { [key: string]: string | number | [] } = {}
  ) => {
    const base: MutationUpdateInvoiceRowArgs = {
      invoiceRowId: invoiceRowEdited.id,
      productNumber: invoiceRowEdited.productNumber,
      title: invoiceRowEdited.title,
      description: invoiceRowEdited.description,
      quantity: parseNumber(invoiceRowEdited.quantity),
      unitPrice: parseNumber(invoiceRowEdited.unitPrice),
    };

    updateInvoiceRow({
      variables: { ...base, ...values },
    });
  };

  return invoiceSendMethod === InvoiceSendMethods.Card &&
    invoiceRowEdited.rowType === InvoiceRowRowType.Surcharge ? (
    <></>
  ) : (
    <tr className={loadingUpdate ? "loading" : ""}>
      <td className="cellProductNumber">
        <FormControl fullWidth>
          {readonly ? (
            invoiceRowEdited.productNumber
          ) : (
            <TextField
              value={invoiceRowEdited.productNumber}
              required={true}
              onChange={(event) => {
                setInvoiceRowEdited({
                  ...invoiceRowEdited,
                  productNumber: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdate({ productNumber: invoiceRowEdited.productNumber });
              }}
              disabled={!hasPermissionEditInvoiceRow}
            />
          )}
        </FormControl>
      </td>
      <td className={"cellQuantity" + (readonly ? " text-end" : "")}>
        <FormControl fullWidth>
          {readonly ? (
            formatNumber(invoiceRowEdited.quantity, 2)
          ) : (
            <TextField
              value={invoiceRowEdited.quantity}
              required={true}
              onChange={(event) => {
                setInvoiceRowEdited({
                  ...invoiceRowEdited,
                  quantity: event.target.value,
                });
              }}
              onBlur={() => {
                const quantityNew = parseNumber(invoiceRowEdited.quantity);
                setInvoiceRowEdited({
                  ...invoiceRowEdited,
                  quantity: formatNumber(quantityNew, 2),
                });
                handleUpdate({ quantity: quantityNew });
              }}
              disabled={!hasPermissionEditInvoiceRow}
            />
          )}
        </FormControl>
      </td>
      <td className={"cellUnitPrice" + (readonly ? " text-end" : "")}>
        <FormControl fullWidth>
          {readonly ? (
            t("currency", {
              amount: formatNumber(invoiceRowEdited.unitPrice, 2),
            })
          ) : (
            <TextField
              value={invoiceRowEdited.unitPrice}
              required={true}
              onChange={(event) => {
                setInvoiceRowEdited({
                  ...invoiceRowEdited,
                  unitPrice: event.target.value,
                });
              }}
              onBlur={() => {
                const unitPriceNew = parseNumber(invoiceRowEdited.unitPrice);
                setInvoiceRowEdited({
                  ...invoiceRowEdited,
                  unitPrice: formatNumber(unitPriceNew, 2),
                });
                handleUpdate({ unitPrice: unitPriceNew });
              }}
              disabled={!hasPermissionEditInvoiceRow}
            />
          )}
        </FormControl>
      </td>
      <td className="cellPrice text-end">
        {t("currency", { amount: formatNumber(invoiceRow.price, 2) })}
      </td>
      <td className="cellTitle">
        <FormControl fullWidth>
          {readonly ? (
            invoiceRowEdited.title
          ) : (
            <TextField
              value={invoiceRowEdited.title}
              required={true}
              onChange={(event) => {
                setInvoiceRowEdited({
                  ...invoiceRowEdited,
                  title: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdate({ title: invoiceRowEdited.title });
              }}
              disabled={!hasPermissionEditInvoiceRow}
            />
          )}
        </FormControl>
      </td>
      <td className="cellDescription">
        <FormControl fullWidth>
          {readonly ? (
            invoiceRowEdited.description
          ) : (
            <TextField
              value={invoiceRowEdited.description}
              onChange={(event) => {
                setInvoiceRowEdited({
                  ...invoiceRowEdited,
                  description: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdate({ description: invoiceRowEdited.description });
              }}
              disabled={!hasPermissionEditInvoiceRow}
            />
          )}
        </FormControl>
      </td>
    </tr>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ManagementInvoiceRow);
