import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from "material-table";
import {
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  tableIcons,
} from "../../utils/TableProps";
import { useTranslation } from "react-i18next";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocationType } from "../../entity/types";
import { LocationEmpty } from "../../entity/empties";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import DialogLocation from "./DialogLocation";
import HeaderPage from "../Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {
  locations: LocationType[];
}

const LocationList = ({ classes, locations }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState(LocationEmpty);

  const data = locations.map((o) => ({ ...o }));

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "locations.change_location",
  ]);

  const getTableActions = () => {
    if (!hasPermissionEdit) {
      return [];
    }

    return [
      {
        icon: () => <FontAwesomeIcon icon={faEllipsisV} />,
        tooltip: t("edit"),
        onClick: (event: any, rowData: any) => {
          setLocation(rowData);
          setOpen(true);
        },
      },
    ];
  };

  return (
    <>
      <HeaderPage title={t("locations")} />
      <div className="containerInner">
        <MaterialTable
          style={getTableStyle()}
          title={""}
          icons={tableIcons}
          localization={GetTableLocalization(t)}
          columns={[
            { title: t("name"), field: "name" },
            { title: t("dim_identifier"), field: "dimIdentifier" },
          ]}
          data={data}
          options={getTableOptions()}
          actions={getTableActions()}
        />
        {open && (
          <DialogLocation
            open={open}
            setOpen={setOpen}
            location={location}
            setLocation={setLocation}
          />
        )}
      </div>
    </>
  );
};

const styles = {};

export default withStyles(styles)(LocationList);
