import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultCatalogTransfer {
  catalogTransferMethods: Query["catalogTransferMethods"];
}
export const GET_CATALOG_TRANSFER_QUERY = gql`
  query getCatalogTransfer {
    catalogTransferMethods {
      id
      name
      catalogtransferpriceSet {
        id
        priceBasic
        priceExtra
        priceStop
        productNumberBasic
        productNumberExtra
        productNumberStop
        location {
          id
        }
      }
    }
  }
`;
