import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import {
  CatalogCategoryType,
  Mutation,
  MutationUploadCatalogCategoryArgs,
} from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Button, Image } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { UPLOAD_CATALOG_CATEGORY_MUTATION } from "../../apollo/mutations/catalogs";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalogCategory: CatalogCategoryType;
}

function DialogCatalogCategoryUpload({
  classes,
  open,
  setOpen,
  catalogCategory,
}: Props) {
  const { t } = useTranslation();

  const [uploadCatalogCategory] = useMutation<
    Mutation,
    MutationUploadCatalogCategoryArgs
  >(UPLOAD_CATALOG_CATEGORY_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalog"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogs"),
      });
    },
  });

  function onChange({
    target: { validity, files },
  }: React.ChangeEvent<HTMLInputElement>) {
    if (files && validity.valid) {
      let file = files[0];
      uploadCatalogCategory({
        variables: {
          catalogCategoryId: catalogCategory.id,
          file: file,
        },
      });
    }
  }

  const handleClickDelete = () => {
    uploadCatalogCategory({
      variables: {
        catalogCategoryId: catalogCategory.id,
        file: null,
      },
    });
  };

  return (
    <Dialog open={open}>
      <form className={classes.form}>
        <DialogTitleWithClose
          id="dialogTitleCatalogCategoryUpload"
          onClose={() => setOpen(false)}
        >
          {t("catalog_category_image")}
        </DialogTitleWithClose>
        <DialogContent>
          <div>
            {catalogCategory.image && (
              <Image src={catalogCategory.image} thumbnail fluid />
            )}
          </div>
          <br />
          <input type="file" required onChange={onChange} accept="image/*" />
        </DialogContent>
        <DialogActions>
          {catalogCategory.image && (
            <Button onClick={handleClickDelete} variant="light">
              {t("delete")}
            </Button>
          )}
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("close")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: spacing(2),
    },
  });

export default withStyles(styles)(DialogCatalogCategoryUpload);
