import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, TextField, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CatalogRowType, QueryCatalogArgs } from "../../entity/types";
import { useHistory } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { getUrlCalendar } from "../../utils/urls";
import { ID_EMPTY } from "../../utils/constants";
import { useQuery } from "@apollo/client";
import {
  GET_CATALOG_WITH_ROWS_QUERY,
  QueryResultCatalogWithRows,
} from "../../apollo/queries/catalogs";
import Error from "../Shared/Error";
import LoadingSimple from "../Shared/LoadingSimple";
import { CatalogRowEmpty } from "../../entity/empties";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  catalogId: string;
  catalogRowId: string;
}

type CatalogRowWithUrlType = CatalogRowType & { url: string };

function CalendarSelectCatalogRow({ classes, catalogId, catalogRowId }: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const { loading, error, data } = useQuery<
    QueryResultCatalogWithRows,
    QueryCatalogArgs
  >(GET_CATALOG_WITH_ROWS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalog"),
    variables: {
      catalogId: catalogId,
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  let catalogRowSelected = CatalogRowEmpty;

  let options: CatalogRowWithUrlType[] = [];
  data.catalog?.catalogcategoryupperSet.forEach((catalogCategoryUpper) => {
    catalogCategoryUpper.catalogcategorySet.forEach((catalogCategory) => {
      catalogCategory.catalogrowSet.forEach((catalogRow) => {
        options.push({
          ...catalogRow,
          catalogCategory: catalogCategory,
          url: getUrlCalendar(
            catalogId,
            catalogCategoryUpper.id,
            catalogCategory.id,
            catalogRow.id
          ),
        });

        if (catalogRow.id === catalogRowId) {
          catalogRowSelected = catalogRow;
        }
      });
    });
  });

  return (
    <div>
      <Autocomplete
        className="mt-2 mb-2" /* Without top margin, label gets cut */
        options={options}
        groupBy={(catalogRow) => catalogRow.catalogCategory.name}
        getOptionLabel={(catalogRow) => catalogRow.name}
        renderInput={(params) => (
          <TextField {...params} label={t("catalog_row")} variant="outlined" />
        )}
        value={
          catalogRowSelected.id !== ID_EMPTY
            ? (catalogRowSelected as CatalogRowWithUrlType)
            : null
        }
        getOptionSelected={(a, b) => {
          return a.id === b.id;
        }}
        onChange={(event, catalogRow) => {
          if (catalogRow) {
            history.push(catalogRow.url);
          }
        }}
      />
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CalendarSelectCatalogRow);
