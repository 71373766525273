import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { QueryMaintenanceImagesArgs } from "../../entity/types";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import {
  GET_MAINTENANCE_IMAGES_QUERY,
  QueryResultMaintenanceImages,
} from "../../apollo/queries/maintenances";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import Error from "../Shared/Error";
import MaintenanceImage from "./MaintenanceImage";
import LoadingSimple from "../Shared/LoadingSimple";

interface Props extends WithStyles<typeof styles> {
  maintenanceAlbumId: string;
}

function MaintenanceImages({ classes, maintenanceAlbumId }: Props) {
  const { t } = useTranslation();

  const {
    loading: loadingImages,
    error: errorImages,
    data: dataImages,
  } = useQuery<QueryResultMaintenanceImages, QueryMaintenanceImagesArgs>(
    GET_MAINTENANCE_IMAGES_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("reservationImageGives"),
      variables: {
        maintenanceAlbumId: maintenanceAlbumId,
      },
    }
  );

  if (loadingImages) return <LoadingSimple />;
  if (errorImages) return <Error error={errorImages} />;
  if (!dataImages) return <Error error={t("error_query_failed")} />;

  const maintenanceImages = dataImages?.maintenanceImages
    ? dataImages.maintenanceImages
    : [];

  return (
    <Row className={classes.conImages}>
      {maintenanceImages.map((maintenanceImage) => (
        <Col
          key={maintenanceImage.id}
          xs={12}
          sm={maintenanceImages.length > 1 ? 6 : 12}
        >
          <MaintenanceImage maintenanceImage={maintenanceImage} />
        </Col>
      ))}
    </Row>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    conImages: {
      maxWidth: "100%",
      marginBottom: spacing(2),
    },
  });

export default withStyles(styles)(MaintenanceImages);
