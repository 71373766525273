import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DiscountThead from "./DiscountThead";
import DiscountTheadCategoryUpper from "./DiscountTheadCategoryUpper";
import DiscountTheadCategory from "./DiscountTheadCategory";
import DiscountRow from "./DiscountRow";
import { Col, Row, Table } from "react-bootstrap";
import {
  CatalogType,
  CustomerType,
  DiscountListType,
  DiscountRowType,
  IdPercentPrintInputType,
  Mutation,
  MutationDeleteDiscountListArgs,
  MutationGetOfferPdfArgs,
  MutationLockDiscountListArgs,
  MutationUpdateDiscountListArgs,
  MutationUpdateDiscountRowPercentsPrintsArgs,
} from "../../../entity/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFilePdf,
  faLock,
  faTasks,
  faTimes,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import {
  getPercentPrefixCalc,
  getPercentPrefixUserInput,
} from "../../../utils/discounts/prefixes";
import { formatNumber, parseNumber } from "../../../utils/formatting";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  GET_DISCOUNT_LIST_QUERY,
  GET_DISCOUNT_LISTS_QUERY,
} from "../../../apollo/queries/discounts";
import {
  DELETE_DISCOUNT_LIST_MUTATION,
  LOCK_DISCOUNT_LIST_MUTATION,
  UPDATE_DISCOUNT_LIST_MUTATION,
  UPDATE_DISCOUNT_ROW_PERCENTS_PRINTS_MUTATION,
} from "../../../apollo/mutations/discounts";
import { handleError } from "../../../entity/ErrorHandler";
import { PermissionsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { CustomerEmpty } from "../../../entity/empties";
import { GET_CUSTOMER_QUERY } from "../../../apollo/queries/customers";
import { formatPercentDiscountList } from "../../../utils/discounts/formatting";
import { getUrlCustomerTab, URL_SETTINGS_DISCOUNTS } from "../../../utils/urls";
import { dialogConfirm, popNewWindow } from "../../../utils/dialogs";
import {
  GET_OFFER_PDF_MUTATION,
  MutationResultOfferPdf,
} from "../../../apollo/mutations/catalogs";
import ButtonLoad from "../../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
  discountList: DiscountListType;
  setDiscountList: React.Dispatch<React.SetStateAction<DiscountListType>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  catalogCategoriesUpperVisible?: { [key: string]: boolean };
  catalogCategoriesVisible?: { [key: string]: boolean };
  catalogRowsVisible?: { [key: string]: boolean };
  customer?: CustomerType;
}

const DiscountTable = ({
  classes,
  catalog,
  discountList,
  setDiscountList,
  search,
  setSearch,
  catalogCategoriesUpperVisible = {},
  catalogCategoriesVisible = {},
  catalogRowsVisible = {},
  customer = CustomerEmpty,
}: Props) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [percent, setPercent] = useState("");
  const [showPrint, setShowPrint] = useState(-1);
  const [timeClickedSet, setTimeClickedSet] = useState(0);
  const [discountListEdited, setDiscountListEdited] = useState(discountList);

  const refetchQueries = [
    {
      query: GET_DISCOUNT_LIST_QUERY,
      variables: {
        discountListId: discountList.id,
      },
    },
  ];

  const [
    updateDiscountRowPercentsPrints,
    { loading: loadingUpdatePercentsPrints },
  ] = useMutation<Mutation, MutationUpdateDiscountRowPercentsPrintsArgs>(
    UPDATE_DISCOUNT_ROW_PERCENTS_PRINTS_MUTATION,
    {
      refetchQueries: refetchQueries,
      onError: (error) => {
        handleError(error);
      },
    }
  );
  const [deleteDiscountList, { loading: loadingDeleteDiscountList }] =
    useMutation<Mutation, MutationDeleteDiscountListArgs>(
      DELETE_DISCOUNT_LIST_MUTATION,
      {
        variables: {
          discountListId: discountList.id,
        },
        refetchQueries: [
          {
            query: GET_DISCOUNT_LISTS_QUERY,
            variables: {
              catalogId: catalog.id,
            },
          },
          { query: GET_CUSTOMER_QUERY, variables: { id: customer.id } },
        ],
        onError: (error) => {
          handleError(error);
        },
        onCompleted: () => {
          if (discountList.isOffer) {
            history.push(getUrlCustomerTab(customer.id, "offer"));
          } else {
            history.push(URL_SETTINGS_DISCOUNTS);
          }
        },
      }
    );
  const [lockDiscountList, { loading: loadingLockDiscountList }] = useMutation<
    Mutation,
    MutationLockDiscountListArgs
  >(LOCK_DISCOUNT_LIST_MUTATION, {
    variables: {
      discountListId: discountList.id,
    },
    refetchQueries: refetchQueries,
    onError: (error) => {
      handleError(error);
    },
    onCompleted: (data) => {
      if (data.lockDiscountList && data.lockDiscountList.discountList) {
        setDiscountList(
          formatPercentDiscountList(data.lockDiscountList.discountList)
        );
      }
    },
  });
  const [updateDiscountList, { loading: loadingUpdateDiscountList }] =
    useMutation<Mutation, MutationUpdateDiscountListArgs>(
      UPDATE_DISCOUNT_LIST_MUTATION,
      {
        refetchQueries: refetchQueries,
        onError: (error) => {
          handleError(error);
        },
      }
    );
  const [getOfferPdf, { loading: loadingGetOfferPdf }] = useMutation<
    MutationResultOfferPdf,
    MutationGetOfferPdfArgs
  >(GET_OFFER_PDF_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: (result) => {
      popNewWindow(t, String(result.getOfferPdf?.url));
    },
  });

  useEffect(() => {
    setDiscountListEdited(discountList);
  }, [discountList]);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditDiscountRow = checkPermission(myPermissions, [
    "discounts.change_discountrow",
  ]);
  const hasPermissionEditDiscountList = checkPermission(myPermissions, [
    "discounts.change_discountlist",
  ]);
  const hasPermissionDeleteDiscountList = checkPermission(myPermissions, [
    "discounts.delete_discountlist",
  ]);

  const handleClickSetPercentPrint = () => {
    const doPercentUpdate = percent !== "";
    const doPrintUpdate = showPrint !== -1;

    let prefix = getPercentPrefixUserInput(percent);
    let prefixCalc = getPercentPrefixCalc(percent);
    let percentNumber = parseNumber(percent);

    let discountListNew = {
      ...discountList,
      discountrowSet: discountList.discountrowSet.map(
        (discountRowLooped: DiscountRowType) => {
          if (selectedRows.indexOf(discountRowLooped.id) >= 0) {
            let discountRowNew = {
              ...discountRowLooped,
            };
            if (doPercentUpdate) {
              discountRowNew.percent = prefix + formatNumber(percentNumber, 2);
            }
            if (doPrintUpdate) {
              discountRowNew.showPrint = showPrint === 1;
            }
            return discountRowNew;
          }
          return {
            ...discountRowLooped,
          };
        }
      ),
    };
    setDiscountList(discountListNew);

    let idPercentPrintInput: Array<IdPercentPrintInputType> = [];
    discountListNew.discountrowSet.forEach(
      (discountRowLooped: DiscountRowType) => {
        if (selectedRows.indexOf(discountRowLooped.id) >= 0) {
          idPercentPrintInput.push({
            id: discountRowLooped.id,
            percent: doPercentUpdate ? prefixCalc + percentNumber : undefined,
            showPrint: doPrintUpdate ? showPrint === 1 : undefined,
          });
        }
      }
    );
    updateDiscountRowPercentsPrints({
      variables: { idPercentPrintInput: idPercentPrintInput },
    });
  };

  const onClickSet = () => {
    if (selectedRows.length === 0) {
      alert(t("no_rows_selected"));
      return;
    }
    if (percent === "" && showPrint === -1) {
      alert(t("set_percent_or_show_print_first"));
      return;
    }

    handleClickSetPercentPrint();
    setTimeClickedSet(new Date().getTime());
  };

  const onClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteDiscountList();
    });
  };

  const onClickLock = (event: React.MouseEvent<HTMLElement>) => {
    lockDiscountList();
  };

  const handleSaveDiscountList = () => {
    updateDiscountList({
      variables: {
        discountListId: discountListEdited.id,
        name: discountListEdited.name,
        isBusiness: discountListEdited.isBusiness,
        catalogId: catalog.id,
      },
    });
  };

  const onClickCreateOffer = () => {
    getOfferPdf({
      variables: {
        catalogId: catalog.id,
        customerId: customer.id,
        discountListId: discountList.id,
      },
    });
  };

  return (
    <>
      <Row className="mb-4">
        <Col>
          <Input
            value={search}
            className="mt-3"
            placeholder={t("search")}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                {search && (
                  <IconButton
                    aria-label={t("clear")}
                    onClick={() => {
                      setSearch("");
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />
        </Col>
        {hasPermissionEditDiscountRow && !discountList.locked && (
          <Col>
            <table>
              <tbody>
                <tr>
                  <td className="pe-3">{t("price_change")}</td>
                  <td>
                    <div className="flex-row d-flex">
                      <div>
                        <TextField
                          value={percent}
                          onChange={(event) => {
                            setPercent(event.target.value);
                          }}
                          onBlur={(event) => {
                            if (event.target.value === "") {
                              setPercent("");
                              return;
                            }

                            let prefix = getPercentPrefixUserInput(percent);
                            setPercent(
                              prefix +
                                formatNumber(parseNumber(event.target.value), 2)
                            );
                          }}
                        />
                      </div>
                      <div>%</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="pe-3">{t("show_print")}</td>
                  <td>
                    <Select
                      fullWidth
                      value={showPrint}
                      onChange={(event) => {
                        setShowPrint(Number(event.target.value));
                      }}
                    >
                      <MenuItem value={-1}>{t("not_selected")}</MenuItem>
                      <MenuItem value={1}>{t("yes")}</MenuItem>
                      <MenuItem value={0}>{t("no")}</MenuItem>
                    </Select>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="buttons mt-2">
              <ButtonLoad
                loading={loadingUpdatePercentsPrints}
                onClick={onClickSet}
                disabled={selectedRows.length === 0}
              >
                <FontAwesomeIcon className="me-2" icon={faTasks} /> {t("set")}
              </ButtonLoad>
              {selectedRows.length === 0 && (
                <p className="d-inline-block text-muted m-0">
                  {t("select_rows_first")}
                </p>
              )}
            </div>
          </Col>
        )}
        <Col>
          <div className="mb-3">
            <TextField
              label={t("name")}
              className={`float-end ${
                loadingUpdateDiscountList ? "loading" : ""
              }`}
              value={discountListEdited.name}
              disabled={Boolean(discountList.locked)}
              onChange={(event) => {
                setDiscountListEdited({
                  ...discountListEdited,
                  name: event.target.value,
                });
              }}
              onBlur={handleSaveDiscountList}
              inputProps={{ maxLength: 100 }}
            />
            <div className="clearfix"></div>
          </div>
          <div className="buttons float-end">
            {hasPermissionEditDiscountList && (
              <ButtonLoad
                loading={loadingLockDiscountList}
                onClick={onClickLock}
                disabled={Boolean(discountList.locked)}
              >
                <FontAwesomeIcon
                  className="me-2"
                  icon={discountList.locked ? faLock : faCheck}
                />
                {t("ready")}
              </ButtonLoad>
            )}
            <ButtonLoad
              loading={loadingGetOfferPdf}
              target="_blank"
              onClick={onClickCreateOffer}
            >
              <FontAwesomeIcon className="me-2" icon={faFilePdf} />
              {t("create_pdf")}
            </ButtonLoad>
            {hasPermissionDeleteDiscountList && !discountList.locked && (
              <ButtonLoad
                loading={loadingDeleteDiscountList}
                variant="light"
                onClick={onClickDelete}
              >
                <FontAwesomeIcon className="me-2" icon={faTrash} />
                {t("delete")}
              </ButtonLoad>
            )}
          </div>
        </Col>
      </Row>
      <Table className="tblDiscounts" borderless hover>
        <DiscountThead
          discountList={discountList}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          catalogRowsVisible={catalogRowsVisible}
        />
        {catalog.catalogcategoryupperSet.map((catalogCategoryUpper) => {
          if (!catalogCategoriesUpperVisible[catalogCategoryUpper.id]) {
            return (
              <React.Fragment key={catalogCategoryUpper.id}></React.Fragment>
            );
          }

          return (
            <React.Fragment key={catalogCategoryUpper.id}>
              <DiscountTheadCategoryUpper
                discountList={discountList}
                catalogCategoryUpper={catalogCategoryUpper}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                catalogRowsVisible={catalogRowsVisible}
              />
              {catalogCategoryUpper.catalogcategorySet.map(
                (catalogCategory) => {
                  if (!catalogCategoriesVisible[catalogCategory.id]) {
                    return (
                      <React.Fragment key={catalogCategory.id}></React.Fragment>
                    );
                  }

                  return (
                    <React.Fragment key={catalogCategory.id}>
                      <DiscountTheadCategory
                        discountList={discountList}
                        catalogCategory={catalogCategory}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        catalogRowsVisible={catalogRowsVisible}
                      />
                      <tbody>
                        {catalogCategory.catalogrowSet.map((catalogRow) => {
                          if (!catalogRowsVisible[catalogRow.id]) {
                            return (
                              <React.Fragment
                                key={catalogRow.id}
                              ></React.Fragment>
                            );
                          }

                          return (
                            <DiscountRow
                              key={catalogRow.id}
                              catalogRow={catalogRow}
                              discountList={discountList}
                              setDiscountList={setDiscountList}
                              selectedRows={selectedRows}
                              setSelectedRows={setSelectedRows}
                              timeClickedSet={timeClickedSet}
                            />
                          );
                        })}
                      </tbody>
                    </React.Fragment>
                  );
                }
              )}
            </React.Fragment>
          );
        })}
      </Table>
    </>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DiscountTable);
