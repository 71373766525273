import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { createStyles, Theme } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { CustomerType, OrderType } from "../../entity/types";
import { CustomerEmpty, UserEmpty } from "../../entity/empties";
import { PermissionsContext, UserContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import DialogCustomer from "./DialogCustomer";
import DialogCustomerSelectType from "./DialogCustomerSelectType";
import { ID_EMPTY } from "../../utils/constants";
import { useLazyQuery } from "@apollo/client";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import {
  GET_CUSTOMER_QUERY,
  QueryResultCustomer,
} from "../../apollo/queries/customers";
import DialogCustomerSub from "./DialogCustomerSub";
import { handleError } from "../../entity/ErrorHandler";

interface Props extends WithStyles<typeof styles> {
  btnClass: string;
  order?: OrderType;
  callbackCreateCustomer?: Function;
}

function CreateCustomer({
  classes,
  btnClass,
  order,
  callbackCreateCustomer,
}: Props) {
  const user = useContext(UserContext);

  const [openCreate, setOpenCreate] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [customer, setCustomer] = useState<CustomerType>({
    ...CustomerEmpty,
    userOwner: { ...UserEmpty, id: user.id },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "customers.add_customer",
  ]);

  const [getParent, { data: searchParent }] = useLazyQuery<QueryResultCustomer>(
    GET_CUSTOMER_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("customer"),
      variables: {
        id: ID_EMPTY,
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  if (!hasPermissionAdd) {
    return <></>;
  }

  const handleSetParent = (parentId: string) => {
    getParent({
      variables: {
        id: parentId,
      },
    });
  };

  const handleNext = () => {
    setOpenSelect(false);
    setOpenCreate(true);
  };

  const isChild = () => {
    if (searchParent?.customer) {
      if (searchParent.customer.id !== ID_EMPTY) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <Button
        onClick={() => setOpenSelect(true)}
        className={btnClass}
        variant="primary"
        size="lg"
      >
        {openSelect || openCreate ? <ClearIcon /> : <AddIcon />}
      </Button>
      {openSelect && (
        <DialogCustomerSelectType
          open={openSelect}
          handleNext={handleNext}
          setOpenSelect={setOpenSelect}
          setParent={handleSetParent}
        />
      )}
      {openCreate && !isChild() && (
        <DialogCustomer
          customer={customer}
          setCustomer={setCustomer}
          open={openCreate}
          setOpen={setOpenCreate}
          order={order}
          callbackCreateCustomer={callbackCreateCustomer}
        />
      )}
      {openCreate && isChild() && (
        <DialogCustomerSub
          open={openCreate}
          setOpen={setOpenCreate}
          customerParent={
            searchParent?.customer ? searchParent.customer : CustomerEmpty
          }
          callbackCreateCustomer={callbackCreateCustomer}
        />
      )}
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CreateCustomer);
