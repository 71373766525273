import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export const FRAG_REFERENCE_SHARED = gql`
  fragment ReferenceFields on ReferenceType {
    id
    name
  }
`;

export interface QueryResultReferences {
  references: Query["references"];
}
export const GET_REFERENCES_QUERY = gql`
  ${FRAG_REFERENCE_SHARED}
  query getReferencesQuery($customerId: ID!) {
    references(customerId: $customerId) {
      ...ReferenceFields
    }
  }
`;

export interface QueryResultReferencesByIds {
  referencesByIds: Query["referencesByIds"];
}
export const GET_REFERENCES_BY_IDS_QUERY = gql`
  query getReferencesByIdsQuery($referenceIds: [ID]!) {
    referencesByIds(referenceIds: $referenceIds) {
      id
      name
      customer {
        id
        name
      }
    }
  }
`;
