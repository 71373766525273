import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { createStyles, Theme } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import UserList from "../components/User/UserList";
import { GET_USERS_QUERY, QueryResultUsers } from "../apollo/queries/users";
import CreateUser from "../components/User/CreateUser";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  GET_LOCATIONS_QUERY,
  QueryResultLocations,
} from "../apollo/queries/locations";
import {
  GET_PROFILES_QUERY,
  QueryResultProfiles,
} from "../apollo/queries/profiles";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {}

function SettingsUsers({ classes }: Props) {
  const { t } = useTranslation();

  const {
    loading: loadingLocations,
    error: errorLocations,
    data: dataLocations,
  } = useQuery<QueryResultLocations>(GET_LOCATIONS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("locations"),
  });

  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers,
  } = useQuery<QueryResultUsers>(GET_USERS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("users"),
  });

  const {
    loading: loadingProfiles,
    error: errorProfiles,
    data: dataProfiles,
  } = useQuery<QueryResultProfiles>(GET_PROFILES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("groups"),
  });

  if (loadingLocations || loadingUsers || loadingProfiles) return <Loading />;
  if (errorLocations) return <Error error={errorLocations} />;
  if (errorUsers) return <Error error={errorUsers} />;
  if (errorProfiles) return <Error error={errorProfiles} />;
  if (!dataLocations || !dataUsers || !dataProfiles) {
    return <Error error={t("error_query_failed")} />;
  }

  return (
    <Container className="containerMain">
      <CreateUser
        groups={dataProfiles.groups ? dataProfiles.groups : []}
        locations={dataLocations.locations ? dataLocations.locations : []}
      />§
      <UserList
        users={dataUsers.users ? dataUsers.users : []}
        groups={dataProfiles.groups ? dataProfiles.groups : []}
        locations={dataLocations.locations ? dataLocations.locations : []}
      />
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(SettingsUsers);
