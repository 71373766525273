import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { createStyles, Theme } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import {
  GET_CATALOGS_WITHOUT_ROWS_QUERY,
  QueryResultCatalogsWithoutRows,
} from "../apollo/queries/catalogs";
import CreateCatalog from "../components/Catalog/CreateCatalog";
import { Col, Container, Row } from "react-bootstrap";
import { CatalogType } from "../entity/types";
import { useTranslation } from "react-i18next";
import { DATETIME_FORMAT_ISO } from "../utils/constants";
import CatalogList from "../components/Catalog/CatalogList";
import format from "date-fns/format";
import { newDate } from "../utils/dates";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import HeaderPage from "../components/Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {}

function SettingsCatalog({ classes }: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<QueryResultCatalogsWithoutRows>(
    GET_CATALOGS_WITHOUT_ROWS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("catalogs"),
    }
  );

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  let catalogsEditable: CatalogType[] = [];
  let catalogsActivated: CatalogType[] = [];
  data.catalogs?.forEach((catalog) => {
    if (catalog.setActiveAt) {
      catalogsActivated.push(catalog);
    } else {
      catalogsEditable.push(catalog);
    }
  });

  catalogsActivated.sort((a, b) =>
    format(newDate(b.setActiveAt), DATETIME_FORMAT_ISO).localeCompare(
      format(newDate(a.setActiveAt), DATETIME_FORMAT_ISO)
    )
  );

  return (
    <Container className="containerMain">
      <HeaderPage title={t("catalog")} />
      <CreateCatalog />
      <div className="containerInner">
        <Row>
          <Col>
            <CatalogList
              catalogs={catalogsEditable}
              title={t("catalogs_editable")}
            />
          </Col>
          <Col>
            <CatalogList
              catalogs={catalogsActivated}
              title={t("catalogs_activated")}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(SettingsCatalog);
