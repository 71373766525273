import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import {
  CatalogExtraRowInvoiceType,
  Mutation,
  MutationDeleteCatalogExtraRowInvoiceArgs,
  MutationUpdateCatalogExtraRowInvoiceArgs,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import ButtonLoad from "../Shared/ButtonLoad";
import { useMutation } from "@apollo/client";
import {
  DELETE_CATALOG_EXTRA_ROW_INVOICE_MUTATION,
  UPDATE_CATALOG_EXTRA_ROW_INVOICE_MUTATION,
} from "../../apollo/mutations/catalogs_extra";
import { handleError } from "../../entity/ErrorHandler";
import { dialogConfirm } from "../../utils/dialogs";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCatalogExtraRowInvoiceTitle } from "../../utils/catalog_extra/catalog_extra";
import format from "date-fns/format";
import { newDate } from "../../utils/dates";
import TextFieldFocus from "../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {
  catalogExtraRowInvoice: CatalogExtraRowInvoiceType;
}

function CatalogExtraRowInvoiceTr({ classes, catalogExtraRowInvoice }: Props) {
  const { t } = useTranslation();

  const [catalogExtraRowInvoiceEdited, setCatalogExtraRowInvoiceEdited] =
    useState({
      ...catalogExtraRowInvoice,
      quantity: formatNumber(catalogExtraRowInvoice.quantity, 2),
      unitPriceOverride: formatNumber(
        catalogExtraRowInvoice.unitPriceOverride,
        2
      ),
    });

  const [updateCatalogExtraRowInvoice, { loading: loadingUpdate }] =
    useMutation<Mutation, MutationUpdateCatalogExtraRowInvoiceArgs>(
      UPDATE_CATALOG_EXTRA_ROW_INVOICE_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("catalogExtraRowsInvoiceReservation"),
          });
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("orderTotal"),
          });
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("invoiceableRows"),
          });
        },
      }
    );

  const [deleteCatalogExtraRowInvoice, { loading: loadingDelete }] =
    useMutation<Mutation, MutationDeleteCatalogExtraRowInvoiceArgs>(
      DELETE_CATALOG_EXTRA_ROW_INVOICE_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("catalogExtraRowsInvoiceReservation"),
          });
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("orderTotal"),
          });
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("invoiceableRows"),
          });
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs_extra.change_catalogextrarowinvoice",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "catalogs_extra.add_catalogextrarowinvoice",
  ]);

  const rowAddedToInvoice = Boolean(catalogExtraRowInvoiceEdited.invoiceRow);
  const rowInvoiceSent =
    rowAddedToInvoice &&
    catalogExtraRowInvoiceEdited.invoiceRow?.invoice.sentAt;

  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCatalogExtraRowInvoice({
        variables: {
          catalogExtraRowInvoiceId: catalogExtraRowInvoiceEdited.id,
        },
      });
    });
  };

  const handleUpdate = (
    values: {
      [key: string]: string | number | undefined;
    } = {}
  ) => {
    let base: MutationUpdateCatalogExtraRowInvoiceArgs = {
      catalogExtraRowInvoiceId: catalogExtraRowInvoiceEdited.id,
      catalogExtraRowUnitPriceId:
        catalogExtraRowInvoiceEdited.catalogExtraRowUnitPrice
          ? catalogExtraRowInvoiceEdited.catalogExtraRowUnitPrice.id
          : ID_EMPTY,
      reservationId: catalogExtraRowInvoiceEdited.reservation.id,
      productCodeId: catalogExtraRowInvoiceEdited.productCode
        ? catalogExtraRowInvoiceEdited.productCode.id
        : ID_EMPTY,
      titleOverride: catalogExtraRowInvoiceEdited.titleOverride,
      quantity: parseNumber(catalogExtraRowInvoiceEdited.quantity),
      unitPriceOverride: parseNumber(
        catalogExtraRowInvoiceEdited.unitPriceOverride
      ),
      answer: catalogExtraRowInvoiceEdited.answer,
    };

    updateCatalogExtraRowInvoice({
      variables: { ...base, ...values },
    });
  };

  const disabled = rowAddedToInvoice || !hasPermissionEdit;

  return (
    <>
      <tr className="d-table-row d-sm-none">
        <td colSpan={6}>
          {getCatalogExtraRowInvoiceTitle(catalogExtraRowInvoiceEdited)}
        </td>
      </tr>
      <tr className={loadingUpdate ? "loading" : ""}>
        <td className="d-none d-sm-table-cell">
          {getCatalogExtraRowInvoiceTitle(catalogExtraRowInvoiceEdited)}
        </td>
        <td className={classes.tdQuantity}>
          <FormControl>
            <TextFieldFocus
              value={catalogExtraRowInvoiceEdited.quantity}
              disabled={disabled}
              InputProps={{
                endAdornment:
                  catalogExtraRowInvoiceEdited.catalogExtraRowUnitPrice ? (
                    <span>
                      {
                        catalogExtraRowInvoiceEdited.catalogExtraRowUnitPrice
                          .unit
                      }
                    </span>
                  ) : undefined,
              }}
              onChange={(event) => {
                setCatalogExtraRowInvoiceEdited({
                  ...catalogExtraRowInvoiceEdited,
                  quantity: event.target.value,
                });
              }}
              onBlur={() => {
                const quantity = parseNumber(
                  catalogExtraRowInvoiceEdited.quantity
                );
                setCatalogExtraRowInvoiceEdited({
                  ...catalogExtraRowInvoiceEdited,
                  quantity: formatNumber(quantity, 2),
                });
                handleUpdate({ quantity: quantity });
              }}
            />
          </FormControl>
        </td>
        <td>
          {catalogExtraRowInvoiceEdited.catalogExtraRowUnitPrice
            ? formatNumber(
                catalogExtraRowInvoiceEdited.catalogExtraRowUnitPrice.unitPrice,
                2
              )
            : ""}
          {!catalogExtraRowInvoiceEdited.catalogExtraRowUnitPrice && (
            <FormControl>
              <TextFieldFocus
                value={catalogExtraRowInvoiceEdited.unitPriceOverride}
                disabled={disabled}
                onChange={(event) => {
                  setCatalogExtraRowInvoiceEdited({
                    ...catalogExtraRowInvoiceEdited,
                    unitPriceOverride: event.target.value,
                  });
                }}
                onBlur={() => {
                  const unitPriceOverride = parseNumber(
                    catalogExtraRowInvoiceEdited.unitPriceOverride
                  );
                  setCatalogExtraRowInvoiceEdited({
                    ...catalogExtraRowInvoiceEdited,
                    unitPriceOverride: formatNumber(unitPriceOverride, 2),
                  });
                  handleUpdate({
                    unitPriceOverride: unitPriceOverride,
                  });
                }}
              />
            </FormControl>
          )}
        </td>
        <td>
          {catalogExtraRowInvoiceEdited.catalogExtraRowUnitPrice &&
            catalogExtraRowInvoiceEdited.catalogExtraRowUnitPrice
              .requireAnswer && (
              <FormControl>
                <TextField
                  value={catalogExtraRowInvoiceEdited.answer}
                  required={true}
                  disabled={disabled}
                  onChange={(event) => {
                    setCatalogExtraRowInvoiceEdited({
                      ...catalogExtraRowInvoiceEdited,
                      answer: event.target.value,
                    });
                  }}
                  onBlur={() => {
                    const answer = catalogExtraRowInvoiceEdited.answer;
                    setCatalogExtraRowInvoiceEdited({
                      ...catalogExtraRowInvoiceEdited,
                      answer: answer,
                    });
                    handleUpdate({ answer: answer });
                  }}
                />
              </FormControl>
            )}
        </td>
        <td>
          {catalogExtraRowInvoiceEdited.catalogExtraRowUnitPrice &&
            catalogExtraRowInvoiceEdited.catalogExtraRowUnitPrice.minCharge >
              0 && (
              <span>
                {
                  catalogExtraRowInvoiceEdited.catalogExtraRowUnitPrice
                    .minCharge
                }
              </span>
            )}
        </td>
        <td>
          {rowInvoiceSent && (
            <small>
              {t("invoiced_at", {
                date: format(
                  newDate(
                    catalogExtraRowInvoiceEdited.invoiceRow?.invoice.sentAt
                  ),
                  t("format_date")
                ),
              })}
            </small>
          )}
          {rowAddedToInvoice && !rowInvoiceSent && (
            <small>
              {t("added_to_invoice_at", {
                date: format(
                  newDate(
                    catalogExtraRowInvoiceEdited.invoiceRow?.invoice.createdAt
                  ),
                  t("format_date")
                ),
              })}
            </small>
          )}
          {rowAddedToInvoice &&
            catalogExtraRowInvoiceEdited.invoiceRow?.invoice.isAdvance && (
              <div>
                <small>{t("pre_invoice_suffix")}</small>
              </div>
            )}
        </td>
        <td>
          {!rowAddedToInvoice && hasPermissionDelete && (
            <ButtonLoad
              loading={loadingDelete}
              onClick={onClickDelete}
              variant="light"
              title={t("delete")}
            >
              <FontAwesomeIcon icon={faTrash} />
            </ButtonLoad>
          )}
        </td>
      </tr>
    </>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    tdQuantity: {
      minWidth: "5rem",
    },
  });

export default withStyles(styles)(CatalogExtraRowInvoiceTr);
