import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Paper, TextField, Theme } from "@material-ui/core";
import {
  MachineType,
  Mutation,
  MutationCopyMachineCatalogExtraRowUnitPriceArgs,
} from "../../entity/types";
import { getMachineName } from "../../utils/machines/machine";
import {
  GET_MACHINES_SIMPLE_QUERY,
  QueryResultMachinesSimple,
} from "../../apollo/queries/machines";
import { useMutation, useQuery } from "@apollo/client";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { COPY_MACHINE_CATALOG_EXTRA_ROW_UNIT_PRICE } from "../../apollo/mutations/machines";
import { handleError, handleMessage } from "../../entity/ErrorHandler";
import { getQueryKey } from "../../utils/cache";
import { ROOT_QUERY } from "../../utils/constants";
import { Autocomplete } from "@material-ui/lab";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
}

function MachineCatalogExtraRowUnitPriceCopy({
  classes,
  machine,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<QueryResultMachinesSimple>(
    GET_MACHINES_SIMPLE_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("machines"),
    }
  );

  const [copyRows, { loading: loadingCopy }] = useMutation<
    Mutation,
    MutationCopyMachineCatalogExtraRowUnitPriceArgs
  >(COPY_MACHINE_CATALOG_EXTRA_ROW_UNIT_PRICE, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: () => {
      handleMessage(t("success_machine_catalog_updated"));
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogExtraRowsRental"),
      });
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const machines: MachineType[] = (data.machines ? data.machines : []).filter(
    (machineLooped) => machineLooped.id !== machine.id
  );

  const onChange = (
    event: React.ChangeEvent<{}>,
    machineSelected: MachineType | null
  ) => {
    if (machineSelected) {
      copyRows({
        variables: {
          machineIdCopyFrom: machineSelected.id,
          machineIdCopyTo: machine.id,
        },
      });
    }
  };

  return (
    <Paper className={`p-3 mb-2 ${loadingCopy ? "loading" : ""}`}>
      <Autocomplete
        options={machines}
        getOptionLabel={(machine: MachineType) => getMachineName(machine, true)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("machine_catalog_extra_copy")}
            variant="outlined"
          />
        )}
        value={null}
        onChange={onChange}
      />
    </Paper>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MachineCatalogExtraRowUnitPriceCopy);
