import { gql } from "@apollo/client";

export const UPDATE_SETTINGS_MUTATION = gql`
  mutation updateSettings(
    $vat: Decimal
    $productNumberSingle: String
    $productNumberOpHours: String
    $productNumberInsurance: String
    $productNumberBillingSurcharge: String
    $productNumberOpeningFee: String
    $insurancePercent: Decimal
    $billingSurchargeAmount: Decimal
    $billingSurchargeLimit: Decimal
    $openingFeeAmount: Decimal
    $openingFeeLimit: Decimal
    $commissionOwner: Decimal
    $commissionOrderer: Decimal
    $commissionRentThrough: Decimal
    $textConfirmation1: String
    $textConfirmation2: String
    $textOffer0: String
    $textOffer1: String
    $textOffer2: String
    $textContract1: String
    $textContract2: String
  ) {
    updateSettings(
      vat: $vat
      productNumberSingle: $productNumberSingle
      productNumberOpHours: $productNumberOpHours
      productNumberInsurance: $productNumberInsurance
      productNumberBillingSurcharge: $productNumberBillingSurcharge
      productNumberOpeningFee: $productNumberOpeningFee
      insurancePercent: $insurancePercent
      billingSurchargeAmount: $billingSurchargeAmount
      billingSurchargeLimit: $billingSurchargeLimit
      openingFeeAmount: $openingFeeAmount
      openingFeeLimit: $openingFeeLimit
      commissionOwner: $commissionOwner
      commissionOrderer: $commissionOrderer
      commissionRentThrough: $commissionRentThrough
      textConfirmation1: $textConfirmation1
      textConfirmation2: $textConfirmation2
      textOffer0: $textOffer0
      textOffer1: $textOffer1
      textOffer2: $textOffer2
      textContract1: $textContract1
      textContract2: $textContract2
    ) {
      settings {
        id
        vat
        productNumberSingle
        productNumberOpHours
        productNumberInsurance
        productNumberBillingSurcharge
        productNumberOpeningFee
        insurancePercent
        billingSurchargeAmount
        billingSurchargeLimit
        openingFeeAmount
        openingFeeLimit
        commissionOwner
        commissionOrderer
        commissionRentThrough
        textConfirmation1
        textConfirmation2
        textOffer0
        textOffer1
        textOffer2
        textContract1
        textContract2
      }
    }
  }
`;
