import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import {
  CatalogExtraCategoryUpperRentalType,
  Mutation,
  MutationCreateCatalogExtraCategoryUpperRentalArgs,
  MutationUpdateCatalogExtraCategoryUpperRentalArgs,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { CatalogExtraCategoryUpperRentalEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import {
  CREATE_CATALOG_EXTRA_CATEGORY_UPPER_RENTAL_MUTATION,
  UPDATE_CATALOG_EXTRA_CATEGORY_UPPER_RENTAL_MUTATION,
} from "../../apollo/mutations/catalogs_extra";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import DialogContentCatalogExtraCategoryUpperRental from "./DialogContentCatalogExtraCategoryUpperRental";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalogExtraCategoryUpperRental: CatalogExtraCategoryUpperRentalType;
}

function DialogCatalogExtraCategoryUpperRental({
  classes,
  open,
  setOpen,
  catalogExtraCategoryUpperRental,
}: Props) {
  const { t } = useTranslation();
  const [
    catalogExtraCategoryUpperRentalEdited,
    setCatalogExtraCategoryUpperRentalEdited,
  ] = useState(catalogExtraCategoryUpperRental);

  const [createCatalogExtraCategoryUpperRental, { loading: loadingCreate }] =
    useMutation<Mutation, MutationCreateCatalogExtraCategoryUpperRentalArgs>(
      CREATE_CATALOG_EXTRA_CATEGORY_UPPER_RENTAL_MUTATION,
      {
        onCompleted: (result) => {
          setCatalogExtraCategoryUpperRentalEdited(
            CatalogExtraCategoryUpperRentalEmpty
          );
        },
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("catalogExtraCategoriesUpperRental"),
          });
        },
      }
    );
  const [updateCatalogExtraCategoryUpperRental, { loading: loadingUpdate }] =
    useMutation<Mutation, MutationUpdateCatalogExtraCategoryUpperRentalArgs>(
      UPDATE_CATALOG_EXTRA_CATEGORY_UPPER_RENTAL_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("catalogExtraCategoriesUpperRental"),
          });
        },
      }
    );

  const handleSubmitCatalogExtra = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (catalogExtraCategoryUpperRentalEdited.id !== ID_EMPTY) {
      updateCatalogExtraCategoryUpperRental({
        variables: {
          catalogExtraCategoryUpperRentalId:
            catalogExtraCategoryUpperRentalEdited.id,
          name: catalogExtraCategoryUpperRentalEdited.name,
        },
      });
    } else {
      createCatalogExtraCategoryUpperRental({
        variables: {
          name: catalogExtraCategoryUpperRentalEdited.name,
        },
      });
    }
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <form
        className={loadingCreate || loadingUpdate ? "loading" : ""}
        onSubmit={(event) => handleSubmitCatalogExtra(event)}
      >
        <DialogTitle>
          {t(
            catalogExtraCategoryUpperRentalEdited.id !== ID_EMPTY
              ? "edit_catalog_extra_category_upper"
              : "new_catalog_extra_category_upper"
          )}
        </DialogTitle>
        <DialogContentCatalogExtraCategoryUpperRental
          catalogExtraCategoryUpperRental={
            catalogExtraCategoryUpperRentalEdited
          }
          setCatalogExtraCategoryUpperRental={
            setCatalogExtraCategoryUpperRentalEdited
          }
        />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <Button
            disabled={!catalogExtraCategoryUpperRentalEdited.name.trim()}
            type="submit"
            variant="primary"
          >
            {t("save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCatalogExtraCategoryUpperRental);
