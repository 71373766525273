import { ID_EMPTY } from "../utils/constants";
import {
  CatalogCategoryType,
  CatalogCategoryUpperType,
  CatalogExtraCategoryRentalType,
  CatalogExtraCategoryUnitPriceType,
  CatalogExtraCategoryUpperRentalType,
  CatalogExtraRowInvoiceType,
  CatalogExtraRowRentalType,
  CatalogExtraRowUnitPriceType,
  CatalogRowType,
  CatalogTransferMethodType,
  CatalogTransferPriceType,
  CatalogType,
  CommissionLevelType,
  CommissionRangeType,
  CommissionTemplateType,
  CommissionUserSettingType,
  CustomerCreditRiskClass,
  CustomerType,
  DiscountListType,
  DiscountRowType,
  EventEventType,
  EventType,
  HolidayHolidayType,
  HolidayType,
  IdentificationRequestType,
  InspectionGiveCategoryType,
  InspectionGiveQuestionAnswerType,
  InspectionGiveQuestionOptionType,
  InspectionGiveQuestionType,
  InspectionGiveTagType,
  InspectionReturnCategoryType,
  InspectionReturnQuestionAnswerType,
  InspectionReturnQuestionOptionType,
  InspectionReturnQuestionType,
  InspectionReturnTagType,
  InvoiceStatus,
  InvoiceType,
  LocationType,
  MachineBreakdownBilling,
  MachineBreakdownType,
  MachineBulkAmountType,
  MachineBulkBreakdownType,
  MachineInfoCategoryType,
  MachineInfoQuestionAnswerType,
  MachineInfoQuestionOptionType,
  MachineInfoQuestionType,
  MachineInfoTagType,
  MachineMakeType,
  MachineModelType,
  MachineRentThroughCommissionCase,
  MachineType,
  MaintenanceAlbumType,
  MaintenanceCommentType,
  MaintenanceImageImageType,
  MaintenanceImageType,
  MaintenanceIntervalIntervalType,
  MaintenanceIntervalType,
  MaintenanceMaintenanceType,
  MaintenanceSettingScheduleType,
  MaintenanceSettingType,
  MaintenanceType,
  NextMaintenanceType,
  OperationHourSource,
  OperationHourType,
  OrderConfirmationType,
  OrderStatus,
  OrderType,
  ProductCodeType,
  ReferenceType,
  ReservationAlbumType,
  ReservationDeliveryMethod,
  ReservationStatus,
  ReservationType,
  SettingsType,
  TransportInvoicingMethod,
  TransportInvoicingRowType,
  TransportInvoicingType,
  TransportMethod,
  TransportType,
  UserInfoLanguage,
  UserInfoType,
  UserType,
} from "./types";
import { AuthType } from "../Root";

export const AuthEmpty: AuthType = {
  Calendar: undefined,
  Customers: undefined,
  Profiles: undefined,
  Users: undefined,
  Logs: undefined,
  Holidays: undefined,
  Locations: undefined,
  CatalogNew: undefined,
  Catalogs: undefined,
  CatalogsExtraRental: undefined,
  CatalogsExtraUnitPrice: undefined,
  CatalogsTransfer: undefined,
  Settings: undefined,
  Machines: undefined,
  MachineInformation: undefined,
  InspectionReturn: undefined,
  InspectionGive: undefined,
  SettingsCommissions: undefined,
  SettingsOther: undefined,
  Discounts: undefined,
  WorkQueues: undefined,
  WorkQueuesDrivingCreate: undefined,
  WorkQueuesRenting: undefined,
  WorkQueuesMaintenance: undefined,
  Management: undefined,
  ManagementInvoicesCreate: undefined,
  ManagementInvoicesOpen: undefined,
  ManagementInvoicesSent: undefined,
  ManagementCommissions: undefined,
  ManagementReportsDay: undefined,
  OperatorMainPage: undefined,
  IdentifyPage: undefined
};

export const UserInfoEmpty: UserInfoType = {
  id: ID_EMPTY,
  phone: "",
  address: "",
  postcode: "",
  district: "",
  language: UserInfoLanguage.Fi,
  location: undefined,
  // @ts-ignore
  user: undefined,
};

export const UserEmpty: UserType = {
  id: ID_EMPTY,
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  language: "fi",
  catalogSet: [],
  commissionSet: [],
  costcenterSet: [],
  customersCreated: [],
  discountlistSet: [],
  eventSet: [],
  eventoffercatalogSet: [],
  eventofferdiscountlistSet: [],
  orderSet: [],
  reservationsCreated: [],
  reservationsGiven: [],
  reservationsReturned: [],
  reservationcommentSet: [],
  machinebreakdownSet: [],
  machinebreakdowncommentSet: [],
  machineBreakdownsFixed: [],
  invoicerowSet: [],
  invoiceSet: [],
  isActive: false,
  isStaff: false,
  isSuperuser: false,
  logSet: [],
  userPermissions: [],
  UserInfo: UserInfoEmpty,
  groups: [
    {
      id: ID_EMPTY,
      name: "",
      permissions: [],
      userSet: [],
    },
  ],
  dateJoined: "",
  lastLogin: "",
  customersOwned: [],
  transportSet: [],
  transportinvoicingSet: [],
  driveSet: [],
  reservationimageSet: [],
  reservationimagebasicSet: [],
  maintenancecommentSet: [],
  maintenanceintervalSet: [],
  maintenanceSet: [],
  maintenancesettingSet: [],
  serviceTechnician: [],
  maintenanceimageSet: [],
  commissionReportsCreated: [],
  commissionreportSet: [],
};

export const SimpleIdentificationRequestEmpty: IdentificationRequestType = {
  id: ID_EMPTY,
  createdTs: undefined,
  identificationLink: "",
  operatorHandlingCompletedTs: undefined,
  identificationCompletedTs: undefined,
  trustedBirthPlace: "",
  trustedFullName: "",
  trustedNationalPersonalId: ""
}

export const CustomerEmpty: CustomerType = {
  id: ID_EMPTY,
  customerNumber: "",
  businessId: "",
  name: "",
  contactNext: "",
  isBusiness: true,
  contactPersonFirstname: "",
  contactPersonLastname: "",
  email: "",
  phone: "",
  industry: "",
  invoicingAddress: "",
  invoicingDistrict: "",
  invoicingEmail: "",
  invoicingPostcode: "",
  invoicingEAddress: "",
  invoicingEOperator: "",
  userOwner: UserEmpty,
  visitAddress: "",
  visitDistrict: "",
  visitPostcode: "",
  creditInformationOk: false,
  hasInsurance: false,
  creditInformationCheckedAt: undefined,
  creditRiskClass: CustomerCreditRiskClass.ClassNone,
  blockedAt: undefined,
  blockedReason: "",
  preInvoicingAt: undefined,
  preInvoicingReason: "",
  customerParent: undefined,
  useParentInvoicing: false,
  useParentPricing: false,
  catalogSelected: undefined,
  discountListSelected: undefined,
  referenceSet: [],
  customersChildren: [],
  eventSet: [],
  eventoffercatalogSet: [],
  eventofferdiscountlistSet: [],
  customersDiscountLists: [],
  orderSet: [],
  createdAt: "",
  createdBy: UserEmpty,
};

export const CatalogEmpty: CatalogType = {
  id: ID_EMPTY,
  catalogSource: undefined,
  name: "",
  catalogcategoryupperSet: [],
  discountlistSet: [],
  customerSet: [],
  eventoffercatalogSet: [],
  catalogsSourced: [],
  setActiveAt: "",
  createdAt: "",
  createdBy: UserEmpty,
};

export const CatalogCategoryUpperEmpty: CatalogCategoryUpperType = {
  id: ID_EMPTY,
  catalogCategoryUpperSource: undefined,
  name: "",
  catalog: CatalogEmpty,
  catalogcategorySet: [],
  catalogCategoriesUpperSourced: [],
  orderCategoryUpper: 0,
  createdAt: "",
};

export const CatalogCategoryEmpty: CatalogCategoryType = {
  id: ID_EMPTY,
  catalogCategorySource: undefined,
  name: "",
  information: "",
  image: "",
  orderCategory: 0,
  catalogCategoryUpper: CatalogCategoryUpperEmpty,
  catalogrowSet: [],
  catalogCategoriesSourced: [],
  createdAt: "",
};

export const CatalogRowEmpty: CatalogRowType = {
  id: ID_EMPTY,
  catalogRowSource: undefined,
  name: "",
  catalogCategory: CatalogCategoryEmpty,
  information: "",
  image: "",
  orderRow: 0,
  billingDaysWeekCompany: 0,
  billingDaysWeekPrivate: 0,
  priceDayCompany: "0.0",
  priceDayPrivate: "0.0",
  priceMonthCompany: "0.0",
  priceMonthPrivate: "0.0",
  hourLimitDayCompany: 0,
  hourLimitDayPrivate: 0,
  hourLimitMonthCompany: 0,
  hourLimitMonthPrivate: 0,
  extraHoursCompany: "0.0",
  extraHoursPrivate: "0.0",
  productNumberDay: "",
  productNumberMonth: "",
  discountrowSet: [],
  machineSet: [],
  reservationSet: [],
  catalogRowsSourced: [],
  createdAt: "",
};

export const SettingsEmpty: SettingsType = {
  id: ID_EMPTY,
  vat: "24.0",
  vatFactor: 1.24,
  productNumberSingle: "",
  productNumberOpHours: "",
  productNumberInsurance: "",
  productNumberBillingSurcharge: "",
  productNumberOpeningFee: "",
  billingSurchargeAmount: 0.0,
  billingSurchargeLimit: 0.0,
  insurancePercent: 0.0,
  openingFeeAmount: 0.0,
  openingFeeLimit: 0.0,
  commissionOwner: 0.0,
  commissionOrderer: 0.0,
  commissionRentThrough: 0.0,
  textConfirmation1: "",
  textConfirmation2: "",
  textOffer0: "",
  textOffer1: "",
  textOffer2: "",
  textContract1: "",
  textContract2: "",
};

export const LocationEmpty: LocationType = {
  id: ID_EMPTY,
  name: "",
  dimIdentifier: "",
  transferDistanceIncluded: 0,
  transferDistanceLimit: 0,
  machineSet: [],
  userinfoSet: [],
  catalogtransferpriceSet: [],
  catalogextrarowrentalSet: [],
  machinebulkamountSet: [],
  orderSet: [],
  createdAt: "",
};

export const MachineMakeEmpty: MachineMakeType = {
  id: ID_EMPTY,
  title: "",
  machinemodelSet: [],
  createdAt: "",
};

export const MachineModelEmpty: MachineModelType = {
  id: ID_EMPTY,
  title: "",
  machineMake: MachineMakeEmpty,
  machineSet: [],
  createdAt: "",
};

export const MachineEmpty: MachineType = {
  id: ID_EMPTY,
  location: LocationEmpty,
  machineModel: MachineModelEmpty,
  information: "",
  identifier: "",
  serial: "",
  acquisitionDate: undefined,
  dateRemoved: null,
  yearModel: 0,
  hourTracking: false,
  rentThrough: false,
  rentThroughCompany: "",
  rentThroughInformation: "",
  rentThroughCommissionCase: MachineRentThroughCommissionCase.No,
  bulkProduct: false,
  catalogRows: [],
  catalogExtraRowsRental: [],
  catalogExtraRowsUnitPrice: [],
  reservationSet: [],
  machinebreakdownSet: [],
  machineinfoanswerSet: [],
  operationhourSet: [],
  machinebulkamountSet: [],
  machinebulkbreakdownSet: [],
  inspectionreturntomachineSet: [],
  inspectiongivetomachineSet: [],
  maintenanceSet: [],
  createdAt: "",
};

export const MachineBreakdownEmpty: MachineBreakdownType = {
  id: ID_EMPTY,
  catalogExtraRowRental: undefined,
  title: "",
  information: "",
  billing: MachineBreakdownBilling.Yes,
  amountBilledSingle: 0.0,
  machine: MachineEmpty,
  fixByEnd: "",
  fixByStart: "",
  fixedAt: "",
  fixedBy: undefined,
  machinebreakdowncommentSet: [],
  invoiceRow: undefined,
  stillRentable: false,
  createdAt: "",
  createdBy: UserEmpty,
};

export const MachineInfoCategoryEmpty: MachineInfoCategoryType = {
  id: ID_EMPTY,
  name: "",
  machineinfoquestionSet: [],
  createdAt: "",
};

export const MachineInfoQuestionEmpty: MachineInfoQuestionType = {
  id: ID_EMPTY,
  question: "",
  answerType: MachineInfoQuestionAnswerType.Text,
  showProductCard: true,
  showDrive: false,
  machineInfoCategory: MachineInfoCategoryEmpty,
  machineinfoanswerSet: [],
  machineinfotagSet: [],
  machineinfoquestionoptionSet: [],
  createdAt: "",
};

export const MachineInfoQuestionOptionEmpty: MachineInfoQuestionOptionType = {
  id: ID_EMPTY,
  title: "",
  createdAt: "",
  machineInfoQuestion: MachineInfoQuestionEmpty,
  machineinfoanswerSet: [],
};

export const MachineInfoTagEmpty: MachineInfoTagType = {
  id: ID_EMPTY,
  name: "",
  machineInfoQuestions: [],
  createdAt: "",
};

export const HolidayEmpty: HolidayType = {
  id: ID_EMPTY,
  holidayType: HolidayHolidayType.Private,
  date: "",
  name: "",
  createdAt: "",
};

export const CatalogExtraCategoryUpperRentalEmpty: CatalogExtraCategoryUpperRentalType =
  {
    id: ID_EMPTY,
    name: "",
    order: 0,
    catalogextracategoryrentalSet: [],
    createdAt: "",
  };

export const CatalogExtraCategoryRentalEmpty: CatalogExtraCategoryRentalType = {
  id: ID_EMPTY,
  name: "",
  order: 0,
  catalogExtraCategoryUpperRental: CatalogExtraCategoryUpperRentalEmpty,
  catalogextrarowrentalSet: [],
  createdAt: "",
};

export const CatalogExtraRowRentalEmpty: CatalogExtraRowRentalType = {
  id: ID_EMPTY,
  location: LocationEmpty,
  name: "",
  order: 0,
  productNumber: "",
  identifier: "",
  information: "",
  billingDaysWeekCompany: 0,
  billingDaysWeekPrivate: 0,
  priceDayCompany: "0.00",
  priceDayPrivate: "0.00",
  priceMonthCompany: "0.00",
  priceMonthPrivate: "0.00",
  showPrint: false,
  catalogExtraCategoryRental: CatalogExtraCategoryRentalEmpty,
  machineSet: [],
  reservationSet: [],
  machinebreakdownSet: [],
  maintenanceSet: [],
  createdAt: "",
};

export const CatalogExtraCategoryUnitPriceEmpty: CatalogExtraCategoryUnitPriceType =
  {
    id: ID_EMPTY,
    name: "",
    printSeparately: false,
    catalogextrarowunitpriceSet: [],
    createdAt: "",
  };

export const ProductCodeEmpty: ProductCodeType = {
  id: ID_EMPTY,
  code: "",
  description: "",
  createdAt: "",
  catalogextrarowinvoiceSet: [],
  catalogextrarowunitpriceSet: [],
};

export const CatalogExtraRowUnitPriceEmpty: CatalogExtraRowUnitPriceType = {
  id: ID_EMPTY,
  name: "",
  productCode: ProductCodeEmpty,
  unit: "",
  unitPrice: "0.00",
  minCharge: 0,
  allowDiscount: false,
  requireAnswer: false,
  showPrint: false,
  catalogExtraCategoryUnitPrice: CatalogExtraCategoryUnitPriceEmpty,
  machineSet: [],
  catalogextrarowinvoiceSet: [],
  createdAt: "",
};

export const EventEmpty: EventType = {
  id: ID_EMPTY,
  eventType: EventEventType.Call,
  date: "",
  information: "",
  customer: CustomerEmpty,
  createdAt: "",
  createdBy: UserEmpty,
};

export const DiscountListEmpty: DiscountListType = {
  id: ID_EMPTY,
  customer: CustomerEmpty,
  discountListSource: undefined,
  name: "",
  isBusiness: true,
  locked: null,
  isOffer: false,
  customersSelected: [],
  discountrowSet: [],
  eventofferdiscountlistSet: [],
  discountListsSourced: [],
  createdAt: "",
  createdBy: UserEmpty,
  catalog: CatalogEmpty,
};
export const DiscountListEmptyLocked: DiscountListType = {
  id: ID_EMPTY,
  customer: CustomerEmpty,
  discountListSource: undefined,
  name: "",
  isBusiness: true,
  locked: "2000-01-01 00:00:00",
  isOffer: false,
  customersSelected: [],
  discountrowSet: [],
  eventofferdiscountlistSet: [],
  discountListsSourced: [],
  createdAt: "",
  createdBy: UserEmpty,
  catalog: CatalogEmpty,
};

export const DiscountRowEmpty: DiscountRowType = {
  id: ID_EMPTY,
  percent: "0.00",
  showPrint: true,
  createdAt: "",
  discountList: DiscountListEmpty,
  catalogRow: CatalogRowEmpty,
};

export const OrderEmpty: OrderType = {
  id: ID_EMPTY,
  customer: CustomerEmpty,
  reference: undefined,
  orderSource: undefined,
  location: LocationEmpty,
  status: OrderStatus.Offer,
  information: "",
  informationInvoice: "",
  commissionSet: [],
  confirmationType: OrderConfirmationType.Email,
  advanceValidUntil: undefined,
  isBusinessIfNoCustomer: true,
  hasInsurance: false,
  updatePricesInCatalogSwitch: true,
  reservationSet: [],
  ordersSourced: [],
  transportSet: [],
  transportinvoicingSet: [],
  transportinvoicingsummarySet: [],
  invoicerowSet: [],
  createdAt: "",
  createdBy: UserEmpty,
};

export const ReservationAlbumEmpty: ReservationAlbumType = {
  id: ID_EMPTY,
  reservationimageSet: [],
  createdAt: "",
};

export const ReservationEmpty: ReservationType = {
  id: ID_EMPTY,
  order: OrderEmpty,
  machine: MachineEmpty,
  catalogRow: CatalogRowEmpty,
  albumGive: ReservationAlbumEmpty,
  albumReturn: ReservationAlbumEmpty,
  catalogExtraRowRental: undefined,
  reservationSource: undefined,
  dateRented: undefined,
  timeRented: undefined,
  dateReturned: undefined,
  timeReturned: undefined,
  dateBillingMin: undefined,
  information: "",
  deliveryMethod: ReservationDeliveryMethod.Customer,
  status: ReservationStatus.Open,
  bulkAmount: 1,
  bulkAmountReturned: 0,
  discountPercentDay: 0.0,
  discountPercentMonth: 0.0,
  estimatedMachineHoursPerDay: 0.0,
  giveCheckedAt: undefined,
  giveImagesAt: undefined,
  giveInstructedAt: undefined,
  giveAt: undefined,
  returnAt: undefined,
  returnImagesAt: undefined,
  returnCheckedCatalogAt: undefined,
  returnCheckedPreAt: undefined,
  returnCheckedAt: undefined,
  returnStoredAt: undefined,
  reservationcommentSet: [],
  invoicerowSet: [],
  catalogextrarowinvoiceSet: [],
  reservationsSourced: [],
  operationhourSet: [],
  transportSet: [],
  drivecontentSet: [],
  machinebulkbreakdownSet: [],
  inspectionreturnanswerSet: [],
  inspectiongiveanswerSet: [],
  reservationimagebasicSet: [],
  commissionreportrowSet: [],
  createdAt: "",
  createdBy: UserEmpty,
};

export const ReferenceEmpty: ReferenceType = {
  id: ID_EMPTY,
  customer: CustomerEmpty,
  name: "",
  orderSet: [],
  invoiceSet: [],
  transportinvoicingSet: [],
  transportinvoicingsummarySet: [],
  createdAt: "",
};

export const CatalogTransferMethodEmpty: CatalogTransferMethodType = {
  id: ID_EMPTY,
  name: "",
  catalogtransferpriceSet: [],
  transportSet: [],
  transportinvoicingSet: [],
  createdAt: "",
};

export const CatalogTransferPriceEmpty: CatalogTransferPriceType = {
  id: ID_EMPTY,
  catalogTransferMethod: CatalogTransferMethodEmpty,
  location: LocationEmpty,
  priceBasic: 0.0,
  priceExtra: 0.0,
  priceStop: 0.0,
  productNumberBasic: "",
  productNumberExtra: "",
  productNumberStop: "",
  createdAt: "",
};

export const OperationHourEmpty: OperationHourType = {
  id: ID_EMPTY,
  machine: MachineEmpty,
  reservation: ReservationEmpty,
  // @ts-ignore
  maintenance: undefined,
  invoiceRow: undefined,
  dateChecked: undefined,
  hours: 0,
  hoursExtra: 0,
  invoiceable: false,
  isBase: false,
  source: OperationHourSource.GiveCheck,
  createdAt: "",
};

export const CommissionTemplateEmpty: CommissionTemplateType = {
  id: ID_EMPTY,
  name: "",
  baseSalary: 0.0,
  commissionrangeSet: [],
  commissionusersettingSet: [],
  commissionlevelSet: [],
  createdAt: "",
};

export const CommissionRangeEmpty: CommissionRangeType = {
  id: ID_EMPTY,
  daysMax: 0,
  commissionTemplate: CommissionTemplateEmpty,
  commissionlevelSet: [],
  createdAt: "",
};

export const CommissionLevelEmpty: CommissionLevelType = {
  id: ID_EMPTY,
  maxCommission: 0,
  maxDiscount: 0,
  minCommission: 0,
  commissionTemplate: CommissionTemplateEmpty,
  commissionRange: CommissionRangeEmpty,
  createdAt: "",
};

export const CommissionUserSettingEmpty: CommissionUserSettingType = {
  id: ID_EMPTY,
  user: UserEmpty,
  commissionTemplate: CommissionTemplateEmpty,
  showCommissions: false,
  createdAt: "",
};

export const MachineBulkAmountEmpty: MachineBulkAmountType = {
  id: ID_EMPTY,
  machine: MachineEmpty,
  location: LocationEmpty,
  bulkAmount: 0,
  machinebulkbreakdownSet: [],
  createdAt: "",
};

export const MachineBulkBreakdownEmpty: MachineBulkBreakdownType = {
  id: ID_EMPTY,
  machineBulkAmount: MachineBulkAmountEmpty,
  machine: MachineEmpty,
  reservation: ReservationEmpty,
  amountBroken: 0,
  fixable: true,
  fixedAt: undefined,
  createdAt: "",
};

export const CatalogExtraRowInvoiceEmpty: CatalogExtraRowInvoiceType = {
  id: ID_EMPTY,
  catalogExtraRowUnitPrice: CatalogExtraRowUnitPriceEmpty,
  invoiceRow: undefined,
  reservation: ReservationEmpty,
  productCode: ProductCodeEmpty,
  titleOverride: "",
  quantity: 0,
  unitPriceOverride: 0.0,
  answer: "",
  createdAt: "",
};

export const InspectionReturnCategoryEmpty: InspectionReturnCategoryType = {
  id: ID_EMPTY,
  name: "",
  order: 0,
  inspectionreturnquestionSet: [],
  createdAt: "",
};

export const InspectionReturnQuestionEmpty: InspectionReturnQuestionType = {
  id: ID_EMPTY,
  question: "",
  answerType: InspectionReturnQuestionAnswerType.Text,
  order: 0,
  inspectionReturnCategory: InspectionReturnCategoryEmpty,
  inspectionreturntomachineSet: [],
  inspectionreturntagSet: [],
  inspectionreturnquestionoptionSet: [],
  createdAt: "",
};

export const InspectionReturnQuestionOptionEmpty: InspectionReturnQuestionOptionType =
  {
    id: ID_EMPTY,
    title: "",
    createdAt: "",
    inspectionReturnQuestion: InspectionReturnQuestionEmpty,
    inspectionreturnanswerSet: [],
  };

export const InspectionReturnTagEmpty: InspectionReturnTagType = {
  id: ID_EMPTY,
  name: "",
  inspectionReturnQuestions: [],
  createdAt: "",
};

export const InspectionGiveCategoryEmpty: InspectionGiveCategoryType = {
  id: ID_EMPTY,
  name: "",
  order: 0,
  inspectiongivequestionSet: [],
  createdAt: "",
};

export const InspectionGiveQuestionEmpty: InspectionGiveQuestionType = {
  id: ID_EMPTY,
  question: "",
  answerType: InspectionGiveQuestionAnswerType.Text,
  order: 0,
  inspectionGiveCategory: InspectionGiveCategoryEmpty,
  inspectiongivetomachineSet: [],
  inspectiongivetagSet: [],
  inspectiongivequestionoptionSet: [],
  createdAt: "",
};

export const InspectionGiveQuestionOptionEmpty: InspectionGiveQuestionOptionType =
  {
    id: ID_EMPTY,
    title: "",
    createdAt: "",
    inspectionGiveQuestion: InspectionGiveQuestionEmpty,
    inspectiongiveanswerSet: [],
  };

export const InspectionGiveTagEmpty: InspectionGiveTagType = {
  id: ID_EMPTY,
  name: "",
  inspectionGiveQuestions: [],
  createdAt: "",
};

export const TransportInvoicingEmpty: TransportInvoicingType = {
  id: ID_EMPTY,
  title: "",
  description: "",
  unitPrice: 0.0,
  quantity: 0.0,
  price: 0.0,
  discountPercent: 0.0,
  method: TransportInvoicingMethod.Send,
  rowType: TransportInvoicingRowType.Basic,
  order: OrderEmpty,
  catalogTransferMethod: CatalogTransferMethodEmpty,
  invoiceRow: undefined,
  reference: undefined,
  createdBy: UserEmpty,
  createdAt: "",
};

export const TransportEmpty: TransportType = {
  id: ID_EMPTY,
  method: TransportMethod.Send,
  catalogTransferMethod: CatalogTransferMethodEmpty,
  order: OrderEmpty,
  reservations: [],
  address: "",
  postcode: "",
  district: "",
  information: "",
  dateDeadline: "",
  timeDeadline: "",
  dateEndUsage: "",
  timeEndUsage: "",
  handledAt: "",
  createdAt: "",
  createdBy: UserEmpty,
  drivecontentSet: [],
};

export const InvoiceEmpty: InvoiceType = {
  id: ID_EMPTY,
  reference: ReferenceEmpty,
  status: InvoiceStatus.Open,
  sentAt: null,
  isAdvance: false,
  remoteId: 0,
  remoteInvoiceNo: "",
  information: "",
  invoicerowSet: [],
  createdBy: UserEmpty,
  createdAt: "",
};

export const MaintenanceSettingTypeEmpty: MaintenanceSettingType = {
  id: ID_EMPTY,
  machine: MachineEmpty,
  scheduleType: MaintenanceSettingScheduleType.None,
  estimatedHoursPerDay: "",
  createdAt: "",
  createdBy: UserEmpty,
  maintenanceintervalSet: [],
  startDate: "",
};

export const MaintenanceIntervalTypeEmpty: MaintenanceIntervalType = {
  id: ID_EMPTY,
  intervalType: MaintenanceIntervalIntervalType.Normal,
  hours: 0,
  days: 0,
  description: "",
  createdAt: "",
  createdBy: UserEmpty,
  maintenanceSetting: MaintenanceSettingTypeEmpty,
  maintenanceSet: [],
};

export const MaintenanceAlbumTypeEmpty: MaintenanceAlbumType = {
  id: ID_EMPTY,
  createdAt: "",
  maintenanceimageSet: [],
};

export const MaintenanceTypeEmpty: MaintenanceType = {
  id: ID_EMPTY,
  album: MaintenanceAlbumTypeEmpty,
  maintenanceType: MaintenanceMaintenanceType.Upcoming,
  machine: MachineEmpty,
  catalogExtraRowRental: CatalogExtraRowRentalEmpty,
  serviceTechnician: UserEmpty,
  estimatedDate: "",
  serviceStartBy: "",
  serviceEndBy: "",
  servicedAt: "",
  verifiedAt: "",
  createdAt: "",
  createdBy: UserEmpty,
  maintenanceInterval: MaintenanceIntervalTypeEmpty,
  maintenancecommentSet: [],
  operationhour: OperationHourEmpty,
};

export const MaintenanceCommentTypeEmpty: MaintenanceCommentType = {
  id: ID_EMPTY,
  maintenance: MaintenanceTypeEmpty,
  comment: "",
  createdAt: "",
  createdBy: UserEmpty,
};

export const MaintenanceImageTypeEmpty: MaintenanceImageType = {
  id: ID_EMPTY,
  album: MaintenanceAlbumTypeEmpty,
  image: "",
  imageName: "",
  imageType: MaintenanceImageImageType.Other,
  eurosWarranty: 0.0,
  eurosOwnCosts: 0.0,
  information: "",
  createdAt: "",
  createdBy: UserEmpty,
};

export const NextMaintenanceTypeEmpty: NextMaintenanceType = {
  days: 0,
  description: "",
  hours: 0,
  intervalType: MaintenanceIntervalIntervalType.Normal,
  nextMaintenanceDate: "",
};
