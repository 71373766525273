import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import { MutationGetOrderOfferPdfArgs } from "../../entity/types";
import { useMutation } from "@apollo/client";
import {
  GET_ORDER_OFFER_PDF_MUTATION,
  MutationResultOrderOfferPdf,
} from "../../apollo/mutations/orders";
import { handleError } from "../../entity/ErrorHandler";
import { popNewWindow } from "../../utils/dialogs";
import { SettingsContext } from "../../Root";
import ButtonLoad from "./ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  orderId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function DialogOrderOffer({ classes, orderId, open, setOpen }: Props) {
  const { t } = useTranslation();

  const settings = useContext(SettingsContext);

  const [offerText, setOfferText] = useState(settings.textOffer0);

  const [getOrderOfferPdf, { loading: loadingOrderOffer }] = useMutation<
    MutationResultOrderOfferPdf,
    MutationGetOrderOfferPdfArgs
  >(GET_ORDER_OFFER_PDF_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: (result) => {
      popNewWindow(t, String(result.getOrderOfferPdf?.url));
    },
  });

  const onClickCreateOrderOffer = () => {
    getOrderOfferPdf({
      variables: {
        orderId: orderId,
        offerText: offerText,
      },
    }).then(() => setOpen(false));
  };

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitleWithClose
        id="dialogTitleOrderOffer"
        onClose={() => setOpen(false)}
      >
        {t("create_order_offer_pdf")}
      </DialogTitleWithClose>
      <DialogContent>
        <div className={classes.dialog}>
          <FormControl fullWidth>
            <TextField
              label={t("pdf_text_offer_0")}
              onChange={(event) => setOfferText(event.target.value)}
              value={offerText}
              multiline
            />
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <ButtonLoad
          loading={loadingOrderOffer}
          variant="primary"
          onClick={onClickCreateOrderOffer}
        >
          {t("create_order_offer_pdf")}
        </ButtonLoad>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      width: "50vw",
      maxWidth: "100%",
    },
  });

export default withStyles(styles)(DialogOrderOffer);
