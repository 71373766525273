import { gql } from "@apollo/client";

export const CREATE_CATALOG_TRANSFER_METHOD_MUTATION = gql`
  mutation createCatalogTransferMethod($name: String!) {
    createCatalogTransferMethod(name: $name) {
      catalogTransferMethod {
        id
      }
    }
  }
`;

export const UPDATE_CATALOG_TRANSFER_METHOD_MUTATION = gql`
  mutation updateCatalogTransferMethod(
    $catalogTransferMethodId: ID!
    $name: String!
  ) {
    updateCatalogTransferMethod(
      catalogTransferMethodId: $catalogTransferMethodId
      name: $name
    ) {
      catalogTransferMethod {
        id
        name
      }
    }
  }
`;

export const CREATE_CATALOG_TRANSFER_PRICE_MUTATION = gql`
  mutation createCatalogTransferPrice(
    $catalogTransferMethodId: ID!
    $locationId: ID!
    $priceBasic: Decimal!
    $priceExtra: Decimal!
    $priceStop: Decimal!
    $productNumberBasic: String!
    $productNumberExtra: String!
    $productNumberStop: String!
  ) {
    createCatalogTransferPrice(
      catalogTransferMethodId: $catalogTransferMethodId
      locationId: $locationId
      priceBasic: $priceBasic
      priceExtra: $priceExtra
      priceStop: $priceStop
      productNumberBasic: $productNumberBasic
      productNumberExtra: $productNumberExtra
      productNumberStop: $productNumberStop
    ) {
      catalogTransferPrice {
        id
      }
    }
  }
`;

export const UPDATE_CATALOG_TRANSFER_PRICE_MUTATION = gql`
  mutation updateCatalogTransferPrice(
    $catalogTransferPriceId: ID!
    $catalogTransferMethodId: ID!
    $locationId: ID!
    $priceBasic: Decimal!
    $priceExtra: Decimal!
    $priceStop: Decimal!
    $productNumberBasic: String!
    $productNumberExtra: String!
    $productNumberStop: String!
  ) {
    updateCatalogTransferPrice(
      catalogTransferPriceId: $catalogTransferPriceId
      catalogTransferMethodId: $catalogTransferMethodId
      locationId: $locationId
      priceBasic: $priceBasic
      priceExtra: $priceExtra
      priceStop: $priceStop
      productNumberBasic: $productNumberBasic
      productNumberExtra: $productNumberExtra
      productNumberStop: $productNumberStop
    ) {
      catalogTransferPrice {
        id
        catalogTransferMethod {
          id
        }
        location {
          id
        }
        priceBasic
        priceExtra
        priceStop
        productNumberBasic
        productNumberExtra
        productNumberStop
      }
    }
  }
`;

export const DELETE_CATALOG_TRANSFER_PRICE_MUTATION = gql`
  mutation deleteCatalogTransferPrice($catalogTransferPriceId: ID!) {
    deleteCatalogTransferPrice(
      catalogTransferPriceId: $catalogTransferPriceId
    ) {
      catalogTransferPriceId
    }
  }
`;

export const DELETE_CATALOG_TRANSFER_METHOD_MUTATION = gql`
  mutation deleteCatalogTransferMethod($catalogTransferMethodId: ID!) {
    deleteCatalogTransferMethod(
      catalogTransferMethodId: $catalogTransferMethodId
    ) {
      catalogTransferMethodId
    }
  }
`;
