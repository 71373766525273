import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import LogList from "../components/Log/LogList";
import { GET_LOGS_QUERY, QueryResultLogs } from "../apollo/queries/logs";
import { createStyles, Theme } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { Container } from "react-bootstrap";
import { QueryLogsArgs } from "../entity/types";
import { useTranslation } from "react-i18next";
import { resetDateTime } from "../utils/dates";
import { format } from "date-fns";
import { DATE_FORMAT_ISO } from "../utils/constants";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {}

function SettingsLogs({ classes }: Props) {
  const { t } = useTranslation();

  const dateFromDefault = resetDateTime(new Date());
  const dateToDefault = resetDateTime(new Date());

  const [dateFrom, setDateFrom] = useState(dateFromDefault);
  const [dateTo, setDateTo] = useState(dateToDefault);
  const [logTypesShown, setLogTypesShown] = useState<string[]>([]);

  const { loading, error, data } = useQuery<QueryResultLogs, QueryLogsArgs>(
    GET_LOGS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("logs"),
      variables: {
        dateFrom: format(dateFrom, DATE_FORMAT_ISO),
        dateTo: format(dateTo, DATE_FORMAT_ISO),
      },
    }
  );

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <Container className="containerMain">
      <LogList
        logs={data.logs ? data.logs : []}
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
        logTypesShown={logTypesShown}
        setLogTypesShown={setLogTypesShown}
      />
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(SettingsLogs);
