import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { createStyles, Menu, MenuItem, Theme } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import DialogCatalogExtraCategoryRental from "./DialogCatalogExtraCategoryRental";
import DialogCatalogExtraRowRental from "./DialogCatalogExtraRowRental";
import {
  CatalogExtraCategoryRentalEmpty,
  CatalogExtraCategoryUpperRentalEmpty,
  CatalogExtraRowRentalEmpty,
} from "../../entity/empties";
import { CatalogExtraCategoryUpperRentalType } from "../../entity/types";
import DialogCatalogExtraCategoryUpperRental from "./DialogCatalogExtraCategoryUpperRental";

interface Props extends WithStyles<typeof styles> {
  catalogExtraCategoriesUpperRental: CatalogExtraCategoryUpperRentalType[];
}

function CreateCatalogExtraRentalMenu({
  classes,
  catalogExtraCategoriesUpperRental,
}: Props) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [
    openDialogCatalogExtraCategoryUpper,
    setOpenDialogCatalogExtraCategoryUpper,
  ] = useState(false);
  const [openDialogCatalogExtraCategory, setOpenDialogCatalogExtraCategory] =
    useState(false);
  const [openDialogCatalogExtraRow, setOpenDialogCatalogExtraRow] =
    useState(false);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddCatalogCategoryUpper = checkPermission(myPermissions, [
    "catalogs_extra.add_catalogextracategoryupperrental",
  ]);
  const hasPermissionAddCatalogCategory = checkPermission(myPermissions, [
    "catalogs_extra.add_catalogextracategoryrental",
  ]);
  const hasPermissionAddCatalogRow = checkPermission(myPermissions, [
    "catalogs_extra.add_catalogextrarowrental",
  ]);

  if (!hasPermissionAddCatalogCategory && !hasPermissionAddCatalogRow) {
    return <></>;
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className="addButton"
        variant="primary"
        size="lg"
        aria-controls="menuCatalogExtra"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {Boolean(anchorEl) ? <ClearIcon /> : <AddIcon />}
      </Button>
      <Menu
        id="menuCatalogExtra"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {hasPermissionAddCatalogCategoryUpper && (
          <MenuItem
            onClick={() => {
              setOpenDialogCatalogExtraCategoryUpper(true);
              handleCloseMenu();
            }}
          >
            {t("new_catalog_extra_category_upper")}
          </MenuItem>
        )}
        {hasPermissionAddCatalogCategory && (
          <MenuItem
            onClick={() => {
              setOpenDialogCatalogExtraCategory(true);
              handleCloseMenu();
            }}
          >
            {t("new_catalog_extra_category")}
          </MenuItem>
        )}
        {hasPermissionAddCatalogRow && (
          <MenuItem
            onClick={() => {
              setOpenDialogCatalogExtraRow(true);
              handleCloseMenu();
            }}
          >
            {t("new_catalog_extra_row")}
          </MenuItem>
        )}
      </Menu>
      <DialogCatalogExtraCategoryUpperRental
        open={openDialogCatalogExtraCategoryUpper}
        setOpen={setOpenDialogCatalogExtraCategoryUpper}
        catalogExtraCategoryUpperRental={CatalogExtraCategoryUpperRentalEmpty}
      />
      <DialogCatalogExtraCategoryRental
        open={openDialogCatalogExtraCategory}
        setOpen={setOpenDialogCatalogExtraCategory}
        catalogExtraCategoryRental={CatalogExtraCategoryRentalEmpty}
        catalogExtraCategoriesUpperRental={catalogExtraCategoriesUpperRental}
      />
      <DialogCatalogExtraRowRental
        open={openDialogCatalogExtraRow}
        setOpen={setOpenDialogCatalogExtraRow}
        catalogExtraRowRental={CatalogExtraRowRentalEmpty}
        catalogExtraCategoriesUpperRental={catalogExtraCategoriesUpperRental}
      />
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CreateCatalogExtraRentalMenu);
