import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { Select } from "@material-ui/core";
import { SelectProps } from "@material-ui/core/Select/Select";
import { ID_EMPTY } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode;
}

const SelectRequired = ({
  children,
  classes,
  ...other
}: Props & SelectProps) => {
  const hasError = other.value === "" || other.value === ID_EMPTY;

  const className = other.className;
  delete other.className;
  delete other.required;

  return (
    <Select
      required={true}
      className={`${className} ${hasError ? classes.error : ""}`}
      {...other}
    >
      {children}
    </Select>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    error: {
      backgroundColor: "#FCEBED",
    },
  });

export default withStyles(styles)(SelectRequired);
