import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import format from "date-fns/format";
import LoadingSimple from "../Shared/LoadingSimple";
import {
  GET_INVOICES_SENT_QUERY,
  QueryResultInvoicesSent,
} from "../../apollo/queries/invoices";
import { OrderType, QueryInvoicesSentArgs } from "../../entity/types";
import { DATE_FORMAT_ISO, ID_EMPTY } from "../../utils/constants";
import Error from "../Shared/Error";
import ManagementInvoice from "../Management/ManagementInvoice";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  order?: OrderType;
  dateFrom: Date;
  dateTo: Date;
}

function InvoicesSentList({ classes, order, dateFrom, dateTo }: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultInvoicesSent,
    QueryInvoicesSentArgs
  >(GET_INVOICES_SENT_QUERY, {
    fetchPolicy: getQueryFetchPolicy("invoicesSent"),
    variables: {
      orderId: order ? order.id : ID_EMPTY,
      dateFrom: format(dateFrom, DATE_FORMAT_ISO),
      dateTo: format(dateTo, DATE_FORMAT_ISO),
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <div>
      {data.invoicesSent &&
        data.invoicesSent.map((invoice) => (
          <ManagementInvoice
            key={invoice.id}
            invoice={invoice}
            readonly={true}
          />
        ))}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InvoicesSentList);
