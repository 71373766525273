import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultOperationHoursForMachine {
  operationHoursForMachine: Query["operationHoursForMachine"];
}
export const GET_OPERATION_HOURS_FOR_MACHINE_QUERY = gql`
  query getOperationHoursForMachineQuery($machineId: ID!, $reservationId: ID!) {
    operationHoursForMachine(
      machineId: $machineId
      reservationId: $reservationId
    ) {
      id
      dateChecked
      hours
      hoursExtra
      invoiceable
      isBase
      invoiceRow {
        id
      }
    }
  }
`;
