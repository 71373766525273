import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, FormControl, Theme } from "@material-ui/core";
import { Col, FormText, Row } from "react-bootstrap";
import { formatNumber, parseNumber } from "../../utils/formatting";
import TextFieldFocus from "../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {
  estimatedHoursPerDay: number;
  setStateVariable: Function;
  saveStateVariable: Function;
  disabled: Boolean;
}

const MaintenanceEstimatedHours = ({
  classes,
  estimatedHoursPerDay,
  setStateVariable,
  saveStateVariable,
  disabled,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col xs={"6"} md={"3"}>
        <div className={classes.textContainer}>
          <FormText className={classes.textContent}>
            {t("estimated_usage_per_day") + ": "}
          </FormText>
        </div>
      </Col>
      <Col xs={"3"} md={"2"}>
        <FormControl>
          <TextFieldFocus
            disabled={disabled ? true : false}
            type="number"
            value={formatNumber(estimatedHoursPerDay)}
            onChange={(event) => {
              setStateVariable(event.target.value);

              // Focus not set in some browsers when pressing up-down buttons in numeric input. Needed for blur!
              event.target.focus();
            }}
            onBlur={(event) => {
              saveStateVariable({
                estimatedHoursPerDay: parseNumber(event.target.value, 2),
              });
            }}
          />
        </FormControl>
      </Col>
      <Col xs={"3"} md={"6"}>
        <div className={classes.textContainer}>
          <FormText className={classes.textContent}>{t("hours")}</FormText>
        </div>
      </Col>
    </Row>
  );
};

const styles = ({ palette }: Theme) =>
  createStyles({
    info: {
      color: palette.secondary.main,
    },
    textContainer: {
      position: "relative",
      height: "1em",
      width: "9em",
    },
    textContent: {
      position: "absolute",
      top: "0.5em",
    },
  });

export default withStyles(styles)(MaintenanceEstimatedHours);
