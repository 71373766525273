import { ReservationType, TransportType } from "../../entity/types";
import { getMachineName } from "../machines/machine";
import { TFunction } from "react-i18next";

export function reservationCanBeDeleted(reservation: ReservationType): boolean {
  return (
    !reservation.giveCheckedAt &&
    !reservation.giveAt &&
    !reservation.returnAt &&
    !reservation.returnCheckedAt
  );
}

export function orderCanBeDeleted(reservations: ReservationType[]): boolean {
  let canBeDeleted = true;
  reservations.forEach((reservation) => {
    if (!reservationCanBeDeleted(reservation)) {
      canBeDeleted = false;
    }
  });
  return canBeDeleted;
}

export function getReservationProductName(
  t: TFunction<"translation">,
  reservation: ReservationType,
  showMachineIdentifier = false,
  showReturnedAmount = false
) {
  let id = "";
  let name = "";
  if (reservation.catalogExtraRowRental) {
    id = reservation.catalogExtraRowRental.identifier;
    name = reservation.catalogExtraRowRental.name;
  } else if (reservation.machine) {
    id = reservation.machine.identifier;
    name = getMachineName(reservation.machine);
    if (reservation.machine.bulkProduct) {
      name +=
        " " +
        t("x_pcs", {
          pcs: showReturnedAmount
            ? reservation.bulkAmountReturned
            : reservation.bulkAmount,
        });
    }
  }
  return (showMachineIdentifier && id !== "" ? id + " " : "") + name;
}

export function checkIfReservationHandled(
  transport: TransportType,
  reservation: ReservationType
) {
  return reservation.drivecontentSet.some(
    (driveContent) => driveContent.transport.id === transport.id
  );
}

export function hasReservationCatalogExtra(reservation: ReservationType) {
  return Boolean(reservation.catalogExtraRowRental);
}

export const getReturnCheckedAtColor = (reservation: ReservationType) => {
  if (reservation.returnCheckedAt) {
    return "primary";
  } else if (reservation.returnCheckedPreAt) {
    return "warning";
  }
  return "danger";
};
