import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import {
  InvoiceableRowType,
  QueryReferencesByIdsArgs,
} from "../../entity/types";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { parseNumber } from "../../utils/formatting";
import ManagementInvoicesOpenReference from "../Management/ManagementInvoicesOpenReference";
import { InvoiceableRowsWithReferenceType } from "./InvoicesCreateListReferences";
import { findFromSetById } from "../../utils/collections";
import { ReferenceEmpty } from "../../entity/empties";
import {
  GET_REFERENCES_BY_IDS_QUERY,
  QueryResultReferencesByIds,
} from "../../apollo/queries/references";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { SettingsContext } from "../../Root";

interface Props extends WithStyles<typeof styles> {
  invoiceableRows: InvoiceableRowType[];
  hideSurchargeInvoices: boolean;
  isAdvance?: Boolean;
  dateTo: Date;
}

function InvoiceableRows({
  classes,
  invoiceableRows,
  hideSurchargeInvoices,
  isAdvance,
  dateTo,
}: Props) {
  const settings = useContext(SettingsContext);

  const referenceIds: string[] = invoiceableRows.map(
    (invoiceableRow) => invoiceableRow.referenceId
  );

  const {
    loading: loadingReferences,
    error: errorReferences,
    data: dataReferences,
  } = useQuery<QueryResultReferencesByIds, QueryReferencesByIdsArgs>(
    GET_REFERENCES_BY_IDS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("referencesByIds"),
      skip: referenceIds.length === 0,
      variables: { referenceIds: referenceIds },
    }
  );

  if (loadingReferences) return <LoadingSimple />;
  if (errorReferences) return <Error error={errorReferences} />;

  let invoiceableRowsByReference: InvoiceableRowsWithReferenceType = {};
  invoiceableRows.forEach((invoiceableRow) => {
    if (invoiceableRowsByReference[invoiceableRow.referenceId] === undefined) {
      invoiceableRowsByReference[invoiceableRow.referenceId] = {
        reference: findFromSetById(
          invoiceableRow.referenceId,
          dataReferences?.referencesByIds ? dataReferences.referencesByIds : [],
          ReferenceEmpty
        ),
        invoiceableRows: [],
      };
    }
    invoiceableRowsByReference[invoiceableRow.referenceId].invoiceableRows.push(
      invoiceableRow
    );
  });

  return (
    <div>
      {Object.entries(invoiceableRowsByReference).map(([referenceId, row]) => {
        if (hideSurchargeInvoices) {
          const total = row.invoiceableRows.reduce(
            (sum, row) => sum + parseNumber(row.price),
            0
          );
          if (total < settings.billingSurchargeLimit) return null;
        }

        return (
          <ManagementInvoicesOpenReference
            key={referenceId}
            reference={row.reference}
            invoiceableRows={row.invoiceableRows}
            isAdvance={Boolean(isAdvance)}
            dateTo={dateTo}
          />
        );
      })}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InvoiceableRows);
