import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  MachineModelType,
  Mutation,
  MutationDeleteMachineModelArgs,
  MutationUpdateMachineModelArgs,
} from "../../entity/types";
import ButtonLoad from "../Shared/ButtonLoad";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import {
  DELETE_MACHINE_MODEL_MUTATION,
  UPDATE_MACHINE_MODEL_MUTATION,
} from "../../apollo/mutations/machines";
import { GET_MAKES_AND_MODELS_QUERY } from "../../apollo/queries/machines";
import { handleError } from "../../entity/ErrorHandler";
import { dialogConfirm } from "../../utils/dialogs";

interface Props extends WithStyles<typeof styles> {
  machineModel: MachineModelType;
}

function MachineModelFlex({ classes, machineModel }: Props) {
  const { t } = useTranslation();

  const [machineModelEdited, setMachineModelEdited] = useState(machineModel);

  const [deleteMachineModel, { loading: loadingDeleteModel }] = useMutation<
    Mutation,
    MutationDeleteMachineModelArgs
  >(DELETE_MACHINE_MODEL_MUTATION, {
    refetchQueries: [{ query: GET_MAKES_AND_MODELS_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const [updateMachineModel, { loading: loadingUpdateModel }] = useMutation<
    Mutation,
    MutationUpdateMachineModelArgs
  >(UPDATE_MACHINE_MODEL_MUTATION, {
    refetchQueries: [{ query: GET_MAKES_AND_MODELS_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditModel = checkPermission(myPermissions, [
    "machines.change_machinemodel",
  ]);
  const hasPermissionDeleteModel = checkPermission(myPermissions, [
    "machines.delete_machinemodel",
  ]);

  const onClickDeleteModel = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteMachineModel({ variables: { machineModelId: machineModel.id } });
    });
  };

  const handleUpdate = (values: { [key: string]: string | number }) => {
    const base: MutationUpdateMachineModelArgs = {
      machineModelId: machineModel.id,
      title: machineModel.title,
    };

    let changed = false;
    for (let key in values) {
      if (values[key] !== base[key as keyof MutationUpdateMachineModelArgs]) {
        changed = true;
      }
    }

    if (changed) {
      updateMachineModel({
        variables: { ...base, ...values },
      });
    }
  };

  return (
    <div className={`d-flex mb-3 ${loadingUpdateModel ? "loading" : ""}`}>
      <FormControl className="m-0" fullWidth>
        <TextField
          value={machineModelEdited.title}
          inputProps={{
            maxLength: 100,
          }}
          onChange={(event) => {
            setMachineModelEdited({
              ...machineModelEdited,
              title: event.target.value,
            });
          }}
          onBlur={() =>
            handleUpdate({
              title: machineModelEdited.title,
            })
          }
          disabled={!hasPermissionEditModel}
        />
      </FormControl>
      {hasPermissionDeleteModel && (
        <ButtonLoad
          loading={loadingDeleteModel}
          className="ms-2"
          onClick={() => onClickDeleteModel()}
          variant="light"
          size="sm"
        >
          <FontAwesomeIcon icon={faTrash} />
        </ButtonLoad>
      )}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MachineModelFlex);
