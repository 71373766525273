import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Menu, MenuItem, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import { getMachineName } from "../../utils/machines/machine";
import {
  MachineBreakdownType,
  MachineType,
  ReservationType,
} from "../../entity/types";
import { selectOrder } from "../../utils/calendar/calendar";
import { Button } from "react-bootstrap";
import { newDate } from "../../utils/dates";

interface Props extends WithStyles<typeof styles> {
  anchorElMenu: null | HTMLElement;
  setAnchorElMenu: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setOrderId: React.Dispatch<React.SetStateAction<string>>;
  reservationsMenu: ReservationType[];
  breakdownsMenu: MachineBreakdownType[];
  machinesMenu: MachineType[];
  setMachineBreakdown: React.Dispatch<
    React.SetStateAction<MachineBreakdownType>
  >;
  setOpenDialogMachineBreakdown: React.Dispatch<React.SetStateAction<boolean>>;
  setMachineSelected?: React.Dispatch<React.SetStateAction<MachineType | null>>;
  setReservationsMenu: React.Dispatch<React.SetStateAction<ReservationType[]>>;
  setBreakdownsMenu: React.Dispatch<
    React.SetStateAction<MachineBreakdownType[]>
  >;
  setMachinesMenu?: React.Dispatch<React.SetStateAction<MachineType[]>>;
  setDateSelectionEnd?: React.Dispatch<React.SetStateAction<Date | null>>;
  reservationsInOrder: ReservationType[];
}

function MenuCalendarCellOptions({
  classes,
  anchorElMenu,
  setAnchorElMenu,
  setOrderId,
  reservationsMenu,
  breakdownsMenu,
  machinesMenu,
  setMachineBreakdown,
  setOpenDialogMachineBreakdown,
  setMachineSelected = undefined,
  setReservationsMenu,
  setBreakdownsMenu,
  setMachinesMenu = undefined,
  setDateSelectionEnd = undefined,
  reservationsInOrder,
}: Props) {
  const { t } = useTranslation();

  const onClickMenuReservation = (reservation: ReservationType) => {
    selectOrder(reservation.order.id, setOrderId);
    setAnchorElMenu(null);
  };

  const onClickMenuBreakdown = (
    machineBreakdownClicked: MachineBreakdownType
  ) => {
    setMachineBreakdown(machineBreakdownClicked);
    setOpenDialogMachineBreakdown(true);
    setAnchorElMenu(null);
  };

  const onClickMenuMachine = (machine: MachineType) => {
    if (setMachineSelected) {
      setMachineSelected(machine);
      setAnchorElMenu(null);
    }
  };

  const onClickMenuMachineWithEndDate = (
    event: React.MouseEvent<HTMLButtonElement>,
    machine: MachineType,
    dateReturned: Date
  ) => {
    event.stopPropagation();

    if (setDateSelectionEnd) {
      setDateSelectionEnd(dateReturned);
    }

    if (setMachineSelected) {
      setMachineSelected(machine);
      setAnchorElMenu(null);
    }
  };

  const onClickMenuClose = () => {
    setAnchorElMenu(null);

    setReservationsMenu([]);
    setBreakdownsMenu([]);
    if (setMachinesMenu) {
      setMachinesMenu([]);
    }
  };

  const reservationEndDatesByMachineId: { [machineId: string]: Date } = {};
  reservationsInOrder.forEach((reservation) => {
    if (reservation.machine) {
      reservationEndDatesByMachineId[reservation.machine.id] = newDate(
        reservation.dateReturned
      );
    }
  });

  return (
    <Menu
      id="menuCalendarCatalogRowReservations"
      anchorEl={anchorElMenu}
      keepMounted
      open={Boolean(anchorElMenu)}
    >
      {reservationsMenu.map((reservation) => (
        <MenuItem
          key={reservation.id}
          onClick={() => onClickMenuReservation(reservation)}
        >
          <span className="text-muted me-2">
            {t("date_range", {
              start: format(newDate(reservation.dateRented), t("format_date")),
              end: format(newDate(reservation.dateReturned), t("format_date")),
            })}
          </span>
          {reservation.order.customer ? reservation.order.customer.name : ""}
        </MenuItem>
      ))}
      {breakdownsMenu.map((machineBreakdownLooped) => (
        <MenuItem
          key={machineBreakdownLooped.id}
          onClick={() => onClickMenuBreakdown(machineBreakdownLooped)}
        >
          <span className="text-muted me-2">
            {t("date_range", {
              start: format(
                newDate(machineBreakdownLooped.fixByStart),
                t("format_date")
              ),
              end: format(
                newDate(machineBreakdownLooped.fixByEnd),
                t("format_date")
              ),
            })}
          </span>
          {machineBreakdownLooped.title}
        </MenuItem>
      ))}
      {machinesMenu.length > 0 && (
        <MenuItem disabled={true}>
          <small>{t("connect_machine")}</small>
        </MenuItem>
      )}
      {machinesMenu.map((machine) => (
        <MenuItem key={machine.id} onClick={() => onClickMenuMachine(machine)}>
          {getMachineName(machine)}{" "}
          <small className="text-muted ms-2" title={machine.serial}>
            {machine.identifier}
          </small>
          {reservationEndDatesByMachineId[machine.id] !== undefined && (
            <Button
              variant="light"
              size="sm"
              title={t("included_in_order")}
              onClick={(event) =>
                onClickMenuMachineWithEndDate(
                  event,
                  machine,
                  reservationEndDatesByMachineId[machine.id]
                )
              }
            >
              {format(
                newDate(reservationEndDatesByMachineId[machine.id]),
                t("format_date")
              )}
            </Button>
          )}
        </MenuItem>
      ))}
      {machinesMenu.length > 0 && (
        <MenuItem onClick={onClickMenuClose}>{t("rented_separately")}</MenuItem>
      )}
      {machinesMenu.length === 0 && (
        <MenuItem onClick={onClickMenuClose}>
          <small>{t("cancel")}</small>
        </MenuItem>
      )}
    </Menu>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MenuCalendarCellOptions);
