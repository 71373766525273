import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { MachineInfoQuestionAnswerType, MachineType } from "../../entity/types";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
}

export const countMachineInformationTotal = (machine: MachineType) => {
  return machine.machineinfoanswerSet.length;
};

export const countMachineInformationAnswered = (machine: MachineType) => {
  let count = 0;
  machine.machineinfoanswerSet.forEach((machineInfoAnswer) => {
    if (
      machineInfoAnswer.machineInfoQuestion.answerType ===
      MachineInfoQuestionAnswerType.Text
    ) {
      if (machineInfoAnswer.answer !== "") {
        count++;
      }
    } else if (
      machineInfoAnswer.machineInfoQuestion.answerType ===
      MachineInfoQuestionAnswerType.Options
    ) {
      if (machineInfoAnswer.machineInfoQuestionOption) {
        count++;
      }
    }
  });
  return count;
};

const MachineInformationAnswered = ({ classes, machine }: Props) => {
  const { t } = useTranslation();

  let total = countMachineInformationTotal(machine);
  let count = countMachineInformationAnswered(machine);

  return (
    <div className={classes.con}>
      <div
        className={`${classes.inner} ${
          count < total ? classes.innerBad : classes.innerGood
        }`}
      >
        <div>
          {t("x_of_x_slim", {
            count: count,
            total: total,
          })}
        </div>
      </div>
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    con: {
      display: "inline-block",
    },
    inner: {
      height: "40px",
      minWidth: "40px",
      borderRadius: "20px",
      fontSize: "0.9rem",
      color: "#000000",
      padding: "0 8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    innerBad: {
      backgroundColor: "#F2B9B9",
    },
    innerGood: {
      backgroundColor: "#C0E3C1",
    },
  });

export default withStyles(styles)(MachineInformationAnswered);
