import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  CustomerType,
  DiscountListType,
  EventOfferCatalogType,
  EventOfferDiscountListType,
  EventType,
  OrderType,
  ReferenceType,
} from "../../entity/types";
import MaterialTable from "material-table";
import {
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  tableIcons,
} from "../../utils/TableProps";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import { useHistory } from "react-router-dom";
import { getUrlOffer } from "../../utils/urls";
import { ID_EMPTY } from "../../utils/constants";

type EventListEventType = {
  object:
    | EventType
    | DiscountListType
    | EventOfferCatalogType
    | EventOfferDiscountListType
    | ReferenceType
    | OrderType;
  date: string;
  createdBy: string;
  typeName: string;
  information: string;
  createdAt: string;
};

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  events: EventType[];
  eventsOfferCatalog: EventOfferCatalogType[];
  eventsOfferDiscountList: EventOfferDiscountListType[];
  discountLists: DiscountListType[];
  references: ReferenceType[];
  orders: OrderType[];
}

const CustomerShowEventsTable = ({
  classes,
  customer,
  events,
  eventsOfferCatalog,
  eventsOfferDiscountList,
  discountLists,
  references,
  orders,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const data: EventListEventType[] = [];

  events.forEach((event) => {
    data.push({
      object: event,
      date: event.date,
      createdBy: event.createdBy
        ? event.createdBy.lastName + " " + event.createdBy.firstName
        : "",
      typeName: t("event_type_" + event.eventType),
      information: event.information,
      createdAt: event.createdAt,
    });
  });

  eventsOfferCatalog.forEach((eventOfferCatalog) => {
    data.push({
      object: eventOfferCatalog,
      date: eventOfferCatalog.createdAt,
      createdBy: eventOfferCatalog.createdBy
        ? eventOfferCatalog.createdBy.lastName +
          " " +
          eventOfferCatalog.createdBy.firstName
        : "",
      typeName: t("offer"),
      information: eventOfferCatalog.catalog.name,
      createdAt: eventOfferCatalog.createdAt,
    });
  });

  eventsOfferDiscountList.forEach((eventOfferDiscountList) => {
    data.push({
      object: eventOfferDiscountList,
      date: eventOfferDiscountList.createdAt,
      createdBy: eventOfferDiscountList.createdBy
        ? eventOfferDiscountList.createdBy.lastName +
          " " +
          eventOfferDiscountList.createdBy.firstName
        : "",
      typeName: t("offer"),
      information: eventOfferDiscountList.discountList.name,
      createdAt: eventOfferDiscountList.createdAt,
    });
  });

  discountLists.forEach((discountList) => {
    data.push({
      object: discountList,
      date: discountList.createdAt,
      createdBy: discountList.createdBy
        ? discountList.createdBy.lastName +
          " " +
          discountList.createdBy.firstName
        : "",
      typeName: t("offer"),
      information: discountList.name,
      createdAt: discountList.createdAt,
    });
  });

  references.forEach((reference) => {
    data.push({
      object: reference,
      date: reference.createdAt,
      createdBy: "",
      typeName: t("reference"),
      information: reference.name,
      createdAt: reference.createdAt,
    });
  });

  orders.forEach((order) => {
    data.push({
      object: order,
      date: order.createdAt,
      createdBy: order.createdBy
        ? order.createdBy.lastName + " " + order.createdBy.firstName
        : "",
      typeName: t("order"),
      information: "#" + order.id + " " + order.information,
      createdAt: order.createdAt,
    });
  });

  const getUrlOfferFromObject = (rowData: any) => {
    let url = "";

    if (rowData.object.__typename === "DiscountListType") {
      let discountList: DiscountListType = rowData.object;
      url = getUrlOffer(customer.id, discountList.id, ID_EMPTY);
    } else if (rowData.object.__typename === "EventOfferCatalogType") {
      let eventOfferCatalog: EventOfferCatalogType = rowData.object;
      url = getUrlOffer(customer.id, ID_EMPTY, eventOfferCatalog.catalog.id);
    } else if (rowData.object.__typename === "EventOfferDiscountListType") {
      let eventOfferDiscountList: EventOfferDiscountListType = rowData.object;
      url = getUrlOffer(
        customer.id,
        eventOfferDiscountList.discountList.id,
        ID_EMPTY
      );
    }

    return url;
  };

  return (
    <MaterialTable
      style={getTableStyle()}
      title={""}
      icons={tableIcons}
      localization={GetTableLocalization(t)}
      columns={[
        { title: t("date"), field: "date", type: "date", defaultSort: "desc" },
        { title: t("type"), field: "typeName" },
        { title: t("user"), field: "createdBy" },
        { title: t("information"), field: "information" },
        {
          title: t("created_at"),
          field: "createdAt",
          type: "datetime",
        },
      ]}
      data={data}
      actions={[
        (rowData: any) => ({
          icon: () => <FontAwesomeIcon icon={faFile} />,
          tooltip: t("offer"),
          onClick: (event, rowData: any) =>
            history.push(getUrlOfferFromObject(rowData)),
          hidden: ![
            "DiscountListType",
            "EventOfferCatalogType",
            "EventOfferDiscountListType",
          ].includes(rowData.object.__typename),
        }),
      ]}
      options={getTableOptions()}
    />
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CustomerShowEventsTable);
