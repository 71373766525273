import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import {
  CatalogExtraCategoryUnitPriceType,
  CatalogExtraRowUnitPriceType,
  Mutation,
  MutationCreateCatalogExtraRowUnitPriceArgs,
  MutationUpdateCatalogExtraRowUnitPriceArgs,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { CatalogExtraRowUnitPriceEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import { GET_CATALOG_EXTRA_UNIT_PRICE_QUERY } from "../../apollo/queries/catalogs_extra";
import {
  CREATE_CATALOG_EXTRA_ROW_UNIT_PRICE_MUTATION,
  UPDATE_CATALOG_EXTRA_ROW_UNIT_PRICE_MUTATION,
} from "../../apollo/mutations/catalogs_extra";
import DialogContentCatalogExtraRowUnitPrice from "./DialogContentCatalogExtraRowUnitPrice";
import { ID_EMPTY } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalogExtraRowUnitPrice: CatalogExtraRowUnitPriceType;
  setCatalogExtraRowUnitPrice: React.Dispatch<
    React.SetStateAction<CatalogExtraRowUnitPriceType>
  >;
  catalogExtraCategoriesUnitPrice: CatalogExtraCategoryUnitPriceType[];
}

function DialogCatalogExtraRowUnitPrice({
  classes,
  open,
  setOpen,
  catalogExtraRowUnitPrice,
  setCatalogExtraRowUnitPrice,
  catalogExtraCategoriesUnitPrice,
}: Props) {
  const { t } = useTranslation();

  const [createCatalogExtraRowUnitPrice] = useMutation<
    Mutation,
    MutationCreateCatalogExtraRowUnitPriceArgs
  >(CREATE_CATALOG_EXTRA_ROW_UNIT_PRICE_MUTATION, {
    refetchQueries: [{ query: GET_CATALOG_EXTRA_UNIT_PRICE_QUERY }],
    onCompleted: (result) => {
      setCatalogExtraRowUnitPrice(CatalogExtraRowUnitPriceEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });
  const [updateCatalogExtraRowUnitPrice] = useMutation<
    Mutation,
    MutationUpdateCatalogExtraRowUnitPriceArgs
  >(UPDATE_CATALOG_EXTRA_ROW_UNIT_PRICE_MUTATION, {
    refetchQueries: [{ query: GET_CATALOG_EXTRA_UNIT_PRICE_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSubmitCatalogExtra = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (catalogExtraRowUnitPrice.id !== ID_EMPTY) {
      updateCatalogExtraRowUnitPrice({
        variables: {
          catalogExtraRowUnitPriceId: catalogExtraRowUnitPrice.id,
          catalogExtraCategoryUnitPriceId:
            catalogExtraRowUnitPrice.catalogExtraCategoryUnitPrice.id,
          name: catalogExtraRowUnitPrice.name,
          productCodeId: catalogExtraRowUnitPrice.productCode.id,
          unit: catalogExtraRowUnitPrice.unit,
          unitPrice: catalogExtraRowUnitPrice.unitPrice,
          allowDiscount: catalogExtraRowUnitPrice.allowDiscount,
          requireAnswer: catalogExtraRowUnitPrice.requireAnswer,
          minCharge: catalogExtraRowUnitPrice.minCharge,
          showPrint: catalogExtraRowUnitPrice.showPrint,
        },
      });
    } else {
      createCatalogExtraRowUnitPrice({
        variables: {
          catalogExtraCategoryUnitPriceId:
            catalogExtraRowUnitPrice.catalogExtraCategoryUnitPrice.id,
          name: catalogExtraRowUnitPrice.name,
          productCodeId: catalogExtraRowUnitPrice.productCode.id,
          unit: catalogExtraRowUnitPrice.unit,
          unitPrice: catalogExtraRowUnitPrice.unitPrice,
          allowDiscount: catalogExtraRowUnitPrice.allowDiscount,
          requireAnswer: catalogExtraRowUnitPrice.requireAnswer,
          minCharge: catalogExtraRowUnitPrice.minCharge,
          showPrint: catalogExtraRowUnitPrice.showPrint,
        },
      });
    }
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <form onSubmit={(event) => handleSubmitCatalogExtra(event)}>
        <DialogTitle>
          {t(
            catalogExtraRowUnitPrice.id
              ? "edit_catalog_extra_row"
              : "new_catalog_extra_row"
          )}
        </DialogTitle>
        <DialogContentCatalogExtraRowUnitPrice
          catalogExtraRowUnitPrice={catalogExtraRowUnitPrice}
          setCatalogExtraRowUnitPrice={setCatalogExtraRowUnitPrice}
          catalogExtraCategoriesUnitPrice={catalogExtraCategoriesUnitPrice}
        />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <Button
            disabled={
              !catalogExtraRowUnitPrice.name.trim() ||
              !catalogExtraRowUnitPrice.catalogExtraCategoryUnitPrice.id
            }
            type="submit"
            variant="primary"
          >
            {t("save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCatalogExtraRowUnitPrice);
