import React, { ReactElement, useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  Mutation,
  MutationUpdateReservationStampsArgs,
  ReservationStatus,
  ReservationType,
  TransportType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faHouseLeave,
  faHouseReturn,
  faInfo,
  faTasks,
  faWarehouse,
  faWarehouseAlt,
} from "@fortawesome/pro-light-svg-icons";
import { Button } from "react-bootstrap";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import { UPDATE_RESERVATION_STAMPS_MUTATION } from "../../apollo/mutations/reservations";
import { handleError } from "../../entity/ErrorHandler";
import DialogReservationWorkQueueImages, {
  ImageFieldType,
} from "./DialogReservationWorkQueueImages";
import DialogReservationReturnCheck from "./DialogReservationReturnCheck";
import { updateCacheReservationStampsMutation } from "../../utils/cache";
import { DriveListType } from "./WorkQueueCardDrive";
import { getReturnCheckedAtColor } from "../../utils/reservations/reservation";
import DialogReservationGiveCheck from "./DialogReservationGiveCheck";
import DialogReservationReturn from "./DialogReservationReturn";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationType;
  type: DriveListType;
  transport?: TransportType | null;
}

function WorkQueueCardButtonsRenting({
  classes,
  reservation,
  type,
  transport,
}: Props) {
  const { t } = useTranslation();

  const [fieldImage, setFieldImage] = useState<ImageFieldType>("");
  const [openDialogImages, setOpenDialogImages] = useState(false);
  const [openDialogGiveCheck, setOpenDialogGiveCheck] = useState(false);
  const [openDialogReturnCheck, setOpenDialogReturnCheck] = useState(false);
  const [openDialogReturnAt, setOpenDialogReturnAt] = useState(false);

  const [updateReservationStamps, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateReservationStampsArgs
  >(UPDATE_RESERVATION_STAMPS_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheReservationStampsMutation(cache);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditReservation = checkPermission(myPermissions, [
    "reservations.change_reservation",
  ]);

  let buttons: ReactElement[] = [];

  if (reservation.machine && reservation.machine.hourTracking === undefined) {
    handleError(t("machine_hour_tracking_info_missing"));
  }

  const handleUpdate = (
    values: {
      [key: string]: Date | null;
    } = {}
  ) => {
    let base: MutationUpdateReservationStampsArgs = {
      reservationId: reservation.id,
      userGiveId: reservation.userGive ? reservation.userGive.id : undefined,
      userReturnId: reservation.userReturn
        ? reservation.userReturn.id
        : undefined,
      giveCheckedAt: reservation.giveCheckedAt,
      giveImagesAt: reservation.giveImagesAt,
      giveInstructedAt: reservation.giveInstructedAt,
      giveAt: reservation.giveAt,
      returnAt: reservation.returnAt,
      returnImagesAt: reservation.returnImagesAt,
      returnCheckedCatalogAt: reservation.returnCheckedCatalogAt,
      returnCheckedPreAt: reservation.returnCheckedPreAt,
      returnCheckedAt: reservation.returnCheckedAt,
      returnStoredAt: reservation.returnStoredAt,
    };

    updateReservationStamps({
      variables: { ...base, ...values },
    });
  };

  const disabled = !hasPermissionEditReservation;
  const isInvoiced = reservation.status === ReservationStatus.Invoiced;

  const onClickGiveCheckedAt = () => {
    if (reservation.giveCheckedAt) {
      handleUpdate({
        giveCheckedAt: null,
      });
    } else {
      setOpenDialogGiveCheck(!openDialogGiveCheck);
    }
  };

  const onClickGiveImagesAt = () => {
    if (reservation.giveImagesAt) {
      handleUpdate({
        giveImagesAt: null,
      });
    } else {
      setOpenDialogImages(!openDialogImages);
      setFieldImage("giveImagesAt");
    }
  };

  const onClickReturnImagesAt = () => {
    if (reservation.returnImagesAt) {
      handleUpdate({
        returnImagesAt: null,
      });
    } else {
      setOpenDialogImages(!openDialogImages);
      setFieldImage("returnImagesAt");
    }
  };

  const onClickReturnCheck = () => {
    if (reservation.returnCheckedAt) {
      handleUpdate({
        returnCheckedAt: null,
      });
    } else {
      setOpenDialogReturnCheck(!openDialogReturnCheck);
    }
  };

  /* 1. Customer collects */
  if (type === "give") {
    buttons.push(
      <Button
        key="giveCheckedAt"
        className={classes.btn}
        variant={reservation.giveCheckedAt ? "primary" : "danger"}
        title={t("toggle_give_checked_at")}
        onClick={onClickGiveCheckedAt}
        disabled={disabled || (reservation.giveCheckedAt && isInvoiced)}
      >
        <FontAwesomeIcon icon={faWarehouseAlt} />
      </Button>
    );
    buttons.push(
      <Button
        key="giveImagesAt"
        className={classes.btn}
        variant={reservation.giveImagesAt ? "primary" : "danger"}
        title={t("toggle_give_images_at")}
        onClick={onClickGiveImagesAt}
        disabled={disabled || (reservation.giveImagesAt && isInvoiced)}
      >
        <FontAwesomeIcon icon={faCamera} />
      </Button>
    );
    buttons.push(
      <Button
        key="giveInstructedAt"
        className={classes.btn}
        variant={reservation.giveInstructedAt ? "primary" : "danger"}
        title={t("toggle_give_instructed_at")}
        onClick={() =>
          handleUpdate({
            giveInstructedAt: reservation.giveInstructedAt ? null : new Date(),
          })
        }
        disabled={disabled || (reservation.giveInstructedAt && isInvoiced)}
      >
        <FontAwesomeIcon icon={faInfo} />
      </Button>
    );
    buttons.push(
      <Button
        key="giveAt"
        className={classes.btn}
        variant={reservation.giveAt ? "primary" : "danger"}
        title={t("toggle_give_at")}
        onClick={() =>
          handleUpdate({
            giveAt: reservation.giveAt ? null : new Date(),
          })
        }
        disabled={
          disabled ||
          !reservation.giveCheckedAt ||
          !reservation.giveImagesAt ||
          !reservation.giveInstructedAt ||
          (reservation.giveAt && isInvoiced)
        }
      >
        <FontAwesomeIcon icon={faHouseLeave} />
      </Button>
    );
  } else {
    /* 2. Customer returns */

    buttons.push(
      <Button
        key="returnAt"
        className={classes.btn}
        variant={reservation.returnAt ? "primary" : "danger"}
        title={t("toggle_return_at")}
        onClick={() =>
          transport
            ? setOpenDialogReturnAt(true)
            : handleUpdate({
                returnAt: reservation.returnAt ? null : new Date(),
              })
        }
        disabled={
          disabled ||
          reservation.returnImagesAt ||
          (reservation.returnAt && isInvoiced)
        }
      >
        <FontAwesomeIcon icon={faHouseReturn} />
      </Button>
    );
    buttons.push(
      <Button
        key="returnImagesAt"
        className={classes.btn}
        variant={reservation.returnImagesAt ? "primary" : "danger"}
        title={t("toggle_return_images_at")}
        onClick={onClickReturnImagesAt}
        disabled={disabled || (reservation.returnImagesAt && isInvoiced)}
      >
        <FontAwesomeIcon icon={faCamera} />
      </Button>
    );
    buttons.push(
      <Button
        key="returnCheckedAt"
        className={classes.btn}
        variant={getReturnCheckedAtColor(reservation)}
        title={t("toggle_return_checked_at")}
        onClick={onClickReturnCheck}
        disabled={disabled || (reservation.returnCheckedAt && isInvoiced)}
      >
        <FontAwesomeIcon icon={faTasks} />
      </Button>
    );
    buttons.push(
      <Button
        key="returnStoredAt"
        className={classes.btn}
        variant={reservation.returnStoredAt ? "primary" : "danger"}
        title={t("toggle_return_stored_at")}
        onClick={() =>
          handleUpdate({
            returnStoredAt: reservation.returnStoredAt ? null : new Date(),
          })
        }
        disabled={
          disabled ||
          !reservation.returnAt ||
          !reservation.returnImagesAt ||
          !reservation.returnCheckedPreAt ||
          (reservation.returnStoredAt && isInvoiced)
        }
      >
        <FontAwesomeIcon icon={faWarehouse} />
      </Button>
    );
  }

  return (
    <div className={loadingUpdate ? "loading" : ""}>
      {buttons}
      {openDialogImages && (
        <DialogReservationWorkQueueImages
          open={openDialogImages}
          setOpen={setOpenDialogImages}
          reservation={reservation}
          field={fieldImage}
        />
      )}
      {openDialogGiveCheck && (
        <DialogReservationGiveCheck
          open={openDialogGiveCheck}
          setOpen={setOpenDialogGiveCheck}
          reservation={reservation}
        />
      )}
      {openDialogReturnCheck && (
        <DialogReservationReturnCheck
          open={openDialogReturnCheck}
          setOpen={setOpenDialogReturnCheck}
          reservation={reservation}
        />
      )}
      {openDialogReturnAt && transport && (
        <DialogReservationReturn
          open={openDialogReturnAt}
          setOpen={setOpenDialogReturnAt}
          locationIdSelected={reservation.order.location.id}
          reservation={reservation}
          defaultReturnAt={transport?.dateEndUsage}
          defaultReturnAtTime={transport?.timeEndUsage}
        />
      )}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    btn: {
      minWidth: "2.8rem",
      [theme.breakpoints.down("sm")]: {
        minWidth: "2rem",
        width: "2.5rem",
        paddingLeft: "0.6rem",
        paddingRight: "0.6rem",
      },
    },
  });

export default withStyles(styles)(WorkQueueCardButtonsRenting);
