import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  IdentificationRequestType,
  Mutation,
  MutationDeleteIdentificationRequestArgs,
  MutationUpdateIdentificationRequestArgs,
  MutationCompleteIdentificationRequestArgs
} from "../../entity/types";
import DialogContentIdentificationRequest from "./DialogContentIdentificationRequest";
import { Button } from "react-bootstrap";
import { dialogConfirm } from "../../utils/dialogs";
import { useMutation } from "@apollo/client";
import {
  DELETE_IDENTIFICATION_REQUEST_MUTATION,
  UPDATE_IDENTIFICATIONREQUEST
} from "../../apollo/mutations/identification_requests";

import { 
  GET_IDENTIFICATION_REQUESTS_BY_STATE
} from "../../apollo/queries/identification_requests";

import { SimpleIdentificationRequestEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  identificationRequest: IdentificationRequestType;
  setIdentificationRequest: React.Dispatch<React.SetStateAction<IdentificationRequestType>>;
}

function DialogIdentificationRequest({
  classes,
  open,
  setOpen,
  identificationRequest,
  setIdentificationRequest,
}: Props) {
  const { t } = useTranslation();

  const [updateIdentificationRequest] = useMutation<Mutation, MutationUpdateIdentificationRequestArgs>(
    UPDATE_IDENTIFICATIONREQUEST,
    {
      onCompleted: (result) => {
        setIdentificationRequest(SimpleIdentificationRequestEmpty);

      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const [deleteIdentificationRequest, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteIdentificationRequestArgs
  >(DELETE_IDENTIFICATION_REQUEST_MUTATION, {
    onCompleted: (result) => {
      setIdentificationRequest(SimpleIdentificationRequestEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("identificationRequests"),
      });
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateIdentificationRequest({
      variables: {
        id: identificationRequest.id
      },
    }).then(() => {
      setOpen(false);
    });
  };

  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteIdentificationRequest(
        {
          variables: { id: identificationRequest.id },
          refetchQueries: [{
            query: GET_IDENTIFICATION_REQUESTS_BY_STATE, 
            variables: { state: "new" }}]
         }).then(() => {
        setOpen(false);
      });
    });
  };

  return (
    <Dialog open={open}>
      <form onSubmit={(event) => handleSubmit(event)}>
        <DialogTitle>{t("edit_identificationRequest")}</DialogTitle>
        <DialogContentIdentificationRequest identificationRequest={identificationRequest} setIdentificationRequest={setIdentificationRequest} />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <ButtonLoad
            loading={loadingDelete}
            onClick={(event) => handleDelete(event)}
            variant="light"
          >
            {t("delete")}
          </ButtonLoad>
          <Button type="submit" variant="primary">
            {t("save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogIdentificationRequest);
