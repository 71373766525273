import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import {
  CatalogExtraRowRentalType,
  MaintenanceIntervalIntervalType,
  MaintenanceSettingScheduleType,
  MaintenanceType,
  QueryMaintenanceHistoryForCatalogExtraRowRentalArgs,
} from "../../entity/types";
import { useQuery } from "@apollo/client";
import Error from "../Shared/Error";
import {
  MaintenanceSettingTypeEmpty,
  NextMaintenanceTypeEmpty,
} from "../../entity/empties";
import { getCatalogExtraRowRentalName } from "../../utils/machines/machine";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import format from "date-fns/format";
import { formMaintenanceTimeRange } from "../../utils/maintenances/maintenance";
import { newDate } from "../../utils/dates";
import {
  GET_MAINTENANCE_HISTORY_FOR_CATALOG_EXTRA_ROW_RENTAL_QUERY,
  QueryResultMaintenanceHistoryForCatalogExtraRowRental,
} from "../../apollo/queries/catalogs_extra";
import LoadingDialog from "../Shared/LoadingDialog";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalogExtraRowRental: CatalogExtraRowRentalType;
}

function DialogCatalogExtraRowRentalInfo({
  classes,
  open,
  setOpen,
  catalogExtraRowRental,
}: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultMaintenanceHistoryForCatalogExtraRowRental,
    QueryMaintenanceHistoryForCatalogExtraRowRentalArgs
  >(GET_MAINTENANCE_HISTORY_FOR_CATALOG_EXTRA_ROW_RENTAL_QUERY, {
    fetchPolicy: getQueryFetchPolicy("machine"),
    variables: {
      catalogExtraRowRentalId: catalogExtraRowRental.id,
    },
  });

  if (error) return <Error error={error} />;
  if (loading) return <LoadingDialog />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const onClickClose = () => {
    setOpen(false);
  };

  const getMaintenanceHistoryInfo = (maintenance: MaintenanceType) => {
    return t("catalog_extra_row_maintenance_history_row", {
      hoursOrDays:
        catalogExtraRowRental.maintenancesetting?.scheduleType &&
        catalogExtraRowRental.maintenancesetting.scheduleType ===
          MaintenanceSettingScheduleType.Days
          ? maintenance.maintenanceInterval.days
          : maintenance.maintenanceInterval.hours,
      timeRange: formMaintenanceTimeRange(
        t,
        maintenance.maintenanceInterval.intervalType,
        catalogExtraRowRental.maintenancesetting?.scheduleType
          ? catalogExtraRowRental.maintenancesetting.scheduleType
          : MaintenanceSettingScheduleType.None
      ),
      date: format(newDate(maintenance.servicedAt), t("format_date")),
      serviceTechnician:
        (maintenance.serviceTechnician?.firstName
          ? maintenance.serviceTechnician.firstName + " "
          : "") +
        (maintenance.serviceTechnician?.lastName
          ? maintenance.serviceTechnician.lastName
          : ""),
    });
  };

  const printNextMaintenance = () => {
    const nextMaintenance = data.maintenanceNextForCatalogExtraRowRental
      ? data.maintenanceNextForCatalogExtraRowRental
      : NextMaintenanceTypeEmpty;
    const settings = catalogExtraRowRental.maintenancesetting
      ? catalogExtraRowRental.maintenancesetting
      : MaintenanceSettingTypeEmpty;

    return t("next_maintenance_estimate", {
      hoursOrDays:
        settings.scheduleType === MaintenanceSettingScheduleType.Hours
          ? nextMaintenance.hours
          : nextMaintenance.days,
      timeRange: formMaintenanceTimeRange(
        t,
        nextMaintenance.intervalType as MaintenanceIntervalIntervalType,
        settings.scheduleType
          ? settings.scheduleType
          : MaintenanceSettingScheduleType.None
      ),
      estimateDate: format(
        newDate(nextMaintenance.nextMaintenanceDate),
        t("format_date")
      ),
    });
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitleWithClose
        id="dialogTitleCatalogExtraRowRentalInfo"
        onClose={onClickClose}
      >
        {t("catalog_extra_row_information")}{" "}
        <small className="text-muted ms-3">
          {getCatalogExtraRowRentalName(catalogExtraRowRental)},{" "}
          {catalogExtraRowRental.identifier}
        </small>
      </DialogTitleWithClose>
      <DialogContent className={loading ? "loading" : ""}>
        {catalogExtraRowRental.maintenancesetting?.scheduleType &&
          catalogExtraRowRental.maintenancesetting.scheduleType !==
            MaintenanceSettingScheduleType.None && (
            <>
              <h5>{t("scheduled_maintenances")}</h5>
              {data.maintenanceNextForCatalogExtraRowRental && (
                <p className="mb-2">{printNextMaintenance()}</p>
              )}
              <p className="mb-2">{t("maintenance_history")}:</p>
              {data.maintenanceHistoryForCatalogExtraRowRental && (
                <ul>
                  {data.maintenanceHistoryForCatalogExtraRowRental.map(
                    (maintenance) => {
                      return (
                        <li key={maintenance.maintenanceInterval.id}>
                          {getMaintenanceHistoryInfo(maintenance)}
                        </li>
                      );
                    }
                  )}
                </ul>
              )}
            </>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickClose} variant="secondary">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    info: {
      color: palette.secondary.main,
    },
  });

export default withStyles(styles)(DialogCatalogExtraRowRentalInfo);
