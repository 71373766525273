import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultEvents {
  events: Query["events"];
  eventsOfferCatalog: Query["eventsOfferCatalog"];
  eventsOfferDiscountList: Query["eventsOfferDiscountList"];
  customer: Query["customer"];
}
export const GET_EVENTS_BY_CUSTOMER_QUERY = gql`
  query getEventsQuery($customerId: ID!) {
    events(customerId: $customerId) {
      id
      eventType
      date
      information
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
    eventsOfferCatalog(customerId: $customerId) {
      id
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      catalog {
        id
        name
      }
    }
    eventsOfferDiscountList(customerId: $customerId) {
      id
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      discountList {
        id
        name
      }
    }
    customer(id: $customerId) {
      id
      customersDiscountLists {
        id
        name
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
      referenceSet {
        id
        name
        createdAt
      }
      orderSet {
        id
        information
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
