import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  IdPriceType,
  QueryReservationPriceArgs,
  ReservationType,
} from "../../entity/types";
import { useQuery } from "@apollo/client";
import {
  GET_RESERVATION_PRICE_QUERY,
  QueryResultReservationPrice,
} from "../../apollo/queries/reservations";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { ID_EMPTY } from "../../utils/constants";
import format from "date-fns/format";
import ReservationDiscountInputs from "./ReservationDiscountInputs";
import { newDate } from "../../utils/dates";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationType;
}

function ReservationDiscount({ classes, reservation }: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultReservationPrice,
    QueryReservationPriceArgs
  >(GET_RESERVATION_PRICE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("reservationPrice"),
    variables: {
      reservationIds: [reservation.id],
      dateTo: reservation.dateReturned,
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const reservationPrice: IdPriceType =
    data.reservationPrice &&
    data.reservationPrice[0] &&
    data.reservationPrice[0].reservationId === reservation.id
      ? data.reservationPrice[0]
      : {
          reservationId: ID_EMPTY,
          totalReservation: 0,
          totalReservationWithoutDiscount: 0,
        };

  return (
    <div className="mb-3">
      {reservationPrice.invoicePeriodStart &&
        reservationPrice.invoicePeriodEnd && (
          <div className="mb-1">
            {t("date_range", {
              start: format(
                newDate(reservationPrice.invoicePeriodStart),
                t("format_date")
              ),
              end: format(
                newDate(reservationPrice.invoicePeriodEnd),
                t("format_date")
              ),
            })}
          </div>
        )}
      <ReservationDiscountInputs
        reservation={reservation}
        reservationPrice={reservationPrice}
      />
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ReservationDiscount);
