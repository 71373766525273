import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-light-svg-icons";
import format from "date-fns/format";
import { CustomerType, DiscountListType } from "../../entity/types";
import { newDate } from "../../utils/dates";

interface Props extends WithStyles<typeof styles> {
  discountList: DiscountListType;
  customer?: CustomerType;
}

const ListGroupItemContentDiscountList = ({
  classes,
  discountList,
  customer,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex w-100 justify-content-between">
        <h5 className="mb-1">
          {discountList.locked && (
            <FontAwesomeIcon className="me-2" icon={faLock} />
          )}
          {discountList.name} -{" "}
          <small>
            {discountList.isBusiness ? t("business") : t("private")}
          </small>
        </h5>
        <small title={t("created_at")}>
          {format(newDate(discountList.createdAt), t("format_datetime"))}
        </small>
      </div>
      {customer && customer.id === discountList.customer?.id && (
        <div className="mt-1 mb-1">{t("offer")}</div>
      )}
      {discountList.createdBy && (
        <div className="mt-1 mb-1">
          {discountList.createdBy.lastName} {discountList.createdBy.firstName}
        </div>
      )}
    </>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ListGroupItemContentDiscountList);
