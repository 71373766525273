import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { Button } from "react-bootstrap";
import { handleError } from "../../entity/ErrorHandler";
import { CommissionTemplateEmpty } from "../../entity/empties";
import {
  Mutation,
  MutationCreateCommissionTemplateArgs,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { CREATE_COMMISSION_TEMPLATE_MUTATION } from "../../apollo/mutations/commissions";
import DialogContentCommissionTemplate from "./DialogContentCommissionTemplate";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {}

function CreateCommissionTemplate({ classes }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [commissionTemplate, setCommissionTemplate] = useState(
    CommissionTemplateEmpty
  );

  const [createCommissionTemplate] = useMutation<
    Mutation,
    MutationCreateCommissionTemplateArgs
  >(CREATE_COMMISSION_TEMPLATE_MUTATION, {
    onCompleted: (result) => {
      setCommissionTemplate(CommissionTemplateEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("commissionTemplates"),
      });
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createCommissionTemplate({
      variables: {
        name: commissionTemplate.name,
        baseSalary: commissionTemplate.baseSalary,
      },
    });
    setOpen(false);
  };

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "commissions.add_commissiontemplate",
  ]);

  if (!hasPermissionAdd) {
    return <></>;
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className="addButton"
        variant="primary"
        size="lg"
      >
        {open ? <ClearIcon /> : <AddIcon />}
      </Button>
      <Dialog open={open}>
        <form onSubmit={(event) => handleSubmit(event)}>
          <DialogTitle>{t("new_commission_template")}</DialogTitle>
          <DialogContentCommissionTemplate
            commissionTemplate={commissionTemplate}
            setCommissionTemplate={setCommissionTemplate}
          />
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="secondary">
              {t("cancel")}
            </Button>
            <Button
              disabled={!commissionTemplate.name.trim()}
              type="submit"
              variant="primary"
            >
              {t("save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CreateCommissionTemplate);
