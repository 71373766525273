import React from "react";
import { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Box, createStyles, Tab, Theme } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMdChat, faNotesMedical, faCog } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { faCalendarAlt, faEllipsisV } from "@fortawesome/pro-light-svg-icons";


import MaterialTable from "material-table";
import {
  tableIcons,
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
} from "../utils/TableProps";
import { useTranslation } from "react-i18next";
import { IdentificationRequestType } from "../entity/types";
import IdentificationRequestListMenuForNew from "../components/Identification/IdentificationRequestListMenuForNew";
import HeaderPage from "../components/Shared/HeaderPage";
import { SimpleIdentificationRequestEmpty } from "../entity/empties";
import DialogIdentificationRequest from "../components/Identification/DialogIdentificationRequest";

interface Props extends WithStyles<typeof styles> {
    elems: IdentificationRequestType[];
}

const OperatorNewRequestsTable = ({ classes, elems }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [identificationRequest, setIdentificationRequest] = useState(SimpleIdentificationRequestEmpty);

  const data = elems.map((o) => ({ ...o }));



    const getTableActions = () => {
        return [
        {
            icon: () => <FontAwesomeIcon icon={faEllipsisV} />,
            tooltip: t("edit"),
            onClick: (event: any, rowData: any) => {
                console.log(rowData);
            },
        },
        ];
    };

  return (
    <>
      <div className="containerInner">
        <MaterialTable
          style={getTableStyle()}
          title={""}
          icons={tableIcons}
          localization={GetTableLocalization(t)}
          columns={[
            { title: t("identifier"), field: "id" },
            { title: t("created_at"), field: "createdTs" }
          ]}
          data={data}
          options={getTableOptions()}
          components={{
            Action: (props) => (
              <IdentificationRequestListMenuForNew
                identificationRequest={props.data}
              />
            ),
          }}
          actions={getTableActions()}
        />
        {open && (
          <DialogIdentificationRequest
            open={open}
            setOpen={setOpen}
            identificationRequest={identificationRequest}
            setIdentificationRequest={setIdentificationRequest}
          />
        )}
      </div>
    </>
  );
};




const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(OperatorNewRequestsTable);




/*

import React, { useState } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import { ThemeProvider } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import {
    CREATE_SIMPLE_IDENTIFICATIONREQUEST,
    DELETE_IDENTIFICATIONREQUEST,
    GET_NEW_IDENTIFICATION_REQUESTS
} from "../../apollo/Mutations/UserMutations";
import { GET_ORGANISATIONS } from "../../Apollo/Queries/OrganisationQueries";

import {
    getBasicTableOptions, 
    getReadOnlyTableOptions,
    tableIcons,
    theme,
} from "../../Utils/TableProps";

import gql from "graphql-tag";

import Select from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from "@mui/material/FormControl";
import {
    MessageType,
    useHandleNotistack,
} from "../../Entity/NotistackHandler";
import { useHandleError } from "../../Entity/ErrorHandling/ErrorHandler";
import { NeurOneTextInput } from "./InputFields/NeurOneTextInput";
import { NeurOneInputType } from "./InputFields/InputHelpers/NeurOneInputType";
import { EditIdentificationRequestButton } from "../views/NeurOneAdminViews/EditIdentificationRequestButton";
import { GET_IDENTIFICATIONREQUESTDIALOG } from "../../apollo/localResolvers";
import { SET_IDENTIFICATIONREQUESTDIALOG } from "../../apollo/Mutations/IdentificationRequestMutations";
import IdentificationRequestDialog from "../views/IdentificationRequestDialog"; 

function NewRequestsTable() {
    const setNotistack = useHandleNotistack();
    const handleError = useHandleError();
    const { loading, error, data: identificationRequestData } = useQuery(GET_NEW_IDENTIFICATION_REQUESTS);
    const { loading: orgLoad, error: orgError, data: orgData } = useQuery(
        GET_ORGANISATIONS
    );
    const [rowId, setRowId] = useState("");
    const [rowTitle, setRowTitle] = useState("");
    const [createIdentificationRequest] = useMutation(CREATE_SIMPLE_IDENTIFICATIONREQUEST, {});
    const [deleteIdentificationRequest] = useMutation(DELETE_IDENTIFICATIONREQUEST, {});

    const { data: dialogState } = useQuery(GET_IDENTIFICATIONREQUESTDIALOG);
    const [openDialog] = useMutation(
        SET_IDENTIFICATIONREQUESTDIALOG, { variables: { isopen: false }}
    );
   
    const handleClose = ()  => {
        openDialog();
    };    if (error) {
        handleError(error, "Tietojen hakeminen epäonnistui.");
        return null;
    }
    if (orgError) {
        handleError(
            orgError,
            "Organisaatioiden tietojen hakeminen epäonnistui."
        );
        return null;
    }



    const lookupDataMapper = (mappingData: any) => {
        const mapped = mappingData.organisations.reduce(function (
            map: any,
            obj: any
        ) {
            map[obj.id] = obj.businessName;
            return map;
        },
        {});
        return mapped;
    };

    const getElemFromArray = (identificationRequestId: string, identificationRequestArray: any) => {
        let element = identificationRequestArray.find((identificationRequest: any) => identificationRequest.id === identificationRequestId);
        if (element) {
            return element;
        } else {
            return undefined;
        }
    };

    const columnTypesComplexIdRequest = [
        NeurOneInputType.Any,
        NeurOneInputType.EmailAddress,
        NeurOneInputType.PhoneNumber,
    ];
    const columnTypesSimple = [
        NeurOneInputType.Any,
        NeurOneInputType.Date
    ];

    return (
        <ThemeProvider theme={theme}>
            <MaterialTable
                isLoading={loading || orgLoad}
                style={{
                    boxShadow: "0px 0px 0px #D3D3D3",
                }}
                title=""
                columns={[
                    { title: "Id", field: "id", hidden: true },
                    { title: "createdTs", field: "date" }
                ]}
                data={identificationRequestData ? identificationRequestData.newWaitingOrCompletedIdentificationRequests: []}
                icons={tableIcons}
                options={getReadOnlyTableOptions()}
                localization={{
                    body: {
                        emptyDataSourceMessage: "Ei näytettävää",
                        addTooltip: "Lisää",
                        deleteTooltip: "Poista",
                        editRow: {
                            deleteText: "Haluatko varmasti poistaa tunnistautumispyynnön?",
                            cancelTooltip: "Peruuta",
                            saveTooltip: "Tallenna",
                        },
                    },
                    header: {
                        actions: "Valinnat",
                    },
                    toolbar: {
                        searchPlaceholder: "Haku",
                        searchTooltip: "Haku",
                    },
                }}
                components={{
                    EditField: (props) => {
                        const menuMapper = (mappingData: any) => {
                            return (
                                <MenuItem value={mappingData.id}>
                                    {mappingData.businessName}
                                </MenuItem>
                            );
                        };

                        return (
                                <NeurOneTextInput
                                    columnProps={props}
                                    columnTypes={columnTypesSimple}
                                />
                            );

                    },
                }}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if (newData.validation === "false") {
                                    reject();
                                } else {

                                    createIdentificationRequest()
                                       .then((response) => {
                                                    setNotistack(
                                                        MessageType.Succ,
                                                        "Pyyntö lisätty."
                                                    );

                                        })
                                        .catch((err) => {
                                            handleError(
                                                err,
                                                "Pyynnön lisääminen epäonnistui."
                                            );
                                        });
                                }
                                resolve();
                            }, 600);
                        }),
                    onRowDelete: (oldData: any) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                deleteIdentificationRequest({
                                    variables: { id: oldData.id },
                                    refetchQueries: [{ query: GET_NEW_IDENTIFICATION_REQUESTS }],
                                })
                                    .then((response) => {
                                        setNotistack(
                                            MessageType.Succ,
                                            "Käyttäjä poistettu."
                                        );
                                    })
                                    .catch((err) => {
                                        handleError(
                                            err,
                                           "Rivin poistaminen epäonnistui."
                                        );
                                    });

                                resolve();
                            }, 600);
                        }),
                }}
                onRowClick={(event, rowData: any) => {
                    openDialog();
                    setRowId(rowData.id);
                    setRowTitle(rowData.orgname);
                }}
            />
             {
                <div>
                    <IdentificationRequestDialog id={rowId} title={rowTitle} />
                </div>
            }

        </ThemeProvider>
    );
}
// inside div:                       

export default NewIdentificationRequestsTable;
*/