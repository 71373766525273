import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { Col, Row, Form } from "react-bootstrap";
import { GroupType, LocationType, UserType } from "../../entity/types";
import { PermissionsContext, UserContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import { SET_IS_LOGGED_IN_MUTATION } from "../../apollo/client/is_logged_in";
import { handleError } from "../../entity/ErrorHandler";
import i18n from "i18next";
import { findFromSetById } from "../../utils/collections";
import { ID_EMPTY } from "../../utils/constants";
import SelectRequired from "../Shared/SelectRequired";
import { onLogout } from "../../utils/login";

interface Props extends WithStyles<typeof styles> {
  user: UserType;
  setUser: Function;
  groups: GroupType[];
  locations: LocationType[];
  emptyPasswordOnBlur?: boolean;
}

function DialogContentUser({
  classes,
  user,
  setUser,
  groups,
  locations,
  emptyPasswordOnBlur = false,
}: Props) {
  const { t } = useTranslation();
  const [email, setUsername] = useState(user.email);
  const [password, setPassword] = useState("");
  const currentUser = useContext(UserContext);
  const [setIsLoggedIn] = useMutation(SET_IS_LOGGED_IN_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });
  let isMe = user.id !== ID_EMPTY && user.id === currentUser.id;

  const handleUsernameChange = () => {
    /* User must login again if username is changed */
    if (isMe) {
      onLogout();
      setIsLoggedIn({ variables: { isLoggedIn: false } });
      window.location.reload();
    }
  };

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddUser = checkPermission(myPermissions, [
    "auth.add_user",
  ]);
  const hasPermissionEditUser = checkPermission(myPermissions, [
    "auth.change_user",
  ]);
  const disabled = isMe
    ? false
    : user.id
    ? !hasPermissionEditUser
    : !hasPermissionAddUser;
  const showProfileSelect = user.id
    ? hasPermissionEditUser
    : hasPermissionAddUser;

  const handleChangeName = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (user.id === ID_EMPTY) {
      setUser({
        ...user,
        email:
          user.firstName.trim().toLowerCase() +
          "." +
          user.lastName.trim().toLowerCase() + "@esimerkki.com",
      });
    }
  };

  let languages = [];
  if (i18n.options.resources) {
    for (let key of Object.keys(i18n.options.resources)) {
      languages.push({ key: key.toUpperCase(), name: t("language_" + key) });
    }
  }

  return (
    <DialogContent>
      <Row>
        <Col>
          <FormControl fullWidth>
            <TextField
              id="txtUserFirstname"
              label={t("firstname")}
              className={classes.textField}
              onChange={(event) => {
                setUser({ ...user, firstName: event.target.value });
              }}
              onKeyUp={(event) => handleChangeName(event)}
              value={user.firstName}
              required={true}
              disabled={disabled}
            />
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth>
            <TextField
              id="txtUserLastname"
              label={t("lastname")}
              className={classes.textField}
              onChange={(event) => {
                setUser({ ...user, lastName: event.target.value });
              }}
              onKeyUp={(event) => handleChangeName(event)}
              value={user.lastName}
              required={true}
              disabled={disabled}
            />
          </FormControl>
        </Col>
      </Row>
      <FormControl fullWidth>
        <InputLabel id="lblLanguage">{t("language")}</InputLabel>
        <Select
          autoWidth
          labelId="lblLanguage"
          className={classes.textField}
          onChange={(event) => {
            setUser({
              ...user,
              UserInfo: { ...user.UserInfo, language: event.target.value },
            });
          }}
          value={user.UserInfo?.language}
          disabled={disabled}
        >
          {languages.map((lang) => (
            <MenuItem key={lang.key} value={lang.key}>
              {lang.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="txtUserEmail"
          type="email"
          label={t("email")}
          className={classes.textField}
          onChange={(event) => {
            setUser({ ...user, email: event.target.value });
          }}
          value={user.email}
          required={true}
          disabled={disabled}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="txtUserPhone"
          label={t("phone")}
          className={classes.textField}
          onChange={(event) => {
            setUser({
              ...user,
              UserInfo: { ...user.UserInfo, phone: event.target.value },
            });
          }}
          value={user.UserInfo?.phone}
          disabled={disabled}
        />
      </FormControl>
      <br />
      <br />
      <FormControl fullWidth>
        <TextField
          id="txtUserAddress"
          label={t("address")}
          className={classes.textField}
          onChange={(event) => {
            setUser({
              ...user,
              UserInfo: { ...user.UserInfo, address: event.target.value },
            });
          }}
          value={user.UserInfo?.address}
          disabled={disabled}
        />
      </FormControl>
      <Row>
        <Col>
          <FormControl fullWidth>
            <TextField
              id="txtUserPostcode"
              label={t("postcode")}
              className={classes.textField}
              onChange={(event) => {
                setUser({
                  ...user,
                  UserInfo: { ...user.UserInfo, postcode: event.target.value },
                });
              }}
              value={user.UserInfo?.postcode}
              disabled={disabled}
            />
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth>
            <TextField
              id="txtUserDistrict"
              label={t("district")}
              className={classes.textField}
              onChange={(event) => {
                setUser({
                  ...user,
                  UserInfo: { ...user.UserInfo, district: event.target.value },
                });
              }}
              value={user.UserInfo?.district}
              disabled={disabled}
            />
          </FormControl>
        </Col>
      </Row>
      <br />
      <br />
      <FormControl fullWidth>
        <InputLabel id="lblUserLocation">{t("location")}</InputLabel>
        <Select
          autoWidth
          labelId="lblUserLocation"
          className={classes.textField}
          onChange={(event) => {
            let locationNew = findFromSetById(
              String(event.target.value),
              locations,
              undefined
            );
            setUser({
              ...user,
              UserInfo: { ...user.UserInfo, location: locationNew },
            });
          }}
          value={user.UserInfo?.location ? user.UserInfo?.location.id : 0}
        >
          <MenuItem key={ID_EMPTY} value={ID_EMPTY}>
            {t("not_selected")}
          </MenuItem>
          {locations.map((location) => (
            <MenuItem key={location.id} value={location.id}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {showProfileSelect && (
        <FormControl fullWidth>
          <InputLabel id="lblProfile">
            {t("profile")} {t("required_mark")}
          </InputLabel>
          <SelectRequired
            autoWidth
            labelId="lblProfile"
            className={classes.textField}
            onChange={(event) => {
              setUser({ ...user, groups: { 0: { id: event.target.value } } });
            }}
            value={user.groups[0] ? user.groups[0].id : ID_EMPTY}
          >
            <MenuItem key={ID_EMPTY} value={ID_EMPTY}>
              {t("not_selected")}
            </MenuItem>
            {groups.map((group: GroupType) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </SelectRequired>
        </FormControl>
      )}
      <FormControl fullWidth>
        <TextField
          id="txtUserUsername"
          label={t("email")}
          className={classes.textField}
          onChange={(event) => {
            setUsername(event.target.value);
          }}
          onBlur={() => {
            setUser({ ...user, email: email });
            handleUsernameChange();
          }}
          value={email}
          required={true}
          disabled={disabled}
        />
      </FormControl>
      {isMe && (
        <Form.Text className="text-muted">
          {t("explain_username_editing_user")}
        </Form.Text>
      )}
      <FormControl fullWidth>
        <TextField
          type="password"
          id="txtUserPassword"
          label={t("password")}
          className={classes.textField}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          onBlur={() => {
            setUser({ ...user, password: password });
            if (emptyPasswordOnBlur) {
              setPassword("");
            }
          }}
          value={password}
          required={user.id === ID_EMPTY}
          disabled={disabled}
          inputProps={{ minLength: 10 }}
        />
      </FormControl>
      {user.id !== ID_EMPTY && (
        <Form.Text className="text-muted">
          {t("explain_password_editing_user")}
        </Form.Text>
      )}
    </DialogContent>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  });

export default withStyles(styles)(DialogContentUser);
