import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export const FRAG_MAINTENANCE_FIELDS = gql`
  fragment MaintenanceFields on MaintenanceType {
    id
    album {
      id
      maintenanceimageSet {
        id
      }
    }
    maintenanceType
    machine {
      id
      identifier
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
      location {
        id
        name
      }
    }
    catalogExtraRowRental {
      id
      name
      location {
        id
        name
      }
    }
    maintenanceInterval {
      id
      maintenanceSetting {
        id
        scheduleType
      }
      intervalType
      hours
      days
      description
    }
    serviceTechnician {
      id
      firstName
      lastName
    }
    operationhour {
      id
      hours
      dateChecked
    }
    estimatedDate
    overdueHours
    serviceStartBy
    serviceEndBy
    servicedAt
    verifiedAt
    maintenancecommentSet {
      id
      comment
      createdAt
      createdBy {
        firstName
        lastName
      }
    }
  }
`;

export interface QueryResultMaintenanceSettingsForMachine {
  maintenanceSettingsForMachine: Query["maintenanceSettingsForMachine"];
}
export const GET_MAINTENANCE_SETTINGS_FOR_MACHINE_QUERY = gql`
  query getMaintenanceSettingsForMachineQuery($machineId: ID!) {
    maintenanceSettingsForMachine(machineId: $machineId) {
      id
      machine {
        id
      }
      scheduleType
      estimatedHoursPerDay
      startDate
      maintenanceintervalSet {
        id
        intervalType
        hours
        days
        description
      }
    }
  }
`;

export interface QueryResultMaintenanceSettingsForExtraRowRental {
  maintenanceSettingsForExtraRowRental: Query["maintenanceSettingsForExtraRowRental"];
}
export const GET_MAINTENANCE_SETTINGS_FOR_EXTRA_ROW_RENTAL_QUERY = gql`
  query getMaintenanceSettingsForExtraRowRentalQuery(
    $catalogExtraRowRentalId: ID!
  ) {
    maintenanceSettingsForExtraRowRental(
      catalogExtraRowRentalId: $catalogExtraRowRentalId
    ) {
      id
      catalogExtraRowRental {
        id
      }
      scheduleType
      estimatedHoursPerDay
      startDate
      maintenanceintervalSet {
        id
        intervalType
        hours
        days
        description
      }
    }
  }
`;

export interface QueryResultMaintenances {
  maintenances: Query["maintenances"];
}
export const GET_MAINTENANCES_QUERY = gql`
  ${FRAG_MAINTENANCE_FIELDS}
  query getMaintenancesQuery(
    $locationIds: [ID]!
    $categoryIds: [ID]!
    $userIds: [ID]!
  ) {
    maintenances(
      locationIds: $locationIds
      categoryIds: $categoryIds
      userIds: $userIds
    ) {
      ...MaintenanceFields
    }
  }
`;

export interface QueryResultMaintenancesUpcoming {
  maintenancesUpcoming: Query["maintenancesUpcoming"];
}
export const GET_MAINTENANCES_UPCOMING_QUERY = gql`
  query getMaintenancesUpcomingQuery(
    $locationIds: [ID]!
    $categoryIds: [ID]!
    $userIds: [ID]!
  ) {
    maintenancesUpcoming(
      locationIds: $locationIds
      categoryIds: $categoryIds
      userIds: $userIds
    ) {
      ...MaintenanceFields
    }
  }
`;

export interface QueryResultMaintenanceQueues {
  maintenances: Query["maintenances"];
  maintenancesUpcoming: Query["maintenancesUpcoming"];
  maintenanceUpcomingFetch: Query["maintenanceUpcomingFetch"];
}
export const GET_MAINTENANCE_QUEUES_QUERY = gql`
  ${FRAG_MAINTENANCE_FIELDS}
  query getMaintenanceQueuesQuery(
    $locationIds: [ID]!
    $categoryIds: [ID]!
    $userIds: [ID]!
    $create: Boolean
  ) {
    maintenances(
      locationIds: $locationIds
      categoryIds: $categoryIds
      userIds: $userIds
    ) {
      ...MaintenanceFields
    }
    maintenancesUpcoming(
      locationIds: $locationIds
      categoryIds: $categoryIds
      userIds: $userIds
      create: $create
    ) {
      ...MaintenanceFields
    }
    maintenanceUpcomingFetch {
      id
      fetchType
      fetchTime
    }
  }
`;

export interface QueryResultMaintenanceImages {
  maintenanceImages: Query["maintenanceImages"];
}
export const GET_MAINTENANCE_IMAGES_QUERY = gql`
  query getMaintenanceImages($maintenanceAlbumId: ID!) {
    maintenanceImages(maintenanceAlbumId: $maintenanceAlbumId) {
      id
      image
      imageName
      imageType
      eurosOwnCosts
      eurosWarranty
      information
      createdAt
    }
  }
`;
