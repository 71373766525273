import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import Error from "../Shared/Error";
import LoadingSimple from "./LoadingSimple";
import { ID_EMPTY } from "../../utils/constants";
import { removeStringFromArr } from "../../utils/collections";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import {
  GET_CATALOG_ACTIVE_CATEGORIES_QUERY,
  QueryResultCatalogActiveWithCategories,
} from "../../apollo/queries/catalogs";
import { setCatalogIdSelected } from "../../utils/catalogs/selected_catalog";
import { renderSelectGroupCategoryUpperCategory } from "../../utils/catalogs/select_group";

interface Props extends WithStyles<typeof styles> {
  catalogCategoryIdsSelected: string[];
  setCatalogCategoryIdsSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

function CatalogCategoryFilter({
  classes,
  catalogCategoryIdsSelected,
  setCatalogCategoryIdsSelected,
}: Props) {
  const { t } = useTranslation();

  const [
    catalogCategoryIdsSelectedEdited,
    setCatalogCategoryIdsSelectedEdited,
  ] = useState(
    catalogCategoryIdsSelected.length === 0
      ? [ID_EMPTY]
      : catalogCategoryIdsSelected
  );

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: dataCategories,
  } = useQuery<QueryResultCatalogActiveWithCategories>(
    GET_CATALOG_ACTIVE_CATEGORIES_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("catalogActive"),
    }
  );
  if (loadingCategories) return <LoadingSimple />;
  if (errorCategories) return <Error error={errorCategories} />;
  if (!dataCategories) return <Error error={t("error_query_failed")} />;

  return (
    <FormControl fullWidth>
      <InputLabel id="lblCatalogCategoryFilter">
        {t("catalog_category")}
      </InputLabel>
      <Select
        autoWidth
        labelId="lblCatalogCategoryFilter"
        value={catalogCategoryIdsSelectedEdited}
        multiple={true}
        onChange={(event) => {
          let catalogCategoryIdsSelectedNew = event.target.value as string[];

          const hadOnlyAll =
            catalogCategoryIdsSelectedEdited.length === 1 &&
            catalogCategoryIdsSelectedEdited.includes(ID_EMPTY);
          const hasAll = catalogCategoryIdsSelectedNew.includes(ID_EMPTY);

          if (hadOnlyAll && catalogCategoryIdsSelectedNew.length > 1) {
            catalogCategoryIdsSelectedNew = removeStringFromArr(
              catalogCategoryIdsSelectedNew,
              ID_EMPTY
            );
          } else if (hasAll) {
            catalogCategoryIdsSelectedNew = [ID_EMPTY];
          }

          setCatalogCategoryIdsSelectedEdited(catalogCategoryIdsSelectedNew);
        }}
        onClose={() => {
          const hasOnlyAll =
            catalogCategoryIdsSelectedEdited.length === 1 &&
            catalogCategoryIdsSelectedEdited.includes(ID_EMPTY);
          setCatalogCategoryIdsSelected(
            hasOnlyAll ? [] : catalogCategoryIdsSelectedEdited
          );

          if (catalogCategoryIdsSelectedEdited.length === 1) {
            setCatalogIdSelected(catalogCategoryIdsSelectedEdited[0]);
          }
        }}
      >
        <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
        {dataCategories.catalogActive?.catalogcategoryupperSet.map(
          (catalogCategoryUpper) =>
            renderSelectGroupCategoryUpperCategory(catalogCategoryUpper)
        )}
      </Select>
    </FormControl>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CatalogCategoryFilter);
