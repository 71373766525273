import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  Box,
  createStyles,
  DialogContent,
  Tab,
  Theme,
} from "@material-ui/core";
import { OperationHourSource, ReservationType } from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import ReservationButtonGiveChecked from "./ReservationButtonGiveChecked";
import DialogContentInspectionGiveAnswers from "../InspectionGive/DialogContentInspectionGiveAnswers";
import { hasReservationCatalogExtra } from "../../utils/reservations/reservation";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { MachineEmpty } from "../../entity/empties";
import DialogContentOperationHours from "../OperationHour/DialogContentOperationHours";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reservation: ReservationType;
}

function DialogReservationGiveCheck({
  classes,
  open,
  setOpen,
  reservation,
}: Props) {
  const { t } = useTranslation();

  const isExtraCatalogReservation = hasReservationCatalogExtra(reservation);

  const [tabSelected, setTabSelected] = useState(
    isExtraCatalogReservation ? "1" : "0"
  );

  const handleChange = (event: React.ChangeEvent<{}>, newTab: string) => {
    setTabSelected(newTab);
  };

  const showOperationHours =
    !isExtraCatalogReservation &&
    reservation.machine &&
    reservation.machine.hourTracking;

  let tabs: JSX.Element[] = [];
  let panels: JSX.Element[] = [];
  if (!isExtraCatalogReservation) {
    tabs.push(<Tab key="0" label={t("inspection_give")} value="0" />);
    panels.push(
      <TabPanel key="0" value="0">
        {reservation.machine && (
          <DialogContentInspectionGiveAnswers
            reservation={reservation}
            editable={true}
          />
        )}
      </TabPanel>
    );
  }
  if (showOperationHours) {
    tabs.push(<Tab key="3" label={t("operation_hours")} value="3" />);
    panels.push(
      <TabPanel key="3" value="3">
        <DialogContentOperationHours
          reservation={reservation}
          machine={reservation.machine ? reservation.machine : MachineEmpty}
          source={OperationHourSource.GiveCheck}
        />
      </TabPanel>
    );
  }

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitleWithClose
        id="dialogTitleReservationGiveCheck"
        onClose={() => setOpen(false)}
      >
        {t("toggle_give_checked_at")}
      </DialogTitleWithClose>
      <DialogContent>
        <TabContext value={tabSelected}>
          <Box>
            <TabList onChange={handleChange}>{tabs}</TabList>
          </Box>
          {panels}
        </TabContext>
      </DialogContent>
      <DialogActions>
        <ReservationButtonGiveChecked
          reservation={reservation}
          disabled={false}
          setOpen={setOpen}
        />
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    note: {
      maxWidth: "20rem",
    },
  });

export default withStyles(styles)(DialogReservationGiveCheck);
