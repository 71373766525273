import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export const FRAG_RESERVATION_SHARED = gql`
  fragment ReservationFields on ReservationType {
    id
    status
    dateRented
    timeRented
    dateReturned
    timeReturned
    dateBillingMin
    bulkAmount
    bulkAmountReturned
    discountPercentDay
    discountPercentMonth
    estimatedMachineHoursPerDay
  }
`;

export const FRAG_RESERVATION_WORK_QUEUE = gql`
  ${FRAG_RESERVATION_SHARED}
  fragment ReservationWorkQueueFields on ReservationType {
    ...ReservationFields
    information
    deliveryMethod
    status
    userGive {
      id
      firstName
      lastName
    }
    userReturn {
      id
      firstName
      lastName
    }
    giveCheckedAt
    giveImagesAt
    giveInstructedAt
    giveAt
    returnAt
    returnImagesAt
    returnCheckedCatalogAt
    returnCheckedPreAt
    returnCheckedAt
    returnStoredAt
    catalogExtraRowRental {
      id
      identifier
      name
      location {
        id
      }
    }
    reservationcommentSet {
      id
      comment
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
    machine {
      id
      hourTracking
      bulkProduct
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
      identifier
      location {
        id
        name
      }
    }
    order {
      id
      customer {
        id
        name
      }
      location {
        id
        name
      }
    }
    albumGive {
      id
    }
    albumReturn {
      id
    }
  }
`;

export interface QueryResultReservations {
  reservations: Query["reservations"];
}
export const GET_RESERVATIONS_QUERY = gql`
  ${FRAG_RESERVATION_SHARED}
  query getReservationsQuery($dateFrom: Date!, $dateTo: Date!) {
    reservations(dateFrom: $dateFrom, dateTo: $dateTo) {
      ...ReservationFields
      machine {
        id
      }
      order {
        id
        status
        customer {
          id
          name
          contactPersonLastname
          contactPersonFirstname
        }
      }
    }
  }
`;

export interface QueryResultReservationsForOrder {
  reservationsForOrder: Query["reservationsForOrder"];
}
export const GET_RESERVATIONS_FOR_ORDER_QUERY = gql`
  ${FRAG_RESERVATION_WORK_QUEUE}
  query getReservationsForOrderQuery($orderId: ID!) {
    reservationsForOrder(orderId: $orderId) {
      ...ReservationWorkQueueFields
    }
  }
`;

export interface QueryResultReservationsWorkQueues {
  reservationsWorkQueues: Query["reservationsWorkQueues"];
}
export const GET_RESERVATIONS_WORK_QUEUES_QUERY = gql`
  ${FRAG_RESERVATION_WORK_QUEUE}
  query getReservationsWorkQueuesQuery($locationIds: [ID]!, $userId: ID) {
    reservationsWorkQueues(locationIds: $locationIds, userId: $userId) {
      ...ReservationWorkQueueFields
    }
  }
`;

export interface QueryResultReservationsWorkQueuesReturn {
  reservationsWorkQueuesReturn: Query["reservationsWorkQueuesReturn"];
}
export const GET_RESERVATIONS_WORK_QUEUES_RETURN_QUERY = gql`
  ${FRAG_RESERVATION_WORK_QUEUE}
  query getReservationsWorkQueuesReturnQuery {
    reservationsWorkQueuesReturn {
      ...ReservationWorkQueueFields
    }
  }
`;

export interface QueryResultReservationPrice {
  reservationPrice: Query["reservationPrice"];
}
export const GET_RESERVATION_PRICE_QUERY = gql`
  query getReservationPriceQuery($reservationIds: [ID], $dateTo: Date) {
    reservationPrice(reservationIds: $reservationIds, dateTo: $dateTo) {
      reservationId
      total
      totalUninvoiced
      totalReservation
      totalReservationWithoutDiscount
      invoicePeriodStart
      invoicePeriodEnd
      total
      isDayPrice
    }
  }
`;

export interface QueryResultReservationCommission {
  reservationCommission: Query["reservationsCommissions"];
}
export const GET_RESERVATION_COMMISSION_QUERY = gql`
  query getReservationsCommissionsQuery(
    $dateFrom: Date!
    $dateTo: Date!
    $userIdSeller: ID!
  ) {
    reservationsCommissions(
      dateFrom: $dateFrom
      dateTo: $dateTo
      userIdSeller: $userIdSeller
    ) {
      id
      machine {
        machineModel {
          machineMake {
            title
          }
          title
        }
      }
      giveAt
      returnAt
      order {
        customer {
          name
        }
      }
    }
  }
`;

export interface QueryResultReservationEditableDates {
  reservationEditableDates: Query["reservationEditableDates"];
}
export const GET_RESERVATION_EDITABLE_DATES_QUERY = gql`
  query getReservationEditableDatesQuery($reservationIds: [ID]) {
    reservationEditableDates(reservationIds: $reservationIds) {
      dateStart
      dateEnd
    }
  }
`;

export interface QueryResultInvoicingPriceForReservation {
  invoicingPriceForReservation: Query["invoicingPriceForReservation"];
}
export const GET_INVOICING_PRICE_FOR_RESERVATION_QUERY = gql`
  query getInvoicingPriceForReservation($reservationId: ID!, $date: Date!) {
    invoicingPriceForReservation(reservationId: $reservationId, date: $date) {
      total
      isDayPrice
      days
      months
    }
  }
`;

export interface QueryResultReservationImageGives {
  reservationImageGives: Query["reservationImageGives"];
}
export const GET_RESERVATION_IMAGE_GIVES_QUERY = gql`
  query getReservationImageGives($reservationId: ID!) {
    reservationImageGives(reservationId: $reservationId) {
      id
      image
      createdAt
    }
  }
`;

export interface QueryResultReservationImageReturns {
  reservationImageReturns: Query["reservationImageReturns"];
}
export const GET_RESERVATION_IMAGE_RETURNS_QUERY = gql`
  query getReservationImageReturns($reservationId: ID!) {
    reservationImageReturns(reservationId: $reservationId) {
      id
      image
      createdAt
    }
  }
`;

export interface QueryResultReservationImageBasics {
  reservationImageBasics: Query["reservationImageBasics"];
}
export const GET_RESERVATION_IMAGE_BASICS_QUERY = gql`
  query getReservationImageBasics($reservationId: ID!) {
    reservationImageBasics(reservationId: $reservationId) {
      id
      image
      information
      shootingDate
      createdAt
    }
  }
`;

export interface QueryResultReservationsSearch {
  reservationsSearch: Query["reservationsSearch"];
}
export const RESERVATIONS_SEARCH_QUERY = gql`
  query ReservationsSearchQuery($search: String!) {
    reservationsSearch(search: $search) {
      id
      bulkAmount
      bulkAmountReturned
      order {
        id
        customer {
          id
          name
        }
      }
      catalogExtraRowRental {
        id
        identifier
        name
      }
      machine {
        id
        identifier
        bulkProduct
        machineModel {
          id
          title
          machineMake {
            id
            title
          }
        }
      }
    }
  }
`;
export interface QueryResultReservationsWithPrice {
  reservationsWithPrice: Query["reservationsWithPrice"];
}
export const GET_RESERVATIONS_WITH_PRICE_QUERY = gql`
  query getReservationsWithPriceQuery($dateFrom: Date!, $dateTo: Date!) {
    reservationsWithPrice(dateFrom: $dateFrom, dateTo: $dateTo) {
      orderId
      reservationId
      dateStart
      dateEnd
      title
      useDayPrice
      dayPrice
      monthPrices {
        month
        pricePerDay
      }
      datesSkipped
    }
  }
`;
