import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  MaintenanceIntervalIntervalType,
  MaintenanceIntervalType,
  MaintenanceSettingScheduleType,
  MaintenanceSettingType,
  Mutation,
  MutationCreateMaintenanceIntervalArgs,
  MutationDeleteMaintenanceIntervalArgs,
  MutationUpdateMaintenanceIntervalArgs,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import ButtonLoad from "../Shared/ButtonLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { dialogConfirm } from "../../utils/dialogs";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import {
  CREATE_MAINTENANCE_INTERVAL_MUTATION,
  DELETE_MAINTENANCE_INTERVAL_MUTATION,
  UPDATE_MAINTENANCE_INTERVAL_MUTATION,
} from "../../apollo/mutations/maintenances";
import { Col, FormText, Row } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import { formMaintenanceTimeRange } from "../../utils/maintenances/maintenance";
import TextFieldFocus from "../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {
  maintenanceInterval: MaintenanceIntervalType;
  maintenanceSetting: MaintenanceSettingType;
  showAddRow: Boolean;
  numberOfNormalRows: Number;
}

const MaintenanceIntervalRow = ({
  classes,
  maintenanceSetting,
  maintenanceInterval,
  showAddRow,
  numberOfNormalRows,
}: Props) => {
  const { t } = useTranslation();

  const [createMaintenanceInterval, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateMaintenanceIntervalArgs
  >(CREATE_MAINTENANCE_INTERVAL_MUTATION, {
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("maintenanceSettingsForMachine"),
      });
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [updateMaintenanceInterval, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateMaintenanceIntervalArgs
  >(UPDATE_MAINTENANCE_INTERVAL_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const [deleteMaintenanceInterval, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteMaintenanceIntervalArgs
  >(DELETE_MAINTENANCE_INTERVAL_MUTATION, {
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("maintenanceSettingsForMachine"),
      });
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "maintenance.change_maintenanceinterval",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "maintenance.change_maintenanceinterval",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "maintenance.delete_maintenanceinterval",
  ]);

  const [maintenanceIntervalEdit, setMaintenanceIntervalEdit] =
    useState(maintenanceInterval);

  const handleUpdate = (values: { [key: string]: string | number } = {}) => {
    const base: MutationUpdateMaintenanceIntervalArgs = {
      maintenanceIntervalId: maintenanceIntervalEdit.id,
      hours: maintenanceIntervalEdit.hours,
      days: maintenanceIntervalEdit.days,
      description: maintenanceIntervalEdit.description,
    };
    updateMaintenanceInterval({
      variables: { ...base, ...values },
    });
  };

  const handleDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteMaintenanceInterval({
        variables: {
          maintenanceIntervalId: maintenanceIntervalEdit.id,
        },
      });
    });
  };

  const handleCreate = () => {
    createMaintenanceInterval({
      variables: {
        maintenanceSettingId: maintenanceSetting.id,
        hours: 0,
        days: 0,
        description: "",
      },
    });
  };

  return (
    <Row className={loadingUpdate ? "loading" : ""}>
      {maintenanceSetting.scheduleType !==
        MaintenanceSettingScheduleType.Days && (
        <>
          <Col
            xs="6"
            sm={
              maintenanceSetting.scheduleType ===
              MaintenanceSettingScheduleType.HoursDays
                ? "1"
                : "2"
            }
          >
            <FormControl fullWidth>
              <TextFieldFocus
                type="number"
                onBlur={(event) => {
                  handleUpdate({ interval: Number(event.target.value) });
                }}
                onChange={(event) => {
                  setMaintenanceIntervalEdit({
                    ...maintenanceIntervalEdit,
                    hours: Number(event.target.value),
                  });
                }}
                value={maintenanceIntervalEdit.hours}
                disabled={!hasPermissionEdit}
              />
            </FormControl>
          </Col>
          <Col
            xs="6"
            sm={
              maintenanceSetting.scheduleType ===
              MaintenanceSettingScheduleType.HoursDays
                ? "1"
                : "3"
            }
            className={classes.textContainer}
          >
            <FormText className={classes.textContent}>
              {formMaintenanceTimeRange(
                t,
                maintenanceIntervalEdit.intervalType,
                MaintenanceSettingScheduleType.Hours
              )}
            </FormText>
            {showAddRow &&
              hasPermissionAdd &&
              maintenanceSetting.scheduleType !==
                MaintenanceSettingScheduleType.HoursDays && (
                <ButtonLoad
                  onClick={() => handleCreate()}
                  className="btnRoundVerySmall fa-pull-right"
                  variant="primary"
                  size="sm"
                  loading={loadingCreate}
                >
                  <AddIcon fontSize="small" />
                </ButtonLoad>
              )}
          </Col>
        </>
      )}
      {maintenanceSetting.scheduleType !==
        MaintenanceSettingScheduleType.Hours && (
        <>
          <Col
            xs="6"
            sm={
              maintenanceSetting.scheduleType ===
              MaintenanceSettingScheduleType.HoursDays
                ? "1"
                : "2"
            }
          >
            <FormControl fullWidth>
              <TextFieldFocus
                type="number"
                onBlur={(event) => {
                  handleUpdate({ interval: Number(event.target.value) });
                }}
                onChange={(event) => {
                  setMaintenanceIntervalEdit({
                    ...maintenanceIntervalEdit,
                    days: Number(event.target.value),
                  });
                }}
                value={maintenanceIntervalEdit.days}
                disabled={!hasPermissionEdit}
              />
            </FormControl>
          </Col>
          <Col
            xs="6"
            sm={
              maintenanceSetting.scheduleType ===
              MaintenanceSettingScheduleType.HoursDays
                ? "2"
                : "3"
            }
            className={classes.textContainer}
          >
            <FormText className={classes.textContent}>
              {formMaintenanceTimeRange(
                t,
                maintenanceIntervalEdit.intervalType,
                MaintenanceSettingScheduleType.Days
              )}
            </FormText>
            {showAddRow && hasPermissionAdd && (
              <ButtonLoad
                onClick={() => handleCreate()}
                className="btnRoundVerySmall fa-pull-right"
                variant="primary"
                size="sm"
                loading={loadingCreate}
              >
                <AddIcon fontSize="small" />
              </ButtonLoad>
            )}
          </Col>
        </>
      )}
      <Col xs="10" sm="6">
        <FormControl fullWidth>
          <TextField
            multiline={true}
            placeholder={t("description")}
            className={classes.description}
            onBlur={(event) => {
              handleUpdate({ description: event.target.value });
            }}
            onChange={(event) => {
              setMaintenanceIntervalEdit({
                ...maintenanceIntervalEdit,
                description: event.target.value,
              });
            }}
            value={maintenanceIntervalEdit.description}
            disabled={!hasPermissionEdit}
          />
        </FormControl>
      </Col>
      <Col xs={"2"} sm={"1"}>
        {hasPermissionDelete &&
          (!showAddRow || (showAddRow && numberOfNormalRows > 1)) &&
          maintenanceIntervalEdit.intervalType ===
            MaintenanceIntervalIntervalType.Normal && (
            <ButtonLoad
              onClick={() => handleDelete()}
              className={classes.btn}
              variant="light"
              size="sm"
              loading={loadingDelete}
            >
              <FontAwesomeIcon icon={faTrash} />
            </ButtonLoad>
          )}
      </Col>
    </Row>
  );
};

const styles = ({ palette }: Theme) =>
  createStyles({
    btn: {
      minWidth: "2rem",
    },
    info: {
      color: palette.secondary.main,
    },
    textContainer: {
      position: "relative",
    },
    textContent: {
      position: "absolute",
      top: "0.5em",
    },
    description: {
      marginRight: "1em",
    },
  });

export default withStyles(styles)(MaintenanceIntervalRow);
