import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import {
  QueryTransportsUnhandledArgs,
  ReservationType,
  TransportMethod,
  TransportType,
} from "../../entity/types";
import TransportList from "./TransportList";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import { UserContext } from "../../Root";
import {
  GET_TRANSPORTS_UNHANDLED_QUERY,
  QueryResultTransportsUnhandled,
} from "../../apollo/queries/transports";
import Drives from "./Drives";
import {
  GET_LOCATIONS_QUERY,
  QueryResultLocations,
} from "../../apollo/queries/locations";
import { getLocationSelected } from "../../utils/locations/location";
import LocationFilter from "../Shared/LocationFilter";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {}

export type TranportReservationSelected = {
  transport: TransportType;
  reservation: ReservationType;
};

function DrivingCreate({ classes }: Props) {
  const { t } = useTranslation();

  const user = useContext(UserContext);
  const locationIdDefault = getLocationSelected(user);

  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([
    locationIdDefault,
  ]);
  const [transportReservationsSelected, setTransportReservationsSelected] =
    useState<TranportReservationSelected[]>([]);

  const {
    loading: loadingTransports,
    error: errorTransports,
    data: dataTransports,
  } = useQuery<QueryResultTransportsUnhandled, QueryTransportsUnhandledArgs>(
    GET_TRANSPORTS_UNHANDLED_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("transportsUnhandled"),
      variables: {
        locationIds: locationIdsSelected,
      },
    }
  );

  const {
    loading: loadingLocations,
    error: errorLocations,
    data: dataLocations,
  } = useQuery<QueryResultLocations>(GET_LOCATIONS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("locations"),
  });

  if (loadingTransports || loadingLocations) return <Loading />;
  if (errorTransports) return <Error error={errorTransports} />;
  if (errorLocations) return <Error error={errorLocations} />;
  if (!dataTransports || !dataLocations) {
    return <Error error={t("error_query_failed")} />;
  }

  const transportsSend = (
    dataTransports.transportsUnhandled ? dataTransports.transportsUnhandled : []
  ).filter((transport) => transport.method === TransportMethod.Send);
  const transportsRetrieve = (
    dataTransports.transportsUnhandled ? dataTransports.transportsUnhandled : []
  ).filter((transport) => transport.method === TransportMethod.Retrieve);

  return (
    <div>
      <Row>
        <Col sm={6} md={6} lg={4} xl={3}>
          <LocationFilter
            locationIdsSelected={locationIdsSelected}
            setLocationIdsSelected={setLocationIdsSelected}
          />
        </Col>
      </Row>
      <Drives
        transportReservationsSelected={transportReservationsSelected}
        setTransportReservationsSelected={setTransportReservationsSelected}
      />
      <Row>
        <Col sm={12} md={6}>
          <TransportList
            header={t("transports_send")}
            transports={transportsSend}
            transportReservationsSelected={transportReservationsSelected}
            setTransportReservationsSelected={setTransportReservationsSelected}
          />
        </Col>
        <Col sm={12} md={6}>
          <TransportList
            header={t("transports_retrieve")}
            transports={transportsRetrieve}
            transportReservationsSelected={transportReservationsSelected}
            setTransportReservationsSelected={setTransportReservationsSelected}
          />
        </Col>
      </Row>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DrivingCreate);
