import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { ReservationType } from "../../entity/types";
import { useTranslation } from "react-i18next";
import WorkQueueCardRenting from "./WorkQueueCardRenting";
import AddIcon from "@material-ui/icons/Add";
import DialogReservationReturn from "./DialogReservationReturn";
import { useQuery } from "@apollo/client";
import {
  GET_RESERVATIONS_WORK_QUEUES_RETURN_QUERY,
  QueryResultReservationsWorkQueuesReturn,
} from "../../apollo/queries/reservations";
import Error from "../Shared/Error";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  reservations: ReservationType[];
  locationIdSelected: string;
}

function WorkQueueRentingCustomerReturn({
  classes,
  reservations,
  locationIdSelected,
}: Props) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const { loading, error, data } =
    useQuery<QueryResultReservationsWorkQueuesReturn>(
      GET_RESERVATIONS_WORK_QUEUES_RETURN_QUERY,
      {
        fetchPolicy: getQueryFetchPolicy("reservationsWorkQueuesReturn"),
      }
    );

  if (error) return <Error error={error} />;

  const reservationsReturnPre: ReservationType[] =
    data && data.reservationsWorkQueuesReturn
      ? data.reservationsWorkQueuesReturn
      : [];

  return (
    <div className={`${classes.conWorkQueue} conWorkQueue`}>
      <div className="titleWorkQueue">
        {t("work_queues_renting_customer_return")}
      </div>
      <ButtonLoad
        loading={loading}
        className={`${classes.btn} btnRound`}
        onClick={() => setOpenDialog(!openDialog)}
        disabled={reservationsReturnPre.length === 0}
      >
        <AddIcon />
      </ButtonLoad>
      {reservations.map((reservation) => {
        return (
          <WorkQueueCardRenting
            key={reservation.id}
            reservation={reservation}
            type="return"
          />
        );
      })}
      {openDialog && (
        <DialogReservationReturn
          open={openDialog}
          setOpen={setOpenDialog}
          reservations={reservationsReturnPre}
          locationIdSelected={locationIdSelected}
        />
      )}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    conWorkQueue: {
      position: "relative",
    },
    btn: {
      position: "absolute",
      top: "0.5rem",
      right: "0.5rem",
    },
  });

export default withStyles(styles)(WorkQueueRentingCustomerReturn);
