import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  InspectionReturnCategoryType,
  InspectionReturnQuestionAnswerType,
  InspectionReturnQuestionType,
  InspectionReturnTagType,
} from "../../entity/types";
import CreateInspectionReturnQuestion from "./CreateInspectionReturnQuestion";
import InspectionReturnQuestion from "./InspectionReturnQuestion";
import InspectionReturnQuestionOptions from "./InspectionReturnQuestionOptions";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionReturnCategory: InspectionReturnCategoryType;
  inspectionReturnQuestions: InspectionReturnQuestionType[];
  inspectionReturnTags: InspectionReturnTagType[];
}

function InspectionReturnCategoryQuestions({
  classes,
  inspectionReturnCategory,
  inspectionReturnQuestions,
  inspectionReturnTags,
}: Props) {
  return (
    <>
      <tbody>
        {inspectionReturnQuestions.map((inspectionReturnQuestion) => (
          <React.Fragment key={inspectionReturnQuestion.id}>
            <InspectionReturnQuestion
              inspectionReturnTags={inspectionReturnTags}
              inspectionReturnQuestion={inspectionReturnQuestion}
            />
            {inspectionReturnQuestion.answerType ===
              InspectionReturnQuestionAnswerType.Options && (
              <InspectionReturnQuestionOptions
                inspectionReturnQuestion={inspectionReturnQuestion}
              />
            )}
          </React.Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td>
            <CreateInspectionReturnQuestion
              inspectionReturnCategory={inspectionReturnCategory}
            />
          </td>
        </tr>
      </tfoot>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InspectionReturnCategoryQuestions);
