import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineType,
  QueryMaintenanceSettingsForMachineArgs,
} from "../../entity/types";
import { useQuery } from "@apollo/client";
import Error from "../Shared/Error";
import { MaintenanceSettingTypeEmpty } from "../../entity/empties";
import { WithStyles } from "@material-ui/core/styles";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import {
  GET_MAINTENANCE_SETTINGS_FOR_MACHINE_QUERY,
  QueryResultMaintenanceSettingsForMachine,
} from "../../apollo/queries/maintenances";
import LoadingDialog from "../Shared/LoadingDialog";
import DialogMaintenanceSettings from "./DialogMaintenanceSettings";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  machine: MachineType;
}

function MaintenanceSettingsMachine({
  classes,
  open,
  setOpen,
  machine,
}: Props) {
  const [settingsEdit, setSettingsEdit] = useState(MaintenanceSettingTypeEmpty);

  const { loading, error, data } = useQuery<
    QueryResultMaintenanceSettingsForMachine,
    QueryMaintenanceSettingsForMachineArgs
  >(GET_MAINTENANCE_SETTINGS_FOR_MACHINE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("maintenanceSettingsForMachine"),
    variables: {
      machineId: machine.id,
    },
  });

  useEffect(() => {
    if (data?.maintenanceSettingsForMachine) {
      setSettingsEdit(data.maintenanceSettingsForMachine);
    }
  }, [data]);

  if (loading) return <LoadingDialog />;
  if (error) return <Error error={error} />;

  return (
    <DialogMaintenanceSettings
      open={open}
      setOpen={setOpen}
      settingsEdit={settingsEdit}
      setSettingsEdit={setSettingsEdit}
      machine={machine}
    />
  );
}

const styles = ({ palette }: Theme) => createStyles({});

export default withStyles(styles)(MaintenanceSettingsMachine);
