import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles } from "@material-ui/core/styles";
import { Button, Col, Row } from "react-bootstrap";
import TextFieldFocus from "../Shared/TextFieldFocus";
import { parseNumber } from "../../utils/formatting";
import { useMutation, useQuery } from "@apollo/client";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import {
  GET_COMMISSION_REPORT_FOR_MONTH_QUERY,
  QueryResultCommissionReportForMonth,
} from "../../apollo/queries/commissions";
import {
  Mutation,
  MutationCreateCommissionReportsArgs,
  MutationGetCommissionReportPdfArgs,
  QueryCommissionReportForMonthArgs,
  UserType,
} from "../../entity/types";
import Error from "../Shared/Error";
import LoadingSimple from "../Shared/LoadingSimple";
import { findFromSetById } from "../../utils/collections";
import { UserEmpty } from "../../entity/empties";
import CommissionPreviewForUser from "./CommissionPreviewForUser";
import { handleError } from "../../entity/ErrorHandler";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import {
  CREATE_COMMISSION_REPORTS_MUTATION,
  GET_COMMISSION_REPORT_PDF_MUTATION,
  MutationResultCommissionReportPdf,
} from "../../apollo/mutations/commissions";
import CommissionReport from "./CommissionReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons";
import { popNewWindow } from "../../utils/dialogs";
import ButtonLoad from "../Shared/ButtonLoad";
import { CommissionUserSettingsByUserType } from "../../pages/ManagementCommissions";

interface Props extends WithStyles<typeof styles> {
  users: UserType[];
  commissionUserSettingsByUser: CommissionUserSettingsByUserType;
}

function CommissionReportArchive({
  classes,
  users,
  commissionUserSettingsByUser,
}: Props) {
  const { t } = useTranslation();

  const yearCurrent = new Date().getFullYear();
  const [year, setYear] = useState<number>(yearCurrent);
  const [yearEdited, setYearEdited] = useState<number>(yearCurrent);

  const monthDefault = new Date().getMonth();
  const [month, setMonth] = useState<number>(monthDefault);
  const [monthEdited, setMonthEdited] = useState<number>(monthDefault);

  const [userIdsSelected, setUserIdsSelected] = useState<string[]>([]);

  const {
    loading: loadingReports,
    error: errorReports,
    data: dataReports,
  } = useQuery<
    QueryResultCommissionReportForMonth,
    QueryCommissionReportForMonthArgs
  >(GET_COMMISSION_REPORT_FOR_MONTH_QUERY, {
    variables: {
      year: year,
      month: month,
      userIds: userIdsSelected,
    },
    fetchPolicy: getQueryFetchPolicy("commissionReportForMonth"),
  });

  const [createCommissionReports, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateCommissionReportsArgs
  >(CREATE_COMMISSION_REPORTS_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("commissionReportForMonth"),
      });
    },
  });

  const [getCommissionReportPdf, { loading: loadingGetCommissionReportPdf }] =
    useMutation<
      MutationResultCommissionReportPdf,
      MutationGetCommissionReportPdfArgs
    >(GET_COMMISSION_REPORT_PDF_MUTATION, {
      onError: (error) => {
        handleError(error);
      },
      onCompleted: (result) => {
        popNewWindow(t, String(result.getCommissionReportPdf?.url));
      },
    });

  if (loadingReports || loadingCreate) return <LoadingSimple />;
  if (errorReports) return <Error error={errorReports} />;
  if (!dataReports) return <Error error={t("error_query_failed")} />;

  const onClickCreateReports = (userIds: string[]) => {
    createCommissionReports({
      variables: {
        year: year,
        month: month,
        userIds: userIds,
      },
    });
  };

  const onClickCreateCommissionReport = () => {
    getCommissionReportPdf({
      variables: {
        year: year,
        month: month,
        userIds: userIdsSelected,
      },
    });
  };

  const dateNow = new Date();
  const dateFrom = new Date(year, month - 1, 1);
  const dateTo = new Date(year, month, 0);
  let userIdsNoReport: string[] = [];

  const commissionReports = dataReports.commissionReportForMonth
    ? dataReports.commissionReportForMonth
    : [];

  return (
    <div>
      <Row>
        <Col sm={4} md={4} lg={3}>
          <FormControl fullWidth>
            <TextFieldFocus
              type="number"
              label={t("year")}
              value={yearEdited}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ min: 2020, max: yearCurrent }}
              onChange={(event) => {
                setYearEdited(parseNumber(event.target.value));

                // Focus not set in some browsers when pressing up-down buttons in numeric input. Needed for blur!
                event.target.focus();
              }}
              onBlur={(event) => {
                setYear(parseNumber(event.target.value));
              }}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={3}>
          <FormControl fullWidth>
            <TextFieldFocus
              type="number"
              label={t("month")}
              value={monthEdited}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ min: 1, max: 12 }}
              onChange={(event) => {
                setMonthEdited(parseNumber(event.target.value));

                // Focus not set in some browsers when pressing up-down buttons in numeric input. Needed for blur!
                event.target.focus();
              }}
              onBlur={(event) => {
                setMonth(parseNumber(event.target.value));
              }}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="lblUserListDataType">{t("user")}</InputLabel>
            <Select
              autoWidth
              labelId="lblUserListFilter"
              value={userIdsSelected}
              multiple={true}
              onChange={(event) => {
                setUserIdsSelected(event.target.value as string[]);
              }}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.firstName + " " + user.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
        {userIdsSelected.length > 0 && commissionReports.length > 0 && (
          <Col sm={4} md={4} lg={3}>
            <ButtonLoad
              loading={loadingGetCommissionReportPdf}
              variant="light"
              onClick={onClickCreateCommissionReport}
            >
              <FontAwesomeIcon className="me-2" icon={faFilePdf} />{" "}
              {t("create_pdf")}
            </ButtonLoad>
          </Col>
        )}
      </Row>
      {dateTo > dateNow && (
        <p className="text-danger mt-4">{t("commission_report_in_future")}</p>
      )}
      {dateTo <= dateNow && (
        <div>
          {userIdsSelected.map((userId) => {
            const commissionReportsForUser = commissionReports.filter(
              (commissionReport) => commissionReport.user.id === userId
            );
            const user: UserType = findFromSetById(userId, users, UserEmpty);

            if (commissionReportsForUser.length === 0) {
              userIdsNoReport.push(userId);
            }

            return (
              <div key={userId}>
                {commissionReportsForUser.length === 0 && (
                  <div>
                    <div className="mt-4 text-muted">
                      {t("commission_report_not_created")}
                    </div>
                    <CommissionPreviewForUser
                      key={userId}
                      userSelected={user}
                      commissionUserSetting={
                        commissionUserSettingsByUser[user.id]
                      }
                      dateFrom={dateFrom}
                      dateTo={dateTo}
                    />
                  </div>
                )}
                {commissionReportsForUser.map((commissionReport) => (
                  <CommissionReport
                    key={commissionReport.id}
                    commissionReport={commissionReport}
                    user={user}
                    commissionUserSetting={
                      commissionUserSettingsByUser[user.id]
                    }
                  />
                ))}
              </div>
            );
          })}
        </div>
      )}
      {userIdsNoReport.length > 0 && (
        <div>
          <Button
            className="me-1"
            onClick={() => onClickCreateReports(userIdsNoReport)}
          >
            {t("create_commission_reports")}
          </Button>
        </div>
      )}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    total: {
      fontSize: "1rem",
    },
  });

export default withStyles(styles)(CommissionReportArchive);
