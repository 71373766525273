import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Paper, Theme } from "@material-ui/core";
import { OrderType, QueryOrderTotalArgs } from "../../entity/types";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import {
  GET_ORDER_TOTAL_QUERY,
  QueryResultOrderTotal,
} from "../../apollo/queries/orders";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { Table } from "react-bootstrap";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { SettingsContext } from "../../Root";

interface Props extends WithStyles<typeof styles> {
  order: OrderType;
}

function OrderPrice({ classes, order }: Props) {
  const { t } = useTranslation();

  const settings = useContext(SettingsContext);

  const { loading, error, data } = useQuery<
    QueryResultOrderTotal,
    QueryOrderTotalArgs
  >(GET_ORDER_TOTAL_QUERY, {
    fetchPolicy: getQueryFetchPolicy("orderTotal"),
    variables: {
      orderId: order.id,
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const total = data.orderTotal
    ? data.orderTotal.reduce((sum, row) => sum + parseNumber(row.amount), 0)
    : 0;

  return (
    <div className="mt-3">
      <Paper className={classes.paper} variant="outlined">
        <Table className="m-0" size="sm" borderless>
          <tbody>
            {(data.orderTotal ? data.orderTotal : []).map((row, i) => (
              <tr key={i}>
                <td>{row.title}</td>
                <td className={classes.alignRight}>
                  {t("currency", {
                    amount: formatNumber(row.amount, 2),
                  })}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>{t("total_vat0")}</th>
              <th className={classes.alignRight}>
                {t("currency", {
                  amount: formatNumber(total, 2),
                })}
              </th>
            </tr>
            <tr>
              <th>{t("total_vat1", { vat: formatNumber(settings.vat) })}</th>
              <th className={classes.alignRight}>
                {t("currency", {
                  amount: formatNumber(total * settings.vatFactor, 2),
                })}
              </th>
            </tr>
          </tfoot>
        </Table>
      </Paper>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: "0.5rem",
    },
    alignRight: {
      textAlign: "right",
    },
  });

export default withStyles(styles)(OrderPrice);
