import { gql } from "@apollo/client";
import { Mutation } from "../../entity/types";

export interface MutationResultUpdateMaintenanceSettings {
  updateMaintenanceSettings: Mutation["updateMaintenanceSettings"];
}
export const UPDATE_MAINTENANCE_SETTINGS_MUTATION = gql`
  mutation updateMaintenanceSetting(
    $maintenanceSettingId: ID!
    $scheduleType: String!
    $estimatedHoursPerDay: Decimal!
    $startDate: Date!
  ) {
    updateMaintenanceSettings(
      maintenanceSettingId: $maintenanceSettingId
      scheduleType: $scheduleType
      estimatedHoursPerDay: $estimatedHoursPerDay
      startDate: $startDate
    ) {
      maintenanceSettings {
        id
        machine {
          id
        }
        catalogExtraRowRental {
          id
        }
        scheduleType
        estimatedHoursPerDay
        startDate
      }
    }
  }
`;

export interface MutationResultCreateMaintenanceInterval {
  createMaintenanceInterval: Mutation["createMaintenanceInterval"];
}
export const CREATE_MAINTENANCE_INTERVAL_MUTATION = gql`
  mutation createMaintenanceInterval(
    $maintenanceSettingId: ID!
    $hours: Int!
    $days: Int!
    $description: String!
  ) {
    createMaintenanceInterval(
      maintenanceSettingId: $maintenanceSettingId
      hours: $hours
      days: $days
      description: $description
    ) {
      maintenanceInterval {
        id
        hours
        days
        description
      }
    }
  }
`;

export interface MutationResultUpdateMaintenanceInterval {
  updateMaintenanceInterval: Mutation["updateMaintenanceInterval"];
}
export const UPDATE_MAINTENANCE_INTERVAL_MUTATION = gql`
  mutation updateMaintenanceInterval(
    $maintenanceIntervalId: ID!
    $hours: Int!
    $days: Int!
    $description: String!
  ) {
    updateMaintenanceInterval(
      maintenanceIntervalId: $maintenanceIntervalId
      hours: $hours
      days: $days
      description: $description
    ) {
      maintenanceInterval {
        id
        hours
        days
        description
      }
    }
  }
`;

export const DELETE_MAINTENANCE_INTERVAL_MUTATION = gql`
  mutation deleteMaintenanceInterval($maintenanceIntervalId: ID!) {
    deleteMaintenanceInterval(maintenanceIntervalId: $maintenanceIntervalId) {
      maintenanceIntervalId
    }
  }
`;

export interface MutationResultCreateMaintenance {
  createMaintenance: Mutation["createMaintenance"];
}
export const CREATE_MAINTENANCE_MUTATION = gql`
  mutation CreateMaintenance(
    $machineId: ID
    $catalogExtraRowRentalId: ID
    $maintenanceIntervalId: ID!
    $estimatedDate: Date!
  ) {
    createMaintenance(
      machineId: $machineId
      catalogExtraRowRentalId: $catalogExtraRowRentalId
      maintenanceIntervalId: $maintenanceIntervalId
      estimatedDate: $estimatedDate
    ) {
      maintenance {
        id
        maintenanceType
        machine {
          id
        }
        catalogExtraRowRental {
          id
        }
        estimatedDate
      }
    }
  }
`;

export interface MutationResultUpdateMaintenance {
  updateMaintenance: Mutation["updateMaintenance"];
}
export const UPDATE_MAINTENANCE_MUTATION = gql`
  mutation UpdateMaintenance(
    $maintenanceId: ID!
    $serviceTechnicianId: ID
    $serviceStartBy: Date
    $serviceEndBy: Date
    $servicedAt: DateTime
    $verifiedAt: DateTime
  ) {
    updateMaintenance(
      maintenanceId: $maintenanceId
      serviceTechnicianId: $serviceTechnicianId
      serviceStartBy: $serviceStartBy
      serviceEndBy: $serviceEndBy
      servicedAt: $servicedAt
      verifiedAt: $verifiedAt
    ) {
      maintenance {
        id
        machine {
          id
        }
        catalogExtraRowRental {
          id
        }
        serviceStartBy
        serviceEndBy
      }
    }
  }
`;

export const DELETE_MAINTENANCE_MUTATION = gql`
  mutation DeleteMaintenance($maintenanceId: ID!) {
    deleteMaintenance(maintenanceId: $maintenanceId) {
      maintenanceId
    }
  }
`;

export interface MutationResultCreateMaintenanceComment {
  createMaintenanceComment: Mutation["createMaintenanceComment"];
}
export const CREATE_MAINTENANCE_COMMENT = gql`
  mutation CreateComment($maintenanceId: ID!, $comment: String!) {
    createMaintenanceComment(maintenanceId: $maintenanceId, comment: $comment) {
      maintenanceComment {
        id
        maintenance {
          id
        }
        comment
      }
    }
  }
`;

export const DELETE_MAINTENANCE_COMMENT = gql`
  mutation DeleteComment($maintenanceCommentId: ID!) {
    deleteMaintenanceComment(maintenanceCommentId: $maintenanceCommentId) {
      maintenanceCommentId
    }
  }
`;

export const UPLOAD_MAINTENANCE_IMAGE_MUTATION = gql`
  mutation uploadMaintenanceImage($maintenanceAlbumId: ID!, $file: Upload!) {
    uploadMaintenanceImage(
      maintenanceAlbumId: $maintenanceAlbumId
      file: $file
    ) {
      maintenanceImage {
        id
      }
    }
  }
`;

export interface MutationResultUpdateMaintenanceImage {
  updateMaintenanceImage: Mutation["updateMaintenanceImage"];
}
export const UPDATE_MAINTENANCE_IMAGE_MUTATION = gql`
  mutation updateMaintenanceImage(
    $maintenanceImageId: ID!
    $imageType: String!
    $eurosWarranty: Decimal!
    $eurosOwnCosts: Decimal!
    $information: String!
  ) {
    updateMaintenanceImage(
      maintenanceImageId: $maintenanceImageId
      imageType: $imageType
      eurosWarranty: $eurosWarranty
      eurosOwnCosts: $eurosOwnCosts
      information: $information
    ) {
      maintenanceImage {
        id
      }
    }
  }
`;

export const DELETE_MAINTENANCE_IMAGE_MUTATION = gql`
  mutation deleteMaintenanceImage($maintenanceImageId: ID!) {
    deleteMaintenanceImage(maintenanceImageId: $maintenanceImageId) {
      maintenanceImageId
    }
  }
`;
