import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { useQuery } from "@apollo/client";
import {
  MachineType,
  QueryInvoicingPriceForReservationArgs,
  QueryOperationHoursForMachineArgs,
  ReservationType,
} from "../../entity/types";
import Error from "../Shared/Error";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import {
  GET_OPERATION_HOURS_FOR_MACHINE_QUERY,
  QueryResultOperationHoursForMachine,
} from "../../apollo/queries/operation_hours";
import { getMachineName } from "../../utils/machines/machine";
import { OperationHourEmpty } from "../../entity/empties";
import { format } from "date-fns";
import { DATE_FORMAT_ISO } from "../../utils/constants";
import {
  GET_INVOICING_PRICE_FOR_RESERVATION_QUERY,
  QueryResultInvoicingPriceForReservation,
} from "../../apollo/queries/reservations";
import DialogContentOperationHours from "./DialogContentOperationHours";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reservation: ReservationType;
  machine: MachineType;
}

function DialogOperationHours({
  classes,
  open,
  setOpen,
  reservation,
  machine,
}: Props) {
  const { t } = useTranslation();

  const dateCheckedDefault = format(new Date(), DATE_FORMAT_ISO);
  const [operationHourNew, setOperationHourNew] = useState({
    ...OperationHourEmpty,
    dateChecked: dateCheckedDefault,
  });

  const {
    loading: loadingHours,
    error: errorHours,
    data: dataHours,
  } = useQuery<
    QueryResultOperationHoursForMachine,
    QueryOperationHoursForMachineArgs
  >(GET_OPERATION_HOURS_FOR_MACHINE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("operationHoursForMachine"),
    variables: {
      machineId: machine.id,
      reservationId: reservation.id,
    },
  });

  const {
    loading: loadingPrice,
    error: errorPrice,
    data: dataPrice,
  } = useQuery<
    QueryResultInvoicingPriceForReservation,
    QueryInvoicingPriceForReservationArgs
  >(GET_INVOICING_PRICE_FOR_RESERVATION_QUERY, {
    fetchPolicy: getQueryFetchPolicy("invoicingPriceForReservation"),
    variables: {
      reservationId: reservation.id,
      date: reservation.dateBillingMin
        ? reservation.dateBillingMin
        : reservation.dateReturned,
    },
  });

  const hoursMin =
    dataHours?.operationHoursForMachine && dataHours.operationHoursForMachine[0]
      ? dataHours.operationHoursForMachine[0].hours
      : 0;
  const checkHourMin = () => {
    if (operationHourNew.hours < hoursMin) {
      setOperationHourNew({
        ...operationHourNew,
        hours: hoursMin,
      });
    }
  };

  useEffect(() => {
    checkHourMin();
    // eslint-disable-next-line
  }, [hoursMin]);

  if (errorHours) return <Error error={errorHours} />;
  if (errorPrice) return <Error error={errorPrice} />;
  if ((!loadingHours && !dataHours) || (!loadingPrice && !dataPrice)) {
    return <Error error={t("error_query_failed")} />;
  }

  if (reservation.catalogExtraRowRental) {
    return <Error error={t("no_operation_hours_for_catalog_extra_row")} />;
  }

  const onClickClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitleWithClose
        id="dialogTitleCatalogExtraReservation"
        onClose={onClickClose}
      >
        {t("operation_hours")}
        <small className="text-muted ms-2">{getMachineName(machine)}</small>
      </DialogTitleWithClose>
      <DialogContentOperationHours
        reservation={reservation}
        machine={machine}
      />
    </Dialog>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    notInvoiceable: {
      textDecoration: "line-through !important",
    },
    cell: {
      paddingRight: "2rem !important",
    },
  });

export default withStyles(styles)(DialogOperationHours);
