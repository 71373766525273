import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { WithStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import {
  GET_COMMISSIONS_FOR_ORDER_QUERY,
  QueryResultCommissionsForOrder,
} from "../../apollo/queries/commissions";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { QueryCommissionsForOrderArgs } from "../../entity/types";

interface Props extends WithStyles<typeof styles> {
  orderId: string;
}

function CommissionsForOrder({ classes, orderId }: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultCommissionsForOrder,
    QueryCommissionsForOrderArgs
  >(GET_COMMISSIONS_FOR_ORDER_QUERY, {
    fetchPolicy: getQueryFetchPolicy("commissionTemplates"),
    variables: {
      orderId: orderId,
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <div>
      {data.commissionsForOrder?.map((commission) => (
        <div key={commission.id} className="mb-3 me-3 d-inline-block">
          <div className="fw-bold">
            {t("commission_CASE_" + commission.case)}
          </div>
          {commission.userSeller.lastName} {commission.userSeller.firstName}
        </div>
      ))}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CommissionsForOrder);
