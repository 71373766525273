import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { CustomerType } from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import { useQuery } from "@apollo/client";
import {
  GET_CUSTOMERS_PRIMARY_QUERY,
  QueryResultCustomersPrimary,
} from "../../apollo/queries/customers";
import Error from "../Shared/Error";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { CustomerEmpty } from "../../entity/empties";
import { Button } from "react-bootstrap";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { Autocomplete } from "@material-ui/lab";
import { ID_EMPTY } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  handleNext: () => void;
  setOpenSelect: React.Dispatch<React.SetStateAction<boolean>>;
  setParent: (id: string) => void;
}

function DialogCustomerSelectType({
  classes,
  open,
  handleNext,
  setOpenSelect,
  setParent,
}: Props) {
  const { t } = useTranslation();

  const [isParent, setIsParent] = useState(true);

  const { loading, error, data } = useQuery<QueryResultCustomersPrimary>(
    GET_CUSTOMERS_PRIMARY_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("customersPrimary"),
    }
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "customers.add_customer",
  ]);

  if (!loading && error) return <Error error={error} />;
  if (!loading && !data) return <Error error={t("error_query_failed")} />;

  const getLabel = (option: CustomerType) => {
    return option.businessId + " " + option.name;
  };

  const customersParent: CustomerType[] =
    data && data.customersPrimary ? data.customersPrimary : [];

  return (
    <Dialog open={open}>
      <DialogTitle>{t("select_customer_type")}</DialogTitle>
      <DialogContent className={loading ? "loading" : ""}>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue="top"
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={t("customer_family_head")}
              checked={Boolean(isParent) === true}
              onChange={() => {
                setIsParent(true);
                setParent(ID_EMPTY);
              }}
            />
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label={t("customer_family_child")}
              checked={Boolean(isParent) === false}
              onChange={() => {
                setIsParent(false);
              }}
            />
          </RadioGroup>
        </FormControl>
        {!isParent && (
          <>
            <h6 id="lblCustomerParent">{t("customer_family_head")}</h6>
            <FormControl fullWidth>
              <Autocomplete
                className="mt-2 mb-2 flex-grow-1"
                options={customersParent}
                disabled={!hasPermissionEdit}
                filterOptions={(options, search) => {
                  const keyWords = search.inputValue.split(" ");
                  return options.filter((option) => {
                    return keyWords.every((item) => {
                      return getLabel(option)
                        .toLowerCase()
                        .includes(item.toLowerCase());
                    });
                  });
                }}
                getOptionLabel={(option: CustomerType) => getLabel(option)}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                getOptionSelected={(a, b) => {
                  return a.id === b.id;
                }}
                onChange={(event, customerSelected) => {
                  const customerParent = customerSelected
                    ? customerSelected
                    : CustomerEmpty;

                  setParent(customerParent.id);
                }}
              />
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNext} variant="primary">
          {t("next")}
        </Button>
        <Button onClick={() => setOpenSelect(false)} variant="secondary">
          {t("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCustomerSelectType);
