import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { Table } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import {
  QueryCatalogExtraRowsInvoiceReservationArgs,
  ReservationType,
} from "../../entity/types";
import {
  GET_CATALOG_EXTRA_ROWS_INVOICE_RESERVATION_QUERY,
  QueryResultCatalogExtraRowsInvoiceReservation,
} from "../../apollo/queries/catalogs_extra";
import Error from "../Shared/Error";
import { getCatalogExtraRowInvoiceTitle } from "../../utils/catalog_extra/catalog_extra";
import { formatNumber } from "../../utils/formatting";
import LoadingSimple from "../Shared/LoadingSimple";
import { WithStyles } from "@material-ui/core/styles";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationType;
}

function CatalogExtraRowsInvoiceReport({ classes, reservation }: Props) {
  const { t } = useTranslation();

  const {
    loading: loadingRowsInvoice,
    error: errorRowsInvoice,
    data: dataRowsInvoice,
  } = useQuery<
    QueryResultCatalogExtraRowsInvoiceReservation,
    QueryCatalogExtraRowsInvoiceReservationArgs
  >(GET_CATALOG_EXTRA_ROWS_INVOICE_RESERVATION_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalogExtraRowsInvoiceReservation"),
    variables: {
      reservationId: reservation.id,
    },
  });

  if (errorRowsInvoice) return <Error error={errorRowsInvoice} />;
  if (loadingRowsInvoice) return <LoadingSimple />;
  if (!dataRowsInvoice) return <Error error={t("error_query_failed")} />;

  return (
    <Table className="w-auto mt-3" borderless>
      <thead>
        <tr>
          <th>{t("catalog_extra_unit_price")}</th>
          <th>{t("quantity")}</th>
          <th>{t("unit_price")}</th>
        </tr>
      </thead>
      <tbody>
        {dataRowsInvoice.catalogExtraRowsInvoiceReservation?.map(
          (catalogExtraRowInvoice) => (
            <tr key={catalogExtraRowInvoice.id}>
              <td>{getCatalogExtraRowInvoiceTitle(catalogExtraRowInvoice)}</td>
              <td align="right">
                {formatNumber(catalogExtraRowInvoice.quantity, 2)}
              </td>
              <td align="right">
                {catalogExtraRowInvoice.catalogExtraRowUnitPrice
                  ? formatNumber(
                      catalogExtraRowInvoice.catalogExtraRowUnitPrice.unitPrice,
                      2
                    )
                  : ""}
                {!catalogExtraRowInvoice.catalogExtraRowUnitPrice
                  ? formatNumber(catalogExtraRowInvoice.unitPriceOverride, 2)
                  : ""}
              </td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CatalogExtraRowsInvoiceReport);
