import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  CommissionTemplateType,
  CommissionUserSettingType,
  Mutation,
  MutationUpdateCommissionUserSettingArgs,
} from "../../entity/types";
import { UPDATE_COMMISSION_USER_SETTING_MUTATION } from "../../apollo/mutations/commissions";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { WithStyles } from "@material-ui/core/styles";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import SelectRequired from "../Shared/SelectRequired";
import { ID_EMPTY } from "../../utils/constants";
import { CommissionTemplateEmpty } from "../../entity/empties";

interface Props extends WithStyles<typeof styles> {
  commissionUserSetting: CommissionUserSettingType;
  commissionTemplates: CommissionTemplateType[];
}

function CommissionUserSettings({
  classes,
  commissionUserSetting,
  commissionTemplates,
}: Props) {
  const { t } = useTranslation();

  const [commissionUserSettingEdit, setCommissionUserSettingEdit] = useState({
    ...commissionUserSetting,
    showCommissions: commissionUserSetting.showCommissions,
  });

  const [updateCommissionUserSetting, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCommissionUserSettingArgs
  >(UPDATE_COMMISSION_USER_SETTING_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "commissions.change_commissionusersetting",
  ]);

  const handleUpdate = (values: {
    [key: string]: string | number | boolean;
  }) => {
    const base: MutationUpdateCommissionUserSettingArgs = {
      commissionUserSettingId: commissionUserSettingEdit.id,
      commissionTemplateId: commissionUserSettingEdit.commissionTemplate
        ? commissionUserSettingEdit.commissionTemplate.id
        : ID_EMPTY,
      showCommissions: commissionUserSettingEdit.showCommissions,
    };

    updateCommissionUserSetting({
      variables: { ...base, ...values },
    });
  };

  return (
    <div className={`mb-5 ${loadingUpdate ? "loading" : ""}`}>
      <div className="mb-2">
        <FormControlLabel
          className="pt-2"
          label={t("commission_settings_for_user_enabled")}
          control={
            <Checkbox
              checked={commissionUserSettingEdit.showCommissions}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setCommissionUserSettingEdit({
                  ...commissionUserSettingEdit,
                  showCommissions: event.target.checked,
                });
                handleUpdate({
                  showCommissions: event.target.checked,
                });
              }}
            />
          }
        />
      </div>
      <div>
        {commissionUserSettingEdit.showCommissions && (
          <div className="mb-2">
            <FormControl>
              <InputLabel id="lblCommissionTemplateForUser">
                {t("commission_template")}
              </InputLabel>
              <SelectRequired
                className={classes.ddl}
                labelId="lblCommissionTemplateForUser"
                disabled={!hasPermissionEdit}
                value={
                  commissionUserSettingEdit.commissionTemplate
                    ? commissionUserSettingEdit.commissionTemplate.id
                    : ID_EMPTY
                }
                onChange={(event) => {
                  const commissionTemplateNew =
                    commissionUserSettingEdit.commissionTemplate
                      ? commissionUserSettingEdit.commissionTemplate
                      : CommissionTemplateEmpty;
                  const idNew = String(event.target.value);

                  setCommissionUserSettingEdit({
                    ...commissionUserSettingEdit,
                    commissionTemplate: {
                      ...commissionTemplateNew,
                      id: idNew,
                    },
                  });

                  handleUpdate({
                    commissionTemplateId: idNew,
                  });
                }}
              >
                <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
                {commissionTemplates.map((commissionTemplate) => (
                  <MenuItem
                    key={commissionTemplate.id}
                    value={commissionTemplate.id}
                  >
                    {commissionTemplate.name}
                  </MenuItem>
                ))}
              </SelectRequired>
            </FormControl>
          </div>
        )}
      </div>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    ddl: {
      minWidth: "10rem",
    },
  });

export default withStyles(styles)(CommissionUserSettings);
