import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomerList from "../components/Customer/CustomerList";
import CreateCustomer from "../components/Customer/CreateCustomer";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { createStyles, Theme } from "@material-ui/core";
import {
  GET_CUSTOMERS_FOR_LIST_QUERY,
  QueryResultCustomersForList,
} from "../apollo/queries/customers";
import { useQuery } from "@apollo/client";
import { Container } from "react-bootstrap";
import { QueryCustomersArgs } from "../entity/types";
import { useTranslation } from "react-i18next";
import { CONTAINER_MAIN_WIDTH_XL } from "../utils/constants";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {}

function Customers({ classes }: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultCustomersForList,
    QueryCustomersArgs
  >(GET_CUSTOMERS_FOR_LIST_QUERY, {
    fetchPolicy: getQueryFetchPolicy("customers"),
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <Container fluid className={`containerMain ${classes.container}`}>
      <CreateCustomer btnClass="addButton" />
      <CustomerList customers={data.customers ? data.customers : []} />
    </Container>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: CONTAINER_MAIN_WIDTH_XL,
    },
  });

export default withStyles(styles)(Customers);
