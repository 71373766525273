import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  MachineInfoQuestionAnswerType,
  MachineInfoQuestionType,
  MachineInfoTagType,
  Mutation,
  MutationDeleteMachineInfoQuestionArgs,
  MutationUpdateMachineInfoQuestionArgs,
} from "../../entity/types";
import ButtonLoad from "../Shared/ButtonLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import {
  DELETE_MACHINE_INFO_QUESTION_MUTATION,
  UPDATE_MACHINE_INFO_QUESTION_MUTATION,
} from "../../apollo/mutations/machines";
import { GET_MACHINE_INFO_CATEGORIES_QUERY } from "../../apollo/queries/machines";
import { handleError } from "../../entity/ErrorHandler";
import MachineInformationQuestionTags from "./MachineInformationQuestionTags";
import { dialogConfirm } from "../../utils/dialogs";
import SelectRequired from "../Shared/SelectRequired";

interface Props extends WithStyles<typeof styles> {
  machineInfoQuestion: MachineInfoQuestionType;
  machineInfoTags: MachineInfoTagType[];
}

function MachineInformationQuestion({
  classes,
  machineInfoQuestion,
  machineInfoTags,
}: Props) {
  const { t } = useTranslation();

  const [machineInfoQuestionEdited, setMachineInfoQuestionEdited] =
    useState(machineInfoQuestion);

  const [updateMachineInfoQuestion, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateMachineInfoQuestionArgs
  >(UPDATE_MACHINE_INFO_QUESTION_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const [deleteMachineInfoQuestion, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteMachineInfoQuestionArgs
  >(DELETE_MACHINE_INFO_QUESTION_MUTATION, {
    refetchQueries: [{ query: GET_MACHINE_INFO_CATEGORIES_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditQuestion = checkPermission(myPermissions, [
    "machines.change_machineinfoquestion",
  ]);
  const hasPermissionDeleteQuestion = checkPermission(myPermissions, [
    "machines.delete_machineinfoquestion",
  ]);

  const handleDeleteMachineInfoQuestion = (machineInfoQuestionId: string) => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteMachineInfoQuestion({
        variables: { machineInfoQuestionId: machineInfoQuestionId },
      });
    });
  };

  const handleUpdateMachineInfoQuestion = (
    values: {
      [key: string]: string | number | boolean;
    } = {}
  ) => {
    let base: MutationUpdateMachineInfoQuestionArgs = {
      machineInfoQuestionId: machineInfoQuestionEdited.id,
      machineInfoCategoryId: machineInfoQuestionEdited.machineInfoCategory.id,
      question: machineInfoQuestionEdited.question,
      answerType: machineInfoQuestionEdited.answerType,
      showProductCard: machineInfoQuestionEdited.showProductCard,
      showDrive: machineInfoQuestionEdited.showDrive,
    };

    updateMachineInfoQuestion({
      variables: { ...base, ...values },
    });
  };

  return (
    <tr
      key={machineInfoQuestion.id}
      className={`${classes.rowQuestion} ${loadingUpdate ? "loading" : ""}`}
    >
      <td className={classes.cellQuestion}>
        <FormControl fullWidth>
          <TextField
            onChange={(event) => {
              setMachineInfoQuestionEdited({
                ...machineInfoQuestionEdited,
                question: event.target.value,
              });
            }}
            value={machineInfoQuestionEdited.question}
            disabled={!hasPermissionEditQuestion}
            inputProps={{ maxLength: 100 }}
            onBlur={() => handleUpdateMachineInfoQuestion()}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <Select
            value={machineInfoQuestionEdited.answerType}
            disabled={!hasPermissionEditQuestion}
            onChange={(event) => {
              const answerType = event.target
                .value as MachineInfoQuestionAnswerType;

              setMachineInfoQuestionEdited({
                ...machineInfoQuestionEdited,
                answerType: answerType,
              });

              handleUpdateMachineInfoQuestion({
                answerType: answerType,
              });
            }}
          >
            <MenuItem value={MachineInfoQuestionAnswerType.Text}>
              {t("answer_type_" + MachineInfoQuestionAnswerType.Text)}
            </MenuItem>
            <MenuItem value={MachineInfoQuestionAnswerType.Options}>
              {t("answer_type_" + MachineInfoQuestionAnswerType.Options)}
            </MenuItem>
          </Select>
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <SelectRequired
            autoWidth
            value={machineInfoQuestionEdited.showProductCard ? 1 : 0}
            disabled={!hasPermissionEditQuestion}
            onChange={(event) => {
              const showProductCard = Boolean(event.target.value);

              setMachineInfoQuestionEdited({
                ...machineInfoQuestionEdited,
                showProductCard: showProductCard,
              });

              handleUpdateMachineInfoQuestion({
                showProductCard: showProductCard,
              });
            }}
          >
            <MenuItem value={1}>{t("yes")}</MenuItem>
            <MenuItem value={0}>{t("no")}</MenuItem>
          </SelectRequired>
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <SelectRequired
            autoWidth
            value={machineInfoQuestionEdited.showDrive ? 1 : 0}
            disabled={!hasPermissionEditQuestion}
            onChange={(event) => {
              const showDrive = Boolean(event.target.value);

              setMachineInfoQuestionEdited({
                ...machineInfoQuestionEdited,
                showDrive: showDrive,
              });

              handleUpdateMachineInfoQuestion({
                showDrive: showDrive,
              });
            }}
          >
            <MenuItem value={1}>{t("yes")}</MenuItem>
            <MenuItem value={0}>{t("no")}</MenuItem>
          </SelectRequired>
        </FormControl>
      </td>
      <MachineInformationQuestionTags
        machineInfoQuestion={machineInfoQuestion}
        machineInfoTags={machineInfoTags}
      />
      <td>
        {hasPermissionDeleteQuestion && (
          <ButtonLoad
            loading={loadingDelete}
            variant="light"
            size="sm"
            onClick={() =>
              handleDeleteMachineInfoQuestion(machineInfoQuestion.id)
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonLoad>
        )}
      </td>
    </tr>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    rowQuestion: {
      "& $cellQuestion": {
        paddingLeft: spacing(4),
      },
    },
    cellQuestion: {
      // Need to have cellQuestion here even if empty
      // so that "& $cellQuestion" works
    },
  });

export default withStyles(styles)(MachineInformationQuestion);
