import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { Box, createStyles, Tab, Tabs, Theme } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { Alert, Container } from "react-bootstrap";
import {
  GET_CATALOG_EXTRA_RENTAL_QUERY,
  QueryResultCatalogExtraRental,
} from "../apollo/queries/catalogs_extra";
import CreateCatalogExtraRentalMenu from "../components/CatalogExtraRental/CreateCatalogExtraRentalMenu";
import CatalogExtraRental from "../components/CatalogExtraRental/table/CatalogExtraRental";
import { useTranslation } from "react-i18next";
import { CONTAINER_MAIN_WIDTH_XL, ID_EMPTY } from "../utils/constants";
import CatalogExtraRentalSort from "../components/CatalogExtraRental/CatalogExtraRentalSort";
import { QueryCatalogExtraCategoriesUpperRentalArgs } from "../entity/types";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import HeaderPage from "../components/Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {}

function SettingsCatalogExtraRental({ classes }: Props) {
  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = useState(0);
  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([]);
  const [catalogExtraCategoryRentalIds, setCatalogExtraCategoryRentalIds] =
    useState<string[]>([]);
  const [
    catalogExtraCategoryUpperRentalIdSelected,
    setCatalogExtraCategoryUpperRentalIdSelected,
  ] = useState(ID_EMPTY);
  const [
    catalogExtraCategoryRentalIdSelected,
    setCatalogExtraCategoryRentalIdSelected,
  ] = useState(ID_EMPTY);

  const { loading, error, data } = useQuery<
    QueryResultCatalogExtraRental,
    QueryCatalogExtraCategoriesUpperRentalArgs
  >(GET_CATALOG_EXTRA_RENTAL_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalogExtraCategoriesUpperRental"),
    variables: {
      locationIds: locationIdsSelected,
      catalogExtraCategoryRentalIds: catalogExtraCategoryRentalIds,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const renderSwitch = () => {
    switch (tabSelected) {
      case 0:
        return (
          <CatalogExtraRental
            catalogExtraCategoriesUpperRental={
              data.catalogExtraCategoriesUpperRental
                ? data.catalogExtraCategoriesUpperRental
                : []
            }
            locationIdsSelected={locationIdsSelected}
            setLocationIdsSelected={setLocationIdsSelected}
            catalogExtraCategoryRentalIds={catalogExtraCategoryRentalIds}
            setCatalogExtraCategoryRentalIds={setCatalogExtraCategoryRentalIds}
          />
        );
      case 1:
        return (
          <CatalogExtraRentalSort
            catalogExtraCategoriesUpperRental={
              data.catalogExtraCategoriesUpperRental
                ? data.catalogExtraCategoriesUpperRental
                : []
            }
            catalogExtraCategoryUpperRentalIdSelected={
              catalogExtraCategoryUpperRentalIdSelected
            }
            setCatalogExtraCategoryUpperRentalIdSelected={
              setCatalogExtraCategoryUpperRentalIdSelected
            }
            catalogExtraCategoryRentalIdSelected={
              catalogExtraCategoryRentalIdSelected
            }
            setCatalogExtraCategoryRentalIdSelected={
              setCatalogExtraCategoryRentalIdSelected
            }
          />
        );
      default:
        return <Alert variant="danger">{t("error_page_not_found")}</Alert>;
    }
  };

  const a11yProps = (index: number) => ({
    id: `tab-catalog-extra-rental-${index}`,
    "aria-controls": `tab-catalog-extra-rental-${index}`,
  });

  const handleChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setTabSelected(newTab);
  };

  return (
    <Container fluid className={`containerMain ${classes.container}`}>
      <HeaderPage title={t("catalog_extra_rental")} />
      <div className="containerInner">
        <Box className="mb-4">
          <Box>
            <Tabs
              value={tabSelected}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={t("catalog_extra_rows_rental")} {...a11yProps(0)} />
              <Tab label={t("sort_catalog_extra")} {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Box>
        {renderSwitch()}
        <CreateCatalogExtraRentalMenu
          catalogExtraCategoriesUpperRental={
            data.catalogExtraCategoriesUpperRental
              ? data.catalogExtraCategoriesUpperRental
              : []
          }
        />
      </div>
    </Container>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: CONTAINER_MAIN_WIDTH_XL,
    },
  });

export default withStyles(styles)(SettingsCatalogExtraRental);
