import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { Box, createStyles, Tab, Theme } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { Container } from "react-bootstrap";
import {
  GET_CATALOG_EXTRA_UNIT_PRICE_QUERY,
  QueryResultCatalogExtraUnitPrice,
} from "../apollo/queries/catalogs_extra";
import CreateCatalogExtraUnitPriceMenu from "../components/CatalogExtraUnitPrice/CreateCatalogExtraUnitPriceMenu";
import CatalogExtraUnitPrice from "../components/CatalogExtraUnitPrice/table/CatalogExtraUnitPrice";
import { useTranslation } from "react-i18next";
import { CONTAINER_MAIN_WIDTH_XL } from "../utils/constants";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import ProductCodeList from "../components/CatalogExtraUnitPrice/ProductCodeList";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {}

function SettingsCatalogExtraUnitPrice({ classes }: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<QueryResultCatalogExtraUnitPrice>(
    GET_CATALOG_EXTRA_UNIT_PRICE_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("catalogExtraCategoriesUnitPrice"),
    }
  );

  const [tabSelected, setTabSelected] = useState("1");

  const handleChange = (event: React.ChangeEvent<{}>, newTab: string) => {
    setTabSelected(newTab);
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <Container fluid className={`containerMain ${classes.container}`}>
      <TabContext value={tabSelected}>
        <Box>
          <TabList onChange={handleChange}>
            <Tab key="1" label={t("catalog_extra_unit_price")} value="1" />
            <Tab key="2" label={t("generic_product_numbers")} value="2" />
          </TabList>
        </Box>
        <TabPanel key="1" value="1">
          <CreateCatalogExtraUnitPriceMenu
            catalogExtraCategoriesUnitPrice={
              data.catalogExtraCategoriesUnitPrice
                ? data.catalogExtraCategoriesUnitPrice
                : []
            }
          />
          <CatalogExtraUnitPrice
            catalogExtraCategoriesUnitPrice={
              data.catalogExtraCategoriesUnitPrice
                ? data.catalogExtraCategoriesUnitPrice
                : []
            }
          />
        </TabPanel>
        <TabPanel key="2" value="2">
          <ProductCodeList />
        </TabPanel>
      </TabContext>
    </Container>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: CONTAINER_MAIN_WIDTH_XL,
    },
  });

export default withStyles(styles)(SettingsCatalogExtraUnitPrice);
