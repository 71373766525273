import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { CREATE_LOCATION_MUTATION } from "../../apollo/mutations/locations";
import { Button } from "react-bootstrap";
import { GET_LOCATIONS_QUERY } from "../../apollo/queries/locations";
import { handleError } from "../../entity/ErrorHandler";
import DialogContentLocation from "./DialogContentLocation";
import { LocationEmpty } from "../../entity/empties";
import { Mutation, MutationCreateLocationArgs } from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";

interface Props extends WithStyles<typeof styles> {}

function CreateLocation({ classes }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState(LocationEmpty);

  const [createLocation] = useMutation<Mutation, MutationCreateLocationArgs>(
    CREATE_LOCATION_MUTATION,
    {
      refetchQueries: [{ query: GET_LOCATIONS_QUERY }],
      onCompleted: (result) => {
        setLocation(LocationEmpty);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createLocation({
      variables: { name: location.name, dimIdentifier: location.dimIdentifier },
    });
    setOpen(false);
  };

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "locations.add_location",
  ]);

  if (!hasPermissionAdd) {
    return <></>;
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className="addButton"
        variant="primary"
        size="lg"
      >
        {open ? <ClearIcon /> : <AddIcon />}
      </Button>
      <Dialog open={open}>
        <form onSubmit={(event) => handleSubmit(event)}>
          <DialogTitle>{t("new_location")}</DialogTitle>
          <DialogContentLocation
            location={location}
            setLocation={setLocation}
          />
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="secondary">
              {t("cancel")}
            </Button>
            <Button
              disabled={!location.name.trim()}
              type="submit"
              variant="primary"
            >
              {t("save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CreateLocation);
