import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  CustomerType,
  MaintenanceSettingScheduleType,
  Mutation,
  MutationUpdateReservationArgs,
  OrderType,
  ReservationDeliveryMethod,
  ReservationStatus,
  ReservationType,
} from "../../entity/types";
import CustomerPriceSource from "../Customer/CustomerPriceSource";
import CalendarCustomerPrices from "./CalendarCustomerPrices";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { UPDATE_RESERVATION_MUTATION } from "../../apollo/mutations/reservations";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { parseNumber, timeWithoutSeconds } from "../../utils/formatting";
import { Button, Image } from "react-bootstrap";
import { ID_EMPTY } from "../../utils/constants";
import DialogMachineInfo from "../Machine/DialogMachineInfo";
import {
  getReservationProductName,
  reservationCanBeDeleted,
} from "../../utils/reservations/reservation";
import ButtonReservationDelete from "./ButtonReservationDelete";
import DialogImage from "../Shared/DialogImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faClock,
  faEuroSign,
  faImages,
  faTasks,
} from "@fortawesome/pro-light-svg-icons";
import DialogCatalogExtraReservation from "../CatalogExtraUnitPrice/DialogCatalogExtraReservation";
import { getMachineName } from "../../utils/machines/machine";
import ButtonOrderSplit from "./ButtonOrderSplit";
import CalendarCustomerPriceMin from "./CalendarCustomerPriceMin";
import ButtonReservationSplit from "./ButtonReservationSplit";
import DialogOperationHours from "../OperationHour/DialogOperationHours";
import { updateCacheReservation } from "../../utils/cache";
import DialogInspectionReturnReport from "../InspectionReturn/DialogInspectionReturnReport";
import ReservationDiscount from "./ReservationDiscount";
import MaintenanceEstimatedHours from "../Maintenance/MaintenanceEstimatedHours";
import TextFieldFocus from "../Shared/TextFieldFocus";
import DialogReservationImages from "../Reservation/DialogReservationImages";

interface Props extends WithStyles<typeof styles> {
  order: OrderType;
  setOrderId?: React.Dispatch<React.SetStateAction<string>>;
  reservation: ReservationType;
  setReservation: React.Dispatch<React.SetStateAction<ReservationType>>;
  customer: CustomerType;
}

function ReservationFields({
  classes,
  order,
  setOrderId,
  reservation,
  setReservation,
  customer,
}: Props) {
  const { t } = useTranslation();

  const [reservationEdited, setReservationEdited] = useState(reservation);
  const [openDialogImage, setOpenDialogImage] = useState(false);
  const [openProductCard, setOpenProductCard] = useState(false);
  const [openDialogInspectionReturn, setOpenDialogInspectionReturn] =
    useState(false);
  const [openCatalogExtra, setOpenCatalogExtra] = useState(false);
  const [openOperationHours, setOpenOperationHours] = useState(false);
  const [openReservationImages, setOpenReservationImages] = useState(false);

  const [updateReservation, { loading }] = useMutation<
    Mutation,
    MutationUpdateReservationArgs
  >(UPDATE_RESERVATION_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheReservation(cache);
    },
  });

  useEffect(() => {
    setReservationEdited(reservation);
  }, [reservation]);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionChangeReservation = checkPermission(myPermissions, [
    "reservations.change_reservation",
  ]);
  const hasPermissionDeleteReservation = checkPermission(myPermissions, [
    "reservations.delete_reservation",
  ]);
  const hasPermissionAddReservation = checkPermission(myPermissions, [
    "reservations.add_reservation",
  ]);
  const hasPermissionAddOrder = checkPermission(myPermissions, [
    "orders.add_order",
  ]);
  const hasPermissionChangeOrder = checkPermission(myPermissions, [
    "orders.change_order",
  ]);
  const hasPermissionViewOperationHour = checkPermission(myPermissions, [
    "operation_hours.view_operationhour",
  ]);
  const hasPermissionViewReservationImageBasic = checkPermission(
    myPermissions,
    ["reservations.view_reservationimagebasic"]
  );
  const hasPermissionChangeReturnDate = checkPermission(myPermissions, [
    "reservations.change_return_after_return",
  ]);

  const hasPermissionOrderSplit =
    hasPermissionAddReservation &&
    hasPermissionChangeReservation &&
    hasPermissionAddOrder &&
    hasPermissionChangeOrder;

  const hasPermissionReservationSplit =
    hasPermissionAddReservation && hasPermissionChangeReservation;

  const disabledReturnDate =
    (reservation.returnAt !== null && !hasPermissionChangeReturnDate) ||
    reservation.status === ReservationStatus.Invoiced;

  return (
    <div className={loading ? "loading" : ""}>
      <h4 className="mb-3">
        {getReservationProductName(t, reservation)}
        {reservation.catalogExtraRowRental &&
          reservation.catalogExtraRowRental.identifier !== "" && (
            <small className="text-muted ms-3">
              {reservation.catalogExtraRowRental.identifier}
            </small>
          )}
        {!reservation.catalogExtraRowRental && reservation.machine && (
          <small className="text-muted ms-3" title={reservation.machine.serial}>
            {reservation.machine.identifier}
          </small>
        )}
        <br />
        <small className={`${classes.smlResId} text-muted`}>
          {t("reservation_id", { id: reservation.id })}
        </small>
        {reservation.catalogExtraRowRental && reservation.machine && (
          <small
            className={`${classes.smlResId} text-muted ms-1`}
            title={reservation.machine.serial}
          >
            - {getMachineName(reservation.machine, true)}
          </small>
        )}
      </h4>
      <div className="d-flex">
        <FormControl fullWidth>
          <TextField
            type="date"
            label={t("date_rented")}
            value={reservation.dateRented}
            inputProps={{
              min: "2020-01-01",
              max: reservation.dateReturned,
            }}
            disabled={
              !hasPermissionChangeReservation || reservation.giveAt !== null
            }
            onChange={(event) => {
              const dateRentedNew = event.currentTarget.value;
              setReservation({
                ...reservation,
                dateRented: dateRentedNew,
              });
              updateReservation({
                variables: {
                  reservationId: reservation.id,
                  dateRented: dateRentedNew,
                },
              });
            }}
          />
        </FormControl>
        <FormControl fullWidth className="ms-3">
          <TextField
            type="time"
            label={t("time_rented")}
            value={timeWithoutSeconds(reservation.timeRented)}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={
              !hasPermissionChangeReservation || reservation.giveAt !== null
            }
            onChange={(event) => {
              const timeRentedNew = event.currentTarget.value
                ? event.currentTarget.value
                : null;
              setReservation({
                ...reservation,
                timeRented: timeRentedNew,
              });
              updateReservation({
                variables: {
                  reservationId: reservation.id,
                  timeRented: timeRentedNew,
                },
              });
            }}
          />
        </FormControl>
      </div>
      <div className="d-flex">
        <FormControl fullWidth>
          <TextField
            type="date"
            label={t("date_returned")}
            value={
              reservation.dateReturned ? reservation.dateReturned : undefined
            }
            inputProps={{
              min: reservation.dateRented,
              max: "2099-12-31",
            }}
            disabled={!hasPermissionChangeReservation || disabledReturnDate}
            onChange={(event) => {
              const dateReturnedNew = event.currentTarget.value;
              setReservation({
                ...reservation,
                dateReturned: dateReturnedNew,
              });
              updateReservation({
                variables: {
                  reservationId: reservation.id,
                  dateReturned: dateReturnedNew,
                },
              });
            }}
          />
        </FormControl>
        <FormControl fullWidth className="ms-3">
          <TextField
            type="time"
            label={t("time_returned")}
            value={timeWithoutSeconds(reservation.timeReturned)}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!hasPermissionChangeReservation || disabledReturnDate}
            onChange={(event) => {
              const timeReturnedNew = event.currentTarget.value
                ? event.currentTarget.value
                : null;
              setReservation({
                ...reservation,
                timeReturned: timeReturnedNew,
              });
              updateReservation({
                variables: {
                  reservationId: reservation.id,
                  timeReturned: timeReturnedNew,
                },
              });
            }}
          />
        </FormControl>
      </div>
      <div className="d-flex">
        <div className="w-50 pe-2">
          <FormControl fullWidth>
            <TextField
              type="date"
              label={t("date_billing_min")}
              value={
                reservation.dateBillingMin ? reservation.dateBillingMin : ""
              }
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: reservation.dateRented,
              }}
              disabled={!hasPermissionChangeReservation}
              onChange={(event) => {
                const dateBillingMinNew = event.currentTarget.value;
                setReservation({
                  ...reservation,
                  dateBillingMin: dateBillingMinNew,
                });
                updateReservation({
                  variables: {
                    reservationId: reservation.id,
                    dateBillingMin: dateBillingMinNew,
                  },
                });
              }}
            />
          </FormControl>
        </div>
        {reservationEdited.machine?.maintenancesetting?.scheduleType &&
          reservationEdited.machine.maintenancesetting.scheduleType ===
            MaintenanceSettingScheduleType.Hours && (
            <div className="w-50 pe-2">
              <MaintenanceEstimatedHours
                estimatedHoursPerDay={
                  reservationEdited.estimatedMachineHoursPerDay !== "0.00"
                    ? reservationEdited.estimatedMachineHoursPerDay
                    : reservationEdited.machine?.maintenancesetting
                        ?.estimatedHoursPerDay
                }
                disabled={!hasPermissionChangeReservation}
                setStateVariable={(value: Number) =>
                  setReservationEdited({
                    ...reservationEdited,
                    estimatedMachineHoursPerDay: value,
                  })
                }
                saveStateVariable={() => {
                  updateReservation({
                    variables: {
                      reservationId: reservation.id,
                      estimatedMachineHoursPerDay:
                        reservationEdited.estimatedMachineHoursPerDay,
                    },
                  });
                }}
              />
            </div>
          )}
        {reservation.dateBillingMin && (
          <CalendarCustomerPriceMin reservation={reservation} />
        )}
      </div>
      {reservation.machine &&
        reservation.machine.bulkProduct &&
        !reservation.catalogExtraRowRental && (
          <div className="d-flex">
            <div className="w-50">
              <FormControl fullWidth>
                <TextFieldFocus
                  type="number"
                  label={t("bulk_amount")}
                  value={reservation.bulkAmount}
                  disabled={!hasPermissionChangeReservation}
                  onChange={(event) => {
                    const bulkAmountNew = parseNumber(
                      event.currentTarget.value
                    );
                    setReservation({
                      ...reservation,
                      bulkAmount: bulkAmountNew,
                    });
                    updateReservation({
                      variables: {
                        reservationId: reservation.id,
                        bulkAmount: bulkAmountNew,
                      },
                    });
                  }}
                />
              </FormControl>
            </div>
          </div>
        )}
      <div className="d-flex">
        <div>
          <FormLabel>{t("customer_active_price_list")}</FormLabel>
          <CustomerPriceSource customer={customer} />
        </div>
        {!reservation.catalogExtraRowRental && (
          <div className="flex-grow-1">
            {reservation.catalogRow && reservation.catalogRow.image && (
              <div className="float-end">
                <Image
                  className={classes.imgMachine}
                  src={reservation.catalogRow.image}
                  onClick={() => setOpenDialogImage(!openDialogImage)}
                />
                <DialogImage
                  open={openDialogImage}
                  setOpen={setOpenDialogImage}
                  src={reservation.catalogRow.image}
                />
              </div>
            )}
            {reservation.machine && reservation.machine.id !== ID_EMPTY && (
              <div className="float-end me-3 mb-2">
                <Button
                  variant="light"
                  onClick={() => setOpenProductCard(!openProductCard)}
                >
                  <FontAwesomeIcon className="me-2" icon={faClipboardList} />
                  {t("open_product_card")}
                </Button>
                {openProductCard && (
                  <DialogMachineInfo
                    open={openProductCard}
                    setOpen={setOpenProductCard}
                    machine={reservation.machine}
                    editable={false}
                  />
                )}
              </div>
            )}
            {reservation.machine && reservation.machine.id !== ID_EMPTY && (
              <div className="float-end me-3 mb-2">
                <Button
                  variant="light"
                  onClick={() =>
                    setOpenDialogInspectionReturn(!openProductCard)
                  }
                >
                  <FontAwesomeIcon className="me-2" icon={faTasks} />
                  {t("inspection_return")}
                </Button>
                {openDialogInspectionReturn && (
                  <DialogInspectionReturnReport
                    open={openDialogInspectionReturn}
                    setOpen={setOpenDialogInspectionReturn}
                    reservation={reservation}
                  />
                )}
              </div>
            )}
            {hasPermissionViewOperationHour &&
              reservation.machine &&
              reservation.machine.id !== ID_EMPTY && (
                <div className="float-end me-3 mb-2">
                  <Button
                    variant="light"
                    onClick={() => setOpenOperationHours(!openCatalogExtra)}
                  >
                    <FontAwesomeIcon className="me-2" icon={faClock} />
                    {t("operation_hours")}
                  </Button>
                  {openOperationHours && (
                    <DialogOperationHours
                      open={openOperationHours}
                      setOpen={setOpenOperationHours}
                      reservation={reservation}
                      machine={reservation.machine}
                    />
                  )}
                </div>
              )}
            {reservation.machine && reservation.machine.id !== ID_EMPTY && (
              <div className="float-end me-3 mb-2">
                <Button
                  variant="light"
                  onClick={() => setOpenCatalogExtra(!openCatalogExtra)}
                >
                  <FontAwesomeIcon className="me-2" icon={faEuroSign} />
                  {t("catalog_extra_unit_price")}
                </Button>
                {openCatalogExtra && (
                  <DialogCatalogExtraReservation
                    open={openCatalogExtra}
                    setOpen={setOpenCatalogExtra}
                    reservation={reservation}
                  />
                )}
              </div>
            )}
            {hasPermissionViewReservationImageBasic && (
              <div className="float-end me-3 mb-2">
                <Button
                  variant="light"
                  onClick={() => setOpenReservationImages(!openCatalogExtra)}
                >
                  <FontAwesomeIcon className="me-2" icon={faImages} />
                  {t("reservation_images")}
                </Button>
                {openReservationImages && (
                  <DialogReservationImages
                    open={openReservationImages}
                    setOpen={setOpenReservationImages}
                    reservation={reservation}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {reservation.catalogRow && (
        <CalendarCustomerPrices
          order={order}
          customer={customer}
          catalogRowId={reservation.catalogRow.id}
          catalogExtraRowRental={reservation.catalogExtraRowRental}
          reservation={reservation}
        />
      )}
      <ReservationDiscount reservation={reservation} />
      <FormControl component="fieldset">
        <FormLabel>{t("delivery_method")}</FormLabel>
        <RadioGroup
          aria-label={t("delivery_method")}
          name="deliveryMethod"
          value={reservation.deliveryMethod}
          onChange={(event, value) => {
            const deliveryMethodNew = value as ReservationDeliveryMethod;
            setReservation({
              ...reservation,
              deliveryMethod: deliveryMethodNew,
            });
            updateReservation({
              variables: {
                reservationId: reservation.id,
                deliveryMethod: deliveryMethodNew,
              },
            });
          }}
        >
          <FormControlLabel
            value={ReservationDeliveryMethod.Customer}
            control={<Radio />}
            label={t("delivery_method_" + ReservationDeliveryMethod.Customer)}
            disabled={!hasPermissionChangeReservation}
          />
          <FormControlLabel
            value={ReservationDeliveryMethod.Transport}
            control={<Radio />}
            label={t("delivery_method_" + ReservationDeliveryMethod.Transport)}
            disabled={!hasPermissionChangeReservation}
          />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          multiline
          label={t("information")}
          value={reservationEdited.information}
          minRows={2}
          disabled={!hasPermissionChangeReservation}
          onChange={(event) => {
            setReservationEdited({
              ...reservationEdited,
              information: event.currentTarget.value,
            });
          }}
          onBlur={() => {
            setReservation(reservationEdited);
            updateReservation({
              variables: {
                reservationId: reservation.id,
                information: reservationEdited.information,
              },
            });
          }}
        />
      </FormControl>
      {!reservation.catalogExtraRowRental &&
        reservation.catalogRow &&
        reservation.catalogRow.information && (
          <p className={classes.elRowInformation}>
            {reservation.catalogRow.information}
          </p>
        )}
      {reservation.catalogExtraRowRental &&
        reservation.catalogExtraRowRental.information !== "" && (
          <p className={classes.elRowInformation}>
            {reservation.catalogExtraRowRental.information}
          </p>
        )}
      <div className="buttons mb-0">
        {hasPermissionDeleteReservation &&
          reservationCanBeDeleted(reservation) && (
            <ButtonReservationDelete reservation={reservation} />
          )}
        {hasPermissionOrderSplit && (
          <ButtonOrderSplit order={order} setOrderId={setOrderId} />
        )}
        {hasPermissionReservationSplit && (
          <ButtonReservationSplit
            order={order}
            reservation={reservation}
            setReservation={setReservation}
          />
        )}
      </div>
    </div>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    imgMachine: {
      width: "auto",
      height: "5rem",
      cursor: "pointer",
    },
    elRowInformation: {
      backgroundColor: palette.grey["100"],
      padding: "10px 15px",
      marginTop: "15px",
    },
    smlResId: {
      fontSize: "0.8rem",
    },
  });

export default withStyles(styles)(ReservationFields);
