import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Theme,
} from "@material-ui/core";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Mutation,
  MutationCreateQuickTransportArgs,
  OrderType,
  QueryTransportsSearchArgs,
  TransportInvoicingRowType,
  TransportMethod,
  TransportType,
} from "../../entity/types";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { handleError } from "../../entity/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltRight } from "@fortawesome/pro-light-svg-icons";
import { parseNumber } from "../../utils/formatting";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import {
  GET_CATALOG_TRANSFER_QUERY,
  QueryResultCatalogTransfer,
} from "../../apollo/queries/catalogs_transfer";
import Error from "../Shared/Error";
import LoadingSimple from "../Shared/LoadingSimple";
import { CREATE_QUICK_TRANSPORT_MUTATION } from "../../apollo/mutations/transports";
import { getQueryKey } from "../../utils/cache";
import { WithStyles } from "@material-ui/core/styles";
import SelectRequired from "../Shared/SelectRequired";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import {
  GET_TRANSPORTS_SEARCH_QUERY,
  QueryResultSearchTransports,
} from "../../apollo/queries/transports";
import { TransportEmpty } from "../../entity/empties";
import { isNonEmptyArray } from "@apollo/client/utilities";
import TextFieldFocus from "../Shared/TextFieldFocus";

const getDefaultTransportMethodId = (transports: TransportType[]) =>
  transports.length > 0 ? TransportMethod.Retrieve : TransportMethod.Send;

interface Props extends WithStyles<typeof styles> {
  order: OrderType;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  transports: TransportType[];
}

function TransportQuickButton({ classes, order, setShow, transports }: Props) {
  const { t } = useTranslation();

  const [km, setKm] = useState("0");
  const [transportMethodId, setTransportMethodId] = useState(
    getDefaultTransportMethodId(transports)
  );
  const [transferMethodId, setTransferMethodId] = useState(ID_EMPTY);
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [district, setDistrict] = useState("");

  const [doSearch, { data: searchResults, loading: loadingSearch }] =
    useLazyQuery<QueryResultSearchTransports, QueryTransportsSearchArgs>(
      GET_TRANSPORTS_SEARCH_QUERY,
      {
        fetchPolicy: getQueryFetchPolicy("transportsSearch"),
        variables: {
          customerId: order.customer ? order.customer.id : ID_EMPTY,
          searchTerms: "",
        },
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const {
    loading: loadingTransfers,
    error: errorTransfers,
    data: dataTransfers,
  } = useQuery<QueryResultCatalogTransfer>(GET_CATALOG_TRANSFER_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalogTransferMethods"),
  });

  const [createQuickTransport, { loading: loadingQuick }] = useMutation<
    Mutation,
    MutationCreateQuickTransportArgs
  >(CREATE_QUICK_TRANSPORT_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: () => {
      setKm("0");
      setTransportMethodId(TransportMethod.Retrieve);
      setTransferMethodId(ID_EMPTY);
      setAddress("");
      setPostcode("");
      setDistrict("");
      setShow(false);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("transportsByOrder"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("transportInvoicingsByOrder"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("orderTotal"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("invoiceableRows"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddTransport = checkPermission(myPermissions, [
    "transports.add_transport",
  ]);
  const hasPermissionAddTransportInvoicing = checkPermission(myPermissions, [
    "transports.add_transportinvoicing",
  ]);

  if (loadingTransfers) return <LoadingSimple />;
  if (errorTransfers) return <Error error={errorTransfers} />;
  if (!dataTransfers) return <Error error={t("error_query_failed")} />;

  let transportAddresses: TransportType[] = [];

  const transportAddressesOrig = searchResults?.transportsSearch
    ? searchResults.transportsSearch
    : [];

  const getKms = (transportAddress: TransportType) => {
    let extra = 0;
    if (isNonEmptyArray(transportAddress.order.transportinvoicingSet)) {
      transportAddress.order.transportinvoicingSet.forEach((address) => {
        if (address.rowType === TransportInvoicingRowType.Distance) {
          extra = parseFloat(address.quantity);
        }
      });
    }

    return transportAddress.order.location.transferDistanceIncluded + extra;
  };

  function addToIndeces(name: string, kms: number) {
    let ttObj = indeces.filter((obj) => {
      return obj.name === name;
    });

    if (!ttObj.length) {
      indeces.push({ name: name, kms: [kms] });
      return true;
    } else {
      if (ttObj[0] && !ttObj[0].kms.includes(kms)) {
        indeces[0].kms.push(kms);
        return true;
      }
    }

    return false;
  }

  let indeces: { name: string; kms: Array<number> }[] = [];
  transportAddressesOrig.forEach((tt, i) => {
    const indecesKey =
      tt.order.location.name +
      " - " +
      tt.address +
      " " +
      tt.postcode +
      ", " +
      tt.district;
    if (!tt.order.transportinvoicingSet.length) {
      if (addToIndeces(indecesKey, 0)) {
        transportAddresses.push(tt);
      }
    } else {
      tt.order.transportinvoicingSet.forEach((ti, j) => {
        if (ti.rowType === TransportInvoicingRowType.Distance) {
          if (
            addToIndeces(
              indecesKey,
              tt.order.location.transferDistanceIncluded + ti.quantity
            )
          ) {
            transportAddresses.push(tt);
          }
        }
      });
    }
  }, indeces);

  if (!hasPermissionAddTransport || !hasPermissionAddTransportInvoicing) {
    return <></>;
  }

  const onClick = () => {
    createQuickTransport({
      variables: {
        orderId: order.id,
        method: transportMethodId,
        catalogTransferMethodId: transferMethodId,
        km: parseNumber(km),
        address: address,
        district: district,
        postcode: postcode,
      },
    });
  };

  const updateTransportAddresses = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    const target = event.target as HTMLInputElement;
    doSearch({
      variables: {
        customerId: order.customer ? order.customer.id : ID_EMPTY,
        searchTerms: target.value,
      },
    });
  };

  const selectTransportAddress = (optionSelected: TransportType | null) => {
    setAddress(optionSelected ? optionSelected.address : address);
    setPostcode(optionSelected ? optionSelected.postcode : postcode);
    setDistrict(optionSelected ? optionSelected.district : district);
    if (optionSelected !== null) {
      const kms = getKms(optionSelected);
      setKm(kms.toString());
    }
  };

  const getFormattedTransportAddress = (transportAddress: TransportType) => {
    if (transportAddress.id === ID_EMPTY) {
      return t("add_value", { value: transportAddress.address });
    } else {
      return (
        transportAddress.order.location.name +
        " - " +
        transportAddress.address +
        ", " +
        transportAddress.postcode +
        " " +
        transportAddress.district +
        " (" +
        getKms(transportAddress) +
        ")"
      );
    }
  };

  const filter = createFilterOptions<TransportType>();

  return (
    <div className={loadingQuick ? "loading" : ""}>
      <b>{t("quick_transport")}</b>
      <div className="mt-2">
        <FormControl className={`${classes.ddlTransportMethod} me-2`}>
          <InputLabel id="lblQuickTransportMethod">
            {t("transport_method")}
          </InputLabel>
          <SelectRequired
            autoWidth
            labelId="lblQuickTransportMethod"
            value={transportMethodId}
            onChange={(event) => {
              const methodNew = event.target.value as TransportMethod;
              setTransportMethodId(methodNew);
            }}
          >
            <MenuItem value={TransportMethod.Send}>
              {t("transport_method_" + TransportMethod.Send)}
            </MenuItem>
            <MenuItem value={TransportMethod.Retrieve}>
              {t("transport_method_" + TransportMethod.Retrieve)}
            </MenuItem>
          </SelectRequired>
        </FormControl>
        <FormControl className="me-2">
          <InputLabel id="lblTransportQuickTransferMethod">
            {t("catalog_transfer_method")}
          </InputLabel>
          <SelectRequired
            autoWidth
            labelId="lblTransportQuickTransferMethod"
            required={true}
            value={transferMethodId}
            onChange={(event) => {
              setTransferMethodId(String(event.target.value));
            }}
          >
            <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
            {dataTransfers.catalogTransferMethods?.map(
              (catalogTransferMethod) => (
                <MenuItem
                  key={catalogTransferMethod.id}
                  value={catalogTransferMethod.id}
                >
                  {catalogTransferMethod.name}
                </MenuItem>
              )
            )}
          </SelectRequired>
        </FormControl>
        <FormControl
          style={{ minWidth: 200 }}
          className={`me-2 ${loadingSearch ? "loading" : ""}`}
        >
          <Autocomplete
            fullWidth={true}
            options={transportAddresses}
            filterOptions={(options: TransportType[], params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === getFormattedTransportAddress(option)
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  ...TransportEmpty,
                  address: inputValue,
                });
              }

              return filtered;
            }}
            getOptionLabel={(transportAddress: TransportType) =>
              getFormattedTransportAddress(transportAddress)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                className="m-0"
                label={t("address_search")}
                variant="standard"
              />
            )}
            value={null}
            getOptionSelected={(a, b) => {
              if (!a || !b) {
                return false;
              }
              return a.id === b.id;
            }}
            onKeyUp={(e) => {
              updateTransportAddresses(e);
            }}
            onChange={(event, optionSelected) => {
              selectTransportAddress(optionSelected);
            }}
          />
        </FormControl>
        <FormControl className="me-2">
          <TextFieldFocus
            type="number"
            value={km}
            label={t("km_one_way")}
            inputProps={{ maxLength: 10 }}
            onChange={(event) => setKm(event.target.value)}
            onBlur={(event) => setKm(String(parseNumber(event.target.value)))}
          />
        </FormControl>
        <FormControl className="me-2">
          <TextField
            label={t("address")}
            value={address}
            onChange={(event) => {
              setAddress(event.target.value);
            }}
          />
        </FormControl>
        <FormControl className="me-2">
          <TextField
            label={t("postcode")}
            value={postcode}
            onChange={(event) => {
              setPostcode(event.target.value);
            }}
          />
        </FormControl>
        <FormControl className="me-2">
          <TextField
            label={t("district")}
            value={district}
            onChange={(event) => {
              setDistrict(event.target.value);
            }}
          />
        </FormControl>
        <Button className="mt-2" onClick={onClick}>
          <FontAwesomeIcon icon={faArrowAltRight} />
        </Button>
      </div>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    ddlTransportMethod: {
      minWidth: "9rem",
    },
  });

export default withStyles(styles)(TransportQuickButton);
