import gql from "graphql-tag";

export const CREATE_IDENTIFICATION_REQUEST = gql`
mutation createIdentificationRequest(
    $org: Int!
){
    createIdentificationRequest(
        org: $org,
    ){
        identificationRequest {
            id
            org{
                id
                businessName
            }
        }
        org(
            id: $org
        ){
            identificationRequests {
                id
                org {
                    id
                    businessName
                }
            }
        }
    } 
}
`;

export const DELETE_IDENTIFICATIONREQUEST = gql`
mutation deleteIdentificationRequest(
    $id: Int!
){
    deleteIdentificationRequest(
        id: $id
    ){
        id
    }
}
`;

export const UPDATE_IDENTIFICATIONREQUEST = gql`
mutation updateIdentificationRequest(
    $id: Int!
    $org: Int
){
    updateIdentificationRequest(
        id: $id,
        org: $org
    ){
        identificationRequests {
            id
            organisation {
                id
                businessName
            }
        }
    }
}
`;



export const COMPLETE_IDENTIFICATIONREQUEST = gql`
mutation operatorHandlingCompleted(
    $id: UUID!
){
    operatorHandlingCompleted(
        id: $id
    ){
        identificationRequest(id: $id) {
            id
            organisation {
                id
                businessName
            }
        }
    }
}
`;

export const SET_IDENTIFICATIONREQUESTDIALOG = gql`
    mutation setIdentificationRequestDialog($isopen: Boolean){
        setIdentificationRequestDialog(isopen: $isopen) @client
    }
`;




export const ADD_USER = gql`
    mutation createUser(
        $email: String!
        $firstName: String
        $lastName: String
        $organisation: Int
        $password: String!
    ) {
        createUser(
            email: $email
            firstName: $firstName
            lastName: $lastName
            organisation: $organisation
            password: $password
        ) {
            user {
                id
            }
        }
    }
`;

export const ADD_USERWITHID = gql`
    mutation createUserwithid(
        $email: String!
        $firstName: String
        $lastName: String
        $organisation: Int
        $password: String!
    ) {
        createUserwithid(
            email: $email
            firstName: $firstName
            lastName: $lastName
            organisation: $organisation
            password: $password
        ) {
            user {
                id
            }
        }
    }
`;

export const ADD_INVITEDUSER = gql`
    mutation createInvitedUser(
        $email: String!
        $phoneNumber: String!
    ) {
        createInvitedUser(
            email: $email
            phoneNumber: $phoneNumber
        ) {
            user {
                id
            }
        }
    }
`;


export const CREATE_SIMPLE_IDENTIFICATIONREQUEST = gql`
    mutation createIdentificationRequest(
        $creator: Int
    ) {
        createIdentificationRequest(
            creator: $creator
        ) {
            identificationRequest {
                id
                createdTs
                identificationCompletedTs
                operatorHandlingCompletedTs
                identificationLink
                trustedBirthPlace
                trustedFullName
                trustedNationalPersonalId
            }
        }
    }
`;


export const DELETE_IDENTIFICATIONREQUEST2 = gql`
    mutation deleteIdentificationRequest($id: UUID!) {
        deleteIdentificationRequest(id: $id) {
            id
        }
    }
`;

export const DELETE_IDENTIFICATION_REQUEST_MUTATION = gql`
    mutation deleteIdentificationRequest($id: UUID!) {
        deleteIdentificationRequest(id: $id) {
            id
        }
    }
`;


export const IdentificationRequestMutations = {
    //@ts-ignore
    setIdentificationRequestDialog: (_, variables, { cache }) => {
        const newValue = variables.isopen
        cache.writeData({
            data: {identificationRequestDialogOpen: newValue}
        });
        return null
    }
}