import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export const FRAG_MACHINE_LIST_FIELDS = gql`
  fragment MachineListFields on MachineType {
    id
    identifier
    serial
    acquisitionDate
    dateRemoved
    yearModel
    hourTracking
    rentThrough
    rentThroughCompany
    rentThroughInformation
    rentThroughCommissionCase
    information
    bulkProduct
    machineModel {
      id
      title
      machineMake {
        id
        title
      }
    }
    catalogExtraRowsUnitPrice {
      id
    }
    catalogExtraRowsRental {
      id
    }
    location {
      id
      name
    }
    catalogRows {
      id
      name
      catalogCategory {
        catalogCategoryUpper {
          catalog {
            id
          }
        }
      }
    }
  }
`;

export const FRAG_MACHINES_AVAILABLE_FIELDS = gql`
  fragment MachinesAvailableFields on MachineType {
    id
    identifier
    serial
    machineModel {
      id
      title
      machineMake {
        id
        title
      }
    }
    location {
      id
      name
    }
  }
`;

export interface QueryResultMachinesForLocation {
  machinesForLocation: Query["machinesForLocation"];
}
export const GET_MACHINES_FOR_LOCATION_QUERY = gql`
  ${FRAG_MACHINE_LIST_FIELDS}
  query getMachinesForLocationQuery(
    $locationIds: [ID]!
    $rentThroughs: [Int]!
    $catalogRowIds: [ID]!
    $search: String
  ) {
    machinesForLocation(
      locationIds: $locationIds
      rentThroughs: $rentThroughs
      catalogRowIds: $catalogRowIds
      search: $search
    ) {
      ...MachineListFields
      machinebulkamountSet {
        id
        location {
          id
        }
        bulkAmount
      }
      machineinfoanswerSet {
        id
        machineInfoQuestion {
          id
          answerType
        }
        machineInfoQuestionOption {
          id
        }
        answer
      }
    }
  }
`;

export interface QueryResultMachines {
  machines: Query["machines"];
}
export const GET_MACHINES_QUERY = gql`
  ${FRAG_MACHINE_LIST_FIELDS}
  query getMachinesQuery {
    machines {
      ...MachineListFields
    }
  }
`;

export interface QueryResultMachinesSimple {
  machines: Query["machines"];
}
export const GET_MACHINES_SIMPLE_QUERY = gql`
  query getMachinesSimpleQuery {
    machines {
      id
      identifier
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
    }
  }
`;

export interface QueryResultMachinesBreakdownsUnfixed {
  machineBreakdownsUnfixed: Query["machineBreakdownsUnfixed"];
  reservationsCurrentByMachine: Query["reservationsCurrentByMachine"];
  reservationsUpcomingByMachine: Query["reservationsUpcomingByMachine"];
  machineBulkBreakdownsUnfixed: Query["machineBulkBreakdownsUnfixed"];
}
export const GET_MACHINE_BREAKDOWNS_UNFIXED_QUERY = gql`
  query getMachinesBreakdownsUnfixedQuery(
    $locationIds: [ID]!
    $categoryIds: [ID]!
    $userIds: [ID]!
  ) {
    machineBreakdownsUnfixed(
      locationIds: $locationIds
      categoryIds: $categoryIds
      userIds: $userIds
    ) {
      id
      stillRentable
      title
      information
      fixByStart
      fixByEnd
      billing
      amountBilledSingle
      fixedAt
      machine {
        id
        identifier
        machineModel {
          id
          title
          machineMake {
            id
            title
          }
        }
      }
      catalogExtraRowRental {
        id
        name
      }
      machinebreakdowncommentSet {
        id
        comment
        createdAt
        createdBy {
          firstName
          lastName
        }
      }
      createdAt
    }
    reservationsCurrentByMachine {
      id
      machine {
        id
      }
      order {
        id
        customer {
          id
          name
          visitAddress
          visitPostcode
          visitDistrict
        }
      }
      dateRented
      dateReturned
    }
    reservationsUpcomingByMachine {
      id
      machine {
        id
      }
      dateRented
    }
    machineBulkBreakdownsUnfixed(
      locationIds: $locationIds
      categoryIds: $categoryIds
      userIds: $userIds
    ) {
      id
      machine {
        id
        identifier
        machineModel {
          id
          title
          machineMake {
            id
            title
          }
        }
      }
      fixedAt
      amountBroken
      createdAt
    }
  }
`;

export interface QueryResultMachineInfoCategories {
  machineInfoCategories: Query["machineInfoCategories"];
  machineInfoTags: Query["machineInfoTags"];
}
export const GET_MACHINE_INFO_CATEGORIES_QUERY = gql`
  query getMachineInfoCategoriesQuery {
    machineInfoCategories {
      id
      name
      machineinfoquestionSet {
        id
        question
        answerType
        showProductCard
        showDrive
        machineInfoCategory {
          id
        }
        machineinfotagSet {
          id
          name
        }
        machineinfoquestionoptionSet {
          id
          title
        }
      }
    }
    machineInfoTags {
      id
      name
    }
  }
`;

export interface QueryResultMachineWithMachineInfoCategories {
  machine: Query["machine"];
  machineInfoCategories: Query["machineInfoCategories"];
  machineInfoTags: Query["machineInfoTags"];
  machines: Query["machines"];
  operationHoursForMachine: Query["operationHoursForMachine"];
  maintenanceHistoryForMachine: Query["maintenanceHistoryForMachine"];
  maintenanceNextForMachine: Query["maintenanceNextForMachine"];
  machineBreakdownsForMachine: Query["machineBreakdownsForMachine"];
}
export const GET_MACHINE_WITH_MACHINE_INFO_CATEGORIES_QUERY = gql`
  query getMachineWithMachineInfoCategoriesQuery($machineId: ID!) {
    machine(machineId: $machineId) {
      id
      machineinfoanswerSet {
        id
        answer
        machineInfoQuestionOption {
          id
        }
        machineInfoQuestion {
          id
          machineInfoCategory {
            id
          }
        }
      }
      maintenancesetting {
        id
        scheduleType
        startDate
      }
    }
    machineInfoCategories {
      id
      name
      machineinfoquestionSet {
        id
        question
        answerType
        showProductCard
        showDrive
        machineInfoCategory {
          id
        }
        machineinfotagSet {
          id
          name
        }
        machineinfoquestionoptionSet {
          id
          title
        }
      }
    }
    machineInfoTags {
      id
      name
    }
    machines {
      id
      identifier
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
    }
    operationHoursForMachine(machineId: $machineId, reservationId: "0") {
      id
      dateChecked
      hours
      source
    }
    maintenanceHistoryForMachine(machineId: $machineId) {
      id
      maintenanceInterval {
        id
        intervalType
        hours
        days
        description
      }
      operationhour {
        id
        hours
      }
      serviceTechnician {
        id
        firstName
        lastName
      }
      servicedAt
    }
    machineBreakdownsForMachine(machineId: $machineId) {
      id
      title
      information
      fixedAt
      fixedBy {
        id
        firstName
        lastName
      }
    }
    maintenanceNextForMachine(machineId: $machineId) {
      nextMaintenanceDate
      intervalType
      hours
      days
      description
    }
  }
`;

export interface QueryResultMakesAndModels {
  machineMakes: Query["machineMakes"];
}
export const GET_MAKES_AND_MODELS_QUERY = gql`
  query getMakesAndModelsQuery {
    machineMakes {
      id
      title
      machinemodelSet {
        id
        title
      }
    }
  }
`;

export interface QueryResultMachineInfoQuestionsForDrives {
  machineInfoQuestionsForDrives: Query["machineInfoQuestionsForDrives"];
}
export const GET_MACHINE_INFO_QUESTIONS_FOR_DRIVES_QUERY = gql`
  query getMachineInfoQuestionsForDrivesQuery($machineIds: [ID]!) {
    machineInfoQuestionsForDrives(machineIds: $machineIds) {
      id
      question
      machineinfoanswerSet {
        id
        answer
        machine {
          id
        }
      }
    }
  }
`;

export interface QueryResultMachinesForCatalogRowAvailable {
  machinesForCatalogRowAvailable: Query["machinesForCatalogRowAvailable"];
}
export const GET_MACHINES_FOR_CATALOG_ROW_AVAILABLE_QUERY = gql`
  ${FRAG_MACHINES_AVAILABLE_FIELDS}
  query getMachinesForCatalogRowAvailableQuery(
    $catalogRowId: ID!
    $dateFrom: Date!
    $dateTo: Date!
  ) {
    machinesForCatalogRowAvailable(
      catalogRowId: $catalogRowId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      ...MachinesAvailableFields
    }
  }
`;

export interface QueryResultMachinesSearchAvailable {
  machinesSearchAvailable: Query["machinesSearchAvailable"];
}
export const GET_MACHINES_SEARCH_AVAILABLE_QUERY = gql`
  ${FRAG_MACHINES_AVAILABLE_FIELDS}
  query getMachinesSearchAvailableQuery(
    $dateFrom: Date!
    $dateTo: Date!
    $search: String!
  ) {
    machinesSearchAvailable(
      dateFrom: $dateFrom
      dateTo: $dateTo
      search: $search
    ) {
      ...MachinesAvailableFields
    }
  }
`;

export interface QueryResultMachineBulkAmountsForMachine {
  machineBulkAmountsForMachine: Query["machineBulkAmountsForMachine"];
}
export const GET_MACHINE_BULK_AMOUNTS_FOR_MACHINE_QUERY = gql`
  query getMachineBulkAmountsForMachineQuery($machineId: ID!) {
    machineBulkAmountsForMachine(machineId: $machineId) {
      id
      bulkAmount
      location {
        id
      }
    }
  }
`;

export interface QueryResultMachineBulkBreakdownsForMachine {
  machineBulkBreakdownsForMachine: Query["machineBulkBreakdownsForMachine"];
}
export const GET_MACHINE_BULK_BREAKDOWNS_FOR_MACHINE_QUERY = gql`
  query getMachineBulkBreakdownsForMachineQuery(
    $machineId: ID!
    $reservationId: ID
  ) {
    machineBulkBreakdownsForMachine(
      machineId: $machineId
      reservationId: $reservationId
    ) {
      id
      amountBroken
      fixable
      fixedAt
    }
  }
`;

export interface QueryResultMachinesForCatalogRows {
  machinesForCatalogRows: Query["machinesForCatalogRows"];
}
export const GET_MACHINES_FOR_CATALOG_ROWS_QUERY = gql`
  query getMachinesForCatalogRows($catalogRowIds: [ID]!, $locationId: ID) {
    machinesForCatalogRows(
      catalogRowIds: $catalogRowIds
      locationId: $locationId
    ) {
      id
      identifier
      serial
      rentThrough
      rentThroughCompany
      rentThroughInformation
      rentThroughCommissionCase
      acquisitionDate
      dateRemoved
      bulkProduct
      yearModel
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
      location {
        id
        name
      }
      catalogRows {
        id
      }
    }
  }
`;

export interface queryResultMachinesWithoutCatalogRow {
  machinesWithoutCatalogRow: Query["machinesWithoutCatalogRow"];
}
export const GET_MACHINES_WITHOUT_CATALOG_ROW_QUERY = gql`
  query getMachinesWithoutCatalogRow($catalogId: ID!) {
    machinesWithoutCatalogRow(catalogId: $catalogId) {
      id
      identifier
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
    }
  }
`;
