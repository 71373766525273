import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import { ReservationType } from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import DialogContentInspectionReturnAnswers from "./DialogContentInspectionReturnAnswers";
import CatalogExtraRowsInvoiceReport from "../CatalogExtraUnitPrice/CatalogExtraRowsInvoiceReport";
import { WithStyles } from "@material-ui/core/styles";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reservation: ReservationType;
}

function DialogInspectionReturnReport({
  classes,
  open,
  setOpen,
  reservation,
}: Props) {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogTitleWithClose
        id="dialogTitleReservationReturnCheck"
        onClose={() => setOpen(false)}
      >
        {t("toggle_return_checked_at")}
      </DialogTitleWithClose>
      <DialogContent>
        {reservation.machine && (
          <DialogContentInspectionReturnAnswers
            reservation={reservation}
            editable={false}
          />
        )}
        <br />
        <CatalogExtraRowsInvoiceReport reservation={reservation} />
      </DialogContent>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogInspectionReturnReport);
