import { getPercentPrefixCalc } from "./discounts/prefixes";
import { parseNumber } from "./formatting";

export const getPercentFactorDiscount = (value: string) => {
  let prefix = getPercentPrefixCalc(value);
  return parseNumber(prefix + value) / 100 + 1;
};

export const getPercentFactor = (value: string) => {
  return parseNumber(value) / 100;
};

export const getVatFactor = (value: string) => {
  return parseNumber(value) / 100 + 1;
};

export const getDiscountPercent = (valueOrig: number, valueNew: number) => {
  return ((valueOrig - valueNew) / valueOrig) * 100;
};

export const getDiscountPrice = (amount: number, discount: number) => {
  return amount - (amount * discount) / 100;
};
