import React from "react";
import { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Box, createStyles, Tab, Theme } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMdChat, faNotesMedical, faCog } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";


import MaterialTable from "material-table";
import {
  tableIcons,
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
} from "../utils/TableProps";
import { useTranslation } from "react-i18next";
import { IdentificationRequestType } from "../entity/types";
import IdentificationRequestListMenuForWaiting from "../components/Identification/IdentificationRequestListMenuForWaiting";
import HeaderPage from "../components/Shared/HeaderPage";


interface Props extends WithStyles<typeof styles> {
    elems: IdentificationRequestType[];
}

const OperatorWaitingRequestsTable = ({ classes, elems }: Props) => {
  const { t } = useTranslation();

  const data = elems.map((o) => ({ ...o }));

  return (
    <>
      <div className="containerInner">
        <MaterialTable
          style={getTableStyle()}
          title={""}
          icons={tableIcons}
          localization={GetTableLocalization(t)}
  // t("createdTs") localize
          columns={[
            { title: t("identifier"), field: "id" },
            { title: t("identification_completed_ts"), field: "identificationCompletedTs" },
            { title: t("full_name"), field: "trustedFullName" },
            { title: t("national_id"), field: "trustedNationalPersonalId" }
          ]}
          data={data}
          options={getTableOptions()}
          components={{
            Action: (props) => (
              <IdentificationRequestListMenuForWaiting
                identificationRequest={props.data}
              />
            ),
          }}
          actions={[
            {
              icon: "menu",
              onClick: () => {},
            },
          ]}
        />
      </div>
    </>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(OperatorWaitingRequestsTable);


