import React, { useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  TableCell,
  TableFooter,
  TableRow,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  tableIcons,
} from "../../utils/TableProps";
import {
  CommissionRowRentThroughType,
  CommissionRowType,
  CommissionUserSettingType,
  QueryCommissionRowsForUserArgs,
  UserType,
} from "../../entity/types";
import { formatNumber, parseNumber } from "../../utils/formatting";
import MaterialTable, { Column, MTableBody } from "material-table";
import { useQuery } from "@apollo/client";
import {
  GET_COMMISSION_ROWS_FOR_USER_QUERY,
  QueryResultCommissionRowsForUser,
} from "../../apollo/queries/commissions";
import { format } from "date-fns";
import { DATE_FORMAT_ISO } from "../../utils/constants";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { WithStyles } from "@material-ui/core/styles";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { doesMatchAllTerms } from "../../utils/search";
import CommissionHeaderUserSalary from "./CommissionHeaderUserSalary";
import {
  commissionsFilterDateRange,
  commissionsRenderDateRange,
} from "../../utils/commissions/commissions";

interface Props extends WithStyles<typeof styles> {
  userSelected: UserType;
  commissionUserSetting: CommissionUserSettingType;
  dateFrom: Date;
  dateTo: Date;
  addToRevenue?: Function;
  addToCommission?: Function;
}

function CommissionPreviewForUser({
  classes,
  userSelected,
  commissionUserSetting,
  dateFrom,
  dateTo,
  addToRevenue,
  addToCommission,
}: Props) {
  const { t } = useTranslation();
  const tableLocalization = GetTableLocalization(t);

  const {
    loading: loadingCommissionReportForUser,
    error: errorCommissionReportForUser,
    data: dataCommissionReportForUser,
  } = useQuery<
    QueryResultCommissionRowsForUser,
    QueryCommissionRowsForUserArgs
  >(GET_COMMISSION_ROWS_FOR_USER_QUERY, {
    fetchPolicy: getQueryFetchPolicy("commissionRowsForUser"),
    variables: {
      dateFrom: format(dateFrom, DATE_FORMAT_ISO),
      dateTo: format(dateTo, DATE_FORMAT_ISO),
      userId: userSelected.id,
    },
  });

  const commissionRowsForUser: CommissionRowType[] = (
    dataCommissionReportForUser?.commissionRowsForUser
      ? dataCommissionReportForUser.commissionRowsForUser
      : []
  ).map((o) => ({
    ...o,
  }));

  const commissionRowsRentThroughForUser = (
    dataCommissionReportForUser?.commissionRowsRentThroughForUser
      ? dataCommissionReportForUser.commissionRowsRentThroughForUser
      : []
  ).map((o) => ({
    ...o,
  }));

  let totalRevenueHere = 0;
  let totalCommissionHere = 0;
  commissionRowsForUser.forEach((commission) => {
    totalRevenueHere += parseNumber(commission.revenue);
    totalCommissionHere += parseNumber(commission.commission);
  });
  commissionRowsRentThroughForUser.forEach((commission) => {
    totalRevenueHere += parseNumber(commission.revenue);
    totalCommissionHere += parseNumber(commission.commission);
  });

  useEffect(() => {
    if (addToRevenue) {
      addToRevenue(totalRevenueHere);
    }
    if (addToCommission) {
      addToCommission(totalCommissionHere);
    }

    return () => {
      if (addToRevenue) {
        addToRevenue(-totalRevenueHere);
      }
      if (addToCommission) {
        addToCommission(-totalCommissionHere);
      }
    };
  }, [totalRevenueHere, addToRevenue, totalCommissionHere, addToCommission]);

  if (loadingCommissionReportForUser) return <LoadingSimple />;
  if (errorCommissionReportForUser)
    return <Error error={errorCommissionReportForUser} />;
  if (!dataCommissionReportForUser)
    return <Error error={t("error_query_failed")} />;

  const columns: Column<CommissionRowType>[] = [
    {
      title: t("customer"),
      field: "customerName",
    },
    {
      title: t("commission_case"),
      field: "case",
      render: (commissionReport) => {
        return t("commission_" + commissionReport.case);
      },
      customFilterAndSearch: (term: string, commissionReport) => {
        return doesMatchAllTerms(
          term,
          t("commission_" + commissionReport.case)
        );
      },
    },
    {
      title: t("reservation_id_header"),
      field: "reservationId",
      render: (commissionRow) => "#" + commissionRow.reservationId,
      customFilterAndSearch: (term: string, commissionRow) =>
        doesMatchAllTerms(term, "#" + commissionRow.reservationId),
    },
    {
      title: t("machine"),
      field: "machineName",
    },
    {
      title: t("date_range_header"),
      field: "dateFrom",
      type: "date",
      render: (commissionRow) => commissionsRenderDateRange(t, commissionRow),
      customFilterAndSearch: (term: string, commissionRow) =>
        commissionsFilterDateRange(t, term, commissionRow),
    },
    {
      title: t("commission_period_revenue"),
      field: "revenue",
      type: "numeric",
      render: (commissionReport) => {
        return formatNumber(commissionReport.revenue, 2);
      },
    },
    {
      title: t("discount_percent"),
      field: "discountPercent",
      type: "numeric",
      render: (commissionReport) => {
        return formatNumber(commissionReport.discountPercent, 2);
      },
    },
    {
      title: t("commission_percent"),
      field: "commissionPercent",
      type: "numeric",
      render: (commissionReport) => {
        return formatNumber(commissionReport.commissionPercent, 2);
      },
    },
    {
      title: t("commission_in_eur"),
      field: "commission",
      type: "currency",
      render: (commissionReport) => {
        return formatNumber(commissionReport.commission, 2);
      },
    },
  ];

  const columnsRentThrough: Column<CommissionRowRentThroughType>[] = [
    {
      title: t("customer"),
      field: "customerName",
    },
    {
      title: t("commission_case"),
      field: "case",
      render: (commissionRowRentThrough) => {
        return t("commission_" + commissionRowRentThrough.case);
      },
      customFilterAndSearch: (term: string, commissionRowRentThrough) => {
        return doesMatchAllTerms(
          term,
          t("commission_" + commissionRowRentThrough.case)
        );
      },
    },
    {
      title: t("reservation_id_header"),
      field: "reservationId",
      render: (commissionRowRentThrough) =>
        "#" + commissionRowRentThrough.reservationId,
      customFilterAndSearch: (term: string, commissionRowRentThrough) =>
        doesMatchAllTerms(term, "#" + commissionRowRentThrough.reservationId),
    },
    {
      title: t("machine"),
      field: "machineName",
    },
    {
      title: t("date_range_header"),
      field: "dateFrom",
      type: "date",
      render: (commissionRowRentThrough) =>
        commissionsRenderDateRange(t, commissionRowRentThrough),
      customFilterAndSearch: (term: string, commissionRowRentThrough) =>
        commissionsFilterDateRange(t, term, commissionRowRentThrough),
    },
    {
      title: t("commission_period_revenue"),
      field: "revenue",
      type: "numeric",
      render: (commissionRowRentThrough) => {
        return formatNumber(commissionRowRentThrough.revenue, 2);
      },
    },
    {
      title: t("rent_through_commission_case_YES"),
      field: "hasCommission",
      render: (commissionRowRentThrough) => {
        return commissionRowRentThrough.hasCommission ? t("yes") : t("no");
      },
    },
    {
      title: t("commission_percent"),
      field: "commissionPercent",
      type: "numeric",
      render: (commissionRowRentThrough) => {
        return formatNumber(commissionRowRentThrough.commissionPercent, 2);
      },
    },
    {
      title: t("commission_in_eur"),
      field: "commission",
      type: "currency",
      render: (commissionRowRentThrough) => {
        return formatNumber(commissionRowRentThrough.commission, 2);
      },
    },
  ];

  return (
    <div className="containerInner">
      <CommissionHeaderUserSalary
        user={userSelected}
        commissionUserSetting={commissionUserSetting}
      />
      <MaterialTable
        style={getTableStyle()}
        title={""}
        localization={tableLocalization}
        columns={columns}
        data={commissionRowsForUser}
        icons={tableIcons}
        options={getTableOptions()}
        components={{
          Body: (props) => {
            let totalObj = {
              revenue: 0,
              commission: 0,
            };
            props.renderData.forEach((commissionReport: CommissionRowType) => {
              totalObj.revenue += parseNumber(commissionReport.revenue);
              totalObj.commission += parseNumber(commissionReport.commission);
            });
            return (
              <>
                <MTableBody {...props} />
                <TableFooter>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell align="right">
                      {formatNumber(totalObj.revenue)}
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell align="right">
                      {formatNumber(totalObj.commission)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </>
            );
          },
        }}
      />
      {commissionRowsRentThroughForUser.length > 0 && (
        <div className="mt-4">
          <MaterialTable
            style={getTableStyle()}
            title={t("commission_rent_through")}
            localization={tableLocalization}
            columns={columnsRentThrough}
            data={commissionRowsRentThroughForUser}
            icons={tableIcons}
            options={getTableOptions()}
            components={{
              Body: (props) => {
                let totalObj = {
                  revenue: 0,
                  commission: 0,
                };
                props.renderData.forEach(
                  (commissionReport: CommissionRowRentThroughType) => {
                    totalObj.revenue += parseNumber(commissionReport.revenue);
                    totalObj.commission += parseNumber(
                      commissionReport.commission
                    );
                  }
                );
                return (
                  <>
                    <MTableBody {...props} />
                    <TableFooter>
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell align="right">
                          {formatNumber(totalObj.revenue)}
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell align="right">
                          {formatNumber(totalObj.commission)}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </>
                );
              },
            }}
          />
        </div>
      )}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CommissionPreviewForUser);
