import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Checkbox, createStyles, Theme } from "@material-ui/core";
import { addStringToArr, removeStringFromArr } from "../../utils/collections";
import { InvoiceableRowSources, InvoiceableRowType } from "../../entity/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faPollH,
} from "@fortawesome/pro-light-svg-icons";

interface Props extends WithStyles<typeof styles> {
  invoiceableRow: InvoiceableRowType;
  reservationIds: string[];
  setReservationIds: React.Dispatch<React.SetStateAction<string[]>>;
  transportInvoicingIds: string[];
  setTransportInvoicingIds: React.Dispatch<React.SetStateAction<string[]>>;
  transportInvoicingSummaryIds: string[];
  setTransportInvoicingSummaryIds: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  catalogExtraRowInvoiceIds: string[];
  setCatalogExtraRowInvoiceIds: React.Dispatch<React.SetStateAction<string[]>>;
  operationHourIds: string[];
  setOperationHourIds: React.Dispatch<React.SetStateAction<string[]>>;
}

function InvoiceableRowCellFirst({
  classes,
  invoiceableRow,
  reservationIds,
  setReservationIds,
  transportInvoicingIds,
  setTransportInvoicingIds,
  transportInvoicingSummaryIds,
  setTransportInvoicingSummaryIds,
  catalogExtraRowInvoiceIds,
  setCatalogExtraRowInvoiceIds,
  operationHourIds,
  setOperationHourIds,
}: Props) {
  return (
    <div>
      {invoiceableRow.rowSource === InvoiceableRowSources.Insurance && (
        <FontAwesomeIcon icon={faPollH} />
      )}
      {invoiceableRow.rowSource === InvoiceableRowSources.MachineBreakdown && (
        <FontAwesomeIcon icon={faExclamationTriangle} />
      )}
      {invoiceableRow.rowSource ===
        InvoiceableRowSources.CatalogExtraRowInvoice && (
        <Checkbox
          checked={catalogExtraRowInvoiceIds.includes(
            invoiceableRow.identifier
          )}
          onChange={() => {
            if (catalogExtraRowInvoiceIds.includes(invoiceableRow.identifier)) {
              setCatalogExtraRowInvoiceIds(
                removeStringFromArr(
                  catalogExtraRowInvoiceIds,
                  invoiceableRow.identifier
                )
              );
            } else {
              setCatalogExtraRowInvoiceIds(
                addStringToArr(
                  catalogExtraRowInvoiceIds,
                  invoiceableRow.identifier
                )
              );
            }
          }}
        />
      )}
      {invoiceableRow.rowSource === InvoiceableRowSources.OperationHour && (
        <Checkbox
          checked={operationHourIds.includes(invoiceableRow.identifier)}
          onChange={() => {
            if (operationHourIds.includes(invoiceableRow.identifier)) {
              setOperationHourIds(
                removeStringFromArr(operationHourIds, invoiceableRow.identifier)
              );
            } else {
              setOperationHourIds(
                addStringToArr(operationHourIds, invoiceableRow.identifier)
              );
            }
          }}
        />
      )}
      {invoiceableRow.rowSource === InvoiceableRowSources.Reservation && (
        <Checkbox
          checked={reservationIds.includes(invoiceableRow.identifier)}
          onChange={() => {
            if (reservationIds.includes(invoiceableRow.identifier)) {
              setReservationIds(
                removeStringFromArr(reservationIds, invoiceableRow.identifier)
              );
            } else {
              setReservationIds(
                addStringToArr(reservationIds, invoiceableRow.identifier)
              );
            }
          }}
        />
      )}
      {invoiceableRow.rowSource ===
        InvoiceableRowSources.TransportInvoicing && (
        <Checkbox
          checked={transportInvoicingIds.includes(invoiceableRow.identifier)}
          onChange={() => {
            if (transportInvoicingIds.includes(invoiceableRow.identifier)) {
              setTransportInvoicingIds(
                removeStringFromArr(
                  transportInvoicingIds,
                  invoiceableRow.identifier
                )
              );
            } else {
              setTransportInvoicingIds(
                addStringToArr(transportInvoicingIds, invoiceableRow.identifier)
              );
            }
          }}
        />
      )}
      {invoiceableRow.rowSource ===
        InvoiceableRowSources.TransportInvoicingSummary && (
        <Checkbox
          checked={transportInvoicingSummaryIds.includes(
            invoiceableRow.identifier
          )}
          onChange={() => {
            if (
              transportInvoicingSummaryIds.includes(invoiceableRow.identifier)
            ) {
              setTransportInvoicingSummaryIds(
                removeStringFromArr(
                  transportInvoicingSummaryIds,
                  invoiceableRow.identifier
                )
              );
            } else {
              setTransportInvoicingSummaryIds(
                addStringToArr(
                  transportInvoicingSummaryIds,
                  invoiceableRow.identifier
                )
              );
            }
          }}
        />
      )}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InvoiceableRowCellFirst);
