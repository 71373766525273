import {
  MachineBreakdownType,
  MaintenanceMaintenanceType,
  MaintenanceType,
  OrderStatus,
  ReservationType,
} from "../../entity/types";
import { datesEqual, newDate, resetDateTime } from "../dates";
import React from "react";
import format from "date-fns/format";
import { DATE_FORMAT_ISO, ID_EMPTY } from "../constants";

export const getCellClassName = (
  date: Date,
  reservations: ReservationType[],
  machineBreakdowns: MachineBreakdownType[],
  maintenances: MaintenanceType[]
): string => {
  let className = ``;
  let today = resetDateTime(new Date());

  let countReservationsOffer = 0;
  let countReservationsAdvance = 0;
  let countReservationsReserved = 0;
  reservations.forEach((reservationLooped) => {
    if (reservationLooped.order.status === OrderStatus.Offer) {
      countReservationsOffer++;
    } else if (reservationLooped.order.status === OrderStatus.Advance) {
      countReservationsAdvance++;
    } else {
      countReservationsReserved++;
    }
  });

  if (date.getDay() === 0) {
    className += " calCellSunday";
  }
  if (date.getDay() === 6) {
    className += " calCellSaturday";
  }
  if (datesEqual(date, today)) {
    className += " calCellToday";
  }
  if (countReservationsOffer > 0) {
    className += " calCellWithOffer";
  }
  if (countReservationsAdvance > 0) {
    className += " calCellWithAdvance";
  }
  if (countReservationsReserved > 0) {
    className += " calCellWithReservation";
  }

  return className;
};

export const getCellTitle = (
  date: Date,
  reservations: ReservationType[]
): string => {
  let title = format(date, "dd.MM.yyyy");
  reservations.forEach((reservationLooped) => {
    title = title + "\n" + reservationLooped.order.customer?.name;
  });
  return title;
};

export const getLocalStorageOrderId = () => {
  const orderId = localStorage.getItem("orderId");
  return orderId === null || orderId === "" ? ID_EMPTY : String(orderId);
};

export const setLocalStorageOrderId = (orderId: string) => {
  localStorage.setItem("orderId", orderId);
};

export const setLocalStorageCustomerId = (customerId: string) => {
  localStorage.setItem("customerId", customerId);
};

export const getLocalStorageCustomerId = () =>
  localStorage.getItem("customerId") === null ||
  localStorage.getItem("customerId") === ""
    ? ID_EMPTY
    : String(localStorage.getItem("customerId"));

export const selectOrder = (
  orderId: string,
  setOrderId: React.Dispatch<React.SetStateAction<string>>
) => {
  setLocalStorageOrderId(orderId);
  setOrderId(orderId);
};

export type reservationsByDateType = {
  [keyId: string]: { [date: string]: ReservationType[] };
};

const createReservationsByDate = (
  reservationsByDate: reservationsByDateType,
  reservation: ReservationType,
  keyId: string
) => {
  let date = newDate(reservation.dateRented);
  // check for reservation that has not been returned ....
  let dateReturned =
    newDate(reservation.dateReturned) >= newDate(new Date()) ||
    !reservation.giveAt
      ? newDate(reservation.dateReturned)
      : reservation.returnAt
      ? newDate(reservation.returnAt)
      : new Date();

  while (date <= dateReturned) {
    let keyDate = format(date, DATE_FORMAT_ISO);
    if (typeof reservationsByDate[keyId] === "undefined") {
      reservationsByDate[keyId] = {};
    }
    if (typeof reservationsByDate[keyId][keyDate] === "undefined") {
      reservationsByDate[keyId][keyDate] = [];
    }

    reservationsByDate[keyId][keyDate].push(reservation);

    date.setDate(date.getDate() + 1);
  }
  return reservationsByDate;
};

export const createReservationsByMachineDate = (
  reservations: ReservationType[]
) => {
  let reservationsByDate: reservationsByDateType = {};

  for (let reservation of reservations) {
    if (!reservation.catalogExtraRowRental && reservation.machine) {
      reservationsByDate = createReservationsByDate(
        reservationsByDate,
        reservation,
        reservation.machine.id
      );
    }
  }

  return reservationsByDate;
};

export const createReservationsByRowExtraDate = (
  reservations: ReservationType[]
) => {
  let reservationsByDate: reservationsByDateType = {};

  for (let reservation of reservations) {
    if (reservation.catalogExtraRowRental) {
      reservationsByDate = createReservationsByDate(
        reservationsByDate,
        reservation,
        reservation.catalogExtraRowRental.id
      );
    }
  }

  return reservationsByDate;
};

type breakdownsByDateType = {
  [machine_id: string]: { [date: string]: MachineBreakdownType[] };
};

const createBreakdownsByDate = (
  breakdownsByDate: breakdownsByDateType,
  machineBreakdown: MachineBreakdownType,
  keyId: string
) => {
  let date = newDate(machineBreakdown.fixByStart);
  let dateReturned = newDate(machineBreakdown.fixByEnd);
  if (machineBreakdown.fixedAt !== null) {
    let dateFixedAt = newDate(machineBreakdown.fixedAt);
    if (dateFixedAt < date) {
      date = dateFixedAt;
    } else if (dateFixedAt > dateReturned) {
      dateReturned = dateFixedAt;
    }
  }

  date = resetDateTime(date);
  dateReturned = resetDateTime(dateReturned);

  while (date <= dateReturned) {
    let keyDate = format(date, DATE_FORMAT_ISO);
    if (typeof breakdownsByDate[keyId] === "undefined") {
      breakdownsByDate[keyId] = {};
    }
    if (typeof breakdownsByDate[keyId][keyDate] === "undefined") {
      breakdownsByDate[keyId][keyDate] = [];
    }

    breakdownsByDate[keyId][keyDate].push(machineBreakdown);

    date.setDate(date.getDate() + 1);
  }

  return breakdownsByDate;
};

export const createBreakdownsByMachineDate = (
  machineBreakdowns: MachineBreakdownType[]
) => {
  let breakdownsByDate: breakdownsByDateType = {};

  machineBreakdowns.forEach((machineBreakdown) => {
    if (!machineBreakdown.catalogExtraRowRental && machineBreakdown.machine) {
      breakdownsByDate = createBreakdownsByDate(
        breakdownsByDate,
        machineBreakdown,
        machineBreakdown.machine.id
      );
    }
  });

  return breakdownsByDate;
};

export const createBreakdownsByRowExtraDate = (
  machineBreakdowns: MachineBreakdownType[]
) => {
  let breakdownsByDate: breakdownsByDateType = {};

  machineBreakdowns.forEach((machineBreakdown) => {
    if (machineBreakdown.catalogExtraRowRental) {
      breakdownsByDate = createBreakdownsByDate(
        breakdownsByDate,
        machineBreakdown,
        machineBreakdown.catalogExtraRowRental.id
      );
    }
  });

  return breakdownsByDate;
};

type maintenanceByDateType = {
  [machine_id: string]: { [date: string]: MaintenanceType[] };
};

const createMaintenancesByDate = (
  maintenancesByDate: maintenanceByDateType,
  maintenance: MaintenanceType,
  keyId: string
) => {
  let date = newDate(
    maintenance.maintenanceType === MaintenanceMaintenanceType.Scheduled
      ? maintenance.serviceStartBy
      : maintenance.estimatedDate
  );
  let dateReturned = newDate(
    maintenance.maintenanceType === MaintenanceMaintenanceType.Scheduled
      ? maintenance.serviceEndBy
      : maintenance.estimatedDate
  );
  if (maintenance.servicedAt !== null) {
    let dateServicedAt = newDate(maintenance.servicedAt);
    if (dateServicedAt < date) {
      date = dateServicedAt;
    } else if (dateServicedAt > dateReturned) {
      dateReturned = dateServicedAt;
    }
  }

  date = resetDateTime(date);
  dateReturned = resetDateTime(dateReturned);

  while (date <= dateReturned) {
    let keyDate = format(date, DATE_FORMAT_ISO);
    if (typeof maintenancesByDate[keyId] === "undefined") {
      maintenancesByDate[keyId] = {};
    }
    if (typeof maintenancesByDate[keyId][keyDate] === "undefined") {
      maintenancesByDate[keyId][keyDate] = [];
    }

    maintenancesByDate[keyId][keyDate].push(maintenance);

    date.setDate(date.getDate() + 1);
  }

  return maintenancesByDate;
};

export const createMaintenancesByMachineDate = (
  maintenances: MaintenanceType[]
) => {
  let maintenancesByDate: maintenanceByDateType = {};

  maintenances.forEach((maintenance) => {
    if (!maintenance.catalogExtraRowRental && maintenance.machine) {
      maintenancesByDate = createMaintenancesByDate(
        maintenancesByDate,
        maintenance,
        maintenance.machine.id
      );
    }
  });

  return maintenancesByDate;
};

export const createMaintenancesByRowExtraDate = (
  maintenances: MaintenanceType[]
) => {
  let maintenancesByDate: maintenanceByDateType = {};

  maintenances.forEach((maintenance) => {
    if (maintenance.catalogExtraRowRental) {
      maintenancesByDate = createMaintenancesByDate(
        maintenancesByDate,
        maintenance,
        maintenance.catalogExtraRowRental.id
      );
    }
  });

  return maintenancesByDate;
};
