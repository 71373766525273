import React from "react";
import { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Box, createStyles, Tab, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import HeaderPage from "../components/Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {}

function IdentificationComplete({ classes }: Props) {
  const { t } = useTranslation();
  const [tabSelected, setTabSelected] = useState("0");
  // TODO Get message from the operator here
  return (
    <Container className="containerMain">
      <HeaderPage title={t("operator_main_page")} />
      <div className="containerInner">
          `Tunnistus valmis, voit sulkea tämän selaimen sivun.`
      </div>
    </Container>
  );
}
const styles = (theme: Theme) => createStyles({});
export default withStyles(styles)(IdentificationComplete);