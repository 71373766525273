import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Box, createStyles, Tab, Theme } from "@material-ui/core";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import {
  GET_USERS_SIMPLE_QUERY,
  QueryResultUsersSimple,
} from "../apollo/queries/users";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import CommissionSettingsForUser from "../components/Commission/CommissionSettingsForUser";
import { WithStyles } from "@material-ui/core/styles";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import {
  GET_COMMISSION_TEMPLATES_QUERY,
  QueryResultCommissionTemplates,
} from "../apollo/queries/commissions";
import CommissionTemplate from "../components/Commission/CommissionTemplate";
import CreateCommissionTemplate from "../components/Commission/CreateCommissionTemplate";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import CommissionLevels from "../components/Commission/CommissionLevels";
import HeaderPage from "../components/Shared/HeaderPage";
import CommissionSettings from "../components/Commission/CommissionSettings";

interface Props extends WithStyles<typeof styles> {}

function SettingsCommissions({ classes }: Props) {
  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = useState("0");

  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers,
  } = useQuery<QueryResultUsersSimple>(GET_USERS_SIMPLE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("users"),
  });

  const {
    loading: loadingTemplates,
    error: errorTemplates,
    data: dataTemplates,
  } = useQuery<QueryResultCommissionTemplates>(GET_COMMISSION_TEMPLATES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("commissionTemplates"),
  });

  if (loadingUsers || loadingTemplates) return <Loading />;
  if (errorUsers) return <Error error={errorUsers} />;
  if (errorTemplates) return <Error error={errorTemplates} />;
  if (!dataUsers || !dataTemplates)
    return <Error error={t("error_query_failed")} />;

  const handleChange = (event: React.ChangeEvent<{}>, newTab: string) => {
    setTabSelected(newTab);
  };

  const usersAll = dataUsers.users ? dataUsers.users : [];

  return (
    <Container className="containerMain">
      <HeaderPage title={t("commissions")} />
      <div className="containerInner">
        <CommissionSettings />
        <TabContext value={tabSelected}>
          <Box>
            <TabList onChange={handleChange}>
              <Tab label={t("commission_templates")} value="0" />
              <Tab label={t("commission_levels")} value="1" />
              <Tab label={t("users")} value="2" />
            </TabList>
          </Box>
          <TabPanel value="0">
            {dataTemplates.commissionTemplates?.map((commissionTemplate) => (
              <CommissionTemplate
                key={commissionTemplate.id}
                commissionTemplate={commissionTemplate}
              />
            ))}
            <CreateCommissionTemplate />
          </TabPanel>
          <TabPanel value="1">
            <CommissionLevels />
          </TabPanel>
          <TabPanel value="2">
            {usersAll.map((userLooped) => (
              <CommissionSettingsForUser
                key={userLooped.id}
                userSeller={userLooped}
                commissionTemplates={
                  dataTemplates.commissionTemplates
                    ? dataTemplates.commissionTemplates
                    : []
                }
              />
            ))}
          </TabPanel>
        </TabContext>
      </div>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(SettingsCommissions);
