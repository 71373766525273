import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  Mutation,
  MutationUpdateReservationStampsArgs,
  ReservationStatus,
  ReservationType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/pro-light-svg-icons";
import ButtonLoad from "../Shared/ButtonLoad";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import { UPDATE_RESERVATION_STAMPS_MUTATION } from "../../apollo/mutations/reservations";
import { handleError } from "../../entity/ErrorHandler";
import { updateCacheReservationStampsMutation } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationType;
  setOpenDialogReturnCheck: React.Dispatch<React.SetStateAction<boolean>>;
}

function ReservationButtonReturnChecked({
  classes,
  reservation,
  setOpenDialogReturnCheck,
}: Props) {
  const { t } = useTranslation();

  const [updateReservationStamps, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateReservationStampsArgs
  >(UPDATE_RESERVATION_STAMPS_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: () => {
      setOpenDialogReturnCheck(false);
    },
    update: (cache) => {
      updateCacheReservationStampsMutation(cache);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditReservation = checkPermission(myPermissions, [
    "reservations.change_reservation",
  ]);
  const hasPermissionSetReturnCheckedAt = checkPermission(myPermissions, [
    "reservations.set_return_checked_at",
  ]);

  const onClickGiveCheckedAt = () => {
    updateReservationStamps({
      variables: {
        reservationId: reservation.id,
        userGiveId: reservation.userGive ? reservation.userGive.id : undefined,
        userReturnId: reservation.userReturn
          ? reservation.userReturn.id
          : undefined,
        giveCheckedAt: reservation.giveCheckedAt,
        giveImagesAt: reservation.giveImagesAt,
        giveInstructedAt: reservation.giveInstructedAt,
        giveAt: reservation.giveAt,
        returnAt: reservation.returnAt,
        returnImagesAt: reservation.returnImagesAt,
        returnCheckedCatalogAt: reservation.returnCheckedCatalogAt,
        returnCheckedPreAt: reservation.returnCheckedPreAt,
        returnCheckedAt: reservation.returnCheckedAt ? null : new Date(),
        returnStoredAt: reservation.returnStoredAt,
      },
    });
  };

  const onClickGiveCheckedPreAt = () => {
    updateReservationStamps({
      variables: {
        reservationId: reservation.id,
        userGiveId: reservation.userGive ? reservation.userGive.id : undefined,
        userReturnId: reservation.userReturn
          ? reservation.userReturn.id
          : undefined,
        giveCheckedAt: reservation.giveCheckedAt,
        giveImagesAt: reservation.giveImagesAt,
        giveInstructedAt: reservation.giveInstructedAt,
        giveAt: reservation.giveAt,
        returnAt: reservation.returnAt,
        returnImagesAt: reservation.returnImagesAt,
        returnCheckedCatalogAt: reservation.returnCheckedCatalogAt,
        returnCheckedPreAt: reservation.returnCheckedPreAt ? null : new Date(),
        returnCheckedAt: reservation.returnCheckedAt,
        returnStoredAt: reservation.returnStoredAt,
      },
    });
  };

  const isDonePre = Boolean(reservation.returnCheckedPreAt);
  const isDone = Boolean(reservation.returnCheckedAt);

  return (
    <>
      <ButtonLoad
        loading={loadingUpdate}
        className="mb-1 me-1"
        key="returnCheckedPreAt"
        variant={isDonePre ? "primary" : "danger"}
        onClick={onClickGiveCheckedPreAt}
        disabled={
          !hasPermissionEditReservation ||
          (isDonePre && reservation.status === ReservationStatus.Invoiced) ||
          !reservation.returnCheckedCatalogAt
        }
      >
        <FontAwesomeIcon icon={faTasks} className="d-none d-sm-inline" />
        <span className="ms-2">{t("toggle_return_checked_pre_at")}</span>
      </ButtonLoad>
      <ButtonLoad
        loading={loadingUpdate}
        className="mb-1 me-1"
        key="returnCheckedAt"
        variant={isDone ? "primary" : "danger"}
        onClick={onClickGiveCheckedAt}
        disabled={
          !hasPermissionEditReservation ||
          (isDone && reservation.status === ReservationStatus.Invoiced) ||
          !reservation.returnCheckedCatalogAt ||
          !reservation.returnCheckedPreAt ||
          !hasPermissionSetReturnCheckedAt
        }
      >
        <FontAwesomeIcon icon={faTasks} className="d-none d-sm-inline" />
        <span className="ms-2">{t("toggle_return_checked_at")}</span>
      </ButtonLoad>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ReservationButtonReturnChecked);
