import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  Mutation,
  MutationUpdateReservationStampsArgs,
  ReservationStatus,
  ReservationType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarehouseAlt } from "@fortawesome/pro-light-svg-icons";
import ButtonLoad from "../Shared/ButtonLoad";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import { UPDATE_RESERVATION_STAMPS_MUTATION } from "../../apollo/mutations/reservations";
import { handleError } from "../../entity/ErrorHandler";
import { ImageFieldType } from "./DialogReservationWorkQueueImages";
import { updateCacheReservationStampsMutation } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationType;
  field: ImageFieldType;
  disabled: boolean;
  setOpenDialogOperationHours: React.Dispatch<React.SetStateAction<boolean>>;
}

function ReservationButtonImagesAt({
  classes,
  reservation,
  field,
  disabled,
  setOpenDialogOperationHours,
}: Props) {
  const { t } = useTranslation();

  const [updateReservationStamps, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateReservationStampsArgs
  >(UPDATE_RESERVATION_STAMPS_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: () => {
      setOpenDialogOperationHours(false);
    },
    update: (cache) => {
      updateCacheReservationStampsMutation(cache);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditReservation = checkPermission(myPermissions, [
    "reservations.change_reservation",
  ]);

  const onClick = () => {
    const keyField = field as keyof MutationUpdateReservationStampsArgs;

    let variables: MutationUpdateReservationStampsArgs = {
      reservationId: reservation.id,
      userGiveId: reservation.userGive ? reservation.userGive.id : undefined,
      userReturnId: reservation.userReturn
        ? reservation.userReturn.id
        : undefined,
      giveCheckedAt: reservation.giveCheckedAt,
      giveImagesAt: reservation.giveImagesAt,
      giveInstructedAt: reservation.giveInstructedAt,
      giveAt: reservation.giveAt,
      returnAt: reservation.returnAt,
      returnImagesAt: reservation.returnImagesAt,
      returnCheckedCatalogAt: reservation.returnCheckedCatalogAt,
      returnCheckedPreAt: reservation.returnCheckedPreAt,
      returnCheckedAt: reservation.returnCheckedAt,
      returnStoredAt: reservation.returnStoredAt,
    };

    variables[keyField] = variables[keyField] ? null : new Date();

    updateReservationStamps({
      variables: variables,
    });
  };

  const isDone = Boolean(reservation[field as keyof ReservationType]);

  return (
    <ButtonLoad
      loading={loadingUpdate}
      variant={isDone ? "primary" : "danger"}
      onClick={onClick}
      disabled={
        disabled ||
        !hasPermissionEditReservation ||
        (isDone && reservation.status === ReservationStatus.Invoiced)
      }
    >
      <FontAwesomeIcon icon={faWarehouseAlt} />
      <span className="ms-2">
        {t(
          field === "giveImagesAt"
            ? "toggle_give_images_at"
            : "toggle_return_images_at"
        )}
      </span>
    </ButtonLoad>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ReservationButtonImagesAt);
