import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
} from "@material-ui/core";
import {
  Mutation,
  MutationUploadReservationImageBasicArgs,
  ReservationType,
} from "../../entity/types";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import { useMutation } from "@apollo/client";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { UPLOAD_RESERVATION_IMAGE_BASIC_MUTATION } from "../../apollo/mutations/reservations";
import { handleError } from "../../entity/ErrorHandler";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import ReservationImagesBasic from "./ReservationImagesBasic";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reservation: ReservationType;
}

function DialogReservationImages({
  classes,
  open,
  setOpen,
  reservation,
}: Props) {
  const { t } = useTranslation();

  const [uploadReservationImageBasic, { loading: loadingUpload }] = useMutation<
    Mutation,
    MutationUploadReservationImageBasicArgs
  >(UPLOAD_RESERVATION_IMAGE_BASIC_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("reservationImageBasics"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "reservations.add_reservationimagebasic",
  ]);

  function onChange({
    target: { validity, files },
  }: React.ChangeEvent<HTMLInputElement>) {
    if (files && validity.valid) {
      uploadReservationImageBasic({
        variables: {
          reservationId: reservation.id,
          file: files[0],
        },
      });
    }
  }

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitleWithClose
        id="dialogReservationImages"
        onClose={() => setOpen(false)}
      >
        {t("reservation_images")}
      </DialogTitleWithClose>
      <DialogContent className={loadingUpload ? "loading" : ""}>
        {hasPermissionAdd && (
          <div className={classes.conUpload}>
            <input type="file" required onChange={onChange} accept="image/*" />
          </div>
        )}
        <ReservationImagesBasic reservationId={reservation.id} />
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    conUpload: {
      marginBottom: spacing(2),
    },
  });

export default withStyles(styles)(DialogReservationImages);
