import { gql } from "@apollo/client";

export const CREATE_DISCOUNT_LIST_MUTATION = gql`
  mutation createDiscountList(
    $name: String!
    $isBusiness: Boolean!
    $catalogId: ID!
  ) {
    createDiscountList(
      name: $name
      isBusiness: $isBusiness
      catalogId: $catalogId
    ) {
      discountList {
        id
      }
    }
  }
`;

export const UPDATE_DISCOUNT_LIST_MUTATION = gql`
  mutation updateDiscountList(
    $discountListId: ID!
    $name: String!
    $isBusiness: Boolean!
    $catalogId: ID!
  ) {
    updateDiscountList(
      discountListId: $discountListId
      name: $name
      isBusiness: $isBusiness
      catalogId: $catalogId
    ) {
      discountList {
        id
        name
        isBusiness
        catalog {
          id
        }
      }
    }
  }
`;

export const DELETE_DISCOUNT_LIST_MUTATION = gql`
  mutation deleteDiscountList($discountListId: ID!) {
    deleteDiscountList(discountListId: $discountListId) {
      discountListId
    }
  }
`;

export const CREATE_DISCOUNT_ROW_MUTATION = gql`
  mutation createDiscountRow(
    $percent: String!
    $showPrint: Boolean!
    $discountListId: ID!
    $catalogRowId: ID!
  ) {
    createDiscountRow(
      percent: $percent
      showPrint: $showPrint
      discountListId: $discountListId
      catalogRowId: $catalogRowId
    ) {
      discountRow {
        id
      }
    }
  }
`;

export const UPDATE_DISCOUNT_ROW_MUTATION = gql`
  mutation updateDiscountRow(
    $discountRowId: ID!
    $percent: String!
    $showPrint: Boolean!
    $discountListId: ID!
    $catalogRowId: ID!
  ) {
    updateDiscountRow(
      discountRowId: $discountRowId
      percent: $percent
      showPrint: $showPrint
      discountListId: $discountListId
      catalogRowId: $catalogRowId
    ) {
      discountRow {
        id
        percent
        showPrint
        discountList {
          id
        }
        catalogRow {
          id
        }
      }
    }
  }
`;

export const UPDATE_DISCOUNT_ROW_PERCENTS_PRINTS_MUTATION = gql`
  mutation updateDiscountRowPercentsPrints(
    $idPercentPrintInput: [IdPercentPrintInputType]
  ) {
    updateDiscountRowPercentsPrints(idPercentPrintInput: $idPercentPrintInput) {
      success
    }
  }
`;

export const LOCK_DISCOUNT_LIST_MUTATION = gql`
  mutation lockDiscountList($discountListId: ID!) {
    lockDiscountList(discountListId: $discountListId) {
      discountList {
        id
        name
        isBusiness
        locked
        isOffer
        discountrowSet {
          id
          percent
          showPrint
          catalogRow {
            id
            catalogCategory {
              id
              catalogCategoryUpper {
                id
                catalog {
                  id
                }
              }
            }
          }
        }
        catalog {
          id
          catalogcategoryupperSet {
            id
            name
            catalogcategorySet {
              id
              name
              catalogrowSet {
                id
                name
                priceDayCompany
                priceMonthCompany
                priceDayPrivate
                priceMonthPrivate
              }
            }
          }
        }
      }
    }
  }
`;

export const CLONE_DISCOUNT_LIST_MUTATION = gql`
  mutation cloneDiscountList($discountListId: ID!, $customerId: ID!) {
    cloneDiscountList(
      discountListId: $discountListId
      customerId: $customerId
    ) {
      discountList {
        id
      }
    }
  }
`;

export const CLONE_DISCOUNT_LIST_FROM_CATALOG_MUTATION = gql`
  mutation cloneDiscountListFromCatalog($catalogId: ID!, $customerId: ID!) {
    cloneDiscountListFromCatalog(
      catalogId: $catalogId
      customerId: $customerId
    ) {
      discountList {
        id
      }
    }
  }
`;
