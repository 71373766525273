import Toastify from "toastify-js";
import { ApolloError } from "@apollo/client";

export function handleError(error: ApolloError | string) {
  let errorText = typeof error === "string" ? error : error.message;
  Toastify({
    text: errorText,
    backgroundColor: "linear-gradient(to right, #953734, #632423)",
    duration: 10000,
    close: true,
  }).showToast();
}

export function handleMessage(message: string) {
  Toastify({
    text: message,
    backgroundColor: "linear-gradient(to right, #449534, #2f6323)",
    duration: 10000,
    close: true,
  }).showToast();
}
