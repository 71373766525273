import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { OrderType } from "../../entity/types";
import OrderPdfButtons from "../Shared/OrderPdfButtons";

interface Props extends WithStyles<typeof styles> {
  orders: OrderType[];
  driveId: string;
}

function WorkQueuePdfButtons({ classes, driveId, orders }: Props) {
  return (
    <div className={classes.con}>
      <OrderPdfButtons driveId={driveId} orders={orders} />
    </div>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    con: { position: "absolute", top: spacing(1), right: spacing(10) },
  });

export default withStyles(styles)(WorkQueuePdfButtons);
