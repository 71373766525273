import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { UserType } from "../../entity/types";

interface Props extends WithStyles<typeof styles> {
  user: UserType;
}

const AvatarUser = ({ classes, user }: Props) => {
  return (
    <Avatar className={classes.darkGreyAvatar}>
      {user.firstName}
    </Avatar>
  );
};

const styles = ({ spacing }: Theme) =>
  createStyles({
    darkGreyAvatar: {
      backgroundColor: "#232321",
      color: "#FFFFFF",
      width: "50px",
      height: "50px",
    },
  });

export default withStyles(styles)(AvatarUser);
