import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  Menu,
  MenuItem,
  Theme,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import {
  GroupType,
  IdentificationRequestType,
  LocationType,
  Mutation,
  MutationDeleteIdentificationRequestArgs,
  MutationUpdateIdentificationRequestArgs,
  MutationCompleteIdentificationRequestArgs
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import {
  DELETE_IDENTIFICATION_REQUEST_MUTATION,
  UPDATE_IDENTIFICATIONREQUEST,
  COMPLETE_IDENTIFICATIONREQUEST
} from "../../apollo/mutations/identification_requests";
import DialogContentSimpleIdentificationRequest from "./DialogContentSimpleIdentificationRequest";
import { Button as ButtonBootstrap } from "react-bootstrap";
import { SimpleIdentificationRequestEmpty } from "../../entity/empties";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { dialogConfirm } from "../../utils/dialogs";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { GET_IDENTIFICATION_REQUESTS_BY_STATE } from "../../apollo/queries/identification_requests";

interface Props extends WithStyles<typeof styles> {
  identificationRequest: IdentificationRequestType;
}

const IdentificationRequestListMenuForNew = ({ classes, identificationRequest }: Props) => {
  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [identificationRequestSelected, setIdentificationRequestSelected] = useState<IdentificationRequestType>(SimpleIdentificationRequestEmpty);
 //  setIdentificationRequestSelected(identificationRequest);

  const [deleteIdentificationRequest, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteIdentificationRequestArgs
  >(DELETE_IDENTIFICATION_REQUEST_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("identificationRequests"),
      });
    },
  });

  const [updateIdentificationRequest] = useMutation<Mutation, MutationUpdateIdentificationRequestArgs>(
    UPDATE_IDENTIFICATIONREQUEST,
    {
      onCompleted: (result) => {
        setIdentificationRequestSelected(SimpleIdentificationRequestEmpty);
        setOpenEdit(false);
      },
      onError: (error) => {
        handleError(error);
      },
      update: (cache) => {
        cache.evict({
          id: ROOT_QUERY,
          fieldName: getQueryKey("identificationRequests")
        });
      },
    }
  );


  const [completeIdentificationRequest] = useMutation<Mutation, MutationCompleteIdentificationRequestArgs>(
    COMPLETE_IDENTIFICATIONREQUEST,
    {
      onCompleted: (result) => {
        setIdentificationRequestSelected(SimpleIdentificationRequestEmpty);
        setOpenEdit(false);
      },
      onError: (error) => {
        handleError(error);
      },
      update: (cache) => {
        cache.evict({
          id: ROOT_QUERY,
          fieldName: getQueryKey("identificationRequests")
        });
        /*cache.evict({
          id: ROOT_QUERY,
          fieldName: getQueryKey("identificationRequests")
        });*/
        /*completeIdentificationRequest({
          variables: {
            id:  identificationRequest.id
          },
        });*/
      },
    }
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = true; /*checkPermission(myPermissions, [
    "auth.change_user",
  ]);*/
  const hasPermissionDelete = true; /*checkPermission(myPermissions, [
    "auth.delete_user",
  ]);*/
  const hasPermissionCopyLink = true;

  if (!hasPermissionEdit && !hasPermissionDelete) {
    return <></>;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickEdit = () => {
    handleClose();
    // identificationRequest.password = "";
    setIdentificationRequestSelected(identificationRequestSelected);
    setOpenEdit(true);
  };
  const handleClickCopyLink = () => {
    navigator.clipboard.writeText(identificationRequest.identificationLink);
    handleClose();    
  }

  const handleClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteIdentificationRequest({ 
        variables: { id: identificationRequest.id },
        refetchQueries: [{
          query: GET_IDENTIFICATION_REQUESTS_BY_STATE, 
          variables: { state: "new" }}]

      }).then(() => { handleClose(); });
   }
  );
  };

  const handleSubmitEdit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateIdentificationRequest({
      variables: {
        id: identificationRequestSelected.id
      },
    });
  };

  /* not yet needed:
          {hasPermissionEdit && (
          <MenuItem onClick={handleClickEdit}>{t("edit")}</MenuItem>
        )}*/
  return (
    <div>
      <Button
        className="btnMaterialTableMenu"
        aria-controls="menuUser"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <FontAwesomeIcon size="lg" icon={faEllipsisV} />
      </Button>
      <Menu
        id="menuUser"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {hasPermissionCopyLink && (
          <MenuItem onClick={handleClickCopyLink}>{t("copy_link")}</MenuItem>
        )}
        {hasPermissionDelete && (
          <MenuItem
            className={loadingDelete ? "loading" : ""}
            onClick={handleClickDelete}
          >
            {t("delete")}
          </MenuItem>
        )}
      </Menu>
      <Dialog open={openEdit}>
        <form onSubmit={(event) => handleSubmitEdit(event)}>§
          <DialogTitle>{t("edit_identification_request")}</DialogTitle>
          <DialogContentSimpleIdentificationRequest
            identificationRequest={identificationRequestSelected}
            setIdentificationRequest={setIdentificationRequestSelected}
          />
          <DialogActions>
            <ButtonBootstrap
              onClick={() => setOpenEdit(false)}
              variant="secondary"
            >
              {t("cancel")}
            </ButtonBootstrap>
            <ButtonBootstrap
              type="submit"
              variant="primary"
            >
              {t("save")}
            </ButtonBootstrap>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(IdentificationRequestListMenuForNew);
