import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  CatalogExtraCategoryUpperRentalType,
  CatalogExtraRowRentalType,
  LocationType,
  Mutation,
  MutationDeleteCatalogExtraRowRentalArgs,
  MutationUpdateCatalogExtraRowRentalArgs,
} from "../../../entity/types";
import { formatNumber, parseNumber } from "../../../utils/formatting";
import { PermissionsContext, SettingsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { useMutation } from "@apollo/client";
import { handleError } from "../../../entity/ErrorHandler";
import {
  DELETE_CATALOG_EXTRA_ROW_RENTAL_MUTATION,
  UPDATE_CATALOG_EXTRA_ROW_RENTAL_MUTATION,
} from "../../../apollo/mutations/catalogs_extra";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faTasks,
  faEdit,
  faWrench,
} from "@fortawesome/pro-light-svg-icons";
import DialogCatalogExtraRowRentalMachine from "../DialogCatalogExtraRowRentalMachine";
import { dialogConfirm } from "../../../utils/dialogs";
import { ID_EMPTY, ROOT_QUERY } from "../../../utils/constants";
import DialogCatalogExtraRowRental from "../DialogCatalogExtraRowRental";
import { getQueryKey } from "../../../utils/cache";
import ButtonLoad from "../../Shared/ButtonLoad";
import MaintenanceSettingsCatalogExtraRowRental from "../../Maintenance/MaintenanceSettingsCatalogExtraRowRental";
import TextFieldFocus from "../../Shared/TextFieldFocus";

const getCatalogExtraRowRentalFormatted = (
  catalogExtraRowRental: CatalogExtraRowRentalType
) => {
  return {
    ...catalogExtraRowRental,
    priceDayCompany: formatNumber(catalogExtraRowRental.priceDayCompany, 2),
    priceDayPrivate: formatNumber(catalogExtraRowRental.priceDayPrivate, 2),
    priceMonthPrivate: formatNumber(catalogExtraRowRental.priceMonthPrivate, 2),
    priceMonthCompany: formatNumber(catalogExtraRowRental.priceMonthCompany, 2),
  };
};

interface Props extends WithStyles<typeof styles> {
  catalogExtraRowRental: CatalogExtraRowRentalType;
  locations: LocationType[];
  catalogExtraCategoriesUpperRental: CatalogExtraCategoryUpperRentalType[];
}

function CatalogExtraRentalRow({
  classes,
  catalogExtraRowRental,
  locations,
  catalogExtraCategoriesUpperRental,
}: Props) {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);

  const [openDialogMachines, setOpenDialogMachines] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogMaintenanceSettings, setOpenDialogMaintenanceSettings] =
    useState(false);

  const [updateCatalogRow, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCatalogExtraRowRentalArgs
  >(UPDATE_CATALOG_EXTRA_ROW_RENTAL_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });
  const [deleteCatalogRow, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteCatalogExtraRowRentalArgs
  >(DELETE_CATALOG_EXTRA_ROW_RENTAL_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogExtraCategoriesUpperRental"),
      });
    },
  });

  const [catalogExtraRowRentalEdited, setCatalogExtraRowRentalEdited] =
    useState<CatalogExtraRowRentalType>(
      getCatalogExtraRowRentalFormatted(catalogExtraRowRental)
    );

  const [priceDayPrivateVat, setPriceDayPrivateVat] = useState<string>(
    formatNumber(
      Number(catalogExtraRowRental.priceDayPrivate) * settings.vatFactor,
      2
    )
  );
  const [priceMonthPrivateVat, setPriceMonthPrivateVat] = useState<string>(
    formatNumber(
      Number(catalogExtraRowRental.priceMonthPrivate) * settings.vatFactor,
      2
    )
  );
  const [priceDayCompanyVat, setPriceDayCompanyVat] = useState<string>(
    formatNumber(
      Number(catalogExtraRowRental.priceDayCompany) * settings.vatFactor,
      2
    )
  );
  const [priceMonthCompanyVat, setPriceMonthCompanyVat] = useState<string>(
    formatNumber(
      Number(catalogExtraRowRental.priceMonthCompany) * settings.vatFactor,
      2
    )
  );

  /* If object is updated above (data is reloaded), we want to mirror changes here */
  useEffect(() => {
    setCatalogExtraRowRentalEdited(
      getCatalogExtraRowRentalFormatted(catalogExtraRowRental)
    );
  }, [catalogExtraRowRental]);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs_extra.change_catalogextrarowrental",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "catalogs_extra.delete_catalogextrarowrental",
  ]);
  const hasPermissionMaintenanceSettings = checkPermission(myPermissions, [
    "maintenance.view_maintenancesetting",
  ]);

  const handleClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCatalogRow({
        variables: {
          catalogExtraRowRentalId: catalogExtraRowRentalEdited.id,
        },
      });
    });
  };

  const handleClickAssignMachine = () => {
    setOpenDialogMachines(true);
  };

  const handleUpdate = (values: {
    [key: string]: string | number | boolean;
  }) => {
    const base: MutationUpdateCatalogExtraRowRentalArgs = {
      catalogExtraRowRentalId: catalogExtraRowRentalEdited.id,
      locationId: catalogExtraRowRentalEdited.location.id,
      name: catalogExtraRowRentalEdited.name,
      productNumber: catalogExtraRowRentalEdited.productNumber,
      identifier: catalogExtraRowRentalEdited.identifier,
      information: catalogExtraRowRentalEdited.information,
      catalogExtraCategoryRentalId:
        catalogExtraRowRentalEdited.catalogExtraCategoryRental
          ? catalogExtraRowRentalEdited.catalogExtraCategoryRental.id
          : ID_EMPTY,
      billingDaysWeekCompany:
        catalogExtraRowRentalEdited.billingDaysWeekCompany,
      priceDayCompany: parseNumber(catalogExtraRowRentalEdited.priceDayCompany),
      priceMonthCompany: parseNumber(
        catalogExtraRowRentalEdited.priceMonthCompany
      ),
      billingDaysWeekPrivate:
        catalogExtraRowRentalEdited.billingDaysWeekPrivate,
      priceDayPrivate: parseNumber(catalogExtraRowRentalEdited.priceDayPrivate),
      priceMonthPrivate: parseNumber(
        catalogExtraRowRentalEdited.priceMonthPrivate
      ),
      showPrint: catalogExtraRowRentalEdited.showPrint,
    };

    updateCatalogRow({
      variables: { ...base, ...values },
    });
  };

  return (
    <tr className={loadingUpdate ? "loading" : ""}>
      <td></td>
      <td>
        <FormControl fullWidth>
          <TextField
            value={catalogExtraRowRentalEdited.name}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                name: event.target.value,
              });
            }}
            onBlur={() => {
              handleUpdate({ name: catalogExtraRowRentalEdited.name });
            }}
            inputProps={{ maxLength: 100 }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={catalogExtraRowRentalEdited.priceDayCompany}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                priceDayCompany: event.target.value,
              });
              setPriceDayCompanyVat(
                formatNumber(
                  parseNumber(event.target.value) * settings.vatFactor,
                  2
                )
              );
            }}
            onBlur={() => {
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                priceDayCompany: formatNumber(
                  catalogExtraRowRentalEdited.priceDayCompany,
                  2
                ),
              });
              handleUpdate({
                priceDayCompany: parseNumber(
                  catalogExtraRowRentalEdited.priceDayCompany
                ).toFixed(2),
              });
            }}
            inputProps={{ maxLength: 11 }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={priceDayCompanyVat}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setPriceDayCompanyVat(event.target.value);
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                priceDayCompany: formatNumber(
                  parseNumber(event.target.value) / settings.vatFactor,
                  2
                ),
              });
            }}
            onBlur={() => {
              setPriceDayCompanyVat(formatNumber(priceDayCompanyVat, 2));
              handleUpdate({
                priceDayCompany: (
                  parseNumber(priceDayCompanyVat) / settings.vatFactor
                ).toFixed(2),
              });
            }}
            inputProps={{ maxLength: 11 }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={catalogExtraRowRentalEdited.priceMonthCompany}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                priceMonthCompany: event.target.value,
              });
              setPriceMonthCompanyVat(
                formatNumber(
                  parseNumber(event.target.value) * settings.vatFactor,
                  2
                )
              );
            }}
            onBlur={() => {
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                priceMonthCompany: formatNumber(
                  catalogExtraRowRentalEdited.priceMonthCompany,
                  2
                ),
              });
              handleUpdate({
                priceMonthCompany: parseNumber(
                  catalogExtraRowRentalEdited.priceMonthCompany
                ).toFixed(2),
              });
            }}
            inputProps={{ maxLength: 11 }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={priceMonthCompanyVat}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setPriceMonthCompanyVat(event.target.value);
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                priceMonthCompany: formatNumber(
                  parseNumber(event.target.value) / settings.vatFactor,
                  2
                ),
              });
            }}
            onBlur={() => {
              setPriceMonthCompanyVat(formatNumber(priceMonthCompanyVat, 2));
              handleUpdate({
                priceMonthCompany: (
                  parseNumber(priceMonthCompanyVat) / settings.vatFactor
                ).toFixed(2),
              });
            }}
            inputProps={{ maxLength: 11 }}
          />
        </FormControl>
      </td>
      <td className={classes.tdDaysWeek}>
        <FormControl fullWidth>
          <TextFieldFocus
            value={catalogExtraRowRentalEdited.billingDaysWeekCompany}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                billingDaysWeekCompany: parseNumber(event.target.value),
              });
            }}
            onBlur={() => {
              handleUpdate({
                billingDaysWeekCompany:
                  catalogExtraRowRentalEdited.billingDaysWeekCompany,
              });
            }}
            inputProps={{ maxLength: 6 }}
          />
        </FormControl>
      </td>
      <td></td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={catalogExtraRowRentalEdited.priceDayPrivate}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                priceDayPrivate: event.target.value,
              });
              setPriceDayPrivateVat(
                formatNumber(
                  parseNumber(event.target.value) * settings.vatFactor,
                  2
                )
              );
            }}
            onBlur={() => {
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                priceDayPrivate: formatNumber(
                  catalogExtraRowRentalEdited.priceDayPrivate,
                  2
                ),
              });
              handleUpdate({
                priceDayPrivate: parseNumber(
                  catalogExtraRowRentalEdited.priceDayPrivate
                ).toFixed(2),
              });
            }}
            inputProps={{ maxLength: 11 }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={priceDayPrivateVat}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setPriceDayPrivateVat(event.target.value);
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                priceDayCompany: formatNumber(
                  parseNumber(event.target.value) / settings.vatFactor,
                  2
                ),
              });
            }}
            onBlur={() => {
              setPriceDayPrivateVat(formatNumber(priceDayPrivateVat, 2));
              handleUpdate({
                priceDayPrivate: (
                  parseNumber(priceDayPrivateVat) / settings.vatFactor
                ).toFixed(2),
              });
            }}
            inputProps={{ maxLength: 11 }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={catalogExtraRowRentalEdited.priceMonthPrivate}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                priceMonthPrivate: event.target.value,
              });
              setPriceMonthPrivateVat(
                formatNumber(
                  parseNumber(event.target.value) * settings.vatFactor,
                  2
                )
              );
            }}
            onBlur={() => {
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                priceMonthPrivate: formatNumber(
                  catalogExtraRowRentalEdited.priceMonthPrivate,
                  2
                ),
              });
              handleUpdate({
                priceMonthPrivate: parseNumber(
                  catalogExtraRowRentalEdited.priceMonthPrivate
                ).toFixed(2),
              });
            }}
            inputProps={{ maxLength: 11 }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <TextFieldFocus
            value={priceMonthPrivateVat}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setPriceMonthPrivateVat(event.target.value);
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                priceMonthPrivate: formatNumber(
                  parseNumber(event.target.value) / settings.vatFactor,
                  2
                ),
              });
            }}
            onBlur={() => {
              setPriceMonthPrivateVat(formatNumber(priceMonthPrivateVat, 2));
              handleUpdate({
                priceMonthPrivate: (
                  parseNumber(priceMonthPrivateVat) / settings.vatFactor
                ).toFixed(2),
              });
            }}
            inputProps={{ maxLength: 11 }}
          />
        </FormControl>
      </td>
      <td className={classes.tdDaysWeek}>
        <FormControl fullWidth>
          <TextFieldFocus
            value={catalogExtraRowRentalEdited.billingDaysWeekPrivate}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setCatalogExtraRowRentalEdited({
                ...catalogExtraRowRentalEdited,
                billingDaysWeekPrivate: parseNumber(event.target.value),
              });
            }}
            onBlur={() => {
              handleUpdate({
                billingDaysWeekPrivate:
                  catalogExtraRowRentalEdited.billingDaysWeekPrivate,
              });
            }}
            inputProps={{ maxLength: 6 }}
          />
        </FormControl>
      </td>
      <td></td>
      <td>
        <Select
          fullWidth
          value={catalogExtraRowRentalEdited.location.id}
          disabled={!hasPermissionEdit}
          onChange={(event) => {
            const newVal = String(event.target.value);
            setCatalogExtraRowRentalEdited({
              ...catalogExtraRowRentalEdited,
              location: {
                ...catalogExtraRowRentalEdited.location,
                id: newVal,
              },
            });
            handleUpdate({
              locationId: newVal,
            });
          }}
        >
          {locations.map((location) => (
            <MenuItem key={location.id} value={location.id}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </td>
      <td>
        <Select
          fullWidth
          value={catalogExtraRowRentalEdited.showPrint ? 1 : 0}
          disabled={!hasPermissionEdit}
          onChange={(
            event: React.ChangeEvent<{ name?: string; value: any }>
          ) => {
            let newVal: boolean = parseInt(event.target.value) === 1;
            setCatalogExtraRowRentalEdited({
              ...catalogExtraRowRentalEdited,
              showPrint: newVal,
            });
            handleUpdate({
              showPrint: newVal,
            });
          }}
        >
          <MenuItem value="1">{t("yes")}</MenuItem>
          <MenuItem value="0">{t("no")}</MenuItem>
        </Select>
      </td>
      <td>
        {hasPermissionEdit && (
          <Button
            variant="light"
            size="sm"
            className={classes.btn}
            onClick={() => setOpenDialogEdit(!openDialogEdit)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        )}
        {hasPermissionEdit && (
          <Button
            variant="light"
            size="sm"
            className={classes.btn}
            onClick={handleClickAssignMachine}
          >
            <FontAwesomeIcon icon={faTasks} />{" "}
            <small>{catalogExtraRowRentalEdited.machineSet.length}</small>
          </Button>
        )}
        {hasPermissionMaintenanceSettings && (
          <Button
            variant="light"
            size="sm"
            className={classes.btn}
            onClick={() =>
              setOpenDialogMaintenanceSettings(!openDialogMaintenanceSettings)
            }
          >
            <FontAwesomeIcon icon={faWrench} />{" "}
          </Button>
        )}
        {hasPermissionDelete && (
          <ButtonLoad
            loading={loadingDelete}
            variant="light"
            size="sm"
            className={classes.btn}
            onClick={handleClickDelete}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonLoad>
        )}
        {openDialogMachines && (
          <DialogCatalogExtraRowRentalMachine
            open={openDialogMachines}
            setOpen={setOpenDialogMachines}
            catalogExtraRowRental={catalogExtraRowRentalEdited}
          />
        )}
        {openDialogEdit && (
          <DialogCatalogExtraRowRental
            open={openDialogEdit}
            setOpen={setOpenDialogEdit}
            catalogExtraRowRental={catalogExtraRowRentalEdited}
            catalogExtraCategoriesUpperRental={
              catalogExtraCategoriesUpperRental
            }
          />
        )}
        {openDialogMaintenanceSettings && (
          <MaintenanceSettingsCatalogExtraRowRental
            open={openDialogMaintenanceSettings}
            setOpen={setOpenDialogMaintenanceSettings}
            catalogExtraRowRental={catalogExtraRowRentalEdited}
          />
        )}
      </td>
    </tr>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    tdDaysWeek: {
      width: "5%",
    },
    btn: {
      marginRight: spacing(0.5),
    },
  });

export default withStyles(styles)(CatalogExtraRentalRow);
