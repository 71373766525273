import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { useMutation } from "@apollo/client";
import { createStyles, Theme } from "@material-ui/core";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/pro-light-svg-icons";
import { DELETE_INSPECTION_RETURN_CATEGORY_MUTATION } from "../../apollo/mutations/inspections";
import { GET_INSPECTION_RETURN_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { PermissionsContext } from "../../Root";
import Loading from "../Shared/Loading";
import {
  InspectionReturnCategoryType,
  InspectionReturnTagType,
  Mutation,
  MutationDeleteInspectionReturnCategoryArgs,
} from "../../entity/types";
import { handleError } from "../../entity/ErrorHandler";
import { checkPermission } from "../../utils/permissions";
import InspectionReturnCategoryQuestions from "./InspectionReturnCategoryQuestions";
import { dialogConfirm } from "../../utils/dialogs";
import DialogInspectionReturnCategory from "./DialogInspectionReturnCategory";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionReturnCategory: InspectionReturnCategoryType;
  inspectionReturnTags: InspectionReturnTagType[];
}

function InspectionReturnCategory({
  classes,
  inspectionReturnCategory,
  inspectionReturnTags,
}: Props) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const [deleteInspectionReturnCategory, { loading: loadingDelete }] =
    useMutation<Mutation, MutationDeleteInspectionReturnCategoryArgs>(
      DELETE_INSPECTION_RETURN_CATEGORY_MUTATION,
      {
        refetchQueries: [{ query: GET_INSPECTION_RETURN_CATEGORIES_QUERY }],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "inspections.change_inspectionreturncategory",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "inspections.delete_inspectionreturncategory",
  ]);

  if (loadingDelete) return <Loading />;

  const handleDeleteInspectionReturnCategory = (
    inspectionReturnCategoryId: string
  ) => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteInspectionReturnCategory({
        variables: { inspectionReturnCategoryId: inspectionReturnCategoryId },
      });
    });
  };

  return (
    <Table className={classes.table} borderless hover responsive={true}>
      <thead>
        <tr>
          <th className={classes.headerCategory}>
            {inspectionReturnCategory.name}
          </th>
          <th>{t("answer_type")}</th>
          <th>{t("tags")}</th>
          <th>
            {hasPermissionEdit && (
              <Button
                className="me-1"
                variant="light"
                size="sm"
                onClick={() => setOpenDialog(true)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
            {hasPermissionDelete && (
              <Button
                variant="light"
                size="sm"
                onClick={() =>
                  handleDeleteInspectionReturnCategory(
                    inspectionReturnCategory.id
                  )
                }
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
            <DialogInspectionReturnCategory
              open={openDialog}
              setOpen={setOpenDialog}
              inspectionReturnCategory={inspectionReturnCategory}
            />
          </th>
        </tr>
      </thead>
      <InspectionReturnCategoryQuestions
        inspectionReturnCategory={inspectionReturnCategory}
        inspectionReturnQuestions={
          inspectionReturnCategory.inspectionreturnquestionSet
        }
        inspectionReturnTags={inspectionReturnTags}
      />
    </Table>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    table: {
      width: "auto",
    },
    headerCategory: {
      color: palette.primary.main,
      fontSize: "1.2rem",
    },
  });

export default withStyles(styles)(InspectionReturnCategory);
