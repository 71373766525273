import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { getReservationProductName } from "../../utils/reservations/reservation";
import ButtonLoad from "../Shared/ButtonLoad";
import AddIcon from "@material-ui/icons/Add";
import { useMutation } from "@apollo/client";
import { Mutation, MutationCreateDriveArgs } from "../../entity/types";
import { handleError } from "../../entity/ErrorHandler";
import { CREATE_DRIVE_MUTATION } from "../../apollo/mutations/drives";
import { TranportReservationSelected } from "./DrivingCreate";
import { getQueryKey } from "../../utils/cache";
import { ROOT_QUERY } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  transportReservationsSelected: TranportReservationSelected[];
  setTransportReservationsSelected: React.Dispatch<
    React.SetStateAction<TranportReservationSelected[]>
  >;
}

function Drives({
  classes,
  transportReservationsSelected,
  setTransportReservationsSelected,
}: Props) {
  const { t } = useTranslation();

  const [createDrive, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateDriveArgs
  >(CREATE_DRIVE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: () => {
      setTransportReservationsSelected([]);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("drives"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("transportsUnhandled"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("drivesForWorkQueues"),
      });
    },
  });

  const onClickCreate = () => {
    createDrive({
      variables: {
        transportReservation: transportReservationsSelected.map((row) => ({
          transportId: row.transport.id,
          reservationId: row.reservation.id,
        })),
      },
    });
  };

  return (
    <div className={classes.conDrive}>
      {transportReservationsSelected.length === 0 && (
        <span className="text-muted">
          {t("create_drive_select_transports")}
        </span>
      )}
      {transportReservationsSelected.map((item) => (
        <div
          key={`${item.transport.id}_${item.reservation.id}`}
          className={classes.item}
        >
          {t("transport_method_" + item.transport.method)} #
          {item.reservation.id}
          <div>{getReservationProductName(t, item.reservation)}</div>
        </div>
      ))}
      {transportReservationsSelected.length > 0 && (
        <ButtonLoad
          loading={loadingCreate}
          className={`btnRoundSmall ${classes.btnCreate}`}
          variant="primary"
          onClick={onClickCreate}
        >
          <AddIcon />
        </ButtonLoad>
      )}
    </div>
  );
}

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    conDrive: {
      minHeight: "5rem",
      backgroundColor: palette.grey["50"],
      marginTop: spacing(3),
      border: "1px solid #EEEEEE",
      padding: spacing(1),
      paddingBottom: 0,
    },
    item: {
      backgroundColor: "#FFFFFF",
      display: "inline-block",
      marginRight: spacing(1),
      marginBottom: spacing(1),
      padding: spacing(1),
      border: "1px solid #EEEEEE",
    },
    btnCreate: {
      verticalAlign: "top",
      marginTop: spacing(0.8),
    },
  });

export default withStyles(styles)(Drives);
