import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  CatalogTransferMethodType,
  Mutation,
  MutationDeleteTransportArgs,
  MutationUpdateTransportArgs,
  OrderType,
  ReservationDeliveryMethod,
  ReservationType,
  TransportMethod,
  TransportType,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { GET_TRANSPORTS_BY_ORDER_QUERY } from "../../apollo/queries/transports";
import { Button } from "react-bootstrap";
import { handleError } from "../../entity/ErrorHandler";
import {
  DELETE_TRANSPORT_MUTATION,
  UPDATE_TRANSPORT_MUTATION,
} from "../../apollo/mutations/transports";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { dialogConfirm } from "../../utils/dialogs";
import { getReservationProductName } from "../../utils/reservations/reservation";
import { ID_EMPTY } from "../../utils/constants";
import {
  CatalogTransferMethodEmpty,
  ReservationEmpty,
} from "../../entity/empties";
import { timeWithoutSeconds } from "../../utils/formatting";
import SelectRequired from "../Shared/SelectRequired";
import { findFromSetById } from "../../utils/collections";

interface Props extends WithStyles<typeof styles> {
  order: OrderType;
  transport: TransportType;
  catalogTransferMethods: CatalogTransferMethodType[];
}

function TransportCard({
  classes,
  order,
  transport,
  catalogTransferMethods,
}: Props) {
  const { t } = useTranslation();

  const [transportEdited, setTransportEdited] = useState({
    ...transport,
    dateDeadline: transport.dateDeadline ? transport.dateDeadline : "",
    timeDeadline: transport.timeDeadline ? transport.timeDeadline : "",
  });
  const [reservationIds, setReservationIds] = useState(
    transport.reservations.map((reservation) => reservation.id)
  );
  const [locationIdAllowed, setLocationIdAllowed] = useState<
    string | undefined
  >(undefined);

  const [updateTransport, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateTransportArgs
  >(UPDATE_TRANSPORT_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const [deleteTransport, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteTransportArgs
  >(DELETE_TRANSPORT_MUTATION, {
    refetchQueries: [
      {
        query: GET_TRANSPORTS_BY_ORDER_QUERY,
        variables: { orderId: order.id },
      },
    ],
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "transports.change_transport",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "transports.delete_transport",
  ]);

  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteTransport({
        variables: {
          transportId: transport.id,
        },
      });
    });
  };

  const handleUpdate = (
    values: { [key: string]: string | number | null | string[] } = {}
  ) => {
    const base: MutationUpdateTransportArgs = {
      transportId: transportEdited.id,
      orderId: order.id,
      catalogTransferMethodId: transportEdited.catalogTransferMethod
        ? transportEdited.catalogTransferMethod.id
        : ID_EMPTY,
      reservationIds: reservationIds,
      method: transportEdited.method,
      address: transportEdited.address,
      district: transportEdited.district,
      information: transportEdited.information,
      postcode: transportEdited.postcode,
      dateDeadline:
        transportEdited.dateDeadline !== ""
          ? transportEdited.dateDeadline
          : null,
      timeDeadline:
        transportEdited.timeDeadline !== ""
          ? transportEdited.timeDeadline
          : null,
      dateEndUsage:
        transportEdited.dateEndUsage !== ""
          ? transportEdited.dateEndUsage
          : null,
      timeEndUsage:
        transportEdited.timeEndUsage !== ""
          ? transportEdited.timeEndUsage
          : null,
    };

    updateTransport({
      variables: { ...base, ...values },
    });
  };

  const reservationsForTransport = order.reservationSet.filter(
    (reservation) =>
      reservation.deliveryMethod === ReservationDeliveryMethod.Transport
  );
  const reservationsForTransportWithLocation = reservationsForTransport.filter(
    (reservation) =>
      reservation.machine &&
      !reservation.catalogExtraRowRental &&
      reservation.machine.location
  );

  return (
    <Paper
      className={`${loadingUpdate || loadingDelete ? "loading" : ""} ${
        classes.conTransport
      }`}
    >
      <FormControl fullWidth>
        <InputLabel id="lblTransportMethod">{t("transport_method")}</InputLabel>
        <SelectRequired
          autoWidth
          labelId="lblTransportMethod"
          value={transportEdited.method}
          disabled={!hasPermissionEdit}
          onChange={(event) => {
            const methodNew = event.target.value as TransportMethod;
            setTransportEdited({
              ...transportEdited,
              method: methodNew,
            });
            handleUpdate({ method: methodNew });
          }}
        >
          <MenuItem value={TransportMethod.Send}>
            {t("transport_method_" + TransportMethod.Send)}
          </MenuItem>
          <MenuItem value={TransportMethod.Retrieve}>
            {t("transport_method_" + TransportMethod.Retrieve)}
          </MenuItem>
        </SelectRequired>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblTransportTransferMethod">
          {t("catalog_transfer_method")}
        </InputLabel>
        <SelectRequired
          autoWidth
          labelId="lblTransportTransferMethod"
          value={
            transportEdited.catalogTransferMethod
              ? transportEdited.catalogTransferMethod.id
              : ID_EMPTY
          }
          disabled={!hasPermissionEdit}
          onChange={(event) => {
            const idNew = String(event.target.value);
            const catalogTransferMethodNew =
              transportEdited.catalogTransferMethod
                ? transportEdited.catalogTransferMethod
                : CatalogTransferMethodEmpty;

            setTransportEdited({
              ...transportEdited,
              catalogTransferMethod: {
                ...catalogTransferMethodNew,
                id: idNew,
              },
            });
            handleUpdate({ catalogTransferMethodId: idNew });
          }}
        >
          <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
          {catalogTransferMethods &&
            catalogTransferMethods.map((catalogTransferMethod) => (
              <MenuItem
                key={catalogTransferMethod.id}
                value={catalogTransferMethod.id}
              >
                {catalogTransferMethod.name}
              </MenuItem>
            ))}
        </SelectRequired>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblTransportReservations">
          {t("reservations")}
        </InputLabel>
        <SelectRequired
          autoWidth
          multiple
          labelId="lblTransportReservations"
          value={reservationIds}
          disabled={!hasPermissionEdit}
          onChange={(event) => {
            const reservationsIdsNew = event.target.value as string[];

            const reservation: ReservationType =
              reservationsIdsNew.length > 0
                ? findFromSetById(
                    String(reservationsIdsNew[0]),
                    reservationsForTransportWithLocation,
                    ReservationEmpty
                  )
                : ReservationEmpty;
            if (reservation.id !== ID_EMPTY) {
              setLocationIdAllowed(reservation.machine?.location?.id);
            } else {
              setLocationIdAllowed(undefined);
            }

            setReservationIds(reservationsIdsNew);
            handleUpdate({ reservationIds: reservationsIdsNew });
          }}
        >
          {reservationsForTransport.map((reservation) => {
            const hasLocation =
              reservation.machine &&
              !reservation.catalogExtraRowRental &&
              reservation.machine.location;
            return (
              <MenuItem
                key={reservation.id}
                value={reservation.id}
                disabled={
                  locationIdAllowed !== undefined &&
                  hasLocation &&
                  locationIdAllowed !== reservation.machine?.location?.id
                }
              >
                {(hasLocation
                  ? reservation.machine?.location?.name + " "
                  : "") + getReservationProductName(t, reservation, true)}
              </MenuItem>
            );
          })}
        </SelectRequired>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          required={true}
          label={t("address")}
          value={transportEdited.address}
          disabled={!hasPermissionEdit}
          onChange={(event) => {
            setTransportEdited({
              ...transportEdited,
              address: event.target.value,
            });
          }}
          onBlur={() => {
            handleUpdate();
          }}
        />
      </FormControl>
      <div className="d-flex">
        <div className="w-50 pe-2">
          <FormControl>
            <TextField
              required={true}
              label={t("postcode")}
              value={transportEdited.postcode}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setTransportEdited({
                  ...transportEdited,
                  postcode: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdate();
              }}
            />
          </FormControl>
        </div>
        <div className="w-50">
          <FormControl>
            <TextField
              required={true}
              label={t("district")}
              value={transportEdited.district}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setTransportEdited({
                  ...transportEdited,
                  district: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdate();
              }}
            />
          </FormControl>
        </div>
      </div>
      <div className="d-flex">
        <div className="w-50 pe-2">
          <FormControl fullWidth>
            <TextField
              required={true}
              type="date"
              label={t("transport_deadline_date")}
              value={transportEdited.dateDeadline}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setTransportEdited({
                  ...transportEdited,
                  dateDeadline: event.target.value,
                });
                handleUpdate({
                  dateDeadline:
                    event.target.value !== "" ? event.target.value : null,
                });
              }}
            />
          </FormControl>
        </div>
        <div className="w-50">
          <FormControl fullWidth>
            <TextField
              type="time"
              label={t("transport_deadline_time")}
              value={timeWithoutSeconds(transportEdited.timeDeadline)}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setTransportEdited({
                  ...transportEdited,
                  timeDeadline: event.target.value,
                });
                handleUpdate({
                  timeDeadline:
                    event.target.value !== "" ? event.target.value : null,
                });
              }}
            />
          </FormControl>
        </div>
      </div>
      {transportEdited.method === TransportMethod.Retrieve && (
        <div className="d-flex">
          <div className="w-50 pe-2">
            <FormControl fullWidth>
              <TextField
                required={false}
                type="date"
                label={t("date_returned")}
                value={transportEdited.dateEndUsage}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={!hasPermissionEdit}
                onChange={(event) => {
                  setTransportEdited({
                    ...transportEdited,
                    dateEndUsage: event.target.value,
                  });
                  handleUpdate({
                    dateEndUsage:
                      event.target.value !== "" ? event.target.value : null,
                  });
                }}
              />
            </FormControl>
          </div>
          <div className="w-50">
            <FormControl fullWidth>
              <TextField
                type="time"
                label={t("time_returned")}
                value={timeWithoutSeconds(transportEdited.timeEndUsage)}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={!hasPermissionEdit}
                onChange={(event) => {
                  setTransportEdited({
                    ...transportEdited,
                    timeEndUsage: event.target.value,
                  });
                  handleUpdate({
                    timeEndUsage:
                      event.target.value !== "" ? event.target.value : null,
                  });
                }}
              />
            </FormControl>
          </div>
        </div>
      )}
      <FormControl fullWidth>
        <TextField
          label={t("information_transport")}
          multiline
          minRows={2}
          value={transportEdited.information}
          disabled={!hasPermissionEdit}
          onChange={(event) => {
            setTransportEdited({
              ...transportEdited,
              information: event.target.value,
            });
          }}
          onBlur={() => {
            handleUpdate();
          }}
        />
      </FormControl>
      {hasPermissionDelete && (
        <Button onClick={onClickDelete} variant="light">
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      )}
    </Paper>
  );
}

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    conTransport: {
      width: "30%",
      padding: spacing(2),
      marginRight: spacing(2),
      marginBottom: spacing(2),
      display: "inline-block",
      verticalAlign: "top",

      [breakpoints.down("lg")]: {
        width: "45%",
      },
      [breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  });

export default withStyles(styles)(TransportCard);
