import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { CustomerType, QueryInvoiceableRowsArgs } from "../../entity/types";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { useTranslation } from "react-i18next";
import {
  GET_INVOICEABLE_ROWS_QUERY,
  QueryResultInvoiceableRows,
} from "../../apollo/queries/invoices";
import format from "date-fns/format";
import { DATE_FORMAT_ISO } from "../../utils/constants";
import InvoiceableRows from "./InvoiceableRows";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  dateTo: Date;
  locationIdsSelected: string[];
  hideSurchargeInvoices: boolean;
  onlyEndedReservations: boolean;
}

function InvoiceableRowsCustomer({
  classes,
  customer,
  dateTo,
  locationIdsSelected,
  hideSurchargeInvoices,
  onlyEndedReservations,
}: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultInvoiceableRows,
    QueryInvoiceableRowsArgs
  >(GET_INVOICEABLE_ROWS_QUERY, {
    fetchPolicy: "cache-and-network", // We want to always fetch up-to-date data when searching
    variables: {
      locationIds: locationIdsSelected,
      dateTo: format(dateTo, DATE_FORMAT_ISO),
      customerId: customer.id,
      onlyEndedReservations: onlyEndedReservations,
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <InvoiceableRows
      invoiceableRows={data.invoiceableRows ? data.invoiceableRows : []}
      dateTo={dateTo}
      isAdvance={false}
      hideSurchargeInvoices={hideSurchargeInvoices}
    />
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InvoiceableRowsCustomer);
