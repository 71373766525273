import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  createStyles,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button, Col, Row } from "react-bootstrap";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import {
  CatalogExtraRowRentalType,
  MachineBreakdownBilling,
  MachineBreakdownType,
  MachineType,
  Mutation,
  MutationCreateMachineBreakdownArgs,
  MutationDeleteMachineBreakdownArgs,
  MutationUpdateMachineBreakdownArgs,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import {
  CREATE_MACHINE_BREAKDOWN_MUTATION,
  DELETE_MACHINE_BREAKDOWN_MUTATION,
  UPDATE_MACHINE_BREAKDOWN_MUTATION,
} from "../../apollo/mutations/machines";
import { handleError } from "../../entity/ErrorHandler";
import { dialogConfirm } from "../../utils/dialogs";
import { ID_EMPTY } from "../../utils/constants";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { MachineBreakdownEmpty } from "../../entity/empties";
import { updateCacheReservationAndBreakdowns } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  machineBreakdown: MachineBreakdownType;
  machine?: MachineType;
  catalogExtraRowRental?: CatalogExtraRowRentalType;
  onClose: Function;
}

function DialogMachineBreakdown({
  classes,
  open,
  setOpen,
  machineBreakdown,
  machine,
  catalogExtraRowRental,
  onClose,
}: Props) {
  const { t } = useTranslation();

  const [machineBreakdownEdited, setMachineBreakdownEdited] = useState(
    MachineBreakdownEmpty
  );

  const [createMachineBreakdown] = useMutation<
    Mutation,
    MutationCreateMachineBreakdownArgs
  >(CREATE_MACHINE_BREAKDOWN_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheReservationAndBreakdowns(cache);
    },
  });

  const [updateMachineBreakdown] = useMutation<
    Mutation,
    MutationUpdateMachineBreakdownArgs
  >(UPDATE_MACHINE_BREAKDOWN_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheReservationAndBreakdowns(cache);
    },
  });

  const [deleteMachineBreakdown, { loading }] = useMutation<
    Mutation,
    MutationDeleteMachineBreakdownArgs
  >(DELETE_MACHINE_BREAKDOWN_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheReservationAndBreakdowns(cache);
    },
  });

  useEffect(() => {
    setMachineBreakdownEdited({
      ...machineBreakdown,
      amountBilledSingle: formatNumber(machineBreakdown.amountBilledSingle, 2),
    });
    // eslint-disable-next-line
  }, [
    machineBreakdown.id,
    machineBreakdown.fixByStart,
    machineBreakdown.fixByEnd,
  ]);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "machines.add_machinebreakdown",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "machines.change_machinebreakdown",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "machines.delete_machinebreakdown",
  ]);
  const hasPermission =
    machineBreakdownEdited.id === ID_EMPTY
      ? hasPermissionAdd
      : hasPermissionEdit;

  const onClickSave = () => {
    setOpen(false);
    onClose();

    if (machineBreakdownEdited.id === ID_EMPTY) {
      createMachineBreakdown({
        variables: {
          machineId: machine ? machine.id : undefined,
          catalogExtraRowRentalId: catalogExtraRowRental
            ? catalogExtraRowRental.id
            : undefined,
          title: machineBreakdownEdited.title,
          information: machineBreakdownEdited.information,
          stillRentable: machineBreakdownEdited.stillRentable,
          fixByStart: machineBreakdownEdited.fixByStart,
          fixByEnd: machineBreakdownEdited.fixByEnd,
          billing: machineBreakdownEdited.billing,
          amountBilledSingle: parseNumber(
            machineBreakdownEdited.amountBilledSingle
          ),
        },
      });
    } else {
      updateMachineBreakdown({
        variables: {
          machineBreakdownId: machineBreakdownEdited.id,
          title: machineBreakdownEdited.title,
          information: machineBreakdownEdited.information,
          stillRentable: machineBreakdownEdited.stillRentable,
          fixByStart: machineBreakdownEdited.fixByStart,
          fixByEnd: machineBreakdownEdited.fixByEnd,
          billing: machineBreakdownEdited.billing,
          amountBilledSingle: parseNumber(
            machineBreakdownEdited.amountBilledSingle
          ),
          fixedAt: machineBreakdownEdited.fixedAt
            ? machineBreakdownEdited.fixedAt
            : null,
        },
      });
    }
  };

  const onClickCancel = () => {
    setOpen(false);
    onClose();
  };

  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      setOpen(false);
      onClose();
      deleteMachineBreakdown({
        variables: {
          machineBreakdownId: machineBreakdownEdited.id,
        },
      });
    });
  };

  return (
    <Dialog className={loading ? "loading" : ""} open={open}>
      <DialogTitle>
        {t(
          machineBreakdownEdited.id === ID_EMPTY
            ? "machine_breakdown_create"
            : "machine_breakdown_update"
        )}
      </DialogTitle>
      <DialogContent>
        <Row>
          <Col>
            <FormControl fullWidth>
              <TextField
                type="date"
                label={t("date_from")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ max: machineBreakdownEdited.fixByEnd }}
                onChange={(event) => {
                  setMachineBreakdownEdited({
                    ...machineBreakdownEdited,
                    fixByStart: event.target.value,
                  });
                }}
                value={machineBreakdownEdited.fixByStart}
                disabled={!hasPermission}
              />
            </FormControl>
          </Col>
          <Col>
            <FormControl fullWidth>
              <TextField
                type="date"
                label={t("date_to")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ min: machineBreakdownEdited.fixByStart }}
                onChange={(event) => {
                  setMachineBreakdownEdited({
                    ...machineBreakdownEdited,
                    fixByEnd: event.target.value,
                  });
                }}
                value={machineBreakdownEdited.fixByEnd}
                disabled={!hasPermission}
              />
            </FormControl>
          </Col>
        </Row>
        <FormControl fullWidth>
          <TextField
            label={t("title_breakdown")}
            onChange={(event) => {
              setMachineBreakdownEdited({
                ...machineBreakdownEdited,
                title: event.target.value,
              });
            }}
            value={machineBreakdownEdited.title}
            inputProps={{ maxLength: 100 }}
            disabled={!hasPermission}
            autoFocus
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label={t("information")}
            onChange={(event) => {
              setMachineBreakdownEdited({
                ...machineBreakdownEdited,
                information: event.target.value,
              });
            }}
            value={machineBreakdownEdited.information}
            disabled={!hasPermission}
            multiline
            minRows={2}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="lblDialogMachineBreakdownStrillRentable">
            {t("still_rentable")}
          </InputLabel>
          <Select
            labelId="lblDialogMachineBreakdownStrillRentable"
            value={machineBreakdownEdited.stillRentable ? 1 : 0}
            onChange={(event) => {
              setMachineBreakdownEdited({
                ...machineBreakdownEdited,
                stillRentable: Boolean(event.target.value),
              });
            }}
            disabled={!hasPermission}
          >
            <MenuItem value={1}>{t("yes")}</MenuItem>
            <MenuItem value={0}>{t("no")}</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="lblDialogMachineBreakdownStrillRentable">
            {t("billing_breakdown")}
          </InputLabel>
          <Select
            labelId="lblDialogMachineBreakdownStrillRentable"
            value={machineBreakdownEdited.billing}
            onChange={(event) => {
              setMachineBreakdownEdited({
                ...machineBreakdownEdited,
                billing: event.target.value as MachineBreakdownBilling,
              });
            }}
            disabled={!hasPermission}
          >
            <MenuItem value={MachineBreakdownBilling.Yes}>
              {t("billing_breakdown_" + MachineBreakdownBilling.Yes)}
            </MenuItem>
            <MenuItem value={MachineBreakdownBilling.No}>
              {t("billing_breakdown_" + MachineBreakdownBilling.No)}
            </MenuItem>
            <MenuItem value={MachineBreakdownBilling.YesSingle}>
              {t("billing_breakdown_" + MachineBreakdownBilling.YesSingle)}
            </MenuItem>
            <MenuItem value={MachineBreakdownBilling.NoSingle}>
              {t("billing_breakdown_" + MachineBreakdownBilling.NoSingle)}
            </MenuItem>
          </Select>
        </FormControl>
        {(machineBreakdownEdited.billing ===
          MachineBreakdownBilling.YesSingle ||
          machineBreakdownEdited.billing ===
            MachineBreakdownBilling.NoSingle) && (
          <FormControl fullWidth>
            <TextField
              label={t("amount_breakdown_single")}
              value={machineBreakdownEdited.amountBilledSingle}
              disabled={!hasPermission}
              onChange={(event) => {
                setMachineBreakdownEdited({
                  ...machineBreakdownEdited,
                  amountBilledSingle: event.target.value,
                });
              }}
              onBlur={(event) => {
                setMachineBreakdownEdited({
                  ...machineBreakdownEdited,
                  amountBilledSingle: formatNumber(event.target.value, 2),
                });
              }}
            />
          </FormControl>
        )}
        <FormControlLabel
          label={t("fixed")}
          control={
            <Checkbox
              checked={Boolean(machineBreakdownEdited.fixedAt)}
              onChange={(event) => {
                setMachineBreakdownEdited({
                  ...machineBreakdownEdited,
                  fixedAt: event.target.checked ? new Date() : null,
                });
              }}
            />
          }
        />
        <p className="text-muted">
          {t("mark_machine_breakdown_fixed_explained")}
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickSave} variant="primary">
          {t("save")}
        </Button>
        <Button onClick={onClickCancel} variant="secondary">
          {t("cancel")}
        </Button>
        {hasPermissionDelete && machineBreakdownEdited.id !== ID_EMPTY && (
          <Button onClick={onClickDelete} variant="light">
            {t("delete")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogMachineBreakdown);
