export const doesMatchAllTerms = (search: string, value: string): boolean => {
  const searchTrim = search.trim();
  let allMatch: boolean = searchTrim !== "";

  for (let word of searchTrim.split(" ")) {
    const match = value.toLowerCase().indexOf(word.toLowerCase()) !== -1;
    if (!match) {
      allMatch = false;
    }
  }

  return allMatch;
};
