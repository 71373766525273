import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import {
  Mutation,
  MutationUploadReservationImageArgs,
  QueryReservationImageGivesArgs,
  QueryReservationImageReturnsArgs,
  ReservationImageType,
  ReservationType,
} from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { useMutation, useQuery } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import { UPLOAD_RESERVATION_IMAGE_MUTATION } from "../../apollo/mutations/reservations";
import {
  GET_RESERVATION_IMAGE_GIVES_QUERY,
  GET_RESERVATION_IMAGE_RETURNS_QUERY,
  QueryResultReservationImageGives,
  QueryResultReservationImageReturns,
} from "../../apollo/queries/reservations";
import Error from "../Shared/Error";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import ReservationButtonImagesAt from "./ReservationButtonImagesAt";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import ReservationImages from "./ReservationImages";

export type ImageFieldType = "" | "giveImagesAt" | "returnImagesAt";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reservation: ReservationType;
  field: ImageFieldType;
}

function DialogReservationWorkQueueImages({
  classes,
  open,
  setOpen,
  reservation,
  field,
}: Props) {
  const { t } = useTranslation();

  const {
    loading: loadingGives,
    error: errorGives,
    data: dataGives,
  } = useQuery<
    QueryResultReservationImageGives,
    QueryReservationImageGivesArgs
  >(GET_RESERVATION_IMAGE_GIVES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("reservationImageGives"),
    skip: field !== "giveImagesAt",
    variables: {
      reservationId: reservation.id,
    },
  });

  const {
    loading: loadingReturns,
    error: errorReturns,
    data: dataReturns,
  } = useQuery<
    QueryResultReservationImageReturns,
    QueryReservationImageReturnsArgs
  >(GET_RESERVATION_IMAGE_RETURNS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("reservationImageReturns"),
    skip: field !== "returnImagesAt",
    variables: {
      reservationId: reservation.id,
    },
  });

  const refetchQueries = [
    {
      query: GET_RESERVATION_IMAGE_GIVES_QUERY,
      variables: { reservationId: reservation.id },
    },
    {
      query: GET_RESERVATION_IMAGE_RETURNS_QUERY,
      variables: { reservationId: reservation.id },
    },
  ];

  const [uploadReservationImage, { loading: loadingUpload }] = useMutation<
    Mutation,
    MutationUploadReservationImageArgs
  >(UPLOAD_RESERVATION_IMAGE_MUTATION, {
    refetchQueries: refetchQueries,
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "reservations.add_reservationimage",
  ]);

  if (!loadingGives && errorGives) return <Error error={errorGives} />;
  if (!loadingReturns && errorReturns) return <Error error={errorReturns} />;

  const reservationImages: ReservationImageType[] =
    field === "giveImagesAt" && dataGives && dataGives.reservationImageGives
      ? dataGives.reservationImageGives
      : field === "returnImagesAt" &&
        dataReturns &&
        dataReturns.reservationImageReturns
      ? dataReturns.reservationImageReturns
      : [];

  function onChange({
    target: { validity, files },
  }: React.ChangeEvent<HTMLInputElement>) {
    if (files && validity.valid) {
      uploadReservationImage({
        variables: {
          reservationAlbumId:
            field === "giveImagesAt"
              ? reservation.albumGive.id
              : reservation.albumReturn.id,
          file: files[0],
        },
      });
    }
  }

  const hasImages = reservationImages.length > 0;

  return (
    <Dialog open={open}>
      <DialogTitleWithClose
        id="dialogTitleCatalogRowUpload"
        onClose={() => setOpen(false)}
      >
        {t("reservation_images")}
      </DialogTitleWithClose>
      <DialogContent className={loadingUpload ? "loading" : ""}>
        <ReservationImages reservationImages={reservationImages} />
        {hasPermissionAdd && (
          <input type="file" required onChange={onChange} accept="image/*" />
        )}
      </DialogContent>
      <DialogActions>
        <ReservationButtonImagesAt
          reservation={reservation}
          field={field}
          disabled={!hasImages}
          setOpenDialogOperationHours={setOpen}
        />
      </DialogActions>
    </Dialog>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    conImages: {
      maxWidth: "300px",
      marginBottom: spacing(2),
    },
    conImage: {
      position: "relative",
    },
    btnDelete: {
      position: "absolute",
      top: 0,
      right: 0,
    },
  });

export default withStyles(styles)(DialogReservationWorkQueueImages);
