import { gql } from "@apollo/client";

export const LOGIN_MUTATION_K = gql`
  mutation tokenAuth($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
    }
  }
`;

export const LOGIN_MUTATION = gql`
    mutation tokenAuth($email:String!, $password: String!)
    {
        tokenAuth(email:$email, password: $password){
            token
            user{
                id
                firstName
                lastName
                lastLogin
                roles{
                    organisation{
                        id
                        businessName
                    }
                    id
                }
                organisation {
                    id
                    businessName
                }
                isSuperuser
            }
            logId
        }
    }
    `;