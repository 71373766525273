import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultCatalogExtraRental {
  catalogExtraCategoriesUpperRental: Query["catalogExtraCategoriesUpperRental"];
}
export const GET_CATALOG_EXTRA_RENTAL_QUERY = gql`
  query getCatalogExtraCategoriesUpperRental(
    $locationIds: [ID]
    $catalogExtraCategoryRentalIds: [ID]
  ) {
    catalogExtraCategoriesUpperRental(
      locationIds: $locationIds
      catalogExtraCategoryRentalIds: $catalogExtraCategoryRentalIds
    ) {
      id
      name
      catalogextracategoryrentalSet {
        id
        name
        catalogextrarowrentalSet {
          id
          productNumber
          identifier
          name
          information
          billingDaysWeekCompany
          priceDayCompany
          priceMonthCompany
          billingDaysWeekPrivate
          priceDayPrivate
          priceMonthPrivate
          showPrint
          catalogExtraCategoryRental {
            id
          }
          machineSet {
            id
          }
          location {
            id
          }
        }
      }
    }
  }
`;

export interface QueryResultCatalogExtraUnitPrice {
  catalogExtraCategoriesUnitPrice: Query["catalogExtraCategoriesUnitPrice"];
}
export const GET_CATALOG_EXTRA_UNIT_PRICE_QUERY = gql`
  query getCatalogExtraUnitPrice {
    catalogExtraCategoriesUnitPrice {
      id
      name
      printSeparately
      createdAt
      catalogextrarowunitpriceSet {
        id
        name
        productCode {
          id
          code
          description
        }
        unit
        unitPrice
        minCharge
        allowDiscount
        requireAnswer
        showPrint
        createdAt
        catalogExtraCategoryUnitPrice {
          id
        }
        machineSet {
          id
        }
      }
    }
  }
`;

export interface QueryResultCatalogExtraRowsUnitPrice {
  catalogExtraRowsUnitPrice: Query["catalogExtraRowsUnitPrice"];
}
export const GET_CATALOG_EXTRA_ROWS_UNIT_PRICE_QUERY = gql`
  query getCatalogExtraRowsUnitPrice($machineId: ID) {
    catalogExtraRowsUnitPrice(machineId: $machineId) {
      id
      productCode {
        id
        code
        description
      }
      name
      minCharge
      catalogExtraCategoryUnitPrice {
        id
        name
      }
      machineSet {
        id
      }
    }
  }
`;

export interface QueryResultCatalogExtraRowsRental {
  catalogExtraRowsRental: Query["catalogExtraRowsRental"];
}
export const GET_CATALOG_EXTRA_ROWS_RENTAL_QUERY = gql`
  query getCatalogExtraRowsRental {
    catalogExtraRowsRental {
      id
      name
      catalogExtraCategoryRental {
        id
        name
      }
      machineSet {
        id
      }
    }
  }
`;

export interface QueryResultCatalogExtraRowsInvoiceReservation {
  catalogExtraRowsInvoiceReservation: Query["catalogExtraRowsInvoiceReservation"];
}
export const GET_CATALOG_EXTRA_ROWS_INVOICE_RESERVATION_QUERY = gql`
  query getCatalogExtraRowsInvoiceReservation($reservationId: ID!) {
    catalogExtraRowsInvoiceReservation(reservationId: $reservationId) {
      id
      productCode {
        id
        code
        description
      }
      titleOverride
      quantity
      unitPriceOverride
      answer
      catalogExtraRowUnitPrice {
        id
        name
        unit
        unitPrice
        requireAnswer
        minCharge
      }
      reservation {
        id
      }
      invoiceRow {
        id
        invoice {
          id
          isAdvance
          sentAt
          createdAt
        }
      }
    }
  }
`;

export interface QueryResultCatalogExtraRowsRentalByLocationMachines {
  catalogExtraRowsRentalByLocationMachines: Query["catalogExtraRowsRentalByLocationMachines"];
}
export const GET_CATALOG_EXTRA_ROWS_RENTAL_BY_LOCATION_MACHINES_QUERY = gql`
  query getCatalogExtraRowsRentalByLocationMachines(
    $locationId: ID
    $machineIds: [ID]!
  ) {
    catalogExtraRowsRentalByLocationMachines(
      locationId: $locationId
      machineIds: $machineIds
    ) {
      id
      identifier
      name
      information
      productNumber
      location {
        id
        name
      }
      catalogExtraCategoryRental {
        id
        name
      }
      machineSet {
        id
        identifier
        serial
        machineModel {
          id
          title
          machineMake {
            id
            title
          }
        }
      }
      maintenancesetting {
        id
        scheduleType
      }
    }
  }
`;

export interface QueryResultMaintenanceHistoryForCatalogExtraRowRental {
  maintenanceHistoryForCatalogExtraRowRental: Query["maintenanceHistoryForCatalogExtraRowRental"];
  maintenanceNextForCatalogExtraRowRental: Query["maintenanceNextForCatalogExtraRowRental"];
}
export const GET_MAINTENANCE_HISTORY_FOR_CATALOG_EXTRA_ROW_RENTAL_QUERY = gql`
  query maintenanceHistoryForCatalogExtraRowRental(
    $catalogExtraRowRentalId: ID!
  ) {
    maintenanceHistoryForCatalogExtraRowRental(
      catalogExtraRowRentalId: $catalogExtraRowRentalId
    ) {
      id
      maintenanceInterval {
        id
        intervalType
        hours
        days
        description
      }
      operationhour {
        id
        hours
      }
      serviceTechnician {
        id
        firstName
        lastName
      }
      servicedAt
    }
    maintenanceNextForCatalogExtraRowRental(
      catalogExtraRowRentalId: $catalogExtraRowRentalId
    ) {
      nextMaintenanceDate
      intervalType
      hours
      days
      description
    }
  }
`;
