import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  CustomerType,
  Mutation,
  MutationCreateReferenceArgs,
  MutationUpdateOrderArgs,
  OrderConfirmationType,
  OrderType,
  QueryReferencesArgs,
  ReferenceType,
} from "../../entity/types";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import {
  GET_REFERENCES_QUERY,
  QueryResultReferences,
} from "../../apollo/queries/references";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import {
  CUSTOMER_ID_CATALOG_COMPANY,
  CUSTOMER_ID_CATALOG_PRIVATE,
  ID_EMPTY,
} from "../../utils/constants";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { CREATE_REFERENCE_MUTATION } from "../../apollo/mutations/references";
import { ReferenceEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import {
  GET_LOCATIONS_QUERY,
  QueryResultLocations,
} from "../../apollo/queries/locations";
import TransportQuickButton from "./TransportQuickButton";
import DialogAdvanceInvoice from "./DialogAdvanceInvoice";
import { UPDATE_ORDER_MUTATION } from "../../apollo/mutations/orders";
import { updateCacheOrder } from "../../utils/cache";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import ButtonLoad from "../Shared/ButtonLoad";
import CommissionsForOrder from "../Commission/CommissionsForOrder";
import OrderPdfButtons from "../Shared/OrderPdfButtons";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  order: OrderType;
}

function OrderFields({ classes, customer, order }: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddReference = checkPermission(myPermissions, [
    "references.add_reference",
  ]);
  const hasPermissionChangeOrder = checkPermission(myPermissions, [
    "orders.change_order",
  ]);
  const hasPermissionViewCommission = checkPermission(myPermissions, [
    "commissions.view_commission",
  ]);

  const [orderEdited, setOrderEdited] = useState(order);
  const [openDialogReference, setOpenDialogReference] = useState(false);
  const [reference, setReference] = useState(ReferenceEmpty);
  const [showTransportQuick, setShowTransportQuick] = useState(false);
  const [openDialogAdvanceInvoice, setOpenDialogAdvanceInvoice] =
    useState(false);

  const {
    loading: loadingLocations,
    error: errorLocations,
    data: dataLocations,
  } = useQuery<QueryResultLocations>(GET_LOCATIONS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("locations"),
  });

  const {
    loading: loadingReferences,
    error: errorReferences,
    data: dataReferences,
  } = useQuery<QueryResultReferences, QueryReferencesArgs>(
    GET_REFERENCES_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("references"),
      skip: [CUSTOMER_ID_CATALOG_COMPANY, CUSTOMER_ID_CATALOG_PRIVATE].includes(
        customer.id
      ),
      variables: { customerId: customer.id },
    }
  );

  const [updateOrder, { loading: loadingUpdateOrder }] = useMutation<
    Mutation,
    MutationUpdateOrderArgs
  >(UPDATE_ORDER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheOrder(cache);
    },
  });

  const [createReference, { loading: loadingCreateRef }] = useMutation<
    Mutation,
    MutationCreateReferenceArgs
  >(CREATE_REFERENCE_MUTATION, {
    refetchQueries: [
      { query: GET_REFERENCES_QUERY, variables: { customerId: customer.id } },
    ],
    onCompleted: (result) => {
      setReference(ReferenceEmpty);

      const referenceNew = result.createReference?.reference
        ? result.createReference.reference
        : ReferenceEmpty;

      setOrderEdited({
        ...orderEdited,
        reference: referenceNew,
      });

      handleUpdateOrder({
        referenceId: referenceNew.id,
      });
    },
    onError: (error) => {
      handleError(error);
    },
  });

  useEffect(() => {
    setOrderEdited(order);
  }, [order]);

  if (loadingReferences || loadingLocations) return <Loading />;
  if (errorReferences) return <Error error={errorReferences} />;
  if (errorLocations) return <Error error={errorLocations} />;
  if (!dataLocations) return <Error error={t("error_query_failed")} />;

  const handleUpdateOrder = (values: { [key: string]: string | boolean }) => {
    const base: MutationUpdateOrderArgs = {
      orderId: orderEdited.id,
      customerId: orderEdited.customer ? orderEdited.customer.id : ID_EMPTY,
      referenceId: orderEdited.reference ? orderEdited.reference.id : ID_EMPTY,
      locationId: orderEdited.location.id,
      information: orderEdited.information,
      informationInvoice: orderEdited.informationInvoice,
      confirmationType: orderEdited.confirmationType,
      hasInsurance: orderEdited.hasInsurance,
      updatePricesInCatalogSwitch: orderEdited.updatePricesInCatalogSwitch,
    };

    updateOrder({
      variables: { ...base, ...values },
    });
  };

  const onClickReferenceNew = () => {
    setOpenDialogReference(true);
  };

  const onClickReferenceSave = () => {
    createReference({
      variables: { customerId: customer.id, name: reference.name },
    });
    setOpenDialogReference(false);
  };

  const filter = createFilterOptions<ReferenceType>();

  return (
    <div className={loadingUpdateOrder ? "loading" : ""}>
      {!orderEdited.customer && (
        <p className="text-muted">{t("reference_hidden_without_customer")}</p>
      )}
      {orderEdited.customer && (
        <div>
          <p>
            {orderEdited.customer.name}{" "}
            <span className="text-muted">
              {orderEdited.customer.businessId}
            </span>
          </p>
          <div className="d-flex mb-3">
            <Autocomplete
              className={classes.autocomplete}
              options={
                dataReferences && dataReferences.references
                  ? dataReferences.references
                  : []
              }
              filterOptions={(options: ReferenceType[], params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.name
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    ...ReferenceEmpty,
                    name: inputValue,
                  });
                }

                return filtered;
              }}
              getOptionLabel={(option: ReferenceType) =>
                option.id === ID_EMPTY
                  ? t("add_value", { value: option.name })
                  : option.name
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="m-0"
                  label={t("reference")}
                  variant="outlined"
                />
              )}
              value={orderEdited.reference}
              disabled={!hasPermissionChangeOrder}
              getOptionSelected={(a, b) => {
                if (!a || !b) {
                  return false;
                }
                return a.id === b.id;
              }}
              onChange={(event, referenceSelected: ReferenceType | null) => {
                if (referenceSelected && referenceSelected.id === ID_EMPTY) {
                  createReference({
                    variables: {
                      customerId: customer.id,
                      name: referenceSelected.name,
                    },
                  });
                } else {
                  setOrderEdited({
                    ...orderEdited,
                    reference: referenceSelected
                      ? referenceSelected
                      : undefined,
                  });
                  handleUpdateOrder({
                    referenceId: referenceSelected
                      ? referenceSelected.id
                      : ID_EMPTY,
                  });
                }
              }}
              selectOnFocus
            />
            {hasPermissionAddReference && (
              <Button className="ms-2 btnRound" onClick={onClickReferenceNew}>
                <AddIcon />
              </Button>
            )}
          </div>
        </div>
      )}
      <FormControl fullWidth>
        <InputLabel id="lblOrderLocation">{t("location")}</InputLabel>
        <Select
          autoWidth
          labelId="lblOrderLocation"
          value={orderEdited.location.id}
          disabled={!hasPermissionChangeOrder}
          onChange={(event) => {
            const locationIdNew = String(event.target.value);
            setOrderEdited({
              ...orderEdited,
              location: {
                ...orderEdited.location,
                id: locationIdNew,
              },
            });
            handleUpdateOrder({
              locationId: locationIdNew,
            });
          }}
        >
          {orderEdited.location.id === ID_EMPTY && (
            <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
          )}
          {dataLocations.locations?.map((location) => (
            <MenuItem key={location.id} value={location.id}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          multiline
          label={t("information")}
          value={orderEdited.information}
          minRows={2}
          disabled={!hasPermissionChangeOrder}
          onChange={(event) => {
            setOrderEdited({
              ...orderEdited,
              information: event.currentTarget.value,
            });
          }}
          onBlur={() => {
            handleUpdateOrder({
              information: orderEdited.information,
            });
          }}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          multiline
          label={t("information_invoice")}
          value={orderEdited.informationInvoice}
          minRows={2}
          inputProps={{ maxLength: 500 }}
          disabled={!hasPermissionChangeOrder}
          onChange={(event) => {
            setOrderEdited({
              ...orderEdited,
              informationInvoice: event.currentTarget.value,
            });
          }}
          onBlur={() => {
            handleUpdateOrder({
              informationInvoice: orderEdited.informationInvoice,
            });
          }}
        />
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel>{t("confirmation_type")}</FormLabel>
        <RadioGroup
          aria-label={t("confirmation_type")}
          name="confirmationType"
          value={orderEdited.confirmationType}
          onChange={(event) => {
            const confirmationTypeNew = event.currentTarget
              .value as OrderConfirmationType;
            setOrderEdited({
              ...orderEdited,
              confirmationType: confirmationTypeNew,
            });
            handleUpdateOrder({
              confirmationType: confirmationTypeNew,
            });
          }}
        >
          <FormControlLabel
            value={OrderConfirmationType.Email}
            control={<Radio />}
            disabled={!customer.email || !hasPermissionChangeOrder}
            label={
              <>
                <span className="me-3">
                  {t("confirmation_type_" + OrderConfirmationType.Email)}
                </span>
                <small>
                  {customer.email ? customer.email : t("no_email_address")}
                </small>
              </>
            }
          />
          <FormControlLabel
            value={OrderConfirmationType.Print}
            control={<Radio />}
            label={t("confirmation_type_" + OrderConfirmationType.Print)}
            disabled={!hasPermissionChangeOrder}
          />
        </RadioGroup>
      </FormControl>
      <Row>
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblOrderHasInsurance">
              {t("has_insurance")}
            </InputLabel>
            <Select
              autoWidth
              labelId="lblOrderHasInsurance"
              value={orderEdited.hasInsurance ? 1 : 0}
              onChange={(event) => {
                const hasInsuranceNew = event.target.value === 1;
                setOrderEdited({
                  ...orderEdited,
                  hasInsurance: hasInsuranceNew,
                });
                handleUpdateOrder({
                  hasInsurance: hasInsuranceNew,
                });
              }}
              disabled={!hasPermissionChangeOrder}
            >
              <MenuItem value={0}>{t("no")}</MenuItem>
              <MenuItem value={1}>{t("yes")}</MenuItem>
            </Select>
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblOrderUpdatePricesInCatalogSwitch">
              {t("update_prices_in_catalog_switch")}
            </InputLabel>
            <Select
              autoWidth
              labelId="lblOrderUpdatePricesInCatalogSwitch"
              value={orderEdited.updatePricesInCatalogSwitch ? 1 : 0}
              onChange={(event) => {
                const updatePricesInCatalogSwitchNew = event.target.value === 1;
                setOrderEdited({
                  ...orderEdited,
                  updatePricesInCatalogSwitch: updatePricesInCatalogSwitchNew,
                });
                handleUpdateOrder({
                  updatePricesInCatalogSwitch: updatePricesInCatalogSwitchNew,
                });
              }}
              disabled={!hasPermissionChangeOrder}
            >
              <MenuItem value={0}>{t("no")}</MenuItem>
              <MenuItem value={1}>{t("yes")}</MenuItem>
            </Select>
          </FormControl>
        </Col>
      </Row>
      {hasPermissionViewCommission && (
        <CommissionsForOrder orderId={order.id} />
      )}
      {showTransportQuick && (
        <TransportQuickButton
          order={orderEdited}
          setShow={setShowTransportQuick}
          transports={orderEdited.transportSet}
        />
      )}
      <div>
        {!showTransportQuick && (
          <Button
            className="me-2"
            variant="light"
            onClick={() => setShowTransportQuick(!showTransportQuick)}
          >
            {t("quick_transport")}
          </Button>
        )}
        <Button
          className="me-2"
          variant="light"
          onClick={() => setOpenDialogAdvanceInvoice(!openDialogAdvanceInvoice)}
        >
          {t("pre_invoicing_or_card")}
        </Button>
        <OrderPdfButtons orders={[order]} driveId={ID_EMPTY} />
      </div>
      {openDialogAdvanceInvoice && (
        <DialogAdvanceInvoice
          open={openDialogAdvanceInvoice}
          setOpen={setOpenDialogAdvanceInvoice}
          order={orderEdited}
        />
      )}
      {hasPermissionAddReference && (
        <Dialog open={openDialogReference}>
          <DialogTitle>{t("new_reference")}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                label={t("name")}
                value={reference.name}
                autoFocus={true}
                onChange={(event) => {
                  setReference({
                    ...reference,
                    name: event.target.value,
                  });
                }}
                inputProps={{ maxLength: 100 }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenDialogReference(false)}
              variant="secondary"
            >
              {t("cancel")}
            </Button>
            <ButtonLoad
              loading={loadingCreateRef}
              onClick={onClickReferenceSave}
              variant="primary"
            >
              {t("save")}
            </ButtonLoad>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    autocomplete: {
      flex: "1 1 0",
    },
  });

export default withStyles(styles)(OrderFields);
