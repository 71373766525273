import { ID_EMPTY } from "./constants";

export function removeStringFromArr(
  arr: string[],
  value: string | string[]
): string[] {
  let arrCopy = [...arr];
  let valueArr: string[] = typeof value !== "object" ? [value] : value;
  return arrCopy.filter((item) => !valueArr.includes(item));
}

export function addStringToArr(
  arr: string[],
  value: string | string[]
): string[] {
  let arrFiltered = removeStringFromArr(arr, value);
  let valueArr: string[] = typeof value !== "object" ? [value] : value;
  return [...arrFiltered, ...valueArr];
}

export function findFromSetById(id: string, set: any[], defaultVal: any) {
  let value = defaultVal;
  if (id !== ID_EMPTY) {
    set.forEach((item) => {
      if (item.id === id) {
        value = item;
        return;
      }
    });
  }
  return value;
}

export function removeFromSetById(id: string, set: any[]) {
  const setNew = [...set];
  setNew.splice(
    set.findIndex((item) => item.id === id),
    1
  );
  return setNew;
}

export function sortDictByValue(dict: { [key: string]: string }) {
  return Object.fromEntries(
    Object.entries(dict).sort(([, a], [, b]) => a.localeCompare(b))
  );
}
