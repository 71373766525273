import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { OrderType, ReservationType } from "../../entity/types";
import { useTranslation } from "react-i18next";
import WorkQueueCardGroupGive from "./WorkQueueCardGroupGive";

interface Props extends WithStyles<typeof styles> {
  reservations: ReservationType[];
}

function WorkQueueRentingCustomerPickup({ classes, reservations }: Props) {
  const { t } = useTranslation();

  let reservationsByOrder: {
    [orderId: string]: {
      order: OrderType;
      reservations: ReservationType[];
    };
  } = {};
  reservations.forEach((reservation) => {
    // Add a prefix so result is not automatically sorted by order_id
    const key = " " + reservation.order.id;
    if (reservationsByOrder[key] === undefined) {
      reservationsByOrder[key] = {
        order: reservation.order,
        reservations: [],
      };
    }
    reservationsByOrder[key].reservations.push(reservation);
  });

  return (
    <div className="conWorkQueue">
      <div className="titleWorkQueue">
        {t("work_queues_renting_customer_pickup")}
      </div>
      {Object.entries(reservationsByOrder).map(([order_id, row]) => (
        <WorkQueueCardGroupGive
          key={order_id}
          order={row.order}
          reservations={row.reservations}
        />
      ))}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(WorkQueueRentingCustomerPickup);
