import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { CircularProgress, createStyles, Theme } from "@material-ui/core";

interface Props extends WithStyles<typeof styles> {}

const LoadingSimple = ({ classes }: Props) => {
  return (
    <div className="text-center p-2">
      <CircularProgress />
    </div>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(LoadingSimple);
