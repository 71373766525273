import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import ButtonLoad from "../Shared/ButtonLoad";
import {
  MachineInfoAnswerType,
  MachineInfoQuestionAnswerType,
  MachineInfoQuestionType,
  MachineType,
  Mutation,
  MutationDeleteMachineInfoAnswerArgs,
  MutationUpdateMachineInfoAnswerArgs,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { GET_MACHINE_WITH_MACHINE_INFO_CATEGORIES_QUERY } from "../../apollo/queries/machines";
import {
  DELETE_MACHINE_INFO_ANSWER_MUTATION,
  UPDATE_MACHINE_INFO_ANSWER_MUTATION,
} from "../../apollo/mutations/machines";
import { handleError } from "../../entity/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { MachineInfoQuestionOptionEmpty } from "../../entity/empties";
import { useTranslation } from "react-i18next";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
  machineInfoQuestion: MachineInfoQuestionType;
  machineInfoAnswer: MachineInfoAnswerType;
}

function DialogContentMachineInfoAnswer({
  classes,
  machine,
  machineInfoQuestion,
  machineInfoAnswer,
}: Props) {
  const { t } = useTranslation();

  const [machineInfoAnswerEdited, setMachineInfoAnswerEdited] =
    useState(machineInfoAnswer);

  const [updateMachineInfoAnswer, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateMachineInfoAnswerArgs
  >(UPDATE_MACHINE_INFO_ANSWER_MUTATION, {
    refetchQueries: [
      {
        query: GET_MACHINE_WITH_MACHINE_INFO_CATEGORIES_QUERY,
        variables: {
          machineId: machine.id,
        },
      },
    ],
    onError: (error) => {
      handleError(error);
    },
  });

  const [deleteMachineInfoAnswer, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteMachineInfoAnswerArgs
  >(DELETE_MACHINE_INFO_ANSWER_MUTATION, {
    refetchQueries: [
      {
        query: GET_MACHINE_WITH_MACHINE_INFO_CATEGORIES_QUERY,
        variables: {
          machineId: machine.id,
        },
      },
    ],
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionChangeAnswer = checkPermission(myPermissions, [
    "machines.change_machineinfoanswer",
  ]);
  const hasPermissionDeleteAnswer = checkPermission(myPermissions, [
    "machines.delete_machineinfoanswer",
  ]);

  const onClickAnswerDelete = () => {
    deleteMachineInfoAnswer({
      variables: {
        machineInfoAnswerId: machineInfoAnswerEdited.id,
      },
    });
  };

  const handleUpdateAnswer = (
    values: {
      [key: string]: string | number | undefined;
    } = {}
  ) => {
    let base: MutationUpdateMachineInfoAnswerArgs = {
      machineInfoAnswerId: machineInfoAnswerEdited.id,
      machineInfoQuestionId: machineInfoAnswerEdited.machineInfoQuestion.id,
      machineId: machine.id,
      machineInfoQuestionOptionId:
        machineInfoAnswerEdited.machineInfoQuestionOption
          ? machineInfoAnswerEdited.machineInfoQuestionOption.id
          : undefined,
      answer: machineInfoAnswerEdited.answer,
    };

    updateMachineInfoAnswer({
      variables: { ...base, ...values },
    });
  };

  return (
    <div className={loadingUpdate ? "loading" : ""}>
      <FormControl fullWidth>
        {machineInfoQuestion.answerType ===
          MachineInfoQuestionAnswerType.Text && (
          <TextField
            label={machineInfoQuestion.question}
            value={machineInfoAnswerEdited.answer}
            InputProps={{
              endAdornment: hasPermissionDeleteAnswer ? (
                <ButtonLoad
                  loading={loadingDelete}
                  variant="light"
                  onClick={onClickAnswerDelete}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ButtonLoad>
              ) : undefined,
            }}
            inputProps={{
              maxLength: 200,
            }}
            disabled={!hasPermissionChangeAnswer}
            onChange={(event) => {
              setMachineInfoAnswerEdited({
                ...machineInfoAnswerEdited,
                answer: event.target.value,
              });
            }}
            onBlur={() => {
              handleUpdateAnswer();
            }}
          />
        )}
        {machineInfoQuestion.answerType ===
          MachineInfoQuestionAnswerType.Options && (
          <>
            <InputLabel id="lblDialogMachineInfoQuestion">
              {machineInfoQuestion.question}
            </InputLabel>
            <Select
              fullWidth
              labelId="lblDialogMachineInfoQuestion"
              disabled={!hasPermissionChangeAnswer}
              value={
                machineInfoAnswerEdited.machineInfoQuestionOption
                  ? machineInfoAnswerEdited.machineInfoQuestionOption.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                let machineInfoQuestionOptionId = String(event.target.value);
                let machineInfoQuestionOption =
                  machineInfoAnswerEdited.machineInfoQuestionOption;
                if (!machineInfoQuestionOption) {
                  machineInfoQuestionOption = MachineInfoQuestionOptionEmpty;
                }

                setMachineInfoAnswerEdited({
                  ...machineInfoAnswerEdited,
                  machineInfoQuestionOption: {
                    ...machineInfoQuestionOption,
                    id: machineInfoQuestionOptionId,
                  },
                });

                handleUpdateAnswer({
                  machineInfoQuestionOptionId:
                    machineInfoQuestionOptionId !== ID_EMPTY
                      ? machineInfoQuestionOptionId
                      : undefined,
                });
              }}
            >
              <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
              {machineInfoQuestion.machineinfoquestionoptionSet.map(
                (machineInfoQuestionOption) => (
                  <MenuItem
                    key={machineInfoQuestionOption.id}
                    value={machineInfoQuestionOption.id}
                  >
                    {machineInfoQuestionOption.title}
                  </MenuItem>
                )
              )}
            </Select>
          </>
        )}
      </FormControl>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentMachineInfoAnswer);
