import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineBreakdownType,
  MaintenanceType,
  ReservationType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { MachineBreakdownEmpty, ReservationEmpty } from "../../entity/empties";
import { ID_EMPTY } from "../../utils/constants";
import {
  sortBreakdownsByMachineAndRowExtra,
  sortReservationsByMachineAndRowExtra,
} from "../../utils/maintenances/maintenance";
import WorkQueueCardMaintenance from "./WorkQueueCardMaintenance";

interface Props extends WithStyles<typeof styles> {
  maintenancesUpcoming: MaintenanceType[];
  reservationsCurrent: ReservationType[];
  reservationsUpcoming: ReservationType[];
  machineBreakdowns: MachineBreakdownType[];
}

function WorkQueuesMaintenancesUpcoming({
  classes,
  maintenancesUpcoming,
  reservationsCurrent,
  reservationsUpcoming,
  machineBreakdowns,
}: Props) {
  const { t } = useTranslation();

  const currentReservations =
    sortReservationsByMachineAndRowExtra(reservationsCurrent);
  const reservationCurrentByMachine = currentReservations[0];
  const reservationCurrentByRowExtra = currentReservations[1];

  const nextReservations =
    sortReservationsByMachineAndRowExtra(reservationsUpcoming);
  const reservationNextByMachine = nextReservations[0];
  const reservationNextByRowExtra = nextReservations[1];

  const breakdowns = sortBreakdownsByMachineAndRowExtra(machineBreakdowns);
  const breakdownsByMachine = breakdowns[0];
  const breakdownsByRowExtra = breakdowns[1];

  return (
    <div className="conWorkQueue">
      <div className="titleWorkQueue">
        {t("work_queues_upcoming_maintenances")}
      </div>
      {maintenancesUpcoming.map((maintenanceUpcoming) => {
        const key = maintenanceUpcoming.catalogExtraRowRental
          ? maintenanceUpcoming.catalogExtraRowRental.id
          : maintenanceUpcoming.machine
          ? maintenanceUpcoming.machine.id
          : ID_EMPTY;

        const reservationNext =
          reservationNextByRowExtra[key] !== undefined
            ? reservationNextByRowExtra[key]
            : reservationNextByMachine[key] !== undefined
            ? reservationNextByMachine[key]
            : ReservationEmpty;

        const reservationCurrent =
          reservationCurrentByRowExtra[key] !== undefined
            ? reservationCurrentByRowExtra[key]
            : reservationCurrentByMachine[key] !== undefined
            ? reservationCurrentByMachine[key]
            : ReservationEmpty;

        const breakdown =
          breakdownsByRowExtra[key] !== undefined
            ? breakdownsByRowExtra[key]
            : breakdownsByMachine[key] !== undefined
            ? breakdownsByMachine[key]
            : MachineBreakdownEmpty;

        return (
          <WorkQueueCardMaintenance
            key={maintenanceUpcoming.id}
            maintenance={maintenanceUpcoming}
            reservationCurrent={reservationCurrent}
            reservationNext={reservationNext}
            machineBreakdown={breakdown}
          />
        );
      })}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(WorkQueuesMaintenancesUpcoming);
