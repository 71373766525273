import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { CatalogType } from "../../entity/types";
import { renderSelectGroupCategoryRow } from "../../utils/catalogs/select_group";
import LocationFilter from "../Shared/LocationFilter";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
  locationIdsSelected: string[];
  setLocationIdsSelected: React.Dispatch<React.SetStateAction<string[]>>;
  filterRentThrough: number[];
  setFilterRentThrough: React.Dispatch<React.SetStateAction<number[]>>;
  catalogRowIdsSelected: string[];
  setCatalogRowIdsSelected: React.Dispatch<React.SetStateAction<string[]>>;
  canQuery: boolean;
  setCanQuery: React.Dispatch<React.SetStateAction<boolean>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const MachineListFilter = ({
  classes,
  catalog,
  locationIdsSelected,
  setLocationIdsSelected,
  filterRentThrough,
  setFilterRentThrough,
  catalogRowIdsSelected,
  setCatalogRowIdsSelected,
  canQuery,
  setCanQuery,
  search,
  setSearch,
}: Props) => {
  const { t } = useTranslation();

  const [catalogRowIdsSelectedEdited, setCatalogRowIdsSelectedEdited] =
    useState(catalogRowIdsSelected);
  const [filterRentThroughEdited, setFilterRentThroughEdited] =
    useState(filterRentThrough);
  const [searchEdited, setSearchEdited] = useState(search);

  return (
    <Row>
      <Col className="mb-3" sm={6} md={4} lg={4} xl={3}>
        <LocationFilter
          locationIdsSelected={locationIdsSelected}
          setLocationIdsSelected={setLocationIdsSelected}
        />
      </Col>
      <Col className="mb-3" sm={6} md={4} lg={4} xl={3}>
        <FormControl fullWidth>
          <InputLabel id="lblMachineListFilterCatalogRow">
            {t("catalog_rows")}
          </InputLabel>
          <Select
            autoWidth
            labelId="lblMachineListFilterCatalogRow"
            value={catalogRowIdsSelectedEdited}
            multiple
            onChange={(event) =>
              setCatalogRowIdsSelectedEdited(event.target.value as string[])
            }
            onClose={() =>
              setCatalogRowIdsSelected(catalogRowIdsSelectedEdited)
            }
          >
            {catalog.catalogcategoryupperSet.map((catalogCategoryUpper) =>
              renderSelectGroupCategoryRow(catalogCategoryUpper)
            )}
          </Select>
        </FormControl>
      </Col>
      <Col className="mb-3" sm={6} md={4} lg={4} xl={3}>
        <FormControl fullWidth>
          <InputLabel id="lblMachineListFilterRentThrough">
            {t("rent_through")}
          </InputLabel>
          <Select
            autoWidth
            labelId="lblMachineListFilterRentThrough"
            value={filterRentThroughEdited}
            multiple
            onChange={(event) =>
              setFilterRentThroughEdited(event.target.value as number[])
            }
            onClose={() => setFilterRentThrough(filterRentThroughEdited)}
          >
            <MenuItem value={0}>{t("rent_through_no")}</MenuItem>
            <MenuItem value={1}>{t("rent_through_yes")}</MenuItem>
          </Select>
        </FormControl>
      </Col>
      <Col className="mb-3" sm={6} md={4} lg={4} xl={3}>
        <FormControl fullWidth>
          <TextField
            label={t("search")}
            value={searchEdited}
            onChange={(event) => {
              setSearchEdited(event.target.value);
            }}
            onBlur={() => {
              setSearch(searchEdited);
            }}
          />
        </FormControl>
      </Col>
      {!canQuery && (
        <Col>
          <Button className="mt-2" onClick={() => setCanQuery(!canQuery)}>
            {t("search")}
          </Button>
        </Col>
      )}
    </Row>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MachineListFilter);
