import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultProfiles {
  groups: Query["groups"];
  permissions: Query["permissions"];
}
export const GET_PROFILES_QUERY = gql`
  query getProfilesQuery {
    groups {
      id
      name
      permissions {
        id
        codename
      }
    }
    permissions {
      id
      codename
      name
    }
  }
`;
