import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Paper, Theme } from "@material-ui/core";
import { OrderType } from "../../entity/types";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import ButtonReservationDelete from "./ButtonReservationDelete";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import {
  getReservationProductName,
  reservationCanBeDeleted,
} from "../../utils/reservations/reservation";
import { newDate } from "../../utils/dates";
import ButtonOrderDelete from "./ButtonOrderDelete";

interface Props extends WithStyles<typeof styles> {
  order: OrderType;
  forgetOrder: Function;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

function CalendarOrder({ classes, order, forgetOrder, setOpenDialog }: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionDeleteReservation = checkPermission(myPermissions, [
    "reservations.delete_reservation",
  ]);

  const onClickForget = () => {
    forgetOrder();
  };

  return (
    <>
      {order.reservationSet.length === 0 && (
        <div>
          <div className="text-muted">{t("order_id", { id: order.id })}</div>
          <div className="mt-2">
            <ButtonOrderDelete
              order={order}
              reservations={order.reservationSet}
              callbackOnDelete={onClickForget}
            />
          </div>
        </div>
      )}
      {order.reservationSet.length > 0 && (
        <Paper variant="outlined" className="pt-2 pe-3 pb-2 ps-3 mt-1">
          <Table
            id="tblCalendarOrderReservations"
            className="m-0"
            borderless
            size="sm"
          >
            {order.reservationSet.length > 0 && (
              <tbody>
                {order.reservationSet.map((reservation) => (
                  <tr key={reservation.id}>
                    <td>{getReservationProductName(t, reservation, true)}</td>
                    <td>
                      {t("date_range", {
                        start: format(
                          newDate(reservation.dateRented),
                          t("format_date")
                        ),
                        end: format(
                          newDate(reservation.dateReturned),
                          t("format_date")
                        ),
                      })}
                    </td>
                    <td>
                      {hasPermissionDeleteReservation &&
                        reservationCanBeDeleted(reservation) && (
                          <ButtonReservationDelete
                            reservation={reservation}
                            size="sm"
                          />
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
            <tfoot>
              <tr>
                <td colSpan={3}>
                  <div className="buttons">
                    <Button
                      disabled={order.customer ? false : true}
                      onClick={() => {
                        setOpenDialog(true);
                      }}
                    >
                      {t("continue_order")}
                    </Button>
                    <Button onClick={onClickForget} variant="secondary">
                      {t("forget_order")}
                    </Button>
                  </div>
                  {order.customer ? "" : <small>{t("select_customer")}</small>}
                </td>
              </tr>
            </tfoot>
          </Table>
        </Paper>
      )}
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CalendarOrder);
