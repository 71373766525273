import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { EventEventType, EventType } from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";

interface Props extends WithStyles<typeof styles> {
  event: EventType;
  setEvent: React.Dispatch<React.SetStateAction<EventType>>;
}

function DialogContentEvent({ classes, event, setEvent }: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, ["events.add_event"]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "events.change_event",
  ]);
  const disabled = event.id ? !hasPermissionEdit : !hasPermissionAdd;

  return (
    <DialogContent>
      <FormControl fullWidth>
        <InputLabel id="lblEventType">{t("type")}</InputLabel>
        <Select
          autoWidth
          labelId="lblEventType"
          value={event.eventType}
          disabled={disabled}
          onChange={(e) => {
            setEvent({
              ...event,
              eventType: e.target.value as EventEventType,
            });
          }}
        >
          <MenuItem value={EventEventType.Call}>
            {t("event_type_" + EventEventType.Call)}
          </MenuItem>
          <MenuItem value={EventEventType.Visit}>
            {t("event_type_" + EventEventType.Visit)}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("date")}
          type="date"
          value={event.date}
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEvent({
              ...event,
              date: e.target.value,
            });
          }}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("information")}
          onChange={(e) => {
            setEvent({
              ...event,
              information: e.target.value,
            });
          }}
          value={event.information}
          disabled={disabled}
          multiline
        />
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentEvent);
